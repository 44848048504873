import { CorporateFare, MedicalServices, Person } from "@mui/icons-material";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import { Chip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { api } from "services/AxiosInterceptor";
import {
  createDoctorCommonTreatment,
  createExperiences,
  deleteDoctorCommonTreatment,
  deleteExperiences,
  getAllCommonTreats,
  updateExperiences,
  createCommonTreats,
} from "services/doctorService";
import { ACTIVE_STATE, startsWithAlphabetic } from "store/constant";
import ModalUI from "ui-component/ModalUI";
import CustomAutoComplete from "ui-component/custom-components/CustomAutoComplete";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import Reveal from "views/utilities/Reveal";

const calendarValue = (year) => {
  if (year) {
    return dayjs(`${year}-01-01`);
  }

  return null;
};

const columns = [
  { field: "roleName", label: "Role Name" },
  { field: "organizationName", label: "Organization Name" },
  { field: "fromYear", label: "From Year" },
  { field: "tillYear", label: "Till Year" },
  { field: "Actions", label: "ACTIONS", actions: true },
];

const DoctorExperience = ({
  doctorDetails,
  setDoctorDetails,
  readOnly,
  setReadOnly,
  refreshData,
  ...others
}) => {
  const [open, setOpen] = useState(false);
  const [openTreat, setOpenTreat] = useState(false);
  const [selected, setSelected] = useState();

  const [treatIds, setTreatIds] = useState([]);
  const [commonTreats, setCommonTreats] = useState([]);
  const { handleClick } = useContext(ToastContext);

  const MIN_AGE_FOR_EXPERIENCE = 20;

  const doctorDateOfBirth = dayjs(doctorDetails?.userResponse?.dateOfBirth);
  const minExperienceDate = doctorDateOfBirth.add(MIN_AGE_FOR_EXPERIENCE, "year").startOf("year");

  const fetchData = async () => {
    try {
      if (doctorDetails) {
        setTreatIds(doctorDetails?.doctorCommonTreatments?.map((e) => e.treatmentId));
        const allTreats = await api.get(`/api/common-treatments?ids=${treatIds}`);
        const treatments = doctorDetails?.doctorCommonTreatments?.map((e) => {
          return {
            ...e,
            treatmentName: allTreats.data.filter((t) => t.id === e.treatmentId)[0]?.treatmentName,
          };
        });
        setCommonTreats(treatments);
      }
    } catch (error) {
      console.error("Error Fetching Common Treatments");
    }
  };

  const closeModal = () => {
    setOpen(false);
    setOpenTreat(false);
    setSelected(null);
  };

  const openUpdateExperienceModal = (degree) => {
    setSelected(degree);
    setOpen(true);
  };

  const openAddExperienceModal = () => {
    setOpen(true);
  };

  const openTreatModal = () => {
    setOpenTreat(true);
  };

  useEffect(() => {
    if (doctorDetails) {
      fetchData();
    }
  }, [doctorDetails]);

  const onDelete = async (row) => {
    if (row.status === ACTIVE_STATE) {
      try {
        await deleteDoctorCommonTreatment(row.id);
        handleClick("success", "Treatment has been marked Inactive");
      } catch (error) {
        handleClick("error", "There seems to be an error marking the Treatment as Inactive.");
      }
    } else {
      handleClick("info", "Treatment is already inactive!");
    }
  };

  const handleMarkInactive = async (row) => {
    if (row.status === ACTIVE_STATE) {
      try {
        await deleteExperiences(row.id);
        handleClick("success", "Experience has been marked Inactive");
      } catch (error) {
        handleClick("error", "There seems to be an error marking the Experience as Inactive");
      }
    } else {
      handleClick("error", "Experience is already inactive!");
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateExperienceModal,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleMarkInactive,
    },
  ];

  return (
    <Reveal>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Typography sx={{ fontSize: "14px", color: "#004C70", fontWeight: "600" }}>
          Experiences
        </Typography>
        <CustomButton
          onClick={openAddExperienceModal}
          label="Add"
          className="ri-add-fill ri-lg btn--primary"
        />
      </Box>

      <CustomizedTable columns={columns} tableData={doctorDetails?.experiences} actions={actions} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Typography sx={{ fontSize: "14px", color: "#004C70", fontWeight: "600" }}>
          Specializations
        </Typography>
      </Box>

      <Grid style={{ marginBottom: "40px", gap: "10px" }} container>
        {doctorDetails?.specialization?.name && (
          <>
            <Chip
              label={doctorDetails?.specialization?.name}
              className="btn--primary-light"
              style={{ minWidth: "150px", borderRadius: "8px" }}
            />
          </>
        )}
        {!doctorDetails?.specialization?.name && (
          <>
            <Chip
              label="No specialization added yet!"
              className="btn--primary-light"
              style={{ minWidth: "150px", borderRadius: "8px" }}
            />
          </>
        )}
      </Grid>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Typography sx={{ fontSize: "14px", color: "#004C70", fontWeight: "600" }}>
          Common Treatments
        </Typography>
        <CustomButton
          onClick={openTreatModal}
          label="Add"
          className="ri-add-fill ri-lg btn--primary"
        />
      </Box>

      <Grid style={{ marginBottom: "40px", gap: "10px" }} container>
        {commonTreats.map((row, index) => (
          <Chip
            label={row?.treatmentName}
            className="btn--primary-light"
            style={{ minWidth: "150px", borderRadius: "8px" }}
            onDelete={!readOnly ? () => onDelete(row) : null}
          />
        ))}
        {commonTreats.length === 0 && (
          <>
            <Chip
              label="No treatments added yet!"
              className="btn--primary-light"
              style={{ minWidth: "150px", borderRadius: "8px" }}
            />
          </>
        )}
      </Grid>

      <ModalUI
        visible={open}
        close={closeModal}
        title={selected ? "Update Experience" : "Add Experience"}
        component={
          <DoctorExperienceModal
            experiences={doctorDetails?.experiences}
            minExperienceDate={minExperienceDate}
            selected={selected}
            handleClick={handleClick}
            close={() => {
              closeModal();
            }}
            doctorId={doctorDetails?.id}
            refreshData={refreshData}
          />
        }
      />

      <ModalUI
        visible={openTreat}
        close={closeModal}
        title={"Add Common Treatment"}
        component={
          <TreatmentModal
            handleClick={handleClick}
            close={() => {
              closeModal();
            }}
            doctorId={doctorDetails?.id}
            refreshData={refreshData}
          />
        }
      />
    </Reveal>
  );
};

const DoctorExperienceModal = ({
  experiences,
  selected,
  handleClick,
  close,
  doctorId,
  refreshData,
  minExperienceDate,
}) => {
  const [roleName, setRoleName] = useState(selected?.roleName ? selected?.roleName : "");
  const [organisationName, setOrganisationName] = useState(
    selected?.organizationName ? selected?.organizationName : ""
  );
  const [fromYear, setFromYear] = useState(
    selected?.fromYear ? calendarValue(selected?.fromYear) : null
  );
  const [tillYear, setTillYear] = useState(
    selected?.tillYear ? calendarValue(selected?.tillYear) : null
  );

  const currentDate = dayjs();
  const [roleNameTouched, setRoleNameTouched] = useState(false);
  const [organizationNameTouched, setOrganizationNameTouched] = useState(false);
  const pattern = /^[a-zA-Z0-9\s'-.()]+$/;

  const [errors, setErrors] = useState({});

  const validateRoleName = (errors, roleName) => {
    if (!roleName) {
      errors.roleName = "Please enter valid role name.";
    } else if (roleName.length < 3) {
      errors.roleName = "Role name must be at least 3 characters long";
    } else if (roleName.length > 100) {
      errors.roleName = "Role name cannot be longer than 100 characters";
    } else if (!pattern.test(roleName)) {
      errors.roleName = "Role name can only contain following special characters: .-'()";
    } else if (!startsWithAlphabetic(roleName)) {
      errors.roleName = "Name must start with alphabet";
    }
  };

  const validateOrganizationName = (errors, organisationName) => {
    if (!organisationName) {
      errors.organisationName = "Please enter organization name.";
    } else if (organisationName.length < 3) {
      errors.organisationName = "Must be at least 3 characters long";
    } else if (organisationName.length > 100) {
      errors.organisationName = "Cannot be longer than 100 characters";
    } else if (!pattern.test(organisationName)) {
      errors.organisationName = "can only contain following special characters: .-'()";
    } else if (!startsWithAlphabetic(organisationName)) {
      errors.organisationName = "Name must start with alphabet";
    }
  };

  const validate = () => {
    const errors = {};

    setRoleNameTouched(true);
    validateRoleName(errors, roleName);

    setOrganizationNameTouched(true);
    validateOrganizationName(errors, organisationName);

    if (fromYear === null) {
      errors.fromYear = "Please select valid start year.";
    } else if (!(fromYear?.isValid ? fromYear.isValid() : true)) {
      errors.fromYear = "Please select valid 'Year' value.";
    } else if (fromYear > currentDate) {
      errors.fromYear = "Start year cannot be in future.";
    } else if (fromYear.isBefore(minExperienceDate)) {
      errors.fromYear = `Year should be at least ${minExperienceDate.year()} for experience.`;
    }

    if (tillYear === null) {
      errors.tillYear = "Please select valid end year.";
    } else if (!(tillYear?.isValid ? tillYear.isValid() : true)) {
      errors.tillYear = "Please select valid 'Year' value.";
    } else if (tillYear <= fromYear) {
      errors.tillYear = "End year should be after start year.";
    } else if (tillYear > currentDate) {
      errors.tillYear = "End year cannot be in future.";
    }

    return errors;
  };

  useEffect(() => {
    if (selected && experiences) {
      experiences.find((experience) => {
        return experience.id === selected.id;
      });
    }
  }, [selected, experiences]);

  const saveExperience = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        doctorId: doctorId,
        roleName: roleName,
        organizationName: organisationName,
        fromYear: fromYear?.$y ? fromYear.$y : fromYear.year(),
        tillYear: tillYear?.$y ? tillYear.$y : tillYear.year(),
      };

      if (selected) {
        try {
          const postData = {
            ...data,
            id: selected?.id,
          };
          await updateExperiences(selected.id, postData);
          handleClick("success", "Experience has been successfully updated.");
          refreshData();
          close();
        } catch (error) {
          handleClick("error", "There seems to be an error updating the experience.");
        }
      } else {
        try {
          await createExperiences(data);
          handleClick("success", "Experience has been successfully added.");
          close();
          refreshData();
        } catch (error) {
          handleClick("error", "There seems to be an error adding the experience.");
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid size={{ xs: 12 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Role Name"
            name="roleName"
            required
            value={roleName}
            onBlur={(e) => {
              setRoleNameTouched(true);
              const newError = {};
              validateRoleName(newError, roleName);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setRoleName(e.target.value);
              if (roleNameTouched) {
                const newError = {};
                validateRoleName(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.roleName;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            size={"big"}
            startAdornment={<Person />}
            error={Boolean(errors.roleName)}
            errorText={errors.roleName}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Organization Name"
            name="organizationName"
            required
            value={organisationName}
            onBlur={(e) => {
              setOrganizationNameTouched(true);
              const newError = {};
              validateOrganizationName(newError, organisationName);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setOrganisationName(e.target.value);
              if (organizationNameTouched) {
                const newError = {};
                validateOrganizationName(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.organisationName;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            size={"big"}
            startAdornment={<CorporateFare />}
            error={Boolean(errors.organisationName)}
            errorText={errors.organisationName}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <FormDatePicker
            label={"From year"}
            style={{ width: "100%", marginTop: "7px" }}
            disableFuture
            required
            value={fromYear}
            minDate={minExperienceDate}
            onChange={(year) => {
              setFromYear(year);
              if (year === null) {
                setErrors({ ...errors, fromYear: "Please select start year." });
              } else if (!year.isValid()) {
                setErrors({ ...errors, fromYear: "Please enter valid 'Year' value." });
              } else if (year > currentDate) {
                setErrors({
                  ...errors,
                  fromYear: "Year cannot be in a future.",
                });
              } else if (year.isBefore(minExperienceDate)) {
                setErrors({
                  ...errors,
                  fromYear: `Year should be at least ${minExperienceDate.year()} for experience.`,
                });
              } else {
                const { fromYear, ...newError } = errors;
                if (tillYear && tillYear.isBefore(year)) {
                  newError.tillYear = "End Year should be after Start Year.";
                }
                setErrors(newError);
              }
            }}
            views={["year"]}
            openTo="year"
            disableBorder={false}
            error={Boolean(errors.fromYear)}
            errorText={errors.fromYear}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <FormDatePicker
            label={"Till year"}
            required
            minDate={fromYear ? fromYear : minExperienceDate}
            style={{ width: "100%", marginTop: "7px" }}
            disableFuture
            value={tillYear}
            onChange={(year) => {
              setTillYear(year);
              if (year === null) {
                setErrors({ ...errors, tillYear: "Please select end year." });
              } else if (!year.isValid()) {
                setErrors({ ...errors, tillYear: "Please select valid 'Year' value." });
              } else if (year < fromYear) {
                setErrors({
                  ...errors,
                  tillYear: "End year should be after from year.",
                });
              } else if (year > currentDate) {
                setErrors({
                  ...errors,
                  tillYear: "Year cannot be in a future.",
                });
              } else {
                const { tillYear, ...newError } = errors;
                setErrors(newError);
              }
            }}
            views={["year"]}
            openTo="year"
            disableBorder={false}
            error={Boolean(errors.tillYear)}
            errorText={errors.tillYear}
          />
        </Grid>

        <Grid sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            onClick={saveExperience}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "auto" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const TreatmentModal = ({ handleClick, close, doctorId, refreshData }) => {
  const [treat, setTreat] = useState(null);
  const [treatments, setTreatments] = useState([]);
  const [newTreat, setNewTreat] = useState("");
  const [errors, setErrors] = useState({});

  const [treatTouched, setTreatTouched] = useState(false);
  const pattern = /^[a-zA-Z0-9 .,'-]*$/;

  const validateTreat = (errors, newTreat) => {
    if (!newTreat) {
      errors.treat = "Please enter valid treatment name.";
    } else if (newTreat.treatmentName.length < 3) {
      errors.treat = "Treatment name must be at least 3 characters long";
    } else if (newTreat.treatmentName.length > 100) {
      errors.treat = "Treatment name cannot be longer than 100 characters";
    } else if (!pattern.test(newTreat.treatmentName)) {
      errors.treat = "Treatment name can only contain following special characters: .-'()";
    } else if (!startsWithAlphabetic(newTreat.treatmentName)) {
      errors.treat = "Name must start with alphabet";
    }
  };

  const validate = (treat) => {
    const errors = {};

    setTreatTouched(true);
    validateTreat(errors, treat);

    return errors;
  };

  const fetchData = async () => {
    try {
      const treatResponse = await getAllCommonTreats();
      setTreatments(treatResponse?.data);
    } catch (error) {
      console.error("Error Fetching Treatments");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const saveTreat = async () => {
    if (treatments.length > 0) {
      const validationErrors = validate(treat);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        setErrors({});

        let data = {
          doctorId: doctorId,
          treatmentId: treat?.id,
          status: treat?.status,
        };

        try {
          const postData = { ...data };
          await createDoctorCommonTreatment(postData);
          handleClick("success", "Treatment has been successfully added.");
          refreshData();
          fetchData();
          close();
        } catch (error) {
          handleClick("error", "There seems to be some error adding the Treatment");
        }
      }
    } else {
      const validationErrors = validate(newTreat);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        try {
          const data = { treatmentName: newTreat };
          const res = await createCommonTreats(data);
          handleClick("success", "Treatment has been successfully created.");
          setNewTreat("");
          setTreatments(res.data);
          let doctorTreatmentData = {
            doctorId: doctorId,
            treatmentId: res.data.id,
            status: res.data.status,
          };
          await createDoctorCommonTreatment(doctorTreatmentData);
          handleClick("success", "Treatment has been successfully added.");
          refreshData();
          fetchData();
          close();
        } catch (e) {
          handleClick("error", "There seems to be an error creating the treatment.");
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid size={{ xs: 12 }}>
          {treatments.length > 0 && (
            <>
              <CustomAutoComplete
                options={treatments}
                label={"Treatment"}
                placeholder="Select"
                required
                iconStart={<MedicalServices />}
                getOptionLabel={(option) => option?.treatmentName}
                onChange={(event, value) => {
                  setTreat(value);
                  const newError = {};
                  validateTreat(newError, value);
                  const updatedErrors = { ...errors };
                  delete updatedErrors.treat;
                  setErrors({ ...updatedErrors, ...newError });
                }}
                value={treat}
                error={Boolean(errors.treat)}
                errorText={errors.treat}
              />
            </>
          )}
          {treatments.length === 0 && (
            <>
              <FormInputField
                label={"Add Treat"}
                value={newTreat}
                onBlur={(e) => {
                  setTreatTouched(true);
                  const newError = {};
                  validateTreat(newError, newTreat);
                  setErrors({ ...errors, ...newError });
                }}
                onChange={(e) => {
                  setNewTreat(e.target.value);
                  if (treatTouched) {
                    const newError = {};
                    validateTreat(newError, e.target.value);
                    const updatedErrors = { ...errors };
                    delete updatedErrors.treat;
                    setErrors({ ...updatedErrors, ...newError });
                  }
                }}
                error={Boolean(errors.treat)}
                errorText={errors.treat}
                startAdornment={<DriveFileRenameOutlineOutlinedIcon />}
                size="small"
              ></FormInputField>
            </>
          )}
        </Grid>
        <Grid sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            onClick={saveTreat}
            label={treatments.length > 0 ? "Save" : "Add"}
            className={"btn--secondary"}
            style={{ marginLeft: "auto" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DoctorExperience;
