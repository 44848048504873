// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offline-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f0f0f0;
}
.offline-container .offline-image {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}
.offline-container .offline-message {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #007390;
}
.offline-container .retry-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #007390;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.offline-container .retry-button:hover {
  background-color: #005f70;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/OfflineAlert.scss"],"names":[],"mappings":"AAoBA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;AAnBF;AAqBE;EACE,YAAA;EACA,YAAA;EACA,mBAAA;AAnBJ;AAsBE;EACE,kCAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AApBJ;AAuBE;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,iCAAA;AArBJ;AAuBI;EACE,yBAAA;AArBN","sourcesContent":["// .offline-alert {\n//   position: fixed;\n//   top: 0;\n//   left: 50%;\n//   transform: translateX(-50%);\n//   background-color: #f44336;\n//   color: white;\n//   padding: 10px 20px;\n//   border-radius: 4px;\n//   z-index: 1000;\n//   text-align: center;\n//   width: 100%;\n//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n// }\n\n// .offline-alert p {\n//   margin: 0;\n//   font-size: 16px;\n// }\n\n.offline-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  text-align: center;\n  background-color: #f0f0f0;\n\n  .offline-image {\n    width: 200px;\n    height: auto;\n    margin-bottom: 20px;\n  }\n\n  .offline-message {\n    font-family: \"Poppins\", sans-serif;\n    font-size: 24px;\n    font-weight: 600;\n    color: #007390;\n  }\n\n  .retry-button {\n    margin-top: 20px;\n    padding: 10px 20px;\n    font-size: 16px;\n    color: #ffffff;\n    background-color: #007390; // Button color\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n\n    &:hover {\n      background-color: #005f70; // Darker shade on hover\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
