import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDgwP2IwvbnPzl8nfhmqMRoc3hc6CL3B5A",
  authDomain: "arog-e9d67.firebaseapp.com",
  projectId: "arog-e9d67",
  storageBucket: "arog-e9d67.appspot.com",
  messagingSenderId: "845218786389",
  appId: "1:845218786389:web:53e6c2729619fe0fac78dc",
  measurementId: "G-D9KCKD67TW",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: "select_account",
});

export { auth, googleProvider };
