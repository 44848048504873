// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appointment-summary {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}
.appointment-summary h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}
.appointment-summary .summary-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.appointment-summary .summary-row:last-child {
  border-bottom: none;
}
.appointment-summary .summary-label {
  font-weight: bold;
  color: #555;
}
.appointment-summary .summary-value {
  color: #333;
}
.appointment-summary .summary-value#totalFees {
  font-weight: bold;
  color: #007bff;
}
.appointment-summary .summary-value#orderId {
  font-style: italic;
  color: #666;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/PatientPayment.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,wCAAA;EACA,yBAAA;AACF;AAEE;EACE,kBAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAGE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,oBAAA;EACA,6BAAA;AADJ;AAGI;EACE,mBAAA;AADN;AAKE;EACE,iBAAA;EACA,WAAA;AAHJ;AAME;EACE,WAAA;AAJJ;AAMI;EACE,iBAAA;EACA,cAAA;AAJN;AAOI;EACE,kBAAA;EACA,WAAA;AALN","sourcesContent":[".appointment-summary {\n  max-width: 500px;\n  margin: 20px auto;\n  padding: 20px;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  background-color: #f9f9f9;\n  // font-family: Arial, sans-serif;\n\n  h2 {\n    text-align: center;\n    margin-bottom: 20px;\n    color: #333;\n  }\n\n  .summary-row {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 12px;\n    padding-bottom: 10px;\n    border-bottom: 1px solid #eee;\n\n    &:last-child {\n      border-bottom: none;\n    }\n  }\n\n  .summary-label {\n    font-weight: bold;\n    color: #555;\n  }\n\n  .summary-value {\n    color: #333;\n\n    &#totalFees {\n      font-weight: bold;\n      color: #007bff;\n    }\n\n    &#orderId {\n      font-style: italic;\n      color: #666;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
