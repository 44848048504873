// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-invitation-verification {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
}
.user-invitation-verification .invite-container {
  width: 500px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: white;
}
.user-invitation-verification .invite-clinic-name {
  font-size: 24px;
  font-weight: bold;
  color: #004c70;
  margin-bottom: 10px;
}
.user-invitation-verification .invite-invited-by {
  font-size: 16px;
  color: #9e9e9e;
  font-weight: 500;
  margin-bottom: 20px;
}
.user-invitation-verification .invite-welcome-message {
  font-size: 20px;
  font-weight: 500;
  color: #29bf91;
  margin-bottom: 20px;
}
.user-invitation-verification .invite-otp-container {
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/userInvitation.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,YAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,0CAAA;EACA,kBAAA;EACA,uBAAA;AACJ;AAEE;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AAAJ;AAGE;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAIE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AAFJ;AAKE;EACE,mBAAA;AAHJ","sourcesContent":[".user-invitation-verification {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  margin: auto;\n\n  .invite-container {\n    width: 500px;\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 10px;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    background-color: white;\n  }\n\n  .invite-clinic-name {\n    font-size: 24px;\n    font-weight: bold;\n    color: #004c70;\n    margin-bottom: 10px;\n  }\n\n  .invite-invited-by {\n    font-size: 16px;\n    color: #9e9e9e;\n    font-weight: 500;\n    margin-bottom: 20px;\n  }\n\n  .invite-welcome-message {\n    font-size: 20px;\n    font-weight: 500;\n    color: #29bf91;\n    margin-bottom: 20px;\n  }\n\n  .invite-otp-container {\n    margin-bottom: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
