import { useEffect, useState } from "react";
import { getMedicationsByPatientId } from "services/PrescriptionsService";
import Grid from "@mui/material/Grid2";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Add, FiberManualRecord, Remove } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAdvices, setAdvices, setMedications } from "store/Slices/prescriptionPadSlice";
import { getMedications } from "services/EntitiesServices";
import { getSelectedAppointmentOnDashboard } from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/appointmentSlice";
import ComponentLoader from "ui-component/custom-components/ComponentLoader";

const PatientPrescriptionActiveMedicationsCV1 = () => {
  const [activeMedications, setActiveMedications] = useState([]);
  const dispatch = useDispatch();
  const prescriptionMedications = useSelector(getMedications);
  const prescriptionAdvices = useSelector(getAdvices);
  const [loading, setLoading] = useState(true);

  const selectedAppointment = useSelector(getSelectedAppointmentOnDashboard)?.value;

  useEffect(() => {
    const fetchActiveMedications = async () => {
      try {
        if (selectedAppointment?.patientId) {
          if (!loading) {
            setLoading(true);
          }
          const activeMedicationResponse = await getMedicationsByPatientId(
            selectedAppointment?.patientId,
            true
          );
          setActiveMedications(activeMedicationResponse.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchActiveMedications();
  }, [selectedAppointment?.patientId]);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}>
        <Grid
          sx={{
            fontWeight: "600",
            margin: "0",
            color: "#004c70",
            lineHeight: "normal",
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
          }}
        >
          Active Medications
        </Grid>
        {loading ? (
          <>
            <div
              style={{
                display: "flex",
                minHeight: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ComponentLoader></ComponentLoader>
            </div>
          </>
        ) : (
          <>
            {activeMedications?.length > 0 ? (
              <div>
                <List sx={{ p: "0px" }}>
                  {activeMedications.map((item, index) => (
                    <ListItem
                      sx={{
                        p: "0px 10px",
                      }}
                      key={index}
                    >
                      <ListItemIcon sx={{ minWidth: "auto", pr: 1 }}>
                        <FiberManualRecord sx={{ fontSize: "8px", color: "#0C2D48" }} />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          sx: {
                            color: "#212121 !important",
                            fontSize: { md: "12px", lg: "13px", xl: "14px" },
                          },
                        }}
                        primary={item?.medicationResponse?.name}
                      ></ListItemText>
                      <Add
                        sx={{
                          color: "#29bf91",
                          cursor: "pointer",
                          fontSize: "18px",
                        }}
                        onClick={() => {
                          const medication = {
                            medicationId: item.id,
                            category: "RX",
                            displayName: item.medicationResponse.name,
                            frequency: item.frequency,
                            duration: item.duration,
                            instructions: item.instructions,
                            doseTiming: item.doseTiming,
                          };
                          if (
                            !prescriptionMedications.find(
                              (medicine) => medicine?.medicationId === item.id
                            )
                          ) {
                            if (
                              prescriptionAdvices?.find((advice) =>
                                advice.displayName.includes(
                                  `Discontinue "${item.medicationResponse.name}"`
                                )
                              )
                            ) {
                              const updatedAdvices = prescriptionAdvices?.filter(
                                (advice) =>
                                  !advice.displayName.includes(
                                    `Discontinue "${item.medicationResponse.name}"`
                                  )
                              );
                              dispatch(setAdvices(updatedAdvices));
                            }
                            dispatch(setMedications([...prescriptionMedications, medication]));
                          }
                        }}
                      />
                      <Remove
                        sx={{
                          color: "red",
                          cursor: "pointer",
                          fontSize: "18px",
                        }}
                        onClick={() => {
                          const advice = {
                            category: "ADV",
                            displayName: `Discontinue "${item.medicationResponse.name}" medicine.`,
                          };
                          if (
                            !prescriptionAdvices?.find((advice) =>
                              advice.displayName.includes(
                                `Discontinue "${item.medicationResponse.name}"`
                              )
                            )
                          ) {
                            if (
                              prescriptionMedications.some(
                                (medicine) => medicine?.medicationId === item.id
                              )
                            ) {
                              const updatedMedications = prescriptionMedications.filter(
                                (medicine) => !(medicine?.medicationId === item.id)
                              );
                              dispatch(setMedications(updatedMedications));
                            }
                            dispatch(setAdvices([...prescriptionAdvices, advice]));
                          }
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    textAlign: "center",
                    minHeight: "20px",
                    width: "50%",
                    backgroundColor: "lightgray",
                  }}
                >
                  NA
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PatientPrescriptionActiveMedicationsCV1;
