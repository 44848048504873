import React from "react";
import "../../../assets/scss/style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardRoutes, { profileDashboard } from "../../../../src/menu-items/dashboard";
import { useDispatch, useSelector } from "react-redux";
import {
  currentActiveUser,
  orgId,
  roleName,
  keysForMenu,
  childrenKeys,
  pathMapper,
  dashboardKey,
  appEnv,
} from "store/constant";
import { useEffect, useState } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import { Box, Divider, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  setRenderSidebar,
  setUserFeatures,
  getRenderSidebar,
  getUserFeatures,
} from "store/Slices/userSlice";

const NewPrimarySidebar = ({
  mainClass,
  toggleMainClass,
  toggleSecondaryBar,
  setSecondarySideBarTitle,
  setSecondarySidebarData,
  setSettingsIconChecked,
  setRouteBeforeSettingsSection,
  settingsIconChecked,
  sidebarList,
  setSidebarList,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [width, setWidth] = useState(window.innerWidth);
  const [isSidebarShrinked, setIsSidebarShrinked] = useState(false);
  const [infoPage, setInfoPage] = useState(null);

  let features = useSelector(getUserFeatures) || {};
  if (Object.keys(features).length === 0) {
    features = JSON.parse(localStorage.getItem("userFeatures"));
    dispatch(setUserFeatures(features));
  }

  window.addEventListener("resize", function (event) {
    setWidth(window.innerWidth);
  });

  const userRoleName = roleName();
  const userOrgId = orgId();
  const allowedChildrenkeys = [];
  const initialListState = [
    dashboardKey,
    {
      id: "DoctorEntities",
      title: "Settings",
      url: "/home/packages",
      iconSrc: "/images/hc_disease.svg",
      children: allowedChildrenkeys,
    },
  ];
  const [list, setList] = useState([]);

  const handleList = (features) => {
    const tempList = initialListState;
    // for main menu
    for (const key in keysForMenu) {
      if (Object.hasOwnProperty.call(keysForMenu, key)) {
        const featureName = keysForMenu[key].feature;
        if (features && features.hasOwnProperty(featureName)) {
          tempList.push(keysForMenu[key]);
        }
      }
    }
    // for submenu
    for (const key in childrenKeys) {
      if (Object.hasOwnProperty.call(childrenKeys, key)) {
        const featureName = childrenKeys[key].feature;
        // Exclude specific keys for SUPER_ADMIN
        if (
          userRoleName === "SUPER ADMIN" &&
          ["doctor", "availability", "generateQr", "Discounts"].includes(key)
        ) {
          continue;
        }

        if ((features && features.hasOwnProperty(featureName)) || featureName === "default") {
          allowedChildrenkeys.push(childrenKeys[key]);
        }
      }
    }
    if (allowedChildrenkeys.length > 0) {
      const entityElement = tempList.find((el) => el.id === "DoctorEntities");
      entityElement.url = allowedChildrenkeys[0].url;
    }
    setList(tempList);
  };

  useEffect(() => {
    handleList(features);
  }, [features && Object.keys(features).length]);

  const path = location.pathname;

  const [filteredList, setFilteredList] = useState([]);
  const [profileObject, setprofileObject] = useState([]);

  const main = "main";
  const mainResponsiveShrink = "main responsive-shrink";
  const mainShrink = "main shrink";
  const mainSubSidebar = "main sub-sidebar";
  const toggleShrink = () => {
    setIsSidebarShrinked(true);
    toggleMainClass((prevClass) => {
      if (
        prevClass === mainResponsiveShrink ||
        prevClass === mainShrink ||
        prevClass === mainSubSidebar
      ) {
        return main;
      } else {
        if (width <= 768) {
          return mainResponsiveShrink;
        } else {
          return mainShrink;
        }
      }
    });
  };

  const handleItemClick = (event, item) => {
    event.preventDefault();
    event.stopPropagation();
    if (item.children) {
      setSecondarySidebarData(item);
      toggleSecondaryBar(true);
    }
    if (item.title !== "Settings") {
      setRouteBeforeSettingsSection("");
    }
    navigateToRoute(item.url, { state: item.state });
  };

  // Filter the list based on the roleId
  useEffect(() => {
    if (features && list && list.length > 0) {
      const filteredList = DashboardRoutes(
        features,
        userOrgId,
        currentActiveUser(),
        userRoleName,
        list.slice()
      );
      setFilteredList(filteredList);
      setprofileObject(profileDashboard(features, userOrgId, currentActiveUser(), userRoleName));
    }
  }, [userOrgId, userRoleName, list]);

  const handleLogout = async () => {
    dispatch({ type: "RESET" });
    localStorage.clear();
    navigate("/");
  };

  //   useEffect(() => {
  //     for (let i = 0; i < filteredList.length; i++) {
  //       const mainItem = filteredList.at(i);
  //       if (path.indexOf(mainItem.url) === 0 && path.length > mainItem.url.length) {
  //         setSecondarySidebarData(mainItem);
  //         toggleSecondaryBar(true);
  //         toggleMainClass((prevClass) => (prevClass === main ? mainSubSidebar : main));
  //         break;
  //       }
  //     }
  //   }, []);

  useEffect(() => {
    setIsSidebarShrinked(false);
    // if (path && list.length > 0) {
    //   const filteredSidebarData = list.find((item) => item.title === "Settings");
    //   const isOnSettings = filteredSidebarData?.children.some((child) => child.url === path);

    //   if (isOnSettings) {
    //     setSettingsIconChecked(true);
    //   } else {
    //     dispatch(setRenderSidebar(false));
    //     setSettingsIconChecked(false);
    //   }
    // }
  }, [path]);

  const navigateToRoute = (url, state) => {
    navigate(url, state);
  };

  const navigateToProfile = (event) => {
    event.preventDefault();
    setRouteBeforeSettingsSection("");
    if (profileObject.children) {
      setSecondarySidebarData(profileObject);
      toggleSecondaryBar(true);
    }
    navigate(profileObject.url, { state: profileObject.state });
  };

  const getActiveClass = (item) => {
    // if (item && item.children && item.children.length > 0) {
    //   if (
    //     item.id === "Profile" &&
    //     item.children?.find((child) => child.url === path || pathMapper[child.id]?.includes(path))
    //   ) {
    //     if (!isSidebarShrinked) {
    //       setSecondarySidebarData(item);
    //       toggleMainClass("main sub-sidebar");
    //     }
    //     return "active";
    //   } else if (
    //     item.id === "DoctorEntities" &&
    //     item.children?.find((child) => child.url === path)
    //   ) {
    //     if (!isSidebarShrinked) {
    //       setSecondarySidebarData(item);
    //       toggleMainClass("main sub-sidebar");
    //     }
    //     return "active";
    //   } else if (
    //     item.id === "AbdmFacilities" &&
    //     item.children?.find((child) => child.url === path)
    //   ) {
    //     if (!isSidebarShrinked) {
    //       setSecondarySidebarData(item);
    //       toggleMainClass("main sub-sidebar");
    //     }
    //     return "active";
    //   }
    // }
    if (pathMapper[item.id] && pathMapper[item.id].includes(path)) {
      //   if (!isSidebarShrinked) {
      // toggleMainClass(main);
      //   }
      return "active";
    }
    if (item && path === item.url) {
      //   if (!isSidebarShrinked) {
      // toggleMainClass(main);
      //   }
      return "active";
    }
    return "";
  };

  const CustomizedToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#29bf91",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      height: "2rem",
      fontSize: "1rem",
      textAlign: "center",
      color: "#fff",
      border: "0.5px solid #29bf91",
      backgroundColor: "#29bf91",
      display: "flex",
      alignItems: "center",
      padding: "8px",
    },
  }));

  const renderSidebarData = useSelector(getRenderSidebar);
  useEffect(() => {
    if (renderSidebarData?.isRender && renderSidebarData?.isSettings && list.length > 0) {
      const filteredSidebarData = list.find((item) => item.title === "Settings");
      setSidebarList(filteredSidebarData);
      //   navigate(filteredSidebarData.url);
    } else if (renderSidebarData?.isRender && !renderSidebarData?.isSettings) {
      const profileObj = profileDashboard(features, userOrgId, currentActiveUser(), userRoleName);
      setSidebarList(profileObj);
    } else if (renderSidebarData?.isRender === false) {
      setSidebarList(null);
      setInfoPage(null);
    }
  }, [renderSidebarData, list]);

  useEffect(() => {
    if (sidebarList) {
      const InfoPage = sidebarList.children.find((item) => item.id === "InfoPage");
      setInfoPage(InfoPage);
    } else {
      setInfoPage(null);
    }
  }, [sidebarList]);

  return (
    <>
      {sidebarList && (
        <div className={`new-sidebar ${sidebarList && "open"}`} id="sidebar">
          {/* <div className="branding">
                <div className="row justify-content-space-between align-items-center">
                <div className="col" style={{ display: "flex", alignItems: "center" }}>
                    <img
                    src="/images/arog-icon.svg"
                    alt="logo"
                    className="img-fluid"
                    height="45px"
                    width="45px"
                    />
                    <img
                    src="/images/arog-logo-text.svg"
                    alt="logo"
                    className="img-fluid logo-text mt-2"
                    height="30px"
                    width="80px"
                    />
                </div>
                <div className="col">
                    <CustomButton
                    onClick={() => {
                        toggleShrink();
                    }}
                    iconButton={
                        <img src="/images/menu-open-close.svg" alt="menu-icon" className="menu-icon" />
                    }
                    />
                </div>
                </div>
            </div> */}
          <div className="branding">
            <div className="row justify-content-space-between align-items-center">
              <div className="col">
                <Typography
                  variant="h2"
                  sx={{
                    color: "#ffffff",
                    fontSize: { sm: "13px", md: "14px", lg: "15px", xl: "16px" },
                  }}
                >
                  {sidebarList.title}
                </Typography>
              </div>
            </div>
          </div>
          <Divider variant="middle" />
          <div className="main-navbar">
            <div className="header">
              <nav className="custom-navbar-ul">
                {sidebarList?.children?.map(
                  (item) =>
                    item.id !== "InfoPage" && (
                      <React.Fragment key={item.id}>
                        <CustomizedToolTip
                          key={item.id}
                          title={item.isOffline ? "You are offline" : item.title}
                          disableHoverListener={!item.isOffline}
                          disableFocusListener={!item.isOffline}
                          placement="right"
                          arrow
                        >
                          <a
                            style={{ textDecoration: "none  " }}
                            href={item.url}
                            onClick={(event) => {
                              if (!item.isOffline) {
                                handleItemClick(event, item);
                              }
                            }}
                            key={item.id}
                            className={`cursor-pointer custom-nav-list ${getActiveClass(item)} ${
                              item.isOffline ? "disabled" : ""
                            }`}
                          >
                            {/* have to change this condition when all the Icons are present, */}
                            {/* Commenting it for now */}
                            {/* {item.iconClass ? (
                              typeof item.iconClass === "string" ? (
                                <i className={item.iconClass} />
                              ) : (
                                <div className="img-icon">{item.iconClass}</div>
                              )
                            ) : item.iconSrc ? (
                              typeof item.iconSrc === "string" ? (
                                <img src={item.iconSrc} alt={item.title} className="img-icon" />
                              ) : (
                                <div className="img-icon">{item.iconSrc}</div>
                              )
                            ) : null} */}
                            <Box
                              component={"span"}
                              sx={{
                                marginLeft: "0.5rem",
                                fontSize: { sm: "12px", md: "13px", lg: "14px", xl: "15px" },
                              }}
                            >
                              {item.title}
                            </Box>
                          </a>
                        </CustomizedToolTip>
                      </React.Fragment>
                    )
                )}
              </nav>
            </div>
            <div className="bottom-links">
              <nav className="custom-navbar-ul">
                {infoPage && (
                  <a
                    style={{ textDecoration: "none" }}
                    href={infoPage.url}
                    key={infoPage.id}
                    className={`custom-nav-list ${
                      location.pathname === infoPage.url ? "active" : ""
                    }`}
                    onClick={(event) => {
                      event.preventDefault();
                      navigateToRoute(infoPage.url, {
                        state: { ...infoPage.state, data: sidebarList },
                      });
                    }}
                  >
                    <Box
                      component={"span"}
                      sx={{
                        marginLeft: "0.5rem",
                        fontSize: { sm: "12px", md: "13px", lg: "14px", xl: "15px" },
                      }}
                    >
                      {infoPage?.title}
                    </Box>
                    <img
                      src="/images/hc_double_arrows.svg"
                      className="img-double-arrow"
                      alt="img-double-arrow"
                    />
                  </a>
                )}

                <li
                  onClick={handleLogout}
                  className={`custom-nav-list ${path === "settings.html" ? "active" : ""}`}
                >
                  {/* <i className="ri-logout-circle-line ri-xl" /> */}
                  <Box
                    component={"span"}
                    sx={{
                      marginLeft: "0.5rem",
                      fontSize: { sm: "12px", md: "13px", lg: "14px", xl: "15px" },
                    }}
                  >
                    Logout
                  </Box>
                </li>
              </nav>
            </div>
            {/* <div className="bottom-links">
                <nav className="custom-navbar-ul">
                    <CustomizedToolTip
                    title="Profile"
                    disableHoverListener={!(mainClass === mainShrink || mainClass === mainSubSidebar)}
                    disableFocusListener={!(mainClass === mainShrink || mainClass === mainSubSidebar)}
                    placement="right"
                    arrow
                    >
                    <a
                        style={{ textDecoration: "none  " }}
                        href={profileObject.url}
                        onClick={(event) => {
                        navigateToProfile(event);
                        }}
                        className={`custom-nav-list ${getActiveClass(profileObject)}`}
                    >
                        <i className="ri-profile-line ri-xl" />
                        <span style={{ marginLeft: "0.5rem" }}>{profileObject?.title}</span>
                    </a>
                    </CustomizedToolTip>
                    <CustomizedToolTip
                    title="Logout"
                    disableHoverListener={mainClass !== mainShrink}
                    disableFocusListener={mainClass !== mainShrink}
                    placement="right"
                    arrow
                    >
                    <li
                        onClick={handleLogout}
                        className={`custom-nav-list ${path === "settings.html" ? "active" : ""}`}
                    >
                        <i className="ri-logout-circle-line ri-xl" />
                        <span style={{ marginLeft: "0.5rem" }}>Logout</span>
                    </li>
                    </CustomizedToolTip>
                </nav>
                </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default NewPrimarySidebar;
