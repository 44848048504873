import React from "react";
import "../../assets/scss/style.scss";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AppointmentListItem from "./AppointmentListItem";
import CustomButton from "./CustomButton";
import { useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useEffect } from "react";
import Reveal from "views/utilities/Reveal";
import { currentActiveUser } from "store/constant";

const CustomAppointmentCard = ({
  activeTab,
  handleFilter,
  appointmentsMenuLabel,
  handleAppointmentsMenu,
}) => {
  const [selectedList, setSelectedList] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const appointmentsMenu = [{ label: "All" }, { label: "Today" }, { label: "Tomorrow" }];
  const userRole = currentActiveUser()?.roleName;

  useEffect(() => {
    const updatedList = handleFilter(appointmentsMenuLabel);
    setSelectedList([...updatedList]);
  }, [appointmentsMenuLabel, activeTab, isUpdated]);

  return (
    <div
      className="mui-tabs__pane mui--is-active "
      //   style="overflow: auto;"
      id="pane-default-1"
    >
      <div className="mui-card custom-card-ui">
        <Reveal className="row justify-content-space-between align-items-center">
          <div className="col card-title">
            {userRole !== "DOCTOR"
              ? "All Appointments"
              : selectedList.length === 1
              ? "My Appointment"
              : "My Appointments"}
          </div>
          <div className="col filters">
            <ul className="horizontal-ul">
              <li className="main-actions">
                <CustomButton
                  label={appointmentsMenuLabel}
                  style={{ marginLeft: "auto" }}
                  className={"btn--secondary-light"}
                  startIcon={<CalendarMonthIcon />}
                  endIcon={<i className="ri-arrow-down-s-fill" />}
                  dropDownMenuItems={appointmentsMenu}
                  onMenuItemClick={handleAppointmentsMenu}
                  menuItemHoverColor="#29BF91"
                />
              </li>
            </ul>
          </div>
        </Reveal>
        <Grid container className="mx-0">
          <Grid size={{ lg: 12 }} className="appointment-list px-0">
            {selectedList.length === 0 ? (
              <Typography>No appointments</Typography>
            ) : (
              <ul className="appointments">
                {selectedList.map((item, index) => (
                  <AppointmentListItem appointment={item} key={index} setIsUpdated={setIsUpdated} />
                ))}
              </ul>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CustomAppointmentCard;
