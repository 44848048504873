import React from "react";
import { Offline } from "react-detect-offline";
import "../src/assets/scss/OfflineAlert.scss";
import CustomButton from "ui-component/custom-components/CustomButton";

const OfflineAlert = () => {
  const handleRetry = () => {
    window.location.reload();
  };
  return (
    <Offline>
      <div className="offline-container">
        <h1 className="offline-message">You are offline! Please check your internet connection.</h1>
        <CustomButton label={"Retry"} className="retry-button" onClick={handleRetry} />
      </div>
    </Offline>
  );
};

export default OfflineAlert;
