import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { getLatestVitals } from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/patientVitalSlice";
import { getColorAndBackgroundColor } from "utils/calculate-vital-color";
import "assets/scss/DoctorHomeScreen/PatientPrescriptionDetails/patient-prescription-vitals.scss";
import ComponentLoader from "ui-component/custom-components/ComponentLoader";

const vitalKeyToDisplayNameMap = {
  BLOOD_GLUCOSE: "Glucose",
  BLOOD_PRESSURE: "BP",
  BLOOD_OXYGEN: "Oxygen",
  BODY_TEMPERATURE: "Temp",
  HEART_RATE: "Heart-rate",
  HEIGHT: "Height",
  WEIGHT: "Weight",
  BODY_MASS_INDEX: "BMI",
};

const PatientPrescriptionVitalsCV1 = () => {
  const latestVitals = useSelector(getLatestVitals);

  const primaryVitalList = [
    "BLOOD_OXYGEN",
    "HEART_RATE",
    "BLOOD_GLUCOSE",
    "BODY_TEMPERATURE",
    "BLOOD_PRESSURE",
  ];

  const secondaryVitalList = ["HEIGHT", "WEIGHT", "BODY_MASS_INDEX"];

  const renderVitals = (vitalList) => {
    return latestVitals
      ?.filter((vital) => vitalList.includes(vital?.key))
      .map((vital, index) => {
        const vitalColorAndBgColor = getColorAndBackgroundColor(vital?.latestValue?.severity);
        return (
          // <Tooltip
          //   title={vital?.latestValue?.vitalSignTypeResponse?.unitAbbreviation || "kg/m2"}
          //   key={index}
          // >
          <div
            key={vital?.key}
            className="patient-vital-item-container"
            style={{
              display: "flex",
              "&:hover": {
                backgroundColor: "#e6eef1",
              },
            }}
          >
            {index !== 0 && (
              <Divider className="patient-vital-divider" orientation="vertical" flexItem />
            )}
            <div className="patient-vital-item">
              <div style={{ whiteSpace: "nowrap" }}>{vitalKeyToDisplayNameMap[vital?.key]}</div>
              <div style={{ color: vitalColorAndBgColor?.color }}>
                {`${vital?.latestValue?.value}${
                  vital?.latestValue?.vitalSignTypeResponse?.unitAbbreviation || "kg/m2"
                }`}
              </div>
            </div>
          </div>
          // </Tooltip>
        );
      });
  };

  return (
    <>
      <div className="patient-prescription-vitals common-scrollbar common-scrollbar-x">
        {latestVitals ? (
          <>
            {latestVitals?.length > 0 ? (
              <div
                style={{
                  justifyContent: "end",
                }}
              >
                <div className="patient-primary-vitals">{renderVitals(primaryVitalList)}</div>
                <div className="patient-secondary-vitals-container">
                  <div className="patient-secondary-vitals">{renderVitals(secondaryVitalList)}</div>
                </div>
              </div>
            ) : (
              <div className="no-patient-vitals">No Patient Vitals Found</div>
            )}
          </>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <ComponentLoader></ComponentLoader>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PatientPrescriptionVitalsCV1;
