import React from "react";

export const responseFormatter = (response) => {
  return response.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line.trim()}
      <br />
    </React.Fragment>
  ));
};
