import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router";
import CustomButton from "ui-component/custom-components/CustomButton";
import { KeyboardDoubleArrowLeft } from "@mui/icons-material";
import { Box } from "@mui/system";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      backgroundColor={"#eff7fa"}
      height={"100vh"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      padding={"20px"}
    >
      <Grid
        item
        display={"flex"}
        alignItems={"center"}
        style={{
          maxWidth: "906px",
          minHeight: "392px",
          borderRadius: "12px",
          top: "38600px",
          left: "2455px",
          padding: "20px",
          gap: "20px",
          backgroundColor: "#ffffff",
        }}
      >
        <Grid size={{ xs: 12 }}>
          <img
            src="/images/img-error-bg-dark.svg"
            alt="Page not found"
            maxHeight="352px"
            maxWidth="352px"
          />
        </Grid>
        <Grid size={{ xs: 12 }} maxWidth={"494px"} maxHeight={"215px"}>
          <div
            style={{
              maxWidth: "494px",
              minHeight: "157.5px",
              top: "88.5px",
              left: "392px",
            }}
          >
            <div
              style={{
                maxWidth: "234px",
                minHeight: "105px",
                top: "88.5px",
                left: "392px",
                gap: "0px",
                opacity: "0px",
              }}
            >
              <Typography fontWeight={600} fontSize={"40px"} lineHeight={"60px"} color={"#455a64"}>
                Oops,
              </Typography>
              <Typography fontWeight={600} fontSize={"32px"} lineHeight={"48px"} color={"#29BF91"}>
                nothing <span style={{ color: "#455a64" }}>here...</span>
              </Typography>
            </div>
            <div
              style={{
                maxWidth: "494px",
                minHeight: "42px",
                top: "204px",
                left: "392px",
              }}
            >
              <Typography
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"21px"}
                color={"#455a64"}
                margin={"20px 0"}
              >
                We can't find the page. The link you followed may be broken, the page have been
                removed or moved to a new address.
              </Typography>
            </div>
          </div>

          <div
            style={{
              maxWidth: "139px",
              maxHeight: "34px",
              top: "269.5px",
              left: "392px",
              gap: "12px",
            }}
          >
            <CustomButton
              variant="contained"
              size="large"
              className={"btn--primary"}
              onClick={() => {
                navigate("/home/dashboard");
              }}
              startIcon={<KeyboardDoubleArrowLeft />}
              label={"Back To Home"}
            ></CustomButton>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ErrorPage;
