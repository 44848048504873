import { api as API } from "./AxiosInterceptor";
import { getUUID } from "../store/constant";

export const medicationReminderNotify = async (data) => {
  data["id"] = getUUID();
  return API.post(`/api/medication-reminders`, data);
};

export const medicationReminderNotifyOff = async (medicationId) => {
  API.delete(`/api/medication-reminders/${medicationId}`);
};

export const getMedicationReminderByUserId = async (userId) => {
  return await API.get(`/api/medication-reminders/user/${userId}`);
};

export const updateMedicationReminder = async (medicationId, data) => {
  return await API.put(`/api/medication-reminders/${medicationId}`, data);
};
