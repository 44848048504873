import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { DATE_TIME_FORMAT } from "store/constant";

const initialState = {
  selectedAppointment: {
    value: undefined,
    timestamp: dayjs().format(DATE_TIME_FORMAT),
  },
  cancelledAppointment: null,
  todaysAppointments: [],
  appointmentsCounts: null,
  collapseList: false,
};

const appointmentSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    setSelectedAppointmentOnDashboard: (state, action) => {
      state.selectedAppointment = {
        value: action.payload,
        timestamp: dayjs().format(DATE_TIME_FORMAT),
      };
    },
    setTodaysAppointments: (state, action) => {
      state.todaysAppointments = action.payload;
    },
    setCancelledAppointment: (state, action) => {
      state.cancelledAppointment = action.payload;
    },
    setAppointmentsCounts: (state, action) => {
      state.appointmentsCounts = action.payload;
    },
    setCollapseList: (state, action) => {
      state.collapseList = action.payload;
    },
  },
});

export const {
  setSelectedAppointmentOnDashboard,
  setAppointmentsCounts,
  setTodaysAppointments,
  setCancelledAppointment,
  setCollapseList,
} = appointmentSlice.actions;

export const getSelectedAppointmentOnDashboard = (state) =>
  state.doctorDashboard.appointments.selectedAppointment;
export const getTodaysAppointments = (state) =>
  state.doctorDashboard.appointments.todaysAppointments;
export const getCancelledAppointment = (state) =>
  state.doctorDashboard.appointments.cancelledAppointment;
export const getAppointmentsCounts = (state) =>
  state.doctorDashboard.appointments.appointmentsCounts;
export const getCollapseList = (state) => state.doctorDashboard.appointments.collapseList;

export const AppointmentsReducer = appointmentSlice.reducer;
