import UserSubscription from "views/Components/Package/user-subscription/UserSubscriptions";
import { NavBar } from "./DoctorOnboarding/DoctorOnboardingPage";

const DoctorOnboardingSubscription = () => {
  return (
    <div className="doctor-onboarding">
      <div className="inner-doctor-onboarding">
        <div className="p-2">
          <NavBar></NavBar>
        </div>
        <div className="container">
          <UserSubscription isOnboarding={true}></UserSubscription>
        </div>
      </div>
    </div>
  );
};

export default DoctorOnboardingSubscription;
