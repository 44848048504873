import { HeadersForAccessTokenAPI } from "store/constant";
import { api as API } from "./AxiosInterceptor";
import { getUUID } from "../store/constant";

export const getAllUsers = () => API.get(`api/users/global`);

export const createUserProfile = (data) => {
  data["id"] = getUUID();
  data["roleBasedId"] = getUUID();
  return API.post(`/api/users/register`, data);
};

// user register api for patients

export const createPatientProfile = (data) => {
  data["id"] = getUUID();
  data["roleBasedId"] = getUUID();
  return API.post(`/api/users/register/global`, data);
};

export const createUserProfileOnboard = (data) => {
  return API.post(`/hc-auth/onboard`, data);
};

export const registerOrganizationInTenant = (data) => {
  return API.post(`/hc-auth/onboarding-details`, data);
};

export const createUserFamilyProfile = (data, userId) => {
  data["id"] = getUUID();
  return API.post(`/api/users/${userId}/family-member/register`, data);
};
export const linkExistingUserAsFamilyMember = (primaryUserId, data) => {
  data["id"] = getUUID();
  return API.post(`/api/users/${primaryUserId}/family-member/link`, data);
};
export const updateFamilyMemberRelation = (id, data) =>
  API.put(`/api/users/${id}/family-member`, data);
export const unlinkFamilyMember = (userId, familyId) =>
  API.put(`/api/users/${userId}/family-member/unlink?familyId=${familyId}`);

export const updateUserProfile = (userId, data) => API.put(`/api/users/${userId}`, data);
export const deleteUser = (userId) => API.put(`/api/users/delete/${userId}`);
export const getUserByMobile = (mobileNumber) =>
  API.get(`/api/users/mobile?mobileNumber=${mobileNumber}`);
export const getUserByEmail = (email) => API.get(`/api/users/email?email=${email}`);
export const getUserProfiles = (id) =>
  API.get(`/api/family-members/patient-user-id?patientUserId=${id}`);
export const getUserById = (id) => API.get(`/api/users/${id}`);
export const deleteFamilyMemberById = (id) => API.put(`/api/users/delete/family-member/${id}`);

export const linkUserWithOrganization = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/organizations/link-user`, data);
};

export const updateLinkUserOrg = (id, data) =>
  API.put(`/api/organizations/${id}/update-association`, data);

export const deleteUserOrgLink = (id) => API.delete(`/api/organizations/${id}/delete-association`);

export const getPrimaryUserByMobileNumber = (mobileNumber, disabledTenant = false) =>
  API.get(`/api/users/primary/mobile?mobileNumber=${mobileNumber}`, { disabledTenant });

export const getPrimaryUserByUsername = (username) =>
  API.get(`/api/users/primary/username?username=${username}`);

export const updateUserPreferences = (id, data) => API.put(`api/users/preferences/${id}`, data);

export const updateGlobalUserPreferences = (id, data) =>
  API.put(`api/users/preferences/global/${id}`, data);
