import {
  CodeOutlined,
  DomainOutlined,
  FiberPinOutlined,
  HomeOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { FormControl } from "@mui/material";
import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";
import { useRef } from "react";
import { fetchLocationByPincode, searchByPincode } from "services/organizationService";
import { DATE_FORMAT } from "store/constant";
import SubCard from "ui-component/cards/SubCard";
import CustomAutoComplete from "ui-component/custom-components/CustomAutoComplete";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import Reveal from "views/utilities/Reveal";

const OrgBasicDetails = ({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  setValues,
  setDobError,
  setErrors,
  setTouched,
  dobError,
  pincodeError,
  setPincodeError,
  selectedFields,
}) => {
  // const [districts, setDistricts] = useState([]);

  const districtRef = useRef(null);
  // const subDistrictRef = useRef(null);

  // const [subDistricts, setSubDistricts] = useState([]);
  const currentDate = dayjs();

  const handlePincodeChange = async (e) => {
    const { value } = e.target;
    setPincodeError("");
    if (value.length === 6) {
      const response = await fetchLocationByPincode(value);
      if (response.data.length !== 0 && response.data[0].id) {
        const stateOb = {
          name: response.data[0].stateName.toUpperCase(),
          code: response.data[0].stateCode,
        };

        const districtOb = {
          name: response.data[0].districtName.toUpperCase(),
          code: response.data[0].districtCode,
        };

        setFieldValue("adState", stateOb);
        setFieldValue("adDistrict", districtOb);
      } else {
        setFieldValue("adState", null);
        setFieldValue("adDistrict", null);
        setTouched({ ...touched, adPincode: true });
        setPincodeError("Please enter valid pincode");
      }
    }
    setFieldValue("adPincode", value);
  };

  // useEffect(async() => {
  //   const fetchDistrict = () => {
  //     if (!values.name) return;
  //     try {
  //       const response = await getSubDistrictByDistrict(values?.adDistrict?.code);
  //       setSubDistricts(response.data);
  //       setDistricts(values?.adState?.districts);
  //     } catch (error) {
  //       console.error("Error fetching sub-districts:", error);
  //     }
  //   };

  //   fetchDistrict();
  // }, [values?.adDistrict?.code]);

  return (
    <Reveal>
      <SubCard style={{ padding: "15px" }}>
        <Grid>
          {selectedFields?.includes("name") && (
            <FormInputField
              style={{ width: "30%", marginRight: "3.33%", marginBottom: "25px" }}
              label="Name"
              name="name"
              required
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(touched.name && errors.name)}
              errorText={errors.name}
              startAdornment={<DomainOutlined />}
              size={"big"}
            />
          )}

          {/* {selectedFields?.includes("code") && (
            <FormInputField
              style={{ width: "30%", marginRight: "3.33%", marginBottom: "25px" }}
              label="Code"
              name="code"
              required
              value={values.code}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(touched.code && errors.code)}
              errorText={errors.code}
              startAdornment={<CodeOutlined />}
              size={"big"}
            />
          )} */}

          {/* <FormInputField
            style={{ width: "30%", marginBottom: "25px" }}
            label="Status"
            readOnly={true}
            name="status"
            value={values.status}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.status && errors.status)}
            errorText={errors.status}
            startAdornment={<PauseCircleOutlineOutlined />}
            size={"big"}
          /> */}

          {selectedFields?.includes("since") && (
            <FormDatePicker
              minDate={dayjs("1800-01-01")}
              disableFuture
              label={"Since When: (Eg. 1991) *"}
              value={values.since_when}
              size={"big"}
              views={["year"]}
              openTo="year"
              required
              setTools
              onChange={(date) => {
                const newDate = dayjs(date).format(DATE_FORMAT);
                setFieldValue("since_when", newDate);
                if (date === null) {
                  setDobError("Please select a year.");
                } else if (!date.isValid()) {
                  setDobError("Please select valid 'Year' value.");
                } else if (date > currentDate) {
                  setDobError("Year cannot be in future.");
                } else {
                  setDobError("");
                }
              }}
              style={{
                width: "30%",
                marginBottom: "25px",
                marginRight: "3.33%",
              }}
              error={Boolean(dobError && touched.since_when)}
              errorText={dobError}
            ></FormDatePicker>
          )}

          {selectedFields?.includes("address") && (
            <FormInputField
              style={{ width: "30%", marginRight: "3.33%", marginBottom: "25px" }}
              label="Address"
              name="address"
              required
              value={values.address}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(touched.address && errors.address)}
              errorText={errors.address}
              startAdornment={<HomeOutlined />}
              size={"big"}
            />
          )}

          {selectedFields?.includes("pincode") && (
            <FormInputField
              style={{ width: "30%", marginBottom: "25px", marginRight: "3.33%" }}
              label="Pincode"
              name="adPincode"
              required
              value={values.adPincode}
              inputProps={{
                maxLength: 6,
              }}
              type={"tel"}
              onBlur={handleBlur}
              onChange={handlePincodeChange}
              error={Boolean(touched.adPincode && (errors.adPincode || pincodeError))}
              errorText={errors.adPincode || pincodeError}
              startAdornment={<FiberPinOutlined />}
              size={"big"}
            />
          )}

          {!(errors?.adPincode || pincodeError) && values.adState && (
            <FormControl
              style={{
                width: "30%",
                marginBottom: "30px",
                marginRight: "3.33%",
              }}
            >
              <CustomAutoComplete
                options={[]}
                label={"State"}
                required
                readOnly={true}
                placeholder="Select"
                iconStart={<HomeOutlined />}
                getOptionLabel={(option) => option?.name}
                disabled
                // onChange={async (event, newValue) => {
                //   if (newValue) {
                //     setValues({
                //       ...values,
                //       adState: newValue,
                //       adDistrict: null,
                //       adSubDistrict: null,
                //     });
                //     setDistricts(newValue.districts);
                //     districtRef.current.focus();
                //   } else if (newValue === null) {
                //     setValues({
                //       ...values,
                //       adState: newValue,
                //       adDistrict: newValue,
                //       adSubDistrict: newValue,
                //     });
                //   }
                // }}
                value={values.adState}
                error={Boolean(touched.adState && errors.adState)}
                errorText={errors.adState}
              />
            </FormControl>
          )}
          {!(errors?.adPincode || pincodeError) && values.adDistrict && (
            <FormControl
              style={{
                width: "30%",
                marginBottom: "30px",
                marginRight: "3.33%",
              }}
            >
              <CustomAutoComplete
                options={[]}
                // readOnly={values.adState ? false : true}
                readOnly={true}
                label={"District"}
                placeholder="Select"
                required
                iconStart={<HomeOutlined />}
                getOptionLabel={(option) => option?.name}
                disabled
                // onChange={async (event, newValue) => {
                //   if (newValue) {
                //     setValues({
                //       ...values,
                //       adDistrict: newValue,
                //       adSubDistrict: null,
                //     });
                //     const response = await getSubDistrictByDistrict(newValue.code);
                //     setSubDistricts(response.data);
                //     subDistrictRef.current.focus();
                //   } else if (newValue === null) {
                //     setValues({
                //       ...values,
                //       adDistrict: newValue,
                //       adSubDistrict: newValue,
                //     });
                //   }
                // }}
                openOnFocus={true}
                value={values.adDistrict}
                error={Boolean(touched.adDistrict && errors.adDistrict)}
                errorText={errors.adDistrict}
                inputRef={districtRef}
              />
            </FormControl>
          )}

          {/* <FormControl style={{ width: "30%", marginBottom: "30px", marginRight: "3.33%" }}>
            <CustomAutoComplete
              options={subDistricts}
              readOnly={values.adDistrict ? false : true}
              label={"Sub-District"}
              placeholder="Select"
              required
              iconStart={<HomeOutlined />}
              getOptionLabel={(option) => option?.name}
              onChange={async (event, newValue) => {
                setFieldValue("adSubDistrict", newValue);
              }}
              value={values.adSubDistrict}
              openOnFocus={true}
              error={Boolean(touched.adSubDistrict && errors.adSubDistrict)}
              errorText={errors.adSubDistrict}
              inputRef={subDistrictRef}
            />
          </FormControl> */}

          {selectedFields?.includes("personName") && (
            <FormInputField
              style={{ width: "30%", marginRight: "3.33%" }}
              label="Contact Person Name"
              name="contactName"
              required
              value={values.contactName}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(touched.contactName && errors.contactName)}
              errorText={errors.contactName}
              startAdornment={<PersonOutlineOutlined />}
              size={"big"}
            />
          )}

          {selectedFields?.includes("personMobile") && (
            <FormInputField
              style={{ width: "30%", marginRight: "3.33%" }}
              label="Contact Person Mobile No"
              name="contactMobile"
              required
              type={"tel"}
              value={values.contactMobile}
              onBlur={handleBlur}
              inputProps={{
                maxLength: 10,
              }}
              onChange={handleChange}
              error={Boolean(touched.contactMobile && errors.contactMobile)}
              errorText={errors.contactMobile}
              startAdornment={<i className="ri-phone-line ri-xl" />}
              size={"big"}
            />
          )}

          {selectedFields?.includes("personEmail") && (
            <FormInputField
              style={{ width: "30%", marginRight: "3.33%" }}
              label="Contact Person Email"
              name="contactEmail"
              required
              value={values.contactEmail}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(touched.contactEmail && errors.contactEmail)}
              errorText={errors.contactEmail}
              startAdornment={<i className="ri-mail-line ri-lg" />}
              size={"big"}
            />
          )}
        </Grid>
      </SubCard>
    </Reveal>
  );
};

export default OrgBasicDetails;
