import { NavigateBefore } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import { getContentById, getPosterForCampaign } from "services/ContentService";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";

const ViewContent = () => {
  const { handleClick } = useContext(ToastContext);
  const [content, setContent] = useState({});
  const state = useLocation();
  const contentId = state?.state?.contentId;
  const navigate = useNavigate();
  const [posterUrl, setPosterUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const contentById = await getContentById(contentId);
        setContent(contentById?.data);
      } catch (e) {
        console.error("Can not fetch content");
      }
    };

    fetchData();
  }, [handleClick, contentId]);

  useEffect(() => {
    const fetchPoster = async () => {
      try {
        const poster = await getPosterForCampaign(content?.poster);
        const posterContent = `data:image/${poster?.data.extension};base64,${poster.data.document}`;
        const posterWithContent = {
          filename: poster.data.filename,
          content: posterContent,
        };
        setPosterUrl(posterWithContent);
      } catch (e) {
        console.error("Can not fetch poster for campaign");
      }
    };

    if (content?.poster) {
      fetchPoster();
    }
  }, [handleClick, content?.poster]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid sx={{ ml: 2, mr: 2, mt: 2 }}>
          <CustomButton
            label={"Back"}
            startIcon={<NavigateBefore />}
            className={"btn--primary"}
            onClick={() => {
              navigate("/home/health-feeds");
            }}
          />
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <Typography variant="h1" className="icon-primary-blue">
            {content?.title}
          </Typography>
          {content?.poster && (
            <div style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
              <img
                src={posterUrl?.content}
                alt="Poster for campaign"
                style={{ width: "600px", height: "400px" }}
              ></img>
            </div>
          )}
          {content?.description && (
            <Typography style={{ fontSize: "16px", margin: "10px" }}>
              {content?.description}
            </Typography>
          )}

          {content?.reference && (
            <Typography style={{ fontSize: "16px", margin: "10px" }}>
              <b>Article Reference : </b>
              {content?.reference}
            </Typography>
          )}
          {content?.link && (
            <Typography style={{ fontSize: "16px", margin: "10px" }}>
              <b>Link :</b>{" "}
              <a
                href={content?.link}
                rel="noopener noreferrer"
                target="_blank"
                style={{ color: "#007bff", marginLeft: "5px", textDecoration: "none" }}
              >
                {content?.link}
              </a>
            </Typography>
          )}
          {content?.startDate && (
            <Typography style={{ fontSize: "16px", margin: "10px" }}>
              <b>Start Date :</b> {content?.startDate}
            </Typography>
          )}
          {content?.endDate && (
            <Typography style={{ fontSize: "16px", margin: "10px" }}>
              <b>End Date :</b> {content?.endDate}
            </Typography>
          )}
          {content?.actionCode && (
            <Typography style={{ fontSize: "16px", margin: "10px" }}>
              <b>Action Code :</b> {content?.actionCode}
            </Typography>
          )}
          {content?.author && (
            <Typography style={{ fontSize: "16px", margin: "10px" }}>
              <b>Author : </b>
              {content?.author}
            </Typography>
          )}

          {content?.tags && (
            <Typography
              style={{
                fontSize: "16px",
                wordWrap: "break-word",
                overflowWrap: "break-word",
                wordBreak: "break-word",
                color: "#007bff",
              }}
            >
              {content?.tags
                ?.split(",")
                ?.map((tag) => `#${tag.replace(/\s+/g, "")}`)
                ?.join(" ")}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ViewContent;
