import React, { useState, useEffect, useContext, useRef } from "react";
import { Typography, Divider, Autocomplete, TextField, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CustomButton from "ui-component/custom-components/CustomButton";
import Reveal from "views/utilities/Reveal";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import { Formik, Form } from "formik";
import { getAllMedicationManufacturers } from "services/PrescriptionsService";
import {
  getPreferredManufacturerList,
  saveNewPreferredManufacturer,
  removeManufacturerFromList,
} from "services/organizationService";
import { orgId } from "store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const Preference = () => {
  const [selectedManufacturer, setSelectedManufacturer] = useState([]);
  const [allManufacturerList, setAllManufacturerList] = useState([]);
  const [highlightedOption, setHighlightedOption] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [noManufacturerFound, setNoManufacturerFound] = useState(false); // New state
  const [isAutocompleteOpen, setAutocompleteOpen] = useState(false);
  const newlyAddedManufacturersRef = useRef([]);
  const organisationId = orgId();
  const [manufacturers, setManufacturers] = useState([]);
  const { handleClick } = useContext(ToastContext);
  const [isSaveEnabled, setIsSaveEnabled] = React.useState(false);
  const [searchManufacturer, setSearchManufacturer] = useState("");
  const [displayManufacturer, setDisplayManufacturer] = useState([]);

  const clearSearch = () => {
    setSearchManufacturer("");
    setDisplayManufacturer(manufacturers);
  };

  useEffect(() => {
    if (searchManufacturer) {
      const lowerCaseSearch = searchManufacturer.toLocaleLowerCase();

      setDisplayManufacturer(
        manufacturers.filter((s) => {
          const lowerCaseName = s.name?.toLocaleLowerCase();
          return lowerCaseName?.includes(lowerCaseSearch);
        })
      );
    } else {
      setDisplayManufacturer(manufacturers);
    }
  }, [searchManufacturer]);

  // Function to handle selecting a manufacturer from search results
  const handleSelectManufacturer = (event, selectedOption) => {
    if (selectedOption) {
      setSelectedManufacturer((prevSelected) => {
        if (Array.isArray(prevSelected)) {
          return [...prevSelected, selectedOption];
        }
        return [selectedOption];
      });
      setSearchValue(selectedOption.name);
      setAutocompleteOpen(false);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setSearchValue(newInputValue); // Update the search bar value as user types
    setAutocompleteOpen(newInputValue !== "");
  };

  // Add the selected manufacturer to the list
  const handleAddManufacturer = () => {
    if (selectedManufacturer.length > 0) {
      const newManufacturers = selectedManufacturer.map((manufacturer, index) => ({
        srNo: manufacturers.length + index + 1,
        name: manufacturer.name,
        id: manufacturer.id,
        actions: "",
      }));
      setManufacturers([...manufacturers, ...newManufacturers]);
      setDisplayManufacturer([...manufacturers, ...newManufacturers]);
      setSelectedManufacturer([]);
      const manufacturerIds = selectedManufacturer.map((manufacturer) => manufacturer.id);
      newlyAddedManufacturersRef.current = [
        ...(newlyAddedManufacturersRef.current || []),
        ...manufacturerIds,
      ];
      handleSaveToDatabase();
    }
  };

  const fetchAllManufacturers = async () => {
    try {
      const allManufacturersResponse = await getAllMedicationManufacturers();
      setAllManufacturerList(allManufacturersResponse.data);
    } catch (error) {}
  };

  const fetchPreferredManufacturers = async () => {
    try {
      const preferredManufacturersResponse = await getPreferredManufacturerList(organisationId);
      setManufacturers(preferredManufacturersResponse.data);
      setDisplayManufacturer(preferredManufacturersResponse.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllManufacturers();
    fetchPreferredManufacturers();
  }, []);

  // Function to save the updated manufacturers list to the database
  const handleSaveToDatabase = () => {
    try {
      saveNewPreferredManufacturer(organisationId, newlyAddedManufacturersRef.current);
    } catch (error) {
      handleClick("warning", "Error saving manufacturers to the database");
    }
  };

  const handleRemoveManufacturerFromList = async (manufacturerToDelete) => {
    const manufacturerId = manufacturerToDelete; // Get the manufacturer ID from the object
    try {
      const response = await removeManufacturerFromList(organisationId, manufacturerId);
      if (response.status === 204) {
        // Check if the API request was successful
        const updatedManufacturers = manufacturers.filter(
          (manufacturer) => manufacturer.id !== manufacturerId
        );
        setManufacturers(updatedManufacturers); // Update the state to remove the manufacturer
        setDisplayManufacturer(updatedManufacturers);
      } else {
        handleClick("warning", "Failed to delete the manufacturer.");
      }
    } catch (error) {
      handleClick("warning", "Failed to delete the manufacturer.");
    }
  };

  const initialValues = {
    searchManufacturer: "",
  };

  const handleSelectionChange = (event, newValue) => {
    setSelectedManufacturer(Array.isArray(newValue) ? newValue : []);
  };

  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "name", label: "Name" },
    { field: "Actions", label: "Actions" },
  ];

  const actions = [
    {
      label: "Remove",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: (manufacturer) => {
        handleRemoveManufacturerFromList(manufacturer.id);
      },
    },
  ];

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}}>
      {({ values, handleChange, handleBlur, touched, errors, setFieldValue }) => (
        <Form>
          <Reveal>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2 style={{ marginBottom: "15px", fontSize: "25px", marginTop: "15px" }}>
                Manufacturer Preferences
              </h2>
            </div>

            <Divider sx={{ mb: "10px", borderBottomWidth: 2 }} />

            <Grid container spacing={5}>
              <Grid size={{ xs: 5 }}>
                <Typography
                  sx={{
                    fontSize: "19px",
                    fontWeight: 600,
                    color: "#004C70",
                    borderRadius: "20px",
                  }}
                >
                  Search from Saved List
                </Typography>
                <Grid sx={{ mt: 2 }}>
                  <FormInputField
                    style={{ width: "90%" }}
                    label={"Search Treatments"}
                    value={searchManufacturer}
                    startAdornment={<i className="ri-search-line ri-lg" />}
                    endAdornment={
                      searchManufacturer && (
                        <IconButton onClick={clearSearch} edge="end">
                          {<ClearOutlinedIcon fontSize="small" />}
                        </IconButton>
                      )
                    }
                    onChange={(e) => {
                      setSearchManufacturer(e.target.value);
                    }}
                    size="small"
                  ></FormInputField>
                </Grid>
              </Grid>

              <Grid size={{ xs: 7 }}>
                <Typography
                  sx={{
                    fontSize: "19px",
                    fontWeight: 600,
                    color: "#004C70",
                  }}
                >
                  Search any Medicine Manufacturer
                </Typography>
                <Grid container sx={{ mt: 2 }}>
                  <Grid size={{ xs: 8 }} sx={{ mr: 2, width: "80%" }}>
                    <Autocomplete
                      multiple
                      options={allManufacturerList}
                      getOptionLabel={(option) => option.name}
                      value={selectedManufacturer}
                      onChange={handleSelectionChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Search and Select"
                          placeholder="Start typing..."
                          size="small"
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault(); // Prevent default behavior (form submission)
                              if (highlightedOption) {
                                handleSelectManufacturer(event, highlightedOption); // Select the highlighted option
                              } else if (
                                params.inputProps.value &&
                                selectedManufacturer.length > 0
                              ) {
                                // Select the first filtered option when Enter is pressed if no highlighted option
                                handleSelectionChange(event, selectedManufacturer[0]);
                              }
                            }
                          }}
                        />
                      )}
                      filterOptions={(options, { inputValue }) => {
                        // Combine both selectedManufacturer and manufacturers lists into a set of ids to filter out
                        const excludeIds = new Set([
                          ...selectedManufacturer.map((item) => item.id),
                          ...manufacturers.map((item) => item.id),
                        ]);

                        if (inputValue === "") {
                          setHighlightedOption(null);
                          return []; // Return empty array if no input
                        }

                        // Filter options that are not in the excludeIds set
                        const filteredOptions = options.filter(
                          (option) =>
                            !excludeIds.has(option.id) &&
                            option.name.toLowerCase().includes(inputValue.toLowerCase())
                        );

                        if (filteredOptions.length > 0) {
                          setHighlightedOption(filteredOptions[0]);
                        } else {
                          setHighlightedOption(null); // Clear highlighted option if no options match
                        }

                        // Set "noManufacturerFound" state if no options match
                        if (filteredOptions.length === 0) {
                          setNoManufacturerFound(true);
                        } else {
                          setNoManufacturerFound(false);
                        }

                        return filteredOptions;
                      }}
                      noOptionsText={noManufacturerFound ? "Manufacturer not found!" : "No options"}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{
                            backgroundColor:
                              highlightedOption && highlightedOption.id === option.id
                                ? "#ddd"
                                : "transparent",
                          }}
                          onMouseEnter={() => setHighlightedOption(option)} // Update highlight on hover
                        >
                          {option.name}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid sx={{ mt: 0.5 }}>
                    <CustomButton
                      className="btn--primary"
                      disabled={selectedManufacturer.length <= 0}
                      onClick={() => {
                        handleAddManufacturer();
                      }}
                      label="Add"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Display List of Manufacturers*/}
            <Grid
              sx={{
                mt: 4,
                textAlign: "center",
              }}
            ></Grid>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#004C70",
                mb: 1,
              }}
            >
              List of Prefered Manufacturers
            </Typography>
            <CustomizedTable columns={columns} tableData={displayManufacturer} actions={actions} />
          </Reveal>
        </Form>
      )}
    </Formik>
  );
};

export default Preference;
