import { checkQuery } from "services/DashboardService";
import { toCamelCase } from "./toCamelCase";

export const setDashboardDataForApi = (droppableAreas) => {
  const updatedDroppableAreas = droppableAreas.map((area) => ({
    ...area,
    rows: area.rows.map((row) => ({
      ...row,
      columns: row.columns.map((column) => {
        if (column.charts.length === 0) {
          return {
            columnId: column.columnId,
            charts: [],
          };
        } else {
          const { title, queryText, chartType, options } = column.charts[0];

          if (Object.keys(options.params).length !== 0) {
            const keys = Object.keys(options.params).reduce((acc, key) => {
              const value = options.params[key];
              if (value && typeof value === "object" && !Array.isArray(value)) {
                return [...acc, ...Object.keys(value)];
              }
              return [...acc, key];
            }, []);

            options["paramsKeys"] = keys;
          }

          return {
            columnId: column.columnId,
            charts: [
              {
                type: chartType,
                name: title,
                widgetId: column.charts[0].widgetId,
                options,
                query: queryText,
              },
            ],
          };
        }
      }),
    })),
  }));

  return updatedDroppableAreas;
};

export const replaceUnderscores = (value) => {
  return typeof value === "string" ? toCamelCase(value.replace(/_/g, " ")) : value;
};

export const updateDataBasedOnFilterParams = (droppableAreas, widgetId, chartData, newParams) => {
  const chartdata = chartData.map((item) =>
    Object.fromEntries(
      Object.entries(item).map(([key, value]) => [
        replaceUnderscores(key),
        replaceUnderscores(value),
      ])
    )
  );
  const updatedDroppables = droppableAreas.map((area) => ({
    ...area,
    rows: area.rows.map((row) => ({
      ...row,
      columns: row.columns.map((column) => ({
        ...column,
        charts: column.charts.map((chart) =>
          chart.widgetId === widgetId
            ? {
                ...chart,
                queryData: [...chartdata],
                ...(chart.options && {
                  options: { ...chart.options, params: newParams },
                }),
                ...(chart.params && { params: newParams }),
              }
            : chart
        ),
      })),
    })),
  }));

  return updatedDroppables;
};

export const identifyDateRangeVariables = (query) => {
  const dateRangeRegex = /between\s#(\w+)#\sand\s#(\w+)#/i;
  const dateRangeMatch = query.match(dateRangeRegex);

  if (dateRangeMatch) {
    const startDate = dateRangeMatch[1];
    const endDate = dateRangeMatch[2];
    return { startDate, endDate };
  }

  return null;
};

export const initializeDashboardData = async (data) => {
  const updatedAreaRows = await Promise.all(
    data.widgets.rows.map(async (row) => ({
      ...row,
      columns: await Promise.all(
        row.columns.map(async (column) => {
          try {
            if (column.charts.length === 0) {
              return {
                columnId: column.columnId,
                charts: [],
              };
            } else {
              const { name, options, query, type, widgetId } = column.charts[0];

              let queryParams = {};

              if (options && options.params) {
                queryParams = Object.keys(options.params).reduce((acc, filter) => {
                  const value = options.params[filter];
                  if (value && typeof value === "object" && !Array.isArray(value)) {
                    return { ...acc, ...value };
                  }
                  return { ...acc, [filter]: value };
                }, {});

                options.paramsKeys?.forEach((key) => {
                  if (!queryParams.hasOwnProperty(key)) {
                    queryParams[key] = null;
                  }
                });
              }
              const variables = identifyDateRangeVariables(query);
              const startDate = variables?.startDate;
              const endDate = variables?.endDate;

              if (variables?.startDate && !options?.params?.dateRange?.[startDate]) {
                options.params.dateRange = {
                  [startDate]: null,
                  ...options.params.dateRange,
                };
              }

              if (variables?.endDate && !options?.params?.dateRange?.[endDate]) {
                options.params.dateRange = {
                  ...options.params.dateRange,
                  [endDate]: null,
                };
              }

              const { data } = await checkQuery({
                query,
                params: queryParams,
              });

              const alteredData = data.data.map((item) =>
                Object.fromEntries(
                  Object.entries(item).map(([key, value]) => [
                    replaceUnderscores(key),
                    replaceUnderscores(value),
                  ])
                )
              );

              const chartdata = {
                chartType: type,
                title: name,
                queryText: query,
                queryData: alteredData,
                widgetId,
                options,
              };

              return {
                columnId: column.columnId,
                charts: [chartdata],
              };
            }
          } catch (error) {
            return {
              columnId: column.columnId,
              charts: [],
            };
          }
        })
      ),
    }))
  );

  return updatedAreaRows;
};
