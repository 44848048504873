import { createSlice } from "@reduxjs/toolkit";

const appointmentSlice = createSlice({
  name: "appointment",
  initialState: {
    selectedDoctor: null,
  },
  reducers: {
    setSelectedDoctor(state, action) {
      state.selectedDoctor = action.payload;
    },
  },
});

export const { setSelectedDoctor } = appointmentSlice.actions;
export const getSelectedDoctor = (state) => state.appointment.selectedDoctor;
export const appointmentReducer = appointmentSlice.reducer;
