import React, { useState, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import { DateCalendar, PickersDay, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomButton from "./CustomButton";

const CustomDateCalendar = ({
  value,
  onChange,
  onClose,
  blockedDates = [],
  disablePastDates = false,
  maxDate,
  background,
  style,
  highlightedDates = [],
  ...restProps
}) => {
  const [openCal, setOpenCal] = useState();
  const calendarRef = useRef(null);

  const ServerDay = (props) => {
    const { day, outsideCurrentMonth, ...other } = props;

    const isHighlighted = highlightedDates.some((highlightedDay) =>
      day.isSame(highlightedDay, "day")
    );

    const isPast = day.isBefore(dayjs(), "day"); // Check if the date is in the past

    return (
      <PickersDay
        {...other}
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
        disabled={isPast} // Disable the day if it is in the past
        sx={{
          ...(isHighlighted && {
            backgroundColor: "#F05D40 !important",
            color: "white",
            "&:hover": {
              backgroundColor: "#8F2D1A !important",
            },
          }),
          ...(isPast && {
            pointerEvents: "none", // Disable click functionality for past dates
            opacity: 0.5, // Visual indication for disabled past dates
          }),
        }}
      />
    );
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        if (!event.target.closest(".MuiPickersDay-root")) {
          setOpenCal(false);
          if (onClose) {
            onClose();
          }
        }
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box style={style} ref={calendarRef}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          value={value ? dayjs(value) : dayjs()} // Default value as today
          onChange={(newDate) => onChange(newDate)} // Handle date change
          open={openCal}
          shouldCloseOnSelect={false}
          maxDate={maxDate ? dayjs(maxDate) : undefined} // Limit the max date
          disablePast={disablePastDates} // Disable past dates
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              highlightedDates,
            },
          }}
          {...restProps}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default CustomDateCalendar;
