import React from "react";
import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ChartDisplay from "./ChartDisplay";
import Reveal from "views/utilities/Reveal";
import { updateDashboard } from "services/DashboardService";
import { setDashboardDataForApi, updateDataBasedOnFilterParams } from "utils/dashboard-utils";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useContext } from "react";

const PreviewDashboard = ({
  droppableAreas,
  dashboardTitle,
  setDroppableAreas,
  selectedDashboard,
  setSelectedDashboard,
}) => {
  const { handleClick } = useContext(ToastContext);

  const updateFilterParams = async (widgetId, chartData, newParams) => {
    const updatedDroppables = updateDataBasedOnFilterParams(
      droppableAreas,
      widgetId,
      chartData,
      newParams
    );
    setDroppableAreas(updatedDroppables);

    const updatedDroppableAreas = setDashboardDataForApi(updatedDroppables);
    try {
      selectedDashboard.widgets.rows = [...updatedDroppableAreas[0].rows];
      selectedDashboard.widgets.screenId = updatedDroppableAreas[0].id;

      let responsedata = await updateDashboard(selectedDashboard.id, selectedDashboard);
      setSelectedDashboard(responsedata.data);
    } catch {
      handleClick("error", "There seems to be an error updating the filter.");
    }
  };
  return (
    <Reveal className="super-admin-dashboard">
      <div className="heading" style={{ marginTop: 10 }}>
        <Typography className="dashboard-title">{dashboardTitle}</Typography>
      </div>

      {droppableAreas.map((area) => (
        <Reveal key={area.id} style={{ marginTop: "5px" }}>
          {area.rows.map((row) => {
            const validColumns = row.columns.filter((column) => column.charts.length > 0);
            const columnCount = validColumns.length;
            const columnSize = Math.floor(12 / columnCount);
            return (
              <Grid container spacing={1} flex={1} style={{ marginTop: "5px" }} key={row.rowId}>
                {row.columns.map((column) => (
                  <Grid size={{ xs: 12, sm: columnSize, md: columnSize }} key={column.columnId}>
                    {column.charts.map((chart) => (
                      <Paper
                        key={chart.widgetId}
                        style={{
                          width: "100%",
                          minHeight: "100%",
                          borderRadius: "10px",
                          padding: "17px 24px",
                        }}
                      >
                        <ChartDisplay
                          querydata={chart}
                          onFilterParamsChange={(chartData, newParams) =>
                            updateFilterParams(chart.widgetId, chartData, newParams)
                          }
                        />
                      </Paper>
                    ))}
                  </Grid>
                ))}
              </Grid>
            );
          })}
        </Reveal>
      ))}
    </Reveal>
  );
};

export default PreviewDashboard;
