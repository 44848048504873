import { appEnv, baseUrl } from "store/constant";
import { api as API } from "./AxiosInterceptor";
import { getUUID } from "../store/constant";

export const createPrescriptions = (data) => {
  // data["id"] = getUUID();
  return API.post(`/api/prescriptions`, data);
};
export const getPrescriptionById = (id) => API.get(`/api/prescriptions/${id}`);
export const getMedicationsByPatientId = (patientId, disabledTenant = true, showLoader = true) =>
  API.get(`/api/prescriptions/medications/patient/${patientId}`, { disabledTenant, showLoader });
export const updatePrescriptions = (data, id) => API.put(`/api/prescriptions/${id}`, data);

//soap notes
export const generateSOAPNotes = (data) => API.post(`/api/soap`, data);

// prescription Search
export const searchPrescription = (param, category, disabledTenant = false) =>
  API.get(`/api/prescription/search`, {
    params: { query: param, category: category },
    showLoader: false,
    disabledTenant,
  });

// prescriptions by Doctor id/Patient id
export const getPrescriptionByIds = (doctorId, patientId) => {
  return API.get(`/api/prescriptions/past-prescriptions`, {
    params: { doctorId, patientId },
  });
};

// creating prescription templates
export const createTemplates = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/prescription/templates`, data);
};

export const deleteTemplates = (id) => API.delete(`/api/prescription/templates/${id}`);
export const UpdateTemplate = (id, data) => API.put(`/api/prescription/templates/${id}`, data);

// prescription templates by doctor Id
export const getTemplatesByDocId = (docId) =>
  API.get(`/api/prescription/templates/doctor/${docId}`);

export const downloadPDF = async (appointmentId, doSendNotification = false, prescriptionId) => {
  return await API.get(`/api/prescriptions/generate-pdf`, {
    responseType: "blob",
    params: { appointmentId, prescriptionId, doSendNotification },
  });
};

export const generatePrescription = (file) => API.post(`/api/prescription/search/audio`, file);

export const getPrescriptionByAppointmentId = (appointmentId) =>
  API.get(`/api/prescriptions/appointment`, {
    params: {
      appointmentId: appointmentId,
    },
  });

export const getEhrSummary = (
  patientId,
  doctorSpecialization,
  showLoader = false,
  disabledTenant = true
) =>
  API.post(
    `/api/ehr/ehr-summary/${patientId}?doctorSpecialization=${doctorSpecialization}`,
    {},
    {
      showLoader: showLoader,
      baseURL: appEnv === "electron" ? "https://hc.argusservices.in" : baseUrl,
      disabledTenant,
    }
  );
export const getEhrData = (patientId, disabledTenant = true) =>
  API.get(`/api/ehr/${patientId}`, { disabledTenant });

//Medicine Images
export const getMedicinesImages = (medicineId) => API.get(`/api/medications/images/${medicineId}`);
export const getAllMedicationManufacturers = () => API.get(`/api/medication-manufacturers`);
