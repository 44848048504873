import { appEnv, baseUrl } from "store/constant";
import { api as API } from "./AxiosInterceptor";

// HPR CREATE API's

// Aadhaar Api

export const generateAadhaarOtp = (data) =>
  API.post(`/api/abdm/hpr/create/generate-aadhaar-otp`, data, {
    baseURL: appEnv === "electron" ? "https://hc.argusservices.in" : baseUrl,
  });
export const verifyAdhaarOtp = (data) => API.post(`/api/abdm/hpr/create/verify-aadhaar-otp`, data);
export const regenerateAdhaarOtp = (data) =>
  API.post(`/api/abdm/hpr/create/resend-aadhaar-otp`, data);

//Mobile Api

export const generateMobileOtp = (data) =>
  API.post(`/api/abdm/hpr/create/generate-mobile-otp`, data);
export const verifyMobileOtp = (data) => API.post(`/api/abdm/hpr/create/verify-mobile-otp`, data);
export const regenerateMobileOtp = (data) =>
  API.post(`/api/abdm/hpr/create/resend-mobile-otp`, data);

export const createHprId = (data) =>
  API.post(`/api/abdm/hpr/create/create-hp-id-with-pre-verified`, data);

export const demographicsAuthmobile = (data) =>
  API.post(`/api/abdm/hpr/create/demographics-auth-via-mobile`, data);
export const isHprIdExist = (data) =>
  API.post(`/api/abdm/hpr/create/check-hp-id-account-exist`, data);

// HPR UTILITY API's

export const getStates = () => API.get(`/api/abdm/hpr/util/states`);
export const getDistricts = (id) => API.get(`/api/abdm/hpr/util/districts?stateCode=${id}`);
export const getCategories = () => API.get(`/api/abdm/hpr/util/categories`);
export const getSubcategories = (id) =>
  API.get(`/api/abdm/hpr/util/sub-categories?categoryCode=${id}`);
