import { Card, CardContent, Checkbox, FormControl, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getDocumentVitalByIndex,
  setCheckedDocumentVital,
  setDocumentError,
  setDocumentVitalValue,
} from "store/Slices/documentVitalSlice";

const LabReportVitalCard = ({
  documentIndex,
  vitalIndex,
  reportDate,
  // setContainerErrors,
}) => {
  const delimeter = "#";
  const dispatch = useDispatch();
  const vital = useSelector(getDocumentVitalByIndex(documentIndex, reportDate, vitalIndex));
  const [readOnly, setReadOnly] = useState(true);
  const inputRef = useRef(null);
  const [hasError, setHasError] = useState(false);

  const handleKeyPress = (event) => {
    const pressedKey = event.key;
    if (pressedKey === "Enter") {
      setReadOnly(true);
    }
  };

  const handleBlur = () => {
    setReadOnly(true);
  };

  const handleChange = (event) => {
    if (isNaN(Number(event.target.value))) {
      setHasError(true);
      dispatch(
        setDocumentError({
          value: reportDate + delimeter + documentIndex + delimeter + vitalIndex,
          error: true,
        })
      );
    } else {
      setHasError(false);
      dispatch(
        setDocumentError({
          value: reportDate + delimeter + documentIndex + delimeter + vitalIndex,
          error: false,
        })
      );
    }
    dispatch(
      setDocumentVitalValue({ documentIndex, reportDate, vitalIndex, value: event.target.value })
    );
  };

  const handleDoubleClick = () => {
    setReadOnly(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleCheckBoxOnChange = (event) => {
    dispatch(
      setCheckedDocumentVital({
        checked: event.target.checked,
        value: event.target.value,
        delimeter,
      })
    );
  };

  return (
    <Grid size={{ xs: 4 }}>
      <Card variant="outlined" sx={{ border: hasError ? "1px solid red" : undefined }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div style={{ width: "fit-content" }}>
            <Checkbox
              defaultChecked
              onChange={handleCheckBoxOnChange}
              value={reportDate + delimeter + documentIndex + delimeter + vitalIndex}
            />
          </div>
          <div style={{ width: "60%" }}>
            <CardContent>
              <Grid display={"flex"}>
                <FormControl sx={{ paddingRight: "0.25rem" }}>
                  <TextField
                    error={hasError ? true : false}
                    onDoubleClick={handleDoubleClick}
                    sx={{
                      "& .MuiFilledInput-root": {
                        backgroundColor: readOnly ? "transparent" : "#e3e3e3",
                        "&:before": {
                          border: "none",
                        },
                        "&:hover:not(.Mui-disabled):before": {
                          border: "none",
                        },
                        "&:after": {
                          border: "none",
                        },
                      },
                      "& .MuiFilledInput-root:hover": {
                        background: "transparent",
                      },
                      "& .MuiFilledInput-input": {
                        padding: 0,
                        color: readOnly ? "#004c70" : "black",
                        fontSize: "1rem",
                        fontWeight: readOnly ? "600" : "none",
                        cursor: readOnly ? "pointer" : undefined,
                        textAlign: "right",
                        backgroundColor: readOnly ? "transparent" : "#e3e3e3",
                      },
                    }}
                    variant="filled"
                    inputRef={inputRef}
                    value={vital?.value}
                    onKeyDown={handleKeyPress}
                    onBlur={handleBlur}
                    inputProps={{
                      readOnly: readOnly,
                      backgrouncolor: "transparent",
                    }}
                    size="small"
                    multiline={false}
                    onChange={handleChange}
                  ></TextField>
                </FormControl>
                <Typography
                  variant="h6"
                  sx={{ color: "#004c70" }}
                  display={"flex"}
                  alignItems={"center"}
                >
                  {vital?.vitalSignTypeResponse?.unitAbbreviation}
                </Typography>
              </Grid>
              <Typography variant="h6" component="div" textAlign={"right"}>
                {vital?.vitalSignTypeResponse?.displayName}
              </Typography>
            </CardContent>
          </div>
        </div>
      </Card>
    </Grid>
  );
};

export default LabReportVitalCard;
