import "assets/scss/style.scss";
import "assets/scss/prescriptionPad.scss";
import ModalUI from "ui-component/ModalUI";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import DescriptionIcon from "@mui/icons-material/Description";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CustomButton from "ui-component/custom-components/CustomButton";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import PrescriptionSearch from "./prescriptionSearch";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import PrescriptionCard from "./prescriptionCard";
import { useEffect, useContext, useMemo, useState } from "react";
import {
  ACTIVE_STATE,
  CLINIC_VISIT,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  FRONT_DESK,
  INACTIVE_STATE,
  PRESCRIPTION_DONE,
  TELE_CONSULTATION,
  currentActiveUser,
  getUUID,
  isDemo,
  isGuidedDemoOn,
  orgId,
} from "store/constant";
import PatientVitalSignModal from "views/Components/Patient/PatientProfile/PatientVitalSignModal";
import { getAppointmentById, updateAppointmentStatus } from "services/Appointments";
import {
  createPrescriptions,
  createTemplates,
  getEhrData,
  getEhrSummary,
  getPrescriptionById,
  getPrescriptionByIds,
  getTemplatesByDocId,
  updatePrescriptions,
} from "services/PrescriptionsService";
import { getVitalSignTypes } from "services/EntitiesServices";
import { Close, TrendingDown, TrendingUp } from "@mui/icons-material";
import PatientCard from "../PatientDTO/PatientCard";
import PatientHealthDetails from "../PatientDTO/PatientHealthDetails";
import RoomPage from "views/Components/TeleCommunication/Screens/Room";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import {
  getPrescriptionId,
  getPrescriptionPadData,
  setPrescriptionId,
  setPrescriptionPadData,
} from "store/Slices/prescriptionPadSlice";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import { fetchPatientLatestVitalSigns } from "utils/patient-latest-vital";
import { getChartByVitalType, getEhrDataPoints } from "utils/ehr-data-utils";
import { useShepherd } from "react-shepherd";
import "shepherd.js/dist/css/shepherd.css";
import { useLoader } from "layout/LoaderContext";
import { prescriptionGuideText, prescriptionGuideTextForTeleconsultation } from "utils/guide-steps";
import "assets/scss/shepherd-guide.scss";
import RIf from "ui-component/RIf";
import PatientPrescription from "views/Components/Patient/PatientPrescription";
import { getUsersByOrganizationId } from "services/organizationService";
import { setClearInvoiceState } from "store/Slices/billingInvoiceSlice";

const PrescriptionPad = () => {
  const { state } = useLocation();
  const room = useParams().appointmentId;
  const { handleClick } = useContext(ToastContext);
  const currentPrescription = useSelector(getPrescriptionPadData);
  const [expandPatientDetails, setExpandPatientDetails] = useState(true);
  const [hideLeftGrid, setHideLeftGrid] = useState(false);
  const prescriptionId = useSelector(getPrescriptionId);
  const [hideRightGrid, setHideRightGrid] = useState(false);
  const [ehrSummary, setEhrSummary] = useState("");
  const [ehrData, setEhrData] = useState(null);
  const [transcript, setTranscript] = useState("");

  /****************************** DEMO MODE START ********************************** */
  const { loading } = useLoader();
  const [firstTimeLoad, setFirstTimeLoad] = useState(true); // Tract first time load, to avoid shephard display before
  const [tourStarted, setTourStarted] = useState(false); // Track if the tour has already started
  const computedData = useMemo(() => {
    return currentPrescription;
  }, [currentPrescription]);

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
    },
    useModalOverlay: true,
  };

  const prescriptionGuideStepsForClinicVisit = [
    {
      id: "guide-intro",
      text: prescriptionGuideText["intro"],
      attachTo: {
        element: "",
        on: "bottom",
      },
      buttons: [
        {
          text: "Start",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-finish-button",
        },
      ],
      classes: "tour-guide-modal",
    },
    {
      id: "prescription-guide-step-1",
      text: prescriptionGuideText["step-1"],
      attachTo: {
        element: "#prescription-clinic-patient-details",
        on: "right",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-right",
    },
    {
      id: "prescription-guide-step-2",
      text: prescriptionGuideText["step-2"],
      attachTo: {
        element: "#prescription-clinic-ehr-details",
        on: "left",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-left",
    },
    {
      id: "prescription-guide-step-3",
      text: prescriptionGuideText["step-3"],
      attachTo: {
        element: "#prescription-clinic-pad",
        on: "left",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-left",
    },
    {
      id: "prescription-guide-step-4",
      text: prescriptionGuideText["step-4"],
      attachTo: {
        element: "#prescription-clinic-search",
        on: "left",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-left",
    },
    {
      id: "prescription-guide-step-5",
      text: prescriptionGuideText["step-5"],
      attachTo: {
        element: "#prescription-past-prescriptions",
        on: "bottom",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-bottom",
    },
    {
      id: "prescription-guide-step-6",
      text: prescriptionGuideText["step-6"],
      attachTo: {
        element: "#prescription-templates",
        on: "left",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-left",
    },
    {
      id: "prescription-guide-step-7",
      text: prescriptionGuideText["step-7"],
      attachTo: {
        element: "#prescription-end-consultation",
        on: "bottom",
      },
      buttons: [
        {
          text: "Finish",
          action: (tour) => Shepherd.activeTour.complete(),
          classes: "tour-guide-finish-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-bottom",
    },
  ];

  const prescriptionGuideStepsForTeleconsultation = [
    {
      id: "guide-intro",
      text: prescriptionGuideTextForTeleconsultation["intro"],
      attachTo: {
        element: "",
        on: "bottom",
      },
      buttons: [
        {
          text: "Start",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-finish-button",
        },
      ],
      classes: "tour-guide-modal",
    },
    {
      id: "prescription-guide-step-1",
      text: prescriptionGuideTextForTeleconsultation["step-1"],
      attachTo: {
        element: "#prescription-teleconsult-patient-details",
        on: "right",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-right",
    },

    {
      id: "prescription-guide-step-2",
      text: prescriptionGuideTextForTeleconsultation["step-2"],
      attachTo: {
        element: "#prescription-teleconsult-search",
        on: "left",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-left",
    },
    {
      id: "prescription-guide-step-3",
      text: prescriptionGuideTextForTeleconsultation["step-3"],
      attachTo: {
        element: "#prescription-teleconsult-pad",
        on: "left",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-left",
    },
    {
      id: "prescription-guide-step-4",
      text: prescriptionGuideTextForTeleconsultation["step-4"],
      attachTo: {
        element: "#prescription-share",
        on: "top",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-top",
    },
    {
      id: "prescription-guide-step-5",
      text: prescriptionGuideTextForTeleconsultation["step-5"],
      attachTo: {
        element: "#prescription-past-prescriptions",
        on: "bottom",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-bottom",
    },
    {
      id: "prescription-guide-step-6",
      text: prescriptionGuideTextForTeleconsultation["step-6"],
      attachTo: {
        element: "#prescription-templates",
        on: "left",
      },
      buttons: [
        {
          text: "Back",
          action: (tour) => Shepherd.activeTour.back(),
          classes: "tour-guide-back-button",
        },
        {
          text: "Next",
          action: (tour) => Shepherd.activeTour.next(),
          classes: "tour-guide-next-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-left",
    },
    {
      id: "prescription-guide-step-7",
      text: prescriptionGuideTextForTeleconsultation["step-7"],
      attachTo: {
        element: "#prescription-end-consultation",
        on: "bottom",
      },
      buttons: [
        {
          text: "Finish",
          action: (tour) => Shepherd.activeTour.complete(),
          classes: "tour-guide-finish-button",
        },
      ],
      classes: "tour-guide-modal tour-modal-arrow-bottom",
    },
  ];

  const Shepherd = useShepherd();

  useEffect(() => {
    if (isGuidedDemoOn()) {
      setFirstTimeLoad(false);
      if (!loading && !firstTimeLoad && !tourStarted && appointment?.type === CLINIC_VISIT) {
        setTourStarted(true);
        const tour = new Shepherd.Tour({
          ...tourOptions,
          steps: prescriptionGuideStepsForClinicVisit,
        });
        tour.start();
      }
      if (!loading && !firstTimeLoad && !tourStarted && appointment?.type === TELE_CONSULTATION) {
        setTourStarted(true);
        const tour = new Shepherd.Tour({
          ...tourOptions,
          steps: prescriptionGuideStepsForTeleconsultation,
        });
        tour.start();
      }
    }
  }, [loading]);
  /****************************** DEMO MODE END   ********************************** */

  useEffect(() => {}, [computedData]);

  const [previousPrescription, setPreviousPrescription] = useState({
    medicationsArr: [],
    symptomsArr: [],
    diagnosisArr: [],
    labInvestigationsArr: [],
    advicesArr: [],
  });
  const [appointment, setAppointment] = useState({});
  const [pastPrescriptions, setPastPrescriptions] = useState([]);
  const [prescriptionIndex, setPrescriptionIndex] = useState(-1);
  const [patientVitals, setPatientVitals] = useState([]);
  const [vitalSignTypes, setVitalSignTypes] = useState([]);
  const [patientLatestVitals, setPatientLatestVitals] = useState([]);
  const { appointmentId } = useParams();
  const [removedPrescriptionData, setRemovedPrescription] = useState({
    medicationsArr: [],
    symptomsArr: [],
    diagnosisArr: [],
    labInvestigationsArr: [],
    advicesArr: [],
  });
  const [iframeOpen, setIframeOpen] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(null);
  const [iframeAppointmentID, setIframeAppointmentId] = useState(null);

  const dispatch = useDispatch();
  // vital sign functionality
  const [vitalModalOpen, setVitalModalOpen] = useState(false);
  const fetchVitalSignTypes = async () => {
    try {
      const response = await getVitalSignTypes();
      setVitalSignTypes(response.data);
    } catch (error) {
      console.error("Error fetching Vital sign types");
    }
  };

  useEffect(() => {
    const vitalDetails = patientLatestVitals.map((vital) => {
      let randomVitalChangePercentage = getRandomPercentage();
      let positiveVitalChangePercentage = false;
      if (randomVitalChangePercentage >= 0) {
        positiveVitalChangePercentage = true;
      }
      return {
        ...vital,
        value: `${vital.value}${vital.vitalSignTypeResponse.unitAbbreviation ?? ""}`,
        displayName: `${vital.vitalSignTypeResponse.displayName}`,
        deviation: (
          <Grid display={"flex"}>
            {positiveVitalChangePercentage && (
              <TrendingUp
                style={{
                  color: "#388E3C",
                  height: "20px",
                  width: "20px",
                }}
              ></TrendingUp>
            )}
            {!positiveVitalChangePercentage && (
              <TrendingDown
                style={{
                  color: "#EF5148",
                  height: "20px",
                  width: "20px",
                }}
              ></TrendingDown>
            )}
            <span
              className={
                randomVitalChangePercentage >= 0
                  ? "change-field-value-up"
                  : "change-field-value-down"
              }
            >
              {randomVitalChangePercentage >= 0 ? "+" : "-"}
              {Math.abs(randomVitalChangePercentage)}%
            </span>
          </Grid>
        ),
      };
    });
    setPatientVitals(vitalDetails);
  }, [patientLatestVitals]);

  const getRandomPercentage = () => {
    let min = -10;
    let max = 11;
    return Math.floor(Math.random() * (max - min)) + min;
  };

  useEffect(() => {
    const VitalArray = ["BLOOD_PRESSURE", "BLOOD_OXYGEN", "RESPIRATORY_RATE", "HEART_RATE"];
    const fetchData = async () => {
      try {
        const appointmentResponse = await getAppointmentById(appointmentId);
        setAppointment(appointmentResponse.data);

        const pastPrescriptionResponse = await getPrescriptionByIds(
          currentActiveUser()?.roleBasedId,
          appointmentResponse.data?.patientId
        );
        setPastPrescriptions(pastPrescriptionResponse.data);
        await fetchPatientLatestVitalSigns(
          appointmentResponse.data?.patientId,
          setPatientLatestVitals,
          handleClick
        );
        fetchVitalSignTypes();

        const { data: result } = await getEhrSummary(appointmentResponse.data?.patientId);
        if (!result) {
          setEhrSummary(null);
        }
        const indexOfFirstDiv = result.EHR_SUMMARY.indexOf("<div>");
        const indexOflastDiv = result.EHR_SUMMARY.lastIndexOf("</div>") + 5;

        setEhrSummary(result.EHR_SUMMARY.substring(indexOfFirstDiv, indexOflastDiv + 1));
        const ehrResponse = await getEhrData(appointmentResponse.data?.patientId);
        const ehrDataPoints = getEhrDataPoints(ehrResponse.data);
        let newVitalData = ehrDataPoints.filter((d) => VitalArray.includes(d.key));
        setEhrData(newVitalData);
      } catch (error) {
        console.error("Error fetching details");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    handleGetTemplates();
    //eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  const getEndDateForPrescription = (duration) => {
    const [countStr, type] = duration.split(" ");
    const count = parseInt(countStr);
    if (isNaN(count)) {
      return null;
    }
    let endDate = dayjs();
    switch (type.toLowerCase()) {
      case "day":
      case "days":
        endDate = endDate.add(count, "day");
        break;
      case "week":
      case "weeks":
        endDate = endDate.add(count, "week");
        break;
      case "month":
      case "months":
        endDate = endDate.add(count, "month");
        break;
      default:
        return null; // Invalid duration type
    }
    return endDate.format(DATE_TIME_FORMAT);
  };

  const checkForFrontDesk = async () => {
    const response = await getUsersByOrganizationId(orgId());
    const frontDeskUserFound = response.data.find((user) => user.roleName === FRONT_DESK);
    try {
      await updateAppointmentStatus(appointment.id, PRESCRIPTION_DONE);
      if (frontDeskUserFound) {
        dispatch(
          setPrescriptionPadData({
            diagnosisArr: [],
            symptomsArr: [],
            medicationsArr: [],
            labInvestigationsArr: [],
            advicesArr: [],
            soapNotes: {},
          })
        );
        dispatch(setPrescriptionId(null));
        dispatch(setClearInvoiceState());
        navigate("/home/dashboard");
        dispatch(
          setPrescriptionPadData({
            diagnosisArr: [],
            symptomsArr: [],
            medicationsArr: [],
            labInvestigationsArr: [],
            advicesArr: [],
            soapNotes: {},
          })
        );
        dispatch(setPrescriptionId(null));
        dispatch(setClearInvoiceState());
      } else {
        navigate("/home/generateInvoice", {
          state: {
            appointmentId: appointment.id,
            patientId: appointment.patientId,
            doctorId: appointment.doctorId,
            orgId: appointment.orgId,
          },
        });
      }
    } catch (error) {
      handleClick("error", "There seems to be an error completing appointment");
    }
  };

  const savePrescriptionPad = async () => {
    /* 
      todo currently, only items selected from search bar can be saved, as id need to be sent.
      Need some changes from backend
    */

    const prescriptionDetails = [];
    const symptomDetails = [];
    const diagnosisDetails = [];
    const labTestDetails = [];
    const generatedPrescriptionId = getUUID();

    currentPrescription.medicationsArr.forEach((medicine) => {
      const prescriptionDetail = {
        id: medicine.id || getUUID(),
        medicationId: medicine.medicationId,
        duration: medicine.duration,
        purpose: null,
        frequency: medicine?.frequency?.trim() || "",
        doseTiming: medicine?.doseTiming,
        prescribingDoctor: null,
        instructions: medicine.instructions,
        patientId: appointment?.patientId,
        status: medicine.status || ACTIVE_STATE,
        startDate: dayjs().format(DATE_TIME_FORMAT),
        endDate: getEndDateForPrescription(medicine.duration),
        prescriptionId: prescriptionId || generatedPrescriptionId,
      };
      prescriptionDetails.push(prescriptionDetail);
    });

    currentPrescription.symptomsArr.forEach((symptom) => {
      const detail = {
        id: symptom.id || getUUID(),
        symptomId: symptom.symptomId,
        severity: symptom.severity,
        since: symptom.since,
        complaints: "complaints",
        status: symptom.status || ACTIVE_STATE,
        prescriptionId: prescriptionId || generatedPrescriptionId,
      };
      symptomDetails.push(detail);
    });

    currentPrescription.diagnosisArr.forEach((diagnosis) => {
      const detail = {
        id: diagnosis.id || getUUID(),
        diagnosisId: diagnosis.diagnosisId,
        since: "2 days",
        severity: "MODERATE",
        conclusions: diagnosis.conclusions,
        status: diagnosis.status || ACTIVE_STATE,
        prescriptionId: prescriptionId || generatedPrescriptionId,
      };
      diagnosisDetails.push(detail);
    });

    currentPrescription.labInvestigationsArr.forEach((labTest) => {
      const detail = {
        id: labTest.id || getUUID(),
        patientId: appointment?.patientId,
        labTestId: labTest.labTestId,
        reportDate: dayjs().format(DATE_FORMAT),
        performedBy: null,
        orderedBy: null,
        instructions: labTest.instructions,
        status: labTest.status || ACTIVE_STATE,
        prescriptionId: prescriptionId || generatedPrescriptionId,
      };
      labTestDetails.push(detail);
    });
    prescriptionDetails.push(...removedPrescriptionData.medicationsArr);
    symptomDetails.push(...removedPrescriptionData.symptomsArr);
    diagnosisDetails.push(...removedPrescriptionData.diagnosisArr);
    labTestDetails.push(...removedPrescriptionData.labInvestigationsArr);

    const payload = {
      id: generatedPrescriptionId,
      appointmentId: appointment?.id,
      additionalRecommendations: currentPrescription.advicesArr
        .map((advice) => advice?.displayName)
        .join(", "),
      doctorSignature: "Signature",
      followUpDate: dayjs().add(7, "day").format(DATE_FORMAT),
      referredTo: null,
      status: ACTIVE_STATE,
      templateId: null,
      prescriptionMedicationResponses: prescriptionDetails,
      prescriptionSymptomResponses: symptomDetails,
      prescriptionDiagnosisResponses: diagnosisDetails,
      prescriptionLabReportResponses: labTestDetails,
    };

    try {
      if (prescriptionId) {
        const updateResponse = await updatePrescriptions(payload, prescriptionId);
        const response = await getPrescriptionById(updateResponse.data.id);

        let advice = response.data?.additionalRecommendations;
        let advicesArr = [];
        if (advice && advice !== "") {
          advicesArr = response.data?.additionalRecommendations.split(",").map((advice) => {
            return {
              category: "ADV",
              displayName: advice.trim(),
            };
          });
        }
        dispatch(
          setPrescriptionPadData({
            diagnosisArr: [
              ...response.data.prescriptionDiagnosisResponses.map((diagnosis) => {
                return {
                  ...diagnosis,
                  displayName: diagnosis.diagnosisResponse.name,
                  category: "DX",
                };
              }),
            ],
            symptomsArr: [
              ...response.data.prescriptionSymptomResponses.map((symptom) => {
                return {
                  ...symptom,
                  displayName: symptom.symptomResponse.name,
                  category: "CC",
                };
              }),
            ],
            medicationsArr: [
              ...response.data.prescriptionMedicationResponses.map((medication) => {
                return {
                  ...medication,
                  displayName: medication.medicationResponse.name,
                  category: "RX",
                };
              }),
            ],
            labInvestigationsArr: [
              ...response.data.prescriptionLabReportResponses.map((labTest) => {
                return {
                  ...labTest,
                  displayName: labTest.labTestResponse.name,
                  category: "LAB",
                };
              }),
            ],
            advicesArr: [...advicesArr],
            soapNotes: response?.data?.soapNotes ?? {},
          })
        );
        checkForFrontDesk();
      } else {
        const createResponse = await createPrescriptions(payload);
        const response = await getPrescriptionById(createResponse.data.id);
        let advice = response.data?.additionalRecommendations;
        let advicesArr = [];
        if (advice && advice !== "") {
          advicesArr = response.data?.additionalRecommendations.split(",").map((advice) => {
            return {
              category: "ADV",
              displayName: advice.trim(),
            };
          });
        }
        dispatch(
          setPrescriptionPadData({
            diagnosisArr: [
              ...response.data.prescriptionDiagnosisResponses.map((diagnosis) => {
                return {
                  ...diagnosis,
                  displayName: diagnosis.diagnosisResponse.name,
                  category: "DX",
                };
              }),
            ],
            symptomsArr: [
              ...response.data.prescriptionSymptomResponses.map((symptom) => {
                return {
                  ...symptom,
                  displayName: symptom.symptomResponse.name,
                  category: "CC",
                };
              }),
            ],
            medicationsArr: [
              ...response.data.prescriptionMedicationResponses.map((medication) => {
                return {
                  ...medication,
                  displayName: medication.medicationResponse.name,
                  category: "RX",
                };
              }),
            ],
            labInvestigationsArr: [
              ...response.data.prescriptionLabReportResponses.map((labTest) => {
                return {
                  ...labTest,
                  displayName: labTest.labTestResponse.name,
                  category: "LAB",
                };
              }),
            ],
            advicesArr: [...advicesArr],
            soapNotes: response?.data?.soapNotes ?? {},
          })
        );
        dispatch(setPrescriptionId(response.data.id));
        checkForFrontDesk();
      }
    } catch (error) {
      handleClick("error", "There seems to be an error saving prescription");
    }
  };

  // methods for removing prescription items
  const handleRemoveMedication = (index) => {
    const updatedMedicationsArr = [...currentPrescription.medicationsArr];
    if (updatedMedicationsArr[index].id) {
      const updatedMedication = { ...updatedMedicationsArr[index], status: INACTIVE_STATE };
      setRemovedPrescription({
        ...removedPrescriptionData,
        medicationsArr: [...removedPrescriptionData.medicationsArr, updatedMedication],
      });
    }
    updatedMedicationsArr.splice(index, 1);

    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        medicationsArr: updatedMedicationsArr,
      })
    );
  };

  const handleRemoveSymptom = (index) => {
    const updatedSymptomsArr = [...currentPrescription.symptomsArr];
    if (updatedSymptomsArr[index].id) {
      const updatedSymptom = { ...updatedSymptomsArr[index], status: INACTIVE_STATE };
      setRemovedPrescription({
        ...removedPrescriptionData,
        symptomsArr: [...removedPrescriptionData.symptomsArr, updatedSymptom],
      });
    }
    updatedSymptomsArr.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        symptomsArr: updatedSymptomsArr,
      })
    );
  };

  const handleRemoveDiagnosis = (index) => {
    const updatedDiagnosisArr = [...currentPrescription.diagnosisArr];
    if (updatedDiagnosisArr[index].id) {
      const updatedDiagnosis = { ...updatedDiagnosisArr, status: INACTIVE_STATE };
      setRemovedPrescription({
        ...removedPrescriptionData,
        diagnosisArr: [...removedPrescriptionData.diagnosisArr, updatedDiagnosis],
      });
    }
    updatedDiagnosisArr.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        diagnosisArr: updatedDiagnosisArr,
      })
    );
  };

  const handleRemoveLabInvestigations = (index) => {
    const updatedLabInvestigations = [...currentPrescription.labInvestigationsArr];
    if (updatedLabInvestigations[index].id) {
      const updatedLab = { ...updatedLabInvestigations, status: INACTIVE_STATE };
      setRemovedPrescription({
        ...removedPrescriptionData,
        labInvestigationsArr: [...removedPrescriptionData.labInvestigationsArr, updatedLab],
      });
    }
    updatedLabInvestigations.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        labInvestigationsArr: updatedLabInvestigations,
      })
    );
  };

  const handleRemoveAdvices = (index, key) => {
    const updatedAdvicesArr = [...currentPrescription.advicesArr];
    updatedAdvicesArr.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        advicesArr: updatedAdvicesArr,
      })
    );
  };

  // template functionality
  const [modalOpen, setModalOpen] = useState(false);
  const [templateInfo, setTemplateInfo] = useState({
    templateName: "",
    templateDescription: "",
  });
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [templateMenus, setTemplateMenus] = useState([]);

  useEffect(() => {
    if (templateInfo.templateName.trim() === "") {
      setShowSaveButton(false);
    } else {
      setShowSaveButton(true);
    }
  }, [templateInfo]);

  const handleTemplateInfoChange = (event) => {
    setTemplateInfo({
      ...templateInfo,
      [event.target.name]: event.target.value,
    });
  };

  const closeModal = () => {
    setModalOpen(false);
    setVitalModalOpen(false);
  };

  const handleSaveTemplate = async () => {
    try {
      if (
        templateMenus.find(
          (template) =>
            template.label.trim().toLowerCase() === templateInfo.templateName.trim().toLowerCase()
        )
      ) {
        handleClick("error", "Template already exists with same name.");
        return;
      }
      const templateObject = {
        diagnosis: currentPrescription.diagnosisArr,
        symptoms: currentPrescription.symptomsArr,
        medications: currentPrescription.medicationsArr,
        labInvestigations: currentPrescription.labInvestigationsArr,
        advices: currentPrescription.advicesArr,
      };
      const payload = {
        templateName: templateInfo.templateName.trim(),
        templateContent: JSON.stringify(templateObject),
        description: templateInfo.templateDescription.trim(),
        status: ACTIVE_STATE,
        version: "1.0",
        doctorId: currentActiveUser()?.roleBasedId,
      };
      if (checkIfValueIsPresent(templateObject)) {
        await createTemplates(payload);
      } else {
        handleClick("error", "Template should not be empty.");
      }
      setTemplateInfo({ templateName: "", templateDescription: "" });
      handleGetTemplates();
      closeModal();
    } catch (error) {
      handleClick("error", "There seems to be an error saving the template");
    }
  };

  const handleGetTemplates = async () => {
    try {
      const templateResponses = await getTemplatesByDocId(currentActiveUser()?.roleBasedId);

      let templates = templateResponses.data.map((template) => ({
        label: template.templateName,
        templateData: template.templateContent,
        category: "TEMP",
        displayName: template.templateName,
      }));

      // Check if there are no templates and add "NO TEMPLATE AVAILABLE"
      if (templates.length === 0) {
        templates.push({
          label: "NO TEMPLATE AVAILABLE",
          templateData: "",
          category: "INFO",
          displayName: "NO TEMPLATE AVAILABLE",
          style: { fontStyle: "italic" },
          disabled: true,
          // isNoTemplateAvailable: true,
        });
      }

      templates.push({
        label: "SAVE THIS",
        templateData: null,
        category: "ACTION",
        displayName: "SAVE",
        isSaveButton: true,
        icon: <SaveIcon style={{ color: "#004c70" }} />,
      });

      setTemplateMenus(templates);
    } catch (error) {
      console.error("Error fetching templates");
    }
  };

  const handleSelectTemplate = (template) => {
    setHideRightGrid(false);
    if (!template?.templateData && template?.displayName === "SAVE") {
      setVitalModalOpen(false);
      setModalOpen(true);
      return;
    }
    const templateData = JSON.parse(template.templateData);
    dispatch(
      setPrescriptionPadData({
        diagnosisArr: templateData.diagnosis,
        symptomsArr: templateData.symptoms,
        medicationsArr: templateData.medications,
        labInvestigationsArr: templateData.labInvestigations,
        advicesArr: templateData.advices,
        soapNotes: {},
      })
    );
  };

  // update past prescription-pad detail object
  useEffect(() => {
    if (prescriptionIndex === -1) {
      return;
    }
    const medicationsArr = pastPrescriptions[prescriptionIndex].prescriptionMedicationResponses.map(
      (medication) => {
        return {
          prescriptionId: pastPrescriptions[prescriptionIndex].id,
          medicationId: medication.medicationId,
          category: "RX",
          displayName: medication.medicationResponse.name,
          frequency: medication.frequency,
          duration: medication.duration,
          instructions: medication.instructions,
          doseTiming: medication.doseTiming,
        };
      }
    );

    const diagnosisArr = pastPrescriptions[prescriptionIndex].prescriptionDiagnosisResponses.map(
      (diagnosis) => {
        return {
          prescriptionId: pastPrescriptions[prescriptionIndex].id,
          diagnosisId: diagnosis.diagnosisId,
          category: "DX",
          displayName: diagnosis.diagnosisResponse.name,
          conclusions: diagnosis.conclusions,
        };
      }
    );

    const symptomsArr = pastPrescriptions[prescriptionIndex].prescriptionSymptomResponses.map(
      (symptom) => {
        return {
          prescriptionId: pastPrescriptions[prescriptionIndex].id,
          symptomId: symptom.symptomId,
          category: "CC",
          displayName: symptom.symptomResponse.name,
          since: symptom.since,
          severity: symptom.severity,
        };
      }
    );

    const labInvestigationsArr = pastPrescriptions[
      prescriptionIndex
    ].prescriptionLabReportResponses.map((lab) => {
      return {
        prescriptionId: pastPrescriptions[prescriptionIndex].id,
        labTestId: lab.labTestId,
        category: "LAB",
        displayName: lab.labTestResponse.name,
        instructions: lab.instructions,
      };
    });

    const advice = pastPrescriptions[prescriptionIndex].additionalRecommendations;
    let advicesArr = [];
    if (advice !== "") {
      advicesArr = pastPrescriptions[prescriptionIndex].additionalRecommendations
        .split(", ")
        .map((advice) => {
          return {
            category: "ADV",
            displayName: advice,
          };
        });
    }

    setPreviousPrescription({
      diagnosisArr: diagnosisArr,
      symptomsArr: symptomsArr,
      medicationsArr: medicationsArr,
      labInvestigationsArr: labInvestigationsArr,
      advicesArr: advicesArr,
    });
  }, [prescriptionIndex]);

  const checkIfValueIsPresent = (obj) => {
    return Object.values(obj).some((res) => res != null && res?.length > 0);
  };

  const toggleLeftGrid = () => {
    setHideLeftGrid(!hideLeftGrid);
  };

  const toggleRightGrid = () => {
    setHideRightGrid(!hideRightGrid);
  };

  return (
    <Reveal>
      <div
        className="row justify-content-space-between align-items-center roll-content"
        style={{ height: "36px" }}
      >
        <div className="col breadcrumb-row">
          <RIf show={!isDemo()}>
            <Link to="/home/dashboard" className="btn back-btn">
              <img src="/images/hc_back_arrow.svg" alt="back-arrow"></img>
            </Link>
          </RIf>
          <h6 className="page-title" id="guide-intro">
            Prescription Pad
          </h6>
        </div>
        <div className="col">
          <ul className="horizontal-ul" style={{ marginTop: 0 }}>
            <li className="main-actions" id="prescription-end-consultation">
              <CustomButton
                height="36px"
                className={"mui-btn--primary"}
                startIcon={
                  state?.isEditable ? (
                    <i className="ri-save-2-line ri-xl" />
                  ) : (
                    <img src="/images/hc_double_tick.svg" alt="double_tick_svg" />
                  )
                }
                label={state?.isEditable ? "Save changes" : "End Consultation"}
                onClick={isDemo() ? null : savePrescriptionPad}
              ></CustomButton>
            </li>
            <li className="main-actions" id="prescription-templates">
              <CustomButton
                className={"mui-btn--secondary"}
                startIcon={<img src="/images/hc_template.svg" alt="template_svg" />}
                height="36px"
                endIcon={<i className="ri-arrow-down-s-fill" />}
                label={"Templates"}
                dropDownMenuItems={templateMenus}
                onMenuItemClick={handleSelectTemplate}
                onClick={() => {
                  setHideRightGrid(true);
                }}
                handleRightGridClose={() => {
                  setHideRightGrid(false);
                }}
                menuItemHoverColor="#29bf91"
              ></CustomButton>
            </li>
            <li className="main-actions">
              <div
                id="prescription-past-prescriptions"
                style={{ display: "flex", alignItems: "center" }}
              >
                <IconButton
                  onClick={() => {
                    setPrescriptionIndex((prev) => prev + 1);
                  }}
                  disabled={prescriptionIndex === pastPrescriptions.length - 1}
                >
                  <KeyboardArrowLeftIcon
                    style={{
                      color: prescriptionIndex === pastPrescriptions.length - 1 ? "" : "#004C70",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </IconButton>
                <p style={{ margin: "0px", fontWeight: "500", color: "#004c70" }}>Prescriptions</p>
                <IconButton
                  onClick={() => {
                    setPrescriptionIndex((prev) => prev - 1);
                  }}
                  disabled={prescriptionIndex === -1}
                >
                  <KeyboardArrowRightIcon
                    style={{
                      color: prescriptionIndex === -1 ? "" : "#004C70",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </IconButton>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div style={{ marginBottom: "0px" }}>
        {appointment?.type === TELE_CONSULTATION ? (
          <div
            style={{
              height: "calc(100vh - 146px)",
              display: "flex",
              gap: "30px",
              width: "100%",
              overflow: "hidden",
              position: "relative",
              scrollbarWidth: "none",
            }}
          >
            {/* RoomPage in the background */}
            <Grid style={{ height: "100%", width: "100%" }}>
              <RoomPage
                room={room}
                isDoctor={true}
                appointment={appointment}
                prescriptionId={prescriptionId}
                className="roomClass"
              ></RoomPage>
            </Grid>

            {/* Left grid overlay */}
            <Grid
              container
              id="prescription-teleconsult-patient-details"
              className={hideLeftGrid ? "container-left-t-com-p-pad-hidden" : ""}
              style={{
                position: "absolute",
                width: "27%",
                bottom: "2vh",
                height: "auto",
                maxHeight: "Calc(100% - 27vh)",
                left: "30px",
                padding: "0px",
                opacity: expandPatientDetails ? 1 : 0,
                backgroundColor: "rgba(0, 0, 0, 0)",
                overflowY: "auto",
                zIndex: 2,
                scrollbarWidth: "none",
                borderRadius: "10px",
                transition: "all 0.5s ease",
              }}
              spacing={2}
            >
              <Grid
                size={{ md: 10 }}
                style={{ height: "100%", borderRadius: "10px" }}
                className="pl-0"
              >
                <Card
                  className="custom-card-ui"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    color: "white",
                    scrollbarWidth: "none",
                  }}
                >
                  <div className="row justify-content-space-between align-items-center">
                    <div className="col card-title-tele">Patient Details</div>
                    <div>
                      <CustomButton
                        iconButton={<i className="ri-more-2-fill" />}
                        dropDownMenuItems={[{ label: "Add Vital" }]}
                        dropDownBorderRadius="3px"
                        menuItemHoverColor="#29bf91"
                        onMenuItemClick={(item) => {
                          setModalOpen(false);
                          setVitalModalOpen(true);
                        }}
                        style={{
                          padding: "0px",
                          justifyContent: "end",
                          color: "white",
                          position: "absolute",
                          right: "18%",
                        }}
                      />
                    </div>
                  </div>

                  <Grid container className="row mb-0 mx-0 column-patient-details">
                    <Grid size={{ md: 12 }} className="px-0">
                      <div className="row w-100 mb-0 align-items-left flex-direction-column">
                        <PatientCard
                          patientId={appointment?.patientId}
                          patientData={appointment?.patient}
                          viewData={{
                            basicDetails: true,
                            lifeStyle: true,
                            chronicDiseases: true,
                            lastVisited: true,
                          }}
                          handleClick={handleClick}
                          isTeleconsultation={true}
                        ></PatientCard>
                        <PatientHealthDetails
                          patientVitals={patientVitals}
                          patientId={appointment.patientId}
                          patientAbhaId={appointment?.patient?.abhaId}
                          handleClick={handleClick}
                          setIframeOpen={setIframeOpen}
                          setIframeSrc={setIframeSrc}
                          setIframeAppointmentId={setIframeAppointmentId}
                          isTeleconsultation={true}
                        ></PatientHealthDetails>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              <Grid size={{ md: 2 }} style={{ margin: "0px" }}>
                <div className="hide-left-grid-button" onClick={toggleLeftGrid}>
                  <i className="ri-arrow-left-s-fill ri-xl" style={{}} />
                </div>
              </Grid>
            </Grid>

            {hideLeftGrid && (
              <div className="show-patient-details-tele-button" onClick={toggleLeftGrid}>
                {" "}
                <span className="text-span-show-left-grid">Show Patient Details</span>
                <i className="ri-arrow-right-s-fill ri-xl" />
              </div>
            )}

            {/* Right grid overlay */}
            <Grid
              className={`${
                hideRightGrid ? "container-right-t-com-p-pad-hidden" : ""
              } right-grid-main-container`}
              container
              id="prescription-teleconsult-pad"
            >
              <Grid size={{ md: 1 }}>
                <div className="hide-right-grid-button" onClick={toggleRightGrid}>
                  <i className="ri-arrow-right-s-fill ri-xl" style={{}} />
                </div>
              </Grid>
              <Grid size={{ md: 11 }}>
                <Card
                  className="custom-card-ui"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    scrollbarWidth: "none",
                    height: "100%",
                  }}
                >
                  {prescriptionIndex === -1 && (
                    <div id="prescription-teleconsult-search">
                      <PrescriptionSearch
                        templates={templateMenus}
                        handleSelectTemplate={handleSelectTemplate}
                        isTeleconsultation={true}
                        setTranscript={setTranscript}
                        patientId={appointment?.patientId}
                      />
                    </div>
                  )}
                  {prescriptionIndex !== -1 && (
                    <Typography
                      style={{
                        fontFamily: "Mitr",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#004C70",
                      }}
                    >
                      {dayjs(
                        pastPrescriptions[prescriptionIndex].appointmentResponse.appointmentDate
                      ).format("DD MMM YYYY")}
                    </Typography>
                  )}
                  <br />

                  <PrescriptionCard
                    key="Cc"
                    cardHeaderTitle="Cc"
                    values={
                      prescriptionIndex === -1
                        ? currentPrescription.symptomsArr
                        : previousPrescription.symptomsArr
                    }
                    onDelete={handleRemoveSymptom}
                    isEditable={prescriptionIndex === -1}
                    isTeleconsultation={true}
                  ></PrescriptionCard>

                  <PrescriptionCard
                    key="Dx"
                    cardHeaderTitle="Dx"
                    values={
                      prescriptionIndex === -1
                        ? currentPrescription.diagnosisArr
                        : previousPrescription.diagnosisArr
                    }
                    onDelete={handleRemoveDiagnosis}
                    isEditable={prescriptionIndex === -1}
                    isTeleconsultation={true}
                  ></PrescriptionCard>

                  <PrescriptionCard
                    key="Rx"
                    cardHeaderTitle="Rx"
                    cardContentField="Medicines"
                    values={
                      prescriptionIndex === -1
                        ? currentPrescription.medicationsArr
                        : previousPrescription.medicationsArr
                    }
                    onDelete={handleRemoveMedication}
                    isEditable={prescriptionIndex === -1}
                    isTeleconsultation={true}
                  ></PrescriptionCard>

                  <PrescriptionCard
                    key="Lab"
                    cardHeaderTitle="Lab"
                    values={
                      prescriptionIndex === -1
                        ? currentPrescription.labInvestigationsArr
                        : previousPrescription.labInvestigationsArr
                    }
                    onDelete={handleRemoveLabInvestigations}
                    isEditable={prescriptionIndex === -1}
                    isTeleconsultation={true}
                  ></PrescriptionCard>

                  <PrescriptionCard
                    key="Adv"
                    cardHeaderTitle="Adv"
                    values={
                      prescriptionIndex === -1
                        ? currentPrescription.advicesArr
                        : previousPrescription.advicesArr
                    }
                    onDelete={handleRemoveAdvices}
                    isEditable={prescriptionIndex === -1}
                    isTeleconsultation={true}
                  ></PrescriptionCard>
                </Card>
              </Grid>
            </Grid>
            {hideRightGrid && (
              <div className="show-prescription-pad-tele" onClick={toggleRightGrid}>
                <i className="ri-arrow-left-s-fill ri-xl" />
                <span>Show Prescription Pad</span>
              </div>
            )}
          </div>
        ) : (
          <>
            <Grid
              container
              spacing={2}
              className="cst-height mx-0 row justify-content-space-between align-items-center mb-0 prescription-layout"
            >
              <Grid
                id="prescription-clinic-patient-details"
                size={{ md: 3.5 }}
                style={{ height: "100%" }}
                className="pl-0"
              >
                <Card
                  className="custom-card-ui"
                  style={{ backgroundColor: "white", scrollbarWidth: "none" }}
                >
                  <div className="row justify-content-space-between align-items-center">
                    <div className="col card-title">Patient Details</div>
                    <div className="col filters">
                      <CustomButton
                        iconButton={<i className="ri-more-2-fill" />}
                        dropDownMenuItems={[{ label: "Add Vital" }]}
                        dropDownBorderRadius="3px"
                        menuItemHoverColor="#29bf91"
                        onMenuItemClick={(item) => {
                          setModalOpen(false);
                          setVitalModalOpen(true);
                        }}
                        style={{ width: "10px" }}
                      ></CustomButton>
                    </div>
                  </div>
                  <Grid container className="row mb-0 mx-0 column-patient-details">
                    <Grid size={{ md: 12 }} className="px-0">
                      <div className="row w-100 mb-0 align-items-left flex-direction-column">
                        <PatientCard
                          patientId={appointment?.patientId}
                          patientData={appointment?.patient}
                          viewData={{
                            basicDetails: true,
                            lifeStyle: true,
                            chronicDiseases: true,
                            lastVisited: true,
                          }}
                          handleClick={handleClick}
                        ></PatientCard>
                        <PatientHealthDetails
                          patientVitals={patientVitals}
                          patientId={appointment.patientId}
                          patientAbhaId={appointment?.patient?.abhaId}
                          handleClick={handleClick}
                          setIframeOpen={setIframeOpen}
                          setIframeSrc={setIframeSrc}
                          setIframeAppointmentId={setIframeAppointmentId}
                        ></PatientHealthDetails>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid
                id="prescription-clinic-ehr-details"
                size={{ md: 4 }}
                style={{ height: "100%" }}
              >
                <Card
                  className="custom-card-ui"
                  style={{
                    backgroundColor: "white",
                    scrollbarWidth: "none",
                  }}
                >
                  <div className="justify-content-space-between align-items-center">
                    {ehrSummary && (
                      <>
                        <div style={{ paddingLeft: "0px" }} className="card-title">
                          EHR Summary
                        </div>
                        <div
                          style={{ padding: "15px" }}
                          dangerouslySetInnerHTML={{ __html: ehrSummary }}
                        ></div>
                      </>
                    )}
                    {ehrData?.map((vital) => {
                      return (
                        <div style={{ marginTop: "10px" }}>
                          {getChartByVitalType(
                            vital?.key,
                            vital?.value,
                            vital?.value[0]?.vitalSignTypeResponse?.displayName
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Card>
              </Grid>
              <Grid id="prescription-clinic-pad" size={{ md: 4.5 }} style={{ height: "100%" }}>
                <Card
                  className="custom-card-ui"
                  style={{
                    backgroundColor: "white",
                    padding: "24px 15px",
                    scrollbarWidth: "none",
                  }}
                >
                  {prescriptionIndex === -1 && (
                    <div id="prescription-clinic-search">
                      <PrescriptionSearch
                        templates={templateMenus}
                        handleSelectTemplate={handleSelectTemplate}
                        setTranscript={setTranscript}
                        patientId={appointment?.patientId}
                      />
                    </div>
                  )}
                  {prescriptionIndex !== -1 && (
                    <Typography
                      style={{
                        fontFamily: "Mitr",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#004C70",
                      }}
                    >
                      {dayjs(
                        pastPrescriptions[prescriptionIndex].appointmentResponse.appointmentDate
                      ).format("DD MMM YYYY")}
                    </Typography>
                  )}
                  <br></br>

                  <PrescriptionCard
                    key="Cc"
                    cardHeaderTitle="Cc"
                    values={
                      prescriptionIndex === -1
                        ? currentPrescription.symptomsArr
                        : previousPrescription.symptomsArr
                    }
                    onDelete={handleRemoveSymptom}
                    isEditable={prescriptionIndex === -1}
                  ></PrescriptionCard>

                  <PrescriptionCard
                    key="Dx"
                    cardHeaderTitle="Dx"
                    values={
                      prescriptionIndex === -1
                        ? currentPrescription.diagnosisArr
                        : previousPrescription.diagnosisArr
                    }
                    onDelete={handleRemoveDiagnosis}
                    isEditable={prescriptionIndex === -1}
                  ></PrescriptionCard>

                  <PrescriptionCard
                    key="Rx"
                    cardHeaderTitle="Rx"
                    cardContentField="Medicines"
                    values={
                      prescriptionIndex === -1
                        ? currentPrescription.medicationsArr
                        : previousPrescription.medicationsArr
                    }
                    onDelete={handleRemoveMedication}
                    isEditable={prescriptionIndex === -1}
                  ></PrescriptionCard>

                  <PrescriptionCard
                    key="Lab"
                    cardHeaderTitle="Lab"
                    values={
                      prescriptionIndex === -1
                        ? currentPrescription.labInvestigationsArr
                        : previousPrescription.labInvestigationsArr
                    }
                    onDelete={handleRemoveLabInvestigations}
                    isEditable={prescriptionIndex === -1}
                  ></PrescriptionCard>

                  <PrescriptionCard
                    key="Adv"
                    cardHeaderTitle="Adv"
                    values={
                      prescriptionIndex === -1
                        ? currentPrescription.advicesArr
                        : previousPrescription.advicesArr
                    }
                    onDelete={handleRemoveAdvices}
                    isEditable={prescriptionIndex === -1}
                  ></PrescriptionCard>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </div>
      <ModalUI
        visible={modalOpen || vitalModalOpen}
        close={closeModal}
        title={modalOpen ? "Save Prescription as Template" : "Add Patient Vital Sign"}
        component={
          <>
            {modalOpen ? (
              <div style={{ width: "500px" }}>
                <FormInputField
                  style={{ width: "60%", marginTop: "20px" }}
                  label={"Template Name *"}
                  name={"templateName"}
                  inputProps={{ maxLength: 30 }}
                  value={templateInfo.templateName}
                  onChange={handleTemplateInfoChange}
                  startAdornment={<DriveFileRenameOutlineIcon></DriveFileRenameOutlineIcon>}
                ></FormInputField>
                <FormInputField
                  style={{ width: "100%", marginTop: "20px" }}
                  label="Template Description"
                  name="templateDescription"
                  inputProps={{ maxLength: 100 }}
                  value={templateInfo.templateDescription}
                  onChange={handleTemplateInfoChange}
                  startAdornment={<DescriptionIcon></DescriptionIcon>}
                ></FormInputField>
                <div style={{ marginTop: "20px", display: "flex" }}>
                  <CustomButton
                    label="Save"
                    height="36px"
                    className="mui-btn--primary"
                    style={{ marginRight: "30px" }}
                    onClick={isDemo() ? closeModal() : handleSaveTemplate}
                    disabled={!showSaveButton}
                  ></CustomButton>
                  <CustomButton
                    height="36px"
                    label="Cancel"
                    className="btn--secondary-light"
                    onClick={() => {
                      setTemplateInfo({
                        templateName: "",
                        templateDescription: "",
                      });
                      closeModal();
                    }}
                  ></CustomButton>
                </div>
              </div>
            ) : (
              <PatientVitalSignModal
                selected={null}
                close={(operation) => {
                  if (operation === "save") {
                    closeModal();
                    fetchPatientLatestVitalSigns(
                      appointment?.patientId,
                      setPatientLatestVitals,
                      handleClick
                    );
                  } else {
                    closeModal();
                  }
                }}
                apData={appointment}
                patientId={appointment?.patientId}
                vitalSignTypes={vitalSignTypes}
              ></PatientVitalSignModal>
            )}
          </>
        }
      ></ModalUI>
      {iframeOpen && (
        <div className="iframe-div">
          {iframeSrc ? (
            <iframe
              title="consent form"
              src={iframeSrc}
              target="_blank"
              className="iframe"
            ></iframe>
          ) : (
            <div className="iframe">
              <PatientPrescription appId={iframeAppointmentID} />
            </div>
          )}

          <CustomButton
            iconButton={<Close />}
            onClick={() => {
              setIframeOpen(false);
              setIframeSrc(null);
            }}
            className="iframe-close-button"
          />
        </div>
      )}
    </Reveal>
  );
};

export default PrescriptionPad;
