import "assets/scss/style.scss";
import "assets/scss/prescriptionPad.scss";
import "assets/scss/DoctorHomeScreen/PatientPrescriptionDetails/patient-prescription-pad.scss";
import ModalUI from "ui-component/ModalUI";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import DescriptionIcon from "@mui/icons-material/Description";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Card, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useEffect, useContext, useState, useCallback } from "react";
import {
  ACTIVE_STATE,
  CANCELLED,
  CHECKED_IN,
  CLINIC_VISIT,
  COMPLETED,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  FRONT_DESK,
  INACTIVE_STATE,
  NO_SHOW,
  PRESCRIPTION_DONE,
  RESCHEDULED,
  SCHEDULED,
  TELE_CONSULTATION,
  currentActiveUser,
  getUUID,
  isDemo,
  orgId,
} from "store/constant";
import PatientVitalSignModal from "views/Components/Patient/PatientProfile/PatientVitalSignModal";
import { updateAppointmentStatus } from "services/Appointments";
import {
  createPrescriptions,
  createTemplates,
  downloadPDF,
  generateSOAPNotes,
  getPrescriptionById,
  getPrescriptionByIds,
  getTemplatesByDocId,
  updatePrescriptions,
} from "services/PrescriptionsService";
import { getVitalSignTypes } from "services/EntitiesServices";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import {
  getPatientPastPrescriptions,
  getPrescriptionId,
  getPrescriptionPadData,
  setPatientPastPrescriptions,
  setPrescriptionId,
  setPrescriptionPadData,
} from "store/Slices/prescriptionPadSlice";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import { fetchPatientLatestVitalSigns } from "utils/patient-latest-vital";
import "shepherd.js/dist/css/shepherd.css";
import "assets/scss/shepherd-guide.scss";
import { getUsersByOrganizationId } from "services/organizationService";
import { Clear, DoneAll, Edit, PlayCircleOutline } from "@mui/icons-material";
import _ from "lodash";
import { convertPrescriptionPadDataToApiFormat } from "utils/prescriptionPadUtils/convert-to-API-format";
import { getSelectedAppointmentOnDashboard } from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/appointmentSlice";
import { getSethuSirLayout } from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/utilSlice";
import PrescriptionCard from "../../Prescription Pad/prescriptionCard";
import PrescriptionSearch from "../../Prescription Pad/prescriptionSearch";
import { linkrecordWithAbha } from "services/AbhaService";
import { setClearInvoiceState } from "store/Slices/billingInvoiceSlice";

const PrescriptionPadForClinicVisitCV1 = () => {
  const { state } = useLocation();
  const { handleClick } = useContext(ToastContext);
  const currentPrescription = useSelector(getPrescriptionPadData);

  const selectedAppointment = useSelector(getSelectedAppointmentOnDashboard)?.value;
  const sethuSirLayout = useSelector(getSethuSirLayout);

  const [prescriptionIndex, setPrescriptionIndex] = useState(-1);

  const prescriptionId = useSelector(getPrescriptionId);
  const [transcript, setTranscript] = useState("");
  const [isLinkPrescription, setIsLinkPrescription] = useState(false);

  const [templateLabel, setTemplateLabel] = useState("Templates");
  const templateClearObj = {
    label: "Clear",
    templateData: null,
    category: "ACTION",
    displayName: "Clear",
    icon: <Clear style={{ color: "red" }} />,
  };
  const [selectedMenuItemLabel, setSelectedMenuItemLabel] = useState("");

  // const computedData = useMemo(() => {
  //   return currentPrescription;
  // }, [currentPrescription]);

  // useEffect(() => {}, [computedData]);

  const previousPrescription = useSelector(getPatientPastPrescriptions);

  const [pastPrescriptions, setPastPrescriptions] = useState([]);
  const [vitalSignTypes, setVitalSignTypes] = useState([]);
  const [patientLatestVitals, setPatientLatestVitals] = useState([]);
  // const { id } = useParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [removedPrescriptionData, setRemovedPrescription] = useState({
    medicationsArr: [],
    symptomsArr: [],
    diagnosisArr: [],
    labInvestigationsArr: [],
    advicesArr: [],
  });

  const dispatch = useDispatch();
  // vital sign functionality
  const [vitalModalOpen, setVitalModalOpen] = useState(false);
  const fetchVitalSignTypes = async () => {
    try {
      const response = await getVitalSignTypes();
      setVitalSignTypes(response.data);
    } catch (error) {
      console.error("Error fetching Vital sign types");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedAppointment?.id) {
          const pastPrescriptionResponse = await getPrescriptionByIds(
            currentActiveUser()?.roleBasedId,
            selectedAppointment?.patientId
          );
          setPastPrescriptions(pastPrescriptionResponse.data);

          fetchVitalSignTypes();
        }
      } catch (error) {
        console.error("Error fetching details");
      }
    };
    fetchData();
  }, [selectedAppointment?.id]);

  useEffect(() => {
    handleGetTemplates();
    //eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  const getEndDateForPrescription = (duration) => {
    const [countStr, type] = duration.split(" ");
    const count = parseInt(countStr);
    if (isNaN(count)) {
      return null;
    }
    let endDate = dayjs();
    switch (type?.toLowerCase()) {
      case "day":
      case "days":
        endDate = endDate.add(count, "day");
        break;
      case "week":
      case "weeks":
        endDate = endDate.add(count, "week");
        break;
      case "month":
      case "months":
        endDate = endDate.add(count, "month");
        break;
      default:
        return null; // Invalid duration type
    }
    return endDate.format(DATE_TIME_FORMAT);
  };

  const handleCloseLinkModal = () => {
    setIsLinkPrescription(false);
  };

  const savePrescriptionPad = async () => {
    /* 
      todo currently, only items selected from search bar can be saved, as id need to be sent.
      Need some changes from backend
    */

    const prescriptionDetails = [];
    const symptomDetails = [];
    const diagnosisDetails = [];
    const labTestDetails = [];
    const generatedPrescriptionId = getUUID();

    currentPrescription.medicationsArr.forEach((medicine) => {
      const prescriptionDetail = {
        id: medicine.id || getUUID(),
        medicationId: medicine.medicationId,
        duration: medicine.duration,
        purpose: null,
        frequency: medicine?.frequency?.trim() || "",
        doseTiming: medicine?.doseTiming,
        prescribingDoctor: null,
        instructions: medicine.instructions,
        patientId: selectedAppointment?.patientId,
        status: medicine.status || ACTIVE_STATE,
        startDate: dayjs().format(DATE_TIME_FORMAT),
        endDate: getEndDateForPrescription(medicine.duration),
        prescriptionId: prescriptionId || generatedPrescriptionId,
      };
      prescriptionDetails.push(prescriptionDetail);
    });

    currentPrescription.symptomsArr.forEach((symptom) => {
      const detail = {
        id: symptom.id || getUUID(),
        symptomId: symptom.symptomId,
        severity: symptom.severity,
        since: symptom.since,
        complaints: "complaints",
        status: symptom.status || ACTIVE_STATE,
        prescriptionId: prescriptionId || generatedPrescriptionId,
      };
      symptomDetails.push(detail);
    });

    currentPrescription.diagnosisArr.forEach((diagnosis) => {
      const detail = {
        id: diagnosis.id || getUUID(),
        diagnosisId: diagnosis.diagnosisId,
        since: "2 days",
        severity: "MODERATE",
        conclusions: diagnosis.conclusions,
        status: diagnosis.status || ACTIVE_STATE,
        prescriptionId: prescriptionId || generatedPrescriptionId,
      };
      diagnosisDetails.push(detail);
    });

    currentPrescription.labInvestigationsArr.forEach((labTest) => {
      const detail = {
        id: labTest.id || getUUID(),
        patientId: selectedAppointment?.patientId,
        labTestId: labTest.labTestId,
        reportDate: dayjs().format(DATE_FORMAT),
        performedBy: null,
        orderedBy: null,
        instructions: labTest.instructions,
        status: labTest.status || ACTIVE_STATE,
        prescriptionId: prescriptionId || generatedPrescriptionId,
      };
      labTestDetails.push(detail);
    });
    prescriptionDetails.push(...removedPrescriptionData.medicationsArr);
    symptomDetails.push(...removedPrescriptionData.symptomsArr);
    diagnosisDetails.push(...removedPrescriptionData.diagnosisArr);
    labTestDetails.push(...removedPrescriptionData.labInvestigationsArr);

    const newPrescription = {
      medicationsArr: [...currentPrescription.medicationsArr],
      symptomsArr: [...currentPrescription.symptomsArr],
      diagnosisArr: [...currentPrescription.diagnosisArr],
      labInvestigationsArr: [...currentPrescription.labInvestigationsArr],
      advicesArr: [...currentPrescription.advicesArr],
    };

    let prescriptionInAPIFormat = await convertPrescriptionPadDataToApiFormat(currentPrescription);
    let generatedSOAPNotes;
    if (!_.isEqual(newPrescription, currentPrescription?.soapNotes?.oldPrescription)) {
      let prescriptionObj = {
        transcript: transcript,
        prescription: prescriptionInAPIFormat,
      };
      try {
        const soapResponse = await generateSOAPNotes(prescriptionObj);
        generatedSOAPNotes = soapResponse?.data;
      } catch (error) {
        console.error(error);
      }
    }

    const payload = {
      id: generatedPrescriptionId,
      appointmentId: selectedAppointment?.id,
      additionalRecommendations: currentPrescription.advicesArr
        .map((advice) => advice?.displayName)
        .join(", "),
      doctorSignature: "Signature",
      followUpDate: dayjs().add(7, "day").format(DATE_FORMAT),
      referredTo: null,
      status: ACTIVE_STATE,
      templateId: null,
      prescriptionMedicationResponses: prescriptionDetails,
      prescriptionSymptomResponses: symptomDetails,
      prescriptionDiagnosisResponses: diagnosisDetails,
      prescriptionLabReportResponses: labTestDetails,
      soapNotes: generatedSOAPNotes ?? prescriptionInAPIFormat?.soapNotes,
    };

    try {
      if (prescriptionId) {
        const updateResponse = await updatePrescriptions(payload, prescriptionId);
        const response = await getPrescriptionById(updateResponse.data.id);

        let advice = response.data?.additionalRecommendations;
        let advicesArr = [];
        if (advice && advice !== "") {
          advicesArr = response.data?.additionalRecommendations.split(",").map((advice) => {
            return {
              category: "ADV",
              displayName: advice.trim(),
            };
          });
        }
        dispatch(
          setPrescriptionPadData({
            diagnosisArr: [
              ...response.data.prescriptionDiagnosisResponses.map((diagnosis) => {
                return {
                  ...diagnosis,
                  displayName: diagnosis.diagnosisResponse.name,
                  category: "DX",
                };
              }),
            ],
            symptomsArr: [
              ...response.data.prescriptionSymptomResponses.map((symptom) => {
                return {
                  ...symptom,
                  displayName: symptom.symptomResponse.name,
                  category: "CC",
                };
              }),
            ],
            medicationsArr: [
              ...response.data.prescriptionMedicationResponses.map((medication) => {
                return {
                  ...medication,
                  displayName: medication.medicationResponse.name,
                  category: "RX",
                };
              }),
            ],
            labInvestigationsArr: [
              ...response.data.prescriptionLabReportResponses.map((labTest) => {
                return {
                  ...labTest,
                  displayName: labTest.labTestResponse.name,
                  category: "LAB",
                };
              }),
            ],
            advicesArr: [...advicesArr],
            soapNotes: response?.data?.soapNotes ?? {},
          })
        );
      } else {
        const createResponse = await createPrescriptions(payload);
        try {
          await downloadPDF(selectedAppointment?.id, true, createResponse.data.id);
        } catch (error) {
          console.error(error?.response?.data?.message);
        }
        const response = await getPrescriptionById(createResponse.data.id);
        let advice = response.data?.additionalRecommendations;
        let advicesArr = [];
        if (advice && advice !== "") {
          advicesArr = response.data?.additionalRecommendations.split(",").map((advice) => {
            return {
              category: "ADV",
              displayName: advice.trim(),
            };
          });
        }
        dispatch(
          setPrescriptionPadData({
            diagnosisArr: [
              ...response.data.prescriptionDiagnosisResponses.map((diagnosis) => {
                return {
                  ...diagnosis,
                  displayName: diagnosis.diagnosisResponse.name,
                  category: "DX",
                };
              }),
            ],
            symptomsArr: [
              ...response.data.prescriptionSymptomResponses.map((symptom) => {
                return {
                  ...symptom,
                  displayName: symptom.symptomResponse.name,
                  category: "CC",
                };
              }),
            ],
            medicationsArr: [
              ...response.data.prescriptionMedicationResponses.map((medication) => {
                return {
                  ...medication,
                  displayName: medication.medicationResponse.name,
                  category: "RX",
                };
              }),
            ],
            labInvestigationsArr: [
              ...response.data.prescriptionLabReportResponses.map((labTest) => {
                return {
                  ...labTest,
                  displayName: labTest.labTestResponse.name,
                  category: "LAB",
                };
              }),
            ],
            advicesArr: [...advicesArr],
            soapNotes: response?.data?.soapNotes ?? {},
          })
        );
        dispatch(setPrescriptionId(response.data.id));
      }
      if (selectedAppointment?.patient?.abhaNumber) {
        setIsLinkPrescription(true);
      } else {
        checkForFrontDesk();
      }
    } catch (error) {
      handleClick("error", "There seems to be an error saving prescription");
    }
  };

  const handleLinkRecord = async (linkingApproved) => {
    const params = {
      patientId: selectedAppointment?.patientId,
      organizationId: orgId(),
      recordType: "PRESCRIPTION",
      recordIdToLink: prescriptionId,
      linkingApproved,
    };

    try {
      const response = await linkrecordWithAbha(params);
      if (linkingApproved) {
        handleClick("success", "Record Succesfully linked with your abha");
      }
      setIsLinkPrescription(false);
      // savePrescriptionPad();
      checkForFrontDesk();
    } catch (error) {
      console.error("Error linking record:", error);
    }
  };

  // methods for removing prescription items
  const handleRemoveMedication = (index) => {
    const updatedMedicationsArr = [...currentPrescription.medicationsArr];
    if (updatedMedicationsArr[index].id) {
      const updatedMedication = { ...updatedMedicationsArr[index], status: INACTIVE_STATE };
      setRemovedPrescription({
        ...removedPrescriptionData,
        medicationsArr: [...removedPrescriptionData.medicationsArr, updatedMedication],
      });
    }
    updatedMedicationsArr.splice(index, 1);

    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        medicationsArr: updatedMedicationsArr,
      })
    );
  };

  const handleRemoveSymptom = (index) => {
    const updatedSymptomsArr = [...currentPrescription.symptomsArr];
    if (updatedSymptomsArr[index].id) {
      const updatedSymptom = { ...updatedSymptomsArr[index], status: INACTIVE_STATE };
      setRemovedPrescription({
        ...removedPrescriptionData,
        symptomsArr: [...removedPrescriptionData.symptomsArr, updatedSymptom],
      });
    }
    updatedSymptomsArr.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        symptomsArr: updatedSymptomsArr,
      })
    );
  };

  const handleRemoveDiagnosis = (index) => {
    const updatedDiagnosisArr = [...currentPrescription.diagnosisArr];
    if (updatedDiagnosisArr[index].id) {
      const updatedDiagnosis = { ...updatedDiagnosisArr, status: INACTIVE_STATE };
      setRemovedPrescription({
        ...removedPrescriptionData,
        diagnosisArr: [...removedPrescriptionData.diagnosisArr, updatedDiagnosis],
      });
    }
    updatedDiagnosisArr.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        diagnosisArr: updatedDiagnosisArr,
      })
    );
  };

  const handleRemoveLabInvestigations = (index) => {
    const updatedLabInvestigations = [...currentPrescription.labInvestigationsArr];
    if (updatedLabInvestigations[index].id) {
      const updatedLab = { ...updatedLabInvestigations, status: INACTIVE_STATE };
      setRemovedPrescription({
        ...removedPrescriptionData,
        labInvestigationsArr: [...removedPrescriptionData.labInvestigationsArr, updatedLab],
      });
    }
    updatedLabInvestigations.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        labInvestigationsArr: updatedLabInvestigations,
      })
    );
  };

  const handleRemoveAdvices = (index, key) => {
    const updatedAdvicesArr = [...currentPrescription.advicesArr];
    updatedAdvicesArr.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        advicesArr: updatedAdvicesArr,
      })
    );
  };

  // template functionality
  const [modalOpen, setModalOpen] = useState(false);
  const [templateInfo, setTemplateInfo] = useState({
    templateName: "",
    templateDescription: "",
  });
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [templateMenus, setTemplateMenus] = useState([]);

  useEffect(() => {
    if (templateInfo.templateName.trim() === "") {
      setShowSaveButton(false);
    } else {
      setShowSaveButton(true);
    }
  }, [templateInfo]);

  const handleTemplateInfoChange = (event) => {
    setTemplateInfo({
      ...templateInfo,
      [event.target.name]: event.target.value,
    });
  };

  const closeModal = () => {
    setModalOpen(false);
    setVitalModalOpen(false);
  };

  const handleSaveTemplate = async () => {
    try {
      if (
        templateMenus.find(
          (template) =>
            template.label.trim().toLowerCase() === templateInfo.templateName.trim().toLowerCase()
        )
      ) {
        handleClick("error", "Template already exists with same name.");
        return;
      }
      const templateObject = {
        diagnosis: currentPrescription.diagnosisArr,
        symptoms: currentPrescription.symptomsArr,
        medications: currentPrescription.medicationsArr,
        labInvestigations: currentPrescription.labInvestigationsArr,
        advices: currentPrescription.advicesArr,
      };
      const payload = {
        templateName: templateInfo.templateName.trim(),
        templateContent: JSON.stringify(templateObject),
        description: templateInfo.templateDescription.trim(),
        status: ACTIVE_STATE,
        version: "1.0",
        doctorId: currentActiveUser()?.roleBasedId,
      };
      if (checkIfValueIsPresent(templateObject)) {
        await createTemplates(payload);
        setTemplateLabel(payload.templateName);
        handleGetTemplates(true);
        setSelectedMenuItemLabel(payload.templateName);
      } else {
        handleClick("error", "Template should not be empty.");
      }
      setTemplateInfo({ templateName: "", templateDescription: "" });
      closeModal();
    } catch (error) {
      handleClick("error", "There seems to be an error saving the template");
    }
  };

  const handleGetTemplates = async (calledAfterTemplateCreated) => {
    let templates = [];

    try {
      const templateResponses = await getTemplatesByDocId(currentActiveUser()?.roleBasedId);

      let templatesFromDatabase = templateResponses.data.map((template) => ({
        label: template.templateName,
        templateData: template.templateContent,
        category: "TEMP",
        displayName: template.templateName,
      }));

      // Check if there are no templates and add "NO TEMPLATE AVAILABLE"
      if (templatesFromDatabase.length === 0) {
        templates.push({
          label: "NO TEMPLATE AVAILABLE",
          templateData: "",
          category: "INFO",
          displayName: "NO TEMPLATE AVAILABLE",
          style: { fontStyle: "italic" },
          disabled: true,
          // isNoTemplateAvailable: true,
        });
      } else {
        templates = [...templatesFromDatabase];
      }
    } catch (error) {
      console.error("Error fetching templates");
    } finally {
      templates.push(
        {
          label: "Save This",
          templateData: null,
          category: "ACTION",
          displayName: "SAVE",
          isSaveButton: true,
          icon: <SaveIcon style={{ color: "#004c70" }} />,
        },
        ...(calledAfterTemplateCreated ? [templateClearObj] : [])
      );
      setTemplateMenus(templates);
    }
  };

  const handleSelectTemplate = (template) => {
    if (!template?.templateData && template?.displayName === "SAVE") {
      setVitalModalOpen(false);
      setModalOpen(true);
      return;
    }
    if (template?.label === "Clear") {
      setTemplateLabel("Templates");
      setSelectedMenuItemLabel("");
      dispatch(
        setPrescriptionPadData({
          diagnosisArr: [],
          symptomsArr: [],
          medicationsArr: [],
          labInvestigationsArr: [],
          advicesArr: [],
          soapNotes: {},
        })
      );
      const templates = templateMenus.filter((template) => template?.label !== "Clear");
      setTemplateMenus(templates);
      return;
    }
    const templateData = JSON.parse(template.templateData);
    setSelectedMenuItemLabel(template.label);
    if (!templateMenus.find((template) => template.label === "Clear")) {
      templateMenus.push(templateClearObj);
    }
    setTemplateLabel(template.label);
    dispatch(
      setPrescriptionPadData({
        diagnosisArr: templateData.diagnosis,
        symptomsArr: templateData.symptoms,
        medicationsArr: templateData.medications,
        labInvestigationsArr: templateData.labInvestigations,
        advicesArr: templateData.advices,
        soapNotes: currentPrescription?.soapNotes,
      })
    );
  };

  // update past prescription-pad detail object
  useEffect(() => {
    if (prescriptionIndex === -1) {
      dispatch(
        setPatientPastPrescriptions({
          medicationsArr: [],
          symptomsArr: [],
          diagnosisArr: [],
          labInvestigationsArr: [],
          advicesArr: [],
          soapNotes: {},
        })
      );
      return;
    }
    if (pastPrescriptions?.length > 0) {
      const medicationsArr = pastPrescriptions[
        prescriptionIndex
      ]?.prescriptionMedicationResponses.map((medication) => {
        return {
          prescriptionId: pastPrescriptions[prescriptionIndex]?.id,
          medicationId: medication.medicationId,
          category: "RX",
          displayName: medication.medicationResponse.name,
          frequency: medication.frequency,
          duration: medication.duration,
          instructions: medication.instructions,
          doseTiming: medication.doseTiming,
        };
      });

      const diagnosisArr = pastPrescriptions[prescriptionIndex]?.prescriptionDiagnosisResponses.map(
        (diagnosis) => {
          return {
            prescriptionId: pastPrescriptions[prescriptionIndex]?.id,
            diagnosisId: diagnosis.diagnosisId,
            category: "DX",
            displayName: diagnosis.diagnosisResponse.name,
            conclusions: diagnosis.conclusions,
          };
        }
      );

      const symptomsArr = pastPrescriptions[prescriptionIndex]?.prescriptionSymptomResponses.map(
        (symptom) => {
          return {
            prescriptionId: pastPrescriptions[prescriptionIndex]?.id,
            symptomId: symptom.symptomId,
            category: "CC",
            displayName: symptom.symptomResponse.name,
            since: symptom.since,
            severity: symptom.severity,
          };
        }
      );

      const labInvestigationsArr = pastPrescriptions[
        prescriptionIndex
      ].prescriptionLabReportResponses.map((lab) => {
        return {
          prescriptionId: pastPrescriptions[prescriptionIndex]?.id,
          labTestId: lab.labTestId,
          category: "LAB",
          displayName: lab.labTestResponse.name,
          instructions: lab.instructions,
        };
      });

      const advice = pastPrescriptions[prescriptionIndex]?.additionalRecommendations;
      let advicesArr = [];
      if (advice !== "") {
        advicesArr = pastPrescriptions[prescriptionIndex]?.additionalRecommendations
          ?.split(", ")
          .map((advice) => {
            return {
              category: "ADV",
              displayName: advice,
            };
          });
      }

      let soapNotes = pastPrescriptions[prescriptionIndex]?.soapNotes;

      dispatch(
        setPatientPastPrescriptions({
          diagnosisArr: diagnosisArr,
          symptomsArr: symptomsArr,
          medicationsArr: medicationsArr,
          labInvestigationsArr: labInvestigationsArr,
          advicesArr: advicesArr,
          soapNotes: soapNotes,
        })
      );
    }
  }, [prescriptionIndex, pastPrescriptions]);

  const checkIfValueIsPresent = (obj) => {
    return Object.values(obj).some((res) => res != null && res?.length > 0);
  };

  const checkForFrontDesk = async () => {
    const response = await getUsersByOrganizationId(orgId());
    const frontDeskUserFound = response.data.find((user) => user.roleName === FRONT_DESK);
    try {
      await updateAppointmentStatus(selectedAppointment?.id, PRESCRIPTION_DONE);
      if (frontDeskUserFound) {
        navigate("/home/dashboard");
      } else {
        navigate("/home/generateInvoice", {
          state: {
            appointmentId: selectedAppointment?.id,
            patientId: selectedAppointment.patientId,
            doctorId: selectedAppointment.doctorId,
            orgId: selectedAppointment.orgId,
          },
        });
      }
    } catch (error) {
      handleClick("error", "There seems to be an error completing appointment");
    }
  };

  const handleJoinRoom = useCallback(() => {
    try {
      if (selectedAppointment) {
        const room = selectedAppointment.id?.toString();
        navigate(`/home/prescriptionPad/${room}`, {
          state: {
            roomId: room,
          },
        });
      } else {
        handleClick("error", "Please select an appointment to join");
      }
    } catch (e) {
      handleClick("error", "Not able to join the call");
    }
  }, [selectedAppointment?.id, navigate, handleClick]);

  useEffect(() => {
    if (selectedAppointment?.id && selectedAppointment?.appointmentStatus === COMPLETED) {
      setPrescriptionIndex(0);
    } else if (selectedAppointment?.id) {
      setPrescriptionIndex(-1);
    }
    setTemplateLabel("Templates");
    setSelectedMenuItemLabel("");
  }, [selectedAppointment?.id]);

  return (
    <Reveal className={"mosaic-window-prescription-pad"} style={{ height: "100%", width: "100%" }}>
      {![NO_SHOW, CANCELLED, PRESCRIPTION_DONE].includes(selectedAppointment?.appointmentStatus) ? (
        selectedAppointment?.appointmentStatus === COMPLETED ||
        ([SCHEDULED, RESCHEDULED, CHECKED_IN].includes(selectedAppointment?.appointmentStatus) &&
          selectedAppointment.type === CLINIC_VISIT) ? (
          <div
            className="moasic-prescription-pad"
            style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}
          >
            <div
              className="row justify-content-space-between align-items-center roll-content no-margin"
              style={{ height: "36px", marginBottom: "7px" }}
            >
              <div className="col" style={{ width: "100%" }}>
                <ul
                  className="horizontal-ul"
                  style={{ marginTop: 0, justifyContent: "space-between", alignItems: "center" }}
                >
                  <div
                    style={{
                      display: "flex",
                      // marginLeft: "auto",
                      justifyContent: "end",
                      gap: "1rem",
                      width: prescriptionIndex !== -1 ? "50%" : undefined,
                    }}
                  >
                    {prescriptionIndex === -1 && (
                      <li id="prescription-templates">
                        <CustomButton
                          className={
                            sethuSirLayout?.value ? "btn--secondary" : "mui-btn--secondary-light"
                          }
                          disabled={
                            selectedAppointment?.appointmentStatus === COMPLETED ||
                            prescriptionIndex !== -1
                          }
                          // startIcon={<img src="/images/hc_template.svg" alt="template_svg" />}
                          height="36px"
                          endIcon={<i className="ri-arrow-down-s-fill" />}
                          label={templateLabel}
                          onClick={handleOpen}
                          gap="0px"
                        ></CustomButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            sx: {
                              width: "200px",
                              maxHeight: "400px",
                              display: "flex",
                              flexDirection: "column",
                              borderRadius: "4px",
                            },
                          }}
                        >
                          {/* Scrollable Menu Items */}
                          <Box
                            className="common-scrollbar"
                            sx={{
                              overflowY: "auto",
                              maxHeight: "100px", // Adjust the height for scrollable area
                            }}
                          >
                            {templateMenus
                              .filter((item) => !["Save This", "Clear"].includes(item.label))
                              .map((item, index) => (
                                <MenuItem
                                  sx={{
                                    backgroundColor:
                                      item?.label === selectedMenuItemLabel ? "#29bf91" : "",
                                    color: item?.label === selectedMenuItemLabel ? "#ffffff" : "",
                                    "&:hover": {
                                      backgroundColor:
                                        item?.label === selectedMenuItemLabel ? "#29bf91" : "",
                                      color: item?.label === selectedMenuItemLabel ? "#ffffff" : "",
                                    },
                                  }}
                                  disabled={item.disabled ? true : false}
                                  style={item.style ? item.style : {}}
                                  onClick={() => {
                                    handleSelectTemplate(item);
                                    handleClose();
                                  }}
                                  key={index}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                          </Box>

                          {/* Fixed Action Items */}
                          <Box
                            sx={{
                              borderTop: "1px solid #ddd",
                              mt: 0.5,
                            }}
                          >
                            {templateMenus
                              .filter((item) => ["Save This", "Clear"].includes(item.label))
                              .map((action, index) => (
                                <MenuItem
                                  key={`action-${index}`}
                                  onClick={() => {
                                    handleSelectTemplate(action);
                                    handleClose();
                                  }}
                                >
                                  {action.icon}
                                  <Box component="span" sx={{ ml: 1 }}>
                                    {action.label}
                                  </Box>
                                </MenuItem>
                              ))}
                          </Box>
                        </Menu>
                      </li>
                    )}
                    <li id="prescription-end-consultation">
                      <CustomButton
                        height="36px"
                        disabled={
                          selectedAppointment?.appointmentStatus === COMPLETED ||
                          prescriptionIndex !== -1
                        }
                        className={`${
                          sethuSirLayout?.value ? "btn--primary" : "btn--primary-light"
                        } save-button-for-big-width`}
                        startIcon={state?.isEditable ? <Edit /> : <DoneAll />}
                        label={state?.isEditable ? "Save changes" : "Save & Next"}
                        onClick={isDemo() ? null : savePrescriptionPad}
                        gap="0px"
                      ></CustomButton>
                      <CustomButton
                        height="36px"
                        disabled={
                          selectedAppointment?.appointmentStatus === COMPLETED ||
                          prescriptionIndex !== -1
                        }
                        className={`${
                          sethuSirLayout?.value ? "btn--primary" : "btn--primary-light"
                        } save-button-for-medium-width`}
                        startIcon={state?.isEditable ? <Edit /> : <DoneAll />}
                        label={state?.isEditable ? "Save" : "Save"}
                        onClick={isDemo() ? null : savePrescriptionPad}
                        gap="0px"
                      ></CustomButton>
                      <Tooltip title={"Save & Next"}>
                        <CustomButton
                          height="36px"
                          disabled={
                            selectedAppointment?.appointmentStatus === COMPLETED ||
                            prescriptionIndex !== -1
                          }
                          className={`${
                            sethuSirLayout?.value ? "btn--primary" : "btn--primary-light"
                          } save-button-for-small-width`}
                          iconButton={state?.isEditable ? <Edit /> : <DoneAll />}
                          onClick={isDemo() ? null : savePrescriptionPad}
                          gap="0px"
                        ></CustomButton>
                      </Tooltip>
                    </li>
                  </div>
                  {prescriptionIndex !== -1 && (
                    <Typography
                      sx={{
                        fontFamily: "Mitr",
                        fontWeight: "400",
                        fontSize: { md: "13px", lg: "14px", xl: "15px" },
                        color: "#004C70",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {dayjs(
                        pastPrescriptions[prescriptionIndex]?.appointmentResponse?.appointmentDate
                      ).format("DD MMM YYYY")}
                    </Typography>
                  )}
                  <li
                    className="main-actions"
                    style={{
                      marginLeft: "0px",
                      marginRight: "0.5rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "0.2rem",
                      width: prescriptionIndex !== -1 ? "50%" : undefined,
                    }}
                  >
                    <div
                      id="prescription-past-prescriptions"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <IconButton
                        onClick={() => {
                          setPrescriptionIndex((prev) => prev + 1);
                        }}
                        disabled={prescriptionIndex === pastPrescriptions.length - 1}
                        style={{ paddingLeft: "5px", paddingRight: "5px" }}
                      >
                        <KeyboardArrowLeftIcon
                          style={{
                            color:
                              prescriptionIndex === pastPrescriptions.length - 1 ? "" : "#004C70",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </IconButton>
                      <Typography
                        className="past-prescription-button-for-big-width"
                        sx={{
                          margin: "0px",
                          fontWeight: "500",
                          color: "#004c70",
                          fontSize: { md: "12px", lg: "13px", xl: "14px" },
                        }}
                      >
                        Prescriptions
                      </Typography>
                      <Tooltip
                        className="past-prescription-button-for-small-to-medium-width"
                        title="Prescriptions"
                      >
                        <Typography
                          sx={{
                            margin: "0px",
                            fontWeight: "500",
                            color: "#004c70",
                            fontSize: { md: "12px", lg: "13px", xl: "14px" },
                          }}
                        >
                          Pres...
                        </Typography>
                      </Tooltip>

                      <IconButton
                        onClick={() => {
                          setPrescriptionIndex((prev) => prev - 1);
                        }}
                        disabled={
                          prescriptionIndex === -1 ||
                          (selectedAppointment.appointmentStatus === COMPLETED &&
                            prescriptionIndex === 0)
                        }
                        style={{ paddingLeft: "5px", paddingRight: "5px" }}
                      >
                        <KeyboardArrowRightIcon
                          style={{
                            color:
                              prescriptionIndex === -1 ||
                              (selectedAppointment.appointmentStatus === COMPLETED &&
                                prescriptionIndex === 0)
                                ? ""
                                : "#004C70",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </IconButton>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="common-scrollbar"
              style={{
                marginBottom: "0px",
                flexGrow: 1,
                maxHeight: "calc(100% - 43px)",
                overflowY: "auto",
              }}
            >
              <Card
                className="custom-card-ui-new"
                style={{
                  backgroundColor: "white",
                  // padding: "24px 15px",
                  scrollbarWidth: "none",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  className="common-scrollbar prescription-pad-for-big-width"
                  style={{ display: "flex", maxHeight: "100%", overflowY: "auto" }}
                >
                  <div
                    className="common-scrollbar"
                    style={{
                      width:
                        (currentPrescription?.soapNotes
                          ? Object.keys(currentPrescription?.soapNotes).length > 0
                          : false) ||
                        (previousPrescription?.soapNotes
                          ? Object.keys(previousPrescription?.soapNotes).length > 0
                          : false)
                          ? "50%"
                          : "100%",
                      transition: "width 0.5s ease-in-out",
                      marginRight:
                        (currentPrescription?.soapNotes
                          ? Object.keys(currentPrescription?.soapNotes).length > 0
                          : false) ||
                        (previousPrescription?.soapNotes
                          ? Object.keys(previousPrescription?.soapNotes).length > 0
                          : false)
                          ? "0.5rem"
                          : undefined,
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: "100%",
                      overflowY: "auto",
                    }}
                  >
                    {prescriptionIndex === -1 && (
                      <div style={{ marginBottom: "0.5rem" }}>
                        <div id="prescription-clinic-search">
                          <PrescriptionSearch
                            templates={templateMenus}
                            handleSelectTemplate={handleSelectTemplate}
                            setTranscript={setTranscript}
                            patientId={selectedAppointment?.patientId}
                          />
                        </div>
                      </div>
                    )}

                    <div
                      className="common-scrollbar"
                      style={{
                        maxHeight: "100%",
                        overflowY: "auto",
                        flexGrow: 1,
                      }}
                    >
                      <PrescriptionCard
                        key="Cc"
                        cardHeaderTitle="Cc"
                        values={
                          prescriptionIndex === -1
                            ? currentPrescription.symptomsArr
                            : previousPrescription.symptomsArr
                        }
                        onDelete={handleRemoveSymptom}
                        isEditable={prescriptionIndex === -1}
                      ></PrescriptionCard>

                      <PrescriptionCard
                        key="Dx"
                        cardHeaderTitle="Dx"
                        values={
                          prescriptionIndex === -1
                            ? currentPrescription.diagnosisArr
                            : previousPrescription.diagnosisArr
                        }
                        onDelete={handleRemoveDiagnosis}
                        isEditable={prescriptionIndex === -1}
                      ></PrescriptionCard>

                      <PrescriptionCard
                        key="Rx"
                        cardHeaderTitle="Rx"
                        cardContentField="Medicines"
                        values={
                          prescriptionIndex === -1
                            ? currentPrescription.medicationsArr
                            : previousPrescription.medicationsArr
                        }
                        onDelete={handleRemoveMedication}
                        isEditable={prescriptionIndex === -1}
                      ></PrescriptionCard>

                      <PrescriptionCard
                        key="Lab"
                        cardHeaderTitle="Lab"
                        values={
                          prescriptionIndex === -1
                            ? currentPrescription.labInvestigationsArr
                            : previousPrescription.labInvestigationsArr
                        }
                        onDelete={handleRemoveLabInvestigations}
                        isEditable={prescriptionIndex === -1}
                      ></PrescriptionCard>

                      <PrescriptionCard
                        key="Adv"
                        cardHeaderTitle="Adv"
                        values={
                          prescriptionIndex === -1
                            ? currentPrescription.advicesArr
                            : previousPrescription.advicesArr
                        }
                        onDelete={handleRemoveAdvices}
                        isEditable={prescriptionIndex === -1}
                      ></PrescriptionCard>
                    </div>
                  </div>
                  <div
                    className="common-scrollbar"
                    style={{
                      width:
                        (currentPrescription?.soapNotes
                          ? Object.keys(currentPrescription?.soapNotes).length > 0
                          : false) ||
                        (previousPrescription?.soapNotes
                          ? Object.keys(previousPrescription?.soapNotes).length > 0
                          : false)
                          ? "50%"
                          : "0%",
                      transition: "width 0.5s ease-in-out",
                      maxHeight: "100%",
                      overflowY: "auto",
                    }}
                  >
                    <PrescriptionCard
                      key="SOAP"
                      cardHeaderTitle="SOAP"
                      values={
                        prescriptionIndex === -1
                          ? (
                              currentPrescription?.soapNotes
                                ? Object.keys(currentPrescription.soapNotes)?.length
                                : false
                            )
                            ? [currentPrescription.soapNotes]
                            : []
                          : (
                              previousPrescription?.soapNotes
                                ? Object.keys(previousPrescription.soapNotes)?.length
                                : false
                            )
                          ? [previousPrescription.soapNotes]
                          : []
                      }
                      isEditable={prescriptionIndex === -1}
                    ></PrescriptionCard>
                  </div>
                </div>
                <div
                  className="common-scrollbar prescription-pad-for-small-width"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "100%",
                    overflowY: "auto",
                  }}
                >
                  {prescriptionIndex === -1 && (
                    <div style={{ marginBottom: "0.5rem" }}>
                      <div id="prescription-clinic-search">
                        <PrescriptionSearch
                          templates={templateMenus}
                          handleSelectTemplate={handleSelectTemplate}
                          setTranscript={setTranscript}
                          patientId={selectedAppointment?.patientId}
                        />
                      </div>
                    </div>
                  )}

                  <div
                    className="common-scrollbar"
                    style={{
                      flexGrow: 1,
                      maxHeight: "100%",
                      overflowY: "auto",
                    }}
                  >
                    <PrescriptionCard
                      key="SOAP"
                      cardHeaderTitle="SOAP"
                      values={
                        prescriptionIndex === -1
                          ? (
                              currentPrescription?.soapNotes
                                ? Object.keys(currentPrescription.soapNotes)?.length
                                : false
                            )
                            ? [currentPrescription.soapNotes]
                            : []
                          : (
                              previousPrescription?.soapNotes
                                ? Object.keys(previousPrescription.soapNotes)?.length
                                : false
                            )
                          ? [previousPrescription.soapNotes]
                          : []
                      }
                      isEditable={prescriptionIndex === -1}
                    ></PrescriptionCard>

                    <PrescriptionCard
                      key="Cc"
                      cardHeaderTitle="Cc"
                      values={
                        prescriptionIndex === -1
                          ? currentPrescription.symptomsArr
                          : previousPrescription.symptomsArr
                      }
                      onDelete={handleRemoveSymptom}
                      isEditable={prescriptionIndex === -1}
                    ></PrescriptionCard>

                    <PrescriptionCard
                      key="Dx"
                      cardHeaderTitle="Dx"
                      values={
                        prescriptionIndex === -1
                          ? currentPrescription.diagnosisArr
                          : previousPrescription.diagnosisArr
                      }
                      onDelete={handleRemoveDiagnosis}
                      isEditable={prescriptionIndex === -1}
                    ></PrescriptionCard>

                    <PrescriptionCard
                      key="Rx"
                      cardHeaderTitle="Rx"
                      cardContentField="Medicines"
                      values={
                        prescriptionIndex === -1
                          ? currentPrescription.medicationsArr
                          : previousPrescription.medicationsArr
                      }
                      onDelete={handleRemoveMedication}
                      isEditable={prescriptionIndex === -1}
                    ></PrescriptionCard>

                    <PrescriptionCard
                      key="Lab"
                      cardHeaderTitle="Lab"
                      values={
                        prescriptionIndex === -1
                          ? currentPrescription.labInvestigationsArr
                          : previousPrescription.labInvestigationsArr
                      }
                      onDelete={handleRemoveLabInvestigations}
                      isEditable={prescriptionIndex === -1}
                    ></PrescriptionCard>

                    <PrescriptionCard
                      key="Adv"
                      cardHeaderTitle="Adv"
                      values={
                        prescriptionIndex === -1
                          ? currentPrescription.advicesArr
                          : previousPrescription.advicesArr
                      }
                      onDelete={handleRemoveAdvices}
                      isEditable={prescriptionIndex === -1}
                    ></PrescriptionCard>
                  </div>
                </div>
              </Card>
            </div>
            <ModalUI
              visible={modalOpen || vitalModalOpen}
              close={closeModal}
              title={modalOpen ? "Save Prescription as Template" : "Add Patient Vital Sign"}
              component={
                <>
                  {modalOpen ? (
                    <div style={{ width: "500px" }}>
                      <FormInputField
                        style={{ width: "60%", marginTop: "20px" }}
                        label={"Template Name *"}
                        name={"templateName"}
                        inputProps={{ maxLength: 30 }}
                        value={templateInfo.templateName}
                        onChange={handleTemplateInfoChange}
                        startAdornment={<DriveFileRenameOutlineIcon></DriveFileRenameOutlineIcon>}
                      ></FormInputField>
                      <FormInputField
                        style={{ width: "100%", marginTop: "20px" }}
                        label="Template Description"
                        name="templateDescription"
                        inputProps={{ maxLength: 100 }}
                        value={templateInfo.templateDescription}
                        onChange={handleTemplateInfoChange}
                        startAdornment={<DescriptionIcon></DescriptionIcon>}
                      ></FormInputField>
                      <div style={{ marginTop: "20px", display: "flex" }}>
                        <CustomButton
                          label="Save"
                          height="36px"
                          className="mui-btn--primary"
                          style={{ marginRight: "30px" }}
                          onClick={isDemo() ? closeModal() : handleSaveTemplate}
                          disabled={!showSaveButton}
                        ></CustomButton>
                        <CustomButton
                          height="36px"
                          label="Cancel"
                          className="btn--secondary-light"
                          onClick={() => {
                            setTemplateInfo({
                              templateName: "",
                              templateDescription: "",
                            });
                            closeModal();
                          }}
                        ></CustomButton>
                      </div>
                    </div>
                  ) : (
                    <PatientVitalSignModal
                      selected={null}
                      close={(operation) => {
                        if (operation === "save") {
                          closeModal();
                          fetchPatientLatestVitalSigns(
                            selectedAppointment?.patientId,
                            setPatientLatestVitals,
                            handleClick
                          );
                        } else {
                          closeModal();
                        }
                      }}
                      apData={selectedAppointment}
                      patientId={selectedAppointment?.patientId}
                      vitalSignTypes={vitalSignTypes}
                    ></PatientVitalSignModal>
                  )}
                </>
              }
            ></ModalUI>

            <ModalUI
              visible={isLinkPrescription}
              close={handleCloseLinkModal}
              title={"Link Record"}
              style={{
                height: "200px",
                width: "410px",
              }}
              component={
                <div style={{ marginTop: "15px" }}>
                  <p>Do you want to link this prescription with ABHA?</p>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                      padding: "10px",
                    }}
                  >
                    <CustomButton
                      label={"Yes"}
                      className={"btn--secondary"}
                      onClick={() => handleLinkRecord(true)}
                    />

                    <CustomButton
                      label={"No"}
                      className={"btn--secondary"}
                      onClick={() => handleLinkRecord(false)}
                    />
                  </div>
                </div>
              }
            ></ModalUI>
          </div>
        ) : (
          <>
            {[SCHEDULED, RESCHEDULED, CHECKED_IN].includes(
              selectedAppointment?.appointmentStatus
            ) &&
              selectedAppointment.type === TELE_CONSULTATION && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "100%",
                    }}
                  >
                    <CustomButton
                      className={"mui-btn--primary"}
                      label={"Join Meet"}
                      gap={"0px"}
                      startIcon={<PlayCircleOutline></PlayCircleOutline>}
                      onClick={() => {
                        // handleVideoCall();
                        dispatch(
                          setPrescriptionPadData({
                            diagnosisArr: [],
                            symptomsArr: [],
                            medicationsArr: [],
                            labInvestigationsArr: [],
                            advicesArr: [],
                            soapNotes: {},
                          })
                        );
                        dispatch(setClearInvoiceState());
                        dispatch(setPrescriptionId(null));
                        if (selectedAppointment.type === TELE_CONSULTATION) {
                          handleJoinRoom();
                        } else {
                          navigate(`prescriptionPad/${selectedAppointment?.id}`);
                        }
                      }}
                    />
                  </div>
                </>
              )}
          </>
        )
      ) : (
        <>
          {selectedAppointment?.appointmentStatus === PRESCRIPTION_DONE ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  minHeight: "100%",
                  gap: "0.5rem",
                }}
              >
                <Typography variant="h4" color={"#004c70"}>
                  Prescription has been created
                </Typography>
                <CustomButton
                  className={"btn--secondary"}
                  label={"Continue with Billing"}
                  onClick={() => {
                    navigate("/home/generateInvoice", {
                      state: {
                        appointmentId: selectedAppointment?.id,
                        patientId: selectedAppointment?.patientId,
                        doctorId: selectedAppointment?.doctorId,
                        orgId: selectedAppointment?.orgId,
                      },
                    });
                  }}
                  gap={"0px"}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100%",
              }}
            >
              <Typography variant="h4" color={"#004c70"}>
                {selectedAppointment?.appointmentStatus === CANCELLED
                  ? "Appointment is cancelled!"
                  : "Patient did not show up!"}
              </Typography>
            </div>
          )}
        </>
      )}

      {![NO_SHOW, CANCELLED].includes(selectedAppointment?.appointmentStatus) && (
        <div className="view-for-small-container">Too small to display contents</div>
      )}
    </Reveal>
  );
};

export default PrescriptionPadForClinicVisitCV1;
