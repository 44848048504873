import React from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from "@mui/material";

const FormInputField = ({
  label,
  labelStyle,
  className,
  name,
  value,
  onBlur,
  onChange,
  error,
  errorText,
  startAdornment,
  endAdorment,
  adornmentStyle,
  style,
  outlinedInputStyle,
  inputPropStyle,
  size = "big",
  inputProps,
  background,
  border,
  borderOnFocus,
  disableBorder,
  borderRadius = "6px",
  required = false,
  shrink,
  type,
  disabled = false,
  kind = null,
  ...restProps
}) => {
  const handleChange = (event) => {
    if (event.target.type === "tel") {
      let cleanedValue = event.target.value.replace(/\D/g, "");

      if (event.target.name === "aadharNumber" || kind === "aadhaarNubmer") {
        if (cleanedValue.length > 12) {
          cleanedValue = cleanedValue.slice(0, 12);
        }

        if (cleanedValue.length > 4) {
          cleanedValue = `${cleanedValue.slice(0, 4)} ${cleanedValue.slice(4)}`;
        }
        if (cleanedValue.length > 9) {
          cleanedValue = `${cleanedValue.slice(0, 9)} ${cleanedValue.slice(9)}`;
        }
      } else if (event.target.name === "abha" || kind === "abhaNumber") {
        if (cleanedValue.length > 14) {
          cleanedValue = cleanedValue.slice(0, 14);
        }

        if (cleanedValue.length > 2) {
          cleanedValue = `${cleanedValue.slice(0, 2)}-${cleanedValue.slice(2)}`;
        }
        if (cleanedValue.length > 7) {
          cleanedValue = `${cleanedValue.slice(0, 7)}-${cleanedValue.slice(7)}`;
        }
        if (cleanedValue.length > 12) {
          cleanedValue = `${cleanedValue.slice(0, 12)}-${cleanedValue.slice(12)}`;
        }
      } else if (kind === "otp") {
        if (cleanedValue.length > 6) {
          cleanedValue = `${cleanedValue.slice(0, 6)}`;
        }
      } else {
        if (cleanedValue.length > 10) {
          cleanedValue = cleanedValue.slice(0, 10);
        }
      }
      event.target.value = cleanedValue;
    }

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <FormControl className={className} error={error} style={style}>
      {label && (
        <InputLabel
          sx={{
            marginLeft:
              shrink === undefined
                ? value !== "" && value !== null
                  ? "0px"
                  : "35px"
                : shrink
                ? "0px"
                : "35px",
            transform:
              size === "small" && (value === "" || value === null)
                ? "translate(14px, 11px)"
                : undefined,
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            ...labelStyle,
          }}
          size={size}
          required={required}
          shrink={shrink === undefined ? value !== "" && value !== null : shrink}
          htmlFor={`outlined-adornment-${name}`}
        >
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: disableBorder ? "none" : border ? border : undefined,
            borderRadius: `${borderRadius} !important`,
          },
          "&:hover fieldset": {
            border: `${border} !important`,
          },
          "&.Mui-focused fieldset": {
            border: `${borderOnFocus} !important`,
            transition: "0.5s ease",
          },
          background: background,
          paddingLeft: startAdornment ? "15px !important" : "0px !important",
          ...outlinedInputStyle,
        }}
        startAdornment={
          <InputAdornment
            position="start"
            style={{
              marginRight: "0px",
              cursor: "default",
              ...adornmentStyle,
            }}
          >
            {startAdornment}
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment
            position="end"
            style={{
              marginRight: "0px",
              cursor: "default",
              ...adornmentStyle,
            }}
          >
            {endAdorment}
          </InputAdornment>
        }
        size={size}
        inputProps={{
          // Pass inputProps to customize the input element
          sx: {
            paddingLeft: startAdornment ? "10px !important" : "14px !important", // Include other inputProps passed from outside
            background: background,
            cursor: disabled ? "not-allowed" : "default",
            // "&::placeholder": {
            //   color: "black",
            //   // fontWeight: "400 ",
            //   fontSize: "16px",
            // },
            ...inputPropStyle,
          },
          ...inputProps,
        }}
        id={`outlined-adornment-${name}`}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
        type={type}
        disabled={disabled}
        label={
          shrink === undefined ? (value !== "" && value !== null ? label : "") : shrink ? label : ""
        }
        {...restProps}
      />
      {error && <FormHelperText error>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default FormInputField;
