import {
  CancelOutlined,
  DomainVerification,
  EventOutlined,
  HealthAndSafetyOutlined,
  Liquor,
  SmokingRoomsOutlined,
  Visibility,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Menu,
  MenuList,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { getAppointmentsByPatientId, updateAppointmentStatus } from "services/Appointments";
import { getPatientLifestyleByPatientId, getProfilePicture } from "services/patientService";
import { CHECKED_IN, COMPLETED, hiuUrl } from "store/constant";
import { setSelectedAppointmentData } from "store/Slices/appointmentDataSlice";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomMenuItem from "ui-component/custom-components/CustomMenuItem";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { calculateAge } from "utils/calculate-age";
import "assets/scss/DoctorHomeScreen/PatientPrescriptionDetails/patient-prescription-card.scss";
import ModalUI from "ui-component/ModalUI";
import PatientVitalSignModal from "views/Components/Patient/PatientProfile/PatientVitalSignModal";
import { getVitalSignTypes } from "services/EntitiesServices";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  getHasUpdates,
  setHasUpdates,
  setPrescriptionId,
  setPrescriptionPadData,
} from "store/Slices/prescriptionPadSlice";
import { useSelector } from "react-redux";
import { setClearInvoiceState } from "store/Slices/billingInvoiceSlice";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import {
  getSelectedAppointmentOnDashboard,
  setCancelledAppointment,
} from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/appointmentSlice";
import {
  getSethuSirLayout,
  setIframeOpen,
  setIframeSrc,
  setRefreshAppointmntsList,
} from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/utilSlice";
import { setNewVitalAdded } from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/patientVitalSlice";
import ComponentLoader from "ui-component/custom-components/ComponentLoader";

const PatientPrescriptionCardCV1 = () => {
  const [profileImageFileWithContent, setProfileImageFileWithContent] = useState(null);
  const [isProfilePicLoading, setIsProfilePicLoading] = useState(false);
  const { handleClick } = useContext(ToastContext);

  const sethuSirLayout = useSelector(getSethuSirLayout);

  const [loading, setLoading] = useState(true);

  const selectedAppointment = useSelector(getSelectedAppointmentOnDashboard)?.value;

  const [vitalModalOpen, setVitalModalOpen] = useState(false);
  const [vitalSignTypes, setVitalSignTypes] = useState([]);

  const [showVisited, setShowVisited] = useState(false);
  const [lastVisited, setLastVisited] = useState(null);

  const dispatch = useDispatch();
  const hasUpdates = useSelector(getHasUpdates);

  const [lifestyleHabits, setLifestyleHabits] = useState({
    Eggetarian: false,
    "Non-vegetarian": false,
    Vegetarian: false,
    "Consuming Tobacco": false,
    Drinking: false,
    Smoking: false,
  });

  const closeModal = () => {
    setVitalModalOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [isBookAppointmentModalOpen, setIsBookAppointmentModalOpen] = useState(false);

  const handleNewModalOpenForReschedulingAppointment = (selectedAppointment) => {
    const appointment = {
      id: selectedAppointment?.id,
      patientMobile:
        selectedAppointment?.patient?.user?.mobileNumber ||
        selectedAppointment?.patientMobileNumber,
      patientName: selectedAppointment?.patient?.user?.name || selectedAppointment?.patientName,
      orgId: selectedAppointment?.orgId,
      appointmentTime: selectedAppointment?.appointmentTime.slice(0, -3),
      patientId: selectedAppointment?.patientId,
      doctorId: selectedAppointment?.doctorId,
      appointmentDate: selectedAppointment?.appointmentDate,
      notes: selectedAppointment?.notes,
      type: selectedAppointment?.type,
    };

    dispatch(setSelectedAppointmentData(appointment));
    setIsBookAppointmentModalOpen(true);
  };

  const handleNewModalClose = () => {
    setIsBookAppointmentModalOpen(false);
    dispatch(setRefreshAppointmntsList(true));
    dispatch(setSelectedAppointmentData({}));
  };

  // dialog box
  const [openDialogForCancelAppointment, setOpenDialogForCancelAppointment] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialogForCancelAppointment(true);
  };

  const handleDialogYes = async () => {
    setOpenDialogForCancelAppointment(false);
    // delete appointment, and fetch current appointments as events
    // todo : currently all appointments are shown, need to filter based on active/inactive once dto changed
    try {
      await updateAppointmentStatus(selectedAppointment.id, "CANCELLED");
      handleClick("success", "Appointment cancelled successfully!");
      dispatch(setCancelledAppointment(selectedAppointment));
      dispatch(setRefreshAppointmntsList(true));
    } catch (error) {
      handleClick("error", "There seems to be an error deleting the appointment");
    }
  };

  const handleDialogNo = () => {
    setOpenDialogForCancelAppointment(false);
  };

  const handleCancelAppointment = (appointment) => {
    handleDialogOpen();
  };

  const navigate = useNavigate();
  const actions = [
    {
      label: "Reschedule",
      icon: <EventOutlined style={{ color: "#004c70" }} />,
      onClick: () => {
        handleNewModalOpenForReschedulingAppointment(selectedAppointment);
      },
    },
    {
      label: "Check-In",
      icon: <DomainVerification style={{ color: "#29bf91" }} />,
      onClick: async () => {
        try {
          await updateAppointmentStatus(selectedAppointment?.id, CHECKED_IN);
          handleClick("success", "Patient Checked-in successfully!");
          selectedAppointment.appointmentStatus = CHECKED_IN;
          dispatch(setRefreshAppointmntsList(true));
        } catch {
          handleClick("error", "There seems to be an error in making patient checked-in");
        }
      },
      disabled: selectedAppointment?.appointmentStatus !== CHECKED_IN ? false : true,
    },
    {
      label: "View EHR",
      icon: <Visibility style={{ color: "#004c70" }} />,
      onClick: () => {
        navigate(`/home/ehr/${selectedAppointment?.patientId}`);
      },
    },
    {
      label: "Add Vital",
      icon: <HealthAndSafetyOutlined style={{ color: "#29bf91" }} />,
      onClick: () => {
        const fetchVitalSignTypes = async () => {
          try {
            const response = await getVitalSignTypes();
            setVitalSignTypes(response.data);
          } catch (error) {
            console.error("Error fetching Vital sign types");
          }
        };
        fetchVitalSignTypes();
        setVitalModalOpen(true);
      },
    },
    {
      label: "Fetch Abha Records",
      icon: <img height="20px" src="/images/abha_logo.svg" alt="abha_logo"></img>,
      onClick: () => {
        dispatch(setIframeOpen(true));
        dispatch(
          setIframeSrc(hiuUrl(selectedAppointment?.doctorId, selectedAppointment?.patient?.abhaId))
        );
      },
      disabled: selectedAppointment?.patient?.abhaId ? false : true,
    },
    {
      label: "Cancel",
      icon: <CancelOutlined style={{ color: "#f5574c" }} />,
      onClick: () => {
        handleCancelAppointment(selectedAppointment);
      },
    },
  ];

  const fetchProfilePic = async () => {
    setIsProfilePicLoading(true);
    try {
      const profilePicResponse = await getProfilePicture(
        selectedAppointment?.patientId || selectedAppointment?.id
      );
      const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;
      const profilePicWithContent = {
        filename: profilePicResponse.data.filename,
        content: content,
      };
      setProfileImageFileWithContent(profilePicWithContent);
    } catch (error) {
      setProfileImageFileWithContent(null);
      console.error("Error fetching profile picture", error);
    } finally {
      setIsProfilePicLoading(false);
    }
  };

  const fetchLifestyleHabits = async (showLoader = true) => {
    try {
      if (!selectedAppointment?.patientId) return;

      const lifestyleResponse = await getPatientLifestyleByPatientId(
        selectedAppointment?.patientId,
        true,
        showLoader
      );

      const matchedHabits = lifestyleResponse.data.flatMap((responseObj) => {
        const responseArray = responseObj.response.split(",");
        return responseArray.filter((response) => lifestyleHabits.hasOwnProperty(response.trim()));
      });

      // re-initialize, when patient id change, to avoid data being persisted
      const updatedLifestyleHabits = {
        Eggetarian: false,
        "Non-vegetarian": false,
        Vegetarian: false,
        "Consuming Tobacco": false,
        Drinking: false,
        Smoking: false,
      };

      matchedHabits.forEach((habit) => {
        if (!updatedLifestyleHabits[habit]) {
          updatedLifestyleHabits[habit] = true;
        }
      });
      setLifestyleHabits(updatedLifestyleHabits);
    } catch (error) {
      console.error("Error fetching data");
    }
  };

  const fetchPatientLastVisited = async () => {
    try {
      const now = dayjs();
      if (selectedAppointment?.patientId) {
        const { data } = await getAppointmentsByPatientId(selectedAppointment?.patientId);
        const filteredAppointments = data.filter(
          (appointment) => appointment.appointmentStatus === COMPLETED
        );
        if (filteredAppointments.length !== 0) {
          const recent = filteredAppointments.reduce((mostRecent, current) => {
            const currentDate = dayjs(current.appointmentDate);
            const mostRecentDate = dayjs(mostRecent.appointmentDate);
            return currentDate > mostRecentDate ? current : mostRecent;
          });
          setLastVisited(now.diff(dayjs(recent.appointmentDate), "day"));
          setShowVisited(true);
        }
      }
    } catch (error) {
      console.error("Error fetching latest appointment.");
    }
  };

  useEffect(() => {
    const fetchUpdatedData = async () => {
      await fetchLifestyleHabits(false);
      dispatch(setHasUpdates(false));
    };
    if (hasUpdates) {
      fetchUpdatedData();
    }
  }, [hasUpdates]);

  const fetchAllData = async () => {
    try {
      if (!selectedAppointment?.patientId) return;

      // Define all API calls
      if (!loading) {
        setLoading(true);
      }
      const patientLastVisited = fetchPatientLastVisited();
      const lifestyle = fetchLifestyleHabits();
      const patientProfile = fetchProfilePic();

      // Clear prescription pad data
      dispatch(
        setPrescriptionPadData({
          diagnosisArr: [],
          symptomsArr: [],
          medicationsArr: [],
          labInvestigationsArr: [],
          advicesArr: [],
          soapNotes: {},
        })
      );
      dispatch(setPrescriptionId(null));
      dispatch(setClearInvoiceState());

      // Fetch all data concurrently
      await Promise.all([patientLastVisited, lifestyle, patientProfile]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, [selectedAppointment?.patientId]);

  return (
    <div className="patient-card">
      {!loading ? (
        <div className="patient-card-container common-scrollbar common-scrollbar-x">
          <Grid size={{ md: 12 }} className="px-0">
            <div
              className="profile-section"
              style={{
                justifyContent: sethuSirLayout?.value ? undefined : "space-between",
                gap: sethuSirLayout?.value ? "0.3rem" : undefined,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: sethuSirLayout?.value ? undefined : "space-between",
                }}
              >
                <div className="patient-avatar-container">
                  {isProfilePicLoading ? (
                    <Skeleton variant="circular" animation="wave" width={"50px"} height={"50px"} />
                  ) : (
                    <img
                      style={{
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                      }}
                      src={
                        profileImageFileWithContent
                          ? profileImageFileWithContent.content
                          : (selectedAppointment?.patient?.userResponse?.gender ||
                              selectedAppointment?.patient?.gender) === "FEMALE"
                          ? "/images/woman.png"
                          : "/images/man.png"
                      }
                      alt=""
                    />
                  )}
                </div>
                <div className="patient-details-container">
                  <div className="patient-name-block">
                    <Tooltip
                      title={
                        selectedAppointment?.patient?.userResponse?.name ||
                        selectedAppointment?.patient?.patientName
                      }
                    >
                      <p className="patient-name">
                        {selectedAppointment?.patient?.userResponse?.name ||
                          selectedAppointment?.patient?.patientName}
                      </p>
                    </Tooltip>
                    {selectedAppointment?.appointmentStatus === CHECKED_IN && (
                      <div className="checked-in-status">
                        <Tooltip title={"Checked-in"}>
                          <i className="ri-checkbox-circle-fill ri-xl icon-primary-green" />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <p className="extra-details">
                    {selectedAppointment?.patient?.userResponse?.dateOfBirth && (
                      <span className="detail">{`${calculateAge(
                        selectedAppointment?.patient?.userResponse?.dateOfBirth
                      )}y`}</span>
                    )}
                    {selectedAppointment?.patient?.userResponse?.gender && (
                      <span className="detail">
                        {selectedAppointment?.patient?.userResponse?.gender.charAt(0)}
                      </span>
                    )}
                    {selectedAppointment?.patient?.userResponse?.mobileNumber && (
                      <span className="detail">
                        {selectedAppointment?.patient?.userResponse?.mobileNumber}
                      </span>
                    )}
                  </p>
                  {showVisited && (
                    <div className="last-visited">
                      <img src="/images/hc_rx.svg" alt="hc_rx"></img>
                      <span style={{ padding: "0 6px" }}>
                        Last visited {lastVisited !== 0 ? lastVisited : ""}{" "}
                        {lastVisited <= 1 ? (lastVisited === 0 ? "today" : "day ago") : "days ago"}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {!sethuSirLayout?.value && (
                <div className="menu-button">
                  <div>
                    <CustomButton
                      iconButton={<i className="ri-more-2-fill" />}
                      onClick={(event) => handleMenuOpen(event)}
                      style={{ padding: "3px" }}
                    ></CustomButton>
                    <Menu
                      elevation={1}
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                      PaperProps={{ style: { borderRadius: "4px" } }}
                    >
                      <MenuList dense sx={{ p: 0 }}>
                        {actions.map((action, actionIndex) => {
                          return (
                            <CustomMenuItem
                              key={actionIndex}
                              text={action.label}
                              icon={action.icon}
                              onClick={() => {
                                handleMenuClose();
                                action.onClick();
                              }}
                              disabled={action?.disabled}
                            />
                          );
                        })}
                      </MenuList>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </Grid>
          <div className="lifestyle-section">
            <div className="lifestyle">
              <Tooltip title={lifestyleHabits["Drinking"] ? "Drinking" : ""} arrow>
                <Liquor
                  sx={{
                    p: "5px",
                    fontSize: { md: "30px", lg: "30px", xl: "37px" },
                    fill: "#DD2025",
                    backgroundColor: "inherit",
                    filter: !lifestyleHabits["Drinking"] ? "grayscale(100%)" : undefined,
                    opacity: !lifestyleHabits["Drinking"] ? "0.5" : undefined,
                  }}
                />
              </Tooltip>
              <Tooltip title={lifestyleHabits["Smoking"] ? "Smoking" : ""} arrow>
                <SmokingRoomsOutlined
                  sx={{
                    p: "5px",
                    fontSize: { md: "30px", lg: "30px", xl: "37px" },
                    fill: "#F6AB65",
                    backgroundColor: "inherit",
                    filter: !lifestyleHabits["Smoking"] ? "grayscale(100%)" : undefined,
                    opacity: !lifestyleHabits["Smoking"] ? "0.5" : undefined,
                  }}
                />
              </Tooltip>
              <Tooltip
                title={lifestyleHabits["Consuming Tobacco"] ? "Consuming Tobacco" : ""}
                arrow
              >
                <Avatar
                  src="/images/tobacco.svg"
                  sx={{
                    padding: "5px",
                    color: "gray",
                    height: { md: "30px", lg: "30px", xl: "37px" },
                    width: { md: "30px", lg: "30px", xl: "37px" },
                    backgroundColor: "inherit",
                    filter: !lifestyleHabits["Consuming Tobacco"] ? "grayscale(100%)" : undefined,
                    opacity: !lifestyleHabits["Consuming Tobacco"] ? "0.5" : undefined,
                  }}
                  alt="Consuming Tobacco"
                ></Avatar>
              </Tooltip>
              {lifestyleHabits["Vegetarian"] ? (
                <Tooltip title="Vegetarian" arrow>
                  <Avatar
                    src="/images/vegetarian-diet.svg"
                    sx={{
                      padding: "5px",
                      height: { md: "30px", lg: "30px", xl: "37px" },
                      width: { md: "30px", lg: "30px", xl: "37px" },
                      backgroundColor: "inherit",
                    }}
                    alt="Vegetarian"
                  ></Avatar>
                </Tooltip>
              ) : (
                <>
                  {lifestyleHabits["Eggetarian"] ? (
                    <Tooltip title="Eggetarian" arrow>
                      <Avatar
                        src="/images/eggetarian-diet.svg"
                        sx={{
                          padding: "5px",
                          height: { md: "30px", lg: "30px", xl: "37px" },
                          width: { md: "30px", lg: "30px", xl: "37px" },
                          backgroundColor: "inherit",
                        }}
                        alt="Eggetarian"
                      ></Avatar>
                    </Tooltip>
                  ) : (
                    <>
                      {lifestyleHabits["Non-vegetarian"] ? (
                        <Tooltip title="Non-vegetarian" arrow>
                          <Avatar
                            src="/images/non-vegetarian-diet.svg"
                            sx={{
                              padding: "5px",
                              height: { md: "30px", lg: "30px", xl: "37px" },
                              width: { md: "30px", lg: "30px", xl: "37px" },
                              backgroundColor: "inherit",
                            }}
                            alt="Non-vegetarian"
                          ></Avatar>
                        </Tooltip>
                      ) : (
                        <Avatar
                          src="/images/non-vegetarian-diet.svg"
                          sx={{
                            padding: "5px",
                            height: { md: "30px", lg: "30px", xl: "37px" },
                            width: { md: "30px", lg: "30px", xl: "37px" },
                            backgroundColor: "inherit",
                            filter: "grayscale(100%)",
                            opacity: "0.5",
                          }}
                          alt="Non-vegetarian"
                        ></Avatar>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            {selectedAppointment?.patient?.abhaId && (
              <Tooltip title={"Abha linked"}>
                <Avatar
                  height="25px"
                  src="/images/abha_logo.svg"
                  alt="abha_logo"
                  onClick={(event) => {}}
                  sx={{
                    padding: "5px",
                    height: { md: "30px", lg: "30px", xl: "37px" },
                    width: { md: "30px", lg: "30px", xl: "37px" },
                    backgroundColor: "inherit",
                  }}
                ></Avatar>
              </Tooltip>
            )}
          </div>
          <ModalUI
            visible={vitalModalOpen}
            close={closeModal}
            title={"Add Patient Vital Sign"}
            component={
              <>
                <PatientVitalSignModal
                  selected={null}
                  close={(operation) => {
                    if (operation === "save") {
                      dispatch(setNewVitalAdded(true));
                      closeModal();
                    } else {
                      closeModal();
                    }
                  }}
                  patientId={selectedAppointment?.patientId}
                  vitalSignTypes={vitalSignTypes}
                ></PatientVitalSignModal>
              </>
            }
          ></ModalUI>
          <ModalUI
            visible={isBookAppointmentModalOpen}
            close={() => {
              dispatch(setSelectedAppointmentData({}));
              setIsBookAppointmentModalOpen(false);
            }}
            title={"Reschedule Appointment"}
            style={{
              overflowY: "auto",
              height: "550px",
              width: "610px",
            }}
            component={
              <BookAppointmentModal
                reschedule={true}
                handleClick={handleClick}
                closeModal={handleNewModalClose}
              />
            }
          />
          <Dialog
            open={openDialogForCancelAppointment}
            onClose={handleDialogNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              sx={{ padding: "16px 20px !important" }}
              id="alert-dialog-title"
              fontSize={16}
            >
              Are you sure?
            </DialogTitle>
            <DialogContent sx={{ padding: "0px 20px 10px 20px !important" }}>
              <DialogContentText id="alert-dialog-description">
                Appointment will be cancelled and patient will be notified.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ color: "#004c70", fontWeight: 600 }} onClick={handleDialogNo}>
                No
              </Button>
              <Button style={{ color: "#f5574c", fontWeight: 600 }} onClick={handleDialogYes}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ComponentLoader></ComponentLoader>
          </div>
        </>
      )}
    </div>
  );
};

export default PatientPrescriptionCardCV1;
