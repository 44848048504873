import { Card, CardContent, Typography, CircularProgress, Paper, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { getPatientVitalByPatientId } from "services/patientService";
import {
  BAR_CHART,
  CHART_OPTION_OBJECT_FOR_LEGEND,
  CHART_OPTIONS_MEDIUM,
  LINE_CHART,
} from "store/constant";
import {
  getChartByVitalType,
  getEhrDataPoints,
  getLatestVitals,
  vitalSignType,
} from "utils/ehr-data-utils";
import { sortVitalsByPriority } from "utils/sortVitalsByPriority";
import { getEhrSummary } from "services/PrescriptionsService";
import ComponentLoader from "ui-component/custom-components/ComponentLoader";
import { minHeight } from "@mui/system";

const PatientVitalGraph = ({
  isExtraLargeScreen,
  sethuSirLayout,
  isMediumScreen,
  isLargeScreen,
  selectedAppointment,
  setLatestVitals,
  setSelectedVitalKey,
  insightLoading,
  vitalsGraphData,
}) => {
  const graphDivRef = useRef(null);
  const thumbnailDivRef = useRef(null);
  const [vitalGraphHeight, setVitalGraphHeight] = useState(0);
  const [thumbnailHeight, setThumbnailHeight] = useState(0);
  const [thumbnailLineHeight, setThumbnailLineHeight] = useState(null);

  useEffect(() => {
    const divElement1 = graphDivRef?.current;
    const divElement2 = thumbnailDivRef?.current;
    const divElement3 = document.querySelector(".main-content");

    if (!divElement1 && !divElement2) return;

    const handleResize = () => {
      if (divElement1?.offsetHeight) {
        setVitalGraphHeight(`${divElement1.offsetHeight}px`);
      }

      if (divElement2?.offsetHeight) {
        setThumbnailHeight(`${divElement2.offsetHeight / 2}px`);
      }

      if (divElement3.offsetHeight < 630) {
        setThumbnailLineHeight("12px");
      } else {
        setThumbnailLineHeight(null);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (divElement1) {
      resizeObserver.observe(divElement1);
    }
    if (divElement2) {
      resizeObserver.observe(divElement2);
    }
    resizeObserver.observe(divElement3);

    handleResize();

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <Grid
        sx={{
          // paddingLeft: "10px",
          fontWeight: "600",
          margin: "0",
          color: sethuSirLayout ? "#ffffff" : "#004c70",
          minHeight: "23px",
          lineHeight: "normal",
          backgroundColor: sethuSirLayout ? "#29bf91" : "#e6eef1",
          marginBottom: "10px",
          fontSize: { md: "13px", lg: "14px", xl: "15px" },
        }}
      >
        <p style={{ textAlign: "center" }}>Insights</p>
      </Grid>
      <div
        className="common-scrollbar"
        style={{
          // flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          // height: "auto"
          overflowY: "auto",
          maxHeight: "calc(100% - 33px)",
          minHeight: "calc(100% - 33px)",
        }}
      >
        {insightLoading ? (
          <ComponentLoader></ComponentLoader>
        ) : (
          <>
            {sethuSirLayout ? (
              <>
                {vitalsGraphData?.map((vital, index) => {
                  const chartOptions = {
                    ...CHART_OPTIONS_MEDIUM,
                    ...(vital?.key === "BLOOD_PRESSURE" ? CHART_OPTION_OBJECT_FOR_LEGEND : {}),
                  };

                  const chartType =
                    vital?.key === "BLOOD_PRESSURE" ? "SINGLE_BAR_CHART" : LINE_CHART;

                  return (
                    <div key={index} style={{ flexGrow: 1, minHeight: "100px" }}>
                      <Card
                        sx={{
                          borderRadius: "8px",
                          // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                          cursor: "pointer",
                          border: "0.5px solid lightgray",
                          height: "100%",
                          minHeight: "100%",
                        }}
                        elevation={2}
                      >
                        <CardContent
                          sx={{
                            padding: "5px !important",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Grid sx={{ height: "20px" }}>
                            <Typography
                              sx={{
                                color: "#0C2D48",
                                fontWeight: 500,
                                fontSize: { md: "12px", lg: "13px", xl: "14px" },
                              }}
                            >
                              {`${vital?.value[0]?.vitalSignTypeResponse?.displayName}(${vital?.value[0]?.vitalSignTypeResponse?.unitAbbreviation})`}
                            </Typography>
                          </Grid>
                          <Grid
                            ref={graphDivRef}
                            sx={{ flexGrow: 1, maxHeight: "calc(100% - 20px)" }}
                          >
                            {getChartByVitalType(
                              vital?.key,
                              vital?.value,
                              vital?.value[0]?.vitalSignTypeResponse?.displayName,
                              chartOptions,
                              true,
                              5,
                              false,
                              {
                                minHeight: "100%",
                                padding: "0px",
                                width: "100%",
                                border: "none",
                              },
                              {
                                maxHeight: vitalGraphHeight,
                                height: vitalGraphHeight,
                              },
                              chartType
                            )}
                          </Grid>
                        </CardContent>
                      </Card>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {/* <div
                // className="common-scrollbar"
                // style={{ flexGrow: 1, maxHeight: "100%", overflowY: "auto" }}
                > */}
                {vitalsGraphData?.slice(0, 2)?.map((vital, index) => {
                  const chartOptions = {
                    ...CHART_OPTIONS_MEDIUM,
                    ...(vital?.key === "BLOOD_PRESSURE" ? CHART_OPTION_OBJECT_FOR_LEGEND : {}),
                  };

                  const chartType =
                    vital?.key === "BLOOD_PRESSURE" ? "SINGLE_BAR_CHART" : LINE_CHART;
                  return (
                    <Grid
                      key={index}
                      sx={{
                        cursor: "pointer",
                        height: "calc(25% - 8.25px)",
                        minHeight: "calc(25% - 8.25px)",
                      }}
                      onClick={() => {
                        if (setSelectedVitalKey) {
                          setSelectedVitalKey(vital.key);
                        }
                      }}
                    >
                      <Card
                        sx={{
                          borderRadius: "8px",
                          // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                          cursor: "pointer",
                          border: "0.5px solid lightgray",
                          height: "100%",
                          minHeight: "100%",
                        }}
                        elevation={2}
                      >
                        <CardContent
                          sx={{
                            padding: "5px !important",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Grid sx={{ height: "20px" }}>
                            <Typography
                              sx={{
                                color: "#0C2D48",
                                fontWeight: 500,
                                fontSize: { md: "12px", lg: "13px", xl: "14px" },
                              }}
                            >
                              {`${vital?.value[0]?.vitalSignTypeResponse?.displayName}(${vital?.value[0]?.vitalSignTypeResponse?.unitAbbreviation})`}
                            </Typography>
                          </Grid>
                          <Grid
                            ref={graphDivRef}
                            sx={{ flexGrow: 1, maxHeight: "calc(100% - 20px)" }}
                          >
                            {getChartByVitalType(
                              vital?.key,
                              vital?.value,
                              vital?.value[0]?.vitalSignTypeResponse?.displayName,
                              chartOptions,
                              true,
                              5,
                              false,
                              {
                                minHeight: "100%",
                                padding: "0px",
                                width: "100%",
                                border: "none",
                              },
                              {
                                maxHeight: vitalGraphHeight,
                                height: vitalGraphHeight,
                              },
                              chartType
                            )}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
                {/* </div> */}
                {vitalsGraphData?.length > 2 && (
                  <ThumbnailGraph
                    vitalsGraphData={vitalsGraphData}
                    vitalGraphHeight={vitalGraphHeight}
                    thumbnailHeight={thumbnailHeight}
                    thumbnailLineHeight={thumbnailLineHeight}
                    thumbnailDivRef={thumbnailDivRef}
                  />
                  // <div
                  //   style={{
                  //     height: "calc(25% - 8.25px)",
                  //     minHeight: "calc(25% - 8.25px)",
                  //     display: "flex",
                  //     flexDirection: "column",
                  //   }}
                  // >
                  //   {thumbnaiGraph && (
                  //     <Grid
                  //       sx={{
                  //         cursor: "pointer",
                  //         height: "100%",
                  //       }}
                  //       onClick={() => {
                  //         if (setSelectedVitalKey) {
                  //           setSelectedVitalKey(thumbnaiGraph.key);
                  //         }
                  //       }}
                  //     >
                  //       <Card
                  //         sx={{
                  //           borderRadius: "8px",
                  //           height: "100%",
                  //           // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                  //           border: "0.5px solid lightgray",
                  //           cursor: "pointer",
                  //         }}
                  //         elevation={2}
                  //       >
                  //         <CardContent
                  //           sx={{
                  //             padding: "5px !important",
                  //             height: "100%",
                  //             display: "flex",
                  //             flexDirection: "column",
                  //           }}
                  //         >
                  //           <Grid sx={{ height: "20px" }}>
                  //             <Typography
                  //               sx={{
                  //                 color: "#0C2D48",
                  //                 fontWeight: 500,
                  //                 fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  //               }}
                  //             >
                  //               {`${thumbnaiGraph?.value[0]?.vitalSignTypeResponse?.displayName}(${thumbnaiGraph?.value[0]?.vitalSignTypeResponse?.unitAbbreviation})`}
                  //             </Typography>
                  //           </Grid>
                  //           <Grid sx={{ flexGrow: 1, maxHeight: "calc(100% - 20px)" }}>
                  //             {getChartByVitalType(
                  //               thumbnaiGraph?.key,
                  //               thumbnaiGraph?.value,
                  //               thumbnaiGraph?.value[0]?.vitalSignTypeResponse?.displayName,
                  //               {
                  //                 ...CHART_OPTIONS_MEDIUM,
                  //                 ...(thumbnaiGraph?.key === "BLOOD_PRESSURE"
                  //                   ? CHART_OPTION_OBJECT_FOR_LEGEND
                  //                   : {}),
                  //               },
                  //               true,
                  //               5,
                  //               false,
                  //               {
                  //                 minHeight: "100%",
                  //                 padding: "0px",
                  //                 width: "100%",
                  //                 border: "none",
                  //               },
                  //               {
                  //                 maxHeight: vitalGraphHeight,
                  //                 height: vitalGraphHeight,
                  //                 // backgroundColor: "#e6eef1",
                  //               },
                  //               LINE_CHART
                  //             )}
                  //           </Grid>
                  //         </CardContent>
                  //       </Card>
                  //     </Grid>
                  //   )}
                  // </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

const ThumbnailGraph = ({
  vitalGraphHeight,
  vitalsGraphData,
  thumbnailHeight,
  thumbnailLineHeight,
  thumbnailDivRef,
}) => {
  const [thumbnaiGraph, setThumbnailGraph] = useState(null);

  useEffect(() => {
    if (vitalsGraphData?.length > 2) {
      setThumbnailGraph(vitalsGraphData[2]);
    }
  }, []);

  return (
    <>
      <div
        style={{
          height: "calc(25% - 8.25px)",
          minHeight: "calc(25% - 8.25px)",
        }}
      >
        {thumbnaiGraph && (
          <Grid
            sx={{
              cursor: "pointer",
              height: "100%",
            }}
          >
            <Card
              sx={{
                borderRadius: "8px",
                height: "100%",
                // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                border: "0.5px solid lightgray",
                cursor: "pointer",
              }}
              elevation={2}
            >
              <CardContent
                sx={{
                  padding: "5px !important",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid sx={{ height: "20px" }}>
                  <Typography
                    sx={{
                      color: "#0C2D48",
                      fontWeight: 500,
                      fontSize: { md: "12px", lg: "13px", xl: "14px" },
                    }}
                  >
                    {`${thumbnaiGraph?.value[0]?.vitalSignTypeResponse?.displayName}(${thumbnaiGraph?.value[0]?.vitalSignTypeResponse?.unitAbbreviation})`}
                  </Typography>
                </Grid>
                <Grid sx={{ flexGrow: 1, maxHeight: "calc(100% - 20px)" }}>
                  {getChartByVitalType(
                    thumbnaiGraph?.key,
                    thumbnaiGraph?.value,
                    thumbnaiGraph?.value[0]?.vitalSignTypeResponse?.displayName,
                    {
                      ...CHART_OPTIONS_MEDIUM,
                      ...(thumbnaiGraph?.key === "BLOOD_PRESSURE"
                        ? CHART_OPTION_OBJECT_FOR_LEGEND
                        : {}),
                    },
                    true,
                    5,
                    false,
                    {
                      minHeight: "100%",
                      padding: "0px",
                      width: "100%",
                      border: "none",
                    },
                    {
                      maxHeight: vitalGraphHeight,
                      height: vitalGraphHeight,
                      // backgroundColor: "#e6eef1",
                    },
                    thumbnaiGraph?.key === "BLOOD_PRESSURE" ? "SINGLE_BAR_CHART" : LINE_CHART
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </div>
      <div
        style={{
          height: "calc(25% - 8.25px)",
          minHeight: "calc(25% - 8.25px)",
          // maxHeight: "calc(25% - 8.25px)",
        }}
      >
        <Grid
          ref={thumbnailDivRef}
          container
          spacing={1}
          sx={{ padding: "5px", maxHeight: "100%", minHeight: "100%" }}
        >
          {vitalsGraphData?.slice(2, vitalsGraphData?.length).map((vital, index) => (
            <Grid
              key={index}
              sx={{
                cursor: "pointer",
                maxHeight: thumbnailHeight,
              }}
              onClick={() => {
                setThumbnailGraph(vital);
              }}
              item
              md={6}
            >
              <Paper
                elevation={2}
                sx={{
                  padding: "7px",
                  color: "#004c70",
                  backgroundColor: thumbnaiGraph?.key === vital.key ? "#def1ee" : "",
                  textAlign: "center",
                  border:
                    thumbnaiGraph?.key === vital.key ? "2px solid #004c70" : "1px solid #e0e0e0",
                  borderRadius: "10px",
                  transition: "background-color 0.3s, transform 0.3s",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                    transform: "scale(1.05)",
                  },
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    lineHeight: thumbnailLineHeight ?? undefined,
                    fontSize: thumbnailLineHeight ? "11px" : { md: "11px", lg: "12px", xl: "14px" },
                  }}
                >
                  {vital?.value[0]?.vitalSignTypeResponse?.displayName}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default PatientVitalGraph;
