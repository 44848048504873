import { Box, Divider, IconButton, Skeleton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useState, useEffect } from "react";
import ModalUI from "ui-component/ModalUI";
import UpdateMedicalServiceCategory from "./UpdateMedicalServiceCategory";
import { mscNameSchema, mscDescriptionSchema } from "../../Common/ValidationSchema/mscValidation";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  ACTIVE_STATE,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  accessToken,
  entitiesInfo,
} from "store/constant";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  createMedicalServiceCategories,
  deleteMedicalServiceCategories,
  getMedicalServiceCategories,
} from "services/BillingService";
import { useContext } from "react";
import { useCallback } from "react";
import Reveal from "views/utilities/Reveal";
import InfoModal from "../InfoModal";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router";

const MedicalServiceCategory = () => {
  const [msc, setmsc] = useState([]);
  const [displayMSC, setdisplayMSC] = useState([]);
  const [newMSC, setnewMSC] = useState("");
  const [newMSCDescription, setnewMSCDescription] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const { handleClick } = useContext(ToastContext);
  const accessTokenValue = accessToken();
  const entityInfo = entitiesInfo.find((entity) => entity.id === "MedicalServiceCategory");
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      const response = await getMedicalServiceCategories();
      setmsc(response.data.filter((msc) => msc.status === "ACTIVE"));
    } catch (error) {
      console.error("Error fetching Medical Service Categories");
    }
  }, []);

  const handleDelete = async (msc) => {
    if (msc.status === ACTIVE_STATE) {
      try {
        await deleteMedicalServiceCategories(msc.id);
        handleClick("success", "Medical Service Category has been deleted!");
        fetchData();
      } catch (e) {
        handleClick("error", "There seems to be an error deleting Medical Service Category");
      }
    }
  };

  const clearSearch = () => {
    setSearchQuery("");
  };

  const formatName = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  const addMSC = async () => {
    const formattedName = formatName(newMSC);

    if (errors.mscName || errors.mscDescription) {
      return;
    }

    const isDuplicateName = msc.some((t) => t.name.toLowerCase() === newMSC.toLowerCase());
    if (isDuplicateName) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mscName: "Medical Service Category already exists",
      }));
      return;
    }

    if (newMSC.trim() === "" || newMSCDescription.trim() === "") {
      errors.name = "Name cannot be empty";
      return;
    }

    const data = { name: formattedName, description: newMSCDescription };

    if (msc.some((t) => t.name.toLowerCase() === formattedName.toLowerCase())) {
      errors.name = "Medical Service Category already exists";
      return;
    }

    try {
      const res = await createMedicalServiceCategories(data);
      handleClick("success", "Medical Service Category has been successfully added.");
      setnewMSC("");
      setnewMSCDescription("");
      setmsc([...msc, res.data]);
      // navigate('/home/DoctorEntities/MedicalServiceCategory')
    } catch (e) {
      handleClick("error", "There seems to be an error creating the Medical Service Category.");
    }

    setOpenModal(false);
  };

  useEffect(() => {
    setdisplayMSC(
      msc.filter((s) => s.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()))
    );
  }, [msc, searchQuery]);

  useEffect(() => {
    fetchData();
  }, [accessTokenValue, fetchData]);

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
    setnewMSC("");
    setnewMSCDescription("");
  };

  const openUpdateModal = (spec) => {
    setSelected(spec);
    setOpen(true);
  };

  //validation
  const [errors, setErrors] = useState({ mscName: "", mscDescription: "" });

  const validateMSCName = async (mscName) => {
    const MIN_NAME_LENGTH = 3;
    const MAX_NAME_LENGTH = 50;
    if (mscName.length < MIN_NAME_LENGTH || mscName.length > MAX_NAME_LENGTH) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mscName: `Category name must be between ${MIN_NAME_LENGTH} and ${MAX_NAME_LENGTH} characters`,
      }));
      return;
    }

    if (!mscName.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mscName: "Category name cannot be only whitespace",
      }));
      return;
    }

    const validNameRegex = /^[a-zA-Z0-9\s]+$/;
    if (!validNameRegex.test(mscName)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mscName: "Category name contains invalid characters",
      }));
      return;
    }

    try {
      await mscNameSchema.validate({ mscName });
      setErrors((prevErrors) => ({ ...prevErrors, mscName: "" }));
    } catch (e) {
      setErrors((prevErrors) => ({ ...prevErrors, mscName: e.message }));
    }
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func(...args), delay);
    };
  };

  const handleSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 400),
    []
  );

  const validateMSCDescription = async (mscDescription) => {
    const MAX_DESCRIPTION_LENGTH = 200;
    if (!mscDescription.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mscDescription: "Category description cannot be empty",
      }));
      return;
    }
    if (mscDescription.length > MAX_DESCRIPTION_LENGTH) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mscDescription: `Category description cannot exceed ${MAX_DESCRIPTION_LENGTH} characters`,
      }));
      return;
    }
    try {
      await mscDescriptionSchema.validate({ mscDescription });
      setErrors((prevErrors) => ({ ...prevErrors, mscDescription: "" }));
    } catch (e) {
      setErrors((prevErrors) => ({ ...prevErrors, mscDescription: e.message }));
    }
  };

  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "name", label: "Name" },
    { field: "description", label: "Description" },
    { field: "Actions", label: "Actions" },
  ];

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleDelete,
    },
  ];

  return (
    <MedicalServiceSkeleton>
      <Reveal>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CustomButton
            className={"btn--secondary-light"}
            iconButton={<img src="/images/hc_back_arrow.svg" alt="back-arrow"></img>}
            onClick={() => navigate(-1)}
          />

          <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
            <h2 style={{ marginBottom: "15px", fontSize: "25px", marginTop: "15px" }}>
              Medical Service Category
            </h2>
            {entityInfo && (
              <>
                <InfoIcon
                  style={{ cursor: "pointer", marginLeft: "15px" }}
                  onClick={() => setOpenDialog(true)}
                />
                <InfoModal
                  open={openDialog}
                  onClose={() => setOpenDialog(false)}
                  entityInfo={{ ...entityInfo, title: "Medical Service Category" }}
                />
              </>
            )}
          </div>
        </div>
        <Divider sx={{ mb: "10px", borderBottomWidth: 2 }} />
        <Grid container size={{ xs: 12 }}>
          <Grid size={{ xs: 10 }}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Search any medical service category
            </Typography>
            <Grid sx={{ mt: 2 }}>
              <FormInputField
                style={{ width: "45%" }}
                label={"Search medical service category"}
                value={searchQuery}
                startAdornment={<i className="ri-search-line ri-lg" />}
                endAdornment={
                  searchQuery && (
                    <IconButton onClick={clearSearch} edge="end">
                      {<ClearOutlinedIcon fontSize="small" />}
                    </IconButton>
                  )
                }
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  handleSearch(e.target.value);
                }}
                size="small"
              ></FormInputField>
            </Grid>
          </Grid>
          <Grid size={{ xs: 2 }}>
            <CustomButton
              className="btn--primary"
              style={{ display: "flex", marginLeft: "auto" }}
              label={" + Add Category"}
              onClick={() => setOpenModal(true)}
            />
            <ModalUI
              visible={openModal}
              close={() => {
                setOpenModal(false);
              }}
              title={"Medical Service Category"}
              style={{
                height: "20rem",
                width: "25rem",
                padding: "20px",
              }}
              component={
                <Grid size={{ xs: 12 }}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 700,
                      marginTop: "15px",
                      // color: "#004C70",
                    }}
                  >
                    Add a New Category
                  </Typography>
                  <Grid container direction="column" spacing={2.5} sx={{ mt: 1 }}>
                    <Grid size={{ xs: 12 }}>
                      <FormInputField
                        style={{ width: "100%" }}
                        label={"Category Name"}
                        value={newMSC}
                        required
                        error={Boolean(errors.mscName)}
                        errorText={errors.mscName}
                        onChange={(e) => {
                          setnewMSC(e.target.value);
                          validateMSCName(e.target.value);
                        }}
                        startAdornment={<DriveFileRenameOutlineOutlinedIcon />}
                        size="small"
                        // sx={{ backgroundColor: "#f5f5f5", borderRadius: "5px" }}
                      />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <FormInputField
                        style={{ width: "100%" }}
                        label={"Category Description"}
                        value={newMSCDescription}
                        required
                        error={Boolean(errors.mscDescription)}
                        errorText={errors.mscDescription}
                        onChange={(e) => {
                          setnewMSCDescription(e.target.value);
                          validateMSCDescription(e.target.value);
                        }}
                        startAdornment={<DescriptionOutlinedIcon />}
                        size="small"
                      />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <CustomButton
                          className="btn--secondary"
                          disabled={newMSC === "" || newMSCDescription === ""}
                          onClick={addMSC}
                          label="Add"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>

        <Grid
          sx={{
            mt: 3,
            textAlign: "center",
          }}
        ></Grid>

        <CustomizedTable columns={columns} tableData={displayMSC} actions={actions} />

        <ModalUI
          visible={open}
          close={closeModal}
          title="Update Medical Service Category"
          component={
            <UpdateMedicalServiceCategory
              selected={selected}
              close={() => {
                closeModal();
                fetchData();
              }}
            />
          }
        />
      </Reveal>
    </MedicalServiceSkeleton>
  );
};

const MedicalServiceSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="35%"
            height={80}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="90%"
            height={80}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MedicalServiceCategory;
