import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appointmentData: null,
  orgId: null,
  appointmentTime: null,
  patientId: null,
  doctorId: null,
  appointmentDate: null,
  notes: null,
  type: null,
  selectedAppointmentData: null,
  uhiAppointmentData: null,
};

const appointmentDataSlice = createSlice({
  name: "appointmentData",
  initialState,
  reducers: {
    setAppointmentData(state, action) {
      state.appointmentData = action.payload;
    },
    setSelectedAppointmentData(state, action) {
      state.selectedAppointmentData = action.payload;
    },
    setOrgId(state, action) {
      state.selectedAppointmentData = { ...state.selectedAppointmentData, orgId: action.payload };
    },
    setAppointmentTime(state, action) {
      state.selectedAppointmentData = {
        ...state.selectedAppointmentData,
        appointmentTime: action.payload,
      };
    },
    setPatientId(state, action) {
      state.selectedAppointmentData = {
        ...state.selectedAppointmentData,
        patientId: action.payload,
      };
    },
    setDoctorId(state, action) {
      state.selectedAppointmentData = {
        ...state.selectedAppointmentData,
        doctorId: action.payload,
      };
    },
    setAppointmentDate(state, action) {
      state.selectedAppointmentData = {
        ...state.selectedAppointmentData,
        appointmentDate: action.payload,
      };
    },
    setNotes(state, action) {
      state.selectedAppointmentData = { ...state.selectedAppointmentData, notes: action.payload };
    },
    setType(state, action) {
      state.selectedAppointmentData = { ...state.selectedAppointmentData, type: action.payload };
    },
    setUhiAppointmentData(state, action) {
      state.uhiAppointmentData = action.payload;
    },
  },
});

export const {
  setAppointmentData,
  setSelectedAppointmentData,
  setOrgId,
  setAppointmentTime,
  setPatientId,
  setDoctorId,
  setAppointmentDate,
  setNotes,
  setType,
  setUhiAppointmentData,
} = appointmentDataSlice.actions;

export const getAppointmentData = (state) => state.appointmentData.appointmentData;
export const getSelectedAppointmentData = (state) => state.appointmentData.selectedAppointmentData;
export const getOrgId = (state) => state.appointmentData.orgId;
export const getPatientId = (state) => state.appointmentData.patientId;
export const getDoctorId = (state) => state.appointmentData.doctorId;
export const getAppointmentDate = (state) => state.appointmentData.appointmentDate;
export const getAppointmentTime = (state) => state.appointmentData.appointmentTime;
export const getNotes = (state) => state.appointmentData.notes;
export const getType = (state) => state.appointmentData.type;
export const getUhiAppointmentData = (state) => state.appointmentData.uhiAppointmentData;

export const appointmentDataReducer = appointmentDataSlice.reducer;
