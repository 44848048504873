// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patient-prescription-overview-container {
  container-type: size;
  height: 100%;
  width: 100%;
}
.patient-prescription-overview-container .patient-prescription-overview-item {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
@container (max-width: 100px) {
  .patient-prescription-overview-container .patient-prescription-overview {
    display: none !important;
  }
  .patient-prescription-overview-container:after {
    content: "Too small to display contents";
    display: block;
    padding: 5px;
    color: #6e6e6e;
    font-style: italic;
    font-weight: 500;
  }
}
@container (max-height: 75px) {
  .patient-prescription-overview-container .patient-prescription-overview {
    display: none !important;
  }
  .patient-prescription-overview-container:after {
    content: "Too small to display contents";
    display: block;
    padding: 5px;
    color: #6e6e6e;
    font-style: italic;
    font-weight: 500;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/DoctorHomeScreen/PatientPrescriptionDetails/patient-prescription-overview.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,YAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AACJ;AAeE;EACE;IACE,wBAAA;EAbJ;EAgBE;IACE,wCAAA;IACA,cAAA;IACA,YAAA;IACA,cAAA;IACA,kBAAA;IACA,gBAAA;EAdJ;AACF;AAiBE;EACE;IACE,wBAAA;EAfJ;EAkBE;IACE,wCAAA;IACA,cAAA;IACA,YAAA;IACA,cAAA;IACA,kBAAA;IACA,gBAAA;EAhBJ;AACF","sourcesContent":[".patient-prescription-overview-container {\n  container-type: size;\n  height: 100%;\n  width: 100%;\n\n  .patient-prescription-overview-item {\n    display: flex;\n    flex-direction: column;\n    gap: 0.3rem;\n  }\n\n  // .patient-prescription-overview-item-dropdown {\n  //   display: none;\n  // }\n\n  // @container (max-width: 250px) {\n  //   .patient-prescription-overview-item {\n  //     display: none !important;\n  //   }\n  //   .patient-prescription-overview-item-dropdown {\n  //     display: inline-block !important;\n  //   }\n  // }\n\n  @container (max-width: 100px) {\n    .patient-prescription-overview {\n      display: none !important;\n    }\n\n    &:after {\n      content: \"Too small to display contents\";\n      display: block;\n      padding: 5px;\n      color: #6e6e6e;\n      font-style: italic;\n      font-weight: 500;\n    }\n  }\n\n  @container (max-height: 75px) {\n    .patient-prescription-overview {\n      display: none !important;\n    }\n\n    &:after {\n      content: \"Too small to display contents\";\n      display: block;\n      padding: 5px;\n      color: #6e6e6e;\n      font-style: italic;\n      font-weight: 500;\n    }\n  }\n\n  // @container (max-height: 150px) {\n  //   .patient-prescription-overview-item {\n  //     display: none !important;\n  //   }\n  //   .patient-prescription-overview-item-dropdown {\n  //     display: inline-block !important;\n  //   }\n  // }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
