import { useState } from "react";
import { useEffect } from "react";
import {
  getPatientAllergyByPatientId,
  getPatientChronicDiseaseByPatientId,
  getPatientFamilyHistoryByPatientId,
  getPatientLifestyleByPatientId,
  getPatientPastProceduresByPatientId,
  getPatientVitalByPatientId,
} from "services/patientService";
import {
  getChartByVitalType,
  getEhrDataPoints,
  getLatestVitals,
  vitalSignType,
} from "utils/ehr-data-utils";
import { getEhrSummary, getMedicationsByPatientId } from "services/PrescriptionsService";
import { getAppointmentsByPatientId, getRecentAppointments } from "services/Appointments";
import { getApprovedRecords } from "services/HiuService";
import {
  getColorAndBackgroundColor,
  getColorAndBackgroundColorBySeverity,
} from "utils/calculate-vital-color";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import {
  CHART_OPTIONS_MEDIUM,
  CLINIC_VISIT,
  COMPLETED,
  LINE_CHART,
  SEVERITY_ORDER,
  TELE_CONSULTATION,
  VITAL_ICON_LIST,
} from "store/constant";
import PrescriptionPadForClinicVisit from "../../Prescription Pad/prescriptionPadForClinicVisit";
import CustomButton from "ui-component/custom-components/CustomButton";
import {
  Add,
  ExpandLess,
  FiberManualRecord,
  KeyboardDoubleArrowLeft,
  PlayCircleOutline,
  Remove,
} from "@mui/icons-material";
import { toCamelCase } from "utils/toCamelCase";
import ModalUI from "ui-component/ModalUI";
import { PatientFamilyHistoryModal } from "views/Components/Patient/PatientProfile/PatientFamilyHistory";
import PatientPrescriptionCard from "./PatientPrescriptionCard";
import {
  getAdvices,
  getMedications,
  setAdvices,
  setMedications,
  setPrescriptionId,
  setPrescriptionPadData,
} from "store/Slices/prescriptionPadSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import PatientVitalGraph from "./PatientVitalGraph";
import ComponentLoader from "ui-component/custom-components/ComponentLoader";
import { sortVitalsByPriority } from "utils/sortVitalsByPriority";
import { setClearInvoiceState } from "store/Slices/billingInvoiceSlice";
import { useNavigate } from "react-router";

const medicalConditions = [
  { conditionName: "Hypertensive", severity: "CRITICAL" },
  { conditionName: "Osteoarthritis", severity: "SEVERE" },
  { conditionName: "Epilepsy", severity: "MODERATE" },
  { conditionName: "Rheumatoid Arthritis", severity: "MILD" },
  { conditionName: "Depression", severity: "CRITICAL" },
  { conditionName: "Obesity", severity: "SEVERE" },
  { conditionName: "Sleep Apnea", severity: "MODERATE" },
  { conditionName: "Anemia", severity: "MILD" },
  { conditionName: "Psoriasis", severity: "CRITICAL" },
];

const PrescriptionPatientDetailsSethuSir = ({
  selectedAppointment,
  handleClick,
  handleJoinRoom,
  docId,
  isMediumScreen,
  isLargeScreen,
  isExtraLargeScreen,
  setIframeOpen,
  setIframeAppointmentId,
  setIframeSrc,
  handleNewModalOpenForReschedulingAppointment,
  parentAppointmentsRefresh,
  doctorData,
  setTodaysAppointments,
  handleCancelAppointment,
  noAppointmentsToday,
}) => {
  const [ehrSummary, setEhrSummary] = useState("");
  // const [patientDetails, setPatientDetails] = useState(null);
  const [activeMedications, setActiveMedications] = useState([]);
  const [pastProcedures, setPastProcedures] = useState([]);

  const [chronicDiseases, setChronicDiseases] = useState([]);
  const [showAllChronicDiseases, setShowAllChronicDiseases] = useState(false);

  const navigate = useNavigate();

  const [allergies, setAllergies] = useState([]);
  const [showAllAllergies, setShowAllAllergies] = useState(false);

  const [bodyImplants, setBodyImplants] = useState([]);
  const [showAllBodyImplants, setShowAllBodyImplants] = useState(false);

  const [familyHistory, setFamilyHistory] = useState([]);
  const [showAllFamilyHistory, setShowAllFamilyHistory] = useState(false);

  const [insightLoading, setInsightLoading] = useState(true);
  const [thumbnaiGraph, setThumbnailGraph] = useState(null);

  const [loading, setLoading] = useState(true);
  const [visits, setVisits] = useState([]);
  const [records, setRecords] = useState([]);
  const [selectedVitalKey, setSelectedVitalKey] = useState(null);
  const [vitalsGraphData, setVitalsGraphData] = useState(null);
  const [latestVitals, setLatestVitals] = useState(null);
  const [showVisited, setShowVisited] = useState(false);
  const [lastVisited, setLastVisited] = useState(null);
  const [open, setOpen] = useState(false);
  const [lifestyleHabits, setLifestyleHabits] = useState({
    Eggetarian: false,
    "Non-vegetarian": false,
    Vegetarian: false,
    "Consuming Tobacco": false,
    Drinking: false,
    Smoking: false,
  });

  const vitalKeyToDisplayNameMap = {
    BLOOD_GLUCOSE: "Glucose",
    BLOOD_PRESSURE: "BP",
    BLOOD_OXYGEN: "Oxygen",
    BODY_TEMPERATURE: "Temp",
    HEART_RATE: "Heart-rate",
    HEIGHT: "Height",
    WEIGHT: "Weight",
    BODY_MASS_INDEX: "BMI",
  };

  const prescriptionMedications = useSelector(getMedications);
  const prescriptionAdvices = useSelector(getAdvices);
  const dispatch = useDispatch();

  const closeModal = () => {
    setOpen(false);
  };

  const openCreateModal = () => {
    setOpen(true);
  };

  const fetchPatientFamilyHistory = async () => {
    const familyHistoryResponse = await getPatientFamilyHistoryByPatientId(
      selectedAppointment?.patientId || selectedAppointment?.id,
      true
    );
    const sortedFamilyHistory = familyHistoryResponse.data.sort((a, b) => {
      return SEVERITY_ORDER[a.severity] - SEVERITY_ORDER[b.severity];
    });
    return sortedFamilyHistory;
  };

  const fetchLifestyleHabits = async () => {
    try {
      if (!(selectedAppointment?.patientId || selectedAppointment?.id)) return;

      const lifestyleResponse = await getPatientLifestyleByPatientId(
        selectedAppointment?.patientId || selectedAppointment?.id,
        true
      );

      const matchedHabits = lifestyleResponse.data.flatMap((responseObj) => {
        const responseArray = responseObj.response.split(",");
        return responseArray.filter((response) => lifestyleHabits.hasOwnProperty(response.trim()));
      });

      // re-initialize, when patient id change, to avoid data being persisted
      const updatedLifestyleHabits = {
        Eggetarian: false,
        "Non-vegetarian": false,
        Vegetarian: false,
        "Consuming Tobacco": false,
        Drinking: false,
        Smoking: false,
      };

      matchedHabits.forEach((habit) => {
        if (!updatedLifestyleHabits[habit]) {
          updatedLifestyleHabits[habit] = true;
        }
      });
      setLifestyleHabits(updatedLifestyleHabits);
    } catch (error) {
      console.error("Error fetching data");
    }
  };

  const fetchPatientLastVisited = async () => {
    try {
      const now = dayjs();
      if (selectedAppointment?.patientId || selectedAppointment?.id) {
        const { data } = await getAppointmentsByPatientId(
          selectedAppointment?.patientId || selectedAppointment?.id
        );
        const filteredAppointments = data.filter(
          (appointment) => appointment.appointmentStatus === COMPLETED
        );
        if (filteredAppointments.length !== 0) {
          const recent = filteredAppointments.reduce((mostRecent, current) => {
            const currentDate = dayjs(current.appointmentDate);
            const mostRecentDate = dayjs(mostRecent.appointmentDate);
            return currentDate > mostRecentDate ? current : mostRecent;
          });
          setLastVisited(now.diff(dayjs(recent.appointmentDate), "day"));
          setShowVisited(true);
        }
      }
    } catch (error) {
      console.error("Error fetching latest appointment.");
    }
  };

  const fetchPatientHealthDetails = async () => {
    if (selectedAppointment?.patientId || selectedAppointment?.id) {
      setShowAllAllergies(false);
      try {
        setBodyImplants(selectedAppointment?.patient?.devicesAndImplants);
        const activeMedicationResponse = await getMedicationsByPatientId(
          selectedAppointment?.patientId || selectedAppointment?.id
        );
        setActiveMedications(activeMedicationResponse.data);

        const pastProcedureResponse = await getPatientPastProceduresByPatientId(
          selectedAppointment?.patientId || selectedAppointment?.id
        );
        setPastProcedures(pastProcedureResponse.data);

        const allergyResponse = await getPatientAllergyByPatientId(
          selectedAppointment?.patientId || selectedAppointment?.id,
          true
        );
        const sortedAllergies = allergyResponse.data.sort((a, b) => {
          return SEVERITY_ORDER[a.severity] - SEVERITY_ORDER[b.severity];
        });
        setAllergies(sortedAllergies);

        const sortedFamilyHistory = await fetchPatientFamilyHistory();
        setFamilyHistory(sortedFamilyHistory);

        const chronicDiseaseResponse = await getPatientChronicDiseaseByPatientId(
          selectedAppointment?.patientId || selectedAppointment?.id,
          true
        );
        setChronicDiseases(chronicDiseaseResponse.data);
      } catch (error) {
        console.error("Error fetching patient health data");
      }
    }
  };

  const fetchPatientVisitDetails = async () => {
    if (selectedAppointment?.patientId || selectedAppointment?.id) {
      try {
        const visitsResponse = await getRecentAppointments(
          selectedAppointment?.patientId || selectedAppointment?.id,
          null,
          null,
          null,
          null,
          null,
          5
        );
        setVisits(visitsResponse.data);
      } catch (error) {
        console.error("Error fetching patient visit details:", error);
      }
    }
  };

  // const fetchLatestVitals = async () => {
  //   if (selectedAppointment?.patientId || selectedAppointment?.id) {
  //     const allPatientVitals = await getPatientVitalByPatientId(
  //       selectedAppointment?.patientId || selectedAppointment?.id
  //     );
  //     const ehrDataPoints = getEhrDataPoints({ encounters: [{ vitals: allPatientVitals.data }] });

  //     let newVitalDataForGraph = ehrDataPoints.filter((d) => vitalSignType.includes(d.key));

  //     const vitalSignTypeListForLatestVitals = [
  //       ...vitalSignType.filter((item) => item !== "RESPIRATORY_RATE"),
  //       ...["HEIGHT", "WEIGHT", "BODY_MASS_INDEX"],
  //     ];

  //     const vitalDataForLatestVital = ehrDataPoints.filter((d) =>
  //       vitalSignTypeListForLatestVitals.includes(d.key)
  //     );
  //     const latestVitalsValue = getLatestVitals(vitalDataForLatestVital);

  //     setLatestVitals(latestVitalsValue);

  //     // if (LatestVitalsGraphData.length > 0 && setSelectedVitalKey) {
  //     //   setSelectedVitalKey(LatestVitalsGraphData[0].key);
  //     // }
  //     setVitalsGraphData(newVitalDataForGraph);
  //   }
  // };

  const getRecordsForPatient = async () => {
    if ((selectedAppointment?.patient?.abhaId || selectedAppointment?.abhaId) && docId) {
      try {
        const abdmRecords = await getApprovedRecords(
          10,
          docId,
          selectedAppointment?.patient?.abhaId || selectedAppointment?.abhaId
        );
        setRecords(abdmRecords?.data);
      } catch (e) {
        console.error("Can not fetch health records... Please try after some time");
      }
    }
  };

  const fetchLatestVitals = async () => {
    if (selectedAppointment?.patientId) {
      try {
        setLatestVitals(null);
        setInsightLoading(true);
        const { data: result } = await getEhrSummary(
          selectedAppointment?.patientId,
          selectedAppointment?.doctor?.specialization?.name
        );
        if (selectedAppointment?.patientId || selectedAppointment?.id) {
          const allPatientVitals = await getPatientVitalByPatientId(
            selectedAppointment?.patientId || selectedAppointment?.id,
            true
          );
          const ehrDataPoints = getEhrDataPoints({
            encounters: [{ vitals: allPatientVitals.data }],
          });

          let newVitalDataForGraph = ehrDataPoints.filter((d) => vitalSignType.includes(d.key));

          const vitalSignTypeListForLatestVitals = [
            ...vitalSignType.filter((item) => item !== "RESPIRATORY_RATE"),
            ...["HEIGHT", "WEIGHT", "BODY_MASS_INDEX"],
          ];

          const vitalDataForLatestVital = ehrDataPoints.filter((d) =>
            vitalSignTypeListForLatestVitals.includes(d.key)
          );
          const latestVitalsValue = getLatestVitals(vitalDataForLatestVital);

          setLatestVitals(latestVitalsValue);

          // if (LatestVitalsGraphData.length > 0 && setSelectedVitalKey) {
          //   setSelectedVitalKey(LatestVitalsGraphData[0].key);
          // }
          if (result?.CRITICAL_VITALS?.length > 0) {
            const sortedVitalData = sortVitalsByPriority(
              newVitalDataForGraph,
              result.CRITICAL_VITALS || []
            );

            if (sortedVitalData?.length > 2) {
              setThumbnailGraph(sortedVitalData[2]);
            }
            setVitalsGraphData(sortedVitalData);
          } else {
            if (newVitalDataForGraph?.length > 2) {
              setThumbnailGraph(newVitalDataForGraph[2]);
            }
            setVitalsGraphData(newVitalDataForGraph);
          }
        }
      } catch (error) {
        console.error(error?.response?.data?.message);
      } finally {
        setInsightLoading(false);
      }
    }
  };

  const fetchAllData = async () => {
    try {
      if (!(selectedAppointment?.patientId || selectedAppointment?.id)) return;

      // Define all API calls
      setLoading(true);
      const patientLastVisited = fetchPatientLastVisited();
      const lifestyle = fetchLifestyleHabits();
      const healthDetails = fetchPatientHealthDetails();
      const visitDetails = fetchPatientVisitDetails();
      const latestVitals = fetchLatestVitals();
      const recordsData = getRecordsForPatient();

      // Fetch all data concurrently
      await Promise.all([
        patientLastVisited,
        lifestyle,
        healthDetails,
        visitDetails,
        latestVitals,
        recordsData,
      ]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, [selectedAppointment?.patientId, selectedAppointment?.id]);

  // if (loading) {
  //   return <ComponentLoader></ComponentLoader>;
  // }

  return (
    <>
      <div
        className="patient-prescription-details"
        style={{
          display: "flex",
          flexDirection: noAppointmentsToday || loading ? undefined : "column",
          borderRadius: "10px",
          justifyContent: noAppointmentsToday || loading ? "center" : undefined,
          alignItems: noAppointmentsToday || loading ? "center" : undefined,
          padding: "12px",
          backgroundColor: "white",
          height: "100%",
          gap: noAppointmentsToday || loading ? undefined : "0.5rem",
        }}
      >
        {noAppointmentsToday ? (
          <NoAppointmentScheduledPage />
        ) : (
          <>
            {loading ? (
              <ComponentLoader />
            ) : (
              <>
                <div
                  style={{
                    height: "auto",
                    display: "flex",
                    gap: "0.5rem",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ height: "100%" }}>
                    <PatientPrescriptionCard
                      sethuSirLayout={true}
                      patientId={selectedAppointment?.patientId || selectedAppointment?.id}
                      patientData={selectedAppointment?.patient || selectedAppointment}
                      lifestyleHabits={lifestyleHabits}
                      showVisited={showVisited}
                      lastVisited={lastVisited}
                    ></PatientPrescriptionCard>
                  </div>

                  <div
                    style={{
                      height: "100%",
                      width: "fit-content",
                      display: "flex",
                      gap: "0.5rem",
                      justifyContent: "end",
                    }}
                  >
                    <div
                      style={{
                        justifyContent: "end",
                      }}
                    >
                      <div
                        style={{
                          height: "fit-content",
                          display: "flex",
                          justifyContent: "end",
                          // marginBottom: "5px",
                          padding: "3px",
                          borderRadius: "10px",
                        }}
                      >
                        {latestVitals
                          ?.filter((vital) =>
                            [
                              "BLOOD_GLUCOSE",
                              "BLOOD_PRESSURE",
                              "BLOOD_OXYGEN",
                              "BODY_TEMPERATURE",
                              "HEART_RATE",
                            ].includes(vital?.key)
                          )
                          .map((vital, index) => {
                            const vitalColorAndBgColor = getColorAndBackgroundColor(
                              vital?.latestValue?.severity
                            );
                            return (
                              <Tooltip
                                title={
                                  vital?.latestValue?.vitalSignTypeResponse?.unitAbbreviation ||
                                  "kg/m2"
                                }
                              >
                                <Grid
                                  sx={{
                                    display: "flex",
                                    "&:hover": {
                                      backgroundColor: "#e6eef1", // Change this to the color you want
                                    },
                                  }}
                                >
                                  {index !== 0 && <Divider orientation="vertical" flexItem />}
                                  <Grid
                                    sx={{
                                      display: "flex",
                                      height: "fit-content",
                                      flexDirection: "column",
                                      // gap: "0.2rem",
                                      alignItems: "center",
                                      padding: "0px 5px",
                                      fontSize: { md: "12px", lg: "13px", xl: "14px" },
                                    }}
                                  >
                                    <div style={{ whiteSpace: "nowrap" }}>
                                      {vitalKeyToDisplayNameMap[vital?.key]}
                                    </div>
                                    <div style={{ color: vitalColorAndBgColor?.color }}>
                                      {`${vital?.latestValue?.value}${
                                        vital?.latestValue?.vitalSignTypeResponse
                                          ?.unitAbbreviation || "kg/m2"
                                      }`}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Tooltip>
                            );
                          })}
                      </div>
                      {/* <Divider /> */}
                      <div style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
                        <div
                          style={{
                            height: "fit-content",
                            width: "fit-content",
                            display: "flex",
                            justifyContent: "end",
                            // marginTop: "5px",
                            // backgroundColor: "#f8f9fa",
                            padding: "3px",
                            // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          {latestVitals
                            ?.filter((vital) =>
                              ["HEIGHT", "WEIGHT", "BODY_MASS_INDEX"].includes(vital?.key)
                            )
                            .map((vital, index) => {
                              const vitalColorAndBgColor = getColorAndBackgroundColor(
                                vital?.latestValue?.severity
                              );
                              return (
                                <Tooltip
                                  title={
                                    vital?.latestValue?.vitalSignTypeResponse?.unitAbbreviation ||
                                    "kg/m2"
                                  }
                                >
                                  <Grid
                                    sx={{
                                      display: "flex",
                                      "&:hover": {
                                        backgroundColor: "#e6eef1", // Change this to the color you want
                                      },
                                      // borderRadius: "14px"
                                    }}
                                  >
                                    {index !== 0 && <Divider orientation="vertical" flexItem />}
                                    <Grid
                                      sx={{
                                        display: "flex",
                                        height: "fit-content",
                                        flexDirection: "column",
                                        // gap: "0.2rem",
                                        alignItems: "center",
                                        padding: "0px 5px",
                                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                                      }}
                                    >
                                      <div style={{ whiteSpace: "nowrap" }}>
                                        {vitalKeyToDisplayNameMap[vital?.key]}
                                      </div>
                                      <div style={{ color: vitalColorAndBgColor?.color }}>
                                        {`${vital?.latestValue?.value}${
                                          vital?.latestValue?.vitalSignTypeResponse
                                            ?.unitAbbreviation || "kg/m2"
                                        }`}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Tooltip>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div
                    style={{
                      height: "100%",
                      width: "75%",
                      display: "flex",
                      gap: "0.5rem",
                      justifyContent: "end",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        // height: "fit-content",
                        // display: "flex",
                        // gap: "0rem",
                        // flexWrap: "wrap",
                        justifyContent: "end",
                      }}
                    >
                      <Grid
                        container
                        spacing={1}
                        justifyContent={"end"}
                        sx={{ height: "fit-content" }}
                      >
                        {latestVitals
                          // ?.filter(vital => ["BLOOD_GLUCOSE", "BLOOD_PRESSURE", "BLOOD_OXYGEN", "BODY_TEMPERATURE", "HEART_RATE"].includes(vital?.key))
                          ?.map((vital, index) => {
                            const vitalColorAndBgColor = getColorAndBackgroundColor(
                              vital?.latestValue?.severity
                            );
                            return (
                              <Chip
                                key={index}
                                sx={{
                                  alignItems: "center",
                                  color: vitalColorAndBgColor?.color,
                                  fontSize: { md: "11px", lg: "12px", xl: "14px" },
                                  // backgroundColor: vitalColorAndBgColor?.backgroundColor,
                                  // borderColor: vitalColorAndBgColor?.color,
                                  backgroundColor: "transparent",
                                  // backgroundColor: index % 2 === 0 ? undefined : "transparent"
                                }}
                                avatar={VITAL_ICON_LIST.find((item) => item.key === vital.key).icon}
                                label={
                                  vital?.latestValue?.value +
                                  (vital?.latestValue?.vitalSignTypeResponse?.unitAbbreviation ||
                                    "kg/m2")
                                }
                                variant="filled"
                              />
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     height: "fit-content",
                              //     alignItems: "center",
                              //     // marginLeft: index !== 0 ? "10px" : "",
                              //     padding: "7px",
                              //   }}
                              // >
                              //   {VITAL_ICON_LIST.find((item) => item.key === vital.key).icon}
                              //   <Box
                              //     component={"span"}
                              //     sx={{
                              //         fontSize: { md: "12px", lg: "13px", xl: "14px" },
                              //         color: vitalColorAndBgColor?.color,
                              //         marginLeft: "5px",
                              //         fontWeight: "500",
                              //     }}
                              //     >
                              //         {vital?.latestValue?.value +
                              //         (vital?.latestValue?.vitalSignTypeResponse?.unitAbbreviation || "kg/m2")}
                              //     </Box>
                              // </div>
                            );
                          })}
                      </Grid>
                      <Grid
                        container
                        spacing={1}
                        justifyContent={"end"}
                        sx={{ height: "fit-content" }}
                      ></Grid>
                    </div>
                  </div> */}
                </div>
                <div
                  style={{
                    // height: "81%",
                    flexGrow: 1,
                    overflowY: "auto",
                    display: "flex",
                    gap: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      width: "25%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid
                      sx={{
                        fontWeight: "600",
                        margin: "0",
                        color: "#ffffff",
                        lineHeight: "normal",
                        marginBottom: "10px",
                        backgroundColor: "#29bf91",
                        fontSize: { md: "13px", lg: "14px", xl: "15px" },
                      }}
                    >
                      <p style={{ textAlign: "center" }}>Overview</p>
                    </Grid>
                    <div
                      className="common-scrollbar"
                      style={{
                        flexGrow: 1,
                        maxHeight: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.5rem",
                        overflowY: "auto",
                      }}
                    >
                      {activeMedications?.length > 0 && (
                        <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}>
                          <Grid
                            sx={{
                              // paddingLeft: "10px",
                              fontWeight: "600",
                              margin: "0",
                              color: "#004c70",
                              lineHeight: "normal",
                              fontSize: { md: "12px", lg: "13px", xl: "14px" },
                            }}
                          >
                            Active Medications
                          </Grid>
                          <div>
                            <List sx={{ p: "0px" }}>
                              {activeMedications.map((item, index) => (
                                <ListItem
                                  sx={{
                                    p: "0px 10px",
                                    // "&:hover": {
                                    //   backgroundColor: "#e6eef1", // Change this to the color you want
                                    // },
                                  }}
                                  key={index}
                                >
                                  {/* <ListItemIcon>
                                                    <VaccinesOutlined sx={{ fill: "#0C2D48" }} />
                                                    </ListItemIcon> */}
                                  <ListItemIcon sx={{ minWidth: "auto", pr: 1 }}>
                                    <FiberManualRecord sx={{ fontSize: "8px", color: "#0C2D48" }} />
                                  </ListItemIcon>
                                  <ListItemText
                                    primaryTypographyProps={{
                                      sx: {
                                        color: "#747B8D !important",
                                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                                      },
                                    }}
                                    primary={item?.medicationResponse?.name}
                                  ></ListItemText>
                                  <Add
                                    sx={{
                                      color: "#29bf91",
                                      cursor: "pointer",
                                      fontSize: "18px",
                                    }}
                                    onClick={() => {
                                      const medication = {
                                        medicationId: item.id,
                                        category: "RX",
                                        displayName: item.medicationResponse.name,
                                        frequency: item.frequency,
                                        duration: item.duration,
                                        instructions: item.instructions,
                                        doseTiming: item.doseTiming,
                                      };
                                      if (
                                        !prescriptionMedications.find(
                                          (medicine) => medicine?.medicationId === item.id
                                        )
                                      ) {
                                        if (
                                          prescriptionAdvices?.find((advice) =>
                                            advice.displayName.includes(
                                              `Discontinue "${item.medicationResponse.name}"`
                                            )
                                          )
                                        ) {
                                          const updatedAdvices = prescriptionAdvices?.filter(
                                            (advice) =>
                                              !advice.displayName.includes(
                                                `Discontinue "${item.medicationResponse.name}"`
                                              )
                                          );
                                          dispatch(setAdvices(updatedAdvices));
                                        }
                                        dispatch(
                                          setMedications([...prescriptionMedications, medication])
                                        );
                                      }
                                    }}
                                  />
                                  <Remove
                                    sx={{
                                      color: "red",
                                      cursor: "pointer",
                                      fontSize: "18px",
                                    }}
                                    onClick={() => {
                                      const advice = {
                                        category: "ADV",
                                        displayName: `Discontinue "${item.medicationResponse.name}" medicine.`,
                                      };
                                      if (
                                        !prescriptionAdvices?.find((advice) =>
                                          advice.displayName.includes(
                                            `Discontinue "${item.medicationResponse.name}"`
                                          )
                                        )
                                      ) {
                                        if (
                                          prescriptionMedications.some(
                                            (medicine) => medicine?.medicationId === item.id
                                          )
                                        ) {
                                          const updatedMedications = prescriptionMedications.filter(
                                            (medicine) => !(medicine?.medicationId === item.id)
                                          );
                                          dispatch(setMedications(updatedMedications));
                                        }
                                        dispatch(setAdvices([...prescriptionAdvices, advice]));
                                      }
                                    }}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </div>
                        </div>
                      )}
                      {allergies?.length > 0 && (
                        <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}>
                          <Grid
                            sx={{
                              // paddingLeft: "10px",
                              fontWeight: "600",
                              margin: "0",
                              color: "#004c70",
                              lineHeight: "normal",
                              fontSize: { md: "12px", lg: "13px", xl: "14px" },
                            }}
                          >
                            Allergies
                          </Grid>
                          <div>
                            <List sx={{ p: "0px" }}>
                              {allergies
                                .slice(0, showAllAllergies ? allergies.length : 3)
                                .map((item, index) => {
                                  const colorCode = getColorAndBackgroundColorBySeverity(
                                    item.severity
                                  );
                                  return (
                                    <ListItem
                                      sx={{
                                        p: "0px 10px",
                                        "&:hover": {
                                          backgroundColor: "#e6eef1", // Change this to the color you want
                                        },
                                      }}
                                      key={index}
                                    >
                                      <ListItemIcon sx={{ minWidth: "auto", pr: 1 }}>
                                        <FiberManualRecord
                                          sx={{ fontSize: "8px", color: "#0C2D48" }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primaryTypographyProps={{
                                          sx: {
                                            color: `${colorCode?.color} !important`,
                                            fontSize: { md: "12px", lg: "13px", xl: "14px" },
                                          },
                                        }}
                                        primary={item?.allergyResponse?.name}
                                      ></ListItemText>
                                    </ListItem>
                                  );
                                })}
                            </List>
                            {allergies.length > 3 && (
                              <div>
                                {!showAllAllergies ? (
                                  <Chip
                                    sx={{
                                      backgroundColor: "transparent",
                                      borderRadius: "2px",
                                      cursor: "pointer",
                                    }}
                                    label={`+${allergies.length - 3} more`}
                                    onClick={() => setShowAllAllergies(true)}
                                  />
                                ) : (
                                  <Chip
                                    sx={{
                                      backgroundColor: "transparent",
                                      borderRadius: "2px",
                                      cursor: "pointer",
                                    }}
                                    label="Show less"
                                    icon={<ExpandLess />}
                                    onClick={() => setShowAllAllergies(false)}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {familyHistory?.length > 0 && (
                        <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}>
                          <Grid
                            sx={{
                              // paddingLeft: "10px",
                              fontSize: { md: "12px", lg: "13px", xl: "14px" },
                              fontWeight: "600",
                              margin: "0",
                              color: "#004c70",
                              lineHeight: "normal",
                            }}
                          >
                            Family History
                          </Grid>
                          <div>
                            <List sx={{ p: "0px" }}>
                              {familyHistory
                                .slice(0, showAllFamilyHistory ? familyHistory.length : 3)
                                .map((item, index) => {
                                  const colorCode = getColorAndBackgroundColorBySeverity(
                                    item.severity
                                  );
                                  return (
                                    <ListItem
                                      sx={{
                                        p: "0px 10px",
                                        "&:hover": {
                                          backgroundColor: "#e6eef1", // Change this to the color you want
                                        },
                                      }}
                                      key={index}
                                    >
                                      <ListItemIcon sx={{ minWidth: "auto", pr: 1 }}>
                                        <FiberManualRecord
                                          sx={{ fontSize: "8px", color: "#0C2D48" }}
                                        />
                                      </ListItemIcon>
                                      <Tooltip title={toCamelCase(item?.relationType)}>
                                        <ListItemText
                                          primaryTypographyProps={{
                                            sx: {
                                              color: `${colorCode?.color} !important`,
                                              fontSize: { md: "12px", lg: "13px", xl: "14px" },
                                            },
                                          }}
                                          primary={`${item?.medicalCondition} (${toCamelCase(
                                            item?.relationType
                                          )})`}
                                        ></ListItemText>
                                      </Tooltip>
                                    </ListItem>
                                  );
                                })}
                            </List>
                            {familyHistory.length > 3 && (
                              <div>
                                {!showAllFamilyHistory ? (
                                  <Chip
                                    sx={{
                                      backgroundColor: "transparent",
                                      borderRadius: "2px",
                                      cursor: "pointer",
                                    }}
                                    label={`+${familyHistory.length - 3} more`}
                                    onClick={() => setShowAllFamilyHistory(true)}
                                  />
                                ) : (
                                  <Chip
                                    sx={{
                                      backgroundColor: "transparent",
                                      borderRadius: "2px",
                                      cursor: "pointer",
                                    }}
                                    label="Show less"
                                    icon={<ExpandLess />}
                                    onClick={() => setShowAllFamilyHistory(false)}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {chronicDiseases?.length > 0 && (
                        <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}>
                          <Grid
                            sx={{
                              // paddingLeft: "10px",
                              fontWeight: "600",
                              margin: "0",
                              color: "#004c70",
                              lineHeight: "normal",
                              fontSize: { md: "12px", lg: "13px", xl: "14px" },
                            }}
                          >
                            Medical conditions
                          </Grid>
                          <div>
                            <List sx={{ p: "0px" }}>
                              {chronicDiseases
                                .slice(0, showAllChronicDiseases ? chronicDiseases.length : 3)
                                .map((item, index) => {
                                  const colorCode = getColorAndBackgroundColorBySeverity(
                                    item.severity
                                  );
                                  return (
                                    <ListItem
                                      sx={{
                                        p: "0px 10px",
                                        "&:hover": {
                                          backgroundColor: "#e6eef1", // Change this to the color you want
                                        },
                                      }}
                                      key={index}
                                    >
                                      <ListItemIcon sx={{ minWidth: "auto", pr: 1 }}>
                                        <FiberManualRecord
                                          sx={{ fontSize: "8px", color: "#0C2D48" }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primaryTypographyProps={{
                                          sx: {
                                            color: `${colorCode?.color} !important`,
                                            fontSize: { md: "12px", lg: "13px", xl: "14px" },
                                          },
                                        }}
                                        primary={item?.chronicDiseaseResponse?.name}
                                      ></ListItemText>
                                    </ListItem>
                                  );
                                })}
                            </List>
                            {chronicDiseases.length > 3 && (
                              <div>
                                {!showAllChronicDiseases ? (
                                  <Chip
                                    sx={{
                                      backgroundColor: "transparent",
                                      borderRadius: "2px",
                                      cursor: "pointer",
                                    }}
                                    label={`+${chronicDiseases.length - 3} more`}
                                    onClick={() => setShowAllChronicDiseases(true)}
                                  />
                                ) : (
                                  <Chip
                                    sx={{
                                      backgroundColor: "transparent",
                                      borderRadius: "2px",
                                      cursor: "pointer",
                                    }}
                                    label="Show less"
                                    icon={<ExpandLess />}
                                    onClick={() => setShowAllChronicDiseases(false)}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {bodyImplants?.length > 0 && (
                        <div style={{ display: "flex", flexDirection: "column", gap: "0.3rem" }}>
                          <Grid
                            sx={{
                              // paddingLeft: "10px",
                              fontWeight: "600",
                              margin: "0",
                              color: "#004c70",
                              lineHeight: "normal",
                              fontSize: { md: "12px", lg: "13px", xl: "14px" },
                            }}
                          >
                            Body Implants
                          </Grid>
                          <div>
                            <List sx={{ p: "0px" }}>
                              {bodyImplants
                                .slice(0, showAllBodyImplants ? bodyImplants.length : 3)
                                .map((item, index) => {
                                  const colorCode = getColorAndBackgroundColorBySeverity(
                                    item?.severity
                                  );
                                  return (
                                    <ListItem
                                      sx={{
                                        p: "0px 10px",
                                        "&:hover": {
                                          backgroundColor: "#e6eef1", // Change this to the color you want
                                        },
                                      }}
                                      key={index}
                                    >
                                      <ListItemIcon sx={{ minWidth: "auto", pr: 1 }}>
                                        <FiberManualRecord
                                          sx={{ fontSize: "8px", color: "#0C2D48" }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primaryTypographyProps={{
                                          sx: {
                                            color: `${colorCode?.color} !important`,
                                            fontSize: { md: "12px", lg: "13px", xl: "14px" },
                                          },
                                        }}
                                        primary={item?.name}
                                      ></ListItemText>
                                    </ListItem>
                                  );
                                })}
                            </List>
                            {bodyImplants.length > 3 && (
                              <div>
                                {!showAllBodyImplants ? (
                                  <Chip
                                    sx={{
                                      backgroundColor: "transparent",
                                      borderRadius: "2px",
                                      cursor: "pointer",
                                    }}
                                    label={`+${bodyImplants.length - 3} more`}
                                    onClick={() => setShowAllBodyImplants(true)}
                                  />
                                ) : (
                                  <Chip
                                    sx={{
                                      backgroundColor: "transparent",
                                      borderRadius: "2px",
                                      cursor: "pointer",
                                    }}
                                    label="Show less"
                                    icon={<ExpandLess />}
                                    onClick={() => setShowAllBodyImplants(false)}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {/* //   )} */}
                  </div>
                  <div
                    style={{
                      width: "50%",
                      // padding: "0px 0px 10px 0px",
                      height: "100%",
                    }}
                  >
                    {selectedAppointment?.type === CLINIC_VISIT ? (
                      <PrescriptionPadForClinicVisit
                        sethuSirLayout={true}
                        appointmentId={selectedAppointment?.id}
                        isMediumScreen={isMediumScreen}
                        isExtraLargeScreen={isExtraLargeScreen}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "100%",
                        }}
                      >
                        <CustomButton
                          className={"mui-btn--primary"}
                          label={"Join Meet"}
                          gap={"0px"}
                          startIcon={<PlayCircleOutline></PlayCircleOutline>}
                          onClick={() => {
                            // handleVideoCall();
                            dispatch(
                              setPrescriptionPadData({
                                diagnosisArr: [],
                                symptomsArr: [],
                                medicationsArr: [],
                                labInvestigationsArr: [],
                                advicesArr: [],
                                soapNotes: {},
                              })
                            );
                            dispatch(setClearInvoiceState());
                            dispatch(setPrescriptionId(null));
                            if (selectedAppointment.type === TELE_CONSULTATION) {
                              handleJoinRoom();
                            } else {
                              navigate(`prescriptionPad/${selectedAppointment?.id}`);
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      width: "25%",
                      // padding: "0px 0px 10px 0px",
                      display: "flex",
                      flexDirection: "column",
                      // gap: "1rem",
                    }}
                  >
                    <PatientVitalGraph
                      sethuSirLayout={true}
                      isMediumScreen={isMediumScreen}
                      isLargeScreen={isLargeScreen}
                      isExtraLargeScreen={isExtraLargeScreen}
                      vitalsGraphData={vitalsGraphData}
                      selectedAppointment={selectedAppointment}
                      setLatestVitals={setLatestVitals}
                      insightLoading={insightLoading}
                      thumbnaiGraph={thumbnaiGraph}
                      setThumbnailGraph={setThumbnailGraph}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <ModalUI
        visible={open}
        close={closeModal}
        title={"Create Patient Family History"}
        component={
          <PatientFamilyHistoryModal
            // selected={selected}
            handleClick={handleClick}
            close={(operation) => {
              if (operation === "save") {
                closeModal();
                fetchPatientFamilyHistory();
              } else {
                closeModal();
              }
            }}
            patientId={selectedAppointment?.patientId || selectedAppointment?.id}
          />
        }
      />
    </>
  );
};

const NoAppointmentScheduledPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "12px",
        padding: "20px",
        backgroundColor: "#ffffff",
        gap: "1rem",
      }}
    >
      <div>
        <img
          src="/images/time_management_acute.svg"
          alt="Page not found"
          height={"352px"}
          width={"352px"}
          // maxHeight="352px"
          // maxWidth="352px"
        />
      </div>
      <div
        style={{ flexGrow: 1 }}
        // maxWidth={"494px"} maxHeight={"215px"}
      >
        <div
          style={{
            // maxWidth: "234px",
            // minHeight: "105px",
            gap: "0px",
            opacity: "0px",
          }}
        >
          {/* <Typography fontWeight={600} fontSize={"40px"} lineHeight={"60px"} color={"#455a64"}>
            Oops,
          </Typography> */}
          <Typography fontWeight={600} fontSize={"32px"} lineHeight={"48px"} color={"#29BF91"}>
            <span style={{ color: "#455a64" }}>No Appointments </span>Scheduled...
          </Typography>
        </div>
        <div
          style={{
            // maxWidth: "494px",
            // minHeight: "42px",
            top: "204px",
            left: "392px",
          }}
        >
          <Typography
            fontWeight={400}
            fontSize={"14px"}
            lineHeight={"21px"}
            color={"#455a64"}
            margin={"20px 0"}
          >
            You do not have any appointments scheduled for today.
            {/* We can't find the page. The link you followed may be broken, the page have been
            removed or moved to a new address. */}
          </Typography>
        </div>

        {/* <div>
          <CustomButton
            variant="contained"
            size="large"
            className={"btn--primary"}
            onClick={() => {
              // navigate("/home/dashboard");
            }}
            startIcon={<KeyboardDoubleArrowLeft />}
            label={"Back To Home"}
          ></CustomButton>
        </div> */}
      </div>
    </div>
  );
};

export default PrescriptionPatientDetailsSethuSir;
