// UI for the QR generation from the clinic side

import { Box, Skeleton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { useState, useEffect } from "react";
import { SKELETON_LOADING_TIME_IN_MILLISECONDS, orgId } from "store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { DvrOutlined, PermIdentityOutlined } from "@mui/icons-material";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { useContext } from "react";
import { getDoctorByOrgId } from "services/doctorService";
import { getOrgById } from "services/organizationService";
import Reveal from "views/utilities/Reveal";
import { scanAndShareQr } from "services/HfrService";
import { useNavigate } from "react-router";
import OfflineAlert from "OfflineAlert";
import { Online } from "react-detect-offline";

const QRCodeGenerator = () => {
  const [facilityName, setFacilityName] = useState("");

  // change HIP Code with the HIP Code of facility generated after HFR
  const [hipCode, setHipCode] = useState(null);
  // counter id will be set as per clinic need, sent back to clinic with share profile
  const [counterId, setCounterId] = useState("");
  const [qrPurpose, setQrPurpose] = useState("");
  const { handleClick } = useContext(ToastContext);
  const navigate = useNavigate();

  const [counterIdList, setCounterIdList] = useState([]);
  const qrPurposeList = [];
  const userOrgId = orgId();
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [counterIdError, setCounterIdError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (!userOrgId) return;
      try {
        const [doctorResponse, organizationResponse] = await Promise.all([
          getDoctorByOrgId(userOrgId),
          getOrgById(userOrgId),
        ]);

        const doctorDataList = doctorResponse.data.map((response) => ({
          id: response.id.toString(),
          name: response.userResponse.name,
        }));
        setCounterIdList((prevList) => [...prevList, ...doctorDataList]);
        setFacilityName(organizationResponse.data.name);
        setHipCode(organizationResponse.data.hfrFacilityId);
      } catch (error) {
        console.error("Error fetching doctor's list");
      }
    };

    fetchData();
  }, [userOrgId, handleClick]);

  const handleHipChange = (event) => {
    setHipCode(event.target.value);
  };

  const handleQrGenerate = async () => {
    if (counterIdError === "") {
      if (!counterId) {
        setCounterIdError("Counter Id is required.");
      } else {
        const response = await scanAndShareQr(hipCode, counterId, null);
        setQrCodeUrl(URL.createObjectURL(response.data));
      }
    }
  };

  return (
    <>
      <OfflineAlert />
      <Online>
        <QRCodeGeneratorSkeleton>
          <Reveal>
            <div>
              <div style={{ marginBottom: "12px" }}>
                <h2 className="page-title">Generate QR Code</h2>
              </div>
              <div style={{ display: "flex", flexDirection: "row", height: "70vh" }}>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FormInputField
                    style={{
                      width: "70%",
                      marginRight: "30px",
                      marginBottom: "25px",
                      marginTop: "10px",
                    }}
                    label="HIP Code"
                    type="text"
                    name="hipCode"
                    value={hipCode}
                    disabled={true}
                    onChange={handleHipChange}
                    startAdornment={<PermIdentityOutlined />}
                    size={"big"}
                  />

                  <FormSelectField
                    style={{ width: "70%", marginBottom: "25px", marginRight: "30px" }}
                    label="Counter ID"
                    name="counterId"
                    disabled={hipCode ? false : true}
                    onChange={(e) => {
                      if (e.target.value) {
                        setCounterIdError("");
                      } else {
                        setCounterId("Counter Id is required.");
                      }
                      setCounterId(e.target.value);
                    }}
                    startAdornment={<PermIdentityOutlined />}
                    menuItems={counterIdList.map((el) => {
                      return {
                        ...el,
                        value: el?.id,
                        menuLabel: el?.name,
                      };
                    })}
                    value={counterId}
                    size={"big"}
                    error={Boolean(counterIdError)}
                    errorText={counterIdError}
                  />
                  <FormSelectField
                    style={{ width: "70%", marginBottom: "25px", marginRight: "30px" }}
                    label="QR Code Purpose"
                    name="qrPurpose"
                    disabled={hipCode ? false : true}
                    onChange={(e) => {
                      setQrPurpose(e.target.value);
                    }}
                    startAdornment={<DvrOutlined />}
                    menuItems={qrPurposeList.map((el) => {
                      return {
                        ...el,
                        value: el,
                        menuLabel: el,
                      };
                    })}
                    value={qrPurpose}
                    size={"big"}
                  ></FormSelectField>
                  <CustomButton
                    className="btn--primary"
                    label={"Generate QR"}
                    onClick={handleQrGenerate}
                    disabled={hipCode ? false : true}
                  ></CustomButton>
                </div>
                <hr></hr>
                <div
                  style={{
                    margin: "25px auto",
                    width: "50%",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h1" style={{ marginBottom: "25px" }}>
                    {facilityName}
                  </Typography>
                  {hipCode ? (
                    qrCodeUrl ? (
                      <img src={qrCodeUrl} alt="Qr Code" width={300} height={300}></img>
                    ) : (
                      <div
                        style={{ width: "300px", height: "300px", backgroundColor: "white" }}
                      ></div>
                    )
                  ) : (
                    <>
                      <Typography style={{ color: "red", fontSize: "16px", marginBottom: "25px" }}>
                        No HFR ID to generate QR Code
                      </Typography>
                      <CustomButton
                        className="btn--primary"
                        label={"Link HFR"}
                        onClick={() => {
                          navigate("/home/HprLogin");
                        }}
                      ></CustomButton>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Reveal>
        </QRCodeGeneratorSkeleton>
      </Online>
    </>
  );
};

const QRCodeGeneratorSkeleton = ({ children, doctorOrganizations }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 3 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>
        <Grid container size={{ xs: 12 }} spacing={2} flexDirection={"row"} sx={{ mt: 2 }}>
          <Grid container size={{ xs: 6 }} sx={{ flexDirection: "column", alignItems: "center" }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="71%"
              height={50}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mt: 3 }}
              animation="wave"
              variant="rectangular"
              width="71%"
              height={50}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mt: 3 }}
              animation="wave"
              variant="rectangular"
              width="71%"
              height={50}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mt: 2 }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={30}
            />
          </Grid>

          <Grid
            size={{ xs: 6 }}
            sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 5 }}
          >
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width={300}
              height={300}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QRCodeGenerator;
