import { Person } from "@mui/icons-material";
import { FormControl } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useContext } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { api } from "services/AxiosInterceptor";
import { orgId } from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import CustomTypography from "ui-component/custom-components/CustomTypography";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import Reveal from "views/utilities/Reveal";

const HfrLink = () => {
  const { handleClick } = useContext(ToastContext);
  const [hfrId, setHfrId] = useState("");
  const navigate = useNavigate();
  const navigateToRegisterFacility = () => {
    navigate("/home/HprLogin");
  };
  const organizationId = orgId();

  const linkHfr = async () => {
    try {
      await api.post(
        `/api/abdm/hfr/hprlinkage/link?hfrFacilityId=${hfrId}&orgId=${organizationId}`
      );
      setHfrId("");
      handleClick("success", "Hfr Id has been successfully linked.");
    } catch (error) {
      setHfrId("");
      handleClick("error", error.response.data.message);
    }
  };

  return (
    <Reveal>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <h2 style={{ display: "inline" }}>Link HfrId with Your Organization</h2>
        <CustomButton
          className="btn--primary"
          style={{ marginLeft: "auto", marginTop: "8px" }}
          label="Register your Health Facility"
          onClick={() => navigateToRegisterFacility()}
        />
      </div>

      <Grid container justifyContent="center" alignItems="center">
        <div className="otp-container" style={{ width: "600px" }}>
          <CustomTypography variant="h2" style={{ textAlign: "center" }}>
            Link HfrId
          </CustomTypography>

          <div className="aadhar-input-container">
            <CustomTypography style={{ margin: "20px 0px", fontSize: "18px" }}>
              Enter the HFR-ID you want to link with your organization
            </CustomTypography>

            <FormControl sx={{ my: 1 }} variant="outlined">
              <FormInputField
                id="hfrId"
                type="text"
                inputProps={{
                  maxLength: 12,
                }}
                value={hfrId}
                onChange={(e) => setHfrId(e.target.value)}
                label="HFR ID"
                startAdornment={<Person />}
              />
            </FormControl>
          </div>

          <div
            className="aadhar-input-container"
            style={{ alignContent: "center", alignItems: "center" }}
          >
            <CustomButton
              label="Submit"
              onClick={linkHfr}
              className="btn--primary"
              disabled={!(hfrId.length === 12)}
            />
          </div>
        </div>
      </Grid>
    </Reveal>
  );
};

export default HfrLink;
