import axios from "axios";
import { hiuToken } from "store/constant";

export const getApprovedRecords = (limit, doctorId, patientAbhaId) =>
  axios.get(
    `https://abdmconnector.argusservices.in/api/hiu/consents/get-summary-data?limit=${limit}&healthId=${patientAbhaId}`,
    {
      headers: {
        "X-M3-Token": `Bearer ${hiuToken}`,
        "X-User": doctorId,
      },
    }
  );
