import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  LineChart,
  BarChart,
  PieChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  Bar,
  Pie,
  Cell,
  ResponsiveContainer,
  Label,
  ScatterChart,
  Scatter,
  Area,
  AreaChart,
} from "recharts";
import { checkQuery } from "services/DashboardService";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ModalUI from "ui-component/ModalUI";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import dayjs from "dayjs";
import CustomButton from "ui-component/custom-components/CustomButton";
import { DATE_FORMAT_DMY } from "store/constant";

const ChartDisplay = ({ querydata, onFilterParamsChange, isChartList }) => {
  const { chartType, queryData, Colors, title, options, queryText, params } = querydata || {};

  const [chartData, setChartData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterParams, setFilterParams] = useState(options ? { ...options.params } : { ...params });
  const colors = options ? { ...options.Colors } : { ...Colors };
  const { handleClick } = useContext(ToastContext);
  const [isDateValid, setIsDateValid] = useState(true);
  const { xAxis, yAxis, Section, Value, Columns } = options || {};
  const [isAllIntegers, setIsAllIntegers] = useState(false);

  const resetFilters = () => {
    if (options && options.params) {
      setFilterParams({ ...options.params });
    }
  };

  useEffect(() => {
    resetFilters();
  }, [options.params]);

  const initialFilterParams = useRef(filterParams);

  useEffect(() => {
    if (queryData) {
      let newChartData = [];

      const isChartTypeValid = ["Bar Chart", "Line Chart", "Scatter Chart", "Area Chart"].includes(chartType);

      if (isChartTypeValid) {
        newChartData = queryData
          .filter((object) => object[xAxis] !== null && object[yAxis] !== null)
          .map((object) => ({
            [xAxis]: object[xAxis],
            [yAxis]: object[yAxis],
          }));
        setIsAllIntegers(newChartData.every((item) => Number.isInteger(item[yAxis])));
      } else if (chartType === "Pie Chart") {
        newChartData = queryData.map((object) => ({
          [Section[0]]: object[Section[0]],
          [Value[0]]: object[Value[0]],
        }));
      } else {
        newChartData = queryData;
      }
      setChartData(newChartData);
    }
  }, [queryData, xAxis, yAxis, Section, Value, chartType]);

  const renderTable = () => (
    <ResponsiveContainer width="100%" height="100%">
      <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: `${colors[0]}`, color: "white" }}>
              <TableCell sx={{ color: "white" }}>Sr. No</TableCell>
              {Columns.map((column, index) => (
                <TableCell key={index} sx={{ color: "white" }}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {chartData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                {Columns.map((column, colIndex) => {
                  const cellData = row[column];
                  const isValidData =
                    typeof cellData === "string" ||
                    typeof cellData === "number" ||
                    React.isValidElement(cellData);
                  return <TableCell key={colIndex}>{isValidData ? cellData : "N/A"}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ResponsiveContainer>
  );

  const renderChartComponents = () => {
    if (chartData.length === 0) {
      return (
        <Box display="flex" justifyContent="center" marginBottom="20px">
          <Typography variant="body1">No data to display.</Typography>
        </Box>
      );
    }

    const renderCard = () => {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <Card className="info-card">
            <CardContent
              style={{
                height: "100%",
                padding: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="card-field-name">{title}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span className="card-field-value" style={{ color: `${colors[0]}` }}>
                    {chartData[0][Object.keys(chartData[0])]}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                ></div>
              </div>
            </CardContent>
          </Card>
        </ResponsiveContainer>
      );
    };

    switch (chartType) {
      case "Count Card":
        return renderCard();
      case "Line Chart":
        return (
          <ResponsiveContainer minWidth={"100% !important"} height={300}>
            <LineChart component={Paper} data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={xAxis}>
                <Label value={xAxis} position="insideBottom" offset={-10} />
              </XAxis>
              <YAxis
                tickFormatter={(tick) => (Number.isInteger(tick) ? tick : null)}
                allowDecimals={!isAllIntegers}
              >
                <Label value={yAxis} angle={-90} dx={-30} />
              </YAxis>
              <Tooltip />
              <Legend
                layout="horizontal"
                align="center"
                verticalAlign="bottom"
                wrapperStyle={{ paddingTop: "18px" }}
              />
              <Line type="monotone" dataKey={yAxis} stroke={colors[0]} />
            </LineChart>
          </ResponsiveContainer>
        );

      case "Bar Chart":
        return (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={xAxis}>
                <Label value={xAxis} position="insideBottom" offset={-10} />
              </XAxis>
              <YAxis
                tickFormatter={(tick) => (Number.isInteger(tick) ? tick : null)}
                allowDecimals={!isAllIntegers}
              >
                <Label value={yAxis} angle={-90} dx={-30} />
              </YAxis>
              <Tooltip />
              <Legend
                layout="horizontal"
                align="center"
                verticalAlign="bottom"
                wrapperStyle={{ paddingTop: "18px" }}
              />
              <Bar dataKey={yAxis} fill={colors[0]} />
            </BarChart>
          </ResponsiveContainer>
        );

      case "Pie Chart":
        return (
          <ResponsiveContainer width="100%" height={300}>
            <PieChart minWidth={300} minHeight={300}>
              <Pie
                data={chartData}
                dataKey={Value[0]}
                nameKey={Section[0]}
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#004c70"
                label
              >
                {chartData.map((entry, idx) => (
                  <Cell key={`cell-${idx}`} fill={colors[entry[Section[0]]] || "#004c70"} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
              <Label value="Pie Chart Title" position="centerTop" fontSize="16" fill="#004c70" />
            </PieChart>
          </ResponsiveContainer>
        );

      case "Scatter Chart":
        return (
          <ResponsiveContainer width="100%" height={300}>
            <ScatterChart
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid />
              <XAxis dataKey={xAxis}>
                <Label value={xAxis} position="insideBottom" offset={-10} />
              </XAxis>
              <YAxis
                tickFormatter={(tick) => (Number.isInteger(tick) ? tick : null)}
                allowDecimals={!isAllIntegers}
              >
                <Label value={yAxis} angle={-90} dx={-10} />
              </YAxis>
              <Tooltip cursor={{ strokeDasharray: "3 3" }} />
              <Scatter data={chartData} fill={colors[0]} />
            </ScatterChart>
          </ResponsiveContainer>
        );

      case "Area Chart":
        return (
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart
              data={chartData}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={xAxis}>
                <Label value={xAxis} position="insideBottom" offset={-10} />
              </XAxis>
              <YAxis
                tickFormatter={(tick) => (Number.isInteger(tick) ? tick : null)}
                allowDecimals={!isAllIntegers}
              >
                <Label value={yAxis} angle={-90} dx={-10} />
              </YAxis>
              <Tooltip />
              <Area type="monotone" dataKey={yAxis} stroke={colors[0]} fill={colors[0]} />
            </AreaChart>
          </ResponsiveContainer>
        );

      case "Table":
        return renderTable();

      default:
        return (
          <Typography variant="body1">Select a chart type and generate data to display.</Typography>
        );
    }
  };

  useEffect(() => {
    const startDate = filterParams?.dateRange?.fromDate;
    const endDate = filterParams?.dateRange?.toDate;
    if (startDate && endDate) {
      setIsDateValid(dayjs(startDate) <= dayjs(endDate));
    } else {
      setIsDateValid(true);
    }
  }, [filterParams]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const [parentKey, childKey] = name.split(".");

    setFilterParams((prevParams) => ({
      ...prevParams,
      [parentKey]: {
        ...prevParams[parentKey],
        [childKey]: value === "" ? null : value,
      },
    }));
  };

  const clearFilters = () => {
    const clearedFilters = Object.fromEntries(
      Object.entries(filterParams).map(([key, value]) => {
        if (value && typeof value === "object") {
          const clearedObject = Object.fromEntries(
            Object.entries(value).map(([innerKey]) => [innerKey, null])
          );
          return [key, clearedObject];
        } else {
          return [key, null];
        }
      })
    );
    setFilterParams(clearedFilters);
  };

  const renderFilterFields = () => {
    return (
      <>
        {Object.keys(filterParams).map((key) => (
          <Grid container size={{ xs: 12 }} sx={{ marginTop: "10px" }} spacing={2} key={key}>
            {key === "dateRange"
              ? Object.keys(filterParams[key]).map((filterKey) => {
                  return (
                    <Grid size={{ xs: 6 }} key={filterKey}>
                      <FormInputField
                        style={{ width: "100%" }}
                        label={filterKey}
                        name={`${key}.${filterKey}`}
                        type="date"
                        required
                        shrink={true}
                        value={filterParams[key][filterKey] || ""}
                        onChange={handleFilterChange}
                        error={!isDateValid}
                        errorText={
                          !isDateValid ? "From Date should be less than or equal to To Date" : ""
                        }
                      />
                    </Grid>
                  );
                })
              : null}
          </Grid>
        ))}
        <Grid size={{ xs: 12 }} display={"flex"} justifyContent={"flex-end"} marginTop={"10px"}>
          <CustomButton
            className="btn--secondary-light"
            style={{ marginRight: "5px" }}
            onClick={() => clearFilters()}
            label={"Clear"}
          />
          <CustomButton
            className="btn--secondary-light"
            onClick={() => {
              saveDetails();
              setIsModalOpen(false);
            }}
            label={"Save"}
          />
        </Grid>
      </>
    );
  };

  const saveDetails = async () => {
    try {
      if (initialFilterParams.current !== filterParams) {
        let queryParams = Object.keys(filterParams).reduce((acc, filter) => {
          return { ...acc, ...filterParams[filter] };
        }, {});
        const data = {
          query: queryText,
          params: queryParams,
          status: "ACTIVE",
        };

        const response = await checkQuery(data);
        const newChartData = response.data.data;
        setChartData(newChartData);
        onFilterParamsChange(newChartData, filterParams);
      }
    } catch (error) {
      handleClick("error", error?.response?.data?.message);
    }
  };

  return (
    <Grid container spacing={1}>
      {!isChartList &&
        ((options && options.params && Object.keys(options.params).length !== 0) ||
          (params && Object.keys(params).length !== 0)) && (
          <Grid size={{ xs: 12 }} display={"flex"} justifyContent={"space-between"}>
            <FilterAltIcon onClick={() => setIsModalOpen(true)} style={{ cursor: "pointer" }} />
            {options?.params?.dateRange &&
              !Object.values(options.params.dateRange).every((value) => value === null) && (
                <Box
                  component="span"
                  sx={{
                    display: "inline-block",
                    padding: "6px 12px",
                    backgroundColor: "#f0f4f7",
                    borderRadius: "8px",
                    color: "#004c70",
                    border: "1px solid #b3c5cd",
                    fontSize: "14px",
                  }}
                >
                  {options.params.dateRange.fromDate || options.params.dateRange.toDate ? (
                    <div>
                      {`From: ` +
                        (dayjs(options.params.dateRange.fromDate).format(DATE_FORMAT_DMY) ??
                          "Not Set")}
                      <br />
                      {`To: ` +
                        (dayjs(options.params.dateRange.toDate).format(DATE_FORMAT_DMY) ??
                          "Not Set")}
                    </div>
                  ) : (
                    // : options.params.dateRange.fromDate
                    // ? `From ${options.params.dateRange.fromDate}`
                    // : options.params.dateRange.toDate
                    // ? `Till ${options.params.dateRange.toDate}`
                    ""
                  )}
                </Box>
              )}
          </Grid>
        )}
      {chartType !== "Count Card" && (
        <Grid size={{ xs: 12 }} display="flex" justifyContent="center">
          <Typography className="card-field-value">{title}</Typography>
        </Grid>
      )}
      <Grid size={{ xs: 12 }}>
        <Box>{renderChartComponents()}</Box>
      </Grid>
      <ModalUI
        visible={isModalOpen}
        close={() => {
          resetFilters();
          setIsModalOpen(false);
        }}
        title="Set filter"
        style={{ padding: 15 }}
        component={renderFilterFields()}
      />
    </Grid>
  );
};

export default ChartDisplay;
