import React, { useState, useEffect } from "react";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import Grid from "@mui/material/Grid2";
import { Typography } from "@mui/material";
import { responseFormatter } from "utils/response-formatter";

const ProfileEditModal = ({ modalData, updatePatientMismatchedProfile }) => {
  const [isModalOpen, setIsModalOpen] = useState(modalData?.showModal);

  useEffect(() => {
    setIsModalOpen(modalData?.showModal);
  }, [modalData?.showModal]);

  return (
    <div>
      <ModalUI
        visible={isModalOpen}
        close={() => {
          updatePatientMismatchedProfile("cancel", false);
          setIsModalOpen(false);
        }}
        style={{
          width: "32rem",
          padding: "5px",
        }}
        closeButtonStylling={{
          position: "relative",
        }}
        component={
          <div>
            <Grid container>
              <Typography
                variant="h3"
                color="primary"
                sx={{ margin: "auto", fontSize: "1.55rem", lineHeight: "3.2rem" }}
              >
                Patient Details Do Not Match!
              </Typography>
            </Grid>
            <Grid item sx={{ margin: "10px auto", width: "92%" }}>
              <Typography color="error" sx={{ marginLeft: "18px" }}>
                {responseFormatter(modalData?.messageText)}
              </Typography>
            </Grid>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "flex-end",
                height: "4em",
                margin: "15px 0px",
              }}
            >
              <CustomButton
                className="btn--secondary"
                style={{ backgroundColor: "#ff7070" }}
                onClick={() => {
                  updatePatientMismatchedProfile("cancel", false);
                }}
              >
                Cancel
              </CustomButton>
              <CustomButton
                className="btn--primary"
                onClick={() => {
                  updatePatientMismatchedProfile("update", modalData?.allowAbhaAddressUpdate);
                }}
              >
                Update Details
              </CustomButton>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ProfileEditModal;
