import React, { useState } from "react";
import { Box, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect } from "react";
import { deleteDashboard, getAllDashboards } from "services/DashboardService";
import { useNavigate } from "react-router";
import { ACTIVE_STATE, SKELETON_LOADING_TIME_IN_MILLISECONDS } from "store/constant";
import Reveal from "views/utilities/Reveal";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import CustomButton from "ui-component/custom-components/CustomButton";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";

const GenerateDashboard = () => {
  const [allDashboards, setAllDashboards] = useState([]);
  const navigate = useNavigate();
  const { handleClick } = useContext(ToastContext);

  const initializeComponent = (e) => {
    e.id
      ? navigate("/home/createEditDashboard", { state: e })
      : navigate("/home/createEditDashboard");
  };

  const handleMarkInactive = async (dashboard) => {
    if (dashboard.status === ACTIVE_STATE) {
      try {
        await deleteDashboard(dashboard.id);
        handleClick("success", "Dashboard has been marked Inactive!");
        const index = allDashboards.findIndex((el) => el.id === dashboard.id);
        allDashboards.splice(index, 1);
      } catch (error) {
        handleClick("error", "There seems to be an error deleting dashboard.");
      }
    } else {
      handleClick("info", "Dashboard is already inactive!");
    }
  };

  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "name", label: "Dashboard Name" },
    { field: "description", label: "Description" },
    { field: "status", label: "Status" },
    { field: "Actions", label: "Actions" },
  ];

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: initializeComponent,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleMarkInactive,
    },
  ];

  useEffect(() => {
    const fetchDashboards = async () => {
      try {
        const response = await getAllDashboards();
        setAllDashboards(response.data?.filter((dashboard) => dashboard.status === "ACTIVE"));
      } catch (error) {
        console.error(error);
      }
    };
    fetchDashboards();
  }, []);

  return (
    <GenerateDashBoardSkeleton>
      <Reveal>
        <>
          <div style={{ display: "flex" }}>
            <h2 style={{ display: "inline" }}>Dashboards</h2>
            <CustomButton
              className="ri-add-fill ri-lg btn--primary"
              style={{ marginLeft: "auto", marginBottom: "10px" }}
              onClick={initializeComponent}
              label="New Dashboard"
            />
          </div>
          {allDashboards.length !== 0 && (
            <CustomizedTable columns={columns} tableData={allDashboards} actions={actions} />
          )}
        </>
      </Reveal>
    </GenerateDashBoardSkeleton>
  );
};

export const GenerateDashBoardSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={400}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={125}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={125}
          />
        </Grid>
        <Grid container size={{ xs: 12 }} justifyContent={"end"}>
          <Grid size={{ xs: 3 }} display={"flex"}>
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="50%"
              height={30}
            />
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="50%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GenerateDashboard;
