// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.color-input-field {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: text;
  background-color: #f9f9f9;
  align-items: center;
}

.color-chips {
  display: flex;
  flex-wrap: wrap;
}

.color-chip, .color-chip-pie {
  display: flex;
  align-items: center;
  margin: 4px;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.color-chip-pie {
  width: 70%;
}

.color-swatch {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
}

.remove-button {
  margin-left: 5px;
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.hidden-input {
  border: none;
  outline: none;
  flex-grow: 1;
  min-width: 100px;
  margin-left: 4px;
  background: none;
}

.add-color-button {
  margin-top: 8px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/colorInputField.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;AACF;;AAEA;EAEE,UAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,UAAA;EACA,eAAA;AAAF;;AAGA;EACE,YAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,eAAA;EACA,eAAA;AAAF","sourcesContent":[".color-input-container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.color-input-field {\n  display: flex;\n  flex-wrap: wrap;\n  padding: 5px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  cursor: text;\n  background-color: #f9f9f9;\n  align-items: center;\n}\n\n.color-chips {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.color-chip {\n  display: flex;\n  align-items: center;\n  margin: 4px;\n  padding: 4px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n.color-chip-pie {\n  @extend .color-chip;\n  width: 70%;\n}\n\n.color-swatch {\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  margin-right: 5px;\n}\n\n.remove-button {\n  margin-left: 5px;\n  background: none;\n  border: none;\n  color: red;\n  cursor: pointer;\n}\n\n.hidden-input {\n  border: none;\n  outline: none;\n  flex-grow: 1;\n  min-width: 100px;\n  margin-left: 4px;\n  background: none;\n}\n\n.add-color-button {\n  margin-top: 8px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
