import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initialState = {
  documents: [],
  allReportDates: [],
  errors: [],
  pages: {},
  showDocumentPage: false,
  showDocumentIndex: 1,
};

const documentVitalSlice = createSlice({
  name: "documentVitals",
  initialState,
  reducers: {
    setDocumentVitals(state, action) {
      const { documents, allReportDates } = action.payload;
      state.documents = documents || [];
      state.allReportDates = allReportDates || [];
    },
    setReportDateOfDocumentVitals(state, action) {
      const { documentIndex, oldReportDate, newReportDate } = action.payload;
      const document = state.documents[documentIndex];

      if (document) {
        const vitalResponse = document.patientVitalResponses.find(
          (response) => response.reportDate === oldReportDate
        );

        if (vitalResponse) {
          vitalResponse.reportDate = newReportDate;
          vitalResponse.vitals = vitalResponse.vitals.map((vital) => ({
            ...vital,
            dateFrom: newReportDate,
            dateTo: newReportDate,
          }));

          state.allReportDates = [
            ...state.allReportDates.filter((date) => date !== oldReportDate),
            newReportDate,
          ];
        }
      }
    },
    setDocumentVitalValue(state, action) {
      const { documentIndex, reportDate, vitalIndex, value } = action.payload;
      const document = state.documents[documentIndex];

      if (document) {
        const vitalResponse = document.patientVitalResponses.find(
          (response) => response.reportDate === reportDate
        );

        if (vitalResponse && vitalResponse.vitals[vitalIndex]) {
          vitalResponse.vitals[vitalIndex] = {
            ...vitalResponse.vitals[vitalIndex],
            value,
          };
        }
      }
    },
    addDocumentVital(state, action) {
      const { documentIndex, reportDate, vital } = action.payload;
      const document = state.documents[documentIndex];

      if (document) {
        const vitalResponse = document.patientVitalResponses.find(
          (response) => response.reportDate === reportDate
        );

        if (vitalResponse) {
          vitalResponse.vitals = [...vitalResponse.vitals, vital];
        }
      }
    },
    setCheckedDocumentVital(state, action) {
      const { checked, value, delimeter } = action.payload;
      const [reportDate, documentIndex, vitalIndex] = value.split(delimeter);
      const document = state.documents[documentIndex];
      if (document) {
        const vitalResponse = document.patientVitalResponses.find(
          (response) => response.reportDate === reportDate
        );
        if (vitalResponse && vitalResponse.vitals[vitalIndex]) {
          vitalResponse.vitals[vitalIndex] = {
            ...vitalResponse.vitals[vitalIndex],
            checked,
          };
        }
      }
    },
    setDocumentError(state, action) {
      const { value, error } = action.payload;

      if (error) {
        if (!state.errors.includes(value)) {
          state.errors = [...state.errors, value];
        }
      } else {
        state.errors = state.errors.filter((e) => e !== value);
      }
    },
    setDocumentPages(state, action) {
      const { documentIndex, documentPages } = action.payload;
      state.pages[documentIndex] = documentPages;
    },
    setDocumentPageVisible(state, action) {
      const { showDocumentPage, showDocumentIndex } = action.payload;
      state.showDocumentPage = showDocumentPage;
      state.showDocumentIndex = showDocumentIndex;
    },
  },
});

export const {
  setDocumentVitals,
  setReportDateOfDocumentVitals,
  setDocumentVitalValue,
  addDocumentVital,
  setCheckedDocumentVital,
  setDocumentError,
  setDocumentPages,
  setDocumentPageVisible,
} = documentVitalSlice.actions;

export const getDocumentVitals = (state) => state.documentVitals;

export const getDocuments = createSelector(
  [getDocumentVitals],
  (documentVitals) => documentVitals.documents
);

export const getDocumentErrors = createSelector(
  [getDocumentVitals],
  (documentVitals) => documentVitals.errors
);

export const getAllReportDates = createSelector(
  [getDocumentVitals],
  (documentVitals) => documentVitals.allReportDates
);

export const getDocumentByIndex = (documentIndex) =>
  createSelector([getDocuments], (documents) => documents[documentIndex]);

export const getDocumentVitalsByReportDate = (documentIndex, reportDate) =>
  createSelector(
    [getDocumentByIndex(documentIndex)],
    (document) =>
      document.patientVitalResponses.find((response) => response.reportDate === reportDate)?.vitals
  );

export const getDocumentVitalByIndex = (documentIndex, reportDate, vitalIndex) =>
  createSelector([getDocumentVitalsByReportDate(documentIndex, reportDate)], (vitals) =>
    vitals ? vitals[vitalIndex] : undefined
  );

export const getDocumentPages = (documentIndex) =>
  createSelector([(state) => state.documentVitals.pages], (pages) => pages[documentIndex]);

export const getDocumentPageVisible = createSelector([getDocumentVitals], (documentVitals) => {
  return {
    showDocumentPage: documentVitals.showDocumentPage,
    showDocumentIndex: documentVitals.showDocumentIndex,
  };
});

export const documentVitalReducer = documentVitalSlice.reducer;
