import { CategoryOutlined, Description, PermIdentityOutlined } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import { Formik } from "formik";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { generateMockData } from "services/Subscription";
import { getAllUsers } from "services/userService";
import { PATIENT } from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import Reveal from "views/utilities/Reveal";
import { mockDataValidationSchema } from "../Common/ValidationSchema/mockDataValidationSchema";

const MockData = () => {
  const { handleClick } = useContext(ToastContext);
  const dataTypes = [{ menuLabel: "VITALS", value: "VITALS" }];
  const [users, setUsers] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userResponse = await getAllUsers();
        let usersMenu = [];
        userResponse?.data
          ?.filter((e) => e?.roleName === PATIENT)
          ?.forEach((element) => {
            const menuItem = {
              ...element,
              value: element?.id,
              menuLabel: element?.name,
            };

            usersMenu.push(menuItem);
          });

        setUsers(usersMenu);
      } catch (e) {
        handleClick("error", "Can not fetch Users");
      }
    };

    fetchUsers();
  }, [handleClick]);

  return (
    <Reveal>
      <div className="section-heading">
        <h2 className="page-title">Generate Mock Data</h2>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            userId: "",
            dataTypes: [],
            additionalInfo: "",
          }}
          validationSchema={mockDataValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            const userData = users?.find((e) => e?.id === values?.userId);

            const data = {
              userId: values?.userId,
              roleId: userData?.roleId,
              dataTypes: values?.dataTypes,
              additionalInfo: values?.additionalInfo,
            };

            try {
              await generateMockData(data);
              resetForm();
              handleClick("success", " Successfully generated Mock Data");
            } catch (error) {
              handleClick("error", "Can not generate Mock Data");
            }
          }}
        >
          {({ values, errors, touched, setFieldValue, handleChange, handleSubmit, handleBlur }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2} sx={{ marginTop: "10px", width: "600px" }}>
                <Grid size={{ xs: 12 }}>
                  <FormSelectField
                    style={{ width: "100%", marginTop: "7px" }}
                    label={"Select User"}
                    required
                    name={"userId"}
                    onBlur={handleBlur}
                    value={values?.userId}
                    size={"big"}
                    startAdornment={<PermIdentityOutlined />}
                    onChange={handleChange}
                    menuItems={users}
                    error={Boolean(errors.userId && touched.userId)}
                    errorText={errors.userId}
                  />
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <FormSelectField
                    style={{ width: "100%", marginTop: "7px" }}
                    label={"Data Type"}
                    required
                    multiple
                    name={"dataType"}
                    onBlur={handleBlur}
                    value={values?.dataTypes}
                    size={"big"}
                    startAdornment={<CategoryOutlined />}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setFieldValue("dataTypes", value);
                    }}
                    menuItems={dataTypes}
                    error={Boolean(errors.dataTypes && touched.dataTypes)}
                    errorText={errors.dataTypes}
                  />
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <FormInputField
                    style={{ width: "100%", marginTop: "7px" }}
                    label="Additional Information"
                    name="additionalInfo"
                    value={values.additionalInfo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    startAdornment={<Description />}
                    size={"big"}
                    multiline
                    error={Boolean(errors.additionalInfo && touched.additionalInfo)}
                    errorText={errors.additionalInfo}
                  />
                </Grid>

                <CustomButton
                  onClick={handleSubmit}
                  label={"Generate Data"}
                  className={"btn--secondary"}
                  style={{ marginLeft: "auto", marginTop: "20px" }}
                />
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </Reveal>
  );
};

export default MockData;
