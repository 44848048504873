import { Checkbox, Chip, IconButton, Input, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { getAllTenantApis, getTenantFilterApiList, saveTenantApis } from "services/tenantFilter";
import { v4 as uuidv4 } from "uuid";
import { useCallback } from "react";

const ITEMS_PER_PAGE = 25;

const TenantFilterConfiguration = () => {
  const [items, setItems] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [apiKeys, setApiKeys] = useState([]);
  const [searchTerms, setSearchTerms] = useState({});
  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (itemId) => {
    setCheckedItems((prev) =>
      prev.includes(itemId) ? prev.filter((id) => id !== itemId) : [...prev, itemId]
    );
  };

  const getFilteredItems = (cardId) => {
    const searchTerm = searchTerms[cardId];
    const cardItems = items[cardId];

    if (!searchTerm) return cardItems;

    return cardItems.filter((item) => item.path.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const methodColors = {
    GET: "#61affe",
    POST: "#49cc90",
    PUT: "#fca130",
    DELETE: "#f93e3e",
    "*": "#808080",
  };

  const bgColors = {
    GET: "rgba(97,175,254,.1)",
    POST: "rgba(73,204,144,.1)",
    PUT: "rgba(252,161,48,.1)",
    DELETE: "rgba(249,62,62,.1)",
    "*": "rgba(128, 128, 128,.1)",
  };

  const onDragEnd = (result) => {
    const { draggableId, source, destination } = result;

    if (!destination) return;

    const sourceCardId = source.droppableId;
    const destinationCardId = destination.droppableId;

    if (sourceCardId === destinationCardId || destinationCardId === "mainCard") {
      return;
    }

    const sourceItems = Array.isArray(items[sourceCardId]) ? [...items[sourceCardId]] : [];
    const destinationItems = Array.isArray(items[destinationCardId])
      ? [...items[destinationCardId]]
      : [];

    if (checkedItems.length !== 0) {
      const itemsToMove = sourceItems.filter((item) => checkedItems.includes(item.id));
      const remainingSourceItems = sourceItems.filter((item) => !checkedItems.includes(item.id));

      const updatedDestinationItems = [...itemsToMove, ...destinationItems];

      setItems((prevItems) => ({
        ...prevItems,
        [sourceCardId]: remainingSourceItems,
        [destinationCardId]: updatedDestinationItems,
      }));

      setCheckedItems([]);
    } else {
      const movedItemIndex = sourceItems.findIndex((i) => i.id === draggableId);
      if (movedItemIndex === -1) return;

      const [movedItem] = sourceItems.splice(movedItemIndex, 1);

      destinationItems.unshift(movedItem);

      setItems((prevItems) => ({
        ...prevItems,
        [sourceCardId]: sourceItems,
        [destinationCardId]: destinationItems,
      }));
    }
  };

  const handleRemove = (itemId, sourceCardId) => {
    const sourceItems = Array.from(items[sourceCardId]);
    const newItems = sourceItems.filter((item) => item.id !== itemId);

    setItems((prevItems) => ({
      ...prevItems,
      [sourceCardId]: newItems,
      mainCard: [...sourceItems.filter((item) => item.id === itemId), ...prevItems.mainCard],
    }));
  };

  useEffect(() => {
    const fetchApis = async () => {
      try {
        const response = await getAllTenantApis();
        const { data } = await getTenantFilterApiList();
        const newItems = Object.fromEntries(
          Object.entries(data).map(([key, value]) => {
            const updatedValue = value.map((item2) => ({ ...item2, id: uuidv4() }));
            return [key, updatedValue];
          })
        );

        const itemsNew = {
          mainCard: response.data.map((item) => ({ ...item, id: uuidv4() })),
          ...newItems,
        };

        setItems(itemsNew);

        const apikeys = Object.keys(itemsNew).filter((item) => item !== "mainCard");
        setApiKeys(apikeys);

        const searchTerms = apikeys.reduce((acc, element) => {
          acc[element] = "";
          return acc;
        }, {});

        setSearchTerms(searchTerms);
      } catch (error) {
        console.error(error);
      }
    };
    fetchApis();
  }, []);

  const [visibleItems, setVisibleItems] = useState(ITEMS_PER_PAGE);

  const handleScroll = useCallback((e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setVisibleItems((prevVisibleItems) => prevVisibleItems + 20);
    }
  }, []);

  const saveCards = async () => {
    try {
      const response = await Promise.all(
        Object.keys(items)
          .filter((item) => item !== "mainCard")
          .map(async (item) => {
            await saveTenantApis(items[item], item);
          })
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
        <h2 style={{ display: "inline" }}>Tenant Filter Configuration</h2>
        <CustomButton
          className="btn--primary"
          onClick={() => {
            saveCards();
          }}
          style={{ display: "flex", marginLeft: "auto" }}
          label={"Save"}
        />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={2} style={{ marginTop: "15px" }}>
          {/* Main Card with Items */}
          <Grid size={{ xs: 12, sm: 6, md: 6 }}>
            <Droppable droppableId="mainCard">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Paper
                    elevation={1}
                    sx={{
                      padding: 2,
                      minHeight: "100px",
                      margin: "auto",
                      borderRadius: 2,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "0 5px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "17px",
                          fontWeight: 600,
                          color: "#004C70",
                          mb: 1,
                        }}
                      >
                        All APIs
                      </Typography>
                      <Input
                        sx={{ ml: 1, mb: 1 }}
                        size="xl"
                        placeholder="  Search ..."
                        startAdornment={<i className="ri-search-line ri-lg" />}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                    <div
                      style={{ maxHeight: "810px", overflowY: "auto", scrollbarWidth: "none" }}
                      onScroll={handleScroll}
                    >
                      {items.mainCard
                        ?.filter((item) =>
                          item.path.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                        .slice(0, visibleItems)
                        .map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  padding: "1px",
                                  paddingLeft: "6px",
                                  margin: "4px 0",
                                  background: bgColors[item.method],
                                  border: `1px solid ${methodColors[item.method]}`,
                                  borderRadius: "4px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  maxHeight: "35px",
                                }}
                              >
                                <div>
                                  {item.method && (
                                    <Chip
                                      label={item.method}
                                      sx={{
                                        marginRight: 1,
                                        borderRadius: 1,
                                        width: 70,
                                        backgroundColor: methodColors[item.method],
                                        color: "white",
                                        fontWeight: "bold",
                                        maxHeight: "25px",
                                      }}
                                    />
                                  )}
                                  {item.path}
                                </div>
                                <div>
                                  <Checkbox
                                    onChange={() => handleCheckboxChange(item.id)}
                                    sx={{
                                      "&.Mui-checked": {
                                        color: methodColors[item.method],
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                    </div>
                    {provided.placeholder}
                  </Paper>
                </div>
              )}
            </Droppable>
          </Grid>

          {/* Target Cards */}
          <Grid size={{ xs: 12, sm: 6, md: 6 }}>
            {apiKeys.map((cardId) => (
              <Droppable droppableId={cardId} key={cardId}>
                {(provided) => (
                  <Paper
                    elevation={1}
                    sx={{
                      padding: 1,
                      minHeight: "100px",
                      borderRadius: 2,
                      marginBottom: "10px",
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "0 5px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "17px",
                          fontWeight: 600,
                          color: "#004C70",
                          mb: 1,
                        }}
                      >
                        {cardId}
                      </Typography>
                      <Input
                        sx={{ ml: 1, mb: 1 }}
                        size="lg"
                        placeholder={`  Search...`}
                        startAdornment={<i className="ri-search-line ri-lg" />}
                        value={searchTerms[cardId]}
                        onChange={(e) =>
                          setSearchTerms({
                            ...searchTerms,
                            [cardId]: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div style={{ maxHeight: "160px", overflowY: "auto", scrollbarWidth: "none" }}>
                      {getFilteredItems(cardId).map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                padding: "1px",
                                paddingLeft: "6px",
                                margin: "4px 0",
                                background: bgColors[item.method],
                                border: `1px solid ${methodColors[item.method]}`,
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                maxHeight: "35px",
                              }}
                            >
                              <div>
                                <Chip
                                  label={item.method}
                                  sx={{
                                    marginRight: 1,
                                    borderRadius: 1,
                                    width: 70,
                                    backgroundColor: methodColors[item.method],
                                    color: "white",
                                    fontWeight: "bold",
                                    maxHeight: "25px",
                                  }}
                                />
                                {item.path}
                              </div>
                              <div>
                                <Checkbox
                                  onChange={() => handleCheckboxChange(item.id)}
                                  sx={{
                                    "&.Mui-checked": {
                                      color: methodColors[item.method],
                                    },
                                  }}
                                />
                                <IconButton
                                  onClick={() => handleRemove(item.id, cardId)}
                                  size="small"
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                    {provided.placeholder}
                  </Paper>
                )}
              </Droppable>
            ))}
          </Grid>
        </Grid>
      </DragDropContext>
    </>
  );
};

export default TenantFilterConfiguration;
