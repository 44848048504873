import React, { useState, useEffect, useRef } from "react";
import { FormControl, Select, MenuItem, FormHelperText } from "@mui/material";
import { styled } from "@mui/material/styles";

// Styled components for read-only mode (span) and editable mode (Select)
const StyledSpan = styled("span")(({ theme }) => ({
  backgroundColor: "transparent",
  borderRadius: "0px",
  padding: "0px",
  paddingTop: "5px !important",
  paddingBottom: "5px !important",
  fontFamily: '"IBM Plex Mono"',
  lineHeight: "18px",
  border: "none",
  cursor: "pointer",
  fontSize: "14px",
  "@media (max-width: 1536px)": {
    fontSize: "13px",
  },
  "@media (max-width: 1200px)": {
    fontSize: "12px",
  },
}));

const ResizableEditSelectField = ({
  name,
  menuItems,
  error,
  style,
  size = "big",
  isEditable,
  errorText,
  initialValue,
  index,
  field,
  showBrackets = false,
  handleUpdate,
  isTeleconsultation = false,
  ...restProps
}) => {
  const [readOnly, setReadOnly] = useState(true);
  const [background, setBackground] = useState("transparent");
  const [value, setValue] = useState(initialValue === null ? menuItems[0].value : initialValue);
  const selectRef = useRef(null);
  const inputRef = useRef(null);
  const spanRef = useRef(null);

  useEffect(() => {
    setBackground(readOnly ? "transparent" : "#e3e3e3");
  }, [readOnly]);

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.style.width = `${value.length + 5}ch`;
    }
    if (spanRef.current) {
      spanRef.current.style.width = `${value.length + 2}ch`;
    }
  }, [value, readOnly]);

  const handleDoubleClick = () => {
    isEditable && setReadOnly(false);
  };

  useEffect(() => {
    if (!readOnly && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  }, [readOnly]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setValue(selectedValue);
    // Switch back to readOnly mode when an option is selected
    setReadOnly(true);
    handleUpdate(index, field, selectedValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setReadOnly(true);
      handleUpdate(index, field, value);
    }
  };

  return readOnly ? (
    <StyledSpan
      onDoubleClick={handleDoubleClick}
      ref={spanRef}
      style={{
        width: `${value.length + 2}ch`,
        marginLeft: "4px",
        cursor: isEditable ? "pointer" : "default",
        color: isTeleconsultation ? "#ffffff" : "#485772",
      }}
    >
      {showBrackets
        ? `(${menuItems.find((item) => item.value === value)?.menuLabel || value})`
        : `${menuItems.find((item) => item.value === value)?.menuLabel || value}`}
    </StyledSpan>
  ) : (
    <div>
      <FormControl error={error} sx={style}>
        <Select
          onClose={() => setReadOnly(true)}
          ref={selectRef}
          sx={{
            "& .MuiSelect-select": {
              paddingTop: readOnly ? "0px" : "5px",
              paddingBottom: readOnly ? "10px" : "5px",
              paddingLeft: readOnly ? "0px" : "10px",
              paddingRight: "0px !important",
              fontFamily: "IBM Plex Mono",
              fontSize: "14px",
              lineHeight: "18px",
              color: isTeleconsultation ? (readOnly ? "#ffffff" : "#485772") : "#485772",
              fontWeight: "500",
              borderRadius: "0px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiSelect-icon": {
              display: readOnly ? "none" : undefined,
            },
            background: background,
          }}
          MenuProps={{ MenuListProps: { disablePadding: true } }}
          inputProps={{
            sx: {
              background: background,
            },
            ref: inputRef,
          }}
          value={value}
          onChange={handleChange}
          onKeyDownCapture={handleKeyPress}
          onBlur={(event) => {
            setReadOnly(true);
          }}
          {...restProps}
        >
          {menuItems.map((row, index) => (
            <MenuItem name={name} key={index} value={row.value}>
              {row.menuLabel}
            </MenuItem>
          ))}
        </Select>
        {error && (
          <FormHelperText error id={`standard-weight-helper-text-${name}`}>
            {errorText}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default ResizableEditSelectField;
