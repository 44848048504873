import { useEffect, useState } from "react";
import { Card } from "@mui/material";
import PrescriptionCard from "../../Doctor/Prescription Pad/prescriptionCard";
import Reveal from "views/utilities/Reveal";

const PatientPrescriptionTeleconsultation = (PrescriptionData) => {
  const [prescription, setPrescription] = useState({
    medicationsArr: [],
    symptomsArr: [],
    diagnosisArr: [],
    labInvestigationsArr: [],
    advicesArr: [],
  });

  useEffect(() => {
    setPrescription({
      diagnosisArr: PrescriptionData.prescriptionData.diagnosisArr,
      symptomsArr: PrescriptionData.prescriptionData.symptomsArr,
      medicationsArr: PrescriptionData.prescriptionData.medicationsArr,
      labInvestigationsArr: PrescriptionData.prescriptionData.labInvestigationsArr,
      advicesArr: PrescriptionData.prescriptionData.advicesArr,
    });
    return () => {
      setPrescription({
        medicationsArr: [],
        symptomsArr: [],
        diagnosisArr: [],
        labInvestigationsArr: [],
        advicesArr: [],
      });
    };
  }, [
    setPrescription,
    PrescriptionData.prescriptionData.advicesArr,
    PrescriptionData.prescriptionData.diagnosisArr,
    PrescriptionData.prescriptionData.labInvestigationsArr,
    PrescriptionData.prescriptionData.medicationsArr,
    PrescriptionData.prescriptionData.symptomsArr,
  ]);

  return (
    <Reveal className="patient-prescription">
      <Card
        className="custom-card-ui"
        style={{
          backgroundColor: "transparent",
          padding: "24px",
          overflow: "auto",
        }}
      >
        <PrescriptionCard
          key="Cc"
          cardHeaderTitle="Cc"
          values={prescription.symptomsArr}
          isEditable={false}
          isTeleconsultation={true}
          patientView={true}
        ></PrescriptionCard>

        <PrescriptionCard
          key="Dx"
          cardHeaderTitle="Dx"
          values={prescription.diagnosisArr}
          isEditable={false}
          isTeleconsultation={true}
          patientView={true}
        />

        <PrescriptionCard
          key="Rx"
          cardHeaderTitle="Rx"
          cardContentField="Medicines"
          values={prescription.medicationsArr}
          isEditable={false}
          isTeleconsultation={true}
          patientView={true}
        ></PrescriptionCard>

        <PrescriptionCard
          key="Lab"
          cardHeaderTitle="Lab"
          values={prescription.labInvestigationsArr}
          isEditable={false}
          isTeleconsultation={true}
          patientView={true}
        ></PrescriptionCard>

        <PrescriptionCard
          key="Adv"
          cardHeaderTitle="Adv"
          values={prescription.advicesArr}
          isEditable={false}
          isTeleconsultation={true}
          patientView={true}
        ></PrescriptionCard>
      </Card>
    </Reveal>
  );
};

export default PatientPrescriptionTeleconsultation;
