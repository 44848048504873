import { IconButton, List, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import LabReportVitalCard from "./LabReportVitalCard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box } from "@mui/system";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import ModalUI from "ui-component/ModalUI";
import PatientVitalSignModal from "./PatientVitalSignModal";
import { currentActiveUser, DATE_FORMAT_DMY, DATE_TIME_FORMAT, roleName } from "store/constant";
import { useSelector } from "react-redux";
import {
  addDocumentVital,
  getAllReportDates,
  getDocumentErrors,
  getDocumentVitalsByReportDate,
  setReportDateOfDocumentVitals,
} from "store/Slices/documentVitalSlice";
import { useDispatch } from "react-redux";
import { getLabReportVitalSignTypes } from "services/EntitiesServices";
import { ToastContext } from "ui-component/custom-components/CustomToast";

const LabReportVitalContainer = ({ reportDate, documentIndex }) => {
  const { handleClick } = useContext(ToastContext);
  useEffect(() => {
    const fetchVitalSignTypes = async () => {
      try {
        const allLabReportVitals = (await getLabReportVitalSignTypes()).data;
        setVitalSignTypes(allLabReportVitals);
      } catch (error) {
        handleClick("error", "Error fetching Vital sign types");
      }
    };
    fetchVitalSignTypes();
  }, []);
  const vitals = useSelector(getDocumentVitalsByReportDate(documentIndex, reportDate));
  const errors = useSelector((state) => getDocumentErrors(state));
  const documentErrors = errors.find((error) => {
    const [errorReportDate, errorDocumentIndex, vitalIndex] = error.split("#");
    return (
      errorReportDate === reportDate.toString() && errorDocumentIndex === documentIndex.toString()
    );
  });
  const [totalVitals, setTotalVitals] = useState(vitals?.length);
  const [vitalSignTypes, setVitalSignTypes] = useState([]);
  const dispatch = useDispatch();
  const allReportDates = useSelector(getAllReportDates);
  useEffect(() => {
    setTotalVitals(vitals.length);
  }, [vitals]);

  const [unMappedVitals, setUnMappedVitals] = useState([]);
  const [readOnly, setReadOnly] = useState(true);
  const [isAddVitalModalOpen, setIsAddVitalModalOpen] = useState(false);

  useEffect(() => {
    const unMappedVitals = vitalSignTypes.filter((vitalSignType) => {
      return !vitals.map((vital) => vital?.vitalSignTypeId).includes(vitalSignType?.id);
    });
    setUnMappedVitals(unMappedVitals);
  }, [vitalSignTypes, vitals]);

  const handleDoubleClick = () => {
    setReadOnly(false);
  };

  const handleAddVitalIconClick = () => {
    setIsAddVitalModalOpen(true);
  };

  const closeAddVitalModal = () => {
    setIsAddVitalModalOpen(false);
  };

  const handleChange = (event) => {
    const selectedDate = dayjs(event.$d).format(DATE_TIME_FORMAT);
    dispatch(
      setReportDateOfDocumentVitals({
        oldReportDate: reportDate,
        newReportDate: selectedDate,
        documentIndex: documentIndex,
      })
    );
    setReadOnly(true);
  };

  const handleAddVital = (vitalSignType, readingValue) => {
    const newVital = {
      dateFrom: reportDate,
      dateTo: reportDate,
      patientId: currentActiveUser().roleBasedId,
      sourceType: roleName(),
      unit: vitalSignType?.unit,
      value: readingValue,
      vitalSignTypeId: vitalSignType?.id,
      vitalSignTypeResponse: vitalSignType,
      checked: true,
    };
    dispatch(addDocumentVital({ documentIndex, reportDate, vital: newVital }));
    setIsAddVitalModalOpen(false);
  };

  const getDisabledDate = (date) => {
    const date_in_dmy_format = dayjs(date.$d).format(DATE_FORMAT_DMY);
    return allReportDates.includes(date_in_dmy_format) && reportDate !== date_in_dmy_format;
  };

  return (
    <Box
      sx={{ pt: "1rem" }}
      children={
        <Grid sx={{ width: "100%" }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            marginRight={"1rem"}
            marginTop={"1rem"}
          >
            <Typography variant="h5" color="rgb(73, 67, 67)" display={"flex"} alignItems={"center"}>
              Report Date :
              <Box onDoubleClick={handleDoubleClick}>
                <FormDatePicker
                  disableTextField={true}
                  disabledDates={getDisabledDate}
                  disableFuture={true}
                  format={DATE_FORMAT_DMY}
                  value={dayjs(reportDate, DATE_TIME_FORMAT)}
                  size={"small"}
                  style={{
                    width: "50%",
                  }}
                  readOnly={readOnly}
                  iconAtEnd={true}
                  background={readOnly ? "transparent" : "#e3e3e3"}
                  disableBorder={true}
                  onChange={handleChange}
                ></FormDatePicker>
              </Box>
            </Typography>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
              {documentErrors && (
                <Typography variant="h6" color="#f44336" display={"flex"} alignItems={"center"}>
                  Please enter valid number
                </Typography>
              )}
              {vitalSignTypes.length !== totalVitals && (
                <IconButton aria-label="delete" size="small" onClick={handleAddVitalIconClick}>
                  <AddCircleOutlineIcon fontSize="medium" color="success" />
                </IconButton>
              )}
            </Box>
          </Box>
          <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Grid container spacing={2}>
              {Array.from({ length: totalVitals }, (element, vitalIndex) => {
                return (
                  <LabReportVitalCard
                    vitalIndex={vitalIndex}
                    documentIndex={documentIndex}
                    reportDate={reportDate}
                    // setContainerErrors={setErrors}
                    key={documentIndex.toString() + vitalIndex.toString()}
                  ></LabReportVitalCard>
                );
              })}
            </Grid>
          </List>
          <ModalUI
            visible={isAddVitalModalOpen}
            close={closeAddVitalModal}
            title={"Add Vital"}
            component={
              <PatientVitalSignModal
                initialValue={{ dateFrom: reportDate }}
                vitalSignTypes={unMappedVitals}
                dateAdministeredReadOnly={true}
                successButtonLabel={"Add"}
                successButtonOnClick={handleAddVital}
                close={closeAddVitalModal}
              />
            }
          />
        </Grid>
      }
    />
  );
};

export default LabReportVitalContainer;
