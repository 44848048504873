import { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Menu, MenuList } from "@mui/material";
import NoDataFoundMessageInTable from "../NoDataFoundMessageInTable";
import CustomButton from "../CustomButton";
import CustomMenuItem from "../CustomMenuItem";
import {
  TablePagination,
  tablePaginationClasses as paginationClasses,
} from "@mui/base/TablePagination";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import PropTypes from "prop-types";
import { isNumber } from "lodash";

const CustomTableNew = ({ columns, tableData, actions, paginationProps }) => {
  const { rowsPerPage = 10, rowsPerPageOptions = [] } = paginationProps || {};

  // handling menu options open on right click, and more icon click
  const [clickedRow, setClickedRow] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);

  const handleMoreActionClick = (event, row) => {
    event.preventDefault();
    handleCloseContextMenu();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
    setClickedRow(row);
  };

  const handleContextMenu = (event, row) => {
    event.preventDefault();
    handleCloseContextMenu();
    if (clickedRow) {
      setClickedRow(null);
      return;
    }
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
    setClickedRow(row);
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
    setClickedRow(null);
  };

  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPageFinal, setRowsPerPageFinal] = useState(
    rowsPerPageOptions.length > 0 && isNumber(rowsPerPageOptions[0])
      ? parseInt(rowsPerPageOptions[0])
      : rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPageFinal(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData =
    paginationProps && rowsPerPageFinal !== -1
      ? tableData.slice(page * rowsPerPageFinal, page * rowsPerPageFinal + rowsPerPageFinal)
      : tableData;

  // get value if nested field as string
  const getValueByField = (data, field) => {
    return field
      .split(".")
      .reduce((obj, key) => (obj && obj[key] !== undefined ? obj[key] : null), data);
  };

  return (
    <TableContainer className="common-scrollbar-x">
      <Table sx={{ minWidth: 700, overflow: "hidden" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <StyledTableCell
                key={index}
                align={column?.align}
                sx={{ width: column.width || "auto" }}
              >
                {column.label}
              </StyledTableCell>
            ))}
            <StyledTableCell align="right" key={columns.length}></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((row, rowIndex) => (
            <StyledTableRow
              key={rowIndex}
              sx={{
                transition: "all 0.15s ease",
                "&:hover": {
                  backgroundColor: "#E6EEF1",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
                  zIndex: 1,
                  position: "relative",
                  scale: 1.02,
                },
                ...(clickedRow === row && {
                  backgroundColor: "#E6EEF1",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
                  zIndex: 1,
                  position: "relative",
                  scale: 1.02,
                }),
              }}
              onContextMenu={(event) => handleContextMenu(event, row)}
            >
              {columns.map((column, colIndex) => (
                <StyledTableCell
                  key={colIndex}
                  align={column?.align}
                  sx={{ py: 1, width: column.width || "auto" }}
                >
                  {column.isSerial
                    ? page * rowsPerPageFinal + rowIndex + 1
                    : column.chip
                    ? column.chip(getValueByField(row, column?.field))
                    : getValueByField(row, column?.field)}
                </StyledTableCell>
              ))}
              {actions && (
                <StyledTableCell align="center" key={columns.length}>
                  <div style={{ width: "60px" }}>
                    <CustomButton
                      iconButton={<i className="ri-more-2-fill" />}
                      onClick={(event) => handleMoreActionClick(event, row)}
                      style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                      aria-label="Actions"
                    />
                    <Menu
                      elevation={1}
                      open={contextMenu !== null}
                      onClose={handleCloseContextMenu}
                      anchorReference="anchorPosition"
                      anchorPosition={
                        contextMenu !== null
                          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                          : undefined
                      }
                      slotProps={{
                        paper: {
                          style: {
                            borderRadius: "4px",
                          },
                        },
                      }}
                    >
                      <MenuList dense sx={{ p: 0 }}>
                        {actions
                          .filter((action) => {
                            if (typeof action.checkVisible === "function") {
                              return action.checkVisible(clickedRow) !== false;
                            }
                            return true;
                          })
                          .map((action, actionIndex) => {
                            return (
                              <CustomMenuItem
                                disabled={
                                  action?.checkDisable
                                    ? action?.checkDisable(clickedRow)
                                    : undefined
                                }
                                key={actionIndex}
                                text={
                                  typeof action.label === "function"
                                    ? action.label(row)
                                    : action.label
                                }
                                icon={action.icon}
                                onClick={() => {
                                  action.onClick(clickedRow);
                                  handleCloseContextMenu();
                                }}
                              />
                            );
                          })}
                      </MenuList>
                    </Menu>
                  </div>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
          {tableData && !tableData.length && (
            <NoDataFoundMessageInTable colSpan={7} backgroundColor="#fff" />
          )}
        </TableBody>
        {paginationProps && (
          <CustomTablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            colSpan={12}
            count={tableData.length}
            rowsPerPage={rowsPerPageFinal}
            page={page}
            slotProps={{
              select: {
                "aria-label": "Rows per page",
              },
              actions: {
                slots: {
                  nextPageIcon: ChevronRightRoundedIcon,
                  backPageIcon: ChevronLeftRoundedIcon,
                },
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></CustomTablePagination>
        )}
      </Table>
    </TableContainer>
  );
};

CustomTableNew.propTypes = {
  tableData: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  actions: PropTypes.array,
  paginationProps: PropTypes.shape({
    rowsPerPage: PropTypes.number,
    // example : [5, 10, 25, { label: 'All', value: -1 }]
    rowsPerPageOptions: PropTypes.array,
  }),
};

/** Table styling using its classes */

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E6EEF1",
    color: "#004c70",
    fontWeight: "bold",
    padding: "5px 0px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "5px 0px",
    lineHeight: "28px",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: "white",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const CustomTablePagination = styled(TablePagination)(
  () => `
  & .${paginationClasses.spacer} {
    display: none;
  }

  & .${paginationClasses.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding:10px 25px 10px;
    background-color: #fff;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${paginationClasses.selectLabel} {
    margin: 0;
    font-size:14px;
    color:#9E9E9E;
  }

  & .${paginationClasses.select}{
    font-family: 'Poppins', sans-serif;
    padding: 4px 0 4px 4px;
    border-radius: 6px; 
    background-color: #E6EEF1;
    color: #9E9E9E;
    border:none;
    font-size:14px;

    &:focus { 
      border:none;
      outline:none;
    background-color: #E6EEF1;

    }
  }

  & .${paginationClasses.displayedRows} {
    margin: 0;
    color:#9E9E9E;
    font-weight:500;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${paginationClasses.actions} {
    display: flex;
    gap: 6px;
    border: transparent;
    text-align: center;
  }

  & .${paginationClasses.actions} > button {
    display: flex;
    align-items: center;
    padding: 0;
    border: transparent;
    border-radius: 50%; 
    background-color: transparent;
    border: none;
    color: #004C70;
    transition: all 100ms ease;

    > svg {
      font-size: 28px;
    }

    &:hover {
      background-color: #E6EEF1;
    }


    &:disabled {
      opacity: 0.3;
      &:hover {
        background-color: transparent;
      }
    }
  }
  `
);

export default CustomTableNew;
