import { createSlice } from "@reduxjs/toolkit";
import config from "config";

const initialState = {
  isOpen: [], // for active default menu
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
  accessToken: "",
};

const customizationSlice = createSlice({
  name: "customization",
  initialState,
  reducers: {
    menuOpen(state, action) {
      state.isOpen = [action.payload];
    },
    setMenu(state, action) {
      state.opened = action.payload;
    },
    setFontFamily(state, action) {
      state.fontFamily = action.payload;
    },
    setBorderRadius(state, action) {
      state.borderRadius = action.payload;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
  },
});

export const { menuOpen, setMenu, setFontFamily, setBorderRadius, setAccessToken } =
  customizationSlice.actions;
export const SET_MENU = "@customization/SET_MENU";
export const MENU_TOGGLE = "@customization/MENU_TOGGLE";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";
export const SET_ACCESS_TOKEN = "@customization/SET_ACCESS_TOKEN";

export const customizationReducer = customizationSlice.reducer;
