import Grid from "@mui/material/Grid2";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useContext, useEffect, useState } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import LabReportDocumentContainer from "./LabReportDocumentContainer";
import { useSelector } from "react-redux";
import { getDocumentErrors, getDocumentVitals } from "store/Slices/documentVitalSlice";
import { createMultiplePatientVital, updatePatientDocument } from "services/patientService";

const LabReportDocumentModal = ({ closeModal }) => {
  const { handleClick } = useContext(ToastContext);
  const allDocuments = useSelector(getDocumentVitals);
  const [documentIndexesContainingVitals, setDocumentIndexesContainingVitals] = useState([]);
  useEffect(() => {
    const documentIndexContainingVitals = [];
    allDocuments.documents.forEach((document, index) => {
      if (document?.patientVitalResponses?.length) {
        documentIndexContainingVitals.push(index);
      }
    });
    setDocumentIndexesContainingVitals(documentIndexContainingVitals);
  }, [allDocuments]);
  const errors = useSelector(getDocumentErrors);

  const handleSaveVitals = async () => {
    try {
      const checkedVitals = [];
      const updatePatientDocumentPromises = [];
      allDocuments.documents.forEach((document) => {
        let vitalsExtractedForCurrentDocument = false;
        document.patientVitalResponses.forEach((vital) => {
          const checkedVitalsForReportDate = [
            ...vital.vitals.filter((vitalInfo) => vitalInfo.checked),
          ];
          if (checkedVitalsForReportDate.length > 0) {
            checkedVitals.push(...checkedVitalsForReportDate);
            vitalsExtractedForCurrentDocument = true;
          }
        });
        if (vitalsExtractedForCurrentDocument) {
          updatePatientDocumentPromises.push(
            updatePatientDocument(
              document.documentId,
              {
                areVitalsExtracted: true,
              },
              false
            )
          );
        }
      });
      await createMultiplePatientVital(checkedVitals);
      handleClick("success", "Patient Vital Details saved Successfully");
    } catch (error) {
      handleClick("error", "Error saving Patient Vitals");
    } finally {
      closeModal();
    }
  };

  return (
    <Grid sx={{ padding: "1rem", width: "100%", height: "70vh", marginTop: "3rem" }}>
      {documentIndexesContainingVitals.map((documentIndex, index) => {
        return (
          <LabReportDocumentContainer
            key={index}
            documentIndex={documentIndex}
          ></LabReportDocumentContainer>
        );
      })}
      <Grid
        className="utility-buttons"
        display={"flex"}
        justifyContent={"flex-end"}
        gap={2}
        paddingBottom={"2rem"}
      >
        <CustomButton
          className={"btn--error"}
          label={"Cancel"}
          onClick={() => {
            closeModal();
          }}
        />
        <CustomButton
          className={"btn--secondary"}
          label={"Save"}
          onClick={() => {
            handleSaveVitals();
          }}
          disabled={errors.length}
        />
      </Grid>
    </Grid>
  );
};

export default LabReportDocumentModal;
