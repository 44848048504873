import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSyncModal: false,
  isSyncing: false,
  syncStatus: "",
  lastSyncInfo: {
    push: "loading",
    pull: "loading",
  },
  isOnline: false,
};

const cloudSyncSlice = createSlice({
  name: "cloudSync",
  initialState,
  reducers: {
    setIsSyncing(state, action) {
      state.isSyncing = action.payload;
    },
    setShowSyncModal(state, action) {
      state.showSyncModal = action.payload;
    },
    setSyncStatus(state, action) {
      state.syncStatus = action.payload;
    },
    setLastSyncInfo(state, action) {
      state.lastSyncInfo = action.payload;
    },
    setIsOnline(state, action) {
      state.isOnline = action.payload;
    },
  },
});

export const { setIsSyncing, setShowSyncModal, setSyncStatus, setLastSyncInfo, setIsOnline } =
  cloudSyncSlice.actions;

export const getIsSyncing = (state) => state.cloudSync.isSyncing;
export const getIsSyncModalOpen = (state) => state.cloudSync.showSyncModal;
export const getSyncStatus = (state) => state.cloudSync.syncStatus;
export const getLastSyncInfo = (state) => state.cloudSync.lastSyncInfo;
export const getIsOnline = (state) => state.cloudSync.isOnline;

export const cloudSyncReducer = cloudSyncSlice.reducer;
