import dayjs from "dayjs";
import { startsAndEndsWithAlphabetic } from "store/constant";
import * as Yup from "yup";

export const doctorGeneralInfoSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Please enter your full name.")
    .min(3, "Minimum 3 characters are required")
    .max(100, "Maximum 50 characters are allowed")
    .test(
      "contains-alphabetic",
      "Name must start and end with alphabet",
      startsAndEndsWithAlphabetic
    )
    .matches(/^[a-zA-Z\s]*$/, "Name should only contain letters and space"),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Please enter a valid Mobile Number")
    .required("Please enter a valid mobile number."),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid email.")
    .required("Please enter a valid email.")
    .max(255, "Maximum 255 characters are allowed"),
  lanValue: Yup.array().min(1, "Please select at least one language"),
  gender: Yup.string().required("Kindly select a gender"),
  avgAppointmentDuration: Yup.number()
    .min(2, "Avg. Appointment Duration must be at least 2 minutes long.")
    .max(60, "Avg. Appointment Duration must be less than 60 minutes.")
    .required("Please provide avg. appointment duration."),
  specializationId: Yup.string().required("Please select a specialization."),
  biography: Yup.string().max(1000, "Biography cannot exceed 1000 characters."),
  experience: Yup.string()
    .required("Please provide your experience.")
    .test("is-valid-experience", "Experience cannot be greater than age", function (value) {
      const { dateOfBirth } = this.parent;
      if (dateOfBirth) {
        const age = dayjs().diff(dayjs(dateOfBirth), "year");
        if (Number(value) > age) {
          return false;
        }
      }
      return true;
    }),
  facebookLink: Yup.string().url("Please provide a valid URL.").max(255, "URL is too long"),
  twitterLink: Yup.string().url("Please provide a valid URL.").max(255, "URL is too long"),
  linkedinLink: Yup.string().url("Please provide a valid URL.").max(255, "URL is too long"),
  instagramLink: Yup.string().url("Please provide a valid URL.").max(255, "URL is too long"),
});
