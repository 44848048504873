import { useContext } from "react";
import { createContext } from "react";

export const SidebarUtilContext = createContext(null);

export const useMainClass = () => useContext(SidebarUtilContext);

export const SidebarUtilProvider = ({
  children,
  mainClass,
  toggleMainClass,
  setSecondarySideBarData,
  tabValue,
  setAppointmentInfo,
  appointmentInfo,
  setSidebarList,
}) => {
  return (
    <SidebarUtilContext.Provider
      value={{
        tabValue,
        mainClass,
        toggleMainClass,
        setSecondarySideBarData,
        setAppointmentInfo,
        appointmentInfo,
        setSidebarList,
      }}
    >
      {children}
    </SidebarUtilContext.Provider>
  );
};
