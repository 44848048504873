import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { ACTIVE_STATE, SKELETON_LOADING_TIME_IN_MILLISECONDS } from "store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { deleteRole, getAllRoles } from "services/roleService";
import { accessToken } from "store/constant";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";
import { Box, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";

const Roles = () => {
  const navigate = useNavigate();

  // state to hold the users data
  const [roles, setRoles] = useState([]);

  // toast related constants
  const { handleClick } = useContext(ToastContext);
  const accessTokenValue = accessToken();

  const [errors, setErrors] = useState({});

  // fetch the roles data from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllRoles();
        setRoles(response.data);
      } catch (error) {
        console.error("Error fetching roles");
      }
    };
    fetchData();
  }, [accessTokenValue]);

  const handleUpdate = (role) => {
    if (!role || !role.id) {
      const Errors = { role: "Invalid role selected" };
      setErrors(Errors);
      return;
    }
    // code to handle update functionality
    navigate("/home/manageRole", { state: { roleId: role.id } });
  };

  const handleMarkInactive = async (role) => {
    if (!role || !role.id) {
      const Errors = { role: "Invalid role selected" };
      setErrors(Errors);
      return;
    }
    if (role.status !== ACTIVE_STATE) {
      const Errors = { role: "Role is already inactive!" };
      setErrors(Errors);
      return;
    }
    if (role.status === ACTIVE_STATE) {
      try {
        await deleteRole(role.id);
        handleClick("success", "Role has been marked Inactive!");
        const index = roles.findIndex((el) => el.id === role.id);
        roles.splice(index, 1);
      } catch (error) {
        handleClick("error", "There seems to be an error marking the Role as Inactive.");
      }
    } else {
      handleClick("info", "Role is already inactive!");
    }
  };

  const navigateToManagaRole = () => {
    navigate("/home/manageRole", { state: { roleId: null } });
  };

  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "name", label: "Role Name" },
    { field: "description", label: "Role description" },
    { field: "status", label: "Status" },
    { field: "Actions", label: "Actions" },
  ];

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: handleUpdate,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleMarkInactive,
    },
  ];

  return (
    <RoleSkeleton>
      <Reveal>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "7px" }}>
          <h2 style={{ display: "inline" }}>Roles</h2>
          <CustomButton
            className="ri-add-fill ri-lg btn--primary"
            style={{ marginLeft: "auto" }}
            onClick={() => navigateToManagaRole()}
            label="Add Role"
          />
        </div>

        {roles.length === 0 ? (
          <div>No roles available</div>
        ) : (
          <CustomizedTable columns={columns} tableData={roles} actions={actions} />
        )}
      </Reveal>
    </RoleSkeleton>
  );
};

export const RoleSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 5 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
          <Grid size={{ xs: 1.5 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Roles;
