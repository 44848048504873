import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { appEnv, socketBaseUrl } from "store/constant";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../../../../assets/scss/Room.scss";
import { useMainClass } from "layout/SidebarUtilContext";
import ReactPlayer from "react-player";
import CustomButton from "ui-component/custom-components/CustomButton";
import { CallEnd, Mic, MicOff, Videocam, VideocamOff } from "@mui/icons-material";
import { useCallback } from "react";
import PatientPrescription from "./PatientPrescriptionTeleconsultation";
import { getPrescriptionPadData } from "store/Slices/prescriptionPadSlice";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { convertPrescriptionPadDataToApiFormat } from "utils/prescriptionPadUtils/convert-to-API-format";
import { convertPrescriptionDataToUIFormat } from "utils/prescriptionPadUtils/convert-to-UI-format";

const RoomPage = ({ isDoctor = false, appointment, prescriptionId }) => {
  const navigate = useNavigate();
  const { handleClick } = useContext(ToastContext);
  const webSocketRef = useRef(null);
  const appointmentId = useParams().appointmentId;
  const room = useParams().roomId ?? appointmentId;
  const { state } = useLocation();
  const patientAppointment = state?.patientAppointment ?? null;
  const [localVideo, setLocalVideo] = useState(null);
  const localVideoRef = useRef(null);
  const localVideoKeyRef = useRef(1);
  const [remoteVideo, setRemoteVideo] = useState(null);
  const remoteVideoKeyRef = useRef(1);
  const [pauseRemoteVideo, setPauseRemoteVideo] = useState(false);
  const [peerLeft, setPeerLeft] = useState(false);
  const [peerName, setPeerName] = useState("");
  const [callStarted, setCallStarted] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [showPrescriptionPad, setShowPrescriptionPad] = useState(false);
  const showPrescriptionPadKeyRef = useRef(true);
  const [hideSharedPrescription, setHideSharedPrescription] = useState(false);
  const [prescriptionData, setPrescriptionData] = useState(null);
  const [hideShareButton, setHideShareButton] = useState(false);
  const currentPrescription = useSelector(getPrescriptionPadData);
  const audioPermissionRef = useRef(true);
  const videoPermissionRef = useRef(true);
  const { toggleMainClass } = useMainClass();
  const peer = useRef(null);

  const allTrackOff = async () => {
    try {
      if (localVideo) {
        const tracks = localVideo.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const replaceRemoteTrack = async (stream) => {
    try {
      const senders = peer.current.getSenders();
      if (stream) {
        stream.getTracks().forEach((newTrack) => {
          const sender = senders.find((s) => s.track.kind === newTrack.kind);
          if (sender) {
            sender.replaceTrack(newTrack);
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getLocalStream = useCallback(
    async (enableVideo, enableAudio) => {
      try {
        if (!enableVideo && !enableAudio) {
          return;
        } else {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({
              audio: enableAudio
                ? {
                    echoCancellation: true,
                    noiseSuppression: true,
                    autoGainControl: true,
                  }
                : false,
              video: enableVideo,
            });
            setLocalVideo(stream);
            localVideoRef.current = stream;

            stream.getTracks().forEach((track) => {
              peer.current.addTrack(track, stream);
            });

            audioPermissionRef.current = true;
            videoPermissionRef.current = true;

            return stream;
          } catch (err) {
            if (err.name === "NotAllowedError") {
              const audioPermission = enableAudio
                ? await navigator.permissions.query({ name: "microphone" })
                : { state: "granted" };

              const videoPermission = enableVideo
                ? await navigator.permissions.query({ name: "camera" })
                : { state: "granted" };

              if (audioPermission.state !== "granted" && videoPermission.state !== "granted") {
                setAudioEnabled(false);
                audioPermissionRef.current = false;
                setVideoEnabled(false);
                videoPermissionRef.current = false;
                handleClick(
                  "error",
                  "Camera and microphone permissions are denied. Please enable them in your browser settings and refresh the page."
                );
              } else if (audioPermission.state !== "granted") {
                if (
                  videoEnabled &&
                  videoPermission.state === "granted" &&
                  videoPermissionRef.current
                ) {
                  await getLocalStream(true, false);
                }
                setAudioEnabled(false);
                audioPermissionRef.current = false;
                handleClick(
                  "error",
                  "Microphone permission is denied. Please enable it in your browser settings and refresh the page."
                );
              } else if (videoPermission.state !== "granted") {
                if (
                  audioEnabled &&
                  audioPermission.state === "granted" &&
                  audioPermissionRef.current
                ) {
                  await getLocalStream(false, true);
                }
                setVideoEnabled(false);
                videoPermissionRef.current = false;
                handleClick(
                  "error",
                  "Camera permission is denied. Please enable it in your browser settings and refresh the page."
                );
              }
            } else if (err.name === "NotFoundError") {
              handleClick(
                "error",
                "No camera or microphone found. Please connect them and try again."
              );
            } else {
              handleClick("error", "An unexpected error occurred. Please try again.");
            }
          }
        }
        ++localVideoKeyRef.current;
        sendMessage({ type: "resumeRemoteVideo" });
      } catch (e) {
        console.error(e);
      }
    },
    [handleClick]
  );

  const handleShowPrescription = useCallback((prescriptionData) => {
    try {
      setPrescriptionData(prescriptionData);
      showPrescriptionPadKeyRef.current = !showPrescriptionPadKeyRef.current;
    } catch (e) {
      console.error(e);
    }
  }, []);

  const startWebRTC = async () => {
    try {
      const videoChatUrl =
        appEnv === "electron" ? "wss://hc.argusservices.in/api/videochat" : socketBaseUrl;
      webSocketRef.current = new WebSocket(`${videoChatUrl}/${room}`);

      webSocketRef.current.onopen = async (socket, event) => {
        await getLocalStream(true, true);

        webSocketRef.current.onmessage = async (message) => {
          try {
            const data = JSON.parse(message.data);
            switch (data.type) {
              case "joined":
                const offer = await peer.current.createOffer();
                await peer.current.setLocalDescription(offer);
                await webSocketRef.current.send(JSON.stringify(peer.current.localDescription));
                break;

              case "offer":
                await peer.current.setRemoteDescription(new RTCSessionDescription(data));
                const answer = await peer.current.createAnswer();
                await peer.current.setLocalDescription(answer);
                webSocketRef.current.send(JSON.stringify(peer.current.localDescription));
                break;

              case "answer":
                await peer.current.setRemoteDescription(new RTCSessionDescription(data));
                setPauseRemoteVideo(false);
                ++remoteVideoKeyRef.current;
                break;

              case "candidate":
                if (data.candidate) {
                  await peer.current.addIceCandidate(new RTCIceCandidate(data.candidate));
                } else {
                  console.warn("No candidate in message");
                }
                break;

              case "prescriptionData":
                const prescriptionData = JSON.parse(message.data);
                const uiData = await convertPrescriptionDataToUIFormat(prescriptionData.data);
                if (uiData) {
                  handleShowPrescription(uiData.data);
                  setShowPrescriptionPad(true);
                }
                break;

              case "hidePrescriptionData":
                setShowPrescriptionPad(false);
                break;

              case "pauseRemoteVideo":
                setPauseRemoteVideo(true);
                break;

              case "resumeRemoteVideo":
                setPauseRemoteVideo(false);
                ++remoteVideoKeyRef.current;
                break;

              case "leave":
                setPeerLeft(true);
                break;

              default:
                console.warn("Unknown message type:", data.type);
                break;
            }
          } catch (e) {
            console.error("Error in WebSocket message handling:", e);
          }
        };

        sendMessage({ type: "joined" });
      };

      peer.current = new RTCPeerConnection({
        iceServers: [
          { urls: "stun:stun.l.google.com:19302" },
          { urls: "stun:stun.l.google.com:5349" },
          { urls: "stun:stun1.l.google.com:3478" },
          { urls: "stun:stun1.l.google.com:5349" },
          { urls: "stun:stun2.l.google.com:19302" },
          { urls: "stun:stun2.l.google.com:5349" },
          { urls: "stun:stun3.l.google.com:3478" },
          { urls: "stun:stun3.l.google.com:5349" },
          { urls: "stun:stun4.l.google.com:19302" },
          { urls: "stun:stun4.l.google.com:5349" },
          { urls: ["stun:bn-turn1.xirsys.com"] },
          {
            urls: "turn:turn.anyfirewall.com:443?transport=tcp",
            username: "webrtc",
            credential: "webrtc",
          },
          {
            urls: "turn:relay1.expressturn.com:3478",
            username: "efRZX7VVZB6250T4HF",
            credential: "UFiJt9Y2Rctg18RL",
          },
          {
            urls: "turn:relay1.expressturn.com:3478",
            username: "ef59NGX88DYZX3JU68",
            credential: "Y9JL6obz547h3xa4",
          },
          {
            username:
              "0I4QrZu3s-C3mU-256EVtbvg9AnsaItARBnXpye1msFXoJ42E8GXgG1pDeGgAHGBAAAAAGZz2bRTaG9iaGE=",
            credential: "721cfd32-2ed6-11ef-8b34-0242ac140004",
            urls: [
              "turn:bn-turn1.xirsys.com:80?transport=udp",
              "turn:bn-turn1.xirsys.com:3478?transport=udp",
              "turn:bn-turn1.xirsys.com:80?transport=tcp",
              "turn:bn-turn1.xirsys.com:3478?transport=tcp",
              "turns:bn-turn1.xirsys.com:443?transport=tcp",
              "turns:bn-turn1.xirsys.com:5349?transport=tcp",
            ],
          },
        ],
        iceTransportPolicy: "all",
      });

      peer.current.ontrack = (event) => {
        setRemoteVideo(event.streams[0]);
        setCallStarted(true);
        sendMessage({ type: "resumeRemoteVideo" });
      };

      peer.current.onicecandidate = (event) => {
        if (event.candidate) {
          webSocketRef.current.send(
            JSON.stringify({ type: "candidate", candidate: event.candidate })
          );
        }
      };
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    try {
      startWebRTC();
      toggleMainClass(() => "main shrink");
      if (isDoctor) {
        setPeerName(appointment?.patient?.userResponse?.name);
      } else {
        setPeerName(`Dr. ${patientAppointment?.doctor?.userResponse?.name}`);
      }

      return () => {
        try {
          if (localVideoRef.current) {
            const tracks = localVideoRef.current.getTracks();
            tracks.forEach((track) => {
              track.stop();
            });
          }
        } catch (e) {
          console.error(e);
        }
        if (peer.current) {
          peer.current.close();
        }
        if (webSocketRef.current) {
          webSocketRef.current.close();
        }
        toggleMainClass("main");
        allTrackOff();
      };
    } catch (e) {
      console.error(e);
    }
  }, [handleShowPrescription, room, toggleMainClass]);

  const sendMessage = (message) => {
    try {
      if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
        webSocketRef.current.send(JSON.stringify(message));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleIceCandidateEvent = useCallback((event) => {
    try {
      if (event.candidate) {
        sendMessage({ type: "ice-candidate", candidate: event.candidate });
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    peer.onicecandidate = handleIceCandidateEvent;
  }, [handleIceCandidateEvent]);

  const toggleAudio = async () => {
    try {
      await allTrackOff();
      const stream = await getLocalStream(videoEnabled, !audioEnabled);
      await replaceRemoteTrack(stream);
      if (audioPermissionRef.current) {
        setAudioEnabled(!audioEnabled);
      }
      ++localVideoKeyRef.current;
    } catch (e) {
      console.error(e);
    }
  };

  const toggleVideo = async () => {
    try {
      sendMessage({
        type: videoEnabled ? "pauseRemoteVideo" : "resumeRemoteVideo",
      });
      await allTrackOff();
      const stream = await getLocalStream(!videoEnabled, audioEnabled);
      await replaceRemoteTrack(stream);
      if (videoPermissionRef.current) {
        setVideoEnabled(!videoEnabled);
      }
      ++localVideoKeyRef.current;
    } catch (e) {
      console.error(e);
    }
  };

  const toHome = async () => {
    try {
      sendMessage({ type: "leave" });
      await allTrackOff();
      navigate(`/home/dashboard`);
    } catch (e) {
      console.error(e);
    }
  };

  const sharePrescription = useCallback(async () => {
    try {
      const data = await convertPrescriptionPadDataToApiFormat(
        currentPrescription,
        appointment,
        prescriptionId
      );
      sendMessage({ type: "prescriptionData", data: data });
      setHideSharedPrescription(true);
    } catch (e) {
      console.error(e);
    }
  }, [currentPrescription]);

  useEffect(() => {
    if (hideSharedPrescription) {
      sharePrescription();
    }
  }, [currentPrescription, hideSharedPrescription, sharePrescription]);

  const hidePrescription = () => {
    sendMessage({ type: "hidePrescriptionData" });
    setHideSharedPrescription(false);
  };

  useEffect(() => {
    const hasData =
      currentPrescription?.diagnosisArr?.length ||
      currentPrescription?.advicesArr?.length ||
      currentPrescription?.symptomsArr?.length ||
      currentPrescription?.medicationsArr?.length ||
      currentPrescription?.labInvestigationsArr?.length;

    setHideShareButton(!hasData);
  }, [currentPrescription]);

  useEffect(() => {
    const hasData =
      prescriptionData?.diagnosisArr?.length ||
      prescriptionData?.advicesArr?.length ||
      prescriptionData?.symptomsArr?.length ||
      prescriptionData?.medicationsArr?.length ||
      prescriptionData?.labInvestigationsArr?.length;

    setShowPrescriptionPad(hasData);
  }, [prescriptionData]);

  return (
    <div className="room">
      <div className={isDoctor ? "video-container-doctor" : "video-container-patient"}>
        {!callStarted && (
          <div
            className="additional-data"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span className="additional-text">
              <div className="centered-text">
                {isDoctor && <h3 className="my-0">Waiting for Patient to Join...</h3>}
                {!isDoctor && <h3 className="my-0">Waiting for Doctor to Join...</h3>}
              </div>
            </span>
          </div>
        )}
        <div className="videos" style={{ height: "100%" }}>
          {showPrescriptionPad && (
            <div className="patient-prescription-container" key={showPrescriptionPadKeyRef.current}>
              <div className="patient-prescription-header">
                <h2 style={{ margin: "auto", marginTop: "5%", width: "50%" }}>Your Prescription</h2>
              </div>
              <div className="patient-prescription-content">
                <PatientPrescription prescriptionData={prescriptionData} />
              </div>
            </div>
          )}

          {callStarted && (
            <ReactPlayer
              url={remoteVideo}
              playing
              key={remoteVideoKeyRef.current}
              className={isDoctor ? "remote-stream-doctor" : "remote-stream-patient"}
            ></ReactPlayer>
          )}
          {pauseRemoteVideo && <div className="red-cover">{peerName} has paused their Video !</div>}
          {peerLeft && <div className="red-cover">{peerName} has left the Call !</div>}
          <div
            className={
              isDoctor
                ? callStarted
                  ? "my-video-doctor"
                  : "my-video-doctor-call-not-started"
                : callStarted
                ? "my-video-patient"
                : "my-video-patient-call-not-started"
            }
          >
            <ReactPlayer
              className={"my-stream"}
              url={localVideo}
              playing
              muted
              key={localVideoKeyRef.current}
              style={{ height: "100%" }}
            ></ReactPlayer>
          </div>
        </div>
        <div className={callStarted ? "controls-call-started" : "controls-call-not-started"}>
          <CustomButton className="control-button" onClick={toggleAudio}>
            {audioEnabled ? <Mic /> : <MicOff />}
          </CustomButton>
          <CustomButton className="control-button" onClick={toggleVideo}>
            {videoEnabled ? <Videocam /> : <VideocamOff />}
          </CustomButton>
          {isDoctor && !hideSharedPrescription && (
            <CustomButton
              id="prescription-share"
              disabled={hideShareButton}
              className="control-button"
              style={{ fontSize: "9px", display: "flex", width: "10px" }}
              onClick={sharePrescription}
              isTeleConsultation={true}
            >
              Share Prescription
            </CustomButton>
          )}
          {isDoctor && hideSharedPrescription && (
            <CustomButton
              className="control-button"
              style={{ fontSize: "9px", display: "flex", width: "10px" }}
              onClick={hidePrescription}
              isTeleConsultation={true}
            >
              Hide Prescription
            </CustomButton>
          )}
          <CustomButton className="control-button end-call" onClick={toHome}>
            <CallEnd />
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
export default RoomPage;
