import { api as API } from "./AxiosInterceptor";
import { getUUID } from "../store/constant";

// billing invoices apis
export const getBillingInvoiceById = (invoiceId) => API.get(`/api/billing/invoices/${invoiceId}`);
export const getBillingInvoiceByTypeId = (typeId) =>
  API.get(`/api/billing/invoices/type/${typeId}`);
export const generateBillingInvoices = (data) =>
  API.post(`/api/billing/invoices`, data, { showLoader: false });
export const updateBillingInvoices = (id, data) =>
  API.put(`/api/billing/invoices/${id}`, data, { showLoader: false });

// medical service categories Apis
export const getMedicalServiceCategories = () => API.get("/api/medical-service-categories");
export const createMedicalServiceCategories = (data) => {
  data["id"] = getUUID();
  return API.post("/api/medical-service-categories", data);
};
export const updateMedicalServiceCategories = (id, data) =>
  API.put(`/api/medical-service-categories/${id}`, data);
export const deleteMedicalServiceCategories = (id) =>
  API.delete(`/api/medical-service-categories/${id}`);

// tax rate apis
export const getTaxRates = () => API.get("/api/billing/tax-rates");
export const createTaxRates = (data) => {
  data["id"] = getUUID();
  return API.post("/api/billing/tax-rates", data);
};
export const updateTaxRates = (id, data) => API.put(`/api/billing/tax-rates/${id}`, data);
export const deleteTaxRates = (id) => API.delete(`/api/billing/tax-rates/${id}`);

// medical service Apis
export const getMedicalServices = () => API.get(`/api/medical-services`);
export const getMedicalServiceById = (id) => API.get(`/api/medical-services/${id}`);
export const createMedicalServices = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/medical-services`, data);
};
export const updateMedicalServices = (id, data) => API.put(`/api/medical-services/${id}`, data);
export const deleteMedicalServices = (id) => API.delete(`/api/medical-services/${id}`);

// invoice pdf

export const getInvoicePDFById = (invoiceId, typeId, doSendNotification = false) =>
  API.get(`/api/billing/invoices/generate-pdf`, {
    responseType: "blob",
    params: { invoiceId, typeId, doSendNotification },
  });

// medical services by owner-type
export const getServiceConfiguration = (organizationId, doctorId, ownerType) =>
  API.get(`/api/medical-services/owner-type`, {
    params: { organizationId, doctorId, ownerType },
  });

// discount services
export const getAllDiscount = () => API.get(`api/billing/discounts`);
export const getDiscountById = (id) => API.get(`api/billing/discounts/${id}`);
export const getDiscountByServiceId = (id) => API.get(`api/billing/discounts/${id}`);
export const addDiscount = (data) => API.post(`api/billing/discounts`, data);
export const updateDiscount = (id, data) => API.put(`api/billing/discounts/${id}`, data);
export const deleteDiscount = (id) => API.delete(`api/billing/discounts/${id}`);

// invoices for subscriotion (super admin feature)
export const generateBillingInvoicesByAdmin = (data) =>
  API.post(`/api/billing-admin/invoices`, data, { showLoader: false });

export const getInvoicePDFByIdByAdmin = (invoiceId, typeId, doSendNotification = false) =>
  API.get(`/api/billing-admin/invoices/generate-pdf`, {
    responseType: "blob",
    params: { invoiceId, typeId, doSendNotification },
  });

// billing order
export const createOrderForPayment = (body) => API.post(`/api/billing/payments`, body);
export const paymentVerification = (params, body) =>
  API.post(`/api/billing/payments/verify`, body, { params });

// billing order for super admin
export const createOrderForPaymentByAdmin = (body) => API.post(`/api/billing-admin/payments`, body);
export const paymentVerificationByAdmin = (params, body) =>
  API.post(`/api/billing-admin/payments/verify`, body, { params });
