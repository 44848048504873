import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { FormHelperText, InputAdornment, TextField } from "@mui/material";

const CustomAutoComplete = ({
  id,
  size,
  options,
  getOptionLabel,
  label,
  placeholder,
  value,
  iconStart,
  iconEnd,
  onChange,
  onInput,
  readOnly,
  onSelect,
  error,
  errorText,
  open,
  inputRef,
  borderRadius = "6px",
  border,
  borderOnFocus,
  background,
  disableBorder,
  inputPropStyle,
  height,
  required = false,
  ...restProps
}) => {
  return (
    <>
      <Autocomplete
        id={id}
        size={size}
        readOnly={readOnly}
        options={options}
        value={value}
        onChange={onChange}
        onInputChange={onInput}
        onSelectCapture={onSelect}
        getOptionLabel={getOptionLabel}
        open={open}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              required={required}
              label={label}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                sx: {
                  ...inputPropStyle,
                },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{
                      marginRight: "5px",
                      marginLeft: "5px",
                      cursor: "default",
                    }}
                  >
                    {iconStart}
                    {params.InputProps?.startAdornment}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      marginRight: "0px",
                      cursor: "default",
                    }}
                  >
                    {iconEnd}
                    {params.InputProps?.endAdornment}
                  </InputAdornment>
                ),
              }}
              readOnly={readOnly}
              error={error}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: disableBorder ? "none" : border ? border : undefined,
                  borderRadius: `${borderRadius} !important`,
                },
                "& .MuiOutlinedInput-input": {
                  background: background ? background : undefined,
                  height: `${height} !important`,
                },
                "& .MuiOutlinedInput-root": {
                  background: background ? background : undefined,
                  "&:hover fieldset": {
                    border: `${border} !important`,
                  },
                  "&.Mui-focused fieldset": {
                    border: `${borderOnFocus} !important`,
                    transition: "0.5s ease",
                  },
                },
              }}
              inputRef={inputRef}
            />
            {error && <FormHelperText error>{errorText}</FormHelperText>}
          </>
        )}
        {...restProps}
      />
    </>
  );
};

export default CustomAutoComplete;
