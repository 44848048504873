import {
  getNodeAtPath,
  Mosaic,
  MosaicContext,
  MosaicDragType,
  MosaicWindow,
  updateTree,
} from "react-mosaic-component";
import "assets/scss/custom-mosaic-theme.scss";
import PatientListForDashboardCV1 from "views/Components/Doctor/ConfigurableDoctorHomeScreenCV1/PatientListForDashboardCV1";
import PatientPrescriptionCardCV1 from "views/Components/Doctor/ConfigurableDoctorHomeScreenCV1/ConfigurableCanvasComponentsCV1/PatientPrescriptionCardCV1";
import PatientPrescriptionVitalsCV1 from "views/Components/Doctor/ConfigurableDoctorHomeScreenCV1/ConfigurableCanvasComponentsCV1/PatientPrescriptionVitalsCV1";
import PatientPrescriptionVitalGraphCV1 from "views/Components/Doctor/ConfigurableDoctorHomeScreenCV1/ConfigurableCanvasComponentsCV1/PatientPrescriptionVitalGraphsCV1";
import PrescriptionPadForClinicVisitCV1 from "views/Components/Doctor/ConfigurableDoctorHomeScreenCV1/ConfigurableCanvasComponentsCV1/PrescriptionPadForClinicVisitCV1";
import { Box, List, ListItem, Skeleton, Tooltip } from "@mui/material";
import {
  AccessibilityNew,
  CancelOutlined,
  Close,
  NoteAdd,
  PendingActions,
  Person,
  QueryStats,
} from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useDrag, useDrop } from "react-dnd";
import { useContext, useEffect, useState } from "react";
import PatientPrescriptionOverviewCV1 from "views/Components/Doctor/ConfigurableDoctorHomeScreenCV1/ConfigurableCanvasComponentsCV1/PatientPrescriptionOverviewCV1";
import { useSelector } from "react-redux";
import {
  getDynamicDefaultDashboard,
  getIsDashboardEditMode,
  getMosaicId,
  getMosaicTree,
  setDynamicDefaultDashboard,
  setIsDashboardEditMode,
  setMosaicTree,
} from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/mosaicSlice";
import { useDispatch } from "react-redux";
import React from "react";
import {
  ACTIVE_STATE,
  currentActiveUser,
  getUUID,
  hiuToken,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
} from "store/constant";
import {
  generateDynamicDashboard,
  getDynamicDashboardByDoctorId,
  updateDynamicDashboardById,
} from "services/DynamicDashboardService";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  getCollapseList,
  getSelectedAppointmentOnDashboard,
  setCollapseList,
} from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/appointmentSlice";
import PatientPrescription from "views/Components/Patient/PatientPrescription";
import {
  getIframeAppointmentId,
  getIframeOpen,
  getIframeSrc,
  getTabValue,
  setIframeOpen,
  setIframeSrc,
} from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/utilSlice";
import NoAppointmentSelectedPage from "views/Components/Doctor/ConfigurableDoctorHomeScreenCV1/ConfigurableCanvasComponentsCV1/NoAppointmentSelectedPage";
import SuperAdminDashboard from "views/Components/Dashboard/SuperAdminDashboard";
import { SidebarUtilContext } from "layout/SidebarUtilContext";

const MosaicTest = () => {
  const widgetIconsMap = {
    "Appointments List": <PendingActions />,
    "Patient Card": <Person />,
    "Latest Vitals": <MonitorHeartIcon />,
    "Vitals graph": <QueryStats />,
    "Prescription Pad": <NoteAdd />,
    "Health history": <AccessibilityNew />,
  };

  const tree = useSelector(getMosaicTree);
  const tabValue = useSelector(getTabValue);
  const dispatch = useDispatch();
  const [sidebarWidgetList, setSidebarWidgetList] = useState([]);
  const mosaicId = useSelector(getMosaicId);
  const isDashboardEditMode = useSelector(getIsDashboardEditMode);
  const { handleClick } = useContext(ToastContext);
  const selectedAppointment = useSelector(getSelectedAppointmentOnDashboard)?.value;

  const iframeOpen = useSelector(getIframeOpen);
  const iframeSrc = useSelector(getIframeSrc);
  const iframeAppointmentID = useSelector(getIframeAppointmentId);
  const collapseList = useSelector(getCollapseList);

  const flattenTree = (node) => {
    if (!node) return [];

    if (typeof node === "string") return [node];

    return [...flattenTree(node.first), ...flattenTree(node.second)];
  };

  useEffect(() => {
    const widgetListInTheTree = flattenTree(tree);

    const filterWidgetListOfSidebar = Object.keys(ELEMENT_MAP)
      .filter(
        (element) => !widgetListInTheTree.includes(element) && element !== "No Appointment Selected"
      )
      .map((element) => ({ id: element }));

    if (filterWidgetListOfSidebar.length !== sidebarWidgetList.length) {
      setSidebarWidgetList(filterWidgetListOfSidebar);
    }
  }, [tree]);

  const [mosaicMap, setMosaicMap] = useState({
    "Appointments List": ["Appointments List"],
    "Patient Card": ["Patient Card"],
    "Latest Vitals": ["Latest Vitals"],
    "Vitals graph": ["Vitals graph"],
    "Prescription Pad": ["Prescription Pad"],
    "Health history": ["Health history"],
    "No Appointment Selected": ["No Appointment Selected"],
  });

  const ELEMENT_MAP = {
    "Appointments List": <PatientListForDashboardCV1 key={"Appointments List"} />,
    "Patient Card": <PatientPrescriptionCardCV1 key={"APatient Card"} />,
    "Latest Vitals": <PatientPrescriptionVitalsCV1 key={"Latest Vitals"} />,
    "Vitals graph": <PatientPrescriptionVitalGraphCV1 key={"Vitals graph"} />,
    "Prescription Pad": <PrescriptionPadForClinicVisitCV1 key={"Prescription Pad"} />,
    "Health history": <PatientPrescriptionOverviewCV1 key={"Health history"} />,
    "No Appointment Selected": <NoAppointmentSelectedPage key={"No Appointment Selected"} />,
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: MosaicDragType.WINDOW,
    drop: (item, monitor) => {
      if (monitor.getDropResult()) {
        const { path, position } = monitor.getDropResult();
        if (path) {
          const destination = getNodeAtPath(tree, path);

          let first, second;
          let direction;
          if (position === "top") {
            direction = "column";
            first = item?.id;
            second = destination;
          } else if (position === "bottom") {
            direction = "column";
            first = destination;
            second = item?.id;
          } else if (position === "left") {
            direction = "row";
            first = item?.id;
            second = destination;
          } else {
            direction = "row";
            first = destination;
            second = item?.id;
          }

          const newTree = updateTree(tree, [
            {
              path,
              spec: {
                $set: {
                  direction,
                  first,
                  second,
                  splitPercentage: 50,
                },
              },
            },
          ]);
          dispatch(setMosaicTree(newTree));
        }
      }
      setMosaicMap({ ...mosaicMap, [item?.id]: [item?.id] });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const dynamicDefaultDashboard = useSelector(getDynamicDefaultDashboard);
  const handleSaveDoctorDashboard = async () => {
    const doctorId = currentActiveUser()?.roleBasedId;
    if (dynamicDefaultDashboard?.doctorId && dynamicDefaultDashboard?.id) {
      try {
        const payload = {
          configJson: JSON.stringify({
            mosaicTree: tree,
            utils: {
              appointmentsCollapseList: collapseList,
            },
          }),
        };
        await updateDynamicDashboardById(dynamicDefaultDashboard?.id, payload);
        handleClick("success", "Dashboard saved successfully");
        dispatch(setIsDashboardEditMode(false));
        setShowDiv(false);
      } catch (error) {}
    } else {
      try {
        const payload = {
          id: getUUID(),
          name: "Doctor Dashboard",
          description: "Dashboard for monitoring patient data",
          configJson: JSON.stringify({
            mosaicTree: tree,
            utils: {
              appointmentsCollapseList: collapseList,
            },
          }),
          cssJson: "",
          doctorId: doctorId,
          isDefault: true,
          status: ACTIVE_STATE,
        };

        const response = await generateDynamicDashboard(payload);
        handleClick("success", "Dashboard saved successfully");
        dispatch(setDynamicDefaultDashboard(response.data));
        dispatch(setIsDashboardEditMode(false));
        setShowDiv(false);
      } catch (error) {}
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let dashboardConfig;
      let AppoointmentCollapseList = false;
      try {
        const doctorDashboardResponse = await getDynamicDashboardByDoctorId(
          currentActiveUser()?.roleBasedId
        );
        dashboardConfig = JSON.parse(doctorDashboardResponse.data.configJson);
        if (dashboardConfig?.utils) {
          AppoointmentCollapseList = dashboardConfig?.utils?.appointmentsCollapseList;
          dashboardConfig = dashboardConfig?.mosaicTree;
        }
        dispatch(setDynamicDefaultDashboard(doctorDashboardResponse.data));
      } catch (error) {
        dashboardConfig = tree || {
          direction: "row",
          first: "Appointments List",
          second: {
            direction: "column",
            first: {
              direction: "row",
              first: "Patient Card",
              second: "Latest Vitals",
              splitPercentage: 50,
            },
            second: "Prescription Pad",
            splitPercentage: 23.82257214593472,
          },
          splitPercentage: 30,
        };
      } finally {
        dispatch(setMosaicTree(dashboardConfig));
        dispatch(setCollapseList(AppoointmentCollapseList));
      }
    };

    fetchData();
  }, []);

  const handleEditMode = () => {
    setMosaicLayoutBeforeGoingToTheEditMode({ mosaicTree: tree, collapseList: collapseList });
    dispatch(setIsDashboardEditMode(true));
    setShowDiv(true);
  };

  const [showDiv, setShowDiv] = useState(false);

  const handleMouseMove = (event) => {
    const { clientX, view } = event;
    const { innerWidth } = view;
    if (!isDashboardEditMode) {
      if (showDiv) {
        const toolbarWindow = document.querySelector(".mosaic-dashboard-right-side-toolbar");
        const throsholdWhenDivIsVisible = toolbarWindow.offsetWidth + 24;
        const isOutOfTOolbarWindow = innerWidth - clientX > throsholdWhenDivIsVisible;
        if (isOutOfTOolbarWindow) {
          setShowDiv(false);
        }
      } else {
        if (selectedAppointment) {
          const thresholdWhenDivIsHidden = 30;
          const isNearRight = innerWidth - clientX <= thresholdWhenDivIsHidden;
          if (isNearRight) {
            setShowDiv(isNearRight);
          }
        }
      }
    }
  };

  const [mosaicLayoutBeforeGoingToTheEditMode, setMosaicLayoutBeforeGoingToTheEditMode] =
    useState(null);

  const MosaicLayoutDashboard = (
    <div onMouseMove={handleMouseMove} style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          height: "calc(100vh - 70px) !important",
          width: "100%",
          backgroundColor: "#f2f6f8",
          position: "relative",
        }}
        ref={drop}
      >
        <div
          style={{
            height: "calc(100vh - 70px) !important",
            width: showDiv ? "96%" : "100%",
            transition: "width 0.3s ease",
          }}
        >
          <Mosaic
            renderTile={(id, path) => (
              <ModifiedMosaicWindow
                key={id}
                id={id}
                path={path}
                mosaicMap={mosaicMap}
                setMosaicMap={setMosaicMap}
                component={mosaicMap[id].map((element) => ELEMENT_MAP[element])}
              />
            )}
            value={tree}
            onChange={(tr) => {
              dispatch(setMosaicTree(tr));
            }}
            // resize={'DISABLED'}
          />
        </div>
        <div
          style={{
            height: "calc(100vh - 70px) !important",
            width: showDiv ? "4%" : "0%",
            padding: showDiv ? "5px 0px" : "0px 0px",
            transition: "width 0.3s ease, padding 0.3s ease",
            position: "relative",
            right: 0,
          }}
          className="mosaic-dashboard-right-side-toolbar"
        >
          {showDiv && (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                borderRadius: "4px",
                padding: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: sidebarWidgetList.length === 0 ? "flex-end" : "space-between",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <List key={mosaicId} sx={{ p: "0px !important" }}>
                  {sidebarWidgetList?.map((widget, index) => {
                    return (
                      <ListItem
                        key={`${widget.id}${isDashboardEditMode}`}
                        disablePadding
                        sx={{ display: "block", marginBottom: "10px" }}
                      >
                        <DraggableIcon
                          icon={widgetIconsMap[widget.id]}
                          id={widget.id}
                          dragType={MosaicDragType.WINDOW}
                          isDashboardEditMode={isDashboardEditMode}
                        />
                      </ListItem>
                    );
                  })}
                </List>
                <div>
                  {isDashboardEditMode && (
                    <Tooltip title={"Cancel"} placement="left">
                      <CustomButton
                        textAndIconColor="#004c70"
                        iconButton={<CancelOutlined sx={{ color: "red" }} />}
                        gap="0px"
                        style={{ borderRadius: "18px" }}
                        onClick={() => {
                          dispatch(setIsDashboardEditMode(false));
                          setShowDiv(false);
                          dispatch(
                            setCollapseList(mosaicLayoutBeforeGoingToTheEditMode.collapseList)
                          );
                          dispatch(setMosaicTree(mosaicLayoutBeforeGoingToTheEditMode.mosaicTree));
                        }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip
                    title={isDashboardEditMode ? "Save Dashboard" : "Edit Dashboard"}
                    placement="left"
                  >
                    <CustomButton
                      textAndIconColor="#004c70"
                      iconButton={
                        isDashboardEditMode ? (
                          <i className="ri-save-3-line"></i>
                        ) : (
                          <i className="ri-edit-2-line"></i>
                        )
                      }
                      gap="0px"
                      style={{ borderRadius: "18px" }}
                      onClick={isDashboardEditMode ? handleSaveDoctorDashboard : handleEditMode}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {iframeOpen?.value && (
        <div className="iframe-div">
          {iframeSrc ? (
            <iframe
              title="consent form"
              src={iframeSrc}
              target="_blank"
              className="iframe"
            ></iframe>
          ) : (
            <div className="iframe">
              <PatientPrescription appId={iframeAppointmentID} />
            </div>
          )}

          <CustomButton
            iconButton={<Close />}
            onClick={() => {
              dispatch(setIframeOpen(false));
              dispatch(setIframeSrc(null));
            }}
            className="iframe-close-button"
          />
        </div>
      )}
    </div>
  );

  const items = [
    {
      label: "Mosaic layout Dashboard",
      value: 0,
      component: MosaicLayoutDashboard,
    },
    {
      label: "Analytics",
      value: 1,
      component: <SuperAdminDashboard dashboardid={"f9d810b1-5c9a-4cb6-b106-184d93da58fd"} />,
    },
  ];

  return (
    <DoctorAppointmentSkeleton>
      <Box>
        <Grid>{items[tabValue?.value].component}</Grid>
      </Box>
    </DoctorAppointmentSkeleton>
  );
};

const DraggableIcon = ({ id, icon, dragType, isDashboardEditMode }) => {
  const mosaicId = useSelector(getMosaicId);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: dragType,
    canDrag: isDashboardEditMode,
    item: { id, mosaicId: dragType === "ICON" ? undefined : mosaicId }, // Dragged item's ID
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Tooltip title={id} placement="left">
      <div
        ref={drag}
        style={{ opacity: isDragging ? 0.5 : 1, display: "flex", justifyContent: "center" }}
      >
        <CustomButton
          textAndIconColor="#004c70"
          iconButton={icon}
          gap="0px"
          style={{ borderRadius: "18px" }}
          disabled={!isDashboardEditMode}
        />
      </div>
    </Tooltip>
  );
};

const ModifiedMosaicWindow = ({ id, path, component, mosaicMap, setMosaicMap }) => {
  const mosaicContext = useContext(MosaicContext);
  const isDashboardEditMode = useSelector(getIsDashboardEditMode);

  const removeWindow = (path) => {
    mosaicContext.mosaicActions.remove(path);
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "ICON",
    drop: (item, monitor) => {
      const updatedMosaicMap = {
        ...mosaicMap,
        [id]: [...mosaicMap[id], item.id],
      };
      setMosaicMap(updatedMosaicMap);
    },
  });

  return (
    <div
      ref={drop}
      key={id}
      onClick={(e) => e.stopPropagation()}
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <MosaicWindow
        key={id}
        title={`${id}`}
        path={path}
        draggable={isDashboardEditMode}
        renderToolbar={(path) => (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 7px",
            }}
          >
            <span
              style={{
                flex: 1,
                color: "#004c70",
                fontWeight: 600,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >{`${id}`}</span>
            {id !== "Appointments List" && isDashboardEditMode && (
              <CustomButton
                onClick={(e) => {
                  e.stopPropagation(); // Prevent drag interactions
                  removeWindow(path.path);
                }}
                iconButton={<Close sx={{ fontSize: "20px" }} />}
                customBackGroundColor={"transparent"}
                textAndIconColor={"red"}
                style={{ padding: "0px" }}
              ></CustomButton>
            )}
          </div>
        )}
      >
        <div
          style={{
            padding: "0px 10px 10px 10px",
            height: "100%",
          }}
        >
          {component}
        </div>
      </MosaicWindow>
    </div>
  );
};

const DoctorAppointmentSkeleton = ({ children, doctorOrganizations }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid size={{ xs: 3 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>

          <Grid display={"flex"} size={{ xs: 6 }}>
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="10%"
              height={40}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={40}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid size={{ xs: 12 }} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid size={{ xs: 8 }} pr={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={700}
            />
          </Grid>
          <Grid size={{ xs: 4 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={700}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MosaicTest;
