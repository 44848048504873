import { EmailOutlined } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import { VERIFY_INVITATION_LINK } from "store/constant";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";

const OnboardingStep3 = ({ handleBlur, handleChange, values, errors, touched, validatedBy }) => {
  return (
    <>
      <div className="mb-3">
        <label htmlFor="inputTypeText" className="form-label onboarding-label">
          How can we reach you?
        </label>
        <Grid>
          <FormInputField
            placeholder="Enter Email Id"
            name="emailId"
            value={values.emailId}
            onBlur={handleBlur}
            inputPropStyle={{ fontSize: "17px", fontWeight: "400" }}
            background={"#fff"}
            border={"1px solid #e6eef1"}
            borderOnFocus={"1px solid #29bf91"}
            onChange={handleChange}
            error={Boolean(touched.emailId && errors.emailId)}
            errorText={errors.emailId}
            startAdornment={<EmailOutlined />}
            size={"small"}
            readOnly={validatedBy === VERIFY_INVITATION_LINK}
          />
        </Grid>
      </div>
    </>
  );
};

export default OnboardingStep3;
