import React from "react";
import "assets/scss/doctorOnboarding.scss";
import "assets/css/animate.min.css";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation, useNavigate } from "react-router";
import OnboardingRole from "./OnboardingRole";
import OnboardingStep1 from "./OnboardingStep1";
import OnboardingStep2 from "./OnboardingStep2";
import OnboardingStep3 from "./OnboardingStep3";
import OnboardingStep4 from "./OnboardingStep4";
import OnboardingStep5 from "./OnboardingStep5";
import { Formik } from "formik";
import { useRef } from "react";
import {
  step0,
  roleSchema,
  step1,
  step2,
  step3,
  step4,
  step5,
} from "views/Components/Common/ValidationSchema/doctorOnboardingValidation";
import { createUserProfileOnboard, registerOrganizationInTenant } from "services/userService";
import {
  ACTIVE_STATE,
  CLINIC_ADMIN,
  DATE_TIME_FORMAT,
  DOCTOR,
  getRoleIdByName,
  getUUID,
  PATIENT,
  VERIFY_ABHA_NUMBER,
  VERIFY_HPR,
  VERIFY_INVITATION_LINK,
  VERIFY_MOBILE_NUMBER,
} from "store/constant";
import OnboardingStep0 from "./OnboardingStep0";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
  getAbhaData,
  getHfrData,
  getHprData,
  getInvitationLinkData,
  getOrganizationData,
  getShowOnboarding,
  setShowOnboarding,
} from "store/Slices/doctorOnboardingSlice";
import { setUserFeatures } from "store/Slices/userSlice";
import { getUserTenant, principle } from "services/AuthService";
import ProfileCard from "ui-component/cards/ProfileCard";
import dayjs from "dayjs";
import { getOrgById } from "services/organizationService";

const convertHfrResponseToOrganizationData = (hfrResponse) => {
  if (!hfrResponse) {
    return null;
  }
  return {
    ...hfrResponse,
    name: hfrResponse.facilityName,
    address: hfrResponse.address,
    adDistrict: {
      name: hfrResponse.districtName,
      code: hfrResponse.districtLGDCode,
    },
    adState: {
      name: hfrResponse.stateName,
      code: hfrResponse.stateLGDCode,
    },
    adPincode: hfrResponse.pincode,
  };
};

const DoctorOnboardingPage = ({ ...others }) => {
  const [onboardingStep, setOnboardingStep] = useState(-1);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const location = useLocation();
  const validatedBy = location?.state?.validatedBy || null;
  const doctorDetail = location?.state?.doctorDetail || {};
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const showOnboardPage = useSelector(getShowOnboarding);

  const { handleClick } = useContext(ToastContext);
  const healthIdAccountProfile = useSelector(getAbhaData);
  const hpIdAccountResponse = useSelector(getHprData);
  const organizaitonData = useSelector(getOrganizationData);
  const hfrResponse = useSelector(getHfrData);
  const invitationLinkData = useSelector(getInvitationLinkData);
  const organizationResponse =
    validatedBy === VERIFY_INVITATION_LINK
      ? organizaitonData
      : convertHfrResponseToOrganizationData(hfrResponse);
  const [roles, setRoles] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const patientRoleId = await getRoleIdByName(PATIENT, handleClick);
        const doctorRoleId = await getRoleIdByName(DOCTOR, handleClick);
        setRoles({
          patientRoleId: patientRoleId,
          doctorRoleId: doctorRoleId,
        });
      } catch (error) {
        console.error("Error getting data");
      }
    };
    if (!showOnboardPage || !validatedBy) {
      navigate("/", { replace: true });
    } else {
      dispatch(setShowOnboarding(false));
      if (validatedBy === VERIFY_HPR) {
        setOnboardingStep(5);
      } else if (validatedBy === VERIFY_INVITATION_LINK) {
        setOnboardingStep(0);
      }
      fetchData();
    }
  }, []);

  const [dobError, setDobError] = useState(
    doctorDetail.dateOfBirth ? "" : "Date of Birth is required."
  );

  const [pincodeError, setPincodeError] = useState("");

  const createTouchedFields = (obj) => {
    const touchedFields = {};
    for (const key in obj) {
      const value = obj[key];
      if (typeof value === "object" && value !== null) {
        touchedFields[key] = createTouchedFields(value);
      } else {
        touchedFields[key] = true;
      }
    }
    return touchedFields;
  };

  const boardingSteps = [
    { image: "/images/Work time-rafiki.png", progressPercentage: "0%" },
    { image: "/images/Choose-rafiki.png", progressPercentage: "20%" },
    { image: "/images/Happy birthday-rafiki.png", progressPercentage: "36.5%" },
    { image: "/images/Contact us-bro.png", progressPercentage: "48%" },
    { image: "/images/Certification-rafiki.png", progressPercentage: "78%" },
    {
      image: "/images/Hospital building-rafiki.png",
      progressPercentage: "100%",
    },
  ];
  useEffect(() => {
    setProgressPercentage(boardingSteps[onboardingStep]?.progressPercentage);
  }, [onboardingStep]);

  const getOnboardingType = (type) => {
    switch (type) {
      case VERIFY_HPR:
        return "HPR";
      case VERIFY_ABHA_NUMBER:
        return "ABHA";
      case VERIFY_MOBILE_NUMBER:
        return "MOBILE_NUMBER";
      case VERIFY_INVITATION_LINK:
        return "INVITE_LINK";
      default:
        return "MOBILE_NUMBER";
    }
  };

  const handleSubmitForm = async () => {
    const formValues = formRef.current.values;

    const payload = {
      userResponse: {
        id: getUUID(),
        name: formValues.fullName,
        mobileNumber: formValues.mobileNumber,
        emailId: formValues.emailId,
        roleId: await getRoleIdByName(DOCTOR, handleClick),
        roleBasedId: getUUID(),
        status: ACTIVE_STATE,
        roleName: "DOCTOR",
        gender: formValues.gender,
        dateOfBirth: formValues.dateOfBirth
          ? dayjs(formValues.dateOfBirth).format(DATE_TIME_FORMAT)
          : null,
        preferences: {
          prescriptionSearchType: "CATEGORY",
        },
        hprId: doctorDetail.hpr,
        abhaNumber: doctorDetail.abha,
        userOrganizationAssociationList: [
          {
            id: getUUID(),
          },
        ],
      },
      doctorRegistrationResponse: {
        id: getUUID(),
        registrationId: formValues.doctorRegistration.registrationId,
        registrationCouncil: formValues.doctorRegistration.registrationCouncil,
      },
      organizationResponse: {
        id: organizationResponse?.id || getUUID(),
        name: formValues.organization.name,
        address: formValues.organization.address,
        adState: formValues.organization.adState,
        adDistrict: formValues.organization.adDistrict,
        adPincode: formValues.organization.adPincode,
        ...(hfrResponse && {
          adSubDistrict: {
            name: hfrResponse.subDistrictName,
            code: hfrResponse.subDistrictLGDCode,
          },
          latitude: hfrResponse.latitude,
          longitude: hfrResponse.longitude,
          hfrFacilityId: hfrResponse.facilityId,
        }),
      },
      onBoardingType: getOnboardingType(validatedBy),
      healthIdAccountProfile: healthIdAccountProfile,
      hpIdAccountResponse: hpIdAccountResponse,
      inviteResponse: {
        ...invitationLinkData,
      },
    };
    try {
      const userResponse = await createUserProfileOnboard(payload);
      const accessToken = userResponse?.data?.authenticationResponse.access_token;
      const refreshToken = userResponse?.data?.authenticationResponse.refresh_token;
      const decodedValue = jwtDecode(accessToken);
      localStorage.setItem("isOTPLogin", true);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("username", decodedValue?.sub);
      localStorage.setItem("loggedIn", true);
      dispatch({ type: "SET_ACCESS_TOKEN", payload: accessToken });

      const userTenantResponse = await getUserTenant(decodedValue?.sub);
      if (userTenantResponse && userTenantResponse.data.length > 0) {
        localStorage.setItem("tenantResponse", JSON.stringify(userTenantResponse.data));
        localStorage.setItem("subDomain", userTenantResponse.data?.[0].baseUrl);
      }

      await registerOrganizationInTenant(payload);

      if (userTenantResponse && userTenantResponse.data.length > 0) {
        const organizationDetailsResponse = await getOrgById(
          userTenantResponse?.data?.[0]?.organizationId
        );
        localStorage.setItem(
          "facilityBridgeLinkId",
          organizationDetailsResponse?.data?.facilityBridgeLinkId
        );
      }

      const responseList = await principle(decodedValue?.sub);
      const userResponseData = responseList.data.userResponse;
      localStorage.setItem("currentActiveUser", JSON.stringify(userResponseData));
      localStorage.setItem(
        "timeFormat",
        userResponseData?.preferences?.prefers24HrFormat === true ? "24" : "12"
      );
      localStorage.setItem(
        "prescriptionSearchType",
        userResponseData?.preferences?.prescriptionSearchType
      );

      let userFeatures = responseList.data.features;
      if (Object.keys(userFeatures).length === 0) {
        userFeatures = { USER: "" };
      }
      dispatch(setUserFeatures(userFeatures));

      if (userResponse.data?.roleName === CLINIC_ADMIN || userResponse.data?.roleName === DOCTOR) {
        localStorage.setItem(
          "orgId",
          userResponse.data.userOrganizationAssociationList[0]?.organizationId
        );
      }
      navigate("/subscription");
    } catch (error) {
      handleClick("error", error?.response?.data?.message);
    }
  };

  const pincodeErrorRef = useRef(pincodeError);

  useEffect(() => {
    pincodeErrorRef.current = pincodeError; // Keep the ref updated with the latest value
  }, [pincodeError]);

  useEffect(() => {
    const handleGlobalKeyPress = async (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        const c = await formRef.current.validateForm();
        let f = {};
        if (Object.keys(c).length === 0) {
          if (onboardingStep === 5) {
            if (!pincodeErrorRef.current) {
              handleSubmitForm();
            } else {
              f = { organization: { name: true, adPincode: true } };
              formRef.current.setTouched(f);
            }
          } else if (onboardingStep === 2) {
            if (dobError) {
              f.dateOfBirth = true;
              formRef.current.setTouched(f);
            } else {
              setOnboardingStep((prev) => prev + 1);
            }
          } else if (onboardingStep === 3 && validatedBy === VERIFY_HPR) {
            setOnboardingStep((prev) => prev + 2);
          } else {
            setOnboardingStep((prev) => prev + 1);
          }
        } else {
          f = createTouchedFields(c);
          if (onboardingStep === 5) {
            f = { ...f, organization: { ...f.organization, name: true, adPincode: true } };
          }
          formRef.current.setTouched(f);
        }
      }
    };

    window.addEventListener("keydown", handleGlobalKeyPress);

    return () => {
      window.removeEventListener("keydown", handleGlobalKeyPress);
    };
  }, [onboardingStep, dobError]);

  return (
    <div className="doctor-onboarding">
      <div className="inner-doctor-onboarding">
        <div className="p-2">
          <NavBar></NavBar>
        </div>
        {onboardingStep >= 0 && (
          <ProgressBar width={progressPercentage} onboardingStep={onboardingStep}></ProgressBar>
        )}
        <div>
          <Formik
            innerRef={formRef}
            enableReinitialize={true}
            initialValues={{
              fullName: doctorDetail?.fullName,
              dateOfBirth: doctorDetail?.dateOfBirth,
              mobileNumber: doctorDetail?.mobileNumber,
              gender: doctorDetail?.gender,
              emailId: doctorDetail.emailId,
              roleId: doctorDetail.roleId,
              doctorRegistration: {
                registrationId: "",
                registrationCouncil: "",
              },
              organization: {
                name: organizationResponse?.name || "",
                address: organizationResponse?.address || "",
                adState: organizationResponse?.adState || null,
                adDistrict: organizationResponse?.adDistrict || null,
                adPincode: organizationResponse?.adPincode || "",
              },
            }}
            validationSchema={() => {
              switch (onboardingStep) {
                case -1:
                  return roleSchema;

                case 0:
                  return step0;

                case 1:
                  return step1;

                case 2:
                  return step2;

                case 3:
                  return step3;

                case 4:
                  return step4;

                case 5:
                  return step5;

                default:
                  return Yup.object().shape({});
              }
            }}
            onSubmit={async (values) => {}}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleBlur,
              handleChange,
              handleSubmit,
              validateForm,
              setTouched,
              setValues,
            }) => (
              <form noValidate onSubmit={handleSubmit} {...others}>
                {onboardingStep === -1 && (
                  <>
                    <div
                      className="back-button-div container"
                      onClick={() => {
                        navigate("/", { replace: true });
                      }}
                    >
                      <div className="btn btn-back">
                        <img src="/images/hc_back_arrow.svg" alt="back-arrow"></img>
                        login
                      </div>
                    </div>

                    <OnboardingRole
                      validateForm={validateForm}
                      onboardingStep={onboardingStep}
                      setOnboardingStep={setOnboardingStep}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      navigate={navigate}
                      setTouched={setTouched}
                      roles={roles}
                    ></OnboardingRole>
                  </>
                )}
                {onboardingStep >= 0 && onboardingStep <= 10 && (
                  <>
                    <div
                      className="back-button-div container"
                      onClick={() => {
                        if (onboardingStep === 5 && validatedBy === VERIFY_HPR) {
                          navigate("/", { replace: true });
                        } else {
                          setOnboardingStep((prev) => prev - 1);
                        }
                      }}
                    >
                      <div className="btn btn-back">
                        <img src="/images/hc_back_arrow.svg" alt="back-arrow"></img>
                        back
                      </div>
                    </div>
                    <div className="container content-div align-items-center justify-content-center">
                      <div className="row align-items-center justify-content-start h-100">
                        {onboardingStep === 0 && (
                          <div className="col-md-8 d-flex flex-column custom-flex-box animate__animated animate__fadeInUp animate__normal">
                            <form className="form-flex-box">
                              <OnboardingStep0
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                values={values}
                                errors={errors}
                                touched={touched}
                              ></OnboardingStep0>
                              <OnboardingFormFooter
                                createTouchedFields={createTouchedFields}
                                onboardingStep={onboardingStep}
                                setOnboardingStep={setOnboardingStep}
                                validateForm={validateForm}
                                setTouched={setTouched}
                              ></OnboardingFormFooter>
                            </form>
                          </div>
                        )}
                        {onboardingStep === 1 && (
                          <div className="col-md-8 d-flex flex-column custom-flex-box animate__animated animate__fadeInUp animate__normal">
                            <form className="form-flex-box">
                              <OnboardingStep1
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                              ></OnboardingStep1>
                              <OnboardingFormFooter
                                createTouchedFields={createTouchedFields}
                                onboardingStep={onboardingStep}
                                setOnboardingStep={setOnboardingStep}
                                validateForm={validateForm}
                                setTouched={setTouched}
                              ></OnboardingFormFooter>
                            </form>
                          </div>
                        )}
                        {onboardingStep === 2 && (
                          <div className="col-md-8 d-flex flex-column custom-flex-box animate__animated animate__fadeInUp animate__normal">
                            <form className="form-flex-box">
                              <OnboardingStep2
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                values={values}
                                errors={errors}
                                touched={touched}
                                dobError={dobError}
                                setDobError={setDobError}
                              ></OnboardingStep2>
                              <OnboardingFormFooter
                                createTouchedFields={createTouchedFields}
                                onboardingStep={onboardingStep}
                                setOnboardingStep={setOnboardingStep}
                                validateForm={validateForm}
                                setTouched={setTouched}
                                dobError={dobError}
                              ></OnboardingFormFooter>
                            </form>
                          </div>
                        )}
                        {onboardingStep === 3 && (
                          <div className="col-md-8 d-flex flex-column custom-flex-box animate__animated animate__fadeInUp animate__normal">
                            <form className="form-flex-box">
                              <OnboardingStep3
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                values={values}
                                errors={errors}
                                touched={touched}
                                validatedBy={validatedBy}
                              ></OnboardingStep3>
                              <OnboardingFormFooter
                                createTouchedFields={createTouchedFields}
                                onboardingStep={onboardingStep}
                                setOnboardingStep={setOnboardingStep}
                                validateForm={validateForm}
                                setTouched={setTouched}
                                validatedBy={validatedBy}
                              ></OnboardingFormFooter>
                            </form>
                          </div>
                        )}
                        {onboardingStep === 4 && (
                          <div className="col-md-8 d-flex flex-column custom-flex-box animate__animated animate__fadeInUp animate__normal">
                            <form className="form-flex-box">
                              <OnboardingStep4
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                values={values}
                                errors={errors}
                                touched={touched}
                              ></OnboardingStep4>
                              <OnboardingFormFooter
                                createTouchedFields={createTouchedFields}
                                onboardingStep={onboardingStep}
                                setOnboardingStep={setOnboardingStep}
                                validateForm={validateForm}
                                setTouched={setTouched}
                              ></OnboardingFormFooter>
                            </form>
                          </div>
                        )}
                        {onboardingStep === 5 && (
                          <div className="col-md-8 d-flex flex-column custom-flex-box animate__animated animate__fadeInUp animate__normal">
                            <form className="form-flex-box row clinic-layout-form g-3">
                              <OnboardingStep5
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                values={values}
                                errors={errors}
                                touched={touched}
                                organizationResponse={organizationResponse}
                                setValues={setValues}
                                setTouched={setTouched}
                                pincodeError={pincodeError}
                                setPincodeError={setPincodeError}
                              ></OnboardingStep5>
                              <OnboardingFormFooter
                                createTouchedFields={createTouchedFields}
                                onboardingStep={onboardingStep}
                                setOnboardingStep={setOnboardingStep}
                                validateForm={validateForm}
                                setTouched={setTouched}
                                navigate={navigate}
                                pincodeError={pincodeError}
                                formRef={formRef}
                                handleSubmitForm={handleSubmitForm}
                              ></OnboardingFormFooter>
                            </form>
                          </div>
                        )}

                        <div key={onboardingStep} className="col-md-4 d-none d-md-block">
                          {onboardingStep === 5 && validatedBy === VERIFY_HPR ? (
                            <div className="mx-auto img-fluid animate__animated animate__fadeInRight animate__normal">
                              <ProfileCard
                                fullName={values.fullName}
                                dateOfBirth={dayjs(values.dateOfBirth).format("DD MMMM, YYYY")}
                                gender={values.gender}
                                emailId={values.emailId}
                                mobileNumber={values.mobileNumber}
                                image={hpIdAccountResponse?.kycPhoto}
                              ></ProfileCard>
                            </div>
                          ) : (
                            <img
                              src={boardingSteps[onboardingStep]?.image}
                              alt="login-img"
                              className="mx-auto img-fluid animate__animated animate__fadeInRight animate__normal"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const OnboardingFormFooter = ({
  createTouchedFields,
  dobError,
  onboardingStep,
  setOnboardingStep,
  navigate,
  validateForm,
  setTouched,
  handleSubmitForm,
  validatedBy,
  pincodeError,
  orgNameError,
  formRef,
}) => {
  return (
    <>
      <div className="form-bottom d-flex align-items-center gap-3 mb-3">
        <div
          className="btn btn-onboarding-next w-auto"
          onClick={async () => {
            const c = await validateForm();
            let f = {};
            if (Object.keys(c).length === 0) {
              if (onboardingStep === 5) {
                if (!pincodeError && !orgNameError) {
                  handleSubmitForm();
                } else {
                  f = { organization: { name: true, adPincode: true } };
                  formRef.current.setTouched(f);
                }
              } else if (onboardingStep === 2) {
                if (dobError) {
                  f.dateOfBirth = true;
                  setTouched(f);
                } else {
                  setOnboardingStep((prev) => prev + 1);
                }
              } else if (onboardingStep === 3 && validatedBy === VERIFY_HPR) {
                setOnboardingStep((prev) => prev + 2);
              } else {
                setOnboardingStep((prev) => prev + 1);
              }
            } else {
              f = createTouchedFields(c);
              if (onboardingStep === 5) {
                f = {
                  ...f,
                  organization: { ...f.organization, name: true, adPincode: true },
                };
              }
              setTouched(f);
            }
          }}
        >
          <span>{onboardingStep === 5 ? "Complete" : "Next"}</span>
          <ArrowCircleRightOutlinedIcon
            style={{ width: "18opx", height: "18px" }}
          ></ArrowCircleRightOutlinedIcon>
        </div>
        <span>or Press enter to continue</span>
      </div>
    </>
  );
};

const ProgressBar = ({ width, onboardingStep }) => {
  return (
    <div id="form-container" className="container mt-5">
      <div className="progress px-1" style={{ height: "3px" }}>
        <div
          className="progress-bar custom-progress"
          role="progressbar"
          style={{ width: width }}
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="step-container d-flex justify-content-between">
        <div
          className={`step-circle ${onboardingStep >= 0 ? "active" : ""} ${
            onboardingStep > 2 ? "completed" : ""
          }`}
        >
          <div className="icon">
            <CheckCircleIcon></CheckCircleIcon>
          </div>
          <span className="d-none d-md-block">Personal Details</span>
          <span className="d-block d-md-none circle-number">1</span>
        </div>
        <div
          className={`step-circle ${onboardingStep > 2 ? "active" : ""} ${
            onboardingStep > 4 ? "completed" : ""
          }`}
        >
          <div className="icon">
            <CheckCircleIcon></CheckCircleIcon>
          </div>
          <span className="d-none d-md-block">Professional Details</span>
          <span className="d-block d-md-none circle-number">2</span>
        </div>
        <div
          className={`step-circle ${onboardingStep > 4 ? "active" : ""} ${
            onboardingStep > 5 ? "completed" : ""
          }`}
        >
          <div className="icon">
            <CheckCircleIcon></CheckCircleIcon>
          </div>
          <span className="d-none d-md-block">Working Details</span>
          <span className="d-block d-md-none circle-number">3</span>
        </div>
      </div>
    </div>
  );
};

export const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light custom-navbar bg-transparent">
      <div className="container">
        <a
          className="navbar-brand text-white d-flex align-items-center"
          href="https://www.argusoft.com"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/images/arog-icon.svg"
            className="me-1 rounded-lg"
            height="40px"
            width="50px"
            alt="logo"
          />
          <img src="/images/arog-logo-text.svg" height="30px" width="auto" alt="text" />
        </a>
        <a
          href="https://www.argusoft.com"
          target="_blank"
          rel="noreferrer"
          className="helper-class"
        >
          Need help?
        </a>
      </div>
    </nav>
  );
};

export default DoctorOnboardingPage;
