import "assets/scss/profileCard.scss";
import { toCamelCase } from "utils/toCamelCase";

const ProfileCard = ({
  fullName = "",
  dateOfBirth = "",
  gender = "",
  emailId = "",
  mobileNumber = "",
  image,
  abhaNumber = "",
  abhaAddress = "",
}) => {
  const base64Image = `data:image/jpeg;base64,${image}`;
  return (
    <>
      <div className="user-profile-card">
        <div
          className="user-image-container"
          style={{
            backgroundImage: `url(${base64Image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        >
          <img className="user-avatar" src={base64Image}></img>
        </div>
        <div className="user-detail-container">
          <div style={{ paddingTop: "60px" }}>
            <div className="user-detail-fullname">
              <p>{fullName}</p>
            </div>
            <div className="user-detail-gender">
              <p>{toCamelCase(gender)}</p>
            </div>
            <div className="user-detail-birth">
              <p>{dateOfBirth}</p>
            </div>
            <div className="user-detail-email">
              <p>{mobileNumber}</p>
            </div>

            <div className="user-detail-email">
              <p>{emailId}</p>
            </div>
            <div className="user-detail-abha-number">
              <p>{abhaNumber}</p>
            </div>
            <div className="user-detail-abha-address">
              <p>{abhaAddress}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCard;
