import { api as API } from "./AxiosInterceptor";
import { getUUID } from "../store/constant";

export const getAllContent = () => API.get(`api/contents`);
export const getContentById = (id) => API.get(`api/contents/${id}`);
export const createContent = (data, file) => {
  return API.post(`api/contents`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const updateContent = (id, data) =>
  API.put(`api/contents/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const deleteContent = (id) => API.delete(`api/contents/${id}`);
export const searchContent = (searchText) =>
  API.get(`api/contents/search?searchText=${searchText}`);
export const searchContentForUser = (userId) => API.get(`api/contents/user-profile/${userId}`);
export const getPosterForCampaign = (posterId) => API.get(`api/contents/poster/${posterId}`);
