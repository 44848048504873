import React, { useCallback, useContext, useEffect } from "react";
import {
  getIsSyncing,
  getIsSyncModalOpen,
  getLastSyncInfo,
  getSyncStatus,
  setIsSyncing,
  setLastSyncInfo,
  setShowSyncModal,
  setSyncStatus,
} from "store/Slices/cloudSyncSlice";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import ModalUI from "ui-component/ModalUI";
import RIf from "ui-component/RIf";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useDispatch } from "react-redux";
import { startCloudSync, fetchSyncInfo } from "services/SyncService";
import dayjs from "dayjs";
import { ToastContext } from "ui-component/custom-components/CustomToast";

const modalSytle = {
  maxWidth: "480px",
  minWidth: "240px",
  width: "100%",
};

const labelStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
};

const successLabel = {
  ...labelStyle,
  color: "green",
};

const failLabel = {
  ...labelStyle,
  color: "red",
};

function SyncModal(props) {
  const isModalOpen = useSelector(getIsSyncModalOpen);
  const isSyncing = useSelector(getIsSyncing);
  const syncStatus = useSelector(getSyncStatus);
  const lastSyncInfo = useSelector(getLastSyncInfo);
  const dispatcher = useDispatch();

  const { handleClick: showToast } = useContext(ToastContext);

  useEffect(() => {
    if (!isModalOpen) {
      if (syncStatus === "FAILED") {
        showToast("error", "Cloud sync failed");
      }
      if (syncStatus === "SUCCESS") {
        showToast("success", "Cloud sync successful");
      }
    }
  }, [syncStatus]);

  const fetchLastSync = useCallback(async () => {
    try {
      const response = await fetchSyncInfo();
      dispatcher(
        setLastSyncInfo({
          push: response.data?.push?.lastSyncTimestamp,
          pull: response.data?.pull?.lastSyncTimestamp,
        })
      );
    } catch (e) {
      dispatcher(setLastSyncInfo({ pull: "error", push: "error" }));
    }
  }, [dispatcher]);

  useEffect(() => {
    fetchLastSync();
  }, [fetchLastSync]);

  const onSync = async () => {
    dispatcher(setIsSyncing(true));
    try {
      await startCloudSync();
      dispatcher(setSyncStatus("SUCCESS"));
      fetchLastSync();
    } catch (e) {
      dispatcher(setSyncStatus("FAILED"));
    }
    dispatcher(setIsSyncing(false));
  };

  const onSyncModalClose = () => {
    dispatcher(setShowSyncModal(false));
    dispatcher(setSyncStatus(""));
  };

  const getLastSyncDate = () => {
    let dt = "never";
    if (lastSyncInfo.push === "error" || lastSyncInfo.pull === "error") return "error";
    if (lastSyncInfo.push === "loading" || lastSyncInfo.pull === "loading") return "loading";

    if (lastSyncInfo.push) {
      dt = dayjs(lastSyncInfo.push);
    }

    if (lastSyncInfo.pull) {
      if (dt) {
        let pullDt = dayjs(lastSyncInfo.pull);
        if (pullDt.isBefore(dt)) {
          dt = pullDt;
        }
      } else {
        dt = dayjs(lastSyncInfo.pull);
      }
    }
    if (typeof dt === "object") return dt.format("DD/MM/YYYY HH:mm A");
    return dt;
  };

  return (
    <ModalUI
      visible={isModalOpen}
      title="Cloud sync"
      component={
        <div style={modalSytle}>
          <div style={{ paddingTop: "16px" }}>
            <p>
              <strong>Last synced: </strong>
              {getLastSyncDate()}
            </p>
          </div>
          <RIf show={isSyncing}>
            <LinearProgress />
          </RIf>
          <RIf show={!isSyncing}>
            <div style={syncStatus === "SUCCESS" ? successLabel : failLabel}>
              <RIf show={syncStatus === "SUCCESS"}>
                <i className="ri-checkbox-circle-line ri-2x"></i>
                <h3>SUCCESS</h3>
              </RIf>
              <RIf show={syncStatus === "FAILED"}>
                <i className="ri-error-warning-line ri-2x"></i>
                <h3>FAILED</h3>
              </RIf>
            </div>
          </RIf>
          <RIf show={!isSyncing}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <CustomButton
                variant="text"
                label={syncStatus === "FAILED" ? "Retry" : "Sync"}
                onClick={onSync}
              />
            </div>
          </RIf>
        </div>
      }
      close={onSyncModalClose}
    />
  );
}

export default SyncModal;
