import Grid from "@mui/system/Unstable_Grid";
import LabReportVitalContainer from "./LabReportVitalContainer";
import { Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  getDocumentByIndex,
  getDocumentPageVisible,
  setDocumentPageVisible,
} from "store/Slices/documentVitalSlice";
import { useEffect, useState, useRef } from "react";
import { getPatientDocumentByDocumentId } from "services/patientService";
import { fileTypes } from "store/constant";
import PdfToImg from "pdftoimg-js/browser";
import CustomButton from "ui-component/custom-components/CustomButton";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ImageMagnifier from "../../../../ui-component/ImageMagnifier";
import Tooltip from "@mui/material/Tooltip";

const LabReportDocumentContainer = ({ documentIndex }) => {
  const labReportDocument = useSelector(getDocumentByIndex(documentIndex));
  const { documentName, documentId, initialPage, summary, suggestions, patientVitalResponses } =
    labReportDocument;
  const [documentUrl, setDocumentUrl] = useState("");
  const dispatch = useDispatch();
  const { showDocumentPage, showDocumentIndex } = useSelector(getDocumentPageVisible);

  const pdfDocumentPagesRef = useRef({
    pages: [],
    index: null,
    initialIndex: null,
    showPagination: false,
  });

  const getFullDocType = (value) => {
    for (const [key, val] of Object.entries(fileTypes)) {
      if (val === value) {
        return key;
      }
    }
    return null;
  };

  const convertPDFToImage = async (byteArray) => {
    return await PdfToImg(byteArray, { returnType: "buffer" });
  };

  const convertByteArrayIntoUrl = (byteArray, extension) => {
    if (extension === "pdf") {
      extension = "png";
    }
    const mimeType = getFullDocType(extension);
    const blob = new Blob([byteArray], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    return url;
  };

  const convertBase64ToArrayBuffer = (base64) => {
    let binaryString = atob(base64);
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    let bytes = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return arrayBuffer;
  };

  useEffect(() => {
    const getDocumentInfo = async () => {
      const documentInfo = await getPatientDocumentByDocumentId(documentId);
      const base64 = documentInfo.data.patientDocumentResponses[0].documentResponse.document;
      const extension = documentInfo.data.patientDocumentResponses[0].documentResponse.extension;
      const bytes = convertBase64ToArrayBuffer(base64);
      if (extension === "pdf") {
        const documentPages = await convertPDFToImage(bytes);
        setDocumentUrl(convertByteArrayIntoUrl(documentPages[initialPage - 1], extension));
        if (documentPages.length === 1) {
          return;
        }
        pdfDocumentPagesRef.current = {
          pages: documentPages,
          index: initialPage - 1,
          initialIndex: initialPage - 1,
          showPagination: true,
        };
      } else {
        setDocumentUrl(convertByteArrayIntoUrl(bytes, extension));
      }
    };
    getDocumentInfo();
  }, []);

  const handlePagination = () => {
    setDocumentUrl(
      convertByteArrayIntoUrl(
        pdfDocumentPagesRef.current.pages[pdfDocumentPagesRef.current.index],
        "pdf"
      )
    );
  };

  return (
    <Box
      sx={{ p: "1rem" }}
      children={
        <>
          <Typography variant="h4" color="rgb(73, 67, 67)" textAlign={"center"} display={"block"}>
            {documentName}
          </Typography>
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Box sx={{ display: "flex", gap: "1rem" }} flex={"2"}>
              <Grid sx={{ width: "100%" }}>
                {patientVitalResponses.map((patientVitalResponse, index) => {
                  return (
                    <LabReportVitalContainer
                      reportDate={patientVitalResponse.reportDate}
                      documentIndex={documentIndex}
                      key={index}
                    ></LabReportVitalContainer>
                  );
                })}
              </Grid>
            </Box>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              style={{ width: "14rem", height: "18rem", marginTop: "2rem" }}
            >
              <Grid item xs={1} container justifyContent="center" alignItems="center">
                {pdfDocumentPagesRef.current.showPagination && (
                  <CustomButton
                    disabled={pdfDocumentPagesRef.current.index === 0}
                    iconButton={<ArrowBackIosNewRoundedIcon />}
                    onClick={(e) => {
                      const newIndex = pdfDocumentPagesRef.current.index - 1;

                      pdfDocumentPagesRef.current = {
                        ...pdfDocumentPagesRef.current,
                        index: newIndex,
                      };
                      handlePagination();
                    }}
                    sx={{
                      position: "absolute",
                    }}
                  />
                )}
              </Grid>

              <Grid item xs={9}>
                <Box
                  sx={{ flex: 1, cursor: "pointer", textAlign: "center" }}
                  onClick={() =>
                    dispatch(
                      setDocumentPageVisible({
                        showDocumentPage: false,
                        showDocumentIndex: documentIndex,
                      })
                    )
                  }
                >
                  <ImageMagnifier src={`${documentUrl}#toolbar=0`} width={100} />
                  {pdfDocumentPagesRef.current.showPagination && (
                    <div style={{ fontSize: "0.8rem", marginTop: "1rem" }}>
                      {pdfDocumentPagesRef.current.index + 1}/
                      {pdfDocumentPagesRef.current?.pages?.length}
                    </div>
                  )}
                </Box>
              </Grid>

              <Grid item xs={1} container justifyContent="center" alignItems="center">
                {pdfDocumentPagesRef.current.showPagination && (
                  <CustomButton
                    iconButton={<ArrowForwardIosRoundedIcon />}
                    disabled={
                      pdfDocumentPagesRef.current.index ===
                      pdfDocumentPagesRef.current.pages.length - 1
                    }
                    onClick={() => {
                      const newIndex = pdfDocumentPagesRef.current.index + 1;

                      pdfDocumentPagesRef.current = {
                        ...pdfDocumentPagesRef.current,
                        index: newIndex,
                      };
                      handlePagination();
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Box display={"flex"} marginTop={"1rem"}>
              <Typography flex="2" variant="h5" fontWeight={"bold"} width={"100%"}>
                Summary :
              </Typography>
              <Typography flex="16" variant="h6" padding={"0 1rem"}>
                {summary}
              </Typography>
            </Box>
            <Box display={"flex"} marginTop={"1rem"}>
              <Typography flex="2" variant="h5" fontWeight={"bold"} width={"100%"}>
                Suggestions :
              </Typography>
              <Typography flex="16" variant="h6" padding={"0 1rem"}>
                {suggestions}
              </Typography>
            </Box>
          </Box>
        </>
      }
    ></Box>
  );
};

export default LabReportDocumentContainer;
