import {
  FRONT_DESK,
  SUPER_ADMIN,
  DOCTOR,
  CLINIC_ADMIN,
  PATIENT,
  keysForMenu,
  patientKeysForMenu,
  doctorKeysForMenu,
  dashboardKey,
} from "store/constant";

const DashboardRoutes = (features, orgId, currentActiveUser, roleName, list) => {
  let filteredList;
  switch (roleName) {
    // front desk
    case FRONT_DESK:
      filteredList = [
        // dashboardKey,
        doctorKeysForMenu.allAppointment,
        doctorKeysForMenu.allAvailability,
        doctorKeysForMenu.billingFrontDesk,
        keysForMenu.content,
      ];
      break;
    case SUPER_ADMIN:
      filteredList = list;
      // as ProductAndServices page is visible to the clinic admins only to add their products and services
      let tempEntities = list.find((item) => item.id === "DoctorEntities");
      tempEntities.children = tempEntities.children.filter((e) => e.id !== "ProductAndServices");
      break;
    case PATIENT:
      filteredList = [
        dashboardKey,
        patientKeysForMenu().doctorSearch,
        patientKeysForMenu().patientAppointments,
        patientKeysForMenu().ehr,
        keysForMenu.review,
        keysForMenu.content,
      ];
      break;
    case DOCTOR:
      const currentOrgAssociation = currentActiveUser?.userOrganizationAssociationList.filter(
        (el) => {
          return el?.organizationId === orgId;
        }
      )[0];
      let additionalItemsIfClinicAdmin = [];
      if (currentOrgAssociation?.isAdmin) {
        const entities = list.find((item) => item.id === "DoctorEntities");
        additionalItemsIfClinicAdmin = [doctorKeysForMenu.allAvailability, entities];
      } else if (currentOrgAssociation?.isDoctor) {
        additionalItemsIfClinicAdmin = [
          ...[doctorKeysForMenu.doctorAvailability],
          ...additionalItemsIfClinicAdmin,
        ];
      }
      filteredList = [
        ...list.filter((e) => {
          if (["default", "patient"].includes(e?.id)) {
            return true;
          }
          return false;
        }),
        ...additionalItemsIfClinicAdmin,
      ];
      break;
    case CLINIC_ADMIN:
      const entities = list.find((item) => item.id === "DoctorEntities");
      filteredList = [
        dashboardKey,
        entities,
        doctorKeysForMenu.availability,
        doctorKeysForMenu.appointment,
      ];
      break;
    default:
      return filteredList;
  }
  filteredList = filteredList.filter((el) => {
    if (el?.id === "default") {
      return el;
    }
    if (el && Object.hasOwnProperty.call(el, "feature") && features.hasOwnProperty(el.feature)) {
      return el;
    } else {
      if (el && Object.hasOwnProperty.call(el, "children") && el.children.length > 0) {
        const tempChild = el.children.filter((subEl) => {
          if (
            subEl &&
            Object.hasOwnProperty.call(subEl, "feature") &&
            features.hasOwnProperty(subEl.feature)
          ) {
            return subEl;
          } else if (
            subEl &&
            Object.hasOwnProperty.call(subEl, "feature") &&
            subEl.feature === "default"
          ) {
            return subEl;
          }
        });
        el.children = tempChild;
        return el;
      }
    }
  });
  return filteredList;
};

export const profileDashboard = (features, orgId, currentActiveUser, roleName) => {
  let list = getProfileList(orgId, currentActiveUser, roleName);
  if (
    list &&
    Object.hasOwnProperty.call(list, "feature") &&
    features.hasOwnProperty(list.feature)
  ) {
    return list;
  } else {
    if (list && Object.hasOwnProperty.call(list, "children") && list.children.length > 0) {
      const tempChild = list.children.filter((subEl) => {
        if (subEl && Object.hasOwnProperty.call(subEl, "feature")) {
          return features.hasOwnProperty(subEl.feature);
        }
      });
      list.children = tempChild;
      return list;
    }
  }
  return list;
};

const getProfileList = (orgId, currentActiveUser, roleName) => {
  switch (roleName) {
    case PATIENT:
      return {
        id: "Profile",
        title: "Profile",
        url: "/home/patientProfile",
        iconClass: "ri-profile-line ri-xl",
        children: [
          {
            id: "Personal Details",
            title: "Personal Details",
            url: "/home/patientProfile",
            feature: "PATIENT",
          },
          {
            id: "medicalInformation",
            title: "Medical Information",
            url: "/home/patientMedicalInfo",
            feature: "PATIENT",
          },
          {
            id: "lifestyle",
            title: "Lifestyle",
            url: "/home/patientLifestyle",
            feature: "PATIENT",
          },
          {
            id: "patientRecords",
            title: "Patient Records",
            url: "/home/patientRecords",
            feature: "PATIENT",
          },
          {
            id: "ABHA",
            title: "ABHA",
            url: "/home/manageAbhaNumber",
            iconSrc: "/images/hc_aadhaar.svg",
            feature: "ABHA",
          },
          {
            id: "familyDetails",
            title: "Family Details",
            url: "/home/patientFamilyDetails",
            feature: "PATIENT",
          },
          {
            id: "emergencyContact",
            title: "Emergency Contact",
            url: "/home/patientEmergencyContact",
            feature: "PATIENT",
          },
        ],
      };
    case DOCTOR:
      const currentOrgAssociation = currentActiveUser?.userOrganizationAssociationList.filter(
        (el) => {
          return el?.organizationId === orgId;
        }
      )[0];
      let additionalItemsIfClinicAdmin = [];
      if (currentOrgAssociation?.isAdmin) {
        additionalItemsIfClinicAdmin = [
          {
            id: "Clinic Details",
            title: "Clinic Details",
            url: "/home/manageOrganization",
            state: { organizationId: orgId },
            feature: "ORGANIZATION",
          },
          {
            id: "Subscriptions",
            title: "Subscriptions",
            url: "/home/userSubscriptions",
            feature: "ORGANIZATION",
          },
        ];
      }
      return {
        id: "Profile",
        title: "Profile",
        url: "/home/doctorProfile",
        iconClass: "ri-profile-line ri-xl",
        children: [
          {
            id: "Personal Details",
            title: "Personal Details",
            url: "/home/doctorProfile",
            feature: "DOCTOR",
          },
          {
            id: "Professional Details",
            title: "Professional Details",
            url: "/home/doctorProfessionalDetails",
            feature: "DOCTOR",
          },
          ...additionalItemsIfClinicAdmin,
          {
            id: "Review",
            title: "Review",
            url: "/home/reviewList",
            feature: "REVIEW",
          },
          {
            id: "Templates",
            title: "Templates",
            type: "item",
            url: "/home/Templates",
            breadcrumbs: false,
            feature: "DOCTOR",
          },
          {
            id: "RemoveData",
            title: "Erase All Data",
            type: "item",
            breadcrumbs: false,
            feature: "DOCTOR",
          },
        ],
      };
    case CLINIC_ADMIN:
      return {
        id: "Profile",
        title: "Profile",
        url: "/home/updateProfile",
        iconClass: "ri-profile-line ri-xl",
        children: [
          {
            id: "Personal Details",
            title: "Personal Details",
            url: "/home/updateProfile",
            feature: "USER",
          },
          {
            id: "Clinic Details",
            title: "Clinic Details",
            url: "/home/manageOrganization",
            feature: "ORGANIZATION",
          },
        ],
      };
    default:
      return {
        id: "Profile",
        title: "Profile",
        url: "/home/updateProfile",
        iconSrc: "/images/hc_disease.svg",
        feature: "USER",
      };
  }
};
export default DashboardRoutes;
