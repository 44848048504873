import { ArrowBackIos } from "@mui/icons-material";
import CustomButton from "./CustomButton";
import { useNavigate } from "react-router";

const CustomBackButton = () => {
  const navigate = useNavigate();

  return (
    <CustomButton
      className={"btn--primary-light"}
      iconButton={<ArrowBackIos fontSize="small" />}
      onClick={() => {
        navigate("/home/dashboard");
      }}
      style={{ borderRadius: "17px", padding: "0px 3px 0px 11px", marginRight: "8px" }}
    />
  );
};

export default CustomBackButton;
