import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  medicationsArr: [],
  symptomsArr: [],
  diagnosisArr: [],
  labInvestigationsArr: [],
  advicesArr: [],
  prescriptionId: null,
  soapNotes: {},
  patientPastPrescriptions: {
    medicationsArr: [],
    symptomsArr: [],
    diagnosisArr: [],
    labInvestigationsArr: [],
    advicesArr: [],
    soapNotes: {},
  },
  hasUpdates: false,
};

const prescriptionPadSlice = createSlice({
  name: "prescriptionPad",
  initialState,
  reducers: {
    setPrescriptionPadData(state, action) {
      state.medicationsArr = action.payload.medicationsArr;
      state.symptomsArr = action.payload.symptomsArr;
      state.diagnosisArr = action.payload.diagnosisArr;
      state.labInvestigationsArr = action.payload.labInvestigationsArr;
      state.advicesArr = action.payload.advicesArr;
      state.soapNotes = action.payload.soapNotes;
    },
    updateMedications(state, action) {
      state.medicationsArr = state.medicationsArr.map((item, i) =>
        i === action.payload.index
          ? { ...item, [action.payload.field]: action.payload.value }
          : item
      );
    },
    setMedications(state, action) {
      state.medicationsArr = action.payload;
    },
    setSymptoms(state, action) {
      state.symptomsArr = state.symptomsArr?.map((item, i) =>
        i === action.payload.index
          ? { ...item, [action.payload.field]: action.payload.value }
          : item
      );
    },
    setDiagnosis(state, action) {
      state.diagnosisArr = state.diagnosisArr.map((item, i) =>
        i === action.payload.index
          ? { ...item, [action.payload.field]: action.payload.value }
          : item
      );
    },
    setLabInvestigations(state, action) {
      state.labInvestigationsArr = state.labInvestigationsArr.map((item, i) =>
        i === action.payload.index
          ? { ...item, [action.payload.field]: action.payload.value }
          : item
      );
    },
    updateAdvices(state, action) {
      state.advicesArr = state.advicesArr.map((item, i) =>
        i === action.payload.index ? { ...item, displayName: action.payload.value } : item
      );
    },
    setAdvices(state, action) {
      state.advicesArr = action.payload;
    },
    setPrescriptionId(state, action) {
      state.prescriptionId = action.payload;
    },
    setSoapNotes(state, action) {
      state.soapNotes = action.payload;
    },
    setPatientPastPrescriptions(state, action) {
      state.patientPastPrescriptions = action.payload;
    },
    setHasUpdates(state, action) {
      state.hasUpdates = action.payload;
    },
  },
});

export const {
  setPrescriptionPadData,
  updateMedications,
  setMedications,
  setSymptoms,
  setDiagnosis,
  setLabInvestigations,
  updateAdvices,
  setAdvices,
  setPrescriptionId,
  setSoapNotes,
  setPatientPastPrescriptions,
  setHasUpdates,
} = prescriptionPadSlice.actions;

export const getPrescriptionPadData = (state) => state.prescriptionPad;
export const getFieldData = (state, key) => state.prescriptionPad[key];
export const getSymptoms = (state) => state.prescriptionPad.symptomsArr;
export const getDiagnosis = (state) => state.prescriptionPad.diagnosisArr;
export const getLabInvestigations = (state) => state.prescriptionPad.labInvestigationsArr;
export const getAdvices = (state) => state.prescriptionPad.advicesArr;
export const getPrescriptionId = (state) => state.prescriptionPad.prescriptionId;
export const getMedications = (state) => state.prescriptionPad.medicationsArr;
export const getSoapNotes = (state) => state.prescriptionPad.soapNotes;
export const getPatientPastPrescriptions = (state) =>
  state.prescriptionPad.patientPastPrescriptions;
export const getHasUpdates = (state) => state.prescriptionPad.hasUpdates;

export const prescriptionPadReducer = prescriptionPadSlice.reducer;
