import { NavigateBefore, NavigateNext, Numbers } from "@mui/icons-material";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { api } from "services/AxiosInterceptor";
import { detailedInformation, getMasterData, getSpecialities } from "services/HfrService";
import { orgId } from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import Reveal from "views/utilities/Reveal";

const HfrDetailedInfo = () => {
  const { handleClick } = useContext(ToastContext);
  const state = useLocation();
  const systemOfMedicine = state?.state?.systemOfMedicine;
  const [specialities, setSpecialities] = useState();
  const [allSystemOfMed, setAllSystemOfMed] = useState([]);

  const fetchData = async () => {
    const allSystemOfMedicine = await getMasterData("Medicine");
    setAllSystemOfMed(allSystemOfMedicine?.data?.data);
  };
  const [postSpecs, setPostSpecs] = useState({});
  const [detailedInfo, setDetailedInfo] = useState({});
  const organizationId = orgId();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/api/abdm/hfr/onboarding?orgId=${organizationId}`);
        setDetailedInfo(response?.data?.facilityDetailedInformationRequest);
      } catch (error) {
        console.error("Can not get form status");
      }
    };

    fetchData();
  }, [handleClick, organizationId]);

  useEffect(() => {
    let postSpec = {};
    systemOfMedicine?.map((e) => {
      const obj = {
        systemOfMedicineCode: e,
        isSpecializationAvalaible: "Y",
        specialities: [],
      };
      postSpec[e] = obj;
      return null;
    });
    setPostSpecs(postSpec);
  }, [systemOfMedicine]);

  useEffect(() => {
    const fetchData = async (value) => {
      const postData = {
        systemOfMedicineCode: value,
      };
      const response = await getSpecialities(postData);
      const data = response?.data?.data;
      setSpecialities((prevDataObject) => ({
        ...prevDataObject,
        [value]: data,
      }));
    };

    systemOfMedicine?.map((value) => {
      fetchData(value);
      return null;
    });
  }, [systemOfMedicine]);

  useEffect(() => {
    fetchData();
  }, []);

  const goBack = () => {
    navigate("/home/hfrAdditionalInfo");
  };

  const navigate = useNavigate();
  return (
    <Reveal>
      <Formik
        enableReinitialize={true}
        initialValues={{
          trackingId: state?.state?.trackingId,
          specialities: [],
          medicalInfrastructure: {
            countHDUBedsWithFunctionalVentilators: detailedInfo?.medicalInfrastructure
              ?.countHDUBedsWithFunctionalVentilators
              ? detailedInfo?.medicalInfrastructure?.countHDUBedsWithFunctionalVentilators
              : "0",
            countIPDBedsWithoutOxygen: detailedInfo?.medicalInfrastructure
              ?.countIPDBedsWithoutOxygen
              ? detailedInfo?.medicalInfrastructure?.countIPDBedsWithoutOxygen
              : "0",
            countIPDBedsWithOxygen: detailedInfo?.medicalInfrastructure?.countIPDBedsWithOxygen
              ? detailedInfo?.medicalInfrastructure?.countIPDBedsWithOxygen
              : "0",
            countICUBedsWithVentilators: detailedInfo?.medicalInfrastructure
              ?.countICUBedsWithVentilators
              ? detailedInfo?.medicalInfrastructure?.countICUBedsWithVentilators
              : "0",
            countICUBedsWithoutVentilators: detailedInfo?.medicalInfrastructure
              ?.countICUBedsWithoutVentilators
              ? detailedInfo?.medicalInfrastructure?.countICUBedsWithoutVentilators
              : "0",
            countHDUBedsWithVentilators: detailedInfo?.medicalInfrastructure
              ?.countHDUBedsWithVentilators
              ? detailedInfo?.medicalInfrastructure?.countHDUBedsWithVentilators
              : "0",
            countHDUBedsWithoutVentilators: detailedInfo?.medicalInfrastructure
              ?.countHDUBedsWithoutVentilators
              ? detailedInfo?.medicalInfrastructure?.countHDUBedsWithoutVentilators
              : "0",
            totalNumberOfVentilators: detailedInfo?.medicalInfrastructure?.totalNumberOfVentilators
              ? detailedInfo?.medicalInfrastructure?.totalNumberOfVentilators
              : "0",
            countDayCareBedsWithoutOxygen: detailedInfo?.medicalInfrastructure
              ?.countDayCareBedsWithoutOxygen
              ? detailedInfo?.medicalInfrastructure?.countDayCareBedsWithoutOxygen
              : "0",
            countDayCareBedsWithOxygen: detailedInfo?.medicalInfrastructure
              ?.countDayCareBedsWithOxygen
              ? detailedInfo?.medicalInfrastructure?.countDayCareBedsWithOxygen
              : "0",
            countDentalChairs: detailedInfo?.medicalInfrastructure?.countDentalChairs
              ? detailedInfo?.medicalInfrastructure?.countDentalChairs
              : "0",
            totalNumberOfBeds: detailedInfo?.medicalInfrastructure?.totalNumberOfBeds
              ? detailedInfo?.medicalInfrastructure?.totalNumberOfBeds
              : "0",
          },
          pharmacyDetails: {
            isJanAushadhiKendra: "",
            janAushadhiKendraId: "",
            drugLicenseNumber: "",
            pharmacyGstinNumber: "",
            pharmacistRegistrationNumber: "",
          },
          bloodBankDetails: {
            isFacilityRegisteredInERaktkosh: "",
            bloodBankLicenseNumber: "",
            bloodStorageCenters: "",
            storageCentersCount: 0,
            bloodCollectedPerAnnum: "",
            bloodRequiredPerAnnum: "",
            eRaktoshId: "",
          },
          imagingServices: [
            {
              service: "",
              count: 0,
            },
          ],
          diagnosticServices: [],
        }}
        onSubmit={async (values) => {
          try {
            const totalBeds =
              parseInt(values?.medicalInfrastructure?.countHDUBedsWithFunctionalVentilators) +
              parseInt(values?.medicalInfrastructure?.countIPDBedsWithoutOxygen) +
              parseInt(values?.medicalInfrastructure?.countIPDBedsWithOxygen) +
              parseInt(values?.medicalInfrastructure?.countICUBedsWithVentilators) +
              parseInt(values?.medicalInfrastructure?.countICUBedsWithoutVentilators) +
              parseInt(values?.medicalInfrastructure?.countHDUBedsWithVentilators) +
              parseInt(values?.medicalInfrastructure?.countHDUBedsWithoutVentilators) +
              parseInt(values?.medicalInfrastructure?.countDayCareBedsWithoutOxygen) +
              parseInt(values?.medicalInfrastructure?.countDayCareBedsWithOxygen);
            const totalVentilators =
              parseInt(values?.medicalInfrastructure?.countHDUBedsWithFunctionalVentilators) +
              parseInt(values?.medicalInfrastructure?.countICUBedsWithVentilators) +
              parseInt(values?.medicalInfrastructure?.countHDUBedsWithVentilators);

            const postdata = {
              ...values,
              specialities: Object.values(postSpecs),
              medicalInfrastructure: {
                ...values.medicalInfrastructure,
                totalNumberOfVentilators: totalVentilators,
                totalNumberOfBeds: totalBeds,
              },
            };

            await detailedInformation(organizationId, postdata);
            navigate("/home/hfrSubmit", {
              state: {
                trackingId: values?.trackingId,
              },
            });
            handleClick("success", "Detailed Information has been successfully updated.");
          } catch (error) {
            handleClick("error", error.response.data.message);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <Box>
            <form onSubmit={handleSubmit}>
              <h2>Register Your Health Facility (HFR)</h2>
              <Reveal className="otp-container" style={{ width: "auto" }}>
                <h3>Services / Specialization</h3>

                {specialities &&
                  Object.entries(specialities)?.map(([cat, item]) => {
                    return (
                      <Grid container spacing={2}>
                        <FormControl component="fieldset" sx={{ width: "100%", ml: 2 }}>
                          <Typography
                            sx={{
                              fontWeight: "500",
                              marginTop: "50px",
                              marginBottom: "20px",
                            }}
                          >
                            {allSystemOfMed?.filter((s) => s?.code === cat)[0]?.value}
                          </Typography>

                          <FormGroup
                            row
                            name={cat}
                            onChange={(e) => {
                              if (e.target.checked) {
                                let postSoecsTemp = { ...postSpecs };
                                let category = postSoecsTemp[cat];
                                category.specialities.push(e.target.value);
                                setPostSpecs(postSoecsTemp);
                              } else {
                                let postSoecsTemp = { ...postSpecs };
                                let category = postSoecsTemp[cat];
                                category.specialities = category.specialities.filter((el) => {
                                  return el !== e.target.defaultValue;
                                });
                                setPostSpecs(postSoecsTemp);
                              }
                            }}
                          >
                            {item?.map((el) => {
                              return (
                                <Grid size={{ xs: 6 }}>
                                  <FormControlLabel
                                    key={el?.code}
                                    control={<Checkbox />}
                                    label={el?.value}
                                    value={el?.code}
                                  />
                                </Grid>
                              );
                            })}
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    );
                  })}
              </Reveal>

              <Reveal className="otp-container" style={{ width: "auto" }}>
                <div>
                  <h3> Medical Infrastructure</h3>
                  <span>
                    (The total number of beds and total number of ventilators is calculated
                    automatically based on the details provided in below columns for IPD, ICU And
                    HDU beds. If left blank, default value for total number of ventilators is 0.)
                  </span>
                </div>

                <FormControl sx={{ width: "47%", mr: 3, mt: 3 }}>
                  <FormInputField
                    id="outlined-adornment-mobilenumber"
                    type="tel"
                    value={values?.medicalInfrastructure?.countIPDBedsWithoutOxygen}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "medicalInfrastructure.countIPDBedsWithoutOxygen",
                        e.target.value
                      );
                    }}
                    label="Number of IPD Beds (except ICU beds) without Oxygen Facility"
                    startAdornment={<Numbers />}
                  />
                </FormControl>
                <FormControl sx={{ width: "46%", mt: 3 }}>
                  <FormInputField
                    id="outlined-adornment-email"
                    type="tel"
                    value={values?.medicalInfrastructure?.countIPDBedsWithOxygen}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("medicalInfrastructure.countIPDBedsWithOxygen", e.target.value);
                    }}
                    label="Number of IPD Beds (except ICU beds) with Oxygen Facility"
                    startAdornment={<Numbers />}
                  />
                </FormControl>
                <FormControl sx={{ width: "47%", mr: 3, mt: 3 }}>
                  <FormInputField
                    id="outlined-adornment-mobilenumber"
                    type="tel"
                    value={values?.medicalInfrastructure?.countICUBedsWithVentilators}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "medicalInfrastructure.countICUBedsWithVentilators",
                        e.target.value
                      );
                    }}
                    label="Number of ICU beds with Ventilators"
                    startAdornment={<Numbers />}
                  />
                </FormControl>
                <FormControl sx={{ width: "46%", mt: 3 }}>
                  <FormInputField
                    id="outlined-adornment-email"
                    type="tel"
                    value={values?.medicalInfrastructure?.countICUBedsWithoutVentilators}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "medicalInfrastructure.countICUBedsWithoutVentilators",
                        e.target.value
                      );
                    }}
                    label="Number of ICU beds without Ventilators"
                    startAdornment={<Numbers />}
                  />
                </FormControl>
                <FormControl sx={{ width: "47%", mr: 3, mt: 3 }}>
                  <FormInputField
                    id="outlined-adornment-mobilenumber"
                    type="tel"
                    value={values?.medicalInfrastructure?.countHDUBedsWithVentilators}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "medicalInfrastructure.countHDUBedsWithVentilators",
                        e.target.value
                      );
                    }}
                    label="Number of HDU beds with ventilators"
                    startAdornment={<Numbers />}
                  />
                </FormControl>
                <FormControl sx={{ width: "46%", mt: 3 }}>
                  <FormInputField
                    id="outlined-adornment-email"
                    type="tel"
                    value={values?.medicalInfrastructure?.countHDUBedsWithoutVentilators}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "medicalInfrastructure.countHDUBedsWithoutVentilators",
                        e.target.value
                      );
                    }}
                    label="Number of HDU beds without Ventilators"
                    startAdornment={<Numbers />}
                  />
                </FormControl>
                <FormControl sx={{ width: "47%", mr: 3, mt: 3 }}>
                  <FormInputField
                    id="outlined-adornment-mobilenumber"
                    type="tel"
                    value={values?.medicalInfrastructure?.countDayCareBedsWithoutOxygen}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "medicalInfrastructure.countDayCareBedsWithoutOxygen",
                        e.target.value
                      );
                    }}
                    label="Number of Daycare beds without O2 facility"
                    startAdornment={<Numbers />}
                  />
                </FormControl>
                <FormControl sx={{ width: "46%", mt: 3 }}>
                  <FormInputField
                    id="outlined-adornment-email"
                    type="tel"
                    value={values?.medicalInfrastructure?.countDayCareBedsWithOxygen}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "medicalInfrastructure.countDayCareBedsWithOxygen",
                        e.target.value
                      );
                    }}
                    label="Number of Daycare beds with O2 facility"
                    startAdornment={<Numbers />}
                  />
                </FormControl>
                <FormControl sx={{ width: "47%", mr: 3, mt: 3 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-mobilenumber"
                    type="tel"
                    value={(
                      parseInt(
                        values?.medicalInfrastructure?.countHDUBedsWithFunctionalVentilators
                      ) +
                      parseInt(values?.medicalInfrastructure?.countIPDBedsWithoutOxygen) +
                      parseInt(values?.medicalInfrastructure?.countIPDBedsWithOxygen) +
                      parseInt(values?.medicalInfrastructure?.countICUBedsWithVentilators) +
                      parseInt(values?.medicalInfrastructure?.countICUBedsWithoutVentilators) +
                      parseInt(values?.medicalInfrastructure?.countHDUBedsWithVentilators) +
                      parseInt(values?.medicalInfrastructure?.countHDUBedsWithoutVentilators) +
                      parseInt(values?.medicalInfrastructure?.countDayCareBedsWithoutOxygen) +
                      parseInt(values?.medicalInfrastructure?.countDayCareBedsWithOxygen)
                    )?.toString()}
                    onBlur={handleBlur}
                    label="Total Number of Beds"
                    startAdornment={<Numbers />}
                  />
                </FormControl>
                <FormControl sx={{ width: "46%", mt: 3 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-email"
                    type="tel"
                    value={(
                      parseInt(
                        values?.medicalInfrastructure?.countHDUBedsWithFunctionalVentilators
                      ) +
                      parseInt(values?.medicalInfrastructure?.countICUBedsWithVentilators) +
                      parseInt(values?.medicalInfrastructure?.countHDUBedsWithVentilators)
                    )?.toString()}
                    onBlur={handleBlur}
                    label="Total number of Ventilators"
                    startAdornment={<Numbers />}
                  />
                </FormControl>
                <FormControl sx={{ width: "47%", mr: 3, mt: 3 }}>
                  <FormInputField
                    id="outlined-adornment-mobilenumber"
                    type="tel"
                    value={values?.medicalInfrastructure?.countDentalChairs}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("medicalInfrastructure.countDentalChairs", e.target.value);
                    }}
                    label="Number of Dental Chairs"
                    startAdornment={<Numbers />}
                  />
                </FormControl>
              </Reveal>

              <Reveal style={{ width: "auto", marginTop: "20px", display: "flex" }}>
                <CustomButton
                  label="Previous"
                  style={{ marginRight: "auto" }}
                  className="btn--primary"
                  startIcon={<NavigateBefore />}
                  onClick={goBack}
                />
                <CustomButton
                  label="Next"
                  style={{ marginLeft: "auto" }}
                  className="btn--primary"
                  endIcon={<NavigateNext />}
                  type="submit"
                />
              </Reveal>
            </form>
          </Box>
        )}
      </Formik>
    </Reveal>
  );
};

export default HfrDetailedInfo;
