import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useContext } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { DescriptionOutlined, DriveFileRenameOutlineOutlined } from "@mui/icons-material";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { updateAllergies } from "services/EntitiesServices";
import { allergyValidation } from "../../Common/ValidationSchema/allergyValidation";
import { Formik } from "formik";

const UpdateAllergy = ({ selected, close }) => {
  const { handleClick } = useContext(ToastContext);

  const updateAllergy = async (values) => {
    if (selected) {
      const payload = {
        id: selected.id,
        name: values.allergyName,
        description: values.allergyDescription,
      };
      try {
        await updateAllergies(selected.id, payload);
        handleClick("success", "Allergy has been successfully updated!");
        close();
      } catch (e) {
        handleClick("error", "There seems to be some error updating the allergy.");
      }
    }
  };

  return (
    <Box
      flex="1"
      alignSelf={"center"}
      minHeight="18vh"
      p={3}
      borderRadius={3}
      bgcolor={"background.default"}
      color={"text.primary"}
    >
      <Typography variant="h4" textAlign="center">
        Update Allergy
      </Typography>
      <Formik
        validateOnMount={true}
        initialValues={{
          allergyName: selected.name,
          allergyDescription: selected.description ? selected?.description : "",
        }}
        validationSchema={allergyValidation}
        onSubmit={async (values, { resetForm }) => {
          updateAllergy(values);
          resetForm();
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <>
            <Grid container spacing={2} sx={{ maxWidth: "350px", marginTop: "5px" }}>
              <Grid size={{ xs: 12 }}>
                <FormInputField
                  label={"Allergy Name"}
                  value={values.allergyName}
                  name={"allergyName"}
                  inputProps={{ maxLength: 50 }}
                  error={Boolean(touched.allergyName && errors.allergyName)}
                  errorText={errors.allergyName}
                  onChange={handleChange}
                  startAdornment={<DriveFileRenameOutlineOutlined />}
                  size="small"
                />
              </Grid>
              <Grid size={{ xs: 12 }} sx={{ marginTop: "10px" }}>
                <FormInputField
                  label={"Allergy Description"}
                  value={values.allergyDescription}
                  name={"allergyDescription"}
                  inputProps={{ maxLength: 255 }}
                  error={Boolean(touched.allergyDescription && errors.allergyDescription)}
                  errorText={errors.allergyDescription}
                  onChange={handleChange}
                  startAdornment={<DescriptionOutlined />}
                  size="small"
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 3 }} display="flex" justifyContent="center" alignItems="center">
              <CustomButton className="btn--primary" onClick={handleSubmit} label="Update" />
            </Box>
          </>
        )}
      </Formik>
    </Box>
  );
};

export default UpdateAllergy;
