import * as Yup from "yup";

const abhaAddressSchema = Yup.string()
  .label("")
  .required("ABHA address is required.")
  .min(8, "ABHA address must be at least 8 characters long.")
  .max(18, "ABHA address cannot exceed 18 characters.")
  .matches(/[a-zA-Z]/, "ABHA address must contain at least one letter.")
  .matches(/\d/, "ABHA address must contain at least one number.")
  .matches(/^[a-zA-Z0-9._]+$/, "ABHA address can only contain letters, numbers, '.' or '_'.")
  .matches(
    /^[a-zA-Z0-9]+([._][a-zA-Z0-9]+)*$/,
    "Special characters ('.' or '_') must be between other characters, not at the start or end."
  )
  .test("one-special-character", "ABHA address can only include one '.' or one '_'.", (value) => {
    if (!value) return true;
    const dotCount = (value.match(/\./g) || []).length;
    const underscoreCount = (value.match(/_/g) || []).length;
    return dotCount <= 1 && underscoreCount <= 1;
  });

export default abhaAddressSchema;
