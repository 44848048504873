import "assets/scss/custom-charts.scss";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend } from "chart.js";
import { useEffect, useState } from "react";
import { padding } from "@mui/system";

ChartJS.register(Title, Tooltip, Legend);

const CustomLineChart = ({
  displayName,
  parentDivStyle,
  graphStyle,
  hidePoints,
  title,
  values,
  labels,
  yAxisRange,
  xAxisLabel,
  yAxisLabel,
  customOptions,
  noHeading,
}) => {
  const [sortedLabels, setSortedLabels] = useState([]);
  const [sortedValues, setSortedValues] = useState([]);

  useEffect(() => {
    if (values && labels) {
      const parseDateString = (dateString) => {
        const [day, month, year] = dateString.split("/");
        return new Date(`20${year}`, month - 1, day);
      };

      const sortedData = labels
        ?.map((label, index) => ({
          label: label,
          values: values.map((dataset) => dataset.values[index]),
        }))
        .sort((a, b) => parseDateString(a.label) - parseDateString(b.label));

      const sortedLabels = sortedData.map((item) => item.label);
      setSortedLabels(sortedLabels);
      const sortedValues = values.map((dataset, index) => ({
        ...dataset,
        values: sortedData.map((item) => item.values[index]),
      }));
      setSortedValues(sortedValues);
    }
  }, [values, labels]);

  const data = {
    labels:
      sortedLabels?.length > 0 ? sortedLabels : Array(sortedValues[0]?.values?.length).fill(""),
    datasets: sortedValues.map((dataset, index) => ({
      label: dataset.label || `Line ${index + 1}`,
      data: dataset.values,
      borderColor: dataset.lineColor || `rgba(75, 192, 192, 1)`,
      borderWidth: 1,
      pointRadius: hidePoints ? 0 : 2,
      backgroundColor:
        dataset.pointBackgroundColor || dataset.lineColor || `rgba(75, 192, 192, 0.2)`,
      tension: 0.4,
    })),
  };

  const defaultOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisLabel || "", // X-Axis label
        },
        labels: sortedLabels,
        grid: {
          color: "#B5BBC233",
          lineWidth: 1,
          drawTicks: false,
        },
        ticks: {
          display: true,
          padding: 10,
        },
      },
      y: {
        title: {
          display: true,
          text: yAxisLabel || "", // Y-Axis label
        },
        grid: {
          color: "#B5BBC233",
          borderDash: [4, 4],
          lineWidth: 1,
          drawTicks: false,
        },
        ticks: {
          display: true,
          stepSize: yAxisRange?.stepSize,
          autoSkip: false,
          padding: 10,
        },
        min: yAxisRange?.updatedMin,
        max: yAxisRange?.updatedMax,
        beginAtZero: false,
      },
    },
    plugins: {
      legend: {
        display: sortedValues?.length > 1 ? true : false,
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    elements: {
      line: {
        borderJoinStyle: "round",
      },
      point: {
        radius: 4,
        // backgroundColor: lineColor,
      },
    },
    maintainAspectRatio: false,
  };

  const options = { ...defaultOptions, ...customOptions };

  return (
    <div className="custom-bar-chart" style={{ ...parentDivStyle }}>
      {!noHeading && (
        <div className="heading">
          <div className="title">
            <p>{displayName}</p>
          </div>
          <div className="actions"></div>
        </div>
      )}
      <div className="main-chart">
        <Line style={{ ...graphStyle }} data={data} options={options} />
      </div>
      {/* <div className="footer"></div> */}
    </div>
  );
};

export default CustomLineChart;
