import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { DATE_TIME_FORMAT } from "store/constant";

const initialState = {
  mosaicTree: null,
  isDashboardEditMode: false,
  isMosaicEditable: {
    value: false,
    timestamp: dayjs().format(DATE_TIME_FORMAT),
  },
  mosaicId: null,
  dynamicDefaultDashboard: null,
};

const mosaicSlice = createSlice({
  name: "mosaic",
  initialState,
  reducers: {
    setMosaicTree: (state, action) => {
      state.mosaicTree = action.payload;
    },
    setIsMosaicEditable: (state, action) => {
      state.isMosaicEditable = {
        value: action.payload,
        timestamp: dayjs().format(DATE_TIME_FORMAT),
      };
    },
    setMosaicId: (state, action) => {
      state.mosaicId = action.payload;
    },
    setDynamicDefaultDashboard: (state, action) => {
      state.dynamicDefaultDashboard = action.payload;
    },
    setIsDashboardEditMode: (state, action) => {
      state.isDashboardEditMode = action.payload;
    },
  },
});

export const {
  setMosaicTree,
  setIsMosaicEditable,
  setMosaicId,
  setDynamicDefaultDashboard,
  setIsDashboardEditMode,
} = mosaicSlice.actions;

export const getMosaicTree = (state) => state.doctorDashboard.mosaic.mosaicTree;

export const getIsMosaicEditable = (state) => state.doctorDashboard.mosaic.isMosaicEditable;
export const getMosaicId = (state) => state.doctorDashboard.mosaic.mosaicId;
export const getDynamicDefaultDashboard = (state) =>
  state.doctorDashboard.mosaic.dynamicDefaultDashboard;
export const getIsDashboardEditMode = (state) => state.doctorDashboard.mosaic.isDashboardEditMode;

export const MosaicReducer = mosaicSlice.reducer;
