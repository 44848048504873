import { Autocomplete, debounce, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CATEGORY, GENERIC } from "store/constant";
import { getPrescriptionPadData, setPrescriptionPadData } from "store/Slices/prescriptionPadSlice";
import { useCallback } from "react";
import { searchPrescription } from "services/PrescriptionsService";
import { useRef } from "react";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";

const StyledSpan = styled("span")(({ theme }) => ({
  backgroundColor: "transparent",
  borderRadius: "0px",
  padding: "0px",
  paddingTop: "5px !important",
  paddingBottom: "5px !important",
  fontFamily: '"IBM Plex Mono"',
  fontStyle: "normal",
  lineHeight: "18px",
  fontWeight: 500,
  border: "none",
  width: "auto",
  fontSize: "14px",
  "@media (max-width: 1536px)": {
    fontSize: "13px",
  },
  "@media (max-width: 1200px)": {
    fontSize: "12px",
  },
}));

const PrescriptionSearchCard = ({
  value,
  searchType = CATEGORY,
  categoryType,
  classname,
  isEditable = false,
  isTeleconsultation = false,
}) => {
  const dispatch = useDispatch();
  const currentPrescription = useSelector(getPrescriptionPadData);
  const [suggestions, setSuggestions] = useState([]);
  // input value consists the complete input value in textfield
  const [inputValue, setInputValue] = useState(value?.displayName || "");
  const [highlightedOption, setHighlightedOption] = useState(null);
  const selectedItemRef = useRef({});
  const isSuggestionRef = useRef(true);
  const inputRef = useRef(null);
  const [readOnly, setReadOnly] = useState(true);

  // filter function to pass to autocomplete, disable items already in prescription pad
  const filterOptions = (options, { inputValue }) => {
    inputValue = inputValue.trim();
    let updatedOptions = options;

    updatedOptions = updatedOptions.map((option) => {
      return {
        ...option,
        disabled:
          currentPrescription.diagnosisArr.some(
            (diagnosis) =>
              diagnosis.diagnosisId === option.id && diagnosis.category === option.category
          ) ||
          currentPrescription.medicationsArr.some(
            (medication) =>
              medication.medicationId === option.id && medication.category === option.category
          ) ||
          currentPrescription.symptomsArr.some(
            (symptom) => symptom.symptomId === option.id && symptom.category === option.category
          ) ||
          currentPrescription.labInvestigationsArr.some(
            (lab) => lab.labTestId === option.id && lab.category === option.category
          ),
      };
    });
    return updatedOptions;
  };

  // keep track of highlighted option on cursor/key move
  const handleHighlightChange = (event, option, reason) => {
    setHighlightedOption(option);
  };

  // search and filter suggestions based on category
  const prescriptionSearch = async (param, filterCategory) => {
    try {
      const searchResponse = await searchPrescription(param, filterCategory?.displayName, true);
      let filteredSuggestions = searchResponse.data;

      if (categoryType && searchType === CATEGORY) {
        filteredSuggestions = filteredSuggestions.filter(
          (item) => item.category === filterCategory
        );
      }

      let extractedData;
      extractedData = filteredSuggestions.map((item) => {
        const parsedItem = JSON.parse(item.additionalInfo);
        return {
          id: item.id,
          category: item.category,
          displayName: parsedItem?.displayName,
          manufacturer: parsedItem?.manufacturer,
          activeIngredient: parsedItem?.activeIngredient,
        };
      });
      setSuggestions([...extractedData]);
    } catch (error) {
      console.error("API issue!");
    }
  };

  const prescriptionSearchDebounce = useCallback(debounce(prescriptionSearch, 500), []);

  useEffect(() => {
    if (!readOnly) {
      setSuggestions([]);
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(inputValue.length, inputValue.length);
        prescriptionSearchDebounce(inputValue, categoryType);
      }
    }
  }, [readOnly]);

  // make field editable on double click
  const handleDoubleClick = () => {
    isEditable && setReadOnly(false);
  };

  // search items based on input (minimum 3 characters)
  const handleCustomSearch = async (event, newInputValue, reason) => {
    if (reason === "reset") {
      setInputValue(value?.displayName);
      setSuggestions([]);
      return;
    }

    setInputValue(newInputValue);
    var param = newInputValue.trim();
    if (param !== "" && param.length >= 3) {
      prescriptionSearchDebounce(param, categoryType);
    } else {
      setSuggestions([]);
    }
  };

  // set the id and display name of new item selected, and rest data same
  const handleChangePrescriotionItem = () => {
    let diagnosisArr = [...currentPrescription.diagnosisArr];
    let symptomsArr = [...currentPrescription.symptomsArr];
    let medicationsArr = [...currentPrescription.medicationsArr];
    let labInvestigationsArr = [...currentPrescription.labInvestigationsArr];
    switch (categoryType) {
      case "DX":
        diagnosisArr = diagnosisArr.filter(
          (diagnosis) => diagnosis.diagnosisId !== value.diagnosisId
        );
        diagnosisArr.push({
          ...value,
          diagnosisId: selectedItemRef.current.id,
          displayName: selectedItemRef.current.displayName,
        });
        break;
      case "CC":
        symptomsArr = symptomsArr.filter((symptom) => symptom.symptomId !== value.symptomId);

        symptomsArr.push({
          ...value,
          symptomId: selectedItemRef.current.id,
          displayName: selectedItemRef.current.displayName,
        });

        break;
      case "RX":
        medicationsArr = medicationsArr.filter(
          (medication) => medication.medicationId !== value.medicationId
        );
        medicationsArr.push({
          ...value,
          medicationId: selectedItemRef.current.id,
          displayName: selectedItemRef.current.displayName,
        });

        break;
      case "LAB":
        labInvestigationsArr = labInvestigationsArr.filter(
          (lab) => lab.labTestId !== value.labTestId
        );
        labInvestigationsArr.push({
          ...value,
          labTestId: selectedItemRef.current.id,
          displayName: selectedItemRef.current.displayName,
        });
        break;
      default:
        break;
    }
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        diagnosisArr,
        symptomsArr,
        medicationsArr,
        labInvestigationsArr,
      })
    );
  };

  const handleBlur = (event) => {
    setReadOnly(true);
    setInputValue(selectedItemRef.current?.displayName || value?.displayName);
  };

  const handleSelectItem = (event) => {
    // do nothing when option/suggestion is disabled, keep the already selected item
    if (highlightedOption && highlightedOption.disabled) {
      setInputValue(value?.displayName);
      setReadOnly(true);
      return;
    }

    // select the highlighted option from suggestions
    if (isSuggestionRef.current) {
      selectedItemRef.current = highlightedOption;
    }

    // if enter pressed for a new suggestion/option
    if (selectedItemRef.current && isSuggestionRef.current) {
      setInputValue(selectedItemRef.current?.displayName);
      setReadOnly(true);
      handleChangePrescriotionItem();
    }
  };

  const handleKeyPress = (event) => {
    inputValue.trim();
    if (event.key === "Enter") {
      event.stopPropagation();
      handleSelectItem(event);
    }
  };

  return readOnly ? (
    <StyledSpan
      onDoubleClick={handleDoubleClick}
      style={{
        width: `${value.length}ch`,
        cursor: isEditable ? "pointer" : "default",
        color: isTeleconsultation ? "#ffffff" : "#485772",
      }}
    >
      {value?.displayName}
    </StyledSpan>
  ) : (
    <Autocomplete
      className={isTeleconsultation ? "prescription-card-search-tele" : "prescription-card-search"}
      sx={{
        width: `${inputValue.length + 2}ch`,
      }}
      slotProps={{ popper: { style: { minWidth: "500px" } } }}
      readOnly={readOnly}
      options={suggestions}
      onBlur={handleBlur}
      autoHighlight
      freeSolo
      clearOnEscape
      value={value}
      filterOptions={filterOptions}
      onHighlightChange={handleHighlightChange}
      inputValue={inputValue}
      onInputChange={handleCustomSearch}
      onChange={(event, newValue, reason) => {
        // when item selected by cursor
        if (reason === "selectOption") {
          handleSelectItem(event);
        }
      }}
      renderOption={(props, option, index) =>
        suggestions.length > 0 && (
          <div
            key={index}
            {...props}
            style={{
              pointerEvents: option.disabled ? "none" : option.readOnly ? "none" : "auto",
              opacity: option.disabled ? 0.5 : option.readOnly ? 0.75 : 1,
            }}
          >
            <DisplayName option={option}></DisplayName>
          </div>
        )
      }
      getOptionLabel={(option) => `${option?.displayName} ${option?.id}`}
      renderInput={(params) => (
        <TextField
          onKeyDown={handleKeyPress}
          inputRef={inputRef}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: null,
          }}
        ></TextField>
      )}
    />
  );
};

PrescriptionSearchCard.propTypes = {
  value: PropTypes.oneOf([PropTypes.array, PropTypes.object]),
  searchType: PropTypes.oneOf([CATEGORY, GENERIC]).isRequired,
  categoryType: PropTypes.oneOf(["DX", "CC", "RX", "LAB"]),
  classname: PropTypes.string,
};

const DisplayName = ({ option }) => {
  const categoryNameMapper = {
    RX: "Medicines",
    CC: "Symptoms",
    DX: "Diagnosis",
    LAB: "Lab Investigations",
    ADV: "Advices",
    TEMP: "Templates",
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ fontSize: "16px" }}>
          {`${option?.displayName}`}
          {option.manufacturer && (
            <em style={{ paddingLeft: "15px", fontSize: "14px" }}>{`(${option?.manufacturer})`}</em>
          )}
        </div>
        {option.category !== "CAT" && (
          <div
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              color: "blue",
            }}
          >
            in {categoryNameMapper[option.category]}
          </div>
        )}
      </div>
      {option.activeIngredient && (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "80%",
            fontWeight: "500",
            fontSize: "12px",
            color: "grey",
          }}
        >
          {option.activeIngredient}
        </div>
      )}
    </div>
  );
};

export default PrescriptionSearchCard;
