import Grid from "@mui/material/Grid2";
import PatientPrescriptionActiveMedicationsCV1 from "./PatientPrescriptionActiveMedicationsCV1";
import CustomPatientPrescriptionOverviewItemCV1 from "./CustomPatientPrescriptionOverviewItemCV1";
import "assets/scss/DoctorHomeScreen/PatientPrescriptionDetails/patient-prescription-overview.scss";

const PatientPrescriptionOverviewCV1 = () => {
  const overviewItems = ["Allergy", "Family History", "Medical Condition", "Body Implants"];

  return (
    <div className="patient-prescription-overview-container">
      <Grid
        className="patient-prescription-overview"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Grid
          sx={{
            fontWeight: "600",
            margin: "0",
            color: "#004c70",
            lineHeight: "normal",
            minHeight: "23px !important",
            marginBottom: "10px",
            backgroundColor: "#e6eef1",
            fontSize: { md: "13px", lg: "14px", xl: "15px" },
          }}
        >
          <p style={{ textAlign: "center" }}>Overview</p>
        </Grid>
        <Grid
          className="common-scrollbar common-scrollbar-x"
          sx={{
            flexGrow: 1,
            maxHeight: "calc(100% - 33px)",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "0.2rem",
          }}
        >
          <PatientPrescriptionActiveMedicationsCV1 />
          {overviewItems.map((item, index) => {
            return <CustomPatientPrescriptionOverviewItemCV1 overviewItemType={item} key={index} />;
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default PatientPrescriptionOverviewCV1;
