import { Paper, Skeleton, Tab } from "@mui/material";
import Grid from "@mui/material/Grid2";
import PatientAllergy from "./PatientAllergy";
import PatientChronicDisease from "./PatientChronicDisease";
import PatientPastProcedure from "./PatientPastProcedure";
import PatientPhysicalExam from "./PatientPhysicalExam";
import PatientTreatmentPlan from "./PatientTreatmentPlan";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PatientFamilyHistory from "./PatientFamilyHistory";
import { SKELETON_LOADING_TIME_IN_MILLISECONDS, currentActiveUser } from "store/constant";
import Reveal from "views/utilities/Reveal";

const PatientMedicalInfo = () => {
  const patientId = currentActiveUser().roleBasedId;
  const patientDOB = currentActiveUser()?.dateOfBirth;

  const [value, setValue] = useState("one");

  const tablist = [
    {
      label: "Allergy",
      value: "one",
      component: <PatientAllergy patientId={patientId} />,
    },
    {
      label: "Chronic Disease",
      value: "two",
      component: <PatientChronicDisease patientDOB={patientDOB} patientId={patientId} />,
    },
    {
      label: "Past procedure",
      value: "three",
      component: <PatientPastProcedure patientDOB={patientDOB} patientId={patientId} />,
    },
    {
      label: "Physical Exam",
      value: "four",
      component: <PatientPhysicalExam patientDOB={patientDOB} patientId={patientId} />,
    },
    {
      label: "Treatment Plan",
      value: "five",
      component: <PatientTreatmentPlan patientId={patientId} />,
    },
    {
      label: "Family medical history",
      value: "six",
      component: <PatientFamilyHistory patientId={patientId} />,
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <PatientMedicalInfoSkeleton>
      <Reveal>
        <Grid>
          <div className="section-heading">
            <h2 className="page-title">Medical Information</h2>
          </div>
        </Grid>
        <Grid sx={{ mt: 2 }}>
          <Paper
            variant="outlined"
            sx={{
              borderRadius: "12px",
              width: "100%",
              // minHeight: '630px',
              height: "fit-content",
              padding: "23px",
            }}
          >
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }} style={{ overflowX: "auto" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    TabIndicatorProps={{
                      sx: {
                        backgroundColor: "#004c70",
                      },
                    }}
                  >
                    {tablist.map((item, index) => {
                      return (
                        <Tab
                          key={index}
                          sx={{
                            backgroundColor: value === item.value ? "#e6eef1" : "inherit",
                            borderRadius: "8px 8px 0px 0px",
                          }}
                          label={item.label}
                          value={item.value}
                        />
                      );
                    })}
                  </TabList>
                </Box>
                {tablist.map((item, index) => {
                  return (
                    <TabPanel
                      key={index}
                      value={item.value}
                      sx={{
                        padding: "10px",
                      }}
                    >
                      {item.component}
                    </TabPanel>
                  );
                })}
              </TabContext>
            </Box>
          </Paper>
        </Grid>
      </Reveal>
    </PatientMedicalInfoSkeleton>
  );
};

const PatientMedicalInfoSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 4 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
        </Grid>

        <Grid sx={{ ml: 3, mt: 2 }} size={{ xs: 12 }} display={"flex"}>
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={50}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={50}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={50}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={50}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={50}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={50}
          />
        </Grid>

        <Grid sx={{ ml: 3, mr: 2 }} size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={400}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientMedicalInfo;
