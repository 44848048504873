import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { currentActiveUser, PATIENT, roleName } from "store/constant";
import { Card, Typography, IconButton } from "@mui/material";
import PrescriptionCard from "../Doctor/Prescription Pad/prescriptionCard";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import { medicationReminderNotify } from "services/MedicationReminderService";
import {
  getMedicationsByPatientId,
  getPrescriptionByAppointmentId,
} from "services/PrescriptionsService";
import { useSelector } from "react-redux";
import { getPatientPastPrescriptions } from "store/Slices/prescriptionPadSlice";

const PatientPrescription = ({ appId }) => {
  const [previousPrescription, setPreviousPrescription] = useState({
    medicationsArr: [],
    symptomsArr: [],
    diagnosisArr: [],
    labInvestigationsArr: [],
    advicesArr: [],
  });

  const navigate = useNavigate();
  const { handleClick } = useContext(ToastContext);
  const [displayDate, setDisplayDate] = useState("");
  const [doctorName, setDoctorName] = useState("");

  const [precscriptions, setPrescriptions] = useState([]);
  const [prescriptionIndex, setPrescriptionIndex] = useState(0);
  const [dailyMedicationValues, setDailyMedicationValues] = useState([]);
  const [notificationReminderData, setNotificationReminderData] = useState([]);

  const { id } = useParams();
  const appointmentId = id ? id : appId;

  const { state } = useLocation();

  const patientPastPrescriptions = useSelector(getPatientPastPrescriptions);

  const setPrescriptionData = (precscription) => {
    setDisplayDate(formatDate(precscription?.appointmentResponse?.appointmentDate));
    setDoctorName(precscription?.appointmentResponse?.doctor?.userResponse?.name);

    const medicationsArr = precscription?.prescriptionMedicationResponses?.map((medication) => {
      return {
        category: "RX",
        displayName: medication.medicationResponse.name,
        frequency: medication.frequency,
        duration: medication.duration,
        instructions: medication.instructions,
        doseTiming: medication.doseTiming,
        medicationId: medication.medicationResponse.medicationId,
      };
    });

    const diagnosisArr = precscription?.prescriptionDiagnosisResponses?.map((diagnosis) => {
      return {
        category: "DX",
        displayName: diagnosis.diagnosisResponse.name,
        conclusions: diagnosis.conclusions,
      };
    });

    const symptomsArr = precscription?.prescriptionSymptomResponses?.map((symptom) => {
      return {
        category: "CC",
        displayName: symptom.symptomResponse.name,
        since: symptom.since,
        severity: symptom.severity,
      };
    });

    const labInvestigationsArr = precscription?.prescriptionLabReportResponses?.map((lab) => {
      return {
        category: "LAB",
        displayName: lab.labTestResponse.name,
        instructions: lab.instructions,
      };
    });

    let advice = precscription?.additionalRecommendations;
    let advicesArr = [];
    if (advice && advice !== "") {
      advicesArr = precscription?.additionalRecommendations.split(",").map((advice) => {
        return {
          category: "ADV",
          displayName: advice.trim(),
        };
      });
    }

    setPreviousPrescription({
      diagnosisArr: diagnosisArr,
      symptomsArr: symptomsArr,
      medicationsArr: medicationsArr,
      labInvestigationsArr: labInvestigationsArr,
      advicesArr: advicesArr,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dailyMedicineResponse = await getMedicationsByPatientId(
          currentActiveUser()?.roleBasedId
        );
        const medicines = dailyMedicineResponse.data.map((medicine) => {
          return {
            medicationId: medicine.medicationId,
            medicine: medicine.medicationResponse?.name,
            frequency: medicine.frequency,
            doseTiming: medicine.doseTiming,
            endDate: medicine.endDate,
            instructions: medicine.instructions,
            status: medicine.status,
          };
        });
        setDailyMedicationValues(medicines);

        // await fetchPatientLatestVitalSigns(
        //   currentActiveUser()?.roleBasedId,
        //   setPatientLatestVitals,
        //   handleClick
        // );
      } catch (error) {
        console.error("Error fetching daily medicines");
      }
    };

    fetchData();
  }, []);

  function convertEndDateToDateTime(endDate) {
    const dateObj = new Date(endDate);
    dateObj.setHours(23, 59, 59);
    const endDateTime = dateObj.toISOString().split(".")[0];
    return endDateTime;
  }

  useEffect(() => {
    const fetchPrescriptionByAppointmentId = async () => {
      try {
        const response = await getPrescriptionByAppointmentId(appointmentId);
        setPrescriptions([response.data]);
        setPrescriptionData(response.data);
      } catch (error) {
        console.error("Error fetching prescription:", error);
      }
    };

    fetchPrescriptionByAppointmentId();
  }, [appointmentId]);

  const medicationReminders = async (medicineDataArray) => {
    for (const medicineData of medicineDataArray) {
      const userId = currentActiveUser()?.id;
      const endDateTime = convertEndDateToDateTime(medicineData.endDate);
      const dataToSend = {
        medicationId: medicineData.medicationId,
        userId: userId, // Replace with dynamic values if needed
        timeOfDayMap: {
          MORNING: "08:00:00",
          AFTERNOON: "12:00:00",
          EVENING: "18:00:00",
        },
        endDateTime: endDateTime,
        notificationFrequency: 1,
        notificationFrequencyType: "DAILY",
        instructions: medicineData.doseTiming,
        status: medicineData.status,
      };
      try {
        const response = await medicationReminderNotify(dataToSend);
        const tempMedicationReminderdata = await response.data;
        setNotificationReminderData((prev) => {
          return [...prev, tempMedicationReminderdata];
        });
      } catch (error) {
        handleClick("error", "error setting medicine reminder");
      }
    }
  };

  // useEffect(() => {
  //   if (precscriptions) {
  //     setPrescriptionData(patientPastPrescriptions[prescriptionIndex]);
  //   }
  // }, [prescriptionIndex]);

  const formatDate = (inputDate) => {
    if (!inputDate) {
      return;
    }
    const date = dayjs(inputDate);
    return date.format("DD MMM YYYY");
  };

  useEffect(() => {
    if (patientPastPrescriptions?.length > 0) {
      const foundIndex = patientPastPrescriptions.findIndex(
        (prescription) => prescription.appointmentId === appointmentId
      );

      if (foundIndex !== -1) {
        setPrescriptionIndex(foundIndex);
      } else if (appointmentId === undefined) {
        const latestAppointmentId = patientPastPrescriptions[0]?.appointmentId;
        navigate(`/home/patientPrescription/${latestAppointmentId}`, { replace: true });
      }
    }
  }, [appointmentId, patientPastPrescriptions, navigate]);

  useEffect(() => {
    if (patientPastPrescriptions?.length > 0) {
      const currentAppointmentId = patientPastPrescriptions[prescriptionIndex]?.appointmentId;

      if (currentAppointmentId && currentAppointmentId !== appointmentId) {
        navigate(`/home/patientPrescription/${currentAppointmentId}`, {
          replace: true,
          state: {
            isDoctor: true,
            patientName: state?.patientName,
          },
        });
      }
    }
  }, [prescriptionIndex, patientPastPrescriptions, navigate, appointmentId]);
  return (
    <Reveal className="patient-prescription">
      {id && (
        <div
          className="row justify-content-space-between align-items-center roll-content"
          style={{ height: "36px" }}
        >
          <div className="col breadcrumb-row">
            <button onClick={() => navigate(-1)} className="btn back-btn">
              <img src="/images/hc_back_arrow.svg" alt="back-arrow" />
            </button>
            <h6 className="page-title">
              {state?.isDoctor ? `${state?.patientName}'s Prescription` : "My Prescription"}
            </h6>
          </div>
          <div className="col">
            <ul
              className="horizontal-ul"
              style={{ marginTop: 0, display: "flex", padding: 0, listStyleType: "none" }}
            >
              {roleName() === PATIENT && (
                <li className="main-actions" style={{ marginRight: "4px" }}>
                  <CustomButton
                    height="36px"
                    disabled={!(dailyMedicationValues && dailyMedicationValues.length > 0)}
                    className={"mui-btn--secondary"}
                    label={"Set Medicine Reminder"}
                    onClick={() => {
                      medicationReminders(dailyMedicationValues);
                    }}
                  ></CustomButton>
                </li>
              )}
              <li className="main-actions">
                <CustomButton
                  height="36px"
                  className={"mui-btn--secondary"}
                  label={"View PDF"}
                  onClick={() => {
                    navigate("/home/prescription/" + appointmentId);
                  }}
                ></CustomButton>
              </li>
            </ul>
          </div>
        </div>
      )}
      {precscriptions.length > 0 && (
        <Card className="custom-card-ui" style={{ backgroundColor: "white", padding: "24px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              style={{
                fontFamily: "Mitr",
                fontWeight: "400",
                fontSize: "16px",
                color: "#004C70",
              }}
            >
              Dr. {doctorName}
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              {state?.isDoctor && (
                <IconButton
                  onClick={() => {
                    setPrescriptionIndex((prev) => prev + 1);
                  }}
                  disabled={prescriptionIndex >= patientPastPrescriptions.length - 1}
                >
                  <KeyboardArrowLeftIcon
                    style={{
                      color: prescriptionIndex >= precscriptions.length - 1 ? "" : "#004C70",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </IconButton>
              )}
              <Typography
                style={{
                  fontFamily: "Mitr",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#004C70",
                }}
              >
                {displayDate}
              </Typography>
              {state?.isDoctor && (
                <IconButton
                  onClick={() => {
                    setPrescriptionIndex((prev) => prev - 1);
                  }}
                  disabled={prescriptionIndex === 0}
                >
                  <KeyboardArrowRightIcon
                    style={{
                      color: prescriptionIndex === 0 ? "" : "#004C70",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </IconButton>
              )}
            </div>
          </div>
          <br></br>

          <PrescriptionCard
            key="Cc"
            cardHeaderTitle="Cc"
            values={previousPrescription.symptomsArr}
            isEditable={false}
            hideActions
          ></PrescriptionCard>

          <PrescriptionCard
            key="Dx"
            cardHeaderTitle="Dx"
            values={previousPrescription.diagnosisArr}
            isEditable={false}
            hideActions
          />

          <PrescriptionCard
            key="Rx"
            cardHeaderTitle="Rx"
            cardContentField="Medicines"
            values={previousPrescription.medicationsArr}
            isEditable={false}
            hideActions
          ></PrescriptionCard>

          <PrescriptionCard
            key="Lab"
            cardHeaderTitle="Lab"
            values={previousPrescription.labInvestigationsArr}
            isEditable={false}
            hideActions
          ></PrescriptionCard>

          <PrescriptionCard
            key="Adv"
            cardHeaderTitle="Adv"
            values={previousPrescription.advicesArr}
            isEditable={false}
            hideActions
          ></PrescriptionCard>
        </Card>
      )}
      {precscriptions.length === 0 && (
        <div className="not-found">
          <p>No Prescriptions Found</p>
        </div>
      )}
    </Reveal>
  );
};

export default PatientPrescription;
