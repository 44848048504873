export const sortVitalsByPriority = (vitalsData, priorityOrder) => {
  const modifiedSortOrder = priorityOrder
    .map((item) => {
      if (item === "BLOOD_PRESSURE_SYSTOLIC" || item === "BLOOD_PRESSURE_DIASTOLIC") {
        return "BLOOD_PRESSURE";
      }
      return item;
    })
    .filter(
      (item, index, self) =>
        self.indexOf(item) === index && vitalsData.some((obj) => obj.key === item)
    );

  return vitalsData.sort((a, b) => {
    const indexA = modifiedSortOrder.indexOf(a.key);
    const indexB = modifiedSortOrder.indexOf(b.key);
    if (indexA === -1 && indexB === -1) return 0;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });
};
