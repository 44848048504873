// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patient-prescription-vital-graphs-container {
  height: 100%;
  width: 100%;
  overflow: auto;
  container-type: size;
}
@container (max-width: 150px) {
  .patient-prescription-vital-graphs-container .patient-prescription-vital-graphs {
    display: none !important;
  }
  .patient-prescription-vital-graphs-container:after {
    content: "Too small to display contents";
    display: block;
    padding: 5px;
    color: #6e6e6e;
    font-style: italic;
    font-weight: 500;
  }
}
@container (max-height: 130px) {
  .patient-prescription-vital-graphs-container .patient-prescription-vital-graphs {
    display: none !important;
  }
  .patient-prescription-vital-graphs-container:after {
    content: "Too small to display contents";
    display: block;
    padding: 5px;
    color: #6e6e6e;
    font-style: italic;
    font-weight: 500;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/DoctorHomeScreen/PatientPrescriptionDetails/patient-prescription-vital-graphs.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,cAAA;EACA,oBAAA;AACF;AACE;EACE;IACE,wBAAA;EACJ;EACE;IACE,wCAAA;IACA,cAAA;IACA,YAAA;IACA,cAAA;IACA,kBAAA;IACA,gBAAA;EACJ;AACF;AAEE;EACE;IACE,wBAAA;EAAJ;EAEE;IACE,wCAAA;IACA,cAAA;IACA,YAAA;IACA,cAAA;IACA,kBAAA;IACA,gBAAA;EAAJ;AACF","sourcesContent":[".patient-prescription-vital-graphs-container {\n  height: 100%;\n  width: 100%;\n  overflow: auto;\n  container-type: size;\n\n  @container (max-width: 150px) {\n    .patient-prescription-vital-graphs {\n      display: none !important;\n    }\n    &:after {\n      content: \"Too small to display contents\";\n      display: block;\n      padding: 5px;\n      color: #6e6e6e;\n      font-style: italic;\n      font-weight: 500;\n    }\n  }\n\n  @container (max-height: 130px) {\n    .patient-prescription-vital-graphs {\n      display: none !important;\n    }\n    &:after {\n      content: \"Too small to display contents\";\n      display: block;\n      padding: 5px;\n      color: #6e6e6e;\n      font-style: italic;\n      font-weight: 500;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
