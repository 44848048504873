import React, { useState } from "react";

const ImageMagnifier = ({ src, width = 100, zoomLevel = 8 }) => {
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [verticalPosition, setVerticalPosition] = useState(0);

  const handleMouseMove = (e) => {
    const elem = e.currentTarget;
    const { top } = elem.getBoundingClientRect();
    const y = e.clientY - top;
    setVerticalPosition(y);
  };

  return (
    <div className="relative inline-block" style={{ height: "13rem" }}>
      <img
        src={src}
        alt="Magnifiable"
        className="block"
        style={{ width: `${width}%`, cursor: "zoom-in" }}
        onMouseEnter={() => setShowMagnifier(true)}
        onMouseLeave={() => setShowMagnifier(false)}
        onMouseMove={handleMouseMove}
      />

      {showMagnifier && (
        <div
          style={{
            position: "relative",
            right: "585%",
            bottom: "calc(100% + 203px)",
            width: "66rem",
            height: "12rem",
            backgroundColor: "#fff",
            zIndex: 999,
            overflow: "hidden",
            border: "2px solid #ddd",
            borderRadius: "15px",
            boxShadow: "2px 2px 7px 2px #ddd",
          }}
        >
          <img
            src={src}
            alt=""
            className="transition-transform duration-300"
            style={{
              width: `${width / 1.5}rem`,
              height: `${width * (zoomLevel / 2.5)}vh`,
              transform: `translateY(${-verticalPosition * 1.3}vh)`,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageMagnifier;
