import { Avatar, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "assets/scss/prescriptionPad.scss";
import "assets/scss/style.scss";
import { Menu, MenuList } from "@mui/material";
import CustomMenuItem from "../../../../ui-component/custom-components/CustomMenuItem";
import { calculateAge } from "utils/calculate-age";
import { useState } from "react";
import { useNavigate } from "react-router";

const PatientFamilyCard = ({ data, actions, isLoading }) => {
  // calculate age using dateOfBirth

  const [anchorEl, setAnchorEl] = useState(null);
  const [clickedRow, setClickedRow] = useState(null);

  const handleMenuOpen = (event, row) => {
    setClickedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/home/manageAbhaNumber");
  };

  const getImageData = () => {
    if (data.profilePicWithContent && data.profilePicWithContent.content) {
      return data.profilePicWithContent?.content;
    } else {
      return data?.userResponse?.gender === "MALE" ? "/images/man.png" : "/images/woman.png";
    }
  };

  return (
    <>
      <Grid size={{ md: 12 }}>
        <div className="row" style={{ justifyContent: "center", marginLeft: "34%" }}>
          {isLoading ? (
            <Skeleton variant="circular" animation="wave" width={"60px"} height={"60px"} />
          ) : (
            <Avatar
              alt="Patient"
              src={getImageData()}
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
              }}
              className="image"
            />
          )}
          <div style={{ marginLeft: "auto" }}>
            {
              <i
                className="ri-more-2-fill ri-xl hover"
                onClick={(event) => handleMenuOpen(event, data)}
              />
            }
            <Menu
              elevation={1}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{ style: { borderRadius: "4px" } }}
            >
              <MenuList dense sx={{ p: 0 }}>
                {actions.map((action, actionIndex) => {
                  return (
                    <CustomMenuItem
                      disabled={action?.checkDisable ? action.checkDisable(action) : undefined}
                      key={actionIndex}
                      text={
                        typeof action.label === "function" ? action.label(action) : action.label
                      }
                      icon={action.icon}
                      onClick={() => {
                        handleMenuClose();
                        action.onClick(clickedRow);
                      }}
                    />
                  );
                })}
              </MenuList>
            </Menu>
          </div>
        </div>
        <div
          className="row align-items-center patient-name my-0"
          style={{ fontWeight: 600, fontSize: "1em", justifyContent: "center" }}
        >
          {data?.userResponse?.name}
        </div>
        <div
          className="row align-items-center patient-details my-0"
          style={{ justifyContent: "center" }}
        >
          {data?.userResponse?.dateOfBirth != null && (
            <span className="additonal-details">
              {`${calculateAge(data?.userResponse?.dateOfBirth)} years`}
              &nbsp;{"|"}&nbsp;
            </span>
          )}
          <span className="additonal-details">{data?.userResponse?.gender}</span>
          <></>
        </div>
      </Grid>
      <Grid size={{ md: 12 }} className="px-0">
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          <p style={{ fontSize: "1em", fontWeight: 500, marginBottom: "0px" }}>
            {data?.relationship}
            {data?.relationship && data?.userResponse?.mobileNumber ? (
              <>
                <span> | </span>
              </>
            ) : null}
            {data?.userResponse?.mobileNumber}
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "0px" }}>
          <p className="link-style" onClick={handleClick}>
            CREATE/LINK ABHA
          </p>
        </div>
      </Grid>
    </>
  );
};

export default PatientFamilyCard;
