import { useState } from "react";
import { Divider, FormControl, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import CustomButton from "ui-component/custom-components/CustomButton";

import dayjs from "dayjs";
import {
  ArrowCircleRightOutlined,
  CurrencyRupeeOutlined,
  Discount,
  DoneAllOutlined,
} from "@mui/icons-material";
import "assets/scss/billingPage.scss";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import { useSelector } from "react-redux";
import {
  getIsPaymentMode,
  getMedicalServiceItems,
  getPatientInvoiceDetail,
  setClearInvoiceState,
  setInvoiceAmountDetail,
  setIsPaymentMode,
  setMedicalServiceItems,
  setPatientInvoiceAdjustments,
  setPatientInvoiceDetail,
  setPatientInvoiceItems,
  setPatientInvoiceTotal,
} from "store/Slices/billingInvoiceSlice";
import { useDispatch } from "react-redux";
import PrescriptionCard from "../Prescription Pad/prescriptionCard";
import PatientCard from "../PatientDTO/PatientCard";
import {
  ACTIVE_STATE,
  CLINIC_VISIT,
  convertTimeForTimeFormat,
  DATE_FORMAT,
  DATE_FORMAT_DMY,
  getUUID,
  PENDING,
} from "store/constant";
import { convertIntoTwoDecimal } from "utils/price-utils";
import { useEffect } from "react";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import { getPrescriptionByAppointmentId } from "services/PrescriptionsService";
import { convertPrescriptionDataToUIFormat } from "utils/prescriptionPadUtils/convert-to-UI-format";
import { getAppointmentById } from "services/Appointments";
import {
  createOrderForPayment,
  paymentVerification,
  updateBillingInvoices,
} from "services/BillingService";
import { setPrescriptionId, setPrescriptionPadData } from "store/Slices/prescriptionPadSlice";

const columns = [
  { field: "itemResponse.name", label: "Description", align: "left" },
  { field: "quantity", label: "Qty.", align: "center" },
  { field: "itemResponse.price", label: "Rate", align: "center" },
  { field: "discount", label: "Discount", align: "center" },
  { field: "tax", label: "Tax", align: "center" },
  { field: "amount", label: "Amount", align: "center" },
];

export const RenderMedicalServices = ({ sourceData, handleGenerateInvoice, isPaymentMode }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const invoiceItems = useSelector(getMedicalServiceItems);
  const invoiceDetails = useSelector(getPatientInvoiceDetail);

  useEffect(() => {
    dispatch(setPatientInvoiceItems([...invoiceItems]));
  }, [invoiceItems]);

  const calculateTotalDetail = (invoiceItems) => {
    let totalDiscount = 0;
    let totalTax = 0;
    let totalAmount = 0;

    invoiceItems.forEach((item) => {
      totalDiscount += item.discount;
      totalTax += item.tax;
      totalAmount += item.amount;
    });
    dispatch(
      setInvoiceAmountDetail({
        discount: convertIntoTwoDecimal(totalDiscount),
        tax: convertIntoTwoDecimal(totalTax),
        amount: convertIntoTwoDecimal(totalAmount),
      })
    );

    dispatch(
      setPatientInvoiceTotal(
        convertIntoTwoDecimal(totalAmount - (invoiceDetails.adjustmentResponses?.[0]?.amount || 0))
      )
    );
  };

  const getDiscountAmount = (discounts, price) => {
    let discountAmount = 0;
    discounts?.forEach((discount) => {
      if (!discount.discountStatus === ACTIVE_STATE) {
        discount += 0;
      } else if (discount.discountUnit === "PERCENTAGE") {
        discountAmount += (price * discount.value) / 100;
      } else if (discount.discountUnit === "FIXED_AMOUNT") {
        discountAmount += discount.value;
      }
    });

    return discountAmount;
  };

  const handleSelectMedicalService = (selectedItem, value) => {
    const updatedInvoiceItems = invoiceItems.map((item) => {
      if (item?.itemResponse.id === selectedItem?.itemResponse.id) {
        const discountAmount = getDiscountAmount(
          item.itemResponse.discounts,
          item.itemResponse.price
        );
        const taxRateAmount =
          ((item.itemResponse.price - discountAmount) * item.itemResponse.taxRateResponse.rate) /
          100;
        return {
          ...item,
          quantity: value,
          discount: convertIntoTwoDecimal(discountAmount * value),
          tax: convertIntoTwoDecimal(taxRateAmount * value),
          amount: convertIntoTwoDecimal(
            (item.itemResponse.price - discountAmount + taxRateAmount) * value
          ),
        };
      } else {
        return item;
      }
    });

    dispatch(setMedicalServiceItems(updatedInvoiceItems));
    calculateTotalDetail(updatedInvoiceItems);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div>
        <p className="billing-heading">Select your services</p>
      </div>
      <div
        className="common-scrollbar"
        style={{ flexGrow: 1, maxHeight: "100%", overflowY: "auto", paddingBottom: "10px" }}
      >
        <div className="card-container">
          {invoiceItems.length > 0 ? (
            invoiceItems.map((item) => (
              <div key={item.itemResponse?.id} className={`medical-service-card`}>
                <div className="medical-service-name">
                  <p>{item.itemResponse?.name}</p>
                </div>
                <div className="">
                  <div className="quantity-control">
                    <CustomButton
                      iconButton={<i className="ri-subtract-fill"></i>}
                      disabled={item.quantity === 0}
                      onClick={() => {
                        if (item.quantity > 0) {
                          handleSelectMedicalService(item, item.quantity - 1);
                        }
                      }}
                      style={{
                        padding: "0",
                        color: item.quantity === 0 ? "#616161" : "#004c70",
                      }}
                    ></CustomButton>
                    <div className="quantity-display">
                      <span>{item.quantity}</span>
                    </div>

                    <CustomButton
                      iconButton={<i className="ri-add-fill"></i>}
                      onClick={() => {
                        handleSelectMedicalService(item, item.quantity + 1);
                      }}
                      style={{ padding: "0", color: "#004c70" }}
                    ></CustomButton>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No Medical Services Found</div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: "5px",
        }}
      >
        <CustomButton
          className={"btn--secondary"}
          onClick={() => {
            navigate("/home/ProductAndServices", {
              state: { sourcePage: "billing", sourceData: sourceData },
            });
          }}
          style={{ padding: "6px", width: "100%" }}
          gap={"0px"}
          iconButton={
            <span style={{ fontSize: "15px", fontFamily: "Poppins", fontWeight: "500" }}>
              <i className="ri-add-fill ri-lg"></i> Services
            </span>
          }
        />
        <CustomButton
          onClick={() => {
            handleGenerateInvoice();
          }}
          style={{ padding: "6px", width: "100%" }}
          gap={"0px"}
          iconButton={
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
            >
              <i className="ri-check-double-line ri-lg"></i>{" "}
              {invoiceDetails?.id ? "Save Invoice" : "Generate Invoice"}
            </span>
          }
          className={"btn--primary"}
        />
      </div>
    </div>
  );
};

export const RenderInvoice = ({ invoiceDetails, totalDetail }) => {
  const dispatch = useDispatch();
  const isPaymentMode = useSelector(getIsPaymentMode);
  const [discountUnit, setDiscountUnit] = useState("amount");
  const [discountValue, setDiscountValue] = useState(
    invoiceDetails.adjustmentResponses?.[0]?.amount
  );
  const [adjustmentError, setAdjustmentError] = useState("");

  const validateAdjustment = (value, type) => {
    if (type === "amount") {
      if (value > invoiceDetails.totalAmount) {
        setAdjustmentError("Adjustment can't be more than Amount");
      } else {
        setAdjustmentError("");
        setDiscountValue(value);
        handleSaveAdjustment(value, type);
      }
    } else {
      if (value > 100) {
        setAdjustmentError("Adjustment percentage can't be more than 100");
      } else {
        setAdjustmentError("");
        setDiscountValue(value);
        handleSaveAdjustment(value, type);
      }
    }
  };

  const handleDiscountUnitChange = (newValue) => {
    setDiscountUnit(newValue);
    setDiscountValue("");
    handleSaveAdjustment("", newValue);
  };

  const handleSaveAdjustment = (discountValue, discountUnit) => {
    try {
      if (Boolean(adjustmentError)) {
        return;
      }
      let dis = 0;
      if (discountUnit === "amount") {
        dis = discountValue || 0;
      } else {
        dis = convertIntoTwoDecimal((totalDetail.amount * discountValue) / 100);
      }
      const payload = {
        date: dayjs().format(DATE_FORMAT),
        type: "DISCOUNT",
        amount: dis,
        reason: "Discount Adjustment",
        id: invoiceDetails.adjustmentResponses[0]?.id || getUUID(),
        status: "ACTIVE",
        invoiceId: invoiceDetails?.id,
      };

      dispatch(setPatientInvoiceAdjustments([payload]));
      dispatch(setPatientInvoiceTotal(totalDetail.amount - dis));
    } catch (error) {}
  };

  const handleDiscountValueChange = (e) => {
    validateAdjustment(e.target.value, discountUnit);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div>
        <Grid
          sx={{
            display: "flex",
            padding: "15px 0px",
            justifyContent: "space-between",
          }}
        >
          <Grid style={{ display: "flex" }}>
            {invoiceDetails.invoiceKey && (
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 400,
                  color: "#000000",
                  fontStyle: "italic",
                }}
              >
                Invoice No. : &nbsp;
              </Typography>
            )}
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
              }}
            >
              {invoiceDetails?.invoiceKey}
            </Typography>
          </Grid>

          <Grid style={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: 400,
                color: "#000000",
                fontStyle: "italic",
              }}
            >
              Date : &nbsp;
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
              }}
            >
              {dayjs(invoiceDetails?.issueDate).format("MMMM D, YYYY")}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ borderWidth: "1px" }} />
      </div>
      <div
        className="common-scrollbar"
        style={{ flexGrow: 1, maxHeight: "100%", overflowY: "auto" }}
      >
        <Grid sx={{ padding: "15px 0px" }}>
          <CustomizedTable
            columns={columns}
            tableData={invoiceDetails?.invoiceItems.filter(
              (invoiceItem) => invoiceItem.quantity > 0 && invoiceItem.status === ACTIVE_STATE
            )}
            noPagination={true}
          />
        </Grid>
        <Divider sx={{ borderWidth: "1px" }} />
      </div>
      <div style={{ marginTop: "20px", display: "flex", justifyContent: "end" }}>
        <div style={{ width: "22.28%" }}>
          <p style={{ paddingLeft: "16px", fontWeight: "500" }}>Total</p>
        </div>
        <div style={{ width: "17.22%" }}>
          <p style={{ width: "116px", textAlign: "center", fontWeight: "500" }}>
            {totalDetail.discount}
          </p>
        </div>

        <div style={{ width: "14.76%" }}>
          <p style={{ width: "100px", textAlign: "center", fontWeight: "500" }}>
            {totalDetail.tax}
          </p>
        </div>

        <div style={{ width: "16.21%" }}>
          <p style={{ width: "110px", textAlign: "center", fontWeight: "500" }}>
            {totalDetail.amount}
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div>
          {!isPaymentMode &&
            invoiceDetails.invoiceItems.filter(
              (item) => item.quantity > 0 && item.status === ACTIVE_STATE
            ).length > 0 && (
              <div>
                <div>
                  <FormInputField
                    style={{
                      width: "50%",
                      height: "50%",
                      marginRight: "5px",
                    }}
                    label="Adjustment"
                    type={"tel"}
                    name="discountValue"
                    value={discountValue}
                    onChange={handleDiscountValueChange}
                    inputProps={{
                      maxLength: 5,
                    }}
                    error={Boolean(adjustmentError)}
                    errorText={adjustmentError}
                    startAdornment={<Discount />}
                    size={"small"}
                  />

                  <ToggleButtonGroup
                    value={discountUnit}
                    exclusive
                    onChange={(e, newValue) => {
                      if (newValue) {
                        handleDiscountUnitChange(newValue);
                      }
                    }}
                  >
                    <ToggleButton
                      sx={{
                        padding: "5px 10px",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        height: "40px",
                        "&.Mui-selected": {
                          color: "#004c70",
                          backgroundColor: "#def1ee",
                        },
                      }}
                      value="amount"
                    >
                      <CurrencyRupeeOutlined fontSize="small" />
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        padding: "5px 10px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",

                        "&.Mui-selected": {
                          color: "#004c70",
                          backgroundColor: "#def1ee",
                        },
                      }}
                      value="percentage"
                    >
                      <PercentOutlinedIcon fontSize="small" />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            )}
        </div>
        <div>
          <Grid
            sx={{
              padding: "2px 0px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
                marginRight: "25px",
              }}
            >
              Adjustments:
            </Typography>
            <CurrencyRupeeOutlined fontSize="small" sx={{ color: "#000000" }} />
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
              }}
            >
              {invoiceDetails?.adjustmentResponses?.length > 0
                ? invoiceDetails?.adjustmentResponses?.[0]?.amount
                : 0}
            </Typography>
          </Grid>
          <Grid
            sx={{
              padding: "2px 0px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
                marginRight: "25px",
              }}
            >
              Payable:
            </Typography>
            <CurrencyRupeeOutlined fontSize="small" sx={{ color: "#000000" }} />

            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
              }}
            >
              {convertIntoTwoDecimal(invoiceDetails.totalAmount) || 0}
            </Typography>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export const RenderPayment = ({ patientDetails, handleClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const invoiceDetails = useSelector(getPatientInvoiceDetail);

  // const [paymentMapper, setpaymentMapper] = useState({
  //   Cash: true,
  //   Online: true,
  // });

  const paymentMapper = {
    Cash: true,
    Online: true,
  };
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);
  // const userOrgId = useSelector(getUserOrgId);

  useEffect(() => {
    const paymentMethodForPaid = invoiceDetails?.paymentResponses?.find(
      (payment) => payment.paymentStatus === "PAID"
    )?.method;
    if (invoiceDetails?.invoiceStatus === "PAID" && paymentMethodForPaid === "RAZORPAY") {
      setPaymentMode("Online");
      setPaymentDone(true);
    } else if (invoiceDetails?.invoiceStatus === "PAID") {
      setPaymentMode("Cash");
      setPaymentDone(true);
    }
  }, [invoiceDetails]);

  const handleRazorPayInitiate = async (event) => {
    event.preventDefault();
    try {
      const payloadForOrderCreation = {
        id: getUUID(),
        invoiceId: invoiceDetails.id,
        paymentDate: dayjs().format(DATE_FORMAT),
        amount: invoiceDetails.totalAmount,
        method: "RAZORPAY",
        paymentStatus: PENDING,
        razorpayOrderResponse: {
          notes: "NOTES",
        },
      };
      const orderResponse = await createOrderForPayment(payloadForOrderCreation);

      const { razorpayOrderResponse } = orderResponse.data;
      const options = {
        key: "rzp_test_5Fezr30xpUnvz4", // Enter the Key ID generated from the Dashboard
        amount: razorpayOrderResponse.amount, // in paise for INR
        currency: razorpayOrderResponse.currency,
        name: "Arog",
        description: "Test Transaction",
        image: "https://hc.argusservices.in/images/email/logo.png",
        order_id: razorpayOrderResponse.razorpayOrderId,
        handler: async function (response) {
          try {
            const data = {
              orderCreationId: razorpayOrderResponse.razorpayOrderId,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            const params = {
              id: razorpayOrderResponse.paymentId,
            };
            const responsePayment = await paymentVerification(params, data);
            markAsPaid(responsePayment.data);
          } catch (error) {
            console.error(error);
          }
        },
        prefill: {
          name: patientDetails?.userResponse?.name,
          email: patientDetails?.userResponse?.emailId,
          contact: patientDetails?.userResponse?.mobileNumber,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
        timeout: 300, //seconds
        modal: {
          confirm_close: true,
          ondismiss: async (reason) => {
            // when modal is closed by the user
            if (reason === undefined) {
              handleClick("error", "Payment Cancelled. Try Again");
            }
            // when modal is auto closed because of time out
            else if (reason === "timeout") {
              handleClick("error", "Timedout. Try Again");
            }
            // When payment gets failed.
            else {
              handleClick("error", "Payment Failed. Try Again");
            }
          },
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.on("payment.failed", async () => {
        console.error("payment failed");
      });
      paymentObject.open();
    } catch (error) {
      console.error(error);
    }
  };

  const markAsPaid = async (paymentResponse) => {
    try {
      dispatch(
        setPatientInvoiceDetail({
          ...invoiceDetails,
          invoiceStatus: "PAID",
          paymentResponse: paymentResponse,
        })
      );
      setPaymentDone(true);
      handleClick("success", "Payment has been successfully received.");
    } catch (error) {
      console.error(error);
    }
  };

  // // todo: currently allowing all payment options from razorpay. can fetch and pass as config to razorpay

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (userOrgId) {
  //         const amenitiesResponse = await getAmenitiesByOrgId(userOrgId);
  //         const acceptedPayments = amenitiesResponse.data.filter(
  //           (c) => c.category === "Payment Mode Accepted"
  //         );
  //         if (acceptedPayments.length > 0) {
  //           acceptedPayments.forEach((element) => {
  //             paymentMapper[element.name] = true;
  //           });
  //           setpaymentMapper(paymentMapper);
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const recordCashPayment = async () => {
    const payloadForPaymentCreation = {
      id: getUUID(),
      invoiceId: invoiceDetails.id,
      paymentDate: dayjs().format(DATE_FORMAT),
      amount: invoiceDetails.totalAmount,
      method: "CASH",
      paymentStatus: "PAID",
    };
    const paymentResponse = await createOrderForPayment(payloadForPaymentCreation);
    const payloadForInvoiceUpdate = {
      ...invoiceDetails,
      invoiceStatus: "PAID",
      paymentResponse: paymentResponse.data,
    };
    await updateBillingInvoices(invoiceDetails?.id, payloadForInvoiceUpdate);
    markAsPaid(paymentResponse.data);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "#004c70",
            }}
          >
            Payment
          </Typography>
        </div>

        <Divider sx={{ marginTop: "8px", borderWidth: "1px" }} />

        <div>
          <FormControl sx={{ marginTop: "13px" }}>
            <FormLabel
              id="demo-radio-buttons-group-label"
              sx={{
                color: "#000000",
                fontWeight: 500,
                marginBottom: "10px",
              }}
            >
              Mode Of Payment
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={paymentMode}
              onChange={(e) => {
                setPaymentMode(e.target.value);
              }}
            >
              {paymentMapper["Cash"] === true && (
                <>
                  <FormControlLabel
                    value="Cash"
                    control={<Radio size="small" sx={{ padding: "3px 5px 3px 9px" }} />}
                    disabled={invoiceDetails?.invoiceStatus === "PAID"}
                    label={
                      <Grid display={"flex"}>
                        <img
                          src="/images/cash_payment.svg"
                          alt=""
                          style={{
                            width: "25px",
                            height: "25px",
                            marginTop: "2px",
                            marginLeft: "1px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: 450,
                            color: "#45483C",
                            paddingTop: "2px",
                            marginLeft: "6px",
                          }}
                        >
                          Cash
                        </Typography>
                      </Grid>
                    }
                  />
                </>
              )}
              {paymentMapper["Online"] === true && (
                <>
                  <FormControlLabel
                    value="Online"
                    control={<Radio size="small" sx={{ padding: "3px 5px 3px 9px" }} />}
                    disabled={invoiceDetails?.invoiceStatus === "PAID"}
                    label={
                      <Grid display={"flex"}>
                        <img
                          src="/images/upi_logo.svg"
                          alt=""
                          style={{
                            width: "25px",
                            height: "20px",
                            marginTop: "2px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: 450,
                            color: "#45483C",
                            paddingTop: "2px",
                            marginLeft: "6px",
                          }}
                        >
                          Online
                        </Typography>
                      </Grid>
                    }
                  />
                </>
              )}
            </RadioGroup>
          </FormControl>
        </div>

        {!paymentDone && (
          <CustomButton
            onClick={(event) => {
              if (paymentMode === "Online") {
                handleRazorPayInitiate(event);
              } else {
                recordCashPayment();
              }
            }}
            style={{ marginTop: "15px" }}
            disabled={paymentMode ? undefined : true}
            label={paymentMode === "Online" ? "Proceed" : "Mark as Paid"}
            className={"btn--secondary"}
            startIcon={
              paymentMode === "Online" ? <ArrowCircleRightOutlined /> : <DoneAllOutlined />
            }
          />
        )}

        {paymentDone && (
          <>
            <Divider sx={{ marginTop: "10px", borderWidth: "1px" }} />

            <div sx={{ paddingTop: "12px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                {" "}
                Transaction
              </Typography>
            </div>

            <div
              sx={{
                border: "0.5px dashed",
                display: "flex",
                borderRadius: "10px",
                padding: "10px",
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              <img
                src="/images/transaction_logo.svg"
                alt=""
                style={{
                  width: "35px",
                  height: "35px",
                  marginTop: "2px",
                }}
              />
              <Grid sx={{ marginLeft: "8px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#29bf91",
                    marginBottom: "3px",
                  }}
                >
                  {invoiceDetails.totalAmount}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#194B5A",
                  }}
                >
                  {" "}
                  {patientDetails?.userResponse.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#616B67",
                  }}
                >
                  {" "}
                  {paymentMode} Payment
                </Typography>
              </Grid>

              <Grid sx={{ marginLeft: "auto" }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#616B67",
                  }}
                >
                  {" "}
                  21 Jan 2024 | 8:45 AM
                </Typography>
              </Grid>
            </div>
          </>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "5px" }}>
        <div>
          {!(invoiceDetails?.invoiceStatus === "PAID") && (
            <CustomButton
              className={"btn--secondary"}
              style={{ padding: "6px", width: "100%" }}
              gap={"0px"}
              iconButton={
                <span style={{ fontSize: "15px", fontFamily: "Poppins", fontWeight: "500" }}>
                  <i className="ri-edit-line ri-lg"></i> Edit Invoice
                </span>
              }
              onClick={() => dispatch(setIsPaymentMode(false))}
            />
          )}
        </div>
        <CustomButton
          onClick={() => {
            dispatch(
              setPrescriptionPadData({
                diagnosisArr: [],
                symptomsArr: [],
                medicationsArr: [],
                labInvestigationsArr: [],
                advicesArr: [],
                soapNotes: {},
              })
            );
            dispatch(setPrescriptionId(null));
            dispatch(setClearInvoiceState());
            navigate("/home/dashboard");
          }}
          disabled={!paymentDone}
          style={{ padding: "6px", width: "100%" }}
          gap={"0px"}
          className={"btn--primary"}
          iconButton={
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
            >
              <i className="ri-check-double-line ri-lg"></i> Save and Exit
            </span>
          }
        />
      </div>
    </div>
  );
};

export const PatientAppointmentDetail = ({ handleClick, appointmentId }) => {
  const [appointmentDetails, setAppointmentDetails] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const appointmentResponse = await getAppointmentById(appointmentId);
        setAppointmentDetails(appointmentResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div>
        <p className="billing-heading">Appointment Details</p>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Typography>
          {" "}
          Date:&nbsp; {dayjs(appointmentDetails?.appointmentDate).format(DATE_FORMAT_DMY)}
        </Typography>
        <Typography>
          {" "}
          Time:&nbsp; {convertTimeForTimeFormat(appointmentDetails?.appointmentTime)}
        </Typography>
        {appointmentDetails?.type === CLINIC_VISIT ? (
          <>
            {"In-Clinic"}

            <i
              className="ri-walk-fill ri-xl"
              style={{
                width: "18px",
                height: "18px",
                color: "#29bf91",
                marginRight: "5px",
              }}
            />
          </>
        ) : (
          <>
            {"Tele-Consultation"}
            <i
              className="ri-video-chat-line ri-xl"
              style={{
                width: "16px",
                height: "16px",
                color: "#004C70",
                marginRight: "5px",
              }}
            />
          </>
        )}
      </div>
      <Divider />
      <div style={{ marginTop: "10px" }}>
        <p className="billing-heading">Patient Details</p>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <PatientCard
          patientId={appointmentDetails?.patient?.id}
          patientData={appointmentDetails?.patient}
          viewData={{
            basicDetails: true,
            lifeStyle: true,
            chronicDiseases: true,
            lastVisited: true,
          }}
          handleClick={handleClick}
        ></PatientCard>{" "}
      </div>{" "}
      <Divider />
      <div style={{ marginTop: "10px" }}>
        <p className="billing-heading">Doctor Details</p>
      </div>
      <Grid>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#616161",
          }}
        >
          Dr.{appointmentDetails?.doctor?.userResponse.name}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#616161",
          }}
        >
          {appointmentDetails?.doctor?.specialization?.name}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#616161",
          }}
        >
          {appointmentDetails?.doctor?.userResponse.mobileNumber}
        </Typography>
      </Grid>
    </div>
  );
};

export const RenderPrescription = ({ appointmentId }) => {
  const [currentPrescription, setCurrentPrescription] = useState({
    symptomsArr: [],
    diagnosisArr: [],
    medicationsArr: [],
    labInvestigationsArr: [],
    advicesArr: [],
    soapNotes: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const prescriptionResponse = await getPrescriptionByAppointmentId(appointmentId);
        const prescriptionData = await convertPrescriptionDataToUIFormat(prescriptionResponse.data);
        setCurrentPrescription(prescriptionData.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return (
    <div style={{ marginTop: "25px" }}>
      <PrescriptionCard
        key="Cc"
        cardHeaderTitle="Cc"
        values={currentPrescription.symptomsArr}
        onDelete={() => {}}
        isEditable={false}
        hideActions
      ></PrescriptionCard>

      <PrescriptionCard
        key="Dx"
        cardHeaderTitle="Dx"
        values={currentPrescription.diagnosisArr}
        onDelete={() => {}}
        isEditable={false}
        hideActions
      ></PrescriptionCard>

      <PrescriptionCard
        key="Rx"
        cardHeaderTitle="Rx"
        cardContentField="Medicines"
        values={currentPrescription.medicationsArr}
        onDelete={() => {}}
        isEditable={false}
        hideActions
      ></PrescriptionCard>

      <PrescriptionCard
        key="Lab"
        cardHeaderTitle="Lab"
        values={currentPrescription.labInvestigationsArr}
        onDelete={() => {}}
        isEditable={false}
        hideActions
      ></PrescriptionCard>

      <PrescriptionCard
        key="Adv"
        cardHeaderTitle="Adv"
        values={currentPrescription.advicesArr}
        onDelete={() => {}}
        isEditable={false}
        hideActions
      ></PrescriptionCard>
      <PrescriptionCard
        key="SOAP"
        cardHeaderTitle="SOAP"
        values={[currentPrescription.soapNotes]}
        onDelete={() => {}}
        isEditable={false}
        hideActions
      ></PrescriptionCard>
    </div>
  );
};
