import React, { useEffect } from "react";
import FilterDrawer from "ui-component/FilterDrawer";
import dayjs from "dayjs";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import {
  COMPLETED,
  NO_SHOW,
  PRESCRIPTION_DONE,
  SCHEDULED,
  RESCHEDULED,
  CANCELLED,
  CHECKED_IN,
  TELE_CONSULTATION,
  CLINIC_VISIT,
  TEXT_FOR_TELECONSULTATION,
  TEXT_FOR_CLINIC_VISIT,
  DATE_FORMAT,
  DATE_FORMAT_DMY,
} from "store/constant";

const today = dayjs();
const startDate = today.format(DATE_FORMAT);
const dateOptions = [
  {
    label: "All",
    startDate: null,
    endDate: null,
  },
  {
    label: "Today",
    startDate: startDate,
    endDate: startDate,
  },
  {
    label: "Yesterday",
    startDate: today.subtract(1, "day").format(DATE_FORMAT),
    endDate: today.subtract(1, "day").format(DATE_FORMAT),
  },
  {
    label: "Last 7 Days",
    startDate: today.subtract(7, "day").format(DATE_FORMAT),
    endDate: startDate,
  },
  {
    label: "Last 30 Days",
    startDate: today.subtract(30, "day").format(DATE_FORMAT),
    endDate: startDate,
  },
  {
    label: "Last 3 Months",
    startDate: today.subtract(3, "month").format(DATE_FORMAT),
    endDate: startDate,
  },
  {
    label: "Custom Range",
    startDate: "",
    endDate: "",
  },
];
const statusOptions = [
  { value: null, label: "All" },
  {
    value: COMPLETED,
    label: "Completed",
  },
  {
    value: NO_SHOW,
    label: "No Show",
  },
  {
    value: PRESCRIPTION_DONE,
    label: "Prescription Done",
  },
  {
    value: SCHEDULED,
    label: "Scheduled",
  },
  {
    value: RESCHEDULED,
    label: "Rescheduled",
  },
  {
    value: CANCELLED,
    label: "Cancelled",
  },
  {
    value: CHECKED_IN,
    label: "Checked In",
  },
];
export const AppointmentFilterDrawer = ({
  doctors,
  patients,
  applyFilters,
  drawerUtils,
  setDrawerUtils,
  searchParams,
  setSearchParams,
}) => {
  useEffect(() => {
    if (searchParams?.fromDate && searchParams?.toDate) {
      const isValid = dayjs(searchParams?.fromDate) <= dayjs(searchParams?.toDate);
      setDrawerUtils((prev) => ({
        ...prev,
        isDateValid: isValid,
        applyFilterDisable: !isValid,
      }));
    }
  }, [searchParams?.fromDate, searchParams?.toDate]);

  const onApply = (searchParams) => {
    const newSearchParams = {
      ...searchParams,
      fromDate: searchParams.fromDate ? dayjs(searchParams.fromDate).format(DATE_FORMAT) : null,
      toDate: searchParams.toDate ? dayjs(searchParams.toDate).format(DATE_FORMAT) : null,
      type: searchParams.type || null,
    };
    applyFilters(newSearchParams);
  };

  return (
    <FilterDrawer
      title="Filters"
      filters={[
        {
          label: "Search Doctor",
          placeholder: "Select Doctor",
          componentType: "autocomplete",
          options: [{ value: null, label: "All" }, ...doctors],
          onChange: (event, newValue) => {
            setSearchParams({
              ...searchParams,
              doctorId: newValue?.value,
            });
            setDrawerUtils((prev) => ({
              ...prev,
              applyFilterDisable: false,
            }));
          },
          value:
            [{ value: null, label: "All" }, ...doctors].find(
              (option) => option.value === searchParams?.doctorId
            ) || null,
        },
        {
          label: "Search Patient",
          placeholder: "Select Patient",
          componentType: "autocomplete",
          options: [{ value: null, label: "All" }, ...patients],
          onChange: (event, newValue) => {
            setSearchParams({
              ...searchParams,
              patientId: newValue?.value,
            });
            setDrawerUtils((prev) => ({
              ...prev,
              applyFilterDisable: false,
            }));
          },
          value:
            [{ value: null, label: "All" }, ...patients].find(
              (option) => option.value === searchParams?.patientId
            ) || null,
        },
        {
          label: "Appointment Status",
          placeholder: "Select Appointment Status",
          componentType: "autocomplete",
          options: statusOptions,
          value: statusOptions.find((option) => option.value === searchParams?.status) || null,
          onChange: (event, newValue) => {
            setSearchParams({
              ...searchParams,
              status: newValue?.value,
            });
            setDrawerUtils((prev) => ({
              ...prev,
              applyFilterDisable: false,
            }));
          },
        },
        {
          label: "Appointment Type",
          componentType: "radio",
          value: searchParams?.type || null,
          onChange: (e) => {
            setSearchParams({
              ...searchParams,
              type: e.target.value,
            });
            setDrawerUtils((prev) => ({
              ...prev,
              applyFilterDisable: false,
            }));
          },
          options: [
            { value: null, label: "Both" },
            {
              value: TELE_CONSULTATION,
              label: TEXT_FOR_TELECONSULTATION,
            },
            {
              value: CLINIC_VISIT,
              label: TEXT_FOR_CLINIC_VISIT,
            },
          ],
        },
        {
          label: "Select Date Range",
          componentType: "autocomplete",
          options: dateOptions,
          onChange: (e, newValue) => {
            if (newValue?.label === "Custom Range") {
              setDrawerUtils((prev) => ({
                ...prev,
                customRange: true,
              }));
            } else {
              setDrawerUtils((prev) => ({
                ...prev,
                customRange: false,
              }));
            }
            setSearchParams({
              ...searchParams,
              fromDate: newValue?.startDate,
              toDate: newValue?.endDate,
            });
            setDrawerUtils((prev) => ({
              ...prev,
              applyFilterDisable: false,
            }));
          },
          value:
            dateOptions.find(
              (option) =>
                option.startDate === searchParams?.fromDate &&
                option.endDate === searchParams?.toDate
            ) || null,
          placeholder: "Select Date Range",
          customComponent: drawerUtils.customRange && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <FormDatePicker
                format={DATE_FORMAT_DMY}
                label="From Date"
                value={searchParams.fromDate || null}
                onChange={(e) => {
                  setSearchParams({
                    ...searchParams,
                    fromDate: e,
                  });
                }}
                style={{ width: "48%" }}
                error={!drawerUtils.isDateValid}
                errorText={
                  !drawerUtils.isDateValid
                    ? "From Date should be less than or equal to To Date"
                    : ""
                }
              ></FormDatePicker>
              <FormDatePicker
                format={DATE_FORMAT_DMY}
                label="To Date"
                value={searchParams.toDate || null}
                onChange={(e) => {
                  setSearchParams({
                    ...searchParams,
                    toDate: e,
                  });
                }}
                style={{ width: "48%" }}
                error={!drawerUtils.isDateValid}
                errorText={
                  !drawerUtils.isDateValid
                    ? "To Date should be greater than or equal to From Date"
                    : ""
                }
              ></FormDatePicker>
            </div>
          ),
        },
      ]}
      applyFilterDisable={drawerUtils.applyFilterDisable}
      setApplyFilterDisable={(value) =>
        setDrawerUtils((prev) => ({
          ...prev,
          applyFilterDisable: value,
        }))
      }
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      applyFilters={onApply}
      resetFilters={() => {
        const { doctorId, patientId, type, status, fromDate, toDate, ...newObj } = searchParams;
        setSearchParams(newObj);
        setDrawerUtils((prev) => ({
          ...prev,
          customRange: false,
          applyFilterDisable: true,
        }));
        applyFilters(newObj);
      }}
      onClose={() => setDrawerUtils({ ...drawerUtils, openDrawer: false })}
    />
  );
};
