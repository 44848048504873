import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  medicalServiceItems: [],
  patientInvoiceId: null,
  patientInvoiceDetail: {
    invoiceItems: [],
    totalAmount: 0,
    adjustmentResponses: [],
  },
  isPaymentMode: false,
  invoiceAmountDetail: {
    discount: 0,
    tax: 0,
    amount: 0,
  },
};

const billingInvoiceSlice = createSlice({
  name: "billingInvoice",
  initialState,
  reducers: {
    setMedicalServiceItems(state, action) {
      state.medicalServiceItems = action.payload;
    },
    setPatientInvoiceId(state, action) {
      state.patientInvoiceId = action.payload;
    },
    setPatientInvoiceDetail(state, action) {
      state.patientInvoiceDetail = action.payload;
    },
    setIsPaymentMode(state, action) {
      state.isPaymentMode = action.payload;
    },
    setPatientInvoiceItems(state, action) {
      state.patientInvoiceDetail.invoiceItems = action.payload;
    },
    setPatientInvoiceTotal(state, action) {
      state.patientInvoiceDetail.totalAmount = action.payload;
    },
    setPatientInvoiceAdjustments(state, action) {
      state.patientInvoiceDetail.adjustmentResponses = action.payload;
    },
    setInvoiceAmountDetail(state, action) {
      state.invoiceAmountDetail = action.payload;
    },
    setClearInvoiceState(state, action) {
      return initialState;
    },
  },
});

export const {
  setMedicalServiceItems,
  setPatientInvoiceId,
  setPatientInvoiceDetail,
  setIsPaymentMode,
  setPatientInvoiceItems,
  setPatientInvoiceTotal,
  setPatientInvoiceAdjustments,
  setInvoiceAmountDetail,
  setClearInvoiceState,
} = billingInvoiceSlice.actions;
export const BillingInvoiceReducer = billingInvoiceSlice.reducer;

export const getMedicalServiceItems = (state) => state.billingInvoice.medicalServiceItems;
export const getPatientInvoiceId = (state) => state.billingInvoice.patientInvoiceId;
export const getPatientInvoiceDetail = (state) => state.billingInvoice.patientInvoiceDetail;
export const getIsPaymentMode = (state) => state.billingInvoice.isPaymentMode;
export const getPatientInvoiceItems = (state) =>
  state.billingInvoice.patientInvoiceDetail.invoiceItems;
export const getPatientInvoiceAmount = (state) =>
  state.billingInvoice.patientInvoiceDetail.totalAmount;
export const getPatientInvoiceAdjustment = (state) =>
  state.billingInvoice.patientInvoiceDetail.adjustmentResponses;
export const getInvoiceAmountDetail = (state) => state.billingInvoice.invoiceAmountDetail;
