/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";

const CustomOtpField = ({ length = 6, onOtpSubmit = () => {}, handleResendOtp = () => {} }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);
  const [timer, setTimer] = useState(60); // Set the initial timer to 60 seconds

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60); // Calculate minutes
    const secs = seconds % 60; // Calculate remaining seconds
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`; // Format as MM:SS
  };
  useEffect(() => {
    let interval = null;

    setTimer(60); // Reset the timer when OTP is sent
    interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    // allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // submit when 6 digit otp
    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) onOtpSubmit(combinedOtp);

    // Move to next input if current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div>
      <div>
        {otp.map((value, index) => {
          return (
            <input
              key={index}
              type="text"
              ref={(input) => (inputRefs.current[index] = input)}
              value={value}
              onChange={(e) => handleChange(e, index)}
              onClick={() => handleClick(index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="otp-input"
            />
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Typography
          onClick={
            timer === 0
              ? () => {
                  handleResendOtp();
                  setTimer(6);
                  setOtp(new Array(length).fill(""));
                }
              : undefined
          }
          variant="subtitle1"
          color={timer === 0 ? "secondary" : "textSecondary"}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          Resend OTP
        </Typography>
        {timer !== 0 && (
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{ fontWeight: 500, ml: "4px !important" }}
          >
            in {formatTime(timer)}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default CustomOtpField;
