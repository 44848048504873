import dayjs from "dayjs";
import CustomBarChart from "views/Components/EHR/ChartComponents/CustomBarChart";
import CustomDoughnutChart from "views/Components/EHR/ChartComponents/CustomDoughnutChart";
import CustomLineChart from "views/Components/EHR/ChartComponents/CustomLineChart";
import CustomSingleBarChart from "views/Components/EHR/ChartComponents/CustomSingleBarChart";

export const vitalSignType = [
  "BODY_TEMPERATURE",
  "BLOOD_PRESSURE",
  "BLOOD_GLUCOSE",
  // "BODY_MASS_INDEX",
  "BLOOD_OXYGEN",
  "HEART_RATE",
  "RESPIRATORY_RATE",
];

const handleBloodPressureCase = (groupedVitals) => {
  const bloodPressusreSystolic = groupedVitals.find(
    (item) => item.key === "BLOOD_PRESSURE_SYSTOLIC"
  );
  const bloodPressusreDiastolic = groupedVitals.find(
    (item) => item.key === "BLOOD_PRESSURE_DIASTOLIC"
  );

  if (bloodPressusreSystolic || bloodPressusreDiastolic) {
    if (bloodPressusreSystolic) {
      bloodPressusreSystolic?.value?.forEach((item) => {
        item.vitalSignTypeResponse.displayName = "Blood Pressure";
      });
    }

    if (bloodPressusreDiastolic) {
      bloodPressusreDiastolic?.value?.forEach((item) => {
        item.vitalSignTypeResponse.displayName = "Blood Pressure";
      });
    }

    const combinedBloodPressure = {
      key: "BLOOD_PRESSURE",
      value: [
        ...(bloodPressusreSystolic ? bloodPressusreSystolic?.value : []),
        ...(bloodPressusreDiastolic ? bloodPressusreDiastolic?.value : []),
      ],
    };
    const updatedGroupedVitals = groupedVitals?.filter(
      (item) => item.key !== "BLOOD_PRESSURE_SYSTOLIC" && item.key !== "BLOOD_PRESSURE_DIASTOLIC"
    );

    updatedGroupedVitals.push(combinedBloodPressure);

    return updatedGroupedVitals;
  }
};

const hanldeLatestBloodPressureCase = (vital) => {
  const systolicBPs = vital.value.filter((el) => el?.dataType === "BLOOD_PRESSURE_SYSTOLIC");
  const latestSystolicBP = systolicBPs.length
    ? systolicBPs.reduce((latest, current) => {
        return current.dateFrom > latest.dateFrom ? current : latest;
      })
    : { value: "-" };

  const diastolicBPs = vital.value.filter((el) => el?.dataType === "BLOOD_PRESSURE_DIASTOLIC");
  const latestDiastolicBP = diastolicBPs.length
    ? diastolicBPs.reduce((latest, current) => {
        return current.dateFrom > latest.dateFrom ? current : latest;
      })
    : { value: "-" };

  return {
    key: vital.key,
    latestValue: {
      ...latestSystolicBP,
      value: `${latestSystolicBP.value}/${latestDiastolicBP.value}`,
      vitalSignTypeResponse: {
        ...latestSystolicBP.vitalSignTypeResponse,
        displayName: "Blood Pressure",
      },
    },
    isIncreased: 1,
  };
};

export const getPatientLatestVitals = (newVitalData) => {
  const latestVitals = newVitalData.map((vital) => {
    if (vital?.key === "BLOOD_PRESSURE") {
      return hanldeLatestBloodPressureCase(vital);
    }

    const sortedValues = vital.value.sort((a, b) => new Date(b.dateFrom) - new Date(a.dateFrom));

    if (sortedValues.length < 2) {
      // If there are less than two values, we can't compute the percentage difference
      return {
        key: vital.key,
        latestValue: sortedValues[0], // Use the latest value
        difference: null, // No difference available
        differencePercentage: null, // No percentage difference
        isIncreased: 1,
      };
    }
    const latest = sortedValues[0]; // Latest value
    const secondLatest = sortedValues[1]; // Second latest value

    const difference = parseFloat(latest.value) - parseFloat(secondLatest.value); // Calculate difference
    const differencePercentage = ((difference / parseFloat(secondLatest.value)) * 100).toFixed(2); // Calculate percentage difference

    const isIncreased = difference > 0 ? 2 : difference < 0 ? 0 : 1;

    return {
      key: vital.key,
      latestValue: latest,
      difference: parseFloat(difference).toFixed(2),
      differencePercentage: `${differencePercentage}%`, // Format percentage difference
      isIncreased: isIncreased,
    };

    // return {
    //   key: vital.key,
    //   latestValue: vital.value.reduce((latest, current) => {
    //     return current.dateFrom > latest.dateFrom ? current : latest;
    //   }),
    // };
  });

  return latestVitals;
};

export const getEhrDataPoints = (ehrData) => {
  // extract vitals
  const vitals = [];
  ehrData.encounters?.forEach((encounter) => {
    if (encounter?.vitals) {
      vitals.push(...encounter?.vitals);
    }
  });
  if (ehrData.clinicMeasuredVitals) {
    vitals.push(...ehrData.clinicMeasuredVitals);
  }
  if (ehrData.selfMeasuredVitals) {
    vitals.push(...ehrData.selfMeasuredVitals);
  }

  // Group vitals by dataType
  const groupedVitals = vitals.reduce((acc, vital) => {
    const { dataType } = vital;
    let existingGroup = acc.find((group) => group.key === dataType);

    if (!existingGroup) {
      existingGroup = { key: dataType, value: [] };
      acc.push(existingGroup);
    }

    existingGroup.value.push(vital);
    return acc;
  }, []);

  const updatedGroupedVitals = handleBloodPressureCase(groupedVitals);

  if (updatedGroupedVitals) {
    return updatedGroupedVitals;
  }

  return groupedVitals;
};

export const getRandomColorForChart = () => {
  const listColors = ["#29BF91", "#33ADEA", "#DD6B20", "#4C49C7"];
  const randomIndex = Math.floor(Math.random() * listColors.length);
  return listColors[randomIndex];
};

export const getChartDetailForVital = {
  BODY_TEMPERATURE: {
    chartType: "LINE_CHART",
    minValue: 35,
    maxValue: 41,
    stepValue: 2,
  },
  ELECTROCARDIOGRAM: {
    chartType: "LINE_CHART",
    minValue: 2.4,
    maxValue: 3.2,
    stepValue: 0.1,
  },
  BLOOD_PRESSURE_DIASTOLIC: {
    chartType: "LINE_CHART",
    minValue: 50,
    maxValue: 90,
    stepValue: 10,
  },
  BLOOD_PRESSURE_SYSTOLIC: {
    chartType: "LINE_CHART",
    minValue: 80,
    maxValue: 140,
    stepValue: 15,
  },
  BLOOD_PRESSURE: {
    chartType: "LINE_CHART",
    minValue: 50,
    maxValue: 150,
    stepValue: 25,
  },
  BLOOD_GLUCOSE: {
    chartType: "LINE_CHART",
    minValue: 60,
    maxValue: 200,
    stepValue: 35,
  },
  BODY_MASS_INDEX: {
    chartType: "LINE_CHART",
    minValue: 16,
    maxValue: 30,
    stepValue: 3.5,
  },
  BLOOD_OXYGEN: {
    chartType: "SINGLE_BAR_CHART",
    minValue: 90,
    maxValue: 100,
    stepValue: 2,
  },
  HEART_RATE: {
    chartType: "LINE_CHART",
    minValue: 50,
    maxValue: 125,
    stepValue: 25,
  },
  RESPIRATORY_RATE: {
    chartType: "BAR_CHART",
    minValue: 8,
    maxValue: 24,
    stepValue: 4,
  },
  SGPT: {
    chartType: "LINE_CHART",
    minValue: 0,
    maxValue: 50,
    stepValue: 10,
  },
  SGOT: {
    chartType: "BAR_CHART",
    minValue: 3,
    maxValue: 37,
    stepValue: 6,
  },
  ALKALINE_PHOSPHATASE: {
    chartType: "LINE_CHART",
    minValue: 45,
    maxValue: 120,
    stepValue: 15,
  },
  GAMMA_GLUTAMYL_TRANSFERASE: {
    chartType: "BAR_CHART",
    minValue: 0,
    maxValue: 60,
    stepValue: 12,
  },
  PROTEINS_TOTAL: {
    chartType: "LINE_CHART",
    minValue: 6.0,
    maxValue: 9.0,
    stepValue: 0.6,
  },
  ALBUMIN: {
    chartType: "BAR_CHART",
    minValue: 3.0,
    maxValue: 5.5,
    stepValue: 0.5,
  },
  GLOBULIN: {
    chartType: "LINE_CHART",
    minValue: 1.5,
    maxValue: 4.5,
    stepValue: 0.6,
  },
  A_G_RATIO: {
    chartType: "BAR_CHART",
    minValue: 0.5,
    maxValue: 2.5,
    stepValue: 0.4,
  },
  BILIRUBIN_TOTAL: {
    chartType: "LINE_CHART",
    minValue: 0.0,
    maxValue: 1.5,
    stepValue: 0.3,
  },
  BILIRUBIN_CONJUGATED: {
    chartType: "BAR_CHART",
    minValue: 0.0,
    maxValue: 0.5,
    stepValue: 0.1,
  },
  BILIRUBIN_UNCONJUGATED: {
    chartType: "LINE_CHART",
    minValue: 0.0,
    maxValue: 1.0,
    stepValue: 0.2,
  },
  UREA: {
    chartType: "BAR_CHART",
    minValue: 15,
    maxValue: 50,
    stepValue: 7,
  },
  CREATININE: {
    chartType: "LINE_CHART",
    minValue: 0.0,
    maxValue: 1.5,
    stepValue: 0.3,
  },
  URIC_ACID: {
    chartType: "BAR_CHART",
    minValue: 3.0,
    maxValue: 10,
    stepValue: 1.4,
  },
  SODIUM: {
    chartType: "LINE_CHART",
    minValue: 130,
    maxValue: 150,
    stepValue: 4,
  },
  POTASSIUM: {
    chartType: "BAR_CHART",
    minValue: 3.0,
    maxValue: 10.0,
    stepValue: 1.4,
  },
  CHLORIDE: {
    chartType: "LINE_CHART",
    minValue: 95,
    maxValue: 110,
    stepValue: 3,
  },
  CHOLESTEROL: {
    chartType: "BAR_CHART",
    minValue: 100,
    maxValue: 220,
    stepValue: 25,
  },
  HDL_CHOLESTEROL: {
    chartType: "LINE_CHART",
    minValue: 30,
    maxValue: 70,
    stepValue: 8,
  },
  TRIGLYCERIDE: {
    chartType: "BAR_CHART",
    minValue: 0,
    maxValue: 170,
    stepValue: 40,
  },
  VLDL: {
    chartType: "LINE_CHART",
    minValue: 0,
    maxValue: 50,
    stepValue: 5,
  },
  CHOL_HDL_RATIO: {
    chartType: "BAR_CHART",
    minValue: 0,
    maxValue: 4.5,
    stepValue: 0.9,
  },
  LDL_CHOLESTEROL: {
    chartType: "LINE_CHART",
    minValue: 0,
    maxValue: 120,
    stepValue: 25,
  },
};

export const getDataForChart = (vitalType, vitalData, chartType) => {
  let values = [];
  let labels = [];
  if (
    vitalType === "BLOOD_PRESSURE" &&
    (chartType === "LINE_CHART" || chartType === "SINGLE_BAR_CHART")
  ) {
    const bloodPressureSystolic = vitalData?.filter(
      (item) => item.dataType === "BLOOD_PRESSURE_SYSTOLIC"
    );
    const bloodPressureDiastolic = vitalData?.filter(
      (item) => item.dataType === "BLOOD_PRESSURE_DIASTOLIC"
    );
    let systolicColor = getRandomColorForChart();
    let diastolicColor;
    do {
      diastolicColor = getRandomColorForChart();
    } while (diastolicColor === systolicColor);

    values.push({
      values: bloodPressureSystolic?.map((el) => el.value),
      label: "Systolic",
      lineColor: systolicColor,
    });
    values.push({
      values: bloodPressureDiastolic?.map((el) => el.value),
      label: "Diastolic",
      lineColor: diastolicColor,
    });

    vitalData?.forEach((vital) => {
      const formattedDate = dayjs(vital.dateTo).format("DD/MM/YY");
      if (!labels.includes(formattedDate)) {
        labels.push(formattedDate);
      }
    });
  } else if (chartType === "LINE_CHART") {
    values.push({
      values: [],
      label: vitalData?.[0]?.vitalSignTypeResponse?.displayName,
      lineColor: getRandomColorForChart(),
    });
    vitalData?.forEach((vital) => {
      labels.push(dayjs(vital.dateTo).format("DD/MM/YY"));
      values[0]?.values.push(vital.value);
    });
  } else if (chartType === "BAR_CHART") {
    vitalData?.forEach((vital) => {
      labels.push(dayjs(vital.dateTo).format("DD/MM/YY"));
      values.push({
        min: vital.value,
        // expected data to be range
        // creating range for bar graph by adding random integer to the stored value
        max: `${parseInt(vital.value) + 3}`,
      });
    });
  } else if (chartType === "SINGLE_BAR_CHART") {
    values.push({
      values: [],
      label: vitalData?.[0]?.vitalSignTypeResponse?.displayName,
      lineColor: getRandomColorForChart(),
    });
    vitalData?.forEach((vital) => {
      labels.push(dayjs(vital.dateTo).format("DD/MM/YY"));
      values[0]?.values.push(vital.value);
    });
  }
  return {
    labels: labels,
    values: values,
  };
};

export const calculateYaxisRanges = (vitalType, modifiedVitalData, chartType) => {
  if (chartType === "LINE_CHART") {
    let updatedRange = modifiedVitalData.values.reduce((acc, current) => {
      current.values.forEach((item) => {
        if (acc?.min) {
          acc.min = acc.min <= Number(item) ? acc.min : Number(item);
        } else {
          acc.min = Number(item);
        }
        if (acc?.max) {
          acc.max = acc.max >= Number(item) ? acc.max : Number(item);
        } else {
          acc.max = Number(item);
        }
      });

      return acc;
    }, {});

    return calculateYAxisRange(updatedRange.min, updatedRange.max);
  } else if (chartType === "SINGLE_BAR_CHART") {
    let updatedRange = modifiedVitalData.values.reduce((acc, current) => {
      if (acc?.min) {
        acc.min = acc.min <= Number(current?.value) ? acc.min : Number(current?.value);
      } else {
        acc.min = Number(current?.value);
      }
      if (acc?.max) {
        acc.max = acc.max >= Number(current?.value) ? acc.max : Number(current?.value);
      } else {
        acc.max = Number(current?.value);
      }

      return acc;
    }, {});
    return calculateYAxisRange(updatedRange.min, updatedRange.max);
  } else if (chartType === "BAR_CHART") {
    let updatedRange = modifiedVitalData.values.reduce((acc, current) => {
      if (acc?.min) {
        acc.min = acc.min <= Number(current?.min) ? acc.min : Number(current?.min);
      } else {
        acc.min = Number(current?.min);
      }
      if (acc?.max) {
        acc.max = acc.max >= Number(current?.max) ? acc.max : Number(current?.max);
      } else {
        acc.max = Number(current?.max);
      }

      return acc;
    }, {});

    return calculateYAxisRange(updatedRange.min, updatedRange.max);
  }
};

export const getChartByVitalType = (
  vitalType,
  vitalData,
  displayName,
  options,
  noHeading,
  barThickness,
  hidePoints,
  parentDivStyle,
  graphStyle,
  chartType
) => {
  const chartDetail = getChartDetailForVital[vitalType];
  if (!chartType) {
    chartType = chartDetail.chartType;
  }
  const modifiedVitalData = getDataForChart(vitalType, vitalData, chartType);

  const yAxisRange = calculateYaxisRanges(vitalType, modifiedVitalData, chartType);

  if (chartType === "LINE_CHART") {
    return (
      <CustomLineChart
        parentDivStyle={parentDivStyle}
        hidePoints={hidePoints}
        graphStyle={graphStyle}
        noHeading={noHeading}
        customOptions={options}
        title={vitalType}
        displayName={displayName}
        xAxisLabel={"Date"}
        yAxisLabel={vitalData?.[0]?.vitalSignTypeResponse?.unitAbbreviation}
        values={modifiedVitalData.values}
        labels={modifiedVitalData.labels}
        yAxisRange={yAxisRange}
      />
    );
  } else if (chartType === "BAR_CHART") {
    return (
      <CustomBarChart
        barThickness={barThickness}
        parentDivStyle={parentDivStyle}
        graphStyle={graphStyle}
        noHeading={noHeading}
        customOptions={options}
        title={vitalType}
        displayName={displayName}
        xAxisLabel={"Date"}
        yAxisLabel={vitalData?.[0]?.vitalSignTypeResponse?.unitAbbreviation}
        values={modifiedVitalData.values}
        labels={modifiedVitalData.labels}
        barColor={getRandomColorForChart()}
        yAxisRange={yAxisRange}
      ></CustomBarChart>
    );
  } else if (chartType === "PIE_CHART") {
    return <CustomDoughnutChart title={vitalType}></CustomDoughnutChart>;
  } else if (chartType === "SINGLE_BAR_CHART") {
    return (
      <CustomSingleBarChart
        barThickness={barThickness}
        parentDivStyle={parentDivStyle}
        graphStyle={graphStyle}
        noHeading={noHeading}
        customOptions={options}
        title={vitalType}
        displayName={displayName}
        xAxisLabel={"Date"}
        yAxisLabel={vitalData?.[0]?.vitalSignTypeResponse?.unitAbbreviation}
        values={modifiedVitalData.values}
        labels={modifiedVitalData.labels}
        barColor={getRandomColorForChart()}
        yAxisRange={yAxisRange}
      ></CustomSingleBarChart>
    );
  }
};

// Organs to vitals map

export const organToVitalMap = {
  BRAIN: ["BLOOD_PRESSURE", "BODY_TEMPERATURE"],
  LUNGS: ["BLOOD_OXYGEN", "RESPIRATORY_RATE", "BODY_TEMPERATURE"],
  LIVER: [
    "ALBUMIN",
    "GLOBULIN",
    "SGPT",
    "SGOT",
    "PROTEINS_TOTAL",
    "ALKALINE_PHOSPHATASE",
    "GAMMA_GLUTAMYL_TRANSFERASE",
    "A_G_RATIO",
    "BILIRUBIN_TOTAL",
    "BILIRUBIN_CONJUGATED",
    "BILIRUBIN_UNCONJUGATED",
  ],
  INTESTINES: ["BODY_TEMPERATURE"],
  STOMACH: ["BODY_TEMPERATURE"],
  KIDNEY: ["UREA", "CREATININE", "URIC_ACID", "SODIUM", "POTASSIUM", "CHLORIDE"],
  BLADDER: ["BODY_TEMPERATURE"],
  HEART: [
    "HEART_RATE",
    "BLOOD_PRESSURE",
    "ELECTROCARDIOGRAM",
    "CHOLESTEROL",
    "HDL_CHOLESTEROL",
    "TRIGLYCERIDE",
    "VLDL",
    "CHOL_HDL_RATIO",
    "LDL_CHOLESTEROL",
  ],
  MOUTH: ["BODY_TEMPERATURE"],
  THROAT: ["BODY_TEMPERATURE"],
};
export const vitalToOrganMap = Object.keys(organToVitalMap).reduce((map, organ) => {
  organToVitalMap[organ].forEach((vital) => {
    if (!map[vital]) {
      map[vital] = [];
    }
    map[vital].push(organ);
  });
  return map;
}, {});

export const calculateYAxisRange = (min, max) => {
  const range = max - min;

  let expandedMin = Math.max(min - range * 0.1, 0);
  let expandedMax = max + range * 0.1;

  let stepSize = (expandedMax - expandedMin) / 5;

  if (max <= 10) {
    expandedMin = parseFloat(expandedMin.toFixed(2));
    expandedMax = parseFloat(expandedMax.toFixed(2));
    stepSize = parseFloat(stepSize.toFixed(2));
  } else {
    expandedMin = Math.floor(expandedMin);
    expandedMax = Math.ceil(expandedMax);
    stepSize = Math.ceil(stepSize);
  }

  return {
    updatedMin: expandedMin,
    updatedMax: expandedMax,
    stepSize: stepSize,
  };
};

export const BRAIN = "BRAIN";
export const LUNGS = "LUNGS";
export const LIVER = "LIVER";
export const INTESTINES = "INTESTINES";
export const STOMACH = "STOMACH";
export const KIDNEY = "KIDNEY";
export const BLADDER = "BLADDER";
export const HEART = "HEART";
export const THROAT = "THROAT";
export const MOUTH = "MOUTH";

export const specializationToOrgansMapper = {
  Cardiology: [HEART],
  Neurology: [BRAIN],
  "General Surgery": [STOMACH, BRAIN, HEART],
  Psychiatry: [BRAIN],
  Gastroenterology: [STOMACH, INTESTINES, LIVER],
  Urology: [KIDNEY, BLADDER],
  Pulmonology: [LUNGS],
  "MBBS/General Physician": [KIDNEY, STOMACH, LUNGS],
  Nephrology: [KIDNEY],
  Allergology: [THROAT],
  Orthodontics: [MOUTH],
};

export const specializationForEhr = [
  { name: "", id: "" },
  { name: "Cardiology", id: "Cardiology" },
  { name: "Neurology", id: "Neurology" },
  { name: "General Surgery", id: "General Surgery" },
  { name: "Psychiatry", id: "Psychiatry" },
  { name: "Gastroenterology", id: "Gastroenterology" },
  { name: "Urology", id: "Urology" },
  { name: "Pulmonology", id: "Pulmonology" },
  { name: "MBBS/General Physician", id: "MBBS/General Physician" },
  { name: "Nephrology", id: "Nephrology" },
  { name: "Allergology", id: "Allergology" },
  { name: "Orthodontics", id: "Orthodontics" },
];

export const organIdMapper = {
  BRAIN: "human-body-brain",
  LUNGS: "human-body-lungs",
  LIVER: "human-body-liver",
  INTESTINES: "human-body-intestines",
  STOMACH: "human-body-stomach",
  KIDNEY: "human-body-kidney",
  BLADDER: "human-body-bladder",
  HEART: "human-body-heart",
  THROAT: "human-body-throat",
  MOUTH: "human-body-mouth",
};
