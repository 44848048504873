import dayjs from "dayjs";
import { ACTIVE_STATE, DATE_FORMAT, DATE_TIME_FORMAT } from "store/constant";

export const convertPrescriptionPadDataToApiFormat = async (
  { medicationsArr, symptomsArr, diagnosisArr, labInvestigationsArr, advicesArr, soapNotes },
  appointment,
  prescriptionId
) => {
  const getPrescriptionDetails = (medicines) =>
    medicines.map((medicine) => ({
      id: medicine.id ?? null,
      medicationId: medicine.medicationId,
      duration: medicine.duration,
      purpose: null,
      frequency: medicine.frequency?.trim() ?? null,
      doseTiming: medicine.doseTiming,
      prescribingDoctor: null,
      instructions: medicine.instructions,
      patientId: appointment?.patientId,
      status: medicine.status ?? ACTIVE_STATE,
      startDate: dayjs().format(DATE_TIME_FORMAT),
      endDate: getEndDateForPrescription(medicine.duration),
      prescriptionId: prescriptionId ?? null,
      medicationResponse: {
        id: medicine.medicationId,
        name: medicine.displayName,
        isRx: true,
      },
    }));

  const getSymptomDetails = (symptoms) =>
    symptoms.map((symptom) => ({
      id: symptom.id ?? null,
      symptomId: symptom.symptomId,
      severity: symptom.severity,
      since: symptom.since,
      complaints: "complaints",
      status: symptom.status ?? ACTIVE_STATE,
      prescriptionId: prescriptionId ?? null,
      symptomResponse: {
        id: symptom.symptomId,
        name: symptom.displayName,
      },
    }));

  const getDiagnosisDetails = (diagnosisList) =>
    diagnosisList.map((diagnosis) => ({
      id: diagnosis.id ?? null,
      diagnosisId: diagnosis.diagnosisId,
      since: "2 days",
      severity: "MODERATE",
      conclusions: diagnosis.conclusions,
      status: diagnosis.status ?? ACTIVE_STATE,
      prescriptionId: prescriptionId ?? null,
      diagnosisResponse: {
        id: diagnosis.diagnosisId,
        name: diagnosis.displayName,
      },
    }));

  const getLabTestDetails = (labTests) =>
    labTests.map((labTest) => ({
      id: labTest.id ?? null,
      patientId: appointment?.patientId,
      labTestId: labTest.labTestId,
      reportDate: dayjs().format(DATE_FORMAT),
      performedBy: null,
      orderedBy: null,
      instructions: labTest.instructions,
      status: labTest.status ?? ACTIVE_STATE,
      prescriptionId: prescriptionId ?? null,
      labTestResponse: {
        id: labTest.labTestId,
        name: labTest.displayName,
      },
    }));

  let generatedSoapNotes;
  if (soapNotes) {
    let { oldPrescription, ...restSoap } = soapNotes;
    generatedSoapNotes = restSoap;
  }

  return {
    appointmentId: appointment?.id,
    appointmentResponse: appointment,
    additionalRecommendations: advicesArr.map((advice) => advice?.displayName).join(", "),
    doctorSignature: "Signature",
    followUpDate: dayjs().add(7, "day").format(DATE_FORMAT),
    referredTo: null,
    status: ACTIVE_STATE,
    templateId: null,
    prescriptionMedicationResponses: getPrescriptionDetails(medicationsArr),
    prescriptionSymptomResponses: getSymptomDetails(symptomsArr),
    prescriptionDiagnosisResponses: getDiagnosisDetails(diagnosisArr),
    prescriptionLabReportResponses: getLabTestDetails(labInvestigationsArr),
    soapNotes: generatedSoapNotes,
  };
};

const getEndDateForPrescription = (duration) => {
  const [countStr, type] = duration.split(" ");
  const count = parseInt(countStr);
  if (isNaN(count)) return null;

  let endDate = dayjs();
  switch (type?.toLowerCase()) {
    case "day":
    case "days":
      endDate = endDate.add(count, "day");
      break;
    case "week":
    case "weeks":
      endDate = endDate.add(count, "week");
      break;
    case "month":
    case "months":
      endDate = endDate.add(count, "month");
      break;
    default:
      return null;
  }

  return endDate.format(DATE_TIME_FORMAT);
};
