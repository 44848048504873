import { Typography } from "@mui/material";
import Reveal from "views/utilities/Reveal";

const NoAppointmentSelectedPage = () => {
  return (
    <Reveal
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "12px",
        padding: "20px",
        backgroundColor: "#ffffff",
        gap: "1rem",
        height: "100%",
      }}
    >
      <div>
        <img
          src="/images/time_management_acute.svg"
          alt="Page not found"
          height={"352px"}
          width={"352px"}
        />
      </div>
      <div style={{ flexGrow: 1 }}>
        <div
          style={{
            gap: "0px",
            opacity: "0px",
          }}
        >
          <Typography fontWeight={600} fontSize={"32px"} lineHeight={"48px"} color={"#29BF91"}>
            <span style={{ color: "#455a64" }}>No Appointments </span>Scheduled...
          </Typography>
        </div>
        <div
          style={{
            top: "204px",
            left: "392px",
          }}
        >
          <Typography
            fontWeight={400}
            fontSize={"14px"}
            lineHeight={"21px"}
            color={"#455a64"}
            margin={"20px 0"}
          >
            You do not have any appointments scheduled for today.
          </Typography>
        </div>
      </div>
    </Reveal>
  );
};

export default NoAppointmentSelectedPage;
