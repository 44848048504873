import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";
import { DATE_FORMAT_DMY, MAX_AGE_FOR_REGUAR_DOB, MIN_AGE_FOR_DOCTOR } from "store/constant";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";

const OnboardingStep2 = ({
  dobError,
  setDobError,
  handleBlur,
  setFieldValue,
  handleChange,
  values,
  errors,
  touched,
}) => {
  const LOWER_BOUND_DATE_FOR_REGUAR_DOB = dayjs().subtract(MAX_AGE_FOR_REGUAR_DOB, "year");
  const MAX_DATE_FOR_DOCTOR = dayjs().subtract(MIN_AGE_FOR_DOCTOR, "year");
  return (
    <>
      <div className="mb-3">
        <label htmlFor="dateOfBirth" className="form-label onboarding-label">
          When is your date of birth?
        </label>
        <Grid>
          <FormDatePicker
            format={DATE_FORMAT_DMY}
            size={"small"}
            value={values?.dateOfBirth || null}
            maxDate={MAX_DATE_FOR_DOCTOR}
            minDate={LOWER_BOUND_DATE_FOR_REGUAR_DOB}
            background={"#fff"}
            inputPropStyle={{ fontSize: "16px", fontWeight: "400" }}
            onChange={(date) => {
              setFieldValue("dateOfBirth", date);
              const currentDate = dayjs();
              if (date === null) {
                setDobError("Please select your date of birth.");
              } else if (!date.isValid()) {
                setDobError("Please select a valid 'Date of birth' value.");
              } else if (date > currentDate) {
                setDobError("Date of birth cannot be a future date.");
              } else if (date > MAX_DATE_FOR_DOCTOR) {
                setDobError(
                  `Age must be at least ${currentDate.diff(MAX_DATE_FOR_DOCTOR, "year")} years.`
                );
              } else if (date < LOWER_BOUND_DATE_FOR_REGUAR_DOB) {
                setDobError(
                  `Date of birth cannot be less than the year ${LOWER_BOUND_DATE_FOR_REGUAR_DOB.year()}`
                );
              } else {
                setDobError("");
              }
            }}
            border={"1px solid #e6eef1"}
            borderOnFocus={"1px solid #29bf91"}
            height={"24.43px"}
            outlinedInputStyle={{ lineHeight: "24.43px !important" }}
            error={Boolean(touched.dateOfBirth && dobError)}
            errorText={dobError}
          />
        </Grid>
      </div>
    </>
  );
};

export default OnboardingStep2;
