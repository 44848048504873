// material-ui
import { Link, Typography, Stack } from "@mui/material";
import RIf from "ui-component/RIf";
import DemoAuthFooter from "views/pages/demo/DemoAuthFooter";

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = ({ isDemo }) => {
  const hostURL = window.origin.includes("arog") ? window.origin : "https://hc.argusservices.in";
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          variant="subtitle2"
          component={Link}
          href="https://www.argusoft.com/"
          target="_blank"
          underline="hover"
          width="20%"
        >
          Arog
        </Typography>

        <div className="footer-services">
          <Typography
            variant="h5"
            style={{ color: "#004c70" }}
            component={Link}
            href={`${hostURL}/subscription.html`}
            target="_blank"
            underline="hover"
          >
            Our Premium Offerings
          </Typography>
          <span className="divider" style={{ margin: "0 10px", color: "#004c70" }}>
            |
          </span>
          <Typography
            variant="h5"
            style={{ color: "#004c70" }}
            component={Link}
            href={`${hostURL}/privacy-policy.html`}
            target="_blank"
            underline="hover"
          >
            Privacy Policy/Terms & Conditions
          </Typography>
          <span className="divider" style={{ margin: "0 10px", color: "#004c70" }}>
            |
          </span>
          <Typography
            style={{ color: "#004c70" }}
            variant="h5"
            component={Link}
            href={`${hostURL}/refund-policy.html`}
            target="_blank"
            underline="hover"
          >
            Refund Policy
          </Typography>
          <RIf show={isDemo}>
            <>
              <span className="divider" style={{ margin: "0 10px", color: "#004c70" }}>
                |
              </span>
              <DemoAuthFooter />
            </>
          </RIf>
        </div>
        <Typography
          variant="subtitle2"
          component={Link}
          href="https://www.argusoft.com/"
          target="_blank"
          underline="hover"
          width="20%"
          textAlign="right"
        >
          &copy; argusoft.com
        </Typography>
      </Stack>
    </>
  );
};

export default AuthFooter;
