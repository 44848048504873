import React, { useRef, useState } from "react";
// material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Tab,
  Box,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
// assets
import "../../../assets/scss/style.scss";
import CustomButton from "ui-component/custom-components/CustomButton";
import "../../../assets/scss/animations.scss";

// seful imports

import {
  CorporateFareOutlined,
  DirectionsRunOutlined,
  DomainVerificationOutlined,
  HourglassBottomOutlined,
  SwitchLeftOutlined,
  SwitchRightOutlined,
  VideoChatOutlined,
} from "@mui/icons-material";
import {
  CLINIC_ADMIN,
  DATE_FORMAT,
  DOCTOR,
  FRONT_DESK,
  NONE,
  PATIENT,
  TIME_FORMAT,
  appEnv,
  isDemo,
  orgId,
  roleName,
  SUPER_ADMIN,
} from "store/constant";
import { currentActiveUser, blockCalenderOptions, tenantResponse, username } from "store/constant";
import { useEffect } from "react";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { useLocation, useNavigate } from "react-router";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  checkInAvailabilities,
  createBlockAvailabilities,
  currentStatusDoctor,
} from "services/Availability";
import { getOrgById, getOrgByIds } from "services/organizationService";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  setUserFeatures,
  setRenderSidebar,
  setUserOrgId,
  getRenderSidebar,
  getUserFeatures,
  getUserOrgId,
} from "store/Slices/userSlice";
import { useSelector } from "react-redux";
import { getIsSyncing, setShowSyncModal, getIsOnline } from "store/Slices/cloudSyncSlice";
import RIf from "ui-component/RIf";
import { Formik } from "formik";
import { checkoutValidation } from "views/Components/Common/ValidationSchema/checkoutValidation";
import { getBlockAvailabilitiesByDate } from "services/Availability";
import eventBus from "utils/eventBus";
import DoctorBlockCalendar from "views/Components/Doctor/doctor-components/DoctorBlockCalendar";
import { getProfilePicture } from "services/patientService";
import { profileDashboard } from "menu-items/dashboard";
import { TabContext, TabList } from "@mui/lab";
import styled from "@emotion/styled";
import { setPrescriptionId, setPrescriptionPadData } from "store/Slices/prescriptionPadSlice";
import { setClearInvoiceState } from "store/Slices/billingInvoiceSlice";
import {
  getSethuSirLayout,
  getTabValue,
  setSethuSirLayout,
  setTabValue,
} from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/utilSlice";
import { principle } from "../../../services/AuthService";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({
  toggleMainClass,
  setSecondarySidebarData,
  handleLeftDrawerToggle,
  settingsIconChecked,
  routeBeforeSettingsSection,
  setRouteBeforeSettingsSection,
  setSettingsIconChecked,
  setSidebarList,
  profileChecked,
  setProfileChecked,
  appointmentInfo,
}) => {
  /*
  todo : by default we are setting isCheckedIn true when doctor login.
  once backend stores the checkIn flag, we can fetch status and display.
   */
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [userOrganisationAssociation, setUserOrganisationAssociation] = useState([]);
  const { handleClick } = useContext(ToastContext);
  const currentUser = currentActiveUser();
  const isOnline = useSelector(getIsOnline);

  const sethuSirLayout = useSelector(getSethuSirLayout);

  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const doctorOrgId = useSelector(getUserOrgId);

  const [organizationDetails, setOrganizationDetails] = useState([]);
  const [buttonLabel, setButtonLabel] = useState("Unavailable");
  const [userData, seUserData] = useState(currentActiveUser());
  const [isLoading, setIsLoading] = useState(false);
  const [profileImageFileWithContent, setProfileImageFileWithContent] = useState(null);
  const userFeatures = useSelector(getUserFeatures);
  const userOrgId = useSelector(getUserOrgId);
  const tabValue = useSelector(getTabValue);

  const items = [
    {
      label: "My Appointments",
      value: 0,
    },
    {
      label: "Analytics",
      value: 1,
    },
  ];

  const formikRef = useRef(null);

  const [menuItemsAvailability, setMenuItemsAvailability] = useState([
    { label: "Unavailable", badgeColor: "#345678", disabled: false },
    { label: "Available", badgeColor: "#29BF91", disabled: false },
  ]);
  const organizationId = orgId();

  const isRunningOnElectron = appEnv === "electron";
  const tenantResponseObj = tenantResponse();

  useEffect(() => {
    if (userData?.id && userData?.roleName === DOCTOR) {
      const fetchProfilePic = async () => {
        setIsLoading(true);
        try {
          const profilePicResponse = await getProfilePicture(userData.id);
          const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;
          const profilePicWithContent = {
            filename: profilePicResponse.data.filename,
            content: content,
          };
          setProfileImageFileWithContent(profilePicWithContent);
        } catch (error) {
          setProfileImageFileWithContent(null);
          console.error("Error fetching profile picture", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchProfilePic();
    }
  }, [userData?.id]);

  useEffect(() => {
    setButtonLabel(isCheckedIn ? "Available" : "Unavailable");
    setMenuItemsAvailability([
      {
        label: "Unavailable",
        badgeColor: "#345678",
        disabled: isCheckedIn ? false : true,
      },
      {
        label: "Available",
        badgeColor: "#29BF91",
        disabled: isCheckedIn ? true : false,
      },
    ]);
  }, [isCheckedIn]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: doctorStatus } = await currentStatusDoctor(currentUser?.roleBasedId, orgId());
        setIsCheckedIn(doctorStatus);
        setButtonLabel(doctorStatus ? "Available" : "Unavailable");
      } catch (error) {}
    };
    if (currentUser?.roleName === DOCTOR) {
      fetchData();
      const intervalId = setInterval(fetchData, 30 * 60 * 1000);
      return () => clearInterval(intervalId); // Clean up the interval when the component unmounts
    }
  }, [organizationId]);

  const menuItemsClinic = organizationDetails.map((item, index) => ({
    label: `${item.name} - ${item.code}`,
    ...item,
  }));

  useEffect(() => {
    // Early return for role 5
    if (roleName() === PATIENT) return;

    const fetchData = async () => {
      try {
        if (tenantResponseObj) {
          const organizationIds = tenantResponseObj.map((obj) => obj.organizationId);
          const { data: organizationDetailsResponse } = await getOrgByIds(organizationIds, true);
          setOrganizationDetails(organizationDetailsResponse);
        }
      } catch (error) {
        console.error("Error fetching doctor data");
      }
    };

    fetchData();
  }, [handleClick, userData]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const handleCustomEvent = async (data) => {
      if (data.isBlockedDateChanged === true) {
        await handleCheckInOut(currentUser.roleBasedId);
      }
    };
    eventBus.on("doctorAvailability", handleCustomEvent);
    return () => {
      eventBus.off("doctorAvailability", handleCustomEvent); // Cleanup the event litabner
    };
  }, []);

  const handleCheckInOut = async (doctorId) => {
    try {
      const { data: blockAvailabilities } = await getBlockAvailabilitiesByDate(
        doctorId,
        dayjs().format(DATE_FORMAT)
      );
      const currTime = dayjs().format(TIME_FORMAT);
      if (
        blockAvailabilities &&
        blockAvailabilities.length > 0 &&
        blockAvailabilities[0].serviceType === NONE &&
        currTime >= blockAvailabilities[0].startTime &&
        currTime <= blockAvailabilities[0].endTime
      ) {
        setIsCheckedIn(false);
      } else {
        setIsCheckedIn(true);
      }
    } catch (error) {
      handleClick("error", "There seems to be an error while checking-in.");
    }
  };

  // const handleCheckout = async () => {
  //   const toastMessage =
  //     checkoutType === "hours" ? `Checked Out for ${hours} Hours.` : "Checked Out for Full day.";

  //   const now = dayjs();
  //   const startTime = now.format(TIME_FORMAT);
  //   const hoursToAdd = parseInt(hours);
  //   const calculatedHours = now.hour() + hoursToAdd;
  //   if (calculatedHours >= 24) {
  //     handleClick("warning", "Hours exceeding the day");
  //     setHours(null);
  //     setCheckoutType("fullDay");
  //     return;
  //   }
  //   const endTime = now.add(hoursToAdd, "hour").format(TIME_FORMAT);
  //   const payload = {
  //     doctorId: currentUser.roleBasedId,
  //     organizationId:
  //       menuItemsClinic && menuItemsClinic.length > 1
  //         ? selectedOrganizations
  //         : [menuItemsClinic[0].id],
  //     blockingType: checkoutType === "hours" ? "CUSTOM_HOURS" : "ENTIRE_DAY",
  //     startTime: checkoutType === "hours" ? startTime : null,
  //     endTime: checkoutType === "hours" ? endTime : null,
  //     serviceType: selectedAppointmentOption,
  //   };
  //   try {
  //     await createBlockAvailabilities(payload);
  //     handleClick("success", toastMessage);
  //     setIsCheckedIn(false);
  //     handleCloseModal();
  //   } catch (error) {
  //     handleClick("error", "Error checking out");
  //   }
  // };

  const handleAvailabilityMenu = async () => {
    if (isCheckedIn) {
      // check-out
      setOpenModal(true);
    } else {
      // check-in
      const payload = {
        doctorId: currentUser.roleBasedId,
        organizationId: [organizationId],
      };
      try {
        await checkInAvailabilities(payload);
        handleClick("success", "You have successfully checked-in.");
        setIsCheckedIn(true);
      } catch (error) {
        handleClick("error", "There seems to be an error while checking-in.");
      }
    }
  };

  const handleAxiosBaseUrlChange = (url) => {
    if (url) {
      localStorage.setItem("subDomain", url);
    }
  };

  const handleMenuClick = async (item) => {
    const foundTenant = tenantResponseObj.find((el) => el.organizationId === item?.id);
    dispatch(setUserOrgId(foundTenant.organizationId));
    handleAxiosBaseUrlChange(foundTenant.baseUrl);
    setSecondarySidebarData(null);

    const principalResponseList = await principle(username());
    // features is newly updated features of this user
    const { features, userResponse } = principalResponseList["data"];
    dispatch(setUserFeatures(features));

    const selectedOrganuzationResponse = await getOrgById(foundTenant.organizationId);
    localStorage.setItem(
      "facilityBridgeLinkId",
      selectedOrganuzationResponse?.data?.facilityBridgeLinkId
    );
    localStorage.setItem("currentActiveUser", JSON.stringify(userResponse));
    toggleMainClass((prevClass) => {
      if (
        prevClass === "main responsive-shrink" ||
        prevClass === "main shrink" ||
        prevClass === "main sub-sidebar"
      ) {
        return "main";
      } else {
        return prevClass;
      }
    });
    navigate("/home/dashboard");
  };

  const checkRoleAndOrganization = () => {
    const acceptedRoles = [CLINIC_ADMIN, DOCTOR, FRONT_DESK];
    if (acceptedRoles.includes(roleName()) && menuItemsClinic && menuItemsClinic.length > 1) {
      return true;
    } else {
      return false;
    }
  };

  // Sync Business Logic
  const isSyncing = useSelector(getIsSyncing);
  const onSyncButtonClick = async () => {
    dispatch(setShowSyncModal(true));
  };

  const renderSidebarData = useSelector(getRenderSidebar);
  const checkSettingIconVisible = () => {
    const acceptedRoles = [CLINIC_ADMIN, DOCTOR, SUPER_ADMIN];
    return acceptedRoles.includes(roleName());
  };

  const CustomizedToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#29bf91",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      height: "2rem",
      fontSize: "1rem",
      textAlign: "center",
      color: "#fff",
      border: "0.5px solid #29bf91",
      backgroundColor: "#29bf91",
      display: "flex",
      alignItems: "center",
      padding: "8px",
    },
  }));

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const divElement = document.querySelector(".main-content");

    const handleResize = () => {
      try {
        if (divElement.offsetWidth <= 950) {
          setIsSmallScreen(true);
        } else {
          setIsSmallScreen(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(divElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      className={
        "row header" +
        (roleName() === DOCTOR ? " responsive-header is-doctor " : " ") +
        "fixed-row" +
        (roleName() === DOCTOR && location.pathname === "/home/dashboard"
          ? " mosaic-doctor-dashboard"
          : "")
      }
    >
      {/* {userData?.roleName !== DOCTOR && (
        <div className="col col-1">
          <form
            className="global-search"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <input className="global-search" type="search" placeholder="Search..." />
            <button className="btn btn-transparent form-button-group">
              <img
                src="/images/hc_mic.svg"
                alt="hc_mic"
                className="inline-icon ri-mic-line ri-lg"
              />
            </button>
          </form>
        </div>
      )} */}
      {userData?.roleName === DOCTOR && (
        <div
          className="col col-1"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            cursor: location.pathname !== "/home/dashboard" ? "pointer" : undefined,
          }}
        >
          <div
            onClick={() => {
              if (location.pathname !== "/home/dashboard") {
                navigate("/home/dashboard");
                setSidebarList(null);
                setProfileChecked(false);
                dispatch(setRenderSidebar({ isRender: false, isSettings: true }));
                setSettingsIconChecked(false);
                dispatch(
                  setPrescriptionPadData({
                    diagnosisArr: [],
                    symptomsArr: [],
                    medicationsArr: [],
                    labInvestigationsArr: [],
                    advicesArr: [],
                    soapNotes: {},
                  })
                );
                dispatch(setPrescriptionId(null));
                dispatch(setClearInvoiceState());
              }
            }}
            style={{ whiteSpace: "nowrap" }}
          >
            <img
              src="/images/arog-icon.svg"
              alt="logo"
              className="header-logo"
              // height="45px"
              // width="45px"
            />
            <img
              src="/images/arog-logo-text.svg"
              alt="logo"
              className="header-logo-text"
              // height="30px"
              // width="80px"
            />
          </div>
          {location.pathname === "/home/dashboard" && roleName() === DOCTOR && (
            <div>
              <TabContext value={tabValue?.value}>
                <Box
                  sx={{
                    overflowX: "auto",
                    height: "20%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TabList
                    //   variant="scrollable"
                    onChange={(event, newValue) => {
                      dispatch(setTabValue(newValue));
                      // const selectedItem = items.find((item) => item.value === newValue);
                      // const formatedDate = dayjs(selectedItem.date).format(DATE_FORMAT);
                      // dispatch(setAppointmentDate(formatedDate));
                      // dispatch(setDoctorId(doctorDetail?.docId));
                      // handleChange(
                      //   event,
                      //   newValue,
                      //   formatedDate,
                      //   doctorDetail?.docId,
                      //   doctorDetail?.orgIds
                      // );
                    }}
                    aria-label="lab API tabs example"
                    allowScrollButtonsMobile={true}
                    TabIndicatorProps={{
                      sx: {
                        // display: "none",
                        backgroundColor: "#004c70",
                      },
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#e6eef1", // Light background for the entire tab list
                      borderRadius: "8px",
                      padding: "5px 10px", // Add some padding around the tabs
                    }}
                  >
                    {items.map((item, index) => {
                      return (
                        <Tab
                          onChange={() => {
                            dispatch(setTabValue(item.value));
                          }}
                          key={index}
                          sx={{
                            // backgroundColor: tabValue === `${item.value}` ? "#29bf91" : "#ffffff",
                            backgroundColor: tabValue?.value === item.value ? "#e6eef1" : "inherit",
                            borderRadius: "8px 8px 8px 8px",
                            // color: tabValue === `${item.value}` ? "#ffffff !important" : "#004c70",
                            padding: "5px 0px",
                            mr: index === items.length - 1 ? "0px" : "10px",
                            fontSize: { md: "13px", lg: "14px", xl: "15px" },

                            "&.MuiButtonBase-root": {
                              minHeight: "37px !important",
                              maxHeight: "37px !important",
                            },

                            // backgroundColor: tabValue === `${item.value}` ? "#29bf91" : "#ffffff", // Active tab green, inactive tab white
                            // color: tabValue === `${item.value}` ? "#ffffff" : "#004c70", // Active tab white text, inactive tab blue text
                            // borderRadius: "8px", // Rounded corners
                            // padding: "8px 16px", // Adjust padding for button-like appearance
                            // marginRight: "8px", // Add spacing between tabs
                            // transition: "background-color 0.3s ease", // Smooth transition for background change
                            // "&:hover": {
                            //     backgroundColor: tabValue === item.value ? "#29bf91" : "#f2f6f8", // Hover effect
                            // },
                          }}
                          label={item.label}
                          value={`${item.value}`}
                        />
                      );
                    })}
                  </TabList>
                </Box>
              </TabContext>
            </div>
          )}
        </div>
      )}
      <RIf show={isDemo()}>
        <CustomButton
          label="Home"
          textAndIconColor={"#FFF"}
          onClick={() => navigate("/demo")}
          MuiButtoncolor="success"
          style={{ marginRight: "10px" }}
        />
      </RIf>

      <div className="col col-2 align-items-center">
        <ul className="horizontal-ul">
          {/* {roleName() === DOCTOR && location.pathname === "/home/dashboard" && (
            <li className="tertiary-actions">
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#29bf91",
                  borderRadius: "7px",
                  padding: "0px 5px",
                  gap: "4px",
                }}
              >
                <Grid>
                  <CustomizedToolTip title="In-Clinic">
                    <CustomButton
                      startIcon={<DirectionsRunOutlined />}
                      label={appointmentInfo?.offlineAppointments}
                      className={"btn--secondary"}
                      gap={"0px"}
                    />
                  </CustomizedToolTip>
                </Grid>
                <div className="btn--secondary">|</div>
                <Grid>
                  <CustomizedToolTip title="Tele-Consultation">
                    <CustomButton
                      startIcon={<VideoChatOutlined />}
                      label={appointmentInfo?.onlineAppointments}
                      className={"btn--secondary"}
                      gap={"0px"}
                    />
                  </CustomizedToolTip>
                </Grid>
                <div className="btn--secondary">|</div>
                <Grid>
                  <CustomizedToolTip title="Checked-In">
                    <CustomButton
                      startIcon={<DomainVerificationOutlined />}
                      label={appointmentInfo?.checkedIn}
                      className={"btn--secondary"}
                      gap={"0px"}
                    />
                  </CustomizedToolTip>
                </Grid>
              </Grid>
            </li>
          )} */}
          {/* {roleName() === DOCTOR && location.pathname === "/home/mosaic" && (
            <li className="tertiary-actions">
              <CustomButton
                label={"Edit"}
                className={"btn--secondary"}
                gap={"0px"}
                onClick={() => {
                  
                  const mosaicWindowElements = document.querySelectorAll('.mosaic-window-toolbar');
                  
                  if(!isMosaicEditable?.value){
                    mosaicWindowElements.forEach(element => {
                      element.classList.add('editable');
                    });
                    dispatch(setIsMosaicEditable(true));
                  } else {
                    mosaicWindowElements.forEach(element => {
                      element.classList.remove('editable');
                    });
                    dispatch(setIsMosaicEditable(false));
                  }
                }}
              />
            </li>
          )} */}
          {roleName() === DOCTOR && location.pathname !== "/home/dashboard" && (
            <li className="tertiary-actions">
              <CustomButton
                startIcon={<i className="ri-home-3-line" />}
                label={"Home"}
                className={"btn--secondary"}
                gap={"0px"}
                onClick={() => {
                  navigate("/home/dashboard");
                  setSidebarList(null);
                  setProfileChecked(false);
                  dispatch(setRenderSidebar({ isRender: false, isSettings: true }));
                  setSettingsIconChecked(false);
                  dispatch(
                    setPrescriptionPadData({
                      diagnosisArr: [],
                      symptomsArr: [],
                      medicationsArr: [],
                      labInvestigationsArr: [],
                      advicesArr: [],
                      soapNotes: {},
                    })
                  );
                  dispatch(setPrescriptionId(null));
                  dispatch(setClearInvoiceState());
                }}
              />
            </li>
          )}
          {roleName() === DOCTOR &&
            (location.pathname === "/home/dashboard" || location.pathname === "/home/mosaic") && (
              <li className="tertiary-actions">
                <Tooltip title={"Switch-Layout"}>
                  <CustomButton
                    iconButton={
                      sethuSirLayout?.value ? <SwitchRightOutlined /> : <SwitchLeftOutlined />
                    }
                    className={"btn--secondary-light"}
                    gap={"0px"}
                    onClick={() => {
                      dispatch(setSethuSirLayout(!sethuSirLayout?.value));
                    }}
                    style={{ padding: "8px 20px" }}
                  />
                </Tooltip>
              </li>
            )}
          {roleName() === DOCTOR && (
            <li>
              <DoctorBlockCalendar
                doctorId={currentUser.roleBasedId}
                doctorOrgId={doctorOrgId}
                calendarStyle={{ top: "70px !important", right: "230px" }}
                actionButtonStyle={{ top: "400px", transform: "translateX(-170px)" }}
              ></DoctorBlockCalendar>
            </li>
          )}
          {roleName() != SUPER_ADMIN && checkSettingIconVisible() && (
            <li className="tertiary-actions">
              <CustomButton
                className={
                  settingsIconChecked
                    ? "ri-settings-3-line ri-xl icon-primary-blue"
                    : "ri-settings-3-fill ri-xl icon-primary-blue"
                }
                onClick={() => {
                  setProfileChecked(false);
                  if (!settingsIconChecked) {
                    if (userData?.roleName === DOCTOR) {
                      setSettingsIconChecked(true);
                      dispatch(setRenderSidebar({ isRender: true, isSettings: true }));
                    } else {
                      setRouteBeforeSettingsSection(location.pathname);
                    }
                    // setRouteBeforeSettingsSection(location.pathname);
                  } else {
                    dispatch(setRenderSidebar({ isRender: false, isSettings: true }));
                    if (userData?.roleName === DOCTOR) {
                      setSettingsIconChecked(false);
                    } else {
                      if (routeBeforeSettingsSection) {
                        navigate(routeBeforeSettingsSection);
                        setRouteBeforeSettingsSection("");
                      } else {
                        navigate("/home/dashboard");
                      }
                    }

                    // if (routeBeforeSettingsSection) {
                    // navigate(routeBeforeSettingsSection);
                    // setRouteBeforeSettingsSection("");
                    // }
                    // else {
                    //   navigate("/home/dashboard");
                    // }
                  }
                }}
                style={{ padding: "2px 5px" }}
                customBackGroundColor="#29BF911A"
              ></CustomButton>
            </li>
          )}
          {roleName() === DOCTOR && (
            <>
              <li className="tertiary-actions">
                <div className="mui-dropdown">
                  {isSmallScreen ? (
                    <Tooltip title={buttonLabel}>
                      <CustomButton
                        label={buttonLabel.slice(0, 2)}
                        className={"ri-timer-line ri-lg btn--secondary-light"}
                        endIcon={<i className="ri-arrow-down-s-fill" />}
                        dropDownMenuItems={menuItemsAvailability}
                        onMenuItemClick={handleAvailabilityMenu}
                        menuItemHoverColor="#29BF91"
                        gap="0px"
                      />
                    </Tooltip>
                  ) : (
                    <CustomButton
                      label={buttonLabel}
                      className={"ri-timer-line ri-lg btn--secondary-light"}
                      endIcon={<i className="ri-arrow-down-s-fill" />}
                      dropDownMenuItems={menuItemsAvailability}
                      onMenuItemClick={handleAvailabilityMenu}
                      menuItemHoverColor="#29BF91"
                      gap="0px"
                    />
                  )}
                </div>
              </li>
            </>
          )}
          {checkRoleAndOrganization() && (
            <li className="tertiary-actions">
              <div className="mui-dropdown">
                {menuItemsClinic.find((e) => e?.id === organizationId) && (
                  <CustomButton
                    className={"btn--secondary-light"}
                    changeButtonLabelOnMenuItemClick={true}
                    endIcon={menuItemsClinic?.length > 1 && <i className="ri-arrow-down-s-fill" />}
                    dropDownMenuItems={menuItemsClinic}
                    defaultSelectedItem={menuItemsClinic.find((e) => e?.id === organizationId)}
                    onMenuItemClick={handleMenuClick}
                    menuItemHoverColor="#29BF91"
                    showMenuItems={menuItemsClinic.length > 1 ? false : true}
                    gap="0px"
                  />
                )}
              </div>
            </li>
          )}

          {roleName() === DOCTOR && (
            <>
              {isLoading ? (
                <li className="tertiary-actions">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Skeleton variant="circular" animation="wave" width={"40px"} height={"40px"} />
                  </div>
                </li>
              ) : (
                <li className="tertiary-actions">
                  <div>
                    <img
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!profileChecked) {
                          const profileObj = profileDashboard(
                            userFeatures,
                            userOrgId,
                            userData,
                            userData.roleName
                          );
                          navigate(
                            profileObj.hasOwnProperty("children")
                              ? profileObj.children[0].url
                              : profileObj.url
                          );
                          dispatch(setRenderSidebar({ isRender: true, isSettings: false }));
                          setProfileChecked(true);
                          setSettingsIconChecked(true);
                        } else {
                          dispatch(setRenderSidebar({ isRender: false, isSettings: false }));
                          setProfileChecked(false);
                          setSettingsIconChecked(false);
                        }
                      }}
                      className="patient-avatar"
                      src={
                        profileImageFileWithContent
                          ? profileImageFileWithContent.content
                          : (userData?.gender || userData?.gender) === "FEMALE"
                          ? "/images/woman.png"
                          : "/images/man.png"
                      }
                      alt=""
                    ></img>
                  </div>
                </li>
              )}
            </>
          )}

          <RIf show={isRunningOnElectron}>
            <li className="tertiary-actions">
              <CustomizedToolTip
                key={`asdasd - ${isOnline} ${isRunningOnElectron}`}
                title="Unavailable offline"
                disableHoverListener={isOnline}
                disableFocusListener={isOnline}
              >
                <div className="mui-dropdown">
                  <CustomButton
                    disabled={isSyncing || !isOnline}
                    startIcon={
                      <i className={`${isSyncing ? "rotate-loop" : ""} ri-refresh-line`} />
                    }
                    className="btn--secondary-light"
                    label="Sync"
                    onClick={onSyncButtonClick}
                  />
                </div>
              </CustomizedToolTip>
            </li>
          </RIf>
        </ul>
      </div>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle sx={{ "font-size": "20px" }}>Checkout Duration</DialogTitle>
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={{
            checkoutType: "fullDay",
            hours: "",
            selectedOrganizations: [],
            selectedAppointmentOption: "",
          }}
          onSubmit={async (values) => {
            const toastMessage =
              values.checkoutType === "hours"
                ? `Checked Out for ${values.hours} Hours.`
                : "Checked Out for Full day.";

            const now = dayjs();
            const startTime = now.format(TIME_FORMAT);
            const hoursToAdd = parseInt(values.hours);
            const calculatedHours = now.hour() + hoursToAdd;
            if (calculatedHours >= 24) {
              handleClick("warning", "Hours exceeding the day");
              // setHours(null);
              // setCheckoutType("fullDay");
              return;
            }
            const endTime = now.add(hoursToAdd, "hour").format(TIME_FORMAT);
            const payload = {
              doctorId: currentUser.roleBasedId,
              organizationId:
                menuItemsClinic && menuItemsClinic.length > 1
                  ? values.selectedOrganizations
                  : [menuItemsClinic[0].id],
              blockingType: values.checkoutType === "hours" ? "CUSTOM_HOURS" : "SINGLE_DATE",
              startTime: values.checkoutType === "hours" ? startTime : "00:00:00",
              endTime: values.checkoutType === "hours" ? endTime : "23:59:59",
              startDate: dayjs().format(DATE_FORMAT),
              endDate: dayjs().format(DATE_FORMAT),
              serviceType: values.selectedAppointmentOption,
            };
            try {
              await createBlockAvailabilities(payload);
              handleClick("success", toastMessage);
              setIsCheckedIn(false);
              handleCloseModal();
            } catch (error) {
              handleClick("error", "There seems to be an error while checking-out");
            }
          }}
          validationSchema={() => {
            return checkoutValidation(formikRef.current?.values?.checkoutType, menuItemsClinic);
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent sx={{ width: 400, height: "40%" }}>
                <RadioGroup
                  aria-label="checkout-type"
                  name="checkoutType"
                  value={values.checkoutType}
                  onChange={handleChange}
                >
                  <FormControlLabel value="fullDay" control={<Radio />} label="Full Day" />
                  <FormControlLabel value="hours" control={<Radio />} label="Specific Hours" />
                </RadioGroup>
                <Grid container spacing={2}>
                  {values.checkoutType === "hours" && (
                    <Grid size={{ xs: 12 }}>
                      <FormInputField
                        style={{
                          width: "100%",
                          marginTop: "10px",
                        }}
                        label="Hours"
                        type="number"
                        autoFocus
                        name="hours"
                        required
                        inputProps={{ min: 1, max: 24 }}
                        value={values.hours}
                        onChange={handleChange}
                        startAdornment={<HourglassBottomOutlined />}
                        error={Boolean(errors.hours && touched.hours)}
                        errorText={errors.hours}
                        size={"big"}
                      />
                    </Grid>
                  )}
                  {menuItemsClinic && menuItemsClinic.length > 1 && (
                    <>
                      <Grid size={{ xs: 12 }}>
                        <FormSelectField
                          multiple
                          required
                          name={"selectedOrganizations"}
                          label="Organization"
                          style={{ width: "100%" }}
                          value={values.selectedOrganizations}
                          onChange={handleChange}
                          startAdornment={<CorporateFareOutlined />}
                          menuItems={menuItemsClinic.map((el) => {
                            return {
                              value: el.id,
                              menuLabel: el.name,
                            };
                          })}
                          error={Boolean(
                            errors.selectedOrganizations && touched.selectedOrganizations
                          )}
                          errorText={errors.selectedOrganizations}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid size={{ xs: 12 }}>
                    <FormSelectField
                      style={{ width: "100%" }}
                      required
                      label="Appointment Options"
                      name="selectedAppointmentOption"
                      onChange={handleChange}
                      startAdornment={<FilterAltIcon />}
                      menuItems={blockCalenderOptions}
                      value={values.selectedAppointmentOption}
                      error={Boolean(
                        errors.selectedAppointmentOption && touched.selectedAppointmentOption
                      )}
                      errorText={errors.selectedAppointmentOption}
                      size={"big"}
                    ></FormSelectField>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleCloseModal();
                  }}
                >
                  Cancel
                </Button>
                {/* Add functionality to check out */}
                <Button type="submit">Check Out</Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default Header;
