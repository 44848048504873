import React, { useEffect, useState, useContext, useRef } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OrgBasicDetails from "./Organization/Child components/OrgBasicDetails";
import { createNewTenant } from "services/TenantService";
import { getPackages } from "services/Subscription";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Card, CardContent, Radio, RadioGroup, FormControlLabel, Typography } from "@mui/material";
import CustomButton from "ui-component/custom-components/CustomButton";
import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { fetchLocationByPincode } from "services/organizationService";
import {
  genderList,
  DATE_FORMAT,
  MAX_AGE_FOR_REGUAR_DOB,
  MIN_AGE_FOR_DOCTOR,
  MIN_AGE_FOR_GENERAL_PRACTITIONER_AND_PARAMEDIC,
  DATE_FORMAT_DMY,
} from "store/constant";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import SubCard from "ui-component/cards/SubCard";
import Reveal from "views/utilities/Reveal";
import { ButtonBase } from "@mui/material";

const TenantsOnboarding = () => {
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const { handleClick } = useContext(ToastContext);
  const [values, setValues] = useState({});
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [dobError, setDobError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [packages, setPackages] = useState([]);
  const selectedFields = ["name", "pincode", "address"];
  const LOWER_BOUND_DATE_FOR_REGUAR_DOB = dayjs().subtract(MAX_AGE_FOR_REGUAR_DOB, "year");
  const MAX_DATE_FOR_DOCTOR = dayjs().subtract(MIN_AGE_FOR_DOCTOR, "year");
  const MAX_DATE_FOR_GENERAL_PRACTITIONER_AND_PARAMEDIC = dayjs().subtract(
    MIN_AGE_FOR_GENERAL_PRACTITIONER_AND_PARAMEDIC,
    "year"
  );

  const [tenantDetails, setTenantDetails] = useState({
    tenantId: "",
    subDomain: "",
    URL: "",
    userName: "",
    password: "",
    dbPort: "",
    dedicatedDatabase: "",
    dbName: "",
  });

  const [organizationDetails, setOrganizationDetails] = useState({
    name: "",
    address: "",
    adPincode: "",
  });

  const [adminDetails, setAdminDetails] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    email: "",
    phone: "",
    dob: "",
  });

  const [packageDetails, setPackageDetails] = useState([
    {
      id: "",
      name: "",
      description: "",
      type: "",
      price: "",
      validity: "",
      validTill: "",
      count: "",
    },
  ]);

  const dataToSend = {
    tenantResponse: {
      tenantId: tenantDetails.tenantId,
      subDomain: `${tenantDetails.subDomain}@arog.health`,
      url: tenantDetails.URL,
      username: tenantDetails.userName,
      password: tenantDetails.password,
      dbPort: tenantDetails.dbPort,
      dbName: tenantDetails.dbName,
      minIdleConnections: 5, //   To be removed when API work is done....-------------
      maxPoolSize: 20,
    },

    organizationResponse: {
      name: organizationDetails.name,
      address: organizationDetails.address,
      adPincode: organizationDetails.adPincode,
      packageResponses: [packageDetails],
    },

    userResponse: {
      name: adminDetails.firstName + " " + adminDetails.lastName,
      firstName: adminDetails.firstName,
      middleName: adminDetails.middleName,
      lastName: adminDetails.lastName,
      gender: adminDetails.gender,
      mobileNumber: adminDetails.phone,
      emailId: adminDetails.email,
      dateOfBirth: adminDetails.dob,
      preferences: {
        prescriptionSearchType: "CATEGORY",
      },
    },
  };

  useEffect(() => {
    const makeNewTenant = async () => {
      try {
        await createNewTenant(dataToSend);
      } catch (error) {
        console.error("Failed to submit admin details:", error);
      }
    };
    if (
      adminDetails.firstName &&
      adminDetails.lastName &&
      adminDetails.email &&
      adminDetails.phone &&
      adminDetails.gender
    )
      makeNewTenant();
  }, [adminDetails]);

  // Organization Details Form Component
  const OrganizationDetailsForm = ({
    nextStep,
    touched,
    setFieldValue,
    setValues,
    setDobError,
    setTouched,
    dobError,
    pincodeError,
    setPincodeError,
  }) => {
    const initialValues = {
      address: organizationDetails.address,
      adPincode: organizationDetails.adPincode,
      name: organizationDetails.name,
    };

    const validationSchema = Yup.object({
      name: Yup.string()
        .required("Organization name is required")
        .min(3, "Name must be at least 3 characters long"),
      address: Yup.string().required("Address is required"),
      // .min(10, "Address must be at least 10 characters long"),
      adPincode: Yup.string()
        .required("Pincode is required")
        .matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits")
        .length(6, "Pincode must be exactly 6 digits"),
    });

    const handleSubmit = (values) => {
      nextStep();
    };

    const handleNextClick = (values) => {
      setOrganizationDetails({
        name: values.name,
        address: values.address,
        adPincode: values.adPincode,
      });
      nextStep();
    };

    const handlePincodeChange = async (e) => {
      const { value } = e.target;
      setPincodeError("");
      if (value.length === 6) {
        const response = await fetchLocationByPincode(value);
        if (response.data.length !== 0 && response.data[0].id) {
          const stateOb = {
            name: response.data[0].stateName.toUpperCase(),
            code: response.data[0].stateCode,
          };

          const districtOb = {
            name: response.data[0].districtName.toUpperCase(),
            code: response.data[0].districtCode,
          };

          setFieldValue("adState", stateOb);
          setFieldValue("adDistrict", districtOb);
        } else {
          setFieldValue("adState", null);
          setFieldValue("adDistrict", null);
          setTouched({ ...touched, adPincode: true });
          setPincodeError("Please enter valid pincode");
        }
      }
      setFieldValue("adPincode", value);
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        // onSubmit={handleSubmit}
        onSubmit={(values) => {
          handleNextClick(values);
          // handleSubmit();
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          errors,
          setTouched,
          setErrors,
        }) => (
          <Form>
            <div>
              <h3>Organization Details</h3>
              <OrgBasicDetails
                values={values}
                touched={touched}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                setValues={setValues}
                setDobError={setDobError}
                setErrors={setErrors}
                setTouched={setTouched}
                dobError={dobError}
                pincodeError={pincodeError}
                setPincodeError={setPincodeError}
                selectedFields={selectedFields}
              />
              <CustomButton
                label="Next"
                textAndIconColor={"#FFF"}
                type="submit"
                // onClick={() => {
                //   handleNextClick(values);
                // }}
                MuiButtoncolor="success"
                style={{
                  float: "right",
                  marginRight: "10px",
                  marginTop: "20px",
                  backgroundColor: "#004C70",
                  color: "#FFF",
                  padding: "10px 20px",
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const TenantDetailsForm = ({ handleBlur, nextStep, prevStep }) => {
    const initialValues = {
      tenantId: tenantDetails.tenantId,
      subDomain: tenantDetails.subDomain,
      url: tenantDetails.URL,
      username: tenantDetails.userName,
      password: tenantDetails.password,
      dbPort: tenantDetails.dbPort,
      dedicatedDatabase: tenantDetails.dedicatedDatabase ? tenantDetails.dedicatedDatabase : "no",
    };

    const validationSchema = Yup.object({
      tenantId: Yup.string()
        .required("Tenant ID is required")
        .min(3, "Tenant ID must be at least 3 characters"),
      subDomain: Yup.string()
        .required("Subdomain is required")
        .matches(
          /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
          "Subdomain must be lowercase and may contain hyphens"
        ),
      url: Yup.string().required("URL is required"),
      username: Yup.string()
        .required("Username is required")
        .min(3, "Username must be at least 3 characters"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters"),
      dbPort: Yup.number()
        .required("DB Port is required")
        .positive("Port number must be positive")
        .integer("Port number must be an integer")
        .min(1024, "Port number must be at least 1024")
        .max(65535, "Port number must be at most 65535"),
      dedicatedDatabase: Yup.string()
        .required("Please select if you want a dedicated database")
        .oneOf(["yes", "no"], "Invalid selection for dedicated database"),
    });

    const handleSubmit = (values) => {
      const dbName = values.dedicatedDatabase === "yes" ? values.tenantId : null;
      setTenantDetails({
        tenantId: values.tenantId,
        subDomain: values.subDomain,
        URL: values.url,
        userName: values.username,
        password: values.password,
        dbPort: values.dbPort,
        dedicatedDatabase: values.dedicatedDatabase,
        dbName: dbName,
      });

      nextStep();
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleBlur, touched, errors }) => (
          <Form className="form">
            <h3 className="form-header">Tenant Details Input</h3>
            <Reveal>
              <SubCard style={{ padding: "15px" }}>
                <Grid>
                  <Grid item xs={6}>
                    <FormInputField
                      style={{ width: "30%", marginRight: "3.33%", marginBottom: "25px" }}
                      label="Tenant ID"
                      name="tenantId"
                      required
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.tenantId}
                      error={Boolean(touched.tenantId && errors.tenantId)}
                      errorText={errors.tenantId}
                      size={"big"}
                    />
                    <span
                      style={{
                        position: "relative",
                        width: "100%",
                        marginBottom: "25px",
                        marginRight: "3.33%",
                      }}
                    >
                      <FormInputField
                        style={{}}
                        label="Sub Domain"
                        name="subDomain"
                        required
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.subDomain}
                        error={Boolean(touched.subDomain && errors.subDomain)}
                        errorText={errors.subDomain}
                        size={"big"}
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "20px",
                          top: "135%",
                          transform: "translateY(-50%)",
                          color: "#888",
                          pointerEvents: "none",
                        }}
                      >
                        @arog.health
                      </span>
                    </span>

                    <FormInputField
                      style={{ width: "30%", marginRight: "3.33%", marginBottom: "25px" }}
                      label="URL"
                      name="url"
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.url}
                      error={Boolean(touched.url && errors.url)}
                      errorText={errors.url}
                      size={"big"}
                    />
                  </Grid>

                  <Grid>
                    <FormInputField
                      style={{ width: "30%", marginRight: "3.33%", marginBottom: "25px" }}
                      label="Username"
                      name="username"
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.username}
                      error={Boolean(touched.username && errors.username)}
                      errorText={errors.username}
                      size={"big"}
                    />

                    <FormInputField
                      style={{ width: "30%", marginRight: "3.33%", marginBottom: "25px" }}
                      label="Password"
                      name="password"
                      required
                      type="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      errorText={errors.password}
                      size={"big"}
                    />

                    <FormInputField
                      style={{ width: "30%", marginRight: "3.33%", marginBottom: "25px" }}
                      label="DB Port"
                      name="dbPort"
                      required
                      // type="any"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.dbPort}
                      error={Boolean(touched.dbPort && errors.dbPort)}
                      errorText={errors.dbPort}
                      size={"big"}
                    />
                  </Grid>

                  <Grid container spacing={3} mt={0.7}>
                    <Grid style={{ paddingTop: 0, marginTop: "20px" }}>
                      <Typography sx={{ mt: 0, mb: 0, fontWeight: 500, color: "#000000" }}>
                        Would you like to have a dedicated database?
                      </Typography>
                      <Grid style={{ display: "flex", paddingTop: 0, mb: 0 }}>
                        <RadioGroup
                          // value={values.dedicatedDatabase}
                          name="dedicatedDatabase"
                          onChange={handleChange}
                          style={{ height: "50px" }}
                        >
                          <Grid size={{ xs: 12 }}>
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                          </Grid>
                          <Grid size={{ xs: 12 }}>
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SubCard>
            </Reveal>

            <div
              className="form-button-row"
              style={{ marginTop: "20px", float: "right", marginRight: "10px" }}
            >
              <CustomButton
                label="Prev"
                textAndIconColor={"#FFF"}
                onClick={prevStep}
                MuiButtoncolor="success"
                style={{ marginRight: "10px", backgroundColor: "#004C70" }}
              />
              <CustomButton
                label="Next"
                textAndIconColor={"#FFF"}
                type="submit"
                MuiButtoncolor="success"
                style={{ marginRight: "10px", backgroundColor: "#004C70" }}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  // Clinic Admin Details Form Component
  const ClinicAdminDetailsForm = ({ prevStep, handleBlur }) => {
    const initialValues = {
      firstName: adminDetails.firstName,
      middleName: adminDetails.middleName,
      lastName: adminDetails.lastName,
      gender: adminDetails.gender,
      email: adminDetails.email,
      phone: adminDetails.phone,
      dateOfBirth: adminDetails.dob || null,
    };

    const validationSchema = Yup.object({
      firstName: Yup.string()
        .required("First name is required")
        .min(2, "First name must be at least 2 characters")
        .matches(/^[A-Za-z\s]+$/, "First name should only contain letters and spaces"),
      middleName: Yup.string()
        .optional()
        .matches(/^[A-Za-z\s]*$/, "Middle name should only contain letters and spaces"),
      lastName: Yup.string()
        .required("Last name is required")
        .min(2, "Last name must be at least 2 characters")
        .matches(/^[A-Za-z\s]+$/, "Last name should only contain letters and spaces"),
      gender: Yup.string().required("Gender is required"),
      email: Yup.string().required("Email is required").email("Invalid email format"),
      phone: Yup.string()
        .required("Phone number is required")
        .matches(/^[0-9]{10,15}$/, "Phone number must be between 10 and 15 digits"),
      role: Yup.string().required("Role is required"),
      dateOfBirth: Yup.date()
        .required("Date of birth is required")
        .max(dayjs(), "Date of birth cannot be a future date")
        .min(dayjs().subtract(120, "years"), "Date of birth cannot be more than 120 years ago"),
    });

    const currentDate = dayjs();

    const handleSubmit = async (values) => {
      setAdminDetails({
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        gender: values.gender,
        email: values.email,
        phone: values.phone,
        dob: values?.dateOfBirth?.format(DATE_FORMAT),
      });
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, touched, errors, handleChange, handleBlur, setFieldValue, values }) => (
          <Form className="form">
            <h3>Clinic Admin Details</h3>
            <Reveal>
              <SubCard style={{ padding: "15px" }}>
                <Grid>
                  <Grid item xs={6}>
                    <FormInputField
                      style={{ width: "30%", marginRight: "3.33%", marginBottom: "25px" }}
                      label="First Name"
                      name="firstName"
                      required
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      error={Boolean(touched.firstName && errors.firstName)}
                      errorText={errors.firstName}
                      size={"big"}
                    />

                    <FormInputField
                      style={{ width: "30%", marginRight: "3.33%", marginBottom: "25px" }}
                      label="Middle Name"
                      name="middleName"
                      required
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.middleName}
                      error={Boolean(touched.middleName && errors.middleName)}
                      errorText={errors.middleName}
                      size={"big"}
                    />

                    <FormInputField
                      style={{ width: "30%", marginRight: "3.33%", marginBottom: "25px" }}
                      label="Last Name"
                      name="lastName"
                      required
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      error={Boolean(touched.lastName && errors.lastName)}
                      errorText={errors.lastName}
                      size={"big"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormInputField
                      style={{ width: "30%", marginRight: "3.33%", marginBottom: "25px" }}
                      label="Email"
                      name="email"
                      required
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      errorText={errors.email}
                      size={"big"}
                    />

                    <FormInputField
                      style={{ width: "30%", marginRight: "3.33%", marginBottom: "25px" }}
                      label="Phone"
                      name="phone"
                      required
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                      error={Boolean(touched.phone && errors.phone)}
                      errorText={errors.phone}
                      size={"big"}
                    />

                    <FormSelectField
                      style={{
                        width: "30%",
                        marginBottom: "25px",
                        marginRight: "3.33%",
                      }}
                      label="Gender"
                      name="gender"
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                      menuItems={genderList}
                      value={values.gender}
                      size={"big"}
                      error={Boolean(touched.gender && errors.gender)}
                    ></FormSelectField>
                  </Grid>

                  <div>
                    <FormDatePicker
                      format={DATE_FORMAT_DMY}
                      // disableFuture
                      label={"Birth date"}
                      value={values?.dateOfBirth || MAX_DATE_FOR_DOCTOR}
                      maxDate={MAX_DATE_FOR_DOCTOR}
                      minDate={LOWER_BOUND_DATE_FOR_REGUAR_DOB}
                      openTo={"year"}
                      size={"big"}
                      onChange={async (date) => {
                        setFieldValue("dateOfBirth", date);
                        const currentDate = dayjs();
                        if (date === null) {
                          setDobError("Please select a valid 'Date of birth' value.");
                        } else if (!date.isValid()) {
                          setDobError("Please select a valid 'Date of birth' value.");
                        } else if (date > currentDate) {
                          setDobError("'Date of birth' can't be a future date.");
                        } else if (date > MAX_DATE_FOR_DOCTOR) {
                          setDobError(
                            `Age must be at least ${currentDate.diff(
                              MAX_DATE_FOR_DOCTOR,
                              "year"
                            )} years.`
                          );
                        } else if (date < LOWER_BOUND_DATE_FOR_REGUAR_DOB) {
                          setDobError(
                            `'Date of birth' can't be less than the year ${LOWER_BOUND_DATE_FOR_REGUAR_DOB.year()}.`
                          );
                          // } else if (
                          //   (values.role ===
                          //     (await getRoleIdByName(GENERAL_PRACTITIONER, handleClick)) ||
                          //     values.role === (await getRoleIdByName(PARAMEDIC, handleClick))) &&
                          //   date > MAX_DATE_FOR_GENERAL_PRACTITIONER_AND_PARAMEDIC
                          // ) {
                          //   setDobError(
                          //     `Age must be at least ${currentDate.diff(
                          //       MAX_DATE_FOR_GENERAL_PRACTITIONER_AND_PARAMEDIC,
                          //       "year"
                          //     )} years.`
                          //   );
                          // } else if (
                          //   values.role === (await getRoleIdByName(DOCTOR, handleClick)) &&
                          //   date > MAX_DATE_FOR_DOCTOR
                          // ) {
                          // setDobError(
                          //   `Age must be at least ${currentDate.diff(
                          //     MAX_DATE_FOR_DOCTOR,
                          //     "year"
                          //   )} years.`
                          // );
                        } else {
                          setDobError("");
                        }
                      }}
                      required
                      style={{
                        width: "30%",
                        marginBottom: "25px",
                        marginRight: "30px",
                      }}
                      error={Boolean(touched.dateOfBirth && dobError)}
                      errorText={dobError}
                    ></FormDatePicker>
                  </div>
                </Grid>
              </SubCard>
            </Reveal>

            <div style={{ marginTop: "20px", marginRight: "10px", float: "right" }}>
              <CustomButton
                label="Prev"
                textAndIconColor={"#FFF"}
                onClick={prevStep}
                MuiButtoncolor="success"
                style={{ marginRight: "10px", backgroundColor: "#004C70" }}
              />
              <CustomButton
                label="Submit"
                textAndIconColor={"#FFF"}
                type="submit"
                MuiButtoncolor="success"
                style={{ marginRight: "10px", backgroundColor: "#004C70" }}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  // Subscription Details Component
  const SubscriptionDetailsForm = ({ nextStep, prevStep }) => {
    const [selectedPackage, setSelectedPackage] = useState(packageDetails.id || null);
    const [selectedPacks, setSelectedPacks] = useState(
      packages.find((pkg) => pkg.id === packageDetails.id) || null
    );

    const handlePackageChange = (selectedPkgId) => {
      const selectedPkg = packages.find((pkg) => pkg.id === selectedPkgId);
      setSelectedPacks(selectedPkg);
      setSelectedPackage(selectedPkgId);
    };

    const handleNext = () => {
      if (selectedPackage) {
        setPackageDetails({
          id: selectedPacks.id,
          name: selectedPacks.name,
          price: selectedPacks.price,
          validity: selectedPacks.validity,
          usageLimit: selectedPacks.usageLimit,
          description: selectedPacks.description,
          type: selectedPacks.type,
        });
        nextStep();
      } else {
        handleClick("error", "Please select a package");
      }
    };

    return (
      <div>
        <h3>Choose Your Subscription Plan</h3>
        <Reveal>
          <SubCard style={{ padding: "15px" }}>
            <Grid>
              <Grid container spacing={2} justifyContent="center">
                {packages.map((pkg) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={pkg.id}>
                    <ButtonBase
                      key={pkg.id}
                      onClick={() => handlePackageChange(pkg.id)}
                      style={{
                        display: "block",
                        width: "400px",
                        marginBottom: "16px",
                        border: selectedPackage === pkg.id ? "2px solid #4CAF50" : "1px solid #ccc",
                        borderRadius: "14px",
                        height: "100%",
                        boxShadow:
                          selectedPackage === pkg.id ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none", // Add shadow for selected package
                        transform: selectedPackage === pkg.id ? "scale(1.05)" : "scale(1)", // Slightly enlarge selected package
                        transition: "all 0.3s ease",
                      }}
                    >
                      <Card
                        variant="outlined"
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "left",
                          width: "100%",
                        }}
                      >
                        <CardContent
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // alignItems: "flex-start",
                            justifyContent: "space-between",
                            height: "100%",
                          }}
                        >
                          <div>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: "bold", color: "#004C70" }}
                            >
                              {pkg.name}
                            </Typography>
                            <Typography variant="subtitle1">₹{pkg.price}</Typography>
                          </div>
                          <Typography
                            variant="body2"
                            component="ul"
                            style={{ textAlign: "left", flexGrow: 1 }}
                          >
                            {pkg.packageBundleAssociationResponses.map((bundle) => (
                              <li key={bundle.bundleId}>
                                {bundle.bundleResponse.name} - {bundle.bundleResponse.description}
                              </li>
                            ))}
                          </Typography>
                        </CardContent>
                      </Card>
                    </ButtonBase>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </SubCard>
        </Reveal>

        <div style={{ marginTop: "20px", float: "right", marginRight: "10px" }}>
          <CustomButton
            label="Prev"
            textAndIconColor={"#FFF"}
            onClick={prevStep}
            MuiButtoncolor="success"
            style={{ marginRight: "10px", backgroundColor: "#004C70" }}
          />
          <CustomButton
            label="Next"
            textAndIconColor={"#FFF"}
            onClick={handleNext}
            type="submit"
            MuiButtoncolor="success"
            style={{ marginRight: "10px", backgroundColor: "#004C70" }}
          />
        </div>
      </div>
    );
  };
  const getSubscriptionPackages = async () => {
    try {
      const response = await getPackages();
      setPackages(response.data);
      return response;
    } catch (error) {
      console.error("Package not found", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form refresh
    try {
      await createNewTenant(dataToSend); // API call with form data
    } catch (error) {
      handleClick("info", "Sorry, you don't have access to this page");
    }
  };

  useEffect(() => {
    getSubscriptionPackages(); // Fetch packages when the component mounts
  }, []);

  // Define your handler functions
  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
  };

  const handleChange = (field, value) => {
    setValues({ ...values, [field]: value });
    // setOrganizationDetails({ ...organizationDetails, [field]: value });
  };

  // const setFieldValue = (field, value) => {
  //   setValues((prevValues) => ({ ...prevValues, [field]: value }));
  // };

  const nextStep = () => {
    setOnboardingStep((prev) => prev + 1);
    updateProgressBar(onboardingStep + 1);
  };

  const prevStep = () => {
    setOnboardingStep((prev) => prev - 1);
    updateProgressBar(onboardingStep - 1);
  };

  const updateProgressBar = (step) => {
    const percentage = (step / 4) * 100;
    setProgressPercentage(percentage);
  };

  const renderForm = () => {
    switch (onboardingStep) {
      case 0:
        return (
          <OrganizationDetailsForm
            nextStep={nextStep}
            values={values}
            // values={organizationDetails}
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setValues={setValues}
            setDobError={setDobError}
            setErrors={setErrors}
            setTouched={setTouched}
            dobError={dobError}
            pincodeError={pincodeError}
            setPincodeError={setPincodeError}
            selectedFields={selectedFields}
          />
        );
      case 1:
        return <SubscriptionDetailsForm nextStep={nextStep} prevStep={prevStep} />;
      case 2:
        return (
          <TenantDetailsForm
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 3:
        return <ClinicAdminDetailsForm prevStep={prevStep} />;
      default:
        return null;
    }
  };

  return (
    <div className="doctor-onboarding">
      <div className="inner-doctor-onboarding">
        <div className="p-2"></div>
        {onboardingStep >= 0 && (
          <ProgressBar width={progressPercentage} onboardingStep={onboardingStep}></ProgressBar>
        )}
        <div>{renderForm()}</div>
      </div>
    </div>
  );
};

const ProgressBar = ({ width, onboardingStep }) => {
  return (
    <div id="form-container" className="container mt-5">
      <div className="progress px-1" style={{ height: "3px" }}>
        <div
          className="progress-bar custom-progress"
          role="progressbar"
          style={{ width: width }}
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="step-container d-flex justify-content-between">
        <div
          className={`step-circle ${onboardingStep >= 0 ? "active" : ""} ${
            onboardingStep > 0 ? "completed" : ""
          }`}
        >
          <div className="icon">
            <CheckCircleIcon></CheckCircleIcon>
          </div>
          <span className="d-none d-md-block">Organization Details</span>
          <span className="d-block d-md-none circle-number">1</span>
        </div>
        <div
          className={`step-circle ${onboardingStep > 0 ? "active" : ""} ${
            onboardingStep > 1 ? "completed" : ""
          }`}
        >
          <div className="icon">
            <CheckCircleIcon></CheckCircleIcon>
          </div>
          <span className="d-none d-md-block">Subscription Details</span>
          <span className="d-block d-md-none circle-number">2</span>
        </div>
        <div
          className={`step-circle ${onboardingStep > 1 ? "active" : ""} ${
            onboardingStep > 2 ? "completed" : ""
          }`}
        >
          <div className="icon">
            <CheckCircleIcon></CheckCircleIcon>
          </div>
          <span className="d-none d-md-block">Tenant Details</span>
          <span className="d-block d-md-none circle-number">2</span>
        </div>
        <div
          className={`step-circle ${onboardingStep > 2 ? "active" : ""} ${
            onboardingStep > 3 ? "completed" : ""
          }`}
        >
          <div className="icon">
            <CheckCircleIcon></CheckCircleIcon>
          </div>
          <span className="d-none d-md-block">Clinic Admin Details</span>
          <span className="d-block d-md-none circle-number">3</span>
        </div>
      </div>
    </div>
  );
};

export default TenantsOnboarding;
