import React from "react";
import "./button.css"; // Assuming you have additional styles for buttons
import "./styles.css"; // Assuming you have additional styles for buttons
import { useState } from "react";
import { useNavigate } from "react-router";
import FeatureCard from "ui-component/cards/FeatureCard";
import CustomButton from "ui-component/custom-components/CustomButton";
import { Close } from "@mui/icons-material";
import ModalUI from "ui-component/ModalUI";
import DataPrivacyHelp from "./DataPrivacyHelp";
import { useSelector } from "react-redux";
import { getDemoDataSet, setIsOverlayOpen } from "store/Slices/demoSlice";
import { useOverlay } from "./OverlayContext";
import { useDispatch } from "react-redux";

const DemoView = () => {
  const [showDataPrivacyHelp, setShowDataPrivacyHelp] = useState(false);
  const [iframeOpen, setIframeOpen] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(null);
  const demoDataSet = useSelector(getDemoDataSet);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setDisabled } = useOverlay();

  const features = [
    {
      title: "No-Click Prescription Pad",
      icon: "/images/demo/prescription-svgrepo-com.svg",
      bgClass: "pad",
      description: [
        "Seamless interface for voice-dictated SOAP notes.",
        "IntelliRx™ provides smart assistance and suggestions.",
        "Review, approve, and order lab tests from a single page.",
      ],
      onClick: () => {
        setDisabled(true);
        dispatch(setIsOverlayOpen(false));
        navigate(`/home/prescriptionPad/${demoDataSet.offlineAppointmentId}`);
      },
    },
    {
      title: "Teleconsultation for Global Care",
      icon: "/images/demo/video-message-svgrepo-com.svg",
      bgClass: "tele",
      description: [
        "Provide care from anywhere with an interactive telemedicine system.",
        "Real-time access to patient records and instant prescription sharing.",
        "Fully compliant with ABDM standards and national healthcare guidelines.",
      ],
      onClick: () => {
        setDisabled(true);
        dispatch(setIsOverlayOpen(false));
        navigate(`/home/prescriptionPad/${demoDataSet.onlineAppointmentId}`);
      },
    },
    {
      title: "Comprehensive Patient EHR",
      icon: "/images/demo/healthcare-device-svgrepo-com.svg",
      bgClass: "cehr",
      description: [
        "Streamlined and concise patient information for swift decision-making.",
        "Key patient details presented clearly during appointments.",
        "Boosts efficiency in patient care through a comprehensive view.",
      ],
      onClick: () => {
        setDisabled(true);
        dispatch(setIsOverlayOpen(false));
        navigate(`/home/ehr/${demoDataSet.patientId}`, {
          state: {
            viewOnlyPage: true,
            activeTab: 1,
            patientId: "3fd7111a-b960-4b03-bf1d-7d4f62128b8b",
          },
        });
      },
    },
    {
      title: "Smooth And Quick On-Boarding",
      icon: "/images/demo/login-2-svgrepo-com.svg",
      bgClass: "easy-on-board",
      description: [
        "Easy, step-by-step onboarding process for doctors and citizens.",
        "Minimal setup steps ensure a user-friendly experience.",
        "Clear instructions prevent haste and simplify the process.",
      ],
      onClick: () => {
        setIframeOpen(true);
        setIframeSrc(
          "https://youtube.com/embed/3YU3RNdHkvs?autoplay=1&loop=1&playlist=3YU3RNdHkvs&rel=0&vq=hd1440&hd=1"
        );
      },
    },
    {
      title: "Customized EHR for Specializations",
      icon: "/images/demo/anatomy-blood-coronary-svgrepo-com.svg",
      bgClass: "detail-ehr",
      description: [
        "Tailor your EHR to suit your medical specialty.",
        "Organized data helps in faster, more efficient consultations.",
        "Quick access to relevant patient information during visits.",
      ],
      onClick: () => {
        setDisabled(true);
        dispatch(setIsOverlayOpen(false));
        navigate(`/home/ehr/${demoDataSet.patientId}`, {
          state: {
            viewOnlyPage: true,
            activeTab: 2,
            patientId: "3fd7111a-b960-4b03-bf1d-7d4f62128b8b",
          },
        });
      },
    },
    {
      title: "Your Data Always with You",
      icon: "/images/demo/cloud-computing-data-secure-svgrepo-com.svg",
      description: [
        "Hybrid-SaaS model ensures data privacy and ownership.",
        "Medical and financial data stored locally for added control.",
        "Full security over your information with no third-party access.",
      ],
      bgClass: "data-privacy",
      onClick: () => {
        setShowDataPrivacyHelp(true);
      },
    },
  ];

  return (
    <>
      <div className="logo-header">
        <div className="demo-hover logo-wrapper">
          <img className="logo" src="/images/demo/arog-logo-svg-logo.svg" />
          <img className="logo" src="/images/demo/arog-logo-svg-text.svg" />
        </div>
        <a href="https://www.argusoft.com/" target="_blank" className="demo-hover logo-wrapper">
          <img className="logo" src="/images/demo/argusoft-removebg-preview.png" />
        </a>
      </div>
      <div className="card-container">
        <div className="card-warpper">
          {features.slice(0, 3).map((f) => (
            <FeatureCard
              title={f.title}
              bgClass={f.bgClass}
              icon={f.icon}
              description={f.description}
              onClick={f.onClick}
              key={f.title}
            />
          ))}
        </div>
        <div className="card-warpper">
          {features.slice(3).map((f) => (
            <FeatureCard
              title={f.title}
              bgClass={f.bgClass}
              icon={f.icon}
              description={f.description}
              onClick={f.onClick}
              key={f.title}
            />
          ))}
        </div>
      </div>
      <ModalUI
        visible={showDataPrivacyHelp}
        title="Arog's Data Privacy Policy"
        component={<DataPrivacyHelp />}
        close={() => setShowDataPrivacyHelp(false)}
      />
      {iframeOpen && (
        <div className="iframe-div">
          <iframe
            title="HPR Onboarding"
            frameborder="0"
            allow="autoplay;"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            src={iframeSrc}
            className="iframe"
            style={{ width: "100%", height: "100vh" }}
          ></iframe>
          <CustomButton
            iconButton={<Close />}
            onClick={() => {
              setIframeOpen(false);
              setIframeSrc(null);
            }}
            className="iframe-close-button"
          />
        </div>
      )}
    </>
  );
};

export default DemoView;
