import { api as API } from "./AxiosInterceptor";
import { getUUID } from "../store/constant";

// patients Apis
export const getAllPatients = () => API.get(`/api/patients`);
export const getPatientData = (id, showLoader = true) =>
  API.get(`/api/patients/${id}`, { showLoader });
export const createPatient = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/patients`, data);
};
export const deletePatientData = (id) => API.delete(`/api/patients/${id}`);
export const setPatientData = (patientId, data) => API.put(`/api/patients/${patientId}`, data);

export const getPatientDetails = (url, id) => API.get(`${url}/${id}`);
export const updatePatient = (id, data, showLoader = true) =>
  API.put(`/api/patients/${id}`, data, { showLoader });

// patient by user id
export const getPatientByUserId = (userId) => API.get(`/api/patients/user/${userId}`);

//patient by mobile number
export const getPatientByMobileNumber = (mobileNumber) =>
  API.get(`/api/patients/mobilenumber/${mobileNumber}`);

// patient by doctor/organization id
export const getPatientsByDoctorOrOrgId = (orgId, doctorId) => {
  return API.get(`/api/appointments/query`, {
    params: {
      orgId: orgId,
      doctorId: doctorId,
    },
  });
};

// Patient allergy
export const getPatientAllergyByPatientId = (patientId, disabledTenant = true, showLoader = true) =>
  API.get(`/api/patient/allergies/patient/${patientId}`, { disabledTenant, showLoader });
export const savePatientAllergies = (data, showLoader = true) => {
  data["id"] = getUUID();
  return API.post(`/api/patient/allergies`, data, { showLoader });
};
export const editPatientAllergies = (id, data) => API.put(`/api/patient/allergies/${id}`, data);
export const deletePatientAllergy = (id) => API.delete(`/api/patient/allergies/${id}`);
export const getPatientAllergyById = (id, disabledTenant = true) =>
  API.get(`/api/patient/allergies/${id}`, { disabledTenant });

export const createPatientLifeStyle = (data, showLoader = true) => {
  data["id"] = getUUID();
  return API.post(`/api/patient/lifestyle-responses`, data, { showLoader });
};
export const updatePatientLifeStyle = (id, data, showLoader = true) =>
  API.put(`/api/patient/lifestyle-responses/${id}`, data, { showLoader });
export const getLifestyleQuestions = () => API.get(`/api/lifestyle-questions`);
export const getLifestyleQuestionByQuestionIdentifier = (questionIdentifier, showLoader = true) =>
  API.get(
    `/api/lifestyle-questions/get-by-question-identifier?questionIdentifier=${questionIdentifier}`,
    { showLoader }
  );
export const getPatientLifestyleByPatientId = (
  patientId,
  disabledTenant = true,
  showLoader = true
) =>
  API.get(`/api/patient/lifestyle-responses/patient/${patientId}`, { disabledTenant, showLoader });

// Patient chronic-disease
export const createPatientMedicalConditions = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/patient/chronic-diseases`, data);
};
export const editPatientMedicalConditions = (id, data) =>
  API.put(`/api/patient/chronic-diseases/${id}`, data);
export const getPatientChronicDiseaseByPatientId = (
  patientId,
  disabledTenant = true,
  showLoader = true
) => API.get(`/api/patient/chronic-diseases/patient/${patientId}`, { disabledTenant, showLoader });
export const deleteChronicDiesease = (id) => API.delete(`/api/patient/chronic-diseases/${id}`);
export const getPatientChronicDieseaseById = (id, disabledTenant = true) =>
  API.get(`/api/patient/chronic-diseases/${id}`, { disabledTenant });

// Patient Body-implants
export const getPatientBodyImplantsByPatientId = (patientId, disabledTenant = true) =>
  API.get(`/api/patients/body-implants/${patientId}`, { disabledTenant });

// Patient family-history
export const createPatientFamilyHistory = (data, showLoader = true) => {
  data["id"] = getUUID();
  return API.post(`/api/patient/family-histories`, data, { showLoader });
};
export const editPatientFamilyHistory = (id, data) =>
  API.put(`/api/patient/family-histories/${id}`, data);
export const deletePatientFamilyHistory = (id) => API.delete(`/api/patient/family-histories/${id}`);
export const getPatientFamilyHistoryByPatientId = (
  patientId,
  disabledTenant = true,
  showLoader = true
) => API.get(`/api/patient/family-histories/patient/${patientId}`, { disabledTenant, showLoader });
export const getPatientFamilyHistoryById = (id, disabledTenant = true) =>
  API.get(`/api/patient/family-histories/${id}`, { disabledTenant });

export const getPatientFamilyHistory = (id, disabledTenant = true) =>
  API.get(`/api/chronic-diseases/patient/${id}`, { disabledTenant });

// Patient past procedure
export const createPatientPastProcedures = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/patient/past-procedures`, data);
};
export const editPatientPastProcedures = (id, data) =>
  API.put(`/api/patient/past-procedures/${id}`, data);
export const deletePatientPastProcedures = (id) => API.delete(`/api/patient/past-procedures/${id}`);
export const getPatientPastProceduresByPatientId = (
  patientId,
  disabledTenant = true,
  showLoader = true
) =>
  API.get(`/api/patient/past-procedures`, {
    params: { patientId: patientId },
    disabledTenant,
    showLoader,
  });
export const getPatientPastProcedureById = (id, disabledTenant = true) =>
  API.get(`/api/patient/past-procedures/${id}`, { disabledTenant });

// Patient Immunization
export const createPatientImmunization = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/patient/immunizations`, data);
};
export const editPatientImmunization = (id, data) =>
  API.put(`/api/patient/immunizations/${id}`, data);
export const deletePatientImmunization = (id) => API.delete(`/api/patient/immunizations/${id}`);
export const getPatientImmunizationByPatientId = (patientId, disabledTenant = true) =>
  API.get(`/api/patient/immunizations/patient/${patientId}`, { disabledTenant });
export const getPatientImmunizationById = (id, disabledTenant = true) =>
  API.get(`/api/patient/immunizations/${id}`, { disabledTenant });

// Patient Emergency contact
export const createPatientEmergencyContact = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/patient/emergency-contacts`, data);
};
export const editPatientEmergencyContact = (id, data) =>
  API.put(`/api/patient/emergency-contacts/${id}`, data);
export const deletePatientEmergencyContact = (id) =>
  API.delete(`/api/patient/emergency-contacts/${id}`);
export const getPatientEmergencyContactByPatientId = (patientId, disabledTenant = true) =>
  API.get(`/api/patient/emergency-contacts/patient/${patientId}`, { disabledTenant });
export const getPatientEmergencyContactById = (id, disabledTenant = true) =>
  API.get(`/api/patient/emergency-contacts/${id}`, { disabledTenant });

// Patient Insurance Information
export const createPatientInsuranceInfo = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/patient/insurance-informations`, data);
};
export const editPatientInsuranceInfo = (id, data) =>
  API.put(`/api/patient/insurance-informations/${id}`, data);
export const deletePatientInsuranceInfo = (id) =>
  API.delete(`/api/patient/insurance-informations/${id}`);
export const getPatientInsuranceInfoByPatientId = (patientId, disabledTenant = true) =>
  API.get(`/api/patient/insurance-informations/patient/${patientId}`, { disabledTenant });
export const getPatientInsuranceInfoById = (id, disabledTenant = true) =>
  API.get(`/api/patient/insurance-informations/${id}`, { disabledTenant });

// Patient Physical exam
export const createPatientPhysicalExam = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/patient/physical-exams`, data);
};
export const editPatientPhysicalExam = (id, data) =>
  API.put(`/api/patient/physical-exams/${id}`, data);
export const deletePatientPhysicalExam = (id) => API.delete(`/api/patient/physical-exams/${id}`);
export const getPatientPhysicalExamByPatientId = (patientId, disabledTenant = true) =>
  API.get(`/api/patient/physical-exams/patient/${patientId}`, { disabledTenant });
export const getPatientPhysicalExamById = (id, disabledTenant = true) =>
  API.get(`/api/patient/physical-exams/${id}`, { disabledTenant });

// Patient Treatment plan
export const createPatientTreatmentPlan = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/patient/treatment-plans`, data);
};
export const editPatientTreatmentPlan = (id, data) =>
  API.put(`/api/patient/treatment-plans/${id}`, data);
export const deletePatientTreatmentPlan = (id) => API.delete(`/api/patient/treatment-plans/${id}`);
export const getPatientTreatmentPlanByPatientId = (patientId, disabledTenant = true) =>
  API.get(`/api/patient/treatment-plans/patient/${patientId}`, { disabledTenant });
export const getPatientTreatmentPlanById = (id, disabledTenant = true) =>
  API.get(`/api/patient/treatment-plans/${id}`, { disabledTenant });

// Patient Family Members
export const getPatientFamilyDetails = (familyId) =>
  API.get(`/api/users/family-users?familyId=${familyId}`);

// Patient Vitals
export const createPatientVital = (data, showLoader = true) => {
  data["id"] = getUUID();
  return API.post(`/api/patient/vitals`, data, { showLoader });
};
export const createMultiplePatientVital = (data) => {
  const updatedData = [];
  data.forEach((vital) => {
    const vitalCopy = { ...vital, id: getUUID() };
    updatedData.push(vitalCopy);
  });
  API.post(`/api/patient/vitals/multiple`, updatedData);
};
export const getPatientVitalById = (id, disabledTenant = true) =>
  API.get(`/api/patient/vitals/${id}`, { disabledTenant });
export const editPatientVital = (id, data) => API.put(`/api/patient/vitals/${id}`, data);
export const deletePatientVital = (id) => API.delete(`/api/patient/vitals/${id}`);
export const getPatientVitalByPatientId = (patientId, disabledTenant = true) =>
  API.get(`/api/patient/vitals/patient/${patientId}`, { disabledTenant });
export const getPatientLatestVitalByPatientId = (patientId, disabledTenant = true) =>
  API.get(`/api/patient/vitals/latest/${patientId}`, { disabledTenant });

export const uploadProfilePicture = (userId, file) =>
  API.post(`/api/users/${userId}/uploadProfilePicture`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getProfilePicture = (userId, disabledTenant = false, showLoader = true) =>
  API.get(`/api/users/${userId}/profilePicture`, { disabledTenant }, { showLoader });

// Patient documents
export const uploadPatientDocuments = (data) => {
  return API.post(`/api/patient/documents`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getPatientDocumentById = (id) => API.get(`/api/patient/documents/${id}`);
export const deletePatientDocumentByDocumentId = (documentId) =>
  API.put(`/api/patient/documents/delete/${documentId}`);
export const getPatientDocumentByDocumentId = (documentId) =>
  API.get(`/api/patient/documents/document/${documentId}`);
export const getPatientDocumentByPatientId = (patientId) =>
  API.get(`/api/patient/documents/patient/${patientId}`);
export const generateVitalsFromDocuments = (patientId, files) => {
  return API.post(`/api/patient/documents/vitals/${patientId}`, files, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const updatePatientDocument = (patientDocumentId, patientDocument, showLoader = true) => {
  return API.put(`/api/patient/documents/${patientDocumentId}`, patientDocument, { showLoader });
};

// family member verification if age >= 18 and <= 60

export const getOtpForFamilyMember = (contactField, verificationType) =>
  API.post(`hc-auth/verification/init`, {
    contactField: contactField,
    verificationType: verificationType,
  });

export const verifyOtpForFamilyMember = (contactField, verificationType, otp) =>
  API.post("hc-auth/verification/complete", {
    contactField: contactField,
    verificationType: verificationType,
    otp: otp,
  });
// Patient family doctor
export const getFamilyDoctor = (familyId) =>
  API.get(`/api/users/family-doctor?familyId=${familyId}`);
export const addFamilyDoctor = (data, params) =>
  API.post(`/api/users/family-doctor`, data, { params });
export const removeFamilyDoctor = (familyId, doctorId) =>
  API.delete(`/api/users/family-doctor`, { params: { doctorId, familyId } });
