import { createSlice } from "@reduxjs/toolkit";
import { isDemo, isGuidedDemoOn, isOverlayOpen } from "store/constant";

export const PatientData = [
  {
    label: "Data Set 1",
    offlineAppointmentId: "10b2c0dd-b3c9-4efb-807e-24ed6e3962bb",
    onlineAppointmentId: "ca75202e-751b-4534-a6b0-b182e1ee6c43",
    patientId: "3fd7111a-b960-4b03-bf1d-7d4f62128b8b",
  },
  {
    label: "Data Set 2",
    offlineAppointmentId: "48f7e1d0-02fb-4c52-8f2e-1d0deb554296",
    onlineAppointmentId: "281a5b51-77e5-482d-828e-113948730d5e",
    patientId: "60a71836-4654-42d7-be91-a55094782a2f",
  },
  {
    label: "Data Set 3",
    offlineAppointmentId: "594b8ccc-5226-4deb-a473-3ba8d792e289",
    onlineAppointmentId: "e2a63ab0-d433-4c66-bcb3-ab9ae60be38f",
    patientId: "2901a60e-fd76-4744-9eee-15b0b9a72897",
  },
];
export const dummyToken = {
  access_token:
    "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5NjAxOTg3Nzg0IiwiaWF0IjoxNzI1NTE4MDMxLCJleHAiOjE3Mjg5NzQwMzF9.9dqzu0ZvKHLcOzzsiwQe93A_qH5vjyC3a2s5cy9u9tI",
  refresh_token:
    "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5NjAxOTg3Nzg0IiwiaWF0IjoxNzI1NTE4MDMxLCJleHAiOjE3MjgxMTAwMzF9.lbIxxvYUu2ykcyz_n4YxwmU4SYt6zSMRzGW3l3Gla70",
};

const initialState = {
  isDemo: isDemo() ?? false,
  demoDataSet: PatientData[0],
  isOverlayOpen: isOverlayOpen() ?? false,
  isGuidedDemo: isGuidedDemoOn() ?? false,
};

const demoSlice = createSlice({
  name: "demo",
  initialState,
  reducers: {
    setIsDemo(state, action) {
      localStorage.setItem("isDemo", action.payload);
      state.isDemo = action.payload;
    },
    setDemoDataSet(state, action) {
      localStorage.setItem("demoDataSet", JSON.stringify(action.payload));
      state.demoDataSet = action.payload;
    },
    setIsOverlayOpen(state, action) {
      localStorage.setItem("isOverlayOpen", JSON.stringify(action.payload));
      state.isOverlayOpen = action.payload;
    },
    setGuidedModeOne(state, action) {
      localStorage.setItem("isGuidedDemoOn", JSON.stringify(action.payload));
      state.isGuidedDemo = action.payload;
    },
  },
});

export const { setIsDemo, setDemoDataSet, setIsOverlayOpen, setGuidedModeOne } = demoSlice.actions;

export const getIsDemo = (state) => state.demo.isDemo;
export const getDemoDataSet = (state) => state.demo.demoDataSet;
export const getIsOverlayOpen = (state) => state.demo.isOverlayOpen;
export const getIsGuidedDemoOn = (state) => state.demo.isGuidedDemo;

export const demoReducer = demoSlice.reducer;
