import React from "react";
import "./button.css"; // Ensure you have corresponding styles
import { useOverlay } from "./OverlayContext";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  PatientData,
  setDemoDataSet,
  setGuidedModeOne,
  setIsDemo,
  setIsOverlayOpen,
  getDemoDataSet,
  getIsGuidedDemoOn,
} from "store/Slices/demoSlice";
import CustomButton from "ui-component/custom-components/CustomButton";
import { demoDataSet } from "store/constant";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { styled, Switch, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

const FloatingOverlayBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeData = useSelector(getDemoDataSet);
  const [mode, setMode] = useState(demoDataSet() ?? storeData);
  const isOverlayOpen = useSelector((state) => state.demo.isOverlayOpen);

  const { disabled, setDisabled } = useOverlay();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes("demo")) {
      setDisabled(true);
      dispatch(setIsOverlayOpen(false));
    } else {
      setDisabled(false);
    }
  }, [location]);

  const handleModeChange = (newMode) => {
    dispatch(setDemoDataSet(newMode));
    setMode(newMode);
  };

  const selectedSet = demoDataSet();

  const handleEndDemo = () => {
    dispatch(setIsDemo(false));
    dispatch(setIsOverlayOpen(false));
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <div className={`floating-overlay-bar ${!isOverlayOpen ? "open" : ""}`}>
        <Grid container spacing={1} direction="row">
          <Grid>
            <Grid container alignItems="center" justifyContent="space-between">
              <CustomButton
                label="End Demo"
                onClick={handleEndDemo}
                MuiButtoncolor="error"
                style={{ marginLeft: "5px" }}
              />
            </Grid>
          </Grid>
          <Grid>
            <Grid
              item
              container
              height={"34px"}
              alignItems="center"
              justifyContent="space-between"
              alignContent={"center"}
              paddingLeft={"8px"}
              paddingRight={"8px"}
              borderRadius={"4px"}
              bgcolor={"#81c784"}
              paddingTop={"0px"}
            >
              <Grid>
                <Typography variant="subtitle1" color={"white"} marginRight={"6px"}>
                  Guided Demo
                </Typography>
              </Grid>
              <Grid>
                <Android12Switch
                  checked={useSelector(getIsGuidedDemoOn)}
                  onChange={(e) => dispatch(setGuidedModeOne(e.target.checked))}
                  name="guidedDemo"
                  size="medium"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <CustomButton
              className={"ri-user-add-line ri-lg btn--secondary-light"}
              endIcon={<i className="ri-arrow-down-s-fill" />}
              dropDownMenuItems={PatientData}
              label={mode.label}
              dropDownPadding="4px"
              disabled={disabled}
              minWidth="145px"
              defaultSelectedItem={selectedSet}
              onMenuItemClick={handleModeChange}
              menuItemHoverColor="#29BF91 !important"
            />
          </Grid>
        </Grid>
      </div>
      <div
        className={`floating-overlay-toggle ${isOverlayOpen ? "open" : ""}`}
        onClick={() => dispatch(setIsOverlayOpen(!isOverlayOpen))}
      >
        <img src="/images/hc_settings.svg" alt="Menu Icon" />
      </div>
    </>
  );
};

export default FloatingOverlayBar;

const Android12Switch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 30,
  height: 18,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: "0px 2px 0px 0px",
    margin: "2px",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#65C466",
      "& + .MuiSwitch-track": {
        backgroundColor: "#FFF",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));
