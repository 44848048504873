import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Avatar, Box, Input, Paper, Rating, Skeleton, Tab, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useRef, useState, useContext } from "react";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import DoctorGeneralInfo from "./DoctorProfile/DoctorGeneralInfo";
import DoctorEducation from "./DoctorProfile/DoctorEducation";
import DoctorAwardsAndPublications from "./DoctorProfile/DoctorAwardsAndPublications";
import { useLocation } from "react-router-dom";
import CustomCard from "ui-component/custom-components/CustomCard";
import {
  addFamilyDoctor,
  getFamilyDoctor,
  removeFamilyDoctor,
  getProfilePicture,
} from "services/patientService";
import "assets/scss/GoogleMap.scss";
import { getDoctorById } from "services/doctorService";
import {
  currentActiveUser,
  FEMALE,
  getUUID,
  SIZE_10_MB,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
} from "store/constant";
import Reveal from "views/utilities/Reveal";
import DoctorReviews from "./DoctorProfile/DoctorReviews";
import CustomBackButton from "ui-component/custom-components/CustomBackButton";

const DoctorProfileNew = () => {
  const { state } = useLocation();
  const pendingTask = state?.pendingTask;

  const activeMember = currentActiveUser();
  const [doctorDetails, setDoctorDetails] = useState(null);
  const { handleClick } = useContext(ToastContext);
  const [readOnly, setReadOnly] = useState(pendingTask ? false : true);
  const [viewOnlyPage, setViewOnlyPage] = useState(false);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [profileImageFileWithContent, setProfileImageFileWithContent] = useState(null);
  const [isSaveChangesModalOpen, setIsSaveChangesModalOpen] = useState(false);
  const newTabValueRef = useRef("one");
  const cancelEditRef = useRef(false);
  const saveEditRef = useRef(false);
  const [isFamilyDoctor, setIsFamilyDoctor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const isDoctorProfilepage = location?.state?.isDoctorProfilepage;
  const isUhiDoctor = location?.state?.data?.isUhiDoctor;
  const doctorId = location?.state?.doctorId;
  const userId = location?.state?.userId;
  const genderBasedImage =
    doctorDetails?.userResponse?.gender === FEMALE ? "/images/woman.png" : "/images/man.png";

  const addDoctorAsFamilyDoctor = async (doctorId) => {
    try {
      const data = {
        id: getUUID(),
        familyId: activeMember?.familyResponse?.id || getUUID(),
      };
      const params = {
        doctorId: doctorId,
        primaryUserId: activeMember.id,
      };
      await addFamilyDoctor(data, params);
      setIsFamilyDoctor(true);
      handleClick("success", "Family doctor has been successfully added.");
    } catch (error) {
      handleClick("error", "There seems to be an error adding family doctor.");
    }
  };

  const removeDoctorAsFamilyDoctor = async (doctorId) => {
    try {
      await removeFamilyDoctor(activeMember?.familyResponse.id, doctorId);
      setIsFamilyDoctor(false);
      handleClick("success", "Family doctor has been successfully removed.");
    } catch (error) {
      handleClick("error", "There seems to be an error removing family doctor.");
    }
  };

  const addActions = [
    {
      label: "Add as family doctor",
      onClick: addDoctorAsFamilyDoctor,
    },
  ];

  const removeActions = [
    {
      label: "Remove as family doctor",
      onClick: removeDoctorAsFamilyDoctor,
    },
  ];

  const getDocId = () => {
    const docId = location?.state?.viewOnlyPage
      ? location?.state?.data?.docId
      : doctorId
      ? doctorId
      : activeMember?.roleBasedId;
    return docId;
  };

  const fetchAndSetSoctorDetails = async () => {
    try {
      const docId = getDocId();
      const doctorResponse = await getDoctorById(docId, true);
      setDoctorDetails(doctorResponse.data);
    } catch (error) {
      console.error("Error getting doctor details");
    }
  };

  const fetchData = async () => {
    // if viewOnlyPage is true, redirected from patient side, get doctor details from doctorID
    // if viewOnlyPage is false, get doctor details from activeMember/user
    if (location?.state?.viewOnlyPage) {
      setViewOnlyPage(location?.state?.viewOnlyPage);
    }
    const docId = getDocId();
    const UserId = userId ? userId : activeMember?.id;

    if (!location?.state?.data?.isUhiDoctor) {
      fetchAndSetSoctorDetails(docId);
    }
    try {
      checkIfFamilyDoctor();
      if (!location?.state?.viewOnlyPage) {
        try {
          setIsLoading(true);
          const profilePicResponse = await getProfilePicture(UserId);
          const content = `data:image/${profilePicResponse?.data.extension};base64,${profilePicResponse.data.document}`;
          const profilePicWithContent = {
            filename: profilePicResponse.data.filename,
            content: content,
          };
          setProfileImageFileWithContent(profilePicWithContent);
        } catch (error) {
          console.error("Error fetching profile picture", error);
        } finally {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching doctor data");
    }
  };

  const checkIfFamilyDoctor = async () => {
    try {
      const patient = activeMember;
      const response = await getFamilyDoctor(patient.familyResponse.id);
      const isDoctorInFamily = response.data.some(
        (doctor) => doctor.doctorId === location?.state?.data?.docId
      );
      setIsFamilyDoctor(isDoctorInFamily);
    } catch (error) {
      console.error("Error checking if doctor is a family doctor:", error);
    }
  };

  const handleTabChange = () => {
    cancelEditRef.current = false;
    saveEditRef.current = false;
    setValue(newTabValueRef.current);
    fetchAndSetSoctorDetails();
    setReadOnly(true);
  };

  const tablist = [
    {
      value: "one",
      label: "General info",
      component: (
        <DoctorGeneralInfo
          refreshData={fetchData}
          pendingTask={pendingTask}
          profileImageFile={profileImageFile}
          viewOnlyPage={viewOnlyPage || false}
          doctorDetails={doctorDetails}
          setDoctorDetails={setDoctorDetails}
          readOnly={readOnly}
          setReadOnly={setReadOnly}
          cancelEdit={cancelEditRef.current}
          saveEditRef={saveEditRef}
        />
      ),
    },
    {
      value: "two",
      label: "Education",
      component: (
        <DoctorEducation
          viewOnlyPage={viewOnlyPage || false}
          doctorDetails={doctorDetails}
          setDoctorDetails={setDoctorDetails}
          readOnly={readOnly}
          setReadOnly={setReadOnly}
          refreshData={fetchData}
        />
      ),
    },
    {
      value: "three",
      label: "Awards and Publications",
      component: (
        <DoctorAwardsAndPublications
          viewOnlyPage={viewOnlyPage || false}
          doctorDetails={doctorDetails}
          setDoctorDetails={setDoctorDetails}
          readOnly={readOnly}
          setReadOnly={setReadOnly}
          refreshData={fetchData}
        />
      ),
    },
    ...(doctorDetails?.displayReviews
      ? [
          {
            value: "four",
            label: "Reviews",
            component: <DoctorReviews doctorDetails={doctorDetails} />,
          },
        ]
      : []),
  ];

  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    newTabValueRef.current = newValue;

    if (!readOnly) {
      setIsSaveChangesModalOpen(true);
    } else {
      handleTabChange();
    }
  };

  const handleEditCancel = () => {
    cancelEditRef.current = true;
    setReadOnly(true);
    setIsSaveChangesModalOpen(false);
  };

  const handleSaveEdit = () => {
    saveEditRef.current = true;
    setReadOnly(false);
    setIsSaveChangesModalOpen(false);
  };

  const handleFileUploads = async (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (!file) {
      return; // Exit early if no file is selected
    }

    //10MB
    if (file.size > SIZE_10_MB) {
      handleClick("error", "File size exceeds 10MB. Please choose a smaller file.");
      event.target.value = null;
      return;
    }

    let updatedSlected;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const s = new Promise((resolve) => {
      reader.onloadend = () => {
        resolve({ file: file, content: reader.result });
      };
    });
    try {
      const updatedFiles = await s;
      updatedSlected = updatedFiles;
      setProfileImageFile(file);
      setProfileImageFileWithContent(updatedSlected);
    } catch (e) {
      handleClick("error", "Sorry, Your file cannot be uploaded.");
    }
  };

  useEffect(() => {
    if (activeMember) {
      fetchData();
    }
  }, []);

  return (
    <DoctorProfileSkeleton>
      <Reveal>
        <ModalUI
          visible={isSaveChangesModalOpen}
          close={() => {
            setIsSaveChangesModalOpen(false);
          }}
          title="Are you sure to discard the changes?"
          style={{
            height: "10rem",
            width: "30rem",
          }}
          closeButtonStylling={{
            marginRight: "-30px",
          }}
          component={
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "flex-end",
                  height: "4em",
                  margin: "10px",
                }}
              >
                <CustomButton
                  className="btn--secondary"
                  style={{ backgroundColor: "#ff7070" }}
                  onClick={handleEditCancel}
                >
                  Yes
                </CustomButton>
                <CustomButton className="btn--primary" onClick={handleSaveEdit}>
                  No, Save it
                </CustomButton>
              </div>
            </div>
          }
        />
        {/* display custom card with data */}
        {viewOnlyPage && (
          <CustomCard
            isDoctorProfilepage={isDoctorProfilepage}
            doctorDetail={location?.state?.data}
            isUhiDoctor={isUhiDoctor}
            actions={isFamilyDoctor ? removeActions : addActions}
          />
        )}

        {!viewOnlyPage && (
          <Grid display={"flex"} justifyContent={"space-between"} sx={{ mb: 2 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomBackButton />
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  color: "#004C70",
                }}
              >
                Personal Details
              </Typography>
            </div>

            <Grid>
              {value === "one" && readOnly && (
                <CustomButton
                  label={"Edit Profile"}
                  style={{ marginLeft: "10px" }}
                  startIcon={<i className="ri-edit-line ri-xl icon-primary-blue" />}
                  className={"btn--secondary-light"}
                  onClick={() => {
                    setReadOnly(false);
                    cancelEditRef.current = false;
                  }}
                />
              )}
            </Grid>
          </Grid>
        )}

        {isUhiDoctor ? (
          <>
            <Typography style={{ textAlign: "center" }}>
              No Additional information available for this doctor
            </Typography>
          </>
        ) : (
          <Grid>
            <Paper
              variant="outlined"
              sx={{
                borderRadius: "12px",
                width: "100%",
                height: "fit-content",
                padding: "23px",
              }}
            >
              {!viewOnlyPage && (
                <Grid container spacing={3} sx={{ mb: "30px", alignItems: "center" }}>
                  <Grid className="doc-profile-image-container">
                    {isLoading ? (
                      <Skeleton
                        variant="circular"
                        animation="wave"
                        width={"125px"}
                        height={"125px"}
                      />
                    ) : (
                      <Avatar
                        alt="Patient"
                        src={
                          profileImageFileWithContent
                            ? profileImageFileWithContent.content
                            : genderBasedImage
                        } // Provide the path to the profile picture
                        style={{
                          width: "125px",
                          height: "125px",
                          borderRadius: "50%",
                        }}
                        variant="square"
                        className="image"
                      />
                    )}
                    <CustomButton
                      className="delete-profile-image-button"
                      onClick={() => {}}
                      iconButton={<i className="ri-edit-fill" />}
                      style={{
                        borderRadius: "15px",
                        width: "30px",
                        height: "30px",
                        display: readOnly ? "none" : undefined,
                      }}
                      component="label"
                    >
                      <Input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleFileUploads(e);
                        }}
                      />
                    </CustomButton>
                  </Grid>
                  <Grid>
                    <Typography
                      sx={{
                        color: "#194B5A",
                        fontSize: "24px",
                        fontWeight: 600,
                      }}
                    >
                      Dr. {doctorDetails?.userResponse?.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {doctorDetails?.specialization?.name}
                      {doctorDetails?.specialization?.name &&
                      doctorDetails?.highestQualificationName ? (
                        <>
                          <span> | </span>
                        </>
                      ) : null}
                      {doctorDetails?.highestQualificationName}
                    </Typography>
                    <Grid style={{ display: "flex", gap: "10px", marginTop: "7px" }}>
                      {doctorDetails?.avgRating && (
                        <>
                          {doctorDetails && (
                            <Rating
                              name={doctorDetails?.id?.toString()}
                              value={parseInt(doctorDetails?.avgRating)}
                              size="small"
                              readOnly
                            />
                          )}
                        </>
                      )}
                      <Typography style={{ marginTop: "1px", fontWeight: "500" }}>
                        {doctorDetails?.avgRating ? doctorDetails?.avgRating : "- No Ratings"}
                      </Typography>
                    </Grid>
                    <p style={{ marginTop: "5px" }}>{doctorDetails?.biography}</p>
                  </Grid>
                </Grid>
              )}

              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      TabIndicatorProps={{
                        sx: {
                          backgroundColor: "#004c70",
                        },
                      }}
                    >
                      {tablist.map((item, index) => {
                        return (
                          <Tab
                            key={index}
                            sx={{
                              backgroundColor: value === item.value ? "#e6eef1" : "inherit",
                              borderRadius: "8px 8px 0px 0px",
                            }}
                            label={item.label}
                            value={item.value}
                          />
                        );
                      })}
                    </TabList>
                  </Box>
                  {tablist.map((item, index) => {
                    return (
                      <TabPanel
                        key={index}
                        value={item.value}
                        sx={{
                          padding: "10px",
                        }}
                      >
                        {item.component}
                      </TabPanel>
                    );
                  })}
                </TabContext>
              </Box>
            </Paper>
          </Grid>
        )}
      </Reveal>
    </DoctorProfileSkeleton>
  );
};

const DoctorProfileSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 3 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
        </Grid>

        <Grid container size={{ xs: 12 }} sx={{ flexDirection: "row", ml: 3, mt: 1.5 }}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={125}
            />
          </Grid>
          <Grid size={{ xs: 10 }} flexDirection={"column"}>
            <Skeleton
              sx={{ borderRadius: "4px", mb: 1 }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={17}
            />
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={17}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mt: 3 }}
              animation="wave"
              variant="rectangular"
              width="90%"
              height={25}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mt: 1 }}
              animation="wave"
              variant="rectangular"
              width="50%"
              height={25}
            />
          </Grid>
        </Grid>

        <Grid sx={{ ml: 3 }} size={{ xs: 12 }} display={"flex"}>
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={40}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={40}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={40}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={40}
          />
        </Grid>

        <Grid sx={{ ml: 3 }} size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={400}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DoctorProfileNew;
