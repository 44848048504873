import {
  AbcOutlined,
  CategoryOutlined,
  CurrencyRupeeOutlined,
  DomainOutlined,
  PercentOutlined,
} from "@mui/icons-material";
import { Box, Divider, FormControl, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Formik } from "formik";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  createMedicalServices,
  deleteMedicalServices,
  getMedicalServiceById,
  getMedicalServiceCategories,
  getMedicalServices,
  getServiceConfiguration,
  getTaxRates,
  updateMedicalServices,
} from "services/BillingService";
import { getDoctorsByOrg } from "services/organizationService";
import {
  ACTIVE_STATE,
  currentActiveUser,
  DOCTOR,
  entitiesInfo,
  ORGANIZATION,
  roleName,
} from "store/constant";
import { getUserOrgId } from "store/Slices/userSlice";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { s } from "views/Components/Common/ValidationSchema/ManageOrganizationFormValidation";
import InfoModal from "views/Components/Entities/InfoModal";
import Reveal from "views/utilities/Reveal";
import InfoIcon from "@mui/icons-material/Info";
import { useLocation, useNavigate } from "react-router";
import ModalUI from "ui-component/ModalUI";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import { getMedicalServiceItems, setMedicalServiceItems } from "store/Slices/billingInvoiceSlice";
import { useDispatch } from "react-redux";

const ProductAndServices = () => {
  const { state } = useLocation();
  const pendingTask = state?.pendingTask;
  const sourcePage = state?.sourcePage;
  const sourceData = state?.sourceData;
  const dispatch = useDispatch();
  const medicalInvoiceItems = useSelector(getMedicalServiceItems);

  const [isUpdateService, setisUpdateService] = useState(false);
  const { handleClick } = useContext(ToastContext);
  const organizationId = useSelector(getUserOrgId);
  const [categories, setCategories] = useState([]);
  const [taxRates, settaxRates] = useState([]);
  const [ownershipArr, setownershipArr] = useState([]);
  const entityInfo = entitiesInfo.find((entity) => entity.id === "ProductAndServices");
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpenModal] = useState(pendingTask ? true : false);
  const navigate = useNavigate();
  const [medicalServices, setMedicalServices] = useState([]);
  const [serviceId, setServiceId] = useState("");
  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "category", label: "Category" },
    { field: "serviceName", label: "Service Name" },
    { field: "amount", label: `Amount (\u20B9)` },
    { field: "gst", label: "GST(%)" },
    { field: "ownerName", label: "Owner" },
    { field: "Actions", label: "Actions" },
  ];

  const handleDispatchServiceForBilling = async (newItem) => {
    try {
      const res = await getMedicalServiceById(newItem?.id);
      dispatch(
        setMedicalServiceItems([
          ...medicalInvoiceItems,
          {
            id: null,
            invoiceId: null,
            itemResponse: res.data,
            quantity: 0,
            discount: 0,
            tax: 0,
            amount: 0,
            status: ACTIVE_STATE,
          },
        ])
      );
    } catch (error) {
      console.error(error);
    }
  };

  const setServiceConfiguration = () => {
    const confData = async () => {
      try {
        const res = await getServiceConfiguration(organizationId, null, ORGANIZATION);
        const categories = res.data.map((el) => ({
          ...el,
          owner: el.ownerType === ORGANIZATION ? ORGANIZATION : el.doctorId,
        }));
      } catch (error) {
        handleClick("error", "API issue.");
      }
    };
    confData();
  };

  const handleManageService = (e, values, setValues) => {
    if (values.categoryId && values.serviceName && values.amount && values.taxId && values.owner) {
      setisUpdateService(false);
      const apiBody = {
        name: values.serviceName,
        description: null,
        price: values.amount,
        categoryId: values.categoryId,
        doctorId: values.owner === ORGANIZATION ? null : values.owner,
        organizationId: organizationId,
        ownerType: values.owner === ORGANIZATION ? ORGANIZATION : DOCTOR,
        taxId: values.taxId,
        status: ACTIVE_STATE,
      };
      setValues({
        ...values,
        categoryId: "",
        serviceName: "",
        amount: "",
        taxId: "",
        owner: "",
      });
      if (isUpdateService) {
        const id = serviceId;
        apiBody["id"] = serviceId;
        const updateData = async () => {
          try {
            await updateMedicalServices(id, apiBody);
            setServiceConfiguration();
            fetchMedicalServices();
          } catch (error) {
            handleClick("error", "There seems to be an issue updating medical service.");
          }
        };
        updateData();
      } else {
        const saveData = async () => {
          try {
            const medResponse = await createMedicalServices(apiBody);
            setServiceConfiguration();
            fetchMedicalServices();
            handleDispatchServiceForBilling(medResponse.data);
            if (pendingTask) {
              navigate("/home/dashboard", { state: { completedDetails: true } });
            }
          } catch (error) {
            handleClick("error", "There seems to be an error creating medical service.");
          }
        };
        saveData();
      }
      setOpenModal(false);
    } else {
      handleClick("info", "Please fill up all details");
    }
  };

  const handleEditService = (index, setValues, values) => {
    setisUpdateService(true);
    setServiceId(index.serviceId);
    setValues({
      ...values,
      categoryId: index.categoryId,
      serviceName: index.serviceName,
      amount: index.amount,
      taxId: index.taxId,
      owner: index.owner,
    });
  };

  const cancelEdit = (values, setValues) => {
    setisUpdateService(false);
    setServiceId("");
    setValues({
      ...values,
      categoryId: "",
      serviceName: "",
      amount: "",
      taxId: "",
      owner: "",
    });
  };

  const handleDeleteService = (index, values, setValues) => {
    const deleteData = async () => {
      try {
        await deleteMedicalServices(index.serviceId);
        setValues({
          ...values,
          categoryId: "",
          serviceName: "",
          amount: "",
          taxId: "",
          owner: "",
        });
        setisUpdateService(false);
        setServiceConfiguration();
        fetchMedicalServices();
      } catch (error) {
        handleClick("error", "There seems to be an issue deleting medical service.");
      }
    };
    deleteData();
  };

  const getCategoryName = (categoryId) => {
    return categories.find((el) => el.id === categoryId)?.name;
  };

  const getTaxRateById = (taxId) => {
    return taxRates.find((t) => t.id === taxId)?.rate;
  };

  const getOwnerName = (row) => {
    const owner =
      row.ownerType === ORGANIZATION
        ? ORGANIZATION
        : ownershipArr.find((el) => el.id === row.doctorId)?.name;
    return owner;
  };

  const fetchData = async () => {
    try {
      const response = await getMedicalServiceCategories();
      if (response && response.data) {
        setCategories(response.data);
      }
      const taxRateResponse = await getTaxRates();
      if (taxRateResponse && taxRateResponse.data) {
        settaxRates(taxRateResponse.data);
      }
      if (roleName() !== DOCTOR || currentActiveUser().userOrganizationAssociationList[0].isAdmin) {
        try {
          const responseDoctorByOrg = await getDoctorsByOrg(organizationId);
          const filteredDoctor = responseDoctorByOrg.data.map((doctor) => ({
            id: doctor.id,
            name: `Dr. ${doctor.userResponse.name}`,
          }));
          setownershipArr([{ id: ORGANIZATION, name: "Organization" }, ...filteredDoctor]);
        } catch (error) {
          setownershipArr([{ id: ORGANIZATION, name: "Organization" }]);
          console.error("Fetch doctors by organization failed");
        }
      } else {
        setownershipArr([
          { id: currentActiveUser().roleBasedId, name: `Dr. ${currentActiveUser().name}` },
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMedicalServices = async () => {
    try {
      const responseData = await getMedicalServices();
      let services = [];
      responseData.data.forEach((service) => {
        // filtering services based on the organization as discussed
        const obj = {
          serviceId: service.id,
          categoryId: service.categoryId,
          category: service.categoryId ? getCategoryName(service.categoryId) : "N/A",
          serviceName: service.name ? service.name : "N/A",
          amount: service.price ? service.price : "N/A",
          taxId: service.taxId,
          gst: service.taxId ? getTaxRateById(service.taxId) : "N/A",
          owner: service.ownerType === ORGANIZATION ? ORGANIZATION : service.doctorId,
          ownerName: getOwnerName(service),
        };

        if (service.organizationId === organizationId) {
          if (
            roleName() !== DOCTOR ||
            currentActiveUser().userOrganizationAssociationList[0].isAdmin ||
            obj.owner === currentActiveUser().roleBasedId
          ) {
            services.push(obj);
          }
        }
      });
      setMedicalServices(services);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchMedicalServices();
  }, [ownershipArr]);

  return (
    <Reveal>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h2 style={{ marginBottom: "15px", fontSize: "25px", marginTop: "15px" }}>
          Product And Services
        </h2>
        {entityInfo && (
          <>
            <InfoIcon
              style={{ cursor: "pointer", marginLeft: "15px" }}
              onClick={() => setOpenDialog(true)}
            />
            <InfoModal
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              entityInfo={{ ...entityInfo, title: "Tax Rates" }}
            />
          </>
        )}
        <CustomButton
          className="btn--secondary"
          style={{ marginLeft: "auto", marginRight: 0 }}
          label={"Manage Tax Rates"}
          onClick={() => navigate("/home/DoctorEntities/TaxRates")}
        />
        <CustomButton
          className="btn--secondary"
          style={{ marginLeft: "10px" }}
          label={"Manage Medical Service Categories"}
          onClick={() => navigate("/home/DoctorEntities/MedicalServiceCategory")}
        />
      </div>
      <Divider sx={{ mb: "10px", borderBottomWidth: 2 }} />
      <div style={{ display: "flex", justifyContent: "end" }}>
        {sourcePage === "billing" && (
          <CustomButton
            className="btn--primary"
            style={{ display: "flex", marginRight: "20px" }}
            label={"Move to Billing"}
            onClick={() => {
              navigate("/home/generateInvoice", { state: { ...sourceData } });
            }}
          />
        )}
        <CustomButton
          className="btn--primary"
          style={{ display: "flex" }}
          label={" + Add Service"}
          onClick={() => setOpenModal(true)}
        />
      </div>
      <Formik
        validateOnMount={true}
        enableReinitialize={true}
        initialValues={{
          categoryId: "",
          serviceName: "",
          amount: "",
          taxId: "",
          owner: ownershipArr[0]?.id,
        }}
        validationSchema={() => {
          return s;
        }}
      >
        {({ values, errors, handleBlur, handleChange, touched, setValues }) => (
          <form noValidate>
            <Box sx={{ width: "100%", mt: 2 }}>
              <>
                <Grid sx={{ m: "30px 0px" }}>
                  {organizationId && (
                    <>
                      <ModalUI
                        visible={openModal}
                        close={() => {
                          setOpenModal(false);
                          cancelEdit(values, setValues);
                        }}
                        title={"Product and Service"}
                        style={{
                          height: "25rem",
                          width: "30rem",
                          padding: "20px",
                        }}
                        component={
                          <>
                            <Typography
                              sx={{
                                fontSize: "20px",
                                fontWeight: 700,
                                marginTop: "15px",
                                // color: "#004C70",
                              }}
                            >
                              {isUpdateService ? "Update service" : "Add a New Service"}
                            </Typography>
                            <div style={{ marginTop: "10px" }}>
                              <FormSelectField
                                style={{ width: "48%", marginRight: "1rem" }}
                                label={"Category"}
                                onBlur={handleBlur}
                                required
                                name={"categoryId"}
                                value={values.categoryId}
                                size={"big"}
                                startAdornment={<CategoryOutlined />}
                                onChange={handleChange}
                                menuItems={categories.map((el) => {
                                  return {
                                    ...el,
                                    value: el?.id,
                                    menuLabel: el?.name,
                                  };
                                })}
                              ></FormSelectField>

                              <FormInputField
                                style={{
                                  width: "48%",
                                  // marginRight: "3.33%",
                                  marginBottom: "25px",
                                }}
                                label="Service Name"
                                name="serviceName"
                                required
                                value={values.serviceName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                startAdornment={<AbcOutlined />}
                                size={"big"}
                              />

                              <FormInputField
                                style={{
                                  width: "48%",
                                  marginRight: "3.33%",
                                  marginBottom: "25px",
                                }}
                                label="Amount"
                                name="amount"
                                type="tel"
                                required
                                value={values.amount}
                                error={Boolean(errors.amount && touched.amount)}
                                errorText={errors.amount}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                startAdornment={<CurrencyRupeeOutlined />}
                                size={"big"}
                              />

                              <FormSelectField
                                style={{ width: "48%" }}
                                label={"GST"}
                                name={"taxId"}
                                required
                                type="number"
                                value={values.taxId}
                                startAdornment={<PercentOutlined />}
                                onChange={handleChange}
                                menuItems={taxRates.map((el) => {
                                  return {
                                    ...el,
                                    value: el?.id,
                                    menuLabel: el?.rate,
                                  };
                                })}
                              ></FormSelectField>
                              <FormSelectField
                                style={{ width: "100%" }}
                                label={"Owned By"}
                                onBlur={handleBlur}
                                name={"owner"}
                                value={values.owner}
                                size={"big"}
                                startAdornment={<DomainOutlined />}
                                onChange={handleChange}
                                menuItems={ownershipArr.map((el) => {
                                  return {
                                    ...el,
                                    value: el?.id,
                                    menuLabel: el?.name,
                                  };
                                })}
                              ></FormSelectField>
                              <FormControl
                                width="8%"
                                style={{ display: "grid", justifyContent: "end" }}
                              >
                                <CustomButton
                                  className={
                                    isUpdateService
                                      ? "btn--primary"
                                      : "ri-add-fill ri-lg btn--primary"
                                  }
                                  style={{ marginTop: "20px" }}
                                  onClick={(e) => handleManageService(e, values, setValues)}
                                  label={isUpdateService ? "Update" : "Add"}
                                />
                              </FormControl>
                            </div>
                          </>
                        }
                      />
                      <CustomizedTable
                        columns={columns}
                        tableData={medicalServices}
                        actions={[
                          {
                            label: "Edit",
                            icon: <i className="ri-edit-line ri-xl icon-primary-blue" />,
                            onClick: (index) => {
                              handleEditService(index, setValues, values);
                              setOpenModal(true);
                            },
                          },
                          {
                            label: "Delete",
                            icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
                            onClick: (index) => handleDeleteService(index, values, setValues),
                          },
                        ]}
                      />
                    </>
                  )}
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    ml: 3.6,
                    mr: 3.6,
                  }}
                ></Box>
              </>
            </Box>
          </form>
        )}
      </Formik>
    </Reveal>
  );
};

export default ProductAndServices;
