import { Typography, IconButton, Skeleton, Box, Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useState, useEffect, useCallback, useContext } from "react";
import ModalUI from "ui-component/ModalUI";
import UpdateChronicDisease from "./UpdateChronicDisease";
import { chronicDiseaseValidation } from "../../Common/ValidationSchema/chronicDiseaseValidation";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  ACTIVE_STATE,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  SUPER_ADMIN,
  accessToken,
  entitiesInfo,
  roleName,
} from "store/constant";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { DescriptionOutlined } from "@mui/icons-material";
import {
  createChronicDiseases,
  deleteChronicDiseases,
  getChronicDiseases,
} from "services/EntitiesServices";
import Reveal from "views/utilities/Reveal";
import InfoModal from "../InfoModal";
import InfoIcon from "@mui/icons-material/Info";
import { Formik } from "formik";

const ChronicDisease = () => {
  const [chronicDisease, setChronicDisease] = useState([]);
  const [displayChronicDisease, setDisplayChronicDisease] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const { handleClick } = useContext(ToastContext);
  const accessTokenValue = accessToken();
  const entityInfo = entitiesInfo.find((entity) => entity.id === "Chronic Disease");
  const [openDialog, setOpenDialog] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await getChronicDiseases();
      setChronicDisease(response?.data);
    } catch (error) {
      console.error("Error fetching chronic-diseases");
    }
  }, []);

  const handleDelete = async (chronicDisease) => {
    if (chronicDisease.status === ACTIVE_STATE) {
      try {
        await deleteChronicDiseases(chronicDisease.id);
        handleClick("success", "Chronic-disease has been marked Inactive!");
        fetchData();
      } catch (e) {
        handleClick("error", "There seems to be an error marking the Chronic-disease as Inactive.");
      }
    } else {
      handleClick("info", "Chronic-disease is already inactive!");
    }
  };

  const clearSearch = () => {
    setSearchQuery("");
  };

  const addChronicDisease = async (values) => {
    const payload = {
      name: values.diseaseName,
      description: values.diseaseDescription,
    };

    if (chronicDisease.some((t) => t.name.toLowerCase() === payload.name.toLowerCase())) {
      handleClick("error", "Chronic Disease already exists");
      return;
    }

    try {
      const res = await createChronicDiseases(payload);
      handleClick("success", "Chronic Disease has been successfully added.");
      setChronicDisease([...chronicDisease, res.data]);
    } catch (e) {
      handleClick("error", "There seems to be an error creating the Chronic Disease.");
    }
  };

  useEffect(() => {
    setDisplayChronicDisease(
      chronicDisease.filter((s) =>
        s.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
      )
    );
  }, [chronicDisease, searchQuery]);

  useEffect(() => {
    fetchData();
  }, [accessTokenValue, fetchData]);

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (spec) => {
    setSelected(spec);
    setOpen(true);
  };

  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "name", label: "Name" },
    { field: "description", label: "Description" },
    { field: "Actions", label: "Actions" },
  ];

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleDelete,
    },
  ];

  return (
    <ChronicDiseaseSkeleton>
      <Reveal>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 style={{ marginBottom: "15px", fontSize: "25px", marginTop: "15px" }}>
            Chronic Disease
          </h2>
          {entityInfo && (
            <>
              <InfoIcon
                style={{ cursor: "pointer", marginLeft: "15px" }}
                onClick={() => setOpenDialog(true)}
              />
              <InfoModal
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                entityInfo={{ ...entityInfo, title: "Chronic Disease" }}
              />
            </>
          )}
        </div>
        <Divider sx={{ mb: "10px", borderBottomWidth: 2 }} />
        <Grid container spacing={3}>
          <Grid size={{ xs: 12 }}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Search any chronic disease
            </Typography>
            <Grid sx={{ mt: 2 }}>
              <FormInputField
                style={{ width: "30%" }}
                label={"Search chronic disease"}
                value={searchQuery}
                startAdornment={<i className="ri-search-line ri-lg" />}
                endAdornment={
                  searchQuery && (
                    <IconButton onClick={clearSearch} edge="end">
                      {<ClearOutlinedIcon fontSize="small" />}
                    </IconButton>
                  )
                }
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                size="small"
              ></FormInputField>
            </Grid>
          </Grid>

          {roleName() === SUPER_ADMIN && (
            <Grid size={{ xs: 12 }}>
              <Typography
                sx={{
                  fontSize: "19px",
                  fontWeight: 600,
                  color: "#004C70",
                }}
              >
                Add a new chronic disease
              </Typography>
              <Formik
                validateOnMount={true}
                initialValues={{ diseaseName: "", diseaseDescription: "" }}
                validationSchema={chronicDiseaseValidation}
                onSubmit={async (values, { resetForm }) => {
                  addChronicDisease(values);
                  resetForm();
                }}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                  <>
                    <Grid container sx={{ mt: 2 }}>
                      <Grid size={{ xs: 5 }} sx={{ mr: 2 }}>
                        <FormInputField
                          label={"Chronic disease Name"}
                          value={values.diseaseName}
                          name={"diseaseName"}
                          inputProps={{ maxLength: 50 }}
                          error={Boolean(touched.diseaseName && errors.diseaseName)}
                          errorText={errors.diseaseName}
                          onChange={handleChange}
                          startAdornment={<DriveFileRenameOutlineOutlinedIcon />}
                          size="small"
                        ></FormInputField>
                      </Grid>
                      <Grid size={{ xs: 5 }} sx={{ mr: 2 }}>
                        <FormInputField
                          label={"Chronic disease Description"}
                          value={values.diseaseDescription}
                          name={"diseaseDescription"}
                          inputProps={{ maxLength: 255 }}
                          error={Boolean(touched.diseaseDescription && errors.diseaseDescription)}
                          errorText={errors.diseaseDescription}
                          onChange={handleChange}
                          startAdornment={<DescriptionOutlined />}
                          size="small"
                        ></FormInputField>
                      </Grid>
                      <Grid sx={{ mt: 0.5 }}>
                        <CustomButton
                          className="btn--primary"
                          disabled={values.diseaseName === ""}
                          onClick={handleSubmit}
                          label="Add"
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Formik>
            </Grid>
          )}
        </Grid>
        <Grid
          sx={{
            mt: 4,
            textAlign: "center",
          }}
        ></Grid>
        <CustomizedTable columns={columns} tableData={displayChronicDisease} actions={actions} />

        <ModalUI
          visible={open}
          close={closeModal}
          title="Update Chronic Diesease"
          component={
            <UpdateChronicDisease
              selected={selected}
              close={() => {
                closeModal();
                fetchData();
              }}
            />
          }
        />
      </Reveal>
    </ChronicDiseaseSkeleton>
  );
};

const ChronicDiseaseSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="35%"
            height={80}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="90%"
            height={80}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChronicDisease;
