import {
  Add,
  ArrowBackIos,
  CalendarToday,
  CancelOutlined,
  ClearOutlined,
  DirectionsRunOutlined,
  DomainVerification,
  DomainVerificationOutlined,
  EventOutlined,
  FormatListBulleted,
  MenuBook,
  People,
  PlayCircleOutline,
  VideoChatOutlined,
  Visibility,
} from "@mui/icons-material";
import "assets/scss/DoctorHomeScreen/patient-appointment-list.scss";
// import Grid from "@mui/material/Grid2";
import { useNavigate, useOutletContext } from "react-router";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomFullCalendar from "ui-component/custom-components/CustomFullCalendar";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuList,
  Paper,
  Skeleton,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useContext, useState } from "react";
import { useEffect } from "react";
import styled from "@emotion/styled";
import {
  CANCELLED,
  CHECKED_IN,
  CLINIC_VISIT,
  convertTimeForTimeFormat,
  currentActiveUser,
  DATE_FORMAT,
  hiuToken,
  orgId,
  TELE_CONSULTATION,
} from "store/constant";
import dayjs from "dayjs";
import { setSelectedAppointmentData } from "store/Slices/appointmentDataSlice";
import { getAppointmentById, updateAppointmentStatus } from "services/Appointments";
import { getProfilePicture } from "services/patientService";
import { useDispatch } from "react-redux";
import { calculateAge } from "utils/calculate-age";
import CustomMenuItem from "ui-component/custom-components/CustomMenuItem";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { setPrescriptionId, setPrescriptionPadData } from "store/Slices/prescriptionPadSlice";
import { setClearInvoiceState } from "store/Slices/billingInvoiceSlice";

const PatientListForDashboard = () => {
  const navigate = useNavigate();

  const { patientListSharedValue } = useOutletContext();
  const {
    handleNewModalOpenForBookingAppointment,
    appointmentInfo,
    setAppointmentSelectedOnFirstLoad,
    isMediumScreen,
    sethuSirLayout,
    isExtraLargeScreenForCalendarView,
    setCollapseList,
    collapseList,
    setIframeOpen,
    setIframeSrc,
    isLargeScreen,
    isExtraLargeScreen,
    appointmentSelectedOnFirstLoad,
    setCheckedInAppointment,
    setTodaysAppointments,
    handleCancelAppointment,
    isCalendarView,
    setIsCalendarView,
    calendarView,
    setCalendarView,
    viewInfo,
    setViewInfo,
    appointmentResponse,
    setFilteredDoctorEvents,
    allEvents,
    filteredDoctorEvents,
    eventClick,
    doctorBlockCalendar,
    doctorBlockCalendarEvents,
    doctorData,
    handleFilteredAppointmentsChange,
    handleMonthViewMore,
    setShowAppointmentList,
    handlePrescriptionClick,
    todaysAppointments,
    patients,
    selectedAppointment,
    setSelectedAppointment,
    parentAppointmentsRefresh,
    handleNewModalOpenForReschedulingAppointment,
    setAppointmentModalTitle,
    setIsNewModalOpen,
  } = patientListSharedValue;

  const [searchQuery, setSearchQuery] = useState("");
  const [displayTodaysAppointments, setDisplayTodaysAppointments] = useState([]);
  const { handleClick } = useContext(ToastContext);

  const clearSearch = () => {
    setSearchQuery("");
  };

  useEffect(() => {
    const DisplayTodaysAppointments = todaysAppointments?.filter((item) => {
      const lowerCaseQuery = searchQuery.toLowerCase();
      if (
        item?.patientName?.toLowerCase().includes(lowerCaseQuery) ||
        item?.patientMobileNumber?.toLowerCase().includes(lowerCaseQuery) ||
        item?.mobileNumber?.toLowerCase().includes(lowerCaseQuery)
      ) {
        return true;
      }
      return false;
    });
    setDisplayTodaysAppointments(DisplayTodaysAppointments);
  }, [searchQuery, todaysAppointments]);

  const CustomizedToolTip = styled(({ className, color, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme, color }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: color || "#29bf91",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      height: "2rem",
      fontSize: "1rem",
      textAlign: "center",
      color: "#fff",
      border: `0.5px solid ${color || "#29bf91"}`,
      backgroundColor: color || "#29bf91",
      display: "flex",
      alignItems: "center",
      padding: "8px",
    },
  }));

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", height: "100%" }}>
        {sethuSirLayout ? (
          <Grid className={`my-patients-header ${collapseList ? "collapsed-list" : ""}`}>
            {/* <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}> */}
            {/* <div
              className="patient-title"
            >
              My Patients
            </div> */}
            {!collapseList && (
              <>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "0.3rem" }}>
                  <div
                    style={{
                      // paddingLeft: "10px",
                      fontSize: "16px",
                      fontWeight: "600",
                      margin: "0",
                      color: "#004c70",
                      lineHeight: "normal",
                    }}
                  >
                    My Patients
                  </div>
                  <div
                    className="appointments-stats"
                    style={{ backgroundColor: sethuSirLayout ? "transparent" : "" }}
                  >
                    {sethuSirLayout ? (
                      <>
                        <CustomizedToolTip title="Checked-In">
                          <span
                            style={{
                              verticalAlign: "middle",
                              color: "#29bf91",
                              fontSize: "18px",
                              marginLeft: "5px",
                            }}
                          >
                            {appointmentInfo?.checkedIn}
                          </span>
                        </CustomizedToolTip>
                        <CustomizedToolTip color={"#000000"} title="Total">
                          <span
                            style={{
                              verticalAlign: "middle",
                              color: "#000000",
                              fontSize: "18px",
                              marginLeft: "5px",
                            }}
                          >
                            {appointmentInfo?.totalAppointments}
                          </span>
                        </CustomizedToolTip>
                      </>
                    ) : (
                      <>
                        <Grid className="stat-item">
                          <CustomizedToolTip title="Checked-In">
                            <CustomButton
                              iconButton={<DomainVerificationOutlined />}
                              label={appointmentInfo?.checkedIn}
                              className={"light-blue"}
                              gap={"0px"}
                              style={{ padding: "6px 0px", marginRight: "5px" }}
                            />
                          </CustomizedToolTip>
                          <Box
                            component={"span"}
                            sx={{
                              fontSize: { md: "12px", lg: "13px", xl: "14px" },
                            }}
                            className={"light-blue"}
                          >
                            {appointmentInfo?.checkedIn}
                          </Box>
                        </Grid>
                        <div className="light-blue">|</div>
                        <Grid className="stat-item">
                          <CustomizedToolTip title="In-Clinic">
                            <CustomButton
                              iconButton={<DirectionsRunOutlined />}
                              label={appointmentInfo?.offlineAppointments}
                              className={"light-blue"}
                              gap={"0px"}
                              style={{ padding: "6px 0px", marginRight: "5px" }}
                            />
                          </CustomizedToolTip>
                          <Box
                            component={"span"}
                            sx={{
                              fontSize: { md: "12px", lg: "13px", xl: "14px" },
                            }}
                            className={"light-blue"}
                          >
                            {appointmentInfo?.offlineAppointments}
                          </Box>
                        </Grid>
                        <div className="light-blue">|</div>
                        <Grid className="stat-item">
                          <CustomizedToolTip title="Tele-Consultation">
                            <CustomButton
                              iconButton={<VideoChatOutlined />}
                              label={appointmentInfo?.onlineAppointments}
                              className={"light-blue"}
                              gap={"0px"}
                              style={{ padding: "6px 0px", marginRight: "5px" }}
                            />
                          </CustomizedToolTip>
                          <Box
                            component={"span"}
                            sx={{
                              fontSize: { md: "12px", lg: "13px", xl: "14px" },
                            }}
                            className={"light-blue"}
                          >
                            {appointmentInfo?.onlineAppointments}
                          </Box>
                        </Grid>
                      </>
                    )}
                  </div>
                </div>
                {/* </Grid> */}

                <Grid>
                  {/* <CustomButton
                    startIcon={<AddOutlined />}
                    label={"Appointment"}
                    className={"btn--primary"}
                    gap={"0px"}
                    onClick={handleNewModalOpenForBookingAppointment}
                    style={{ marginRight: "10px" }}
                  /> */}
                  {isMediumScreen ? (
                    <Tooltip title={isCalendarView ? "Today's schedule" : "Calendar view"}>
                      <CustomButton
                        className={"btn--secondary-light"}
                        iconButton={isCalendarView ? <FormatListBulleted /> : <CalendarToday />}
                        gap={"0px"}
                        onClick={() => {
                          if (isCalendarView) {
                            setViewInfo(null);
                          } else {
                            setAppointmentSelectedOnFirstLoad(false);
                          }
                          setIsCalendarView(!isCalendarView);
                        }}
                        // style={{padding: "6px 6px"}}
                      />
                    </Tooltip>
                  ) : (
                    <CustomButton
                      startIcon={isCalendarView ? <FormatListBulleted /> : <CalendarToday />}
                      label={isCalendarView ? "Today's schedule" : "Calendar view"}
                      className={"btn--secondary-light"}
                      gap={"0px"}
                      style={{ padding: isCalendarView ? "" : "6px 25.82px" }}
                      onClick={() => {
                        if (isCalendarView) {
                          setViewInfo(null);
                        } else {
                          setAppointmentSelectedOnFirstLoad(false);
                        }
                        setIsCalendarView(!isCalendarView);
                      }}
                    />
                  )}
                  {/* <CustomButton
                    startIcon={isCalendarView ? <FormatListBulleted /> : <CalendarToday />}
                    label={isMediumScreen ? undefined : isCalendarView ? "Today's schedule" : "Calendar view"}
                    className={"btn--secondary-light"}
                    iconButton={isMediumScreen ? isCalendarView ? <FormatListBulleted /> : <CalendarToday /> : undefined}
                    gap={"0px"}
                    style={{ padding: isMediumScreen ? "" : isCalendarView ? "" : "6px 25.82px" }}
                    onClick={() => {
                      if (isCalendarView) {
                        setViewInfo(null);
                      } else {
                        setAppointmentSelectedOnFirstLoad(false);
                      }
                      setIsCalendarView(!isCalendarView);
                    }}
                  /> */}
                </Grid>
              </>
            )}

            {!isCalendarView && (
              <div>
                <CustomButton
                  // className={"btn--primary-light"}
                  textAndIconColor="#004c70"
                  iconButton={collapseList ? <MenuIcon /> : <ArrowBackIos fontSize="small" />}
                  onClick={() => {
                    setCollapseList((prevValue) => {
                      return !prevValue;
                    });
                  }}
                  gap="0px"
                  style={{ padding: collapseList ? undefined : "8px 0px 8px 8px" }}
                />
              </div>
            )}
          </Grid>
        ) : (
          <Grid className={`my-patients-header ${collapseList ? "collapsed-list" : ""}`}>
            {!collapseList && (
              <>
                <div
                  className="appointments-stats"
                  style={{ backgroundColor: sethuSirLayout ? "transparent" : "" }}
                >
                  <Grid className="stat-item">
                    <Tooltip title="Checked-In">
                      <DomainVerificationOutlined sx={{ m: "5px 5px 5px 0px", color: "#004c70" }} />
                      {/* <CustomButton
                        iconButton={<DomainVerificationOutlined />}
                        label={appointmentInfo?.checkedIn}
                        className={"light-blue"}
                        gap={"0px"}
                        style={{ padding: "6px 0px", marginRight: "5px" }}
                      /> */}
                    </Tooltip>
                    <Box
                      component={"span"}
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      }}
                      className={"light-blue"}
                    >
                      {appointmentInfo?.checkedIn}
                    </Box>
                  </Grid>
                  <div className="light-blue">|</div>
                  <Grid className="stat-item">
                    <Tooltip title="In-Clinic">
                      <DirectionsRunOutlined sx={{ m: "5px 5px 5px 0px", color: "#004c70" }} />
                      {/* <CustomButton
                        iconButton={<DirectionsRunOutlined />}
                        label={appointmentInfo?.offlineAppointments}
                        className={"light-blue"}
                        gap={"0px"}
                        style={{ padding: "6px 0px", marginRight: "5px" }}
                      /> */}
                    </Tooltip>
                    <Box
                      component={"span"}
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      }}
                      className={"light-blue"}
                    >
                      {appointmentInfo?.offlineAppointments}
                    </Box>
                  </Grid>
                  <div className="light-blue">|</div>
                  <Grid className="stat-item">
                    <Tooltip title="Tele-Consultation">
                      <VideoChatOutlined sx={{ m: "5px 5px 5px 0px", color: "#004c70" }} />
                      {/* <CustomButton
                        iconButton={<VideoChatOutlined />}
                        label={appointmentInfo?.onlineAppointments}
                        className={"light-blue"}
                        gap={"0px"}
                        style={{ padding: "6px 0px", marginRight: "5px" }}
                      /> */}
                    </Tooltip>
                    <Box
                      component={"span"}
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      }}
                      className={"light-blue"}
                    >
                      {appointmentInfo?.onlineAppointments}
                    </Box>
                  </Grid>
                </div>

                <Grid>
                  {/* <CustomButton
                    startIcon={<AddOutlined />}
                    label={"Appointment"}
                    className={"btn--primary"}
                    gap={"0px"}
                    onClick={handleNewModalOpenForBookingAppointment}
                    style={{ marginRight: "10px" }}
                  /> */}
                  {isMediumScreen ? (
                    <Tooltip title={isCalendarView ? "Today's schedule" : "Calendar view"}>
                      <CustomButton
                        className={"btn--secondary-light"}
                        iconButton={isCalendarView ? <FormatListBulleted /> : <CalendarToday />}
                        gap={"0px"}
                        onClick={() => {
                          if (isCalendarView) {
                            setViewInfo(null);
                          } else {
                            setAppointmentSelectedOnFirstLoad(false);
                          }
                          setIsCalendarView(!isCalendarView);
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <CustomButton
                      startIcon={isCalendarView ? <FormatListBulleted /> : <CalendarToday />}
                      label={isCalendarView ? "Today's schedule" : "Calendar view"}
                      className={"btn--secondary-light"}
                      gap={"0px"}
                      style={{ padding: isCalendarView ? "" : "6px 25.82px" }}
                      onClick={() => {
                        if (isCalendarView) {
                          setViewInfo(null);
                        } else {
                          setAppointmentSelectedOnFirstLoad(false);
                        }
                        setIsCalendarView(!isCalendarView);
                      }}
                    />
                  )}
                  {/* <CustomButton
                    startIcon={isCalendarView ? <FormatListBulleted /> : <CalendarToday />}
                    label={isMediumScreen ? undefined : isCalendarView ? "Today's schedule" : "Calendar view"}
                    className={"btn--secondary-light"}
                    iconButton={isMediumScreen ? isCalendarView ? <FormatListBulleted /> : <CalendarToday /> : undefined}
                    gap={"0px"}
                    style={{ padding: isMediumScreen ? "" : isCalendarView ? "" : "6px 25.82px" }}
                    onClick={() => {
                      if (isCalendarView) {
                        setViewInfo(null);
                      } else {
                        setAppointmentSelectedOnFirstLoad(false);
                      }
                      setIsCalendarView(!isCalendarView);
                    }}
                  /> */}
                </Grid>
              </>
            )}

            {!isCalendarView && (
              <div>
                <CustomButton
                  // className={"btn--primary-light"}
                  textAndIconColor="#004c70"
                  iconButton={collapseList ? <MenuIcon /> : <ArrowBackIos fontSize="small" />}
                  onClick={() => {
                    setCollapseList((prevValue) => {
                      return !prevValue;
                    });
                  }}
                  gap="0px"
                  style={{ padding: collapseList ? undefined : "8px 0px 8px 8px" }}
                />
              </div>
            )}
          </Grid>
        )}
        {collapseList && <Divider />}
        {isCalendarView ? (
          <div style={{ flexGrow: 1 }}>
            <CustomFullCalendar
              isExtraLargeScreenForCalendarView={isExtraLargeScreenForCalendarView}
              appointmentResponse={appointmentResponse}
              setCalendarView={setCalendarView}
              viewInfo={viewInfo}
              setViewInfo={setViewInfo}
              calendarView={calendarView}
              setFilteredDoctorEvents={setFilteredDoctorEvents}
              allEvents={allEvents}
              filteredDoctorEvents={filteredDoctorEvents}
              eventClick={eventClick}
              blockedDays={doctorBlockCalendar}
              blockedEvents={doctorBlockCalendarEvents}
              slotDuration={doctorData?.avgAppointmentDuration <= 15 ? 30 : 60}
              onFilteredAppointmentsChange={handleFilteredAppointmentsChange}
              handleMonthViewMore={handleMonthViewMore}
              setShowAppointmentList={setShowAppointmentList}
              handlePrescriptionClick={handlePrescriptionClick}
            ></CustomFullCalendar>
          </div>
        ) : (
          <>
            <div
              style={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                maxHeight: "100%",
                gap: collapseList ? undefined : "0.5rem",
              }}
            >
              {collapseList ? (
                <div style={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
                  <div style={{ minHeight: "70px" }}>
                    <div
                      className={`appointments-stats collapsed-list light-blue`}
                      style={{ backgroundColor: sethuSirLayout ? "transparent" : "" }}
                    >
                      <Grid className={`stat-item collapsed-list`}>
                        <Tooltip title="Checked-In" placement="right">
                          <DomainVerificationOutlined />
                          {/* <CustomButton
                              iconButton={<DomainVerificationOutlined />}
                              label={appointmentInfo?.checkedIn}
                              className={"light-blue"}
                              gap={"0px"}
                              style={{ padding: "6px 0px 0px" }}
                            /> */}
                        </Tooltip>
                        <Box
                          component={"span"}
                          sx={{
                            fontSize: { md: "12px", lg: "13px", xl: "14px" },
                          }}
                        >
                          {appointmentInfo?.checkedIn}
                        </Box>
                      </Grid>
                      <Divider />
                      <Grid className="stat-item collapsed-list">
                        <Tooltip title="In-Clinic" placement="right">
                          <DirectionsRunOutlined />
                          {/* <CustomButton
                              iconButton={<DirectionsRunOutlined />}
                              label={appointmentInfo?.offlineAppointments}
                              className={"light-blue"}
                              gap={"0px"}
                              style={{ padding: "6px 0px 0px" }}
                            /> */}
                        </Tooltip>
                        <Box
                          component={"span"}
                          sx={{
                            fontSize: { md: "12px", lg: "13px", xl: "14px" },
                          }}
                        >
                          {appointmentInfo?.offlineAppointments}
                        </Box>
                      </Grid>
                      <Divider />
                      <Grid className="stat-item collapsed-list">
                        <Tooltip title="Tele-Consultation" placement="right">
                          <VideoChatOutlined />
                          {/* <CustomButton
                              iconButton={<VideoChatOutlined />}
                              label={appointmentInfo?.onlineAppointments}
                              className={"light-blue"}
                              gap={"0px"}
                              style={{ padding: "6px 0px 0px" }}
                            /> */}
                        </Tooltip>
                        <Box
                          component={"span"}
                          sx={{
                            fontSize: { md: "12px", lg: "13px", xl: "14px" },
                          }}
                          className={"light-blue"}
                        >
                          {appointmentInfo?.onlineAppointments}
                        </Box>
                      </Grid>
                    </div>
                  </div>
                  <Divider />
                  <List sx={{ p: "0px !important" }}>
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <Tooltip title="Book Appointment" placement="right">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <CustomButton
                            textAndIconColor="#485772"
                            iconButton={<Add />}
                            onClick={handleNewModalOpenForBookingAppointment}
                            gap="0px"
                          />
                        </div>
                      </Tooltip>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <Tooltip title="View Calendar" placement="right">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <CustomButton
                            textAndIconColor="#485772"
                            iconButton={<CalendarToday />}
                            onClick={() => {
                              setAppointmentSelectedOnFirstLoad(false);
                              setIsCalendarView(!isCalendarView);
                              setCollapseList(false);
                            }}
                            gap="0px"
                          />
                        </div>
                      </Tooltip>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <Tooltip title="My Patients" placement="right">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <CustomButton
                            textAndIconColor="#485772"
                            iconButton={<People />}
                            onClick={() => {
                              navigate("/home/patient");
                            }}
                            gap="0px"
                          />
                        </div>
                      </Tooltip>
                    </ListItem>
                  </List>
                </div>
              ) : (
                <>
                  <div>
                    <FormInputField
                      style={{ width: "100%" }}
                      label={
                        isMediumScreen
                          ? "By Patient Name and Mobile"
                          : "Search Patients by Name and Mobilenumber"
                      }
                      value={searchQuery}
                      startAdornment={<i className="ri-search-line ri-lg" />}
                      endAdornment={
                        searchQuery ? (
                          <IconButton onClick={clearSearch} edge="end">
                            {<ClearOutlined fontSize="small" />}
                          </IconButton>
                        ) : (
                          <></>
                        )
                      }
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      size="small"
                    ></FormInputField>
                  </div>
                  {sethuSirLayout ? (
                    <>
                      {todaysAppointments?.length > 0 && (
                        <div
                          style={{
                            flexGrow: 1,
                            maxHeight: "100%",
                            overflowY: "auto",
                          }}
                          className="common-scrollbar"
                        >
                          <div
                            className="common-scrollbar"
                            style={{ width: "100%", padding: "0px 5px 0px 0px" }}
                          >
                            {displayTodaysAppointments.map((item, index) => {
                              return (
                                <PatientAppointmentCardV2
                                  todaysAppointments={todaysAppointments}
                                  isMediumScreen={isMediumScreen}
                                  setIframeOpen={setIframeOpen}
                                  setIframeSrc={setIframeSrc}
                                  setCheckedInAppointment={setCheckedInAppointment}
                                  key={index}
                                  itemLength={displayTodaysAppointments.length}
                                  item={item}
                                  selectedAppointment={selectedAppointment}
                                  setSelectedAppointment={setSelectedAppointment}
                                  index={index}
                                  parentAppointmentsRefresh={parentAppointmentsRefresh}
                                  doctorData={doctorData}
                                  setAppointmentModalTitle={setAppointmentModalTitle}
                                  setIsNewModalOpen={setIsNewModalOpen}
                                  handleNewModalOpenForReschedulingAppointment={
                                    handleNewModalOpenForReschedulingAppointment
                                  }
                                  handleCancelAppointment={handleCancelAppointment}
                                  handleClick={handleClick}
                                  setTodaysAppointments={setTodaysAppointments}
                                />
                              );
                            })}
                          </div>
                          {/* {patients && (
                            <div className="common-scrollbar" style={{ width: "100%", padding: "5px 5px 5px 0px" }}>
                              {patients.map((item, index) => {
                                return (
                                  <PatientAppointmentCard
                                    itemsLength={patients.length}
                                    item={item}
                                    selectedAppointment={selectedAppointment}
                                    setSelectedAppointment={setSelectedAppointment}
                                    index={index}
                                    isPatientCard={true}
                                    setAppointmentModalTitle={setAppointmentModalTitle}
                                    setIsNewModalOpen={setIsNewModalOpen}
                                    parentAppointmentsRefresh={parentAppointmentsRefresh}
                                    doctorData={doctorData}
                                  />
                                );
                              })}
                            </div>
                          )} */}
                          {/* {displayTodaysAppointments && displayTodaysAppointments.length === 0 && (
                            <div>No Patients...</div>
                          )} */}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {todaysAppointments?.length > 1 && (
                        <div
                          style={{
                            flexGrow: 1,
                            maxHeight: "100%",
                            overflowY: "auto",
                          }}
                          className="common-scrollbar"
                        >
                          <div
                            className="common-scrollbar"
                            style={{ width: "100%", padding: "0px 5px 0px 0px" }}
                          >
                            {displayTodaysAppointments
                              .filter((app) => app?.id !== selectedAppointment?.id)
                              .map((item, index) => {
                                return (
                                  <PatientAppointmentCardV2
                                    todaysAppointments={todaysAppointments}
                                    isMediumScreen={isMediumScreen}
                                    setIframeSrc={setIframeSrc}
                                    setIframeOpen={setIframeOpen}
                                    setCheckedInAppointment={setCheckedInAppointment}
                                    key={index}
                                    itemLength={displayTodaysAppointments.length}
                                    item={item}
                                    selectedAppointment={selectedAppointment}
                                    setSelectedAppointment={setSelectedAppointment}
                                    index={index}
                                    parentAppointmentsRefresh={parentAppointmentsRefresh}
                                    doctorData={doctorData}
                                    setAppointmentModalTitle={setAppointmentModalTitle}
                                    setIsNewModalOpen={setIsNewModalOpen}
                                    handleNewModalOpenForReschedulingAppointment={
                                      handleNewModalOpenForReschedulingAppointment
                                    }
                                    handleCancelAppointment={handleCancelAppointment}
                                    handleClick={handleClick}
                                    setTodaysAppointments={setTodaysAppointments}
                                  />
                                );
                              })}
                          </div>
                          {/* {patients && (
                            <div className="common-scrollbar" style={{ width: "100%", padding: "5px 5px 5px 0px" }}>
                              {patients.map((item, index) => {
                                return (
                                  <PatientAppointmentCard
                                    itemsLength={patients.length}
                                    item={item}
                                    selectedAppointment={selectedAppointment}
                                    setSelectedAppointment={setSelectedAppointment}
                                    index={index}
                                    isPatientCard={true}
                                    setAppointmentModalTitle={setAppointmentModalTitle}
                                    setIsNewModalOpen={setIsNewModalOpen}
                                    parentAppointmentsRefresh={parentAppointmentsRefresh}
                                    doctorData={doctorData}
                                  />
                                );
                              })}
                            </div>
                          )} */}
                          {/* {displayTodaysAppointments && displayTodaysAppointments.length === 0 && (
                            <div>No Patients...</div>
                          )} */}
                        </div>
                      )}
                    </>
                  )}

                  <Grid sx={{ display: "flex", justifyContent: "center", mt: "0px", pr: "5px" }}>
                    {/* {displayTodaysAppointments && displayTodaysAppointments.length < 2 && ( */}
                    <CustomButton
                      // startIcon={<AddOutlined />}
                      label={"Book Appointment"}
                      className={"btn--primary"}
                      gap={"0px"}
                      onClick={handleNewModalOpenForBookingAppointment}
                      style={{ marginRight: "10px" }}
                    />
                    {/* )} */}
                    <CustomButton
                      startIcon={<i className="ri-list-check-line" />}
                      label={"My Patients"}
                      className={"btn--primary-light"}
                      onClick={() => {
                        navigate("/home/patient");
                      }}
                      gap="0px"
                      style={{ padding: "6px 12px 6px 7.8px" }}
                    />
                  </Grid>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const PatientAppointmentCardV2 = ({
  item,
  isMediumScreen,
  selectedAppointment,
  index,
  setSelectedAppointment,
  setCheckedInAppointment,
  todaysAppointments,
  // isPatientCard,
  itemsLength,
  setAppointmentModalTitle,
  setIsNewModalOpen,
  handleNewModalOpenForReschedulingAppointment,
  parentAppointmentsRefresh,
  doctorData,
  handleCancelAppointment,
  handleClick,
  setTodaysAppointments,
  setIframeOpen,
  setIframeSrc,
}) => {
  const [profileImageFileWithContent, setProfileImageFileWithContent] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchProfilePic = async () => {
      setIsLoading(true);
      try {
        const profilePicResponse = await getProfilePicture(item?.patientUserId || item?.userId);
        const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;
        const profilePicWithContent = {
          filename: profilePicResponse.data.filename,
          content: content,
        };
        setProfileImageFileWithContent(profilePicWithContent);
      } catch (error) {
        setProfileImageFileWithContent(null);
        console.error("Error fetching profile picture", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProfilePic();
  }, [item?.patientUserId, item?.userId]);

  const handleBookAppointment = () => {
    const appointment = {
      patientMobile: item.mobileNumber,
      isUhiDoctor: false,
      orgId: orgId(),
      appointmentDate: dayjs().format(DATE_FORMAT),
      appointmentTime: null,
      patientId: item?.id,
      doctorId: currentActiveUser().roleBasedId,
      notes: "",
      type: null,
    };
    dispatch(setSelectedAppointmentData(appointment));
    setAppointmentModalTitle("Book Appointment");
    setIsNewModalOpen(true);
    // setisModalOpen(true);
  };
  const navigate = useNavigate();
  const actions = !item?.patientId
    ? [
        {
          label: "Book Appointment",
          icon: <i className="ri-contract-line ri-xl icon-primary-blue" />,
          onClick: handleBookAppointment,
        },
      ]
    : [
        {
          label: "Reschedule",
          icon: <EventOutlined style={{ color: "#004c70" }} />,
          onClick: () => {
            handleNewModalOpenForReschedulingAppointment(item);
          },
        },
        {
          label: "Check-In",
          icon: <DomainVerification style={{ color: "#29bf91" }} />,
          onClick: async () => {
            // try {
            //   await updateAppointmentStatus(item?.id, CHECKED_IN);
            //   const UpdatedItem = { ...item, appointmentStatus: CHECKED_IN };
            //   setUpdatedItem(UpdatedItem);
            //   // parentAppointmentsRefresh(doctorData);
            // } catch {
            //   console.error("Error cancelling appointment");
            // }
            try {
              await updateAppointmentStatus(item?.id, CHECKED_IN);
              handleClick("success", "Patient Checked-in successfully!");
              item.appointmentStatus = CHECKED_IN;
              setCheckedInAppointment(item);

              // setTodaysAppointments((prevValue) => {

              //   const currentAppointment = prevValue.find(app => app?.id === item?.id);

              //   currentAppointment.appointmentStatus = CHECKED_IN;
              //   return [...prevValue.filter(app => app?.id !== item?.id), currentAppointment];
              // });
              // selectedAppointment.appointmentStatus = CHECKED_IN;
              // const UpdatedItem = { ...item, appointmentStatus: CHECKED_IN };
              // setUpdatedItem(UpdatedItem);
              // parentAppointmentsRefresh(doctorData);
            } catch {
              handleClick("error", "There seems to be an error in making patient checked-in");
            }
          },
          disabled: item?.appointmentStatus !== CHECKED_IN ? false : true,
        },
        {
          label: "View EHR",
          icon: <Visibility style={{ color: "#004c70" }} />,
          onClick: () => {
            navigate(`/home/ehr/${item?.patientId}`);
          },
        },
        {
          label: "Fetch Abha Records",
          icon: <img height="20px" src="/images/abha_logo.svg" alt="abha_logo"></img>,
          onClick: () => {
            setIframeOpen(true);
            setIframeSrc(
              `https://abdmgateway.argusservices.in/abdm-m3-ui/consent-list?token=${hiuToken}&username=${doctorData?.id}&searchValue=${selectedAppointment?.patient?.abhaId}&themeColor=004c70`
            );
          },
        },
        {
          label: "Cancel",
          icon: <CancelOutlined style={{ color: "#f5574c" }} />,
          onClick: () => {
            handleCancelAppointment(item);
          },
          // async () => {
          //   try {
          //     await updateAppointmentStatus(item?.id, CANCELLED);
          //     parentAppointmentsRefresh(doctorData);
          //   } catch {
          //     console.error("Error cancelling appointment");
          //   }
          // },
        },
      ];

  return (
    <Paper
      elevation={selectedAppointment && item.id === selectedAppointment.id ? 3 : 0}
      sx={{
        border:
          selectedAppointment && item.id === selectedAppointment.id ? "" : "0.5px solid lightgray",
        ...(item?.appointmentStatus === CHECKED_IN
          ? {
              border: "1px solid #29bf91",
            }
          : {}),
        borderRadius: "12px",
        p: "10px",
        mb: `${index === itemsLength - 1 ? 0 : 10}px`,
        // mt:"10px",
        backgroundColor:
          selectedAppointment && (item.id === selectedAppointment.id ? "#e6eef1" : "#ffffff"),
      }}
    >
      <Grid
        // spacing={2}
        // container
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "space-between",
          borderRadius: "25px",
          // height: "40px",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            cursor: "pointer",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            width: "90%",
          }}
          onClick={async () => {
            try {
              if (!item?.patientId) {
                setSelectedAppointment(item);
              } else {
                const appointment = await getAppointmentById(item.id);
                setSelectedAppointment(appointment.data);
              }
            } catch (error) {
              console.error("Error fetching appointment");
            }
          }}
        >
          <Grid sx={{ width: "45px" }} display={"flex"} alignItems={"center"}>
            {isLoading ? (
              <Skeleton variant="circular" animation="wave" width={"45px"} height={"45px"} />
            ) : (
              <img
                style={{
                  borderRadius: "50%",
                  // marginRight: "6px",
                  width: "45px",
                  height: "45px",
                }}
                className="patient-avatar"
                src={
                  profileImageFileWithContent
                    ? profileImageFileWithContent.content
                    : item?.genedr === "FEMALE"
                    ? "/images/woman.png"
                    : "/images/man.png"
                }
                alt=""
              ></img>
            )}
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                mb: "0px !important",
              }}
            >
              {item?.patientName && (
                <Tooltip title={item?.patientName}>
                  <Box
                    component={"span"}
                    sx={{
                      fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      // minWidth: "200px",
                      maxWidth: "150px", // Adjust this value as needed
                      display: "inline-block",
                    }}
                  >
                    {item?.patientName}
                  </Box>
                </Tooltip>
              )}
              {!isMediumScreen && (
                <>
                  {item?.patientName && item?.dateOfBirth && (
                    <Box
                      component={"span"}
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      }}
                    >
                      &nbsp;<strong>{"|"}</strong>&nbsp;
                    </Box>
                  )}
                  {item?.dateOfBirth && (
                    <Box
                      component={"span"}
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      }}
                    >{`${calculateAge(item?.dateOfBirth)}y`}</Box>
                  )}

                  {((item?.dateOfBirth && item?.gender) || (item?.patientName && item?.gender)) && (
                    <Box
                      component={"span"}
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      }}
                    >
                      &nbsp;<strong>{"|"}</strong>&nbsp;
                    </Box>
                  )}
                  {item?.gender && (
                    <Box
                      component={"span"}
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      }}
                    >
                      {item?.gender.charAt(0)}
                    </Box>
                  )}
                </>
              )}
            </Grid>
            {item?.appointmentTime && item?.type && item.appointmentStatus && (
              <Grid
                sx={{
                  width: "100%",
                  display: "flex",
                  mt: "7px",
                  // justifyContent: "space-between",
                }}
              >
                <Box
                  component={"span"}
                  sx={{
                    fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  }}
                >
                  {item?.type === CLINIC_VISIT ? (
                    <>
                      {/* <i
                        className="ri-walk-fill ri-xl"
                        style={{
                          width: "18px",
                          height: "18px",
                          color: "#29bf91",
                          marginRight: "5px"
                        }}
                      /> */}
                      {"In-Clinic"}
                    </>
                  ) : (
                    <>
                      {/* <i
                      className="ri-video-chat-line ri-xl"
                      style={{
                        width: "16px",
                        height: "16px",
                        color: "#004C70",
                        marginRight: "5px"
                      }}
                    /> */}
                      {"Tele-Consultation"}
                    </>
                  )}
                </Box>
                {/* <Box 
                  component={"span"}
                  sx={{
                    fontSize: {md: "12px", lg: "13px", xl: "14px"}
                  }}>
                  &nbsp;<strong>{"|"}</strong>&nbsp;
                </Box>
                <Box 
                  component={"span"}
                  sx={{
                    fontSize: {md: "12px", lg: "13px", xl: "14px"}
                  }}>
                  {dayjs(item?.appointmentDate).format(DATE_FORMAT) === dayjs().format(DATE_FORMAT)
                    ? "Today"
                    : dayjs(item?.appointmentDate).format(DATE_FORMAT) ===
                      dayjs().add(1, "day").format(DATE_FORMAT)
                    ? "Tomorrow"
                    : dayjs(item?.appointmentDate).format("Do MMM")}
                </Box> */}
                <Box
                  component={"span"}
                  sx={{
                    fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  }}
                >
                  &nbsp;<strong>{"|"}</strong>&nbsp;
                </Box>
                <Box
                  component={"span"}
                  sx={{
                    fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  }}
                >
                  {convertTimeForTimeFormat(item?.appointmentTime)}
                </Box>

                {/* <Grid >
                  {item?.appointmentStatus}
                </Grid> */}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
          <>
            {(item?.patientAbhaId || item?.abhaId) && (
              <CustomButton
                iconButton={<img height="25px" src="/images/abha_logo.svg" alt="abha_logo"></img>}
                onClick={(event) => {}}
                style={{ padding: "3px 3px 6px 3px" }}
              ></CustomButton>
            )}
            <CustomButton
              iconButton={<i className="ri-more-2-fill" />}
              onClick={(event) => handleMenuOpen(event)}
              style={{ padding: "3px" }}
            ></CustomButton>
            <Menu
              elevation={1}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{ style: { borderRadius: "4px" } }}
            >
              <MenuList dense sx={{ p: 0 }}>
                {actions.map((action, actionIndex) => {
                  return (
                    <CustomMenuItem
                      key={actionIndex}
                      text={action.label}
                      icon={action.icon}
                      onClick={() => {
                        handleMenuClose();
                        action.onClick();
                      }}
                      disabled={action?.disabled}
                    />
                  );
                })}
              </MenuList>
            </Menu>
          </>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PatientListForDashboard;
