import dayjs from "dayjs";
import * as Yup from "yup";

export const discountValidationSchema = Yup.object().shape({
  description: Yup.string().required("Please provide description for the discount."),
  discountUnit: Yup.string().required("Please select discount type"),
  value: Yup.number()
    .typeError("Discount value must be a number")
    .when("discountUnit", {
      is: "PERCENTAGE",
      then: () =>
        Yup.number()
          .moreThan(0, "Discount Percentage should be more than 0")
          .max(100, "Discount Percentage should not be more than 100")
          .required("Discount value is required for percentage"),
      otherwise: () =>
        Yup.number()
          .moreThan(0, "Amount should be greater than 0")
          .required("Discount value is required for amount"),
    }),
  typeId: Yup.string().required("Please provide applicable service."),
  validFrom: Yup.date()
    .required("Please select start date.")
    .min(dayjs().startOf("day"), "Start date cannot be in the past.")
    .max(Yup.ref("validTo"), "StartDate cannot be later than end date"),
  validTo: Yup.date()
    .required("Please select end date.")
    .min(Yup.ref("validFrom"), "End date cannot be earlier than start date."),
});
