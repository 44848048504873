import {
  Add,
  ArrowBackIos,
  Build,
  CalendarToday,
  CancelOutlined,
  ClearOutlined,
  DirectionsRunOutlined,
  DomainVerification,
  DomainVerificationOutlined,
  EventAvailable,
  EventOutlined,
  FormatListBulleted,
  HealthAndSafetyOutlined,
  People,
  Stars,
  VideoChatOutlined,
  Visibility,
} from "@mui/icons-material";
import "assets/scss/DoctorHomeScreen/patient-appointment-list.scss";
// import Grid from "@mui/material/Grid2";
import { useLocation, useNavigate } from "react-router";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomFullCalendar from "ui-component/custom-components/CustomFullCalendar";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuList,
  Paper,
  Skeleton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useContext, useState } from "react";
import { useEffect } from "react";
import styled from "@emotion/styled";
import {
  APPOINTMENTS_LIST,
  CHECKED_IN,
  CLINIC_VISIT,
  COMPLETED,
  convertTimeForTimeFormat,
  currentActiveUser,
  DATE_FORMAT,
  DAY_GRID_CALENDAR,
  DOCTOR,
  hiuUrl,
  MONTH_GRID_CALENDAR,
  orgId,
  PATIENT,
  PRESCRIPTION_DONE,
  RESCHEDULED,
  SCHEDULED,
  TELE_CONSULTATION,
  TIME_FORMAT,
} from "store/constant";
import dayjs from "dayjs";
import { setSelectedAppointmentData } from "store/Slices/appointmentDataSlice";
import {
  getAppointmentById,
  getFilteredAppointments,
  updateAppointmentStatus,
} from "services/Appointments";
import { getPatientVitalByPatientId, getProfilePicture } from "services/patientService";
import { useDispatch } from "react-redux";
import { calculateAge } from "utils/calculate-age";
import CustomMenuItem from "ui-component/custom-components/CustomMenuItem";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { setPrescriptionId, setPrescriptionPadData } from "store/Slices/prescriptionPadSlice";
import PatientVitalSignModal from "views/Components/Patient/PatientProfile/PatientVitalSignModal";
import ModalUI from "ui-component/ModalUI";
import { getVitalSignTypes } from "services/EntitiesServices";
import {
  getAppointmentEvents,
  handleMonthViewMoreClick,
  updateFilteredAppointment,
} from "utils/appointment-utils";
import { getEhrSummary, getPrescriptionByAppointmentId } from "services/PrescriptionsService";
import { getBlockAvailabilities } from "services/Availability";
import {
  doesAvailabilityExist,
  doesProductServicesExist,
  doSpecializationAndExperienceExist,
  getDoctorAvailability,
  getDoctorByUserId,
} from "services/doctorService";
import { useSelector } from "react-redux";
import { getUserFeatures, getUserOrgId } from "store/Slices/userSlice";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import {
  getAppointmentsCounts,
  getCancelledAppointment,
  getCollapseList,
  getSelectedAppointmentOnDashboard,
  getTodaysAppointments,
  setAppointmentsCounts,
  setCancelledAppointment,
  setCollapseList,
  setSelectedAppointmentOnDashboard,
  setTodaysAppointments,
} from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/appointmentSlice";
import {
  getRefreshAppointmentsList,
  getSethuSirLayout,
  getTabValue,
  setIframeOpen,
  setIframeSrc,
  setRefreshAppointmntsList,
} from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/utilSlice";
import {
  getNewVitalAdded,
  setLatestVitals,
  setNewVitalAdded,
  setVitalsGraphData,
} from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/patientVitalSlice";
import { MosaicContext } from "react-mosaic-component";
import {
  getDynamicDefaultDashboard,
  getMosaicTree,
  setMosaicId,
  setMosaicTree,
} from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/mosaicSlice";
import { getEhrDataPoints, getPatientLatestVitals, vitalSignType } from "utils/ehr-data-utils";
import { sortVitalsByPriority } from "utils/sortVitalsByPriority";
import { profileDashboard } from "menu-items/dashboard";
import { SidebarUtilContext } from "layout/SidebarUtilContext";
import { ThemeProvider } from "@emotion/react";
import theme from "themes";

const PatientListForDashboardCV1 = () => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [displayTodaysAppointments, setDisplayTodaysAppointments] = useState([]);
  const { handleClick } = useContext(ToastContext);
  const dispatch = useDispatch();

  const [isCalendarView, setIsCalendarView] = useState(false);
  const tabValue = useSelector(getTabValue);

  const sethuSirLayout = useSelector(getSethuSirLayout);
  const refreshAppointmentsList = useSelector(getRefreshAppointmentsList);
  const todaysAppointments = useSelector(getTodaysAppointments);
  const cancelledAppointment = useSelector(getCancelledAppointment);
  const dynamicDefaultDashboard = useSelector(getDynamicDefaultDashboard);

  // eslint-disable-next-line
  const [doctorData, setDoctorData] = useState({});
  // eslint-disable-next-line
  const [doctorAvailability, setDoctorAvailability] = useState([]);
  const [doctorAppointments, setDoctorAppointments] = useState(null);
  const user = currentActiveUser();
  const docId = currentActiveUser()?.roleBasedId;
  const [appointmentResponse, setAppointmentResponse] = useState(null);
  const collapseList = useSelector(getCollapseList);

  const [filteredDoctorEvents, setFilteredDoctorEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);

  const [calendarView, setCalendarView] = useState(DAY_GRID_CALENDAR);
  const [viewInfo, setViewInfo] = useState(null);

  const [isNewModalOpen, setIsNewModalOpen] = useState(false);

  const doctorOrgId = useSelector(getUserOrgId);

  const [appointmentModalTitle, setAppointmentModalTitle] = useState(null);
  const [isReschedule, setIsRechedule] = useState(false);

  const appointmentsCounts = useSelector(getAppointmentsCounts);
  const selectedAppointment = useSelector(getSelectedAppointmentOnDashboard);

  const mosaicContext = useContext(MosaicContext);
  useEffect(() => {
    if (mosaicContext.mosaicId) {
      dispatch(setMosaicId(mosaicContext.mosaicId));
    }
  }, [mosaicContext.mosaicId]);

  const mosaicTree = useSelector(getMosaicTree);

  const clearSearch = () => {
    setSearchQuery("");
  };

  const CustomizedToolTip = styled(({ className, color, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme, color }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: color || "#29bf91",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      height: "2rem",
      fontSize: "1rem",
      textAlign: "center",
      color: "#fff",
      border: `0.5px solid ${color || "#29bf91"}`,
      backgroundColor: color || "#29bf91",
      display: "flex",
      alignItems: "center",
      padding: "8px",
    },
  }));

  const handleMonthViewMore = async (eventInfo) => {
    await handleMonthViewMoreClick(eventInfo, doctorAppointments);
  };

  useEffect(() => {
    const fetchTodaysAppointment = async () => {
      // if(doctorAppointments){
      if (doctorAppointments?.length > 0 && tabValue?.value === "0" && !isCalendarView) {
        const currentTime = dayjs().format(TIME_FORMAT);

        let TodaysAppointments = doctorAppointments
          .filter((appointment) =>
            [SCHEDULED, RESCHEDULED, CHECKED_IN].includes(appointment.appointmentStatus)
          )
          .filter((appointment) => dayjs(appointment.appointmentDate).isSame(dayjs(), "day"));

        if (TodaysAppointments.length > 0) {
          TodaysAppointments.sort((a, b) => {
            // First, sort by date (day)
            const dateComparison = dayjs(a.appointmentDate).diff(dayjs(b.appointmentDate), "day");
            if (dateComparison !== 0) {
              return dateComparison; // If dates are different, return the result
            }

            // If the dates are the same, sort by time
            return a.appointmentTime.localeCompare(b.appointmentTime);
          });

          TodaysAppointments.sort((a, b) => {
            if (a.patientQueueNo > 0 && b.patientQueueNo > 0) {
              return a.patientQueueNo - b.patientQueueNo; // Sort in ascending order of patientQueueNo
            }

            // Place items with patientQueueNo of zero at the end
            if (a.patientQueueNo === 0 && b.patientQueueNo > 0) {
              return 1; // Move `a` after `b` when `a` has a queue number of zero
            }

            if (a.patientQueueNo > 0 && b.patientQueueNo === 0) {
              return -1; // Move `b` after `a` when `b` has a queue number of zero
            }

            return 0;
          });

          const upcomingAppointments = TodaysAppointments.filter(
            (appointment) => appointment.appointmentTime > currentTime
          );
          if (
            cancelledAppointment?.id === selectedAppointment?.value?.id ||
            !selectedAppointment?.value
          ) {
            const selectedAppointment =
              upcomingAppointments.length > 0
                ? upcomingAppointments[0]
                : TodaysAppointments[TodaysAppointments.length - 1];
            try {
              const appointment = await getAppointmentById(selectedAppointment.id);
              dispatch(setSelectedAppointmentOnDashboard(appointment.data));
            } catch (error) {
              console.error("Error fetching appointment");
            }
          }
        } else {
          dispatch(setSelectedAppointmentOnDashboard(null));
        }
        dispatch(setTodaysAppointments(TodaysAppointments));
      } else {
        dispatch(setTodaysAppointments([]));
        if (!selectedAppointment?.value) {
          dispatch(setSelectedAppointmentOnDashboard(null));
        }
      }
      // }
    };

    fetchTodaysAppointment();
  }, [doctorAppointments, tabValue]);

  useEffect(() => {
    if (refreshAppointmentsList?.value) {
      parentAppointmentsRefresh(doctorData);
    }
  }, [refreshAppointmentsList]);

  const handleNewModalOpenForBookingAppointment = () => {
    const appointment = {
      doctorId: docId,
      orgId: doctorOrgId,
      appointmentDate: dayjs().format(DATE_FORMAT),
    };
    dispatch(setSelectedAppointmentData(appointment));
    setAppointmentModalTitle("Book Appointment");
    setIsNewModalOpen(true);
  };

  const handleFilteredAppointmentsChange = async (appointments, info, appointmentResponse) => {
    try {
      await fetchAndFilterAppointments(doctorData?.id, doctorOrgId, appointmentResponse, info);
      const sortedAppointments = [...appointments].sort(
        (a, b) => dayjs(a.start).valueOf() - dayjs(b.start).valueOf()
      );

      if (isCalendarView) {
        if (sortedAppointments.length > 0) {
          const updatedEvents = appointments.map((item) => {
            if (!selectedAppointment?.value) {
              if (item.appointmentId === sortedAppointments[0].appointmentId) {
                return { ...item, eventSelected: true };
              } else {
                return { ...item, eventSelected: false };
              }
            } else {
              if (item.appointmentId === selectedAppointment.value?.id) {
                return { ...item, eventSelected: true };
              } else {
                return { ...item, eventSelected: false };
              }
            }
          });
          setFilteredDoctorEvents(updatedEvents);
        }

        if (!selectedAppointment?.value && info.view.type !== MONTH_GRID_CALENDAR) {
          if (sortedAppointments.length > 0) {
            const appointmentDetails = await getAppointmentById(
              sortedAppointments[0].appointmentId
            );
            dispatch(setSelectedAppointmentOnDashboard(appointmentDetails.data));
          } else if (sortedAppointments.length === 0) {
            dispatch(setSelectedAppointmentOnDashboard(null));
          }
        }
      }
    } catch (error) {
      console.error("Error fetching appointment");
    }
  };

  const handlePrescriptionClick = async (appointmentId) => {
    try {
      const response = await getPrescriptionByAppointmentId(appointmentId);
      const prescriptionId = response?.data?.id;

      const diagnosisArr = [
        ...response?.data?.prescriptionDiagnosisResponses?.map((diagnosis) => {
          return {
            ...diagnosis,
            displayName: diagnosis?.diagnosisResponse?.name,
            category: "DX",
          };
        }),
      ];
      const symptomsArr = [
        ...response?.data?.prescriptionSymptomResponses?.map((symptom) => {
          return {
            ...symptom,
            displayName: symptom?.symptomResponse?.name,
            category: "CC",
          };
        }),
      ];

      const medicationsArr = [
        ...response?.data?.prescriptionMedicationResponses?.map((medication) => {
          return {
            ...medication,
            displayName: medication?.medicationResponse?.name,
            category: "RX",
          };
        }),
      ];
      const labInvestigationsArr = [
        ...response?.data?.prescriptionLabReportResponses?.map((labTest) => {
          return {
            ...labTest,
            displayName: labTest?.labTestResponse?.name,
            category: "LAB",
          };
        }),
      ];

      const advice = response?.data?.additionalRecommendations;
      let advicesArr = [];
      if (advice !== "") {
        advicesArr = response?.data?.additionalRecommendations.split(",").map((advice) => {
          return {
            category: "ADV",
            displayName: advice,
          };
        });
      }
      dispatch(setPrescriptionId(prescriptionId));
      dispatch(
        setPrescriptionPadData({
          diagnosisArr,
          symptomsArr,
          medicationsArr,
          labInvestigationsArr,
          advicesArr,
          soapNotes: response?.data?.soapNotes ?? {},
        })
      );
      navigate(`/home/prescriptionPad/${appointmentId}`, {
        state: {
          isEditable: true,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleNewModalOpenForReschedulingAppointment = (selectedAppointment) => {
    const appointment = {
      id: selectedAppointment?.id,
      patientMobile:
        selectedAppointment?.patient?.user?.mobileNumber ||
        selectedAppointment?.patientMobileNumber,
      patientName: selectedAppointment?.patient?.user?.name || selectedAppointment?.patientName,
      orgId: selectedAppointment?.orgId,
      appointmentTime: selectedAppointment?.appointmentTime.slice(0, -3),
      patientId: selectedAppointment?.patientId,
      doctorId: selectedAppointment?.doctorId,
      appointmentDate: selectedAppointment?.appointmentDate,
      notes: selectedAppointment?.notes,
      type: selectedAppointment?.type,
    };

    dispatch(setSelectedAppointmentData(appointment));
    setIsRechedule(true);
    setAppointmentModalTitle("Reschedule Appointment");
    setIsNewModalOpen(true);
  };

  const handleNewModalClose = () => {
    setIsNewModalOpen(false);
    parentAppointmentsRefresh(doctorData);
    dispatch(setSelectedAppointmentData({}));
  };

  const [doctorBlockCalendar, setDoctorBlockCalendar] = useState([]);
  const [doctorBlockCalendarEvents, setDoctorBlockCalendarEvents] = useState([]);

  const formatDateTime = (date, time) => {
    return `${date}T${time}`;
  };

  const blockEventsByTime = (blockedEvents, startTimeTobeChecked, endTimeTobeChecked) => {
    while (startTimeTobeChecked <= endTimeTobeChecked) {
      const endDateTime30Min = dayjs(startTimeTobeChecked).add(30, "minute");
      blockedEvents.push({
        start: dayjs(startTimeTobeChecked),
        end: dayjs(endDateTime30Min),
        appointmentType: "Blocked",
        display: "background",
      });
      startTimeTobeChecked = `${endDateTime30Min.format(DATE_FORMAT)}T${endDateTime30Min.format(
        TIME_FORMAT
      )}`;
    }
    return blockedEvents;
  };

  const createBlockCalendarEvents = (blockedDetails) => {
    let blockedEvents = [];
    blockedDetails.forEach((blocked) => {
      let startDateTime = formatDateTime(blocked.startDate, blocked.startTime);
      const endDateTime = formatDateTime(blocked.endDate, blocked.endTime);
      if (blocked.blockingType === "CUSTOM_DATE_TIME_RANGE") {
        let startDay = dayjs(blocked.startDate).date();
        let endDay = dayjs(blocked.endDate).date();
        let dateToBeBlocked = blocked.startDate; // by default start date
        for (let index = startDay; index <= endDay; index++) {
          let startTimeForADay = formatDateTime(dateToBeBlocked, blocked.startTime);
          let endDateTimeForADay = formatDateTime(dateToBeBlocked, blocked.endTime);
          blockedEvents = blockEventsByTime(blockedEvents, startTimeForADay, endDateTimeForADay);
          dateToBeBlocked = dayjs(dateToBeBlocked).add(1, "d").format(DATE_FORMAT);
        }
      } else {
        blockedEvents = blockEventsByTime(blockedEvents, startDateTime, endDateTime);
      }
    });
    return blockedEvents;
  };

  const getBlockCalendar = async () => {
    try {
      const response = await getBlockAvailabilities(user?.roleBasedId, doctorOrgId);
      const doctorBlockedDetails = response.data.filter(
        (entry) =>
          entry.blockingType !== "SINGLE_DATE" || entry.blockingType !== "CUSTOM_DATE_TIME_RANGE"
      );
      setDoctorBlockCalendar(doctorBlockedDetails);
      const events = createBlockCalendarEvents(doctorBlockedDetails);
      setDoctorBlockCalendarEvents(events);
    } catch (error) {
      if (error.response?.status === 404) {
      } else {
        console.error("Error fetching block calendar details");
      }
    }
  };

  useEffect(() => {
    getBlockCalendar();
  }, []);

  const handleGetAppointmentEvents = async (doctorDetail) => {
    try {
      const appointmentResponse = await getFilteredAppointments({
        doctorId: docId,
        orgId: doctorOrgId,
      });
      setAppointmentResponse(appointmentResponse);
      const appointmentInfoData = await getAppointmentEvents(
        doctorDetail,
        doctorOrgId,
        appointmentResponse
      );
      setDoctorAppointments([...appointmentInfoData.doctorAppointments]);
      setAllEvents([...appointmentInfoData.allEvents]);
      return appointmentResponse;
    } catch (error) {
      console.error("Error fetching appointments");
    }
  };

  const parentAppointmentsRefresh = async (doctorData) => {
    if (location.pathname === "/home/dashboard") {
      try {
        const appointmentResponse = await handleGetAppointmentEvents(doctorData);

        const filteredAppointments = updateFilteredAppointment(allEvents, viewInfo, calendarView);
        setFilteredDoctorEvents(filteredAppointments);
        if (isCalendarView) {
          handleFilteredAppointmentsChange(filteredAppointments, viewInfo, appointmentResponse);
        } else {
          await fetchAndFilterAppointments(
            doctorData?.id,
            doctorOrgId,
            appointmentResponse,
            viewInfo
          );
        }
      } catch (error) {
        console.error("Error fethcing refreshed appointments");
      }
    }
  };

  const fetchAndFilterAppointments = async (doctorId, doctorOrgId, appointmentResponse, info) => {
    try {
      if (!appointmentResponse) {
        appointmentResponse = await getFilteredAppointments({
          doctorId: docId,
          orgId: doctorOrgId,
        });
      }

      const allowedStatuses = [SCHEDULED, RESCHEDULED, CHECKED_IN, PRESCRIPTION_DONE, COMPLETED];
      const allAppointments = appointmentResponse?.data?.content
        ?.filter((app) => allowedStatuses.includes(app.appointmentStatus))
        .filter((app) => app.appointmentDate === dayjs().format(DATE_FORMAT));

      const offline = allAppointments?.filter(
        (app) =>
          app.type === CLINIC_VISIT &&
          [SCHEDULED, RESCHEDULED, CHECKED_IN].includes(app.appointmentStatus)
      );
      const online = allAppointments?.filter(
        (app) =>
          app.type === TELE_CONSULTATION &&
          [SCHEDULED, RESCHEDULED, CHECKED_IN].includes(app.appointmentStatus)
      );
      const checkedIn = allAppointments?.filter((app) => app.appointmentStatus === CHECKED_IN);
      const prescriptionDone = allAppointments?.filter(
        (app) => app.appointmentStatus === COMPLETED || app.appointmentStatus === PRESCRIPTION_DONE
      );

      dispatch(
        setAppointmentsCounts({
          totalAppointments: allAppointments?.length.toString(),
          onlineAppointments: online?.length.toString(),
          offlineAppointments: offline?.length.toString(),
          checkedIn: checkedIn?.length.toString(),
          prescriptionDone: prescriptionDone,
        })
      );
    } catch (error) {
      console.error("Error filtering appointments", error);
      return null;
    }
  };

  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      try {
        const docResponse = await getDoctorByUserId(user.id);
        if (docResponse?.data) {
          const docId = docResponse?.data.id;
          setDoctorData(docResponse.data);

          const response = await getDoctorAvailability(docId, doctorOrgId);
          setDoctorAvailability(response);

          parentAppointmentsRefresh(docResponse.data);

          intervalId = setInterval(() => {
            parentAppointmentsRefresh(docResponse.data);
          }, 300000);
        }
      } catch (error) {
        console.error("Error fetching details");
      }
    };
    fetchData();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const eventClick = async (eventInfo) => {
    if (eventInfo.event.extendedProps.appointmentType === "Blocked") {
      return;
    }
    try {
      if (eventInfo.event.extendedProps.patientId !== selectedAppointment?.value?.patientId) {
        dispatch(setLatestVitals(null));
        dispatch(setVitalsGraphData(null));
      }
      const appointmentResponse = await getAppointmentById(
        eventInfo.event.extendedProps.appointmentId
      );
      dispatch(setSelectedAppointmentOnDashboard(appointmentResponse.data));

      const updatedEvents = filteredDoctorEvents.map((item) => {
        if (item.appointmentId === eventInfo.event.extendedProps.appointmentId) {
          return { ...item, eventSelected: true };
        } else {
          return { ...item, eventSelected: false };
        }
      });

      setFilteredDoctorEvents(updatedEvents);
    } catch (error) {
      console.error("Error fetching appointment detail");
    }
  };
  const location = useLocation();

  useEffect(() => {
    const DisplayTodaysAppointments = todaysAppointments?.filter((item) => {
      const lowerCaseQuery = searchQuery.toLowerCase();
      if (
        item?.patientName?.toLowerCase().includes(lowerCaseQuery) ||
        item?.patientMobileNumber?.toLowerCase().includes(lowerCaseQuery) ||
        item?.mobileNumber?.toLowerCase().includes(lowerCaseQuery)
      ) {
        return true;
      }
      return false;
    });
    setDisplayTodaysAppointments(DisplayTodaysAppointments);
  }, [searchQuery, todaysAppointments]);

  const newVitalAdded = useSelector(getNewVitalAdded);

  const fetchLatestVitals = async () => {
    if (selectedAppointment?.value?.patientId) {
      try {
        const { data: result } = await getEhrSummary(
          selectedAppointment?.value?.patientId,
          selectedAppointment?.value?.doctor?.specialization?.name
        );
        if (selectedAppointment?.value?.patientId || selectedAppointment?.value?.id) {
          const allPatientVitals = await getPatientVitalByPatientId(
            selectedAppointment?.value?.patientId || selectedAppointment?.value?.id,
            true
          );
          const ehrDataPoints = getEhrDataPoints({
            encounters: [{ vitals: allPatientVitals.data }],
          });

          let newVitalDataForGraph = ehrDataPoints.filter((d) => vitalSignType.includes(d.key));

          const vitalSignTypeListForLatestVitals = [
            ...vitalSignType.filter((item) => item !== "RESPIRATORY_RATE"),
            ...["HEIGHT", "WEIGHT", "BODY_MASS_INDEX"],
          ];

          const vitalDataForLatestVital = ehrDataPoints.filter((d) =>
            vitalSignTypeListForLatestVitals.includes(d.key)
          );
          const latestVitalsValue = getPatientLatestVitals(vitalDataForLatestVital);

          dispatch(setLatestVitals(latestVitalsValue));
          if (result?.CRITICAL_VITALS?.length > 0) {
            const sortedVitalData = sortVitalsByPriority(
              newVitalDataForGraph,
              result.CRITICAL_VITALS || []
            );
            dispatch(setVitalsGraphData(sortedVitalData));
          } else {
            dispatch(setVitalsGraphData(newVitalDataForGraph));
          }
        }
      } catch (error) {
        console.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    fetchLatestVitals();
  }, [selectedAppointment?.value?.patientId]);

  useEffect(() => {
    if (newVitalAdded.value) {
      fetchLatestVitals();
    }
  }, [newVitalAdded]);

  const updateTreeSplitPercentageForWhenWeCollapseAppointmentsList = (tree, makeListCollapse) => {
    if (tree.first === APPOINTMENTS_LIST) {
      if (tree.direction === "row") {
        const newSplitPercentage = makeListCollapse ? 5 : 30;
        const updatedTree = { ...tree, splitPercentage: newSplitPercentage };
        return { found: true, treeUpdated: true, tree: updatedTree };
      }
      return { found: true, treeUpdated: false, tree };
    } else if (tree.second === APPOINTMENTS_LIST) {
      if (tree.direction === "row") {
        const newSplitPercentage = makeListCollapse ? 95 : 70;
        const updatedTree = { ...tree, splitPercentage: newSplitPercentage };
        return { found: true, treeUpdated: true, tree: updatedTree };
      }
      return { found: true, treeUpdated: false, tree };
    }

    if (typeof tree.first === "object") {
      const updated = updateTreeSplitPercentageForWhenWeCollapseAppointmentsList(
        tree.first,
        makeListCollapse
      );
      if (updated.found) {
        return {
          found: true,
          treeUpdated: updated.treeUpdated,
          tree: { ...tree, first: updated.tree },
        };
      }
    }

    if (typeof tree.second === "object") {
      const updated = updateTreeSplitPercentageForWhenWeCollapseAppointmentsList(
        tree.second,
        makeListCollapse
      );
      return {
        found: updated.found,
        treeUpdated: updated.treeUpdated,
        tree: { ...tree, second: updated.tree },
      };
    }

    if (typeof tree.first !== "object" && typeof tree.second !== "object") {
      return { found: false, treeUpdated: false, tree };
    }
  };

  useEffect(() => {
    if (selectedAppointment?.value === null) {
      dispatch(setMosaicTree({ ...mosaicTree, second: "No Appointment Selected" }));
    } else if (selectedAppointment?.value) {
      let configJson = JSON.parse(dynamicDefaultDashboard.configJson);
      if (configJson?.mosaicTree) {
        dispatch(setMosaicTree({ ...mosaicTree, second: configJson?.mosaicTree?.second }));
      } else {
        dispatch(setMosaicTree({ ...mosaicTree, second: configJson?.second }));
      }
    }
  }, [selectedAppointment]);

  // complete profile modal on login and onboarding
  const [specialtyAndExperiencePending, setSpecialtyAndExperiencePending] = useState(true);
  const [availabilityPending, setAvailabilityPending] = useState(true);
  const [prodServciesPending, setProdServciesPending] = useState(true);
  const [checkProfileCompletedApiCalled, setCheckProfileCompletedApiCalled] = useState(true);
  const activeUser = currentActiveUser();
  const userFeatures = useSelector(getUserFeatures);
  const { toggleMainClass, setSecondarySideBarData } = useContext(SidebarUtilContext);
  const [pendingTaskPopupVisible, setPendingTaskPopupVisible] = useState(false);
  const completedDetails = useLocation()?.state?.completedDetails;

  const steps = [
    {
      label: "Set Your Speciality, Experience and Language in profile",
      icon: <Stars />,
      condition: specialtyAndExperiencePending,
      onClick: () => {
        const profileObj = profileDashboard(
          userFeatures,
          doctorOrgId,
          activeUser,
          activeUser.roleName
        );
        setSecondarySideBarData(profileObj);
        navigate("/home/doctorProfile", { state: { pendingTask: true } });
        toggleMainClass((prevClass) => "main sub-sidebar");
      },
    },
    {
      label: "Help us set your Availability Schedule",
      icon: <EventAvailable />,
      condition: availabilityPending,
      onClick: () => {
        navigate("/home/doctorAvailability", { state: { pendingTask: true } });
      },
    },
    {
      label: "Complete Product-Service Information",
      icon: <Build />,
      condition: prodServciesPending,
      onClick: () => {
        const profileObj = profileDashboard(
          userFeatures,
          doctorOrgId,
          activeUser,
          activeUser.roleName
        );
        setSecondarySideBarData(profileObj);
        navigate("/home/ProductAndServices", {
          state: { organizationId: doctorOrgId, pendingTask: true },
        });
        toggleMainClass((prevClass) => "main sub-sidebar");
      },
    },
  ];

  const handlePendingPopupClose = () => {
    setPendingTaskPopupVisible(false);
  };

  const checkForProfileSetupDetails = async () => {
    if (activeUser.roleName === DOCTOR) {
      try {
        const speAndExpBool = await doSpecializationAndExperienceExist(activeUser.roleBasedId);
        const availBool = await doesAvailabilityExist(activeUser.roleBasedId, doctorOrgId);

        const orgAssociationRecord = activeUser.userOrganizationAssociationList.filter(
          (el) => el.organizationId === doctorOrgId
        )[0];
        if (orgAssociationRecord.isAdmin) {
          const prodServBool = await doesProductServicesExist(doctorOrgId);
          setProdServciesPending(prodServBool.data);
        }
        setSpecialtyAndExperiencePending(speAndExpBool.data);
        setAvailabilityPending(availBool.data);
        setCheckProfileCompletedApiCalled(true);
      } catch (error) {}
    }
  };

  useEffect(() => {
    checkForProfileSetupDetails();
  }, []);

  useEffect(() => {
    if (!specialtyAndExperiencePending || !availabilityPending || !prodServciesPending) {
      setPendingTaskPopupVisible(true);
    } else if (specialtyAndExperiencePending && availabilityPending && prodServciesPending) {
      setPendingTaskPopupVisible(false);
    } else if (checkProfileCompletedApiCalled && completedDetails) {
      handleClick("success", "You're all set to go!!");
    }
  }, [
    specialtyAndExperiencePending,
    availabilityPending,
    prodServciesPending,
    checkProfileCompletedApiCalled,
  ]);

  return (
    <>
      <div className="mosaic-window-appointment-list" style={{ height: "100%" }}>
        {!collapseList && (
          <div className="mosaic-appointment-list">
            {sethuSirLayout?.value ? (
              <Grid className={`my-patients-header ${collapseList ? "collapsed-list" : ""}`}>
                {!collapseList && (
                  <>
                    <div
                      style={{ display: "flex", justifyContent: "space-between", gap: "0.3rem" }}
                    >
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          margin: "0",
                          color: "#004c70",
                          lineHeight: "normal",
                        }}
                      >
                        My Patients
                      </div>
                      <div
                        className="appointments-stats"
                        style={{ backgroundColor: sethuSirLayout?.value ? "transparent" : "" }}
                      >
                        {sethuSirLayout?.value ? (
                          <>
                            <CustomizedToolTip title="Checked-In">
                              <span
                                style={{
                                  verticalAlign: "middle",
                                  color: "#29bf91",
                                  fontSize: "18px",
                                  marginLeft: "5px",
                                }}
                              >
                                {appointmentsCounts?.checkedIn}
                              </span>
                            </CustomizedToolTip>
                            <CustomizedToolTip color={"#000000"} title="Total">
                              <span
                                style={{
                                  verticalAlign: "middle",
                                  color: "#000000",
                                  fontSize: "18px",
                                  marginLeft: "5px",
                                }}
                              >
                                {appointmentsCounts?.totalAppointments}
                              </span>
                            </CustomizedToolTip>
                          </>
                        ) : (
                          <>
                            <Grid className="stat-item">
                              <CustomizedToolTip title="Checked-In">
                                <CustomButton
                                  iconButton={<DomainVerificationOutlined />}
                                  label={appointmentsCounts?.checkedIn}
                                  className={"light-blue"}
                                  gap={"0px"}
                                  style={{ padding: "6px 0px", marginRight: "5px" }}
                                />
                              </CustomizedToolTip>
                              <Box
                                component={"span"}
                                sx={{
                                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                                }}
                                className={"light-blue"}
                              >
                                {appointmentsCounts?.checkedIn}
                              </Box>
                            </Grid>
                            <div className="light-blue">|</div>
                            <Grid className="stat-item">
                              <CustomizedToolTip title="In-Clinic">
                                <CustomButton
                                  iconButton={<DirectionsRunOutlined />}
                                  label={appointmentsCounts?.offlineAppointments}
                                  className={"light-blue"}
                                  gap={"0px"}
                                  style={{ padding: "6px 0px", marginRight: "5px" }}
                                />
                              </CustomizedToolTip>
                              <Box
                                component={"span"}
                                sx={{
                                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                                }}
                                className={"light-blue"}
                              >
                                {appointmentsCounts?.offlineAppointments}
                              </Box>
                            </Grid>
                            <div className="light-blue">|</div>
                            <Grid className="stat-item">
                              <CustomizedToolTip title="Tele-Consultation">
                                <CustomButton
                                  iconButton={<VideoChatOutlined />}
                                  label={appointmentsCounts?.onlineAppointments}
                                  className={"light-blue"}
                                  gap={"0px"}
                                  style={{ padding: "6px 0px", marginRight: "5px" }}
                                />
                              </CustomizedToolTip>
                              <Box
                                component={"span"}
                                sx={{
                                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                                }}
                                className={"light-blue"}
                              >
                                {appointmentsCounts?.onlineAppointments}
                              </Box>
                            </Grid>
                          </>
                        )}
                      </div>
                    </div>
                    {/* </Grid> */}
                  </>
                )}

                <div style={{ display: "flex", gap: "0.2rem" }}>
                  {!collapseList && (
                    <>
                      <Grid>
                        <Tooltip
                          className="calendar-button-for-small-width"
                          title={isCalendarView ? "Today's schedule" : "Calendar view"}
                        >
                          <CustomButton
                            className={"btn--secondary-light"}
                            iconButton={isCalendarView ? <FormatListBulleted /> : <CalendarToday />}
                            gap={"0px"}
                            onClick={() => {
                              if (isCalendarView) {
                                setViewInfo(null);
                                dispatch(setRefreshAppointmntsList(true));
                              }
                              setIsCalendarView(!isCalendarView);
                            }}
                          />
                        </Tooltip>
                        <CustomButton
                          startIcon={isCalendarView ? <FormatListBulleted /> : <CalendarToday />}
                          label={isCalendarView ? "Today's schedule" : "Calendar view"}
                          className={"btn--secondary-light calendar-button-for-big-width"}
                          gap={"0px"}
                          style={{ padding: isCalendarView ? "" : "6px 25.82px" }}
                          onClick={() => {
                            if (isCalendarView) {
                              setViewInfo(null);
                              dispatch(setRefreshAppointmntsList(true));
                            }
                            setIsCalendarView(!isCalendarView);
                          }}
                        />
                      </Grid>
                      {!isCalendarView && (
                        <div>
                          <CustomButton
                            textAndIconColor="#004c70"
                            iconButton={<ArrowBackIos fontSize="small" />}
                            onClick={() => {
                              dispatch(setCollapseList(!collapseList));
                              const updatedTree = { ...mosaicTree };
                              const updatedResponse =
                                updateTreeSplitPercentageForWhenWeCollapseAppointmentsList(
                                  updatedTree,
                                  !collapseList
                                );
                              if (updatedResponse.found && updatedResponse.treeUpdated) {
                                dispatch(setMosaicTree(updatedResponse.tree));
                              }
                            }}
                            gap="0px"
                            style={{ padding: collapseList ? undefined : "8px 0px 8px 8px" }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Grid>
            ) : (
              <Grid className={`my-patients-header ${collapseList ? "collapsed-list" : ""}`}>
                {!collapseList && (
                  <>
                    <div
                      className="appointments-stats"
                      style={{ backgroundColor: sethuSirLayout?.value ? "transparent" : "" }}
                    >
                      <Grid className="stat-item">
                        <Tooltip title="Checked-In">
                          <DomainVerificationOutlined
                            sx={{ m: "5px 5px 5px 0px", color: "#004c70" }}
                          />
                        </Tooltip>
                        <Box
                          component={"span"}
                          sx={{
                            fontSize: { md: "12px", lg: "13px", xl: "14px" },
                          }}
                          className={"light-blue"}
                        >
                          {appointmentsCounts?.checkedIn}
                        </Box>
                      </Grid>
                      <div className="light-blue">|</div>
                      <Grid className="stat-item">
                        <Tooltip title="In-Clinic">
                          <DirectionsRunOutlined sx={{ m: "5px 5px 5px 0px", color: "#004c70" }} />
                        </Tooltip>
                        <Box
                          component={"span"}
                          sx={{
                            fontSize: { md: "12px", lg: "13px", xl: "14px" },
                          }}
                          className={"light-blue"}
                        >
                          {appointmentsCounts?.offlineAppointments}
                        </Box>
                      </Grid>
                      <div className="light-blue">|</div>
                      <Grid className="stat-item">
                        <Tooltip title="Tele-Consultation">
                          <VideoChatOutlined sx={{ m: "5px 5px 5px 0px", color: "#004c70" }} />
                        </Tooltip>
                        <Box
                          component={"span"}
                          sx={{
                            fontSize: { md: "12px", lg: "13px", xl: "14px" },
                          }}
                          className={"light-blue"}
                        >
                          {appointmentsCounts?.onlineAppointments}
                        </Box>
                      </Grid>
                    </div>
                  </>
                )}

                <div style={{ display: "flex", gap: "0.2rem" }}>
                  {!collapseList && (
                    <>
                      <Grid>
                        <Tooltip
                          className="calendar-button-for-small-width"
                          title={isCalendarView ? "Today's schedule" : "Calendar view"}
                        >
                          <CustomButton
                            className={"btn--secondary-light"}
                            iconButton={isCalendarView ? <FormatListBulleted /> : <CalendarToday />}
                            gap={"0px"}
                            onClick={() => {
                              if (isCalendarView) {
                                setViewInfo(null);
                                dispatch(setRefreshAppointmntsList(true));
                              }
                              setIsCalendarView(!isCalendarView);
                            }}
                          />
                        </Tooltip>
                        <CustomButton
                          startIcon={isCalendarView ? <FormatListBulleted /> : <CalendarToday />}
                          label={isCalendarView ? "Today's schedule" : "Calendar view"}
                          className={"btn--secondary-light calendar-button-for-big-width"}
                          gap={"0px"}
                          style={{ padding: isCalendarView ? "" : "6px 25.82px" }}
                          onClick={() => {
                            if (isCalendarView) {
                              setViewInfo(null);
                              dispatch(setRefreshAppointmntsList(true));
                            }
                            setIsCalendarView(!isCalendarView);
                          }}
                        />
                      </Grid>
                      {!isCalendarView && (
                        <div>
                          <CustomButton
                            textAndIconColor="#004c70"
                            iconButton={
                              collapseList ? <MenuIcon /> : <ArrowBackIos fontSize="small" />
                            }
                            onClick={() => {
                              dispatch(setCollapseList(!collapseList));
                              const updatedTree = { ...mosaicTree };
                              const updatedResponse =
                                updateTreeSplitPercentageForWhenWeCollapseAppointmentsList(
                                  updatedTree,
                                  !collapseList
                                );
                              if (updatedResponse.found && updatedResponse.treeUpdated) {
                                dispatch(setMosaicTree(updatedResponse.tree));
                              }
                            }}
                            gap="0px"
                            style={{ padding: collapseList ? undefined : "8px 0px 8px 8px" }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Grid>
            )}

            {isCalendarView ? (
              <div className="doctor-appointments" style={{ flexGrow: 1 }}>
                <div className="left-box-new">
                  <CustomFullCalendar
                    // isExtraLargeScreenForCalendarView={isExtraLargeScreenForCalendarView}
                    appointmentResponse={appointmentResponse}
                    setCalendarView={setCalendarView}
                    viewInfo={viewInfo}
                    setViewInfo={setViewInfo}
                    calendarView={calendarView}
                    setFilteredDoctorEvents={setFilteredDoctorEvents}
                    allEvents={allEvents}
                    filteredDoctorEvents={filteredDoctorEvents}
                    eventClick={eventClick}
                    blockedDays={doctorBlockCalendar}
                    blockedEvents={doctorBlockCalendarEvents}
                    slotDuration={doctorData?.avgAppointmentDuration <= 15 ? 30 : 60}
                    onFilteredAppointmentsChange={handleFilteredAppointmentsChange}
                    handleMonthViewMore={handleMonthViewMore}
                    handlePrescriptionClick={handlePrescriptionClick}
                  ></CustomFullCalendar>
                </div>
              </div>
            ) : (
              <>
                <div
                  className="common-scrollbar common-scrollbar-x"
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    maxHeight: "100%",
                    gap: collapseList ? undefined : "0.5rem",
                  }}
                >
                  <>
                    <div>
                      <FormInputField
                        style={{ width: "100%" }}
                        label={"By Patient Name and Mobile"}
                        className="search-bar-for-small-width"
                        value={searchQuery}
                        startAdornment={<i className="ri-search-line ri-lg" />}
                        endAdornment={
                          searchQuery ? (
                            <IconButton onClick={clearSearch} edge="end">
                              {<ClearOutlined fontSize="small" />}
                            </IconButton>
                          ) : (
                            <></>
                          )
                        }
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                        size="small"
                      ></FormInputField>
                      <FormInputField
                        style={{ width: "100%" }}
                        className="search-bar-for-big-width"
                        label={"Search Patients by Name and Mobilenumber"}
                        value={searchQuery}
                        startAdornment={<i className="ri-search-line ri-lg" />}
                        endAdornment={
                          searchQuery ? (
                            <IconButton onClick={clearSearch} edge="end">
                              {<ClearOutlined fontSize="small" />}
                            </IconButton>
                          ) : (
                            <></>
                          )
                        }
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                        size="small"
                      ></FormInputField>
                    </div>
                    {sethuSirLayout?.value ? (
                      <>
                        {todaysAppointments?.length > 0 && (
                          <div
                            style={{
                              flexGrow: 1,
                              maxHeight: "100%",
                              overflowY: "auto",
                            }}
                            className="common-scrollbar common-scrollbar-x"
                          >
                            <div
                              className="common-scrollbar common-scrollbar-x"
                              style={{ width: "100%", padding: "0px 5px 0px 0px" }}
                            >
                              {displayTodaysAppointments.map((item, index) => {
                                return (
                                  <PatientAppointmentCardV2
                                    key={index}
                                    itemLength={displayTodaysAppointments.length}
                                    item={item}
                                    index={index}
                                    setAppointmentModalTitle={setAppointmentModalTitle}
                                    setIsNewModalOpen={setIsNewModalOpen}
                                    handleNewModalOpenForReschedulingAppointment={
                                      handleNewModalOpenForReschedulingAppointment
                                    }
                                    handleClick={handleClick}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {todaysAppointments?.length > 0 && (
                          <div
                            style={{
                              flexGrow: 1,
                              maxHeight: "100%",
                              overflowY: "auto",
                            }}
                            className="common-scrollbar common-scrollbar-x"
                          >
                            <div
                              className="common-scrollbar common-scrollbar-x"
                              style={{ width: "100%", padding: "0px 5px 0px 0px" }}
                            >
                              {displayTodaysAppointments
                                .filter((app) => app?.id !== selectedAppointment?.value?.id)
                                .map((item, index) => {
                                  return (
                                    <PatientAppointmentCardV2
                                      key={index}
                                      itemLength={displayTodaysAppointments.length}
                                      item={item}
                                      index={index}
                                      setAppointmentModalTitle={setAppointmentModalTitle}
                                      setIsNewModalOpen={setIsNewModalOpen}
                                      handleNewModalOpenForReschedulingAppointment={
                                        handleNewModalOpenForReschedulingAppointment
                                      }
                                      handleClick={handleClick}
                                    />
                                  );
                                })}
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    <Grid className="bottom-util-buttons">
                      <CustomButton
                        label={"Book Appointment"}
                        className={"btn--primary"}
                        gap={"0px"}
                        onClick={handleNewModalOpenForBookingAppointment}
                        style={{ marginRight: "10px" }}
                      />
                      {/* )} */}
                      <CustomButton
                        startIcon={<i className="ri-list-check-line" />}
                        label={"My Patients"}
                        className={"btn--primary-light"}
                        onClick={() => {
                          navigate("/home/patient");
                        }}
                        gap="0px"
                        style={{ padding: "6px 12px 6px 7.8px" }}
                      />
                    </Grid>
                  </>
                </div>
              </>
            )}
          </div>
        )}
        {collapseList && (
          <div className="mosaic-appointment-list-toolbar">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CustomButton
                textAndIconColor="#004c70"
                iconButton={<MenuIcon />}
                onClick={() => {
                  dispatch(setCollapseList(!collapseList));
                  const updatedTree = { ...mosaicTree };
                  const updatedResponse =
                    updateTreeSplitPercentageForWhenWeCollapseAppointmentsList(
                      updatedTree,
                      !collapseList
                    );
                  if (updatedResponse.found && updatedResponse.treeUpdated) {
                    dispatch(setMosaicTree(updatedResponse.tree));
                  }
                }}
                gap="0px"
                style={{ padding: collapseList ? undefined : "8px 0px 8px 8px" }}
              />
            </div>
            <Divider />
            <>
              <div
                className="common-scrollbar common-scrollbar-x"
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                  maxHeight: "100%",
                  gap: collapseList ? undefined : "0.5rem",
                }}
              >
                {collapseList ? (
                  <div style={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
                    <div style={{ minHeight: "70px" }}>
                      <div
                        className={`appointments-stats collapsed-list light-blue`}
                        style={{ backgroundColor: sethuSirLayout?.value ? "transparent" : "" }}
                      >
                        <Grid className={`stat-item collapsed-list`}>
                          <Tooltip title="Checked-In" placement="right">
                            <DomainVerificationOutlined />
                          </Tooltip>
                          <Box
                            component={"span"}
                            sx={{
                              fontSize: { md: "12px", lg: "13px", xl: "14px" },
                            }}
                          >
                            {appointmentsCounts?.checkedIn}
                          </Box>
                        </Grid>
                        <Divider />
                        <Grid className="stat-item collapsed-list">
                          <Tooltip title="In-Clinic" placement="right">
                            <DirectionsRunOutlined />
                          </Tooltip>
                          <Box
                            component={"span"}
                            sx={{
                              fontSize: { md: "12px", lg: "13px", xl: "14px" },
                            }}
                          >
                            {appointmentsCounts?.offlineAppointments}
                          </Box>
                        </Grid>
                        <Divider />
                        <Grid className="stat-item collapsed-list">
                          <Tooltip title="Tele-Consultation" placement="right">
                            <VideoChatOutlined />
                          </Tooltip>
                          <Box
                            component={"span"}
                            sx={{
                              fontSize: { md: "12px", lg: "13px", xl: "14px" },
                            }}
                            className={"light-blue"}
                          >
                            {appointmentsCounts?.onlineAppointments}
                          </Box>
                        </Grid>
                      </div>
                    </div>
                    <Divider />
                    <List sx={{ p: "0px !important" }}>
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <Tooltip title="Book Appointment" placement="right">
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <CustomButton
                              textAndIconColor="#485772"
                              iconButton={<Add />}
                              onClick={handleNewModalOpenForBookingAppointment}
                              gap="0px"
                            />
                          </div>
                        </Tooltip>
                      </ListItem>
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <Tooltip title="View Calendar" placement="right">
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <CustomButton
                              textAndIconColor="#485772"
                              iconButton={<CalendarToday />}
                              onClick={() => {
                                setIsCalendarView(!isCalendarView);
                                dispatch(setCollapseList(false));
                              }}
                              gap="0px"
                            />
                          </div>
                        </Tooltip>
                      </ListItem>
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <Tooltip title="My Patients" placement="right">
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <CustomButton
                              textAndIconColor="#485772"
                              iconButton={<People />}
                              onClick={() => {
                                navigate("/home/patient");
                              }}
                              gap="0px"
                            />
                          </div>
                        </Tooltip>
                      </ListItem>
                    </List>
                  </div>
                ) : (
                  <>
                    <div>
                      <FormInputField
                        style={{ width: "100%" }}
                        label={"By Patient Name and Mobile"}
                        className="search-bar-for-small-width"
                        value={searchQuery}
                        startAdornment={<i className="ri-search-line ri-lg" />}
                        endAdornment={
                          searchQuery ? (
                            <IconButton onClick={clearSearch} edge="end">
                              {<ClearOutlined fontSize="small" />}
                            </IconButton>
                          ) : (
                            <></>
                          )
                        }
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                        size="small"
                      ></FormInputField>
                      <FormInputField
                        style={{ width: "100%" }}
                        className="search-bar-for-big-width"
                        label={"Search Patients by Name and Mobilenumber"}
                        value={searchQuery}
                        startAdornment={<i className="ri-search-line ri-lg" />}
                        endAdornment={
                          searchQuery ? (
                            <IconButton onClick={clearSearch} edge="end">
                              {<ClearOutlined fontSize="small" />}
                            </IconButton>
                          ) : (
                            <></>
                          )
                        }
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                        size="small"
                      ></FormInputField>
                    </div>
                    {sethuSirLayout?.value ? (
                      <>
                        {todaysAppointments?.length > 0 && (
                          <div
                            style={{
                              flexGrow: 1,
                              maxHeight: "100%",
                              overflowY: "auto",
                            }}
                            className="common-scrollbar common-scrollbar-x"
                          >
                            <div
                              className="common-scrollbar common-scrollbar-x"
                              style={{ width: "100%", padding: "0px 5px 0px 0px" }}
                            >
                              {displayTodaysAppointments.map((item, index) => {
                                return (
                                  <PatientAppointmentCardV2
                                    key={index}
                                    itemLength={displayTodaysAppointments.length}
                                    item={item}
                                    index={index}
                                    setAppointmentModalTitle={setAppointmentModalTitle}
                                    setIsNewModalOpen={setIsNewModalOpen}
                                    handleNewModalOpenForReschedulingAppointment={
                                      handleNewModalOpenForReschedulingAppointment
                                    }
                                    handleClick={handleClick}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {todaysAppointments?.length > 1 && (
                          <div
                            style={{
                              flexGrow: 1,
                              maxHeight: "100%",
                              overflowY: "auto",
                            }}
                            className="common-scrollbar common-scrollbar-x"
                          >
                            <div
                              className="common-scrollbar common-scrollbar-x"
                              style={{ width: "100%", padding: "0px 5px 0px 0px" }}
                            >
                              {displayTodaysAppointments
                                .filter((app) => app?.id !== selectedAppointment?.value?.id)
                                .map((item, index) => {
                                  return (
                                    <PatientAppointmentCardV2
                                      key={index}
                                      itemLength={displayTodaysAppointments.length}
                                      item={item}
                                      index={index}
                                      setAppointmentModalTitle={setAppointmentModalTitle}
                                      setIsNewModalOpen={setIsNewModalOpen}
                                      handleNewModalOpenForReschedulingAppointment={
                                        handleNewModalOpenForReschedulingAppointment
                                      }
                                      handleClick={handleClick}
                                    />
                                  );
                                })}
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    <Grid className="bottom-util-buttons">
                      <CustomButton
                        label={"Book Appointment"}
                        className={"btn--primary"}
                        gap={"0px"}
                        onClick={handleNewModalOpenForBookingAppointment}
                        style={{ marginRight: "10px" }}
                      />
                      {/* )} */}
                      <CustomButton
                        startIcon={<i className="ri-list-check-line" />}
                        label={"My Patients"}
                        className={"btn--primary-light"}
                        onClick={() => {
                          navigate("/home/patient");
                        }}
                        gap="0px"
                        style={{ padding: "6px 12px 6px 7.8px" }}
                      />
                    </Grid>
                  </>
                )}
              </div>
            </>
          </div>
        )}
        {!collapseList && (
          <div className="view-for-small-container">Too small to display contents</div>
        )}
      </div>
      <ModalUI
        visible={isNewModalOpen}
        close={() => {
          dispatch(setSelectedAppointmentData({}));
          setIsNewModalOpen(false);
        }}
        title={appointmentModalTitle}
        style={{
          overflowY: "auto",
          height: "550px",
          width: "610px",
        }}
        component={
          <BookAppointmentModal
            reschedule={isReschedule}
            handleClick={handleClick}
            closeModal={handleNewModalClose}
          />
        }
      />
      {pendingTaskPopupVisible && (
        <ModalUI
          visible={pendingTaskPopupVisible}
          close={handlePendingPopupClose}
          title={"Complete below profile details"}
          style={{
            overflowY: "auto",
          }}
          component={
            <div>
              <PendingDetailsPopupComponent steps={steps} activeUser={activeUser} />
            </div>
          }
        />
      )}
    </>
  );
};

const PatientAppointmentCardV2 = ({
  item,
  index,
  itemsLength,
  setAppointmentModalTitle,
  setIsNewModalOpen,
  handleNewModalOpenForReschedulingAppointment,
  handleClick,
}) => {
  const [profileImageFileWithContent, setProfileImageFileWithContent] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const selectedAppointment = useSelector(getSelectedAppointmentOnDashboard)?.value;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [vitalModalOpen, setVitalModalOpen] = useState(false);
  const [vitalSignTypes, setVitalSignTypes] = useState([]);

  const closeModal = () => {
    setVitalModalOpen(false);
  };

  useEffect(() => {
    const fetchProfilePic = async () => {
      setIsLoading(true);
      try {
        const profilePicResponse = await getProfilePicture(item?.patientUserId || item?.userId);
        const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;
        const profilePicWithContent = {
          filename: profilePicResponse.data.filename,
          content: content,
        };
        setProfileImageFileWithContent(profilePicWithContent);
      } catch (error) {
        setProfileImageFileWithContent(null);
        console.error("Error fetching profile picture", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProfilePic();
  }, [item?.patientUserId, item?.userId]);

  const handleBookAppointment = () => {
    const appointment = {
      patientMobile: item.mobileNumber,
      isUhiDoctor: false,
      orgId: orgId(),
      appointmentDate: dayjs().format(DATE_FORMAT),
      appointmentTime: null,
      patientId: item?.id,
      doctorId: currentActiveUser().roleBasedId,
      notes: "",
      type: null,
    };
    dispatch(setSelectedAppointmentData(appointment));
    setAppointmentModalTitle("Book Appointment");
    setIsNewModalOpen(true);
  };
  const navigate = useNavigate();

  // dialog box
  const [openDialogForCancelAppointment, setOpenDialogForCancelAppointment] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialogForCancelAppointment(true);
  };

  const handleDialogYes = async () => {
    setOpenDialogForCancelAppointment(false);
    // delete appointment, and fetch current appointments as events
    // todo : currently all appointments are shown, need to filter based on active/inactive once dto changed
    try {
      await updateAppointmentStatus(item.id, "CANCELLED");
      handleClick("success", "Appointment cancelled successfully!");
      dispatch(setCancelledAppointment(item));
      // dispatch(setAppointmentSelectedOnFirstLoad(false));
      dispatch(setRefreshAppointmntsList(true));
    } catch (error) {
      handleClick("error", "There seems to be an error deleting the appointment");
    }
  };

  const handleDialogNo = () => {
    setOpenDialogForCancelAppointment(false);
  };

  const handleCancelAppointment = () => {
    handleDialogOpen();
  };

  const actions = !item?.patientId
    ? [
        {
          label: "Book Appointment",
          icon: <i className="ri-contract-line ri-xl icon-primary-blue" />,
          onClick: handleBookAppointment,
        },
      ]
    : [
        {
          label: "Reschedule",
          icon: <EventOutlined style={{ color: "#004c70" }} />,
          onClick: () => {
            handleNewModalOpenForReschedulingAppointment(item);
          },
        },
        {
          label: "Check-In",
          icon: <DomainVerification style={{ color: "#29bf91" }} />,
          onClick: async () => {
            try {
              await updateAppointmentStatus(item?.id, CHECKED_IN);
              handleClick("success", "Patient Checked-in successfully!");
              item.appointmentStatus = CHECKED_IN;
              dispatch(setRefreshAppointmntsList(true));
            } catch {
              handleClick("error", "There seems to be an error in making patient checked-in");
            }
          },
          disabled: item?.appointmentStatus !== CHECKED_IN ? false : true,
        },
        {
          label: "View EHR",
          icon: <Visibility style={{ color: "#004c70" }} />,
          onClick: () => {
            navigate(`/home/ehr/${item?.patientId}`);
          },
        },
        {
          label: "Add Vital",
          icon: <HealthAndSafetyOutlined style={{ color: "#29bf91" }} />,
          onClick: () => {
            const fetchVitalSignTypes = async () => {
              try {
                const response = await getVitalSignTypes();
                setVitalSignTypes(response.data);
              } catch (error) {
                console.error("Error fetching Vital sign types");
              }
            };
            fetchVitalSignTypes();
            setVitalModalOpen(true);
          },
        },
        {
          label: "Fetch Abha Records",
          icon: <img height="20px" src="/images/abha_logo.svg" alt="abha_logo"></img>,
          onClick: () => {
            dispatch(setIframeOpen(true));
            dispatch(setIframeSrc(hiuUrl(item?.doctorId, item?.abhaId)));
          },
          disabled: item?.patientAbhaId ? false : true,
        },
        {
          label: "Cancel",
          icon: <CancelOutlined style={{ color: "#f5574c" }} />,
          onClick: () => {
            handleCancelAppointment();
          },
        },
      ];

  return (
    <>
      <Paper
        elevation={selectedAppointment && item.id === selectedAppointment.id ? 3 : 0}
        sx={{
          border:
            selectedAppointment && item.id === selectedAppointment.id
              ? ""
              : "0.5px solid lightgray",
          ...(item?.appointmentStatus === CHECKED_IN
            ? {
                border: "1px solid #29bf91",
              }
            : {}),
          borderRadius: "12px",
          p: "10px",
          mb: `${index === itemsLength - 1 ? 0 : 10}px`,
          backgroundColor:
            selectedAppointment && (item.id === selectedAppointment.id ? "#e6eef1" : "#ffffff"),
        }}
      >
        <Grid
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            borderRadius: "25px",
            alignItems: "center",
          }}
        >
          <Grid
            sx={{
              cursor: "pointer",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              width: "90%",
            }}
            onClick={async () => {
              try {
                if (item?.patientId !== selectedAppointment?.patientId) {
                  dispatch(setLatestVitals(null));
                  dispatch(setVitalsGraphData(null));
                }
                if (!item?.patientId) {
                  dispatch(setSelectedAppointmentOnDashboard(item));
                } else {
                  const appointment = await getAppointmentById(item.id);
                  dispatch(setSelectedAppointmentOnDashboard(appointment.data));
                }
                dispatch(setNewVitalAdded(false));
              } catch (error) {
                console.error("Error fetching appointment");
              }
            }}
          >
            <Grid sx={{ width: "45px" }} display={"flex"} alignItems={"center"}>
              {isLoading ? (
                <Skeleton variant="circular" animation="wave" width={"45px"} height={"45px"} />
              ) : (
                <img
                  style={{
                    borderRadius: "50%",
                    width: "45px",
                    height: "45px",
                  }}
                  className="patient-avatar"
                  src={
                    profileImageFileWithContent
                      ? profileImageFileWithContent.content
                      : item?.genedr === "FEMALE"
                      ? "/images/woman.png"
                      : "/images/man.png"
                  }
                  alt=""
                ></img>
              )}
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  mb: "0px !important",
                }}
              >
                {item?.patientName && (
                  <Tooltip title={item?.patientName}>
                    <Box
                      component={"span"}
                      className="appointment-card-patient-name"
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      }}
                    >
                      {item?.patientName}
                    </Box>
                  </Tooltip>
                )}
                <>
                  {item?.patientName && item?.dateOfBirth && (
                    <Box
                      component={"span"}
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      }}
                    >
                      &nbsp;<strong>{"|"}</strong>&nbsp;
                    </Box>
                  )}
                  {item?.dateOfBirth && (
                    <Box
                      component={"span"}
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      }}
                    >{`${calculateAge(item?.dateOfBirth)}y`}</Box>
                  )}

                  {((item?.dateOfBirth && item?.gender) || (item?.patientName && item?.gender)) && (
                    <Box
                      component={"span"}
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      }}
                    >
                      &nbsp;<strong>{"|"}</strong>&nbsp;
                    </Box>
                  )}
                  {item?.gender && (
                    <Box
                      component={"span"}
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      }}
                    >
                      {item?.gender.charAt(0)}
                    </Box>
                  )}
                </>
              </Grid>
              {item?.appointmentTime && item?.type && item.appointmentStatus && (
                <Grid
                  sx={{
                    width: "100%",
                    display: "flex",
                    mt: "7px",
                  }}
                >
                  <Tooltip title={item?.type === CLINIC_VISIT ? "In-Clinic" : "Tele-Consultation"}>
                    <Box
                      component={"span"}
                      className="appointment-card-appointment-type"
                      sx={{
                        fontSize: { md: "12px", lg: "13px", xl: "14px" },
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item?.type === CLINIC_VISIT ? (
                        <>{"In-Clinic"}</>
                      ) : (
                        <>{"Tele-Consultation"}</>
                      )}
                    </Box>
                  </Tooltip>
                  <Box
                    component={"span"}
                    sx={{
                      fontSize: { md: "12px", lg: "13px", xl: "14px" },
                    }}
                  >
                    &nbsp;<strong>{"|"}</strong>&nbsp;
                  </Box>
                  <Box
                    component={"span"}
                    sx={{
                      fontSize: { md: "12px", lg: "13px", xl: "14px" },
                      whiteSpace: "nowrap",
                    }}
                  >
                    {convertTimeForTimeFormat(item?.appointmentTime)}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            <>
              {item?.patientAbhaId && (
                <Tooltip title={"Abha linked"}>
                  <CustomButton
                    iconButton={
                      <img height="25px" src="/images/abha_logo.svg" alt="abha_logo"></img>
                    }
                    onClick={(event) => {}}
                    style={{ padding: "3px 3px 6px 3px" }}
                  ></CustomButton>
                </Tooltip>
              )}
              <CustomButton
                iconButton={<i className="ri-more-2-fill" />}
                onClick={(event) => handleMenuOpen(event)}
                style={{ padding: "3px" }}
              ></CustomButton>
              <Menu
                elevation={1}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperProps={{ style: { borderRadius: "4px" } }}
              >
                <MenuList dense sx={{ p: 0 }}>
                  {actions.map((action, actionIndex) => {
                    return (
                      <CustomMenuItem
                        key={actionIndex}
                        text={action.label}
                        icon={action.icon}
                        onClick={() => {
                          handleMenuClose();
                          action.onClick();
                        }}
                        disabled={action?.disabled}
                      />
                    );
                  })}
                </MenuList>
              </Menu>
            </>
          </Grid>
        </Grid>
      </Paper>
      <ModalUI
        visible={vitalModalOpen}
        close={closeModal}
        title={"Add Patient Vital Sign"}
        component={
          <>
            <PatientVitalSignModal
              selected={null}
              close={(operation) => {
                if (operation === "save") {
                  if (item?.id === selectedAppointment?.id) {
                    dispatch(setNewVitalAdded(true));
                  }
                  closeModal();
                } else {
                  closeModal();
                }
              }}
              patientId={item?.patientId}
              vitalSignTypes={vitalSignTypes}
            ></PatientVitalSignModal>
          </>
        }
      ></ModalUI>
      <Dialog
        open={openDialogForCancelAppointment}
        onClose={handleDialogNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ padding: "16px 20px !important" }} id="alert-dialog-title" fontSize={16}>
          Are you sure?
        </DialogTitle>
        <DialogContent sx={{ padding: "0px 20px 10px 20px !important" }}>
          <DialogContentText id="alert-dialog-description">
            Appointment will be cancelled and patient will be notified.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "#004c70", fontWeight: 600 }} onClick={handleDialogNo}>
            No
          </Button>
          <Button style={{ color: "#f5574c", fontWeight: 600 }} onClick={handleDialogYes}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const PendingDetailsPopupComponent = ({
  steps,
  isAbhaNumberExist,
  handleSkipForNow,
  activeUser,
}) => {
  const navigate = useNavigate();
  const customization = {
    colors: {
      primaryMain: "#29bf91",
    },
    ...useSelector((state) => state.customization),
  };

  const sortedSteps = steps.sort((a, b) =>
    a.condition === b.condition ? 0 : a.condition ? -1 : 1
  );
  const activeStep = sortedSteps.findIndex((el) => !el.condition);

  return (
    <ThemeProvider theme={theme(customization)}>
      <Grid sx={{ mt: 2 }}>
        <Stepper orientation="vertical" activeStep={activeStep}>
          {sortedSteps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                sx={{
                  "& .MuiStepIcon-root": {
                    display: sortedSteps.length === 1 ? "none" : "flex",
                  },
                  "& .MuiStepIcon-text": {
                    fill: "#ffffff",
                  },
                }}
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <CustomButton
                      label={"Continue"}
                      className={"btn--secondary"}
                      onClick={step.onClick}
                    />
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}

          {activeUser?.roleName === PATIENT && !isAbhaNumberExist && (
            <div style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
              <CustomButton
                label={"Create/link"}
                className={"btn--secondary"}
                onClick={() =>
                  navigate("/home/manageAbhaNumber", {
                    state: {
                      abhaCreationPending: true,
                    },
                  })
                }
              ></CustomButton>

              <CustomButton
                className={"btn--secondary"}
                label={"Skip For Now"}
                onClick={handleSkipForNow}
              />
            </div>
          )}
        </Stepper>
      </Grid>
    </ThemeProvider>
  );
};

export default PatientListForDashboardCV1;
