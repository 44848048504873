export const medicalAbbreviationsList = [
  { abbr: "ac", fullName: "before meals" },
  { abbr: "ad", fullName: "right ear" },
  { abbr: "ad lib", fullName: "use as much as desired" },
  { abbr: "ai", fullName: "left ear" },
  { abbr: "as", fullName: "left ear" },
  { abbr: "au", fullName: "both ears" },
  { abbr: "bid", fullName: "twice a day" },
  { abbr: "cap", fullName: "capsule" },
  { abbr: "cf", fullName: "with food" },
  { abbr: "daw", fullName: "dispense as written" },
  { abbr: "dieb alt", fullName: "every other day" },
  { abbr: "emp", fullName: "as directed" },
  { abbr: "g", fullName: "gram" },
  { abbr: "gr", fullName: "grain" },
  { abbr: "gtt(s)", fullName: "drop(s)" },
  { abbr: "IM", fullName: "intramuscular" },
  { abbr: "IV", fullName: "intravenous" },
  { abbr: "mdu", fullName: "to be used as directed" },
  { abbr: "od", fullName: "right eye" },
  { abbr: "os", fullName: "left eye" },
  { abbr: "ou", fullName: "both eyes" },
  { abbr: "pc", fullName: "after meals" },
  { abbr: "po", fullName: "by mouth" },
  { abbr: "pr", fullName: "by rectum" },
  { abbr: "prn", fullName: "as needed" },
  { abbr: "qad", fullName: "every other day" },
  { abbr: "qd", fullName: "every day" },
  { abbr: "qh", fullName: "every hour" },
  { abbr: "qhs", fullName: "every night at bedtime" },
  { abbr: "q3h", fullName: "every three hours" },
  { abbr: "qid", fullName: "four times a day" },
  { abbr: "qwk", fullName: "every week" },
  { abbr: "sc", fullName: "subcutaneous" },
  { abbr: "subc", fullName: "subcutaneous" },
  { abbr: "subcut", fullName: "subcutaneous" },
  { abbr: "subq", fullName: "subcutaneous" },
  { abbr: "sq", fullName: "subcutaneous" },
  { abbr: "sig", fullName: "write" },
  { abbr: "tab", fullName: "tablet" },
  { abbr: "tbsp", fullName: "tablespoon" },
  { abbr: "tsp", fullName: "teaspoon" },
  { abbr: "tid", fullName: "three times a day" },
  { abbr: "top", fullName: "topical" },
  { abbr: "ud", fullName: "as directed" },
  { abbr: "ut dict", fullName: "as directed" },
  { abbr: "w", fullName: "with" },
  { abbr: "w/o", fullName: "without" },
  { abbr: "x", fullName: "times" },
];

export const symbols = [
  { displayName: "!", category: "DX", readOnly: true },
  { displayName: "@", category: "CC", readOnly: true },
  { displayName: "#", category: "RX", readOnly: true },
  { displayName: "$", category: "LAB", readOnly: true },
];

export const categoryAbbr = [
  { displayName: "DX", category: "CAT" },
  { displayName: "CC", category: "CAT" },
  { displayName: "RX", category: "CAT" },
  { displayName: "ADV", category: "CAT" },
  { displayName: "LAB", category: "CAT" },
];
