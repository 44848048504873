import { Outlet, useNavigate } from "react-router-dom";

// project imports
// import Customization from "../Customization";
import { useEffect } from "react";
import { isDemo, loggedIn } from "store/constant";
// import Customization from "layout/Customization";

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      loggedIn() &&
      !isDemo() &&
      !["/invitation", "/subscription"].includes(window.location.pathname)
    ) {
      navigate("/home/dashboard");
    }
  });

  return (
    <>
      <Outlet />
      {/* <Customization /> */}
    </>
  );
};

export default MinimalLayout;
