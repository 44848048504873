import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { DATE_TIME_FORMAT } from "store/constant";

const initialState = {
  vitalsGraphData: null,
  latestVitals: null,
  newVitalAdded: {
    value: false,
    timestamp: dayjs().format(DATE_TIME_FORMAT),
  },
};

const patientVitalSlice = createSlice({
  name: "patientVitals",
  initialState,
  reducers: {
    setVitalsGraphData: (state, action) => {
      state.vitalsGraphData = action.payload;
    },
    setLatestVitals: (state, action) => {
      state.latestVitals = action.payload;
    },
    setNewVitalAdded: (state, action) => {
      state.newVitalAdded = {
        value: action.payload,
        timestamp: dayjs().format(DATE_TIME_FORMAT),
      };
    },
  },
});

export const { setVitalsGraphData, setLatestVitals, setNewVitalAdded } = patientVitalSlice.actions;

export const getVitalsGraphData = (state) => state.doctorDashboard.patientVitals.vitalsGraphData;
export const getLatestVitals = (state) => state.doctorDashboard.patientVitals.latestVitals;
export const getNewVitalAdded = (state) => state.doctorDashboard.patientVitals.newVitalAdded;

export const PatientVitalsReducer = patientVitalSlice.reducer;
