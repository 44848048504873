import * as React from "react";
import { useState, useEffect } from "react";
import { Card, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useLocation } from "react-router";
import CustomButton from "ui-component/custom-components/CustomButton";
import { PrintOutlined } from "@mui/icons-material";
import { ACTIVE_STATE, getUUID, INACTIVE_STATE, ORGANIZATION } from "store/constant";
import { useContext } from "react";
import {
  generateBillingInvoices,
  getBillingInvoiceById,
  getBillingInvoiceByTypeId,
  getInvoicePDFById,
  getServiceConfiguration,
  updateBillingInvoices,
} from "services/BillingService";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import { ShareOutlined } from "@mui/icons-material";
import "assets/scss/billingPage.scss";
import { getPatientData } from "services/patientService";
import { useSelector } from "react-redux";
import {
  getInvoiceAmountDetail,
  getIsPaymentMode,
  getMedicalServiceItems,
  getPatientInvoiceDetail,
  getPatientInvoiceId,
  setInvoiceAmountDetail,
  setIsPaymentMode,
  setMedicalServiceItems,
  setPatientInvoiceDetail,
  setPatientInvoiceId,
  setPatientInvoiceTotal,
} from "store/Slices/billingInvoiceSlice";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import {
  PatientAppointmentDetail,
  RenderInvoice,
  RenderMedicalServices,
  RenderPayment,
  RenderPrescription,
} from "./billing-components";
import { convertIntoTwoDecimal } from "utils/price-utils";
import { updateAppointmentStatus } from "services/Appointments";
import { downloadPDF } from "services/PrescriptionsService";

const GenerateInvoice = () => {
  const { handleClick } = useContext(ToastContext);
  const { state } = useLocation();
  const dispatch = useDispatch();

  // get data from url state
  const doctorId = state ? state.doctorId : "";
  const patientId = state ? state.patientId : "";
  const appointmentId = state ? state.appointmentId : "";
  const orgId = state ? state.orgId : "";

  const invoiceId = useSelector(getPatientInvoiceId);
  const isPaymentMode = useSelector(getIsPaymentMode);

  const [patientDetails, setPatientDetails] = useState(null);
  const invoiceDetails = useSelector(getPatientInvoiceDetail);
  const medicalServiceItems = useSelector(getMedicalServiceItems);

  //total amount
  const totalDetail = useSelector(getInvoiceAmountDetail);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const patientResponse = await getPatientData(patientId);
        setPatientDetails(patientResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [patientId, doctorId]);

  const [billingViewType, setBillingViewType] = useState("invoice");

  const updateInvoiceItems = async (invId) => {
    try {
      const invoiceResponse = await getBillingInvoiceById(invId);
      const invoiceItems = invoiceResponse.data.invoiceItems;
      const updatedList = medicalServiceItems.map((item) => {
        const itemFound = invoiceItems.find((i) => i.itemResponse.id === item.itemResponse.id);
        if (itemFound) {
          return { ...itemFound };
        } else {
          return { ...item };
        }
      });
      dispatch(setMedicalServiceItems(updatedList));
      dispatch(setPatientInvoiceDetail(invoiceResponse.data));
    } catch (error) {}
  };
  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        const invoiceResponse = await getBillingInvoiceByTypeId(appointmentId);
        const invoiceItems = invoiceResponse.data.invoiceItems;
        const updatedList = medicalServiceItems.map((item) => {
          const itemFound = invoiceItems.find((i) => i.itemResponse.id === item.itemResponse.id);
          if (itemFound) {
            return { ...itemFound };
          } else {
            return { ...item };
          }
        });
        dispatch(setMedicalServiceItems(updatedList));
        dispatch(setPatientInvoiceDetail(invoiceResponse.data));
        dispatch(setIsPaymentMode(invoiceResponse.data.invoiceStatus === "PAID" ? true : false));
      } catch (error) {}
    };
    fetchInvoiceData();
  }, []);

  const calculateTotalDetail = (invoiceItems) => {
    let totalDiscount = 0;
    let totalTax = 0;
    let totalAmount = 0;

    invoiceItems.forEach((item) => {
      totalDiscount += item.discount;
      totalTax += item.tax;
      totalAmount += item.amount;
    });
    dispatch(
      setInvoiceAmountDetail({
        discount: convertIntoTwoDecimal(totalDiscount),
        tax: convertIntoTwoDecimal(totalTax),
        amount: convertIntoTwoDecimal(totalAmount),
      })
    );

    dispatch(
      setPatientInvoiceTotal(
        convertIntoTwoDecimal(totalAmount - (invoiceDetails.adjustmentResponses?.[0]?.amount || 0))
      )
    );
  };

  // fetch doctor medical services and convert to invoice items
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (invoiceDetails.invoiceItems.length > 0) {
          return;
        }
        const response = await getServiceConfiguration(orgId, doctorId, ORGANIZATION);
        let invoiceItems = response.data.map((service) => {
          return {
            id: null,
            invoiceId: null,
            itemResponse: service,
            quantity: 0,
            discount: 0,
            tax: 0,
            amount: 0,
            status: ACTIVE_STATE,
          };
        });

        try {
          const invoiceResponse = await getBillingInvoiceByTypeId(appointmentId);
          const invoiceNewItems = invoiceResponse.data.invoiceItems;
          const updatedList = invoiceItems.map((item) => {
            const itemFound = invoiceNewItems.find(
              (i) => i.itemResponse.id === item.itemResponse.id
            );
            if (itemFound) {
              return { ...itemFound };
            } else {
              return { ...item };
            }
          });
          invoiceItems = [...updatedList];
          dispatch(setPatientInvoiceDetail(invoiceResponse.data));
          dispatch(setPatientInvoiceId(invoiceResponse.data.id));
          calculateTotalDetail(invoiceItems);
        } catch (error) {}

        dispatch(setMedicalServiceItems(invoiceItems));
      } catch (error) {
        console.error("Fetch Information failed");
      }
    };
    fetchData();
  }, [doctorId, orgId]);

  const handleGenerateInvoice = async () => {
    try {
      let invoiceItemArray = [];
      let invoiceUUID = getUUID();

      invoiceDetails.invoiceItems.forEach((selService) => {
        const data = {
          id: selService.id || getUUID(),
          invoiceId: invoiceId || invoiceUUID,
          description: null,
          typeId: selService.itemResponse.id,
          invoiceItemType: "MEDICAL_SERVICE",
          quantity: selService.quantity,
          discount: selService.discount || 0,
          tax: selService.tax || 0,
          amount: selService?.amount || 0,
          discountId: selService.itemResponse.discounts?.[0]?.id,
          status: selService.id && selService.quantity === 0 ? INACTIVE_STATE : ACTIVE_STATE,
        };

        // marking the items inactive if already added, but made quantity 0 while updating
        if (data.quantity > 0 || data.status === INACTIVE_STATE) {
          invoiceItemArray.push(data);
        }
      });
      const body = {
        description: null,
        id: invoiceId || invoiceUUID,
        typeId: appointmentId,
        invoiceType: "APPOINTMENT",
        organizationId: orgId,
        patientId: patientId,
        doctorId: doctorId,
        issueDate: dayjs(),
        totalAmount: convertIntoTwoDecimal(
          totalDetail?.amount - (invoiceDetails.adjustmentResponses?.[0]?.amount || 0)
        ),
        invoiceStatus: "PENDING",
        status: "ACTIVE",
        invoiceItems: invoiceItemArray,
        invoiceKey: invoiceDetails.invoiceKey,
        adjustmentResponses: invoiceDetails.adjustmentResponses,
      };
      if (!invoiceId) {
        const invoiceResponse = await generateBillingInvoices(body);
        dispatch(setPatientInvoiceId(invoiceResponse.data.id));
        updateInvoiceItems(invoiceResponse.data.id);
        try {
          await updateAppointmentStatus(appointmentId, "COMPLETED");
        } catch (error) {
          handleClick("error", "There seems to be an error updating the appointment status");
        }
      } else {
        const invoiceResponse = await updateBillingInvoices(invoiceId, body);
        updateInvoiceItems(invoiceResponse.data.id);
      }
      dispatch(setIsPaymentMode(true));
    } catch (error) {
      console.error(error);
    }
  };

  //adjustment modal

  const sharePdfToPatient = async () => {
    try {
      if (billingViewType === "invoice") {
        await getInvoicePDFById(invoiceDetails?.id, null, true);
      } else {
        await downloadPDF(appointmentId, true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const generatePDF = async () => {
    try {
      // toDo need to check once the pr is merged

      let pdfRes;
      if (billingViewType === "invoice") {
        pdfRes = await getInvoicePDFById(invoiceDetails?.id, null);
      } else {
        pdfRes = await downloadPDF(appointmentId);
      }

      const blob = new Blob([pdfRes.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      // Create an iframe element to embed the PDF
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = url;

      document.body.appendChild(iframe);

      iframe.onload = () => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      };
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    } catch (error) {
      handleClick("error", "There seems to be an error generating PDF");
    }
  };

  return (
    <Reveal className="billing-page">
      <Grid
        container
        spacing={2}
        className="cst-height-billing mx-0 row justify-content-space-between align-items-center mb-0"
        style={{ marginBottom: "25px" }}
      >
        <Grid size={{ md: 3, sm: 12 }} style={{ height: "100%", marginBottom: "25px" }}>
          <Card
            className="custom-card-ui"
            style={{ backgroundColor: "white", scrollbarWidth: "none" }}
          >
            <div>
              <div style={{ marginBottom: "10px" }}>
                <h2 className="page-title">Billing</h2>
              </div>
              <div>
                <PatientAppointmentDetail
                  handleClick={handleClick}
                  appointmentId={appointmentId}
                ></PatientAppointmentDetail>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid size={{ md: 3, sm: 12 }} style={{ height: "100%", marginBottom: "25px" }}>
          <div style={{ height: "100%" }}>
            <motion.div
              style={{
                position: "relative",
                transformStyle: "preserve-3d",
                width: "100%",
                height: "100%",
              }}
              animate={{
                rotateY: isPaymentMode ? 0 : 180,
              }}
              transition={{
                duration: 0.6,
                ease: "easeInOut",
              }}
            >
              <motion.div
                style={{
                  backfaceVisibility: "hidden",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Card
                  className="custom-card-ui"
                  style={{ backgroundColor: "white", scrollbarWidth: "none" }}
                >
                  <RenderPayment
                    patientDetails={patientDetails}
                    handleClick={handleClick}
                  ></RenderPayment>
                </Card>
              </motion.div>
              <motion.div
                style={{
                  backfaceVisibility: "hidden",
                  transform: "rotateY(180deg)",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Card
                  className="custom-card-ui"
                  style={{
                    backgroundColor: "white",
                    scrollbarWidth: "none",
                  }}
                >
                  <RenderMedicalServices
                    sourceData={{
                      appointmentId: appointmentId,
                      patientId: patientId,
                      doctorId: doctorId,
                      orgId: orgId,
                    }}
                    handleGenerateInvoice={handleGenerateInvoice}
                    isPaymentMode={isPaymentMode}
                  ></RenderMedicalServices>
                </Card>
              </motion.div>
            </motion.div>
          </div>
        </Grid>
        <Grid size={{ md: 6, sm: 12 }} style={{ height: "100%", marginBottom: "25px" }}>
          <Card
            className="custom-card-ui"
            style={{
              backgroundColor: "white",
              scrollbarWidth: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <ToggleButtonGroup
                  value={billingViewType}
                  exclusive
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBillingViewType(newValue);
                    }
                  }}
                >
                  <ToggleButton
                    sx={{
                      padding: "5px 10px",
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      "&.Mui-selected": {
                        color: "#004c70",
                        backgroundColor: "#def1ee",
                      },
                    }}
                    value="invoice"
                  >
                    Invoice
                  </ToggleButton>
                  <ToggleButton
                    sx={{
                      padding: "5px 10px",
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",

                      "&.Mui-selected": {
                        color: "#004c70",
                        backgroundColor: "#def1ee",
                      },
                    }}
                    value="prescription"
                  >
                    Prescription
                  </ToggleButton>
                </ToggleButtonGroup>
                <div>
                  <CustomButton
                    iconButton={<PrintOutlined />}
                    style={{ marginLeft: "auto" }}
                    className={"btn--secondary-light"}
                    onClick={() => {
                      generatePDF();
                    }}
                    disabled={
                      billingViewType === "invoice" && invoiceDetails?.invoiceStatus !== "PAID"
                    }
                  />
                  <CustomButton
                    iconButton={<ShareOutlined />}
                    style={{ marginLeft: "10px" }}
                    className={"btn--secondary-light"}
                    onClick={() => {
                      sharePdfToPatient();
                    }}
                  />
                </div>
              </div>
              <div
                className="common-scrollbar"
                style={{ flexGrow: 1, maxHeight: "100%", maxWidth: "100%", overflowY: "none" }}
              >
                {billingViewType === "invoice" ? (
                  <RenderInvoice
                    invoiceDetails={invoiceDetails}
                    totalDetail={totalDetail}
                  ></RenderInvoice>
                ) : (
                  <RenderPrescription appointmentId={appointmentId}></RenderPrescription>
                )}
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Reveal>
  );
};

export default GenerateInvoice;
