import React, { useEffect, useState } from "react";
import { getDashboard } from "services/DashboardService";
import PreviewDashboard from "../Chart/previewDashboard";
import { useParams } from "react-router";
import { initializeDashboardData } from "utils/dashboard-utils";

const SuperAdminDashboard = ({ dashboardid }) => {
  const { id } = useParams();
  const [droppableAreas, setDroppableAreas] = useState([]);
  const [dashboardTitle, setDashboardTitle] = useState("");
  const [dashboardId, setDashboardId] = useState(dashboardid ?? id);
  const [selectedDashboard, setSelectedDashboard] = useState();

  const initializeComponent = async () => {
    try {
      let data;
      if (dashboardId) {
        const response = await getDashboard(dashboardId);
        data = response.data;
        setSelectedDashboard(response.data);
      } else {
        throw new Error("Dashboard does not exist...Create one");
      }
      setDashboardTitle(data.name);
      const updatedAreaRows = await initializeDashboardData(data);
      setDroppableAreas([{ id: data.widgets.screenId, rows: updatedAreaRows }]);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    initializeComponent();
  }, [dashboardId]);
  return (
    <PreviewDashboard
      droppableAreas={droppableAreas}
      dashboardTitle={dashboardTitle}
      setDroppableAreas={setDroppableAreas}
      selectedDashboard={selectedDashboard}
      setSelectedDashboard={setSelectedDashboard}
    />
  );
};

export default SuperAdminDashboard;
