import { api as API } from "./AxiosInterceptor";
import { getUUID } from "../store/constant";

export const createDashboard = (data) => {
  data["id"] = getUUID();
  return API.post(`/api/dashboards`, data);
};
export const getAllDashboards = () => API.get(`/api/dashboards`);
// export const checkQuery = (query) => {
//   query["id"] = getUUID();
//   return API.post(`/api/queries/execute`, query);
// };
export const checkQuery = (query) => {
  query["id"] = getUUID();
  return API.post(`/api/queries/execute`, query, {
    headers: {
      "Application-Agent": "ahc",
    },
  });
};
export const getDashboard = (id, disabledTenant = true) =>
  API.get(`/api/dashboards/${id}`, { disabledTenant });
export const updateDashboard = (id, data, disabledTenant = true) =>
  API.put(`/api/dashboards/${id}`, data, { disabledTenant });
export const deleteDashboard = (id, disabledTenant = true) =>
  API.delete(`/api/dashboards/${id}`, { disabledTenant });
