import PropTypes from "prop-types";
import { toCamelCase } from "utils/toCamelCase";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { Chip, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ExpandLess, FiberManualRecord } from "@mui/icons-material";
import {
  getPatientAllergyByPatientId,
  getPatientBodyImplantsByPatientId,
  getPatientChronicDiseaseByPatientId,
  getPatientFamilyHistoryByPatientId,
} from "services/patientService";
import { SEVERITY_ORDER } from "store/constant";
import { useSelector } from "react-redux";
import { getSelectedAppointmentOnDashboard } from "store/Slices/Doctor-Configurable-Dashboard-Slice/ChildSlices/appointmentSlice";
import { getColorAndBackgroundColorBySeverity } from "utils/calculate-vital-color";
import ComponentLoader from "ui-component/custom-components/ComponentLoader";

const CustomPatientPrescriptionOverviewItemCV1 = ({ overviewItemType }) => {
  const selectedAppointment = useSelector(getSelectedAppointmentOnDashboard)?.value;

  const getListDisplayValue = (overviewItemType, element) => {
    switch (overviewItemType) {
      case "Allergy":
        return element?.allergyResponse?.name || "";

      case "Family History":
        return element?.medicalCondition
          ? `${element?.medicalCondition} (${toCamelCase(element?.relationType)})`
          : "";

      case "Medical Condition":
        return element?.chronicDiseaseResponse?.name || "";

      case "Body Implants":
        return element?.name || "";
      default:
        return "";
    }
  };

  const ItemApiMap = {
    Allergy: getPatientAllergyByPatientId,
    "Family History": getPatientFamilyHistoryByPatientId,
    "Medical Condition": getPatientChronicDiseaseByPatientId,
    "Body Implants": getPatientBodyImplantsByPatientId,
  };

  const [list, setList] = useState([]);
  const [showWholeList, setShowWholeList] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedAppointment?.patientId) {
          if (!loading) {
            setLoading(true);
          }
          const listResponse = await ItemApiMap[overviewItemType](
            selectedAppointment?.patientId || selectedAppointment?.id
          );
          let sortedList = listResponse.data;
          if (listResponse.data[0]?.severity) {
            sortedList = listResponse.data.sort((a, b) => {
              return SEVERITY_ORDER[a.severity] - SEVERITY_ORDER[b.severity];
            });
          }
          setList(sortedList);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedAppointment?.patientId]);

  return (
    <>
      <div className="patient-prescription-overview-item">
        <Grid
          sx={{
            fontWeight: "600",
            margin: "0",
            color: "#004c70",
            lineHeight: "normal",
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
          }}
        >
          {overviewItemType}
        </Grid>
        {loading ? (
          <>
            <div
              style={{
                display: "flex",
                minHeight: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ComponentLoader></ComponentLoader>
            </div>
          </>
        ) : (
          <>
            {list?.length > 0 ? (
              <div>
                <List sx={{ p: "0px" }}>
                  {list.slice(0, showWholeList ? list.length : 3).map((item, index) => {
                    const colorCode = getColorAndBackgroundColorBySeverity(item?.severity);
                    return (
                      <ListItem
                        sx={{
                          p: "0px 10px",
                          "&:hover": {
                            backgroundColor: "#e6eef1", // Change this to the color you want
                          },
                        }}
                        key={index}
                      >
                        <ListItemIcon sx={{ minWidth: "auto", pr: 1 }}>
                          <FiberManualRecord sx={{ fontSize: "8px", color: "#0C2D48" }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            sx: {
                              color: `${colorCode?.color} !important`,
                              fontSize: { md: "12px", lg: "13px", xl: "14px" },
                            },
                          }}
                          // primary={item?.allergyResponse?.name}
                          primary={getListDisplayValue(overviewItemType, item)}
                        ></ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
                {list.length > 3 && (
                  <div>
                    {!showWholeList ? (
                      <Chip
                        sx={{
                          backgroundColor: "transparent",
                          borderRadius: "2px",
                          cursor: "pointer",
                        }}
                        label={`+${list.length - 3} more`}
                        onClick={() => setShowWholeList(true)}
                      />
                    ) : (
                      <Chip
                        sx={{
                          backgroundColor: "transparent",
                          borderRadius: "2px",
                          cursor: "pointer",
                        }}
                        label="Show less"
                        icon={<ExpandLess />}
                        onClick={() => setShowWholeList(false)}
                      />
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    textAlign: "center",
                    minHeight: "20px",
                    width: "50%",
                    backgroundColor: "lightgray",
                  }}
                >
                  NA
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

CustomPatientPrescriptionOverviewItemCV1.propTypes = {
  overviewItemType: PropTypes.oneOf([
    "Allergy",
    "Medical Condition",
    "Family History",
    "Body Implants",
  ]),
};

export default CustomPatientPrescriptionOverviewItemCV1;
