import React, { useState, useEffect } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  COMPLETED,
  DOCTOR,
  PATIENT,
  accessToken,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
} from "store/constant";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import {
  IconButton,
  Popper,
  Rating,
  Typography,
  Switch,
  Card,
  CardContent,
  Skeleton,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router";
import CustomButton from "ui-component/custom-components/CustomButton";
import { getAppointmentsByPatientId, getFilteredAppointments } from "services/Appointments";
import { useContext } from "react";
import { getAllDoctors, getDoctorByUserId } from "services/doctorService";
import { getAllPatients, getPatientByUserId } from "services/patientService";
import {
  getAllReviews,
  getReviewsByDocId,
  getReviewsByPatientId,
  deleteReview,
} from "services/ReviewService";
import SettingsIcon from "@mui/icons-material/Settings";
import { useRef } from "react";
import { updateDoctors } from "services/doctorService";
import Reveal from "views/utilities/Reveal";

const ReviewList = () => {
  const { handleClick } = useContext(ToastContext);

  const navigate = useNavigate();

  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddButton, setshowAddButton] = useState(false);
  const currUser = JSON.parse(localStorage.getItem("currentActiveUser"));
  const currRole = currUser?.roleName;
  const accessTokenValue = accessToken();
  const [iconRotate, setIconRotate] = useState(false);
  const [settingModalOpen, setSettingModalOpen] = useState(false);
  const [displayReviews, setDisplayReviews] = useState(null);
  const [acceptReviews, setAcceptReviews] = useState(null);
  const settingsRef = useRef(null);

  const getColumns = () => {
    let columns;
    let c = [
      { field: "Sr. No.", label: "Sr. No" },
      { field: "rating", label: "Rating" },
      { field: "title", label: "Title" },
      { field: "description", label: "Description" },
    ];

    switch (currRole) {
      case PATIENT:
        columns = [
          ...c.slice(0, 2),
          { field: "docName", label: "Doctor" },
          ...c.slice(2),
          { field: "Actions", label: "Actions" },
        ];
        break;
      case DOCTOR:
        columns = [...c.slice(0, 2), { field: "patientName", label: "Patient" }, ...c.slice(2)];
        break;
      default:
        columns = [
          ...c.slice(0, 2),
          { field: "patientName", label: "Patient" },
          { field: "docName", label: "Doctor" },
          ...c.slice(2),
        ];
        break;
    }
    return columns;
  };
  const handleIconClick = () => {
    setSettingModalOpen(!settingModalOpen);
    setIconRotate(!iconRotate);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = [];
        let fetchedDoctors = [];
        let fetchedPatients = [];

        if (currRole === PATIENT) {
          const tempResponse = await getPatientByUserId(currUser.id);
          const patientId = tempResponse?.data?.id;
          const tempAppointmentsRes = await getAppointmentsByPatientId(patientId);
          const appRes = tempAppointmentsRes.data.filter(
            (el) => el?.appointmentStatus === COMPLETED
          );

          const acceptReviews = appRes.filter((el) => el?.doctor?.acceptReviews);
          setshowAddButton(acceptReviews.length !== 0);

          fetchedDoctors = appRes.map((el) => ({
            id: el.doctor?.id,
            userResponse: { name: el.doctor.userResponse.name },
            displayReviews: el?.doctor?.displayReviews,
          }));

          response = await getReviewsByPatientId(patientId);
          setLoading(false);
        } else if (currRole === DOCTOR) {
          const tempResponse = await getDoctorByUserId(currUser?.id);
          setAcceptReviews(tempResponse?.data.acceptReviews);
          setDisplayReviews(tempResponse?.data.displayReviews);
          const docId = tempResponse?.data?.id;
          const tempAppointmentsRes = await getFilteredAppointments({ doctorId: docId });
          const appRes = tempAppointmentsRes.data.content.filter(
            (el) => el.appointmentStatus === COMPLETED
          );

          fetchedPatients = appRes.map((el) => ({
            id: el?.patientId,
            user: { name: el.patientName },
          }));
          response = await getReviewsByDocId(docId);
          setshowAddButton(false);
          setLoading(false);
        } else {
          const docResponse = await getAllDoctors();
          fetchedDoctors = docResponse.data;

          const patientResponse = await getAllPatients();
          fetchedPatients = patientResponse.data;

          response = await getAllReviews();
          setshowAddButton(false);
        }

        let reviewsData = [];
        response.data.forEach((el) => {
          const obj = {
            ...el,
            docResponse: fetchedDoctors.find((d) => d.id === el.doctorId),
            docName: ` Dr. ${fetchedDoctors.find((d) => d.id === el.doctorId)?.userResponse?.name}`,
            patientName: fetchedPatients.find((p) => p.id === el.patientId)?.userResponse?.name,
            rating: <Rating name={`rating-${el.id}`} value={el.rating} size="large" readOnly />,
          };
          reviewsData.push(obj);
        });

        if (currRole !== DOCTOR && currRole !== PATIENT) {
          setReviews(reviewsData?.filter((e) => e?.docResponse?.displayReviews));
        } else {
          setReviews(reviewsData);
        }
      } catch (error) {
        console.error("Error fetching data");
      }
    };

    fetchData();
  }, [accessTokenValue, currRole, currUser?.id, handleClick]);

  const navigatoToAddReview = () => {
    navigate("/home/review");
  };

  const handleUpdate = (review) => {
    navigate("/home/review", { state: { reviewId: review?.id } });
  };

  const handleDelete = async (review) => {
    const reviewId = review?.id;
    try {
      const res = await deleteReview(reviewId);
      if (res) {
        const tempReviews = reviews.filter((el) => el.id !== reviewId);
        setReviews(tempReviews);
        handleClick("success", "Review has been successfully deleted.");
      }
    } catch (error) {
      handleClick("error", "There seems to be an error deleting review.");
    }
  };

  const isDisable = () => {
    //  delete functionality should only allow for patient
    if (currRole === PATIENT) {
      return false;
    } else {
      return true;
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: handleUpdate,
    },
    {
      label: "Delete",
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleDelete,
      checkDisable: isDisable,
    },
  ];

  const handleSettingsSave = async () => {
    try {
      const data = {
        displayReviews: displayReviews,
        acceptReviews: acceptReviews,
      };
      await updateDoctors(currUser?.roleBasedId, data);
      setSettingModalOpen(false);
      handleClick("success", "Settings are successfully updated.");
    } catch (error) {
      handleClick("error", "There seems to be an error updating settings.");
    }
  };

  return (
    // <ReviewListSkeletonStructure loading={loading}>
    <Box>
      {loading ? (
        <Box>
          <Grid container spacing={2}>
            {/* Page Title */}
            <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
              <Grid>
                <Skeleton animation="wave" width={100} height={40} />
              </Grid>
              {/* Add Button skeleton */}
              {showAddButton && (
                <Grid>
                  <Skeleton animation="wave" width={150} height={40} />
                </Grid>
              )}
            </Grid>

            {/* Settings Icon skeleton */}
            {currRole === DOCTOR && (
              <Grid size={{ xs: 12 }} display="flex" justifyContent="flex-end">
                <Skeleton animation="wave" width={40} height={40} />
              </Grid>
            )}

            {/* Table Skeleton */}
            <Grid size={{ xs: 12 }}>
              <Skeleton
                sx={{ borderRadius: "4px" }}
                animation="wave"
                variant="rectangular"
                width="100%"
                height={350}
              />
            </Grid>

            {/* Actions Button Skeleton */}
            <Grid size={{ xs: 12 }} display="flex" justifyContent="flex-end" spacing={2}>
              <Skeleton animation="wave" width={100} height={40} />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Reveal>
          <div className="section-heading">
            <h2 className="page-title">Reviews</h2>
            <div className="buttons">
              {showAddButton && (
                <CustomButton
                  className="ri-add-fill ri-lg btn--primary"
                  style={{ marginLeft: "auto" }}
                  onClick={() => navigatoToAddReview()}
                  label="Add Review"
                />
              )}
              {currRole === "DOCTOR" && (
                <>
                  <IconButton
                    onClick={handleIconClick}
                    ref={settingsRef}
                    sx={{
                      marginLeft: "auto",
                      transform: iconRotate ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s",
                      "&:hover, &:focus": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                  <Popper
                    placement="bottom-end"
                    open={settingModalOpen}
                    disablePortal
                    anchorEl={settingsRef.current}
                    style={{ zIndex: "10" }}
                  >
                    <Card
                      sx={{
                        bgcolor: "white",
                        height: "200px",
                        width: "250px",
                        padding: "10px",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <CardContent>
                        <Grid container spacing={3} direction="column">
                          <Grid>
                            <Grid container alignItems="center" justifyContent="space-between">
                              <Grid>
                                <Typography variant="subtitle1">Display Reviews</Typography>
                              </Grid>
                              <Grid>
                                <Switch
                                  color="primary"
                                  checked={displayReviews}
                                  onChange={(e) => setDisplayReviews(e.target.checked)}
                                  name="displayReviews"
                                  size="small"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid>
                            <Grid container alignItems="center" justifyContent="space-between">
                              <Grid>
                                <Typography variant="subtitle1">Accept Reviews</Typography>
                              </Grid>
                              <Grid>
                                <Switch
                                  checked={acceptReviews}
                                  onChange={(e) => setAcceptReviews(e.target.checked)}
                                  name="acceptReviews"
                                  size="small"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid>
                            <CustomButton
                              label={"Save"}
                              onClick={handleSettingsSave}
                              className={"btn--secondary-light"}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Popper>
                </>
              )}
            </div>
          </div>
          <CustomizedTable columns={getColumns()} tableData={reviews} actions={actions} />
        </Reveal>
      )}
    </Box>
    // </ReviewListSkeletonStructure>
  );
};

const ReviewListSkeletonStructure = ({ children, showAddButtion, currRole }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid size={{ xs: 12 }} container justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewList;
