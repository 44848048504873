import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hprData: null,
  hfrData: null,
  abhaData: null,
  showVerify: false,
  showOnboarding: false,
  organizationData: null,
  invitationLinkData: null,
};

const doctorOnboardingSlice = createSlice({
  name: "doctorOnboarding",
  initialState,
  reducers: {
    setHprData(state, action) {
      state.hprData = action.payload;
    },
    setHfrData(state, action) {
      state.hfrData = action.payload;
    },
    setAbhaData(state, action) {
      state.abhaData = action.payload;
    },
    setShowVerify(state, action) {
      state.showVerify = action.payload;
    },
    setShowOnboarding(state, action) {
      state.showOnboarding = action.payload;
    },
    setOrganizationData(state, action) {
      state.organizationData = action.payload;
    },
    setInvitationLinkData(state, action) {
      state.invitationLinkData = action.payload;
    },
  },
});

export const {
  setHprData,
  setHfrData,
  setAbhaData,
  setShowVerify,
  setShowOnboarding,
  setOrganizationData,
  setInvitationLinkData,
} = doctorOnboardingSlice.actions;

export const getHprData = (state) => state.doctorOnboarding.hprData;
export const getHfrData = (state) => state.doctorOnboarding.hfrData;
export const getAbhaData = (state) => state.doctorOnboarding.abhaData;
export const getShowVerify = (state) => state.doctorOnboarding.showVerify;
export const getShowOnboarding = (state) => state.doctorOnboarding.showOnboarding;
export const getOrganizationData = (state) => state.doctorOnboarding.organizationData;
export const getInvitationLinkData = (state) => state.doctorOnboarding.invitationLinkData;

export const doctorOnboardingReducer = doctorOnboardingSlice.reducer;
