import React, { useState } from "react";
import { FormControl, TextField, InputAdornment } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import dayjs from "dayjs";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";

const FormDatePicker = ({
  label,
  value,
  onChange,
  size,
  style,
  background,
  disableBorder,
  iconAtEnd,
  borderRadius = "6px",
  border,
  borderOnFocus,
  inputPropStyle,
  readOnly = false,
  required = false,
  error,
  errorText,
  handleBlur,
  onClose,
  height,
  maxDate,
  views = ["year", "month", "day"],
  hideInput = false,
  blockedDates = [],
  closeOnSelect,
  closeOnBlockClick = false,
  disablePastDates = false,
  popperStyle = {},
  disabledDates,
  disableTextField = false,
  renderDay,
  ...restProps
}) => {
  const [openCal, setOpenCal] = useState(hideInput);

  return (
    <FormControl error={error} style={style}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          style={{
            transform: "translateX(1px)",
          }}
          value={value === "" ? dayjs() : dayjs(value)}
          onChange={(date) => onChange(date)}
          onClose={() => {
            if (onClose) {
              onClose();
            }
            setOpenCal(false);
          }}
          open={openCal}
          views={views}
          referenceDate={maxDate ? dayjs(maxDate) : undefined}
          maxDate={maxDate}
          readOnly={readOnly}
          closeOnSelect={!hideInput}
          shouldDisableDate={disabledDates}
          slots={{
            textField: TextField, // Replaces renderInput
          }}
          slotProps={{
            textField: {
              label: label,
              onKeyDown: disableTextField ? (e) => e.preventDefault() : undefined,
              onBlur: handleBlur,
              error: error, // Pass the error state to the TextField
              helperText: error ? errorText : "", // Display the errorText conditionally
              sx: {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: disableBorder ? "none" : border ? border : undefined,
                  borderRadius: `${borderRadius} !important`,
                },
                "& .MuiOutlinedInput-input": {
                  background: background ? background : undefined,
                  height: `${height} !important`,
                },
                "& .MuiOutlinedInput-root": {
                  background: background ? background : undefined,
                  "&:hover fieldset": {
                    border: `${border} !important`,
                  },
                  "&.Mui-focused fieldset": {
                    border: `${borderOnFocus} !important`,
                    transition: "0.5s ease",
                  },
                },
              },
              InputProps: {
                sx: {
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  ...inputPropStyle,
                },
                label: label,
                size: size,
                startAdornment: !iconAtEnd && !readOnly && (
                  <InputAdornment
                    position="start"
                    sx={{ marginRight: "11px", marginLeft: "1px", cursor: "pointer" }}
                  >
                    <EventOutlinedIcon onClick={() => setOpenCal(!openCal)} />
                  </InputAdornment>
                ),
                endAdornment: iconAtEnd && !readOnly && (
                  <InputAdornment
                    position="start"
                    sx={{ marginRight: "11px", marginLeft: "1px", cursor: "pointer" }}
                  >
                    <EventOutlinedIcon onClick={() => setOpenCal(!openCal)} />
                  </InputAdornment>
                ),
              },
            },
            day: { renderDay },
          }}
          PopperProps={{
            disablePortal: false,
            modifiers: [
              {
                name: "flip",
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: "viewport",
                  padding: 8,
                },
              },
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
            ],
          }}
          {...restProps}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default FormDatePicker;
