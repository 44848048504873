import { Card, Paper, CardContent, Typography, Skeleton, Box, Drawer } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState, useCallback, useEffect } from "react";
import {
  CLINIC_VISIT,
  SCHEDULED,
  currentActiveUser,
  TELE_CONSULTATION,
  RESCHEDULED,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  COMPLETED,
  FEMALE,
  DATE_FORMAT_DMY,
  convertTimeForTimeFormat,
  DATE_FORMAT,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useNavigate } from "react-router";
import { getProfilePicture, getPatientFamilyDetails } from "services/patientService";
import {
  medicationReminderNotify,
  medicationReminderNotifyOff,
  getMedicationReminderByUserId,
  updateMedicationReminder,
} from "services/MedicationReminderService";
// scss
import "assets/scss/dashboard.scss";
import "assets/scss/style.scss";
// icons + images
import VideocamIcon from "@mui/icons-material/Videocam";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import ModalUI from "ui-component/ModalUI";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import { useDispatch } from "react-redux";
import { setSelectedAppointmentData } from "store/Slices/appointmentDataSlice";
import { useContext } from "react";
import { getAppointmentsByPatientId, getRecentAppointments } from "services/Appointments";
import {
  getEhrData,
  getMedicationsByPatientId,
  getMedicinesImages,
} from "services/PrescriptionsService";
import Reveal from "views/utilities/Reveal";
import { calculateAge } from "utils/calculate-age";
import dayjs from "dayjs";
import { getAllContent } from "services/ContentService";
import { fetchPatientLatestVitalSigns } from "utils/patient-latest-vital";
import { getEhrDataPoints, vitalSignType } from "utils/ehr-data-utils";
import { api } from "services/AxiosInterceptor";
import { TrendingUp } from "@mui/icons-material";
import { PendingDetailsPopupComponent } from "./CommonDashboardForFrontDeskAndDoctor";
import RecentVisitItem from "ui-component/custom-components/RecentVisitItem";
import CloseIcon from "@mui/icons-material/Close";
import SimpleImageSlider from "react-simple-image-slider";

const PatientDashboard = () => {
  const currentTime = dayjs();
  const { handleClick } = useContext(ToastContext);
  const navigate = useNavigate();
  const [dailyMedicationValues, setDailyMedicationValues] = useState([]);
  const [upcomingAppointment, setUpcomingAppointment] = useState(null);
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);
  const [vitalDetails, setVitalDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recentVisits, setRecentVisits] = useState([]);
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const [isSkipForNow, setIsSkipForNow] = useState(localStorage.getItem("isSkipForNow"));
  const [openModal, setOpenModal] = useState(false);

  const [patientLatestVitals, setPatientLatestVitals] = useState([]);
  const [lastVisited, setLastVisited] = useState(null);
  const [showVisited, setShowVisited] = useState(false);
  const [notificationReminderData, setNotificationReminderData] = useState([]);
  const now = new Date();
  const [profileImage, setProfileImage] = useState(null);
  const [genderBasedImage, setGenderBasedImage] = useState(null);
  const [vitalData, setVitalData] = useState([]);
  const [isAbhaNumberExist, setIsAbhaNumberExist] = useState(
    currentActiveUser()?.abhaNumber ? true : false
  );
  const dispatch = useDispatch();

  const getDaysDifference = (date1, date2) => {
    const timeDiff = date2.getTime() - date1.getTime();
    return Math.floor(timeDiff / (1000 * 3600 * 24));
  };

  useEffect(() => {
    if (!isSkipForNow && !isAbhaNumberExist) {
      setOpenModal(true);
    }
  }, [isSkipForNow, isAbhaNumberExist]);

  const handleSkipForNow = () => {
    localStorage.setItem("isSkipForNow", true);
    setOpenModal(false);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const steps = [
    {
      label: "Abha helps in easy access and tracking of health records",
      icon: <i className="ri-eye-fill ri- lg" />,
      condition: !isAbhaNumberExist,
      onClick: () => {
        navigate("/home/manageAbhaNumber", { state: { pendingTask: true } });
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dailyMedicineResponse = await getMedicationsByPatientId(
          currentActiveUser()?.roleBasedId
        );

        const medicines = dailyMedicineResponse.data.map((medicine) => {
          return {
            medicationId: medicine.medicationId,
            medicine: medicine.medicationResponse?.name,
            frequency: medicine.frequency,
            doseTiming: medicine.doseTiming,
            endDate: medicine.endDate,
            instructions: medicine.instructions,
            status: medicine.status,
          };
        });
        setDailyMedicationValues(medicines);

        await fetchPatientLatestVitalSigns(
          currentActiveUser()?.roleBasedId,
          setPatientLatestVitals,
          handleClick
        );
      } catch (error) {
        console.error("Error fetching daily medicines");
      }
    };

    fetchData();

    const fetchPatientDetails = async () => {
      try {
        const user = currentActiveUser();
        const patientAppointmentsResponse = await getAppointmentsByPatientId(user?.roleBasedId);

        const upcomingAppointments = patientAppointmentsResponse?.data?.filter(
          (e) => e?.appointmentStatus === SCHEDULED || e?.appointmentStatus === RESCHEDULED
        );

        let UpcomingAppointment;
        upcomingAppointments?.some((e) => {
          const appointmentDateTime = dayjs(`${e?.appointmentDate}T${e?.appointmentTime}`);
          if (currentTime.isBefore(appointmentDateTime.add(30, "minute"))) {
            UpcomingAppointment = e;
            return true;
          }
          return false;
        });

        setUpcomingAppointment(UpcomingAppointment);

        const filteredAppointments = patientAppointmentsResponse?.data.filter(
          (appointment) =>
            appointment.appointmentStatus === COMPLETED &&
            appointment.doctor.id === upcomingAppointments[0]?.doctor.id
        );
        if (filteredAppointments.length !== 0) {
          const recent = filteredAppointments.reduce((mostRecent, current) => {
            const currentDate = new Date(current.appointmentDate);
            const mostRecentDate = new Date(mostRecent.appointmentDate);
            return currentDate > mostRecentDate ? current : mostRecent;
          });
          setLastVisited(getDaysDifference(new Date(recent.appointmentDate), now));
          setShowVisited(true);
        }
        const familyId = currentActiveUser().familyResponse?.id;
        if (familyId) {
          const familyMembersResponse = await getPatientFamilyDetails(familyId);
          const familyMembers = familyMembersResponse.data?.userFamilyMappingResponses.map(
            (member) => ({
              id: member.userId,
              member: member?.userResponse.name,
              age: calculateAge(member.userResponse.dateOfBirth),
              gender: member.userResponse.gender,
              relation: member?.relationship,
            })
          );
          setMemberDetailValues(familyMembers);
        }

        const recentResponse = await getRecentAppointments(
          currentActiveUser()?.roleBasedId,
          currentTime.subtract(7, "day").format(DATE_FORMAT),
          currentTime.format(DATE_FORMAT)
        );

        const recentvisits = recentResponse.data.map((visit) => {
          return {
            doctorName: visit.doctor.userResponse.name,
            patient: visit.patient.user.name,
            organizationName: visit.organization.name,
            type: visit.type,
            appointmentDate: dayjs(visit.appointmentDate).format(DATE_FORMAT_DMY),
            appointmentTime: visit.appointmentTime,
            appointmentId: visit?.id,
          };
        });
        setRecentVisits(recentvisits);
      } catch (error) {
        console.error("Issue fetching patient details.", error);
      }
    };

    fetchPatientDetails();
  }, [handleClick]);

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (upcomingAppointment) {
        try {
          setIsLoading(true);
          const userId = upcomingAppointment.doctor.userResponse.id;
          const profilePicResponse = await getProfilePicture(userId);
          const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;
          setProfileImage({
            filename: profilePicResponse.data.filename,
            content,
          });
        } catch (error) {
          setGenderBasedImage(
            upcomingAppointment.doctor.userResponse.gender === FEMALE
              ? "/images/woman.png"
              : "/images/man.png"
          );
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchProfilePicture();
  }, [upcomingAppointment]);

  // useEffect(() => {
  //   const tempCardDetails = patientLatestVitals.map((vital) => {
  //     return {
  //       fieldName: vital.vitalSignTypeResponse.displayName,
  //       fieldValue: vital.value,
  //       change: "+0%",
  //       abbreviation: vital.vitalSignTypeResponse.unitAbbreviation,
  //     };
  //   });
  //   setVitalDetails(tempCardDetails);
  // }, [patientLatestVitals]);

  const hanldeLatestBloodPressureCase = (vital) => {
    const latestSystolicBP = vital.value
      .filter((el) => el?.dataType === "BLOOD_PRESSURE_SYSTOLIC")
      .reduce((latest, current) => {
        return current.dateFrom > latest.dateFrom ? current : latest;
      });

    const latestDiastolicBP = vital.value
      .filter((el) => el?.dataType === "BLOOD_PRESSURE_DIASTOLIC")
      .reduce((latest, current) => {
        return current.dateFrom > latest.dateFrom ? current : latest;
      });

    return {
      key: vital.key,
      latestValue: {
        ...latestSystolicBP,
        value: `${latestSystolicBP.value}/${latestDiastolicBP.value}`,
        vitalSignTypeResponse: {
          ...latestSystolicBP.vitalSignTypeResponse,
          displayName: "Blood Pressure",
        },
      },
      isIncreased: 1,
    };
  };

  useEffect(() => {
    const fetchPatientEhrDetails = async () => {
      try {
        const response = await getEhrData(currentActiveUser()?.roleBasedId);
        const ehrDataPoints = getEhrDataPoints(response.data);
        const newVitalData = ehrDataPoints.filter((d) => vitalSignType.includes(d.key));

        const latestVitalValue = newVitalData.map((vital) => {
          if (vital?.key === "BLOOD_PRESSURE") {
            return hanldeLatestBloodPressureCase(vital);
          }

          const sortedValues = vital.value.sort(
            (a, b) => new Date(b.dateFrom) - new Date(a.dateFrom)
          );

          if (sortedValues.length < 2) {
            // If there are less than two values, we can't compute the percentage difference
            return {
              key: vital.key,
              latestValue: sortedValues[0], // Use the latest value
              difference: null, // No difference available
              differencePercentage: null, // No percentage difference
              isIncreased: 1,
            };
          }

          const latest = sortedValues[0]; // Latest value
          const secondLatest = sortedValues[1]; // Second latest value

          const difference = parseFloat(latest.value) - parseFloat(secondLatest.value); // Calculate difference
          const differencePercentage = (
            (difference / parseFloat(secondLatest.value)) *
            100
          ).toFixed(2); // Calculate percentage difference

          const isIncreased = difference > 0 ? 2 : difference < 0 ? 0 : 1;

          return {
            key: vital.key,
            latestValue: latest,
            difference: difference.toFixed(2),
            differencePercentage: `${differencePercentage}%`, // Format percentage difference
            isIncreased: isIncreased,
          };

          // return {
          //   key: vital.key,
          //   latestValue: vital.value.reduce((latest, current) => {
          //     return current.dateFrom > latest.dateFrom ? current : latest;
          //   }),
          // };
        });

        const top4VitalValues = latestVitalValue.slice(0, 4);

        setVitalData(newVitalData);
        // setVitalLatestValues(top4VitalValues);
        setVitalDetails(latestVitalValue);
      } catch (error) {}
    };

    fetchPatientEhrDetails();
  }, []);

  const checkCallButton = (data) => {
    return (
      (data?.appointmentStatus === SCHEDULED || data?.appointmentStatus === RESCHEDULED) &&
      data?.type === TELE_CONSULTATION
    );
  };

  const handleJoinRoom = useCallback(async () => {
    try {
      const room = upcomingAppointment?.id?.toString();
      navigate(`/home/room/${room}`, {
        state: {
          patientAppointment: upcomingAppointment,
        },
      });
    } catch (e) {
      handleClick("error", "There seems to be an error joining call");
    }
  }, [upcomingAppointment?.id, navigate, handleClick]);

  const [contents, setContents] = useState([]);
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const content = await getAllContent();
        setContents(content?.data);
      } catch (e) {
        console.error("Error fetching Content");
      }
    };

    fetchContent();
  }, [handleClick]);

  // member details table
  const memberDetailFeilds = [
    { field: "member", label: "Member" },
    { field: "age", label: "Age" },
    { field: "gender", label: "Gender" },
    { field: "relation", label: "Relation" },
  ];
  const [memberDetailValues, setMemberDetailValues] = useState([]);

  const [notifyFlags, setNotifyFlags] = useState({});

  useEffect(() => {
    fetchMedicationRemindersDetails();
  }, [dailyMedicationValues]);

  // render vital cards upto 4
  const renderVitalCards = () => {
    let limitedVitalDetails;
    if (vitalDetails.length > 4) {
      limitedVitalDetails = vitalDetails.slice(0, 4);
    } else {
      limitedVitalDetails = vitalDetails;
    }
    return limitedVitalDetails.map((latestVitalValue, index) => (
      <Grid key={index} style={{ width: "275px" }}>
        <Reveal>
          <Card className="patient-vitals-card">
            <CardContent
              style={{
                height: "100%",
                padding: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="card-field-name">
                {latestVitalValue.latestValue?.vitalSignTypeResponse?.displayName}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span className="card-field-value">{latestVitalValue.latestValue.value}</span>
                  &nbsp;
                  <span className="card-field-unit">
                    {latestVitalValue.latestValue?.vitalSignTypeResponse?.unitAbbreviation}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  {/* {card?.change[0] === "+" && (
                    <>
                      <TrendingUpIcon
                        style={{
                          color: "#388E3C",
                          height: "20px",
                          width: "20px",
                        }}
                      ></TrendingUpIcon>
                      <span className="change-field-value-up">{card?.change}</span>
                    </>
                  )}
                  {card?.change[0] === "-" && (
                    <>
                      <TrendingDownIcon
                        style={{
                          color: "#EF5148",
                          height: "20px",
                          width: "20px",
                        }}
                      ></TrendingDownIcon>
                      <span className="change-field-value-down">{card?.change}</span>
                    </>
                  )} */}
                  {latestVitalValue?.isIncreased === 2 ? (
                    <Grid display={"flex"}>
                      <TrendingUp
                        style={{
                          color: "#388E3C",
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </Grid>
                  ) : latestVitalValue?.isIncreased === 0 ? (
                    <Grid display={"flex"}>
                      <TrendingDownIcon
                        style={{
                          color: "#EF5148",
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </Grid>
                  ) : null}
                  <Typography variant="h6" component="div">
                    {latestVitalValue?.differencePercentage}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
        </Reveal>
      </Grid>
    ));
  };

  const fetchMedicationRemindersDetails = async () => {
    const userId = currentActiveUser()?.id;
    try {
      const response = await getMedicationReminderByUserId(userId);
      const data = response.data;
      setNotificationReminderData(data);
      const initialStatuses = data.reduce((acc, item) => {
        acc[item.medicationId] = item.reminderState;
        return acc;
      }, {});
      setNotifyFlags(initialStatuses);
      if (dailyMedicationValues.length !== 0) {
        dailyMedicationValues.forEach((targetMedication) => {
          const isPresent = response.data.some(
            (sourceMedication) => sourceMedication.medicationId === targetMedication.medicationId
          );

          if (!isPresent) {
            setNotifyFlags((prev) => ({ ...prev, [targetMedication.medicationId]: "ARCHIVE" }));
          }
        });
      }
    } catch (error) {
      console.error("Error button status", error);
    }
  };

  function convertEndDateToDateTime(endDate) {
    const dateObj = new Date(endDate);
    dateObj.setHours(23, 59, 59);
    const endDateTime = dateObj.toISOString().split(".")[0];
    return endDateTime;
  }

  const medicationReminders = async (medicineData) => {
    const userId = currentActiveUser()?.id;
    const endDateTime = convertEndDateToDateTime(medicineData.endDate);
    const dataToSend = {
      medicationId: medicineData.medicationId,
      userId: userId,
      timeOfDayMap: {
        MORNING: "08:00:00",
        AFTERNOON: "12:00:00",
        EVENING: "18:00:00",
      },
      endDateTime: endDateTime,
      notificationFrequency: 1,
      notificationFrequencyType: "DAILY",
      instructions: medicineData.doseTiming,
      status: medicineData.status,
    };
    try {
      const response = await medicationReminderNotify(dataToSend);
      const tempMedicationReminderdata = await response.data;
      setNotificationReminderData((prev) => {
        return [...prev, tempMedicationReminderdata];
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const medicationRemindersOff = async (medicineData) => {
    try {
      const response = await medicationReminderNotifyOff(
        notificationReminderData.find((item) => item?.medicationId === medicineData.medicationId)
          ?.id
      );
    } catch (error) {
      handleClick("error", "There seems to be an error turning off medication reminder");
    }
  };

  const medicationRemindersUpdateTrue = async (medicineData) => {
    const dataToSend = {
      reminderState: "ACTIVE",
    };
    try {
      const response = await updateMedicationReminder(
        notificationReminderData.find((item) => item?.medicationId === medicineData.medicationId)
          ?.id,
        dataToSend
      );
      const data = response.data;
    } catch (error) {
      handleClick("error", "There seems to be an error updating medication reminder");
    }
  };

  const medicationRemindersUpdateFalse = async (medicineData) => {
    const dataToSend = {
      reminderState: "ARCHIVE",
    };
    try {
      const response = await updateMedicationReminder(
        notificationReminderData.find((item) => item?.medicationId === medicineData.medicationId)
          ?.id,
        dataToSend
      );
      const data = response.data;
    } catch (error) {
      handleClick("error", "There seems to be an error updating medication reminder");
    }
  };

  const renderVitalButton = () => {
    if (vitalDetails.length > 4) {
      return (
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Reveal>
            <p
              className="view-all"
              onClick={() => {
                navigate("/home/patientProfile", {
                  state: { initialTab: "four" },
                });
              }}
            >
              View All
            </p>
          </Reveal>
        </Grid>
      );
    }
    return null;
  };
  const getRandomColor = () => {
    const letters = "6789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  const [medicineImages, setMedicineImages] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState(null);

  const handleOpenImageModal = async (medicine) => {
    try {
      setTitle(medicine?.medicine);
      const medicineImagesResponse = await getMedicinesImages(medicine?.medicationId);

      const medicines = medicineImagesResponse.data.map((e) => {
        return {
          ...e,
          url: `data:image/${e.extension};base64,${e.document}`,
        };
      });
      setMedicineImages(medicines);
      if (medicines?.length > 0) {
        setModalOpen(true);
      }
    } catch (e) {
      console.error("Failed to fetch Medicine Images", e);
    }
  };

  const handleCloseImageModal = () => {
    setModalOpen(false);
    setMedicineImages([]);
  };

  return (
    <PatientDashboardSkeleton>
      <div>
        <div className="patient-dashboard">
          <Grid container direction="column" alignItems="stretch" justifyContent="space-evenly">
            <Reveal>
              <div className="section-heading">
                <h2 className="page-title">Dashboard</h2>
                <div className="buttons">
                  <CustomButton
                    className="ri-add-fill ri-lg btn--secondary"
                    label="New Appointment"
                    onClick={() => {
                      navigate("/home/doctorSearch");
                    }}
                  ></CustomButton>
                </div>
              </div>
            </Reveal>
            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
              {renderVitalCards()}
              {renderVitalButton()}
            </Grid>
            <Grid container spacing={2}>
              <Grid item size={{ xs: 12, md: 6 }}>
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <Reveal>
                      <Paper
                        style={{
                          width: "100%",
                          height: "fit-content",
                          minHeight: "300px",
                          borderRadius: "10px",
                          padding: "17px 24px",
                          marginBottom: "12px",
                        }}
                      >
                        <div style={{ width: "100%", height: "fit-content" }}>
                          <div>
                            <Typography className="card-field-value">
                              Upcoming Appointment :
                            </Typography>
                            {!upcomingAppointment && (
                              <Typography>
                                <i>Currently, there are no appointments on your schedule.</i>
                              </Typography>
                            )}
                            {upcomingAppointment && (
                              <Card style={{ padding: "15px 8px" }}>
                                <div
                                  style={{
                                    height: "fit-content",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "400px",
                                      height: "85px",
                                      borderRadius: "8px",
                                      display: "flex",
                                    }}
                                  >
                                    {isLoading ? (
                                      <Skeleton
                                        variant="circular"
                                        animation="wave"
                                        width={"30%"}
                                        height={"100%"}
                                      />
                                    ) : (
                                      <img
                                        src={
                                          profileImage ? profileImage?.content : genderBasedImage
                                        }
                                        alt="patient_image"
                                        style={{
                                          aspectRatio: "1 / 1",
                                          borderRadius: "50%",
                                        }}
                                        height="100%"
                                      ></img>
                                    )}
                                    <div style={{ marginLeft: "10px" }}>
                                      <div>
                                        <Typography className="doctorNameText">
                                          Dr. {upcomingAppointment?.doctor?.userResponse?.name}
                                        </Typography>
                                      </div>
                                      <div
                                        style={{
                                          width: "fit-content",
                                          height: "fit-content",
                                          display: "flex",
                                          justifyContent: "space-evenly",
                                        }}
                                      >
                                        <Typography className="doctorDetailText">
                                          {upcomingAppointment?.doctor?.specialization?.name}
                                        </Typography>
                                        {upcomingAppointment?.doctor?.specialization?.name && (
                                          <>
                                            &nbsp;
                                            <Typography className="doctorDetailText">|</Typography>
                                            &nbsp;&nbsp;
                                          </>
                                        )}
                                        {upcomingAppointment?.doctor?.avgRating ? (
                                          <>
                                            <Typography>
                                              {upcomingAppointment?.doctor?.avgRating}
                                            </Typography>
                                            <i className="ri-star-fill icon-primary-star" />
                                          </>
                                        ) : (
                                          <span className="cst-star-style">
                                            - <i className="ri-star-fill" />
                                          </span>
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          width: "fit-content",
                                          height: "fit-content",
                                          display: "flex",
                                          justifyContent: "space-evenly",
                                        }}
                                      >
                                        <Typography className="doctorDetailText">
                                          {dayjs(upcomingAppointment?.appointmentDate).format(
                                            DATE_FORMAT_DMY
                                          )}
                                        </Typography>
                                        <Typography className="doctorDetailText">|</Typography>
                                        <Typography className="doctorDetailText">
                                          {convertTimeForTimeFormat(
                                            upcomingAppointment?.appointmentTime
                                          )}
                                        </Typography>
                                      </div>
                                      <div
                                        style={{
                                          width: "fit-content",
                                          height: "fit-content",
                                          display: "flex",
                                          justifyContent: "space-evenly",
                                        }}
                                      >
                                        {/* distance from doctor/clinic to be added later 
                                        <Typography className="doctorDetailText">
                                          Distance
                                        </Typography> */}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="text-align-right">
                                    {checkCallButton(upcomingAppointment) && (
                                      <CustomButton
                                        label="Join Meeting"
                                        className="btn--secondary"
                                        startIcon={<VideocamIcon></VideocamIcon>}
                                        onClick={handleJoinRoom}
                                        style={{ marginBottom: "15px" }}
                                      ></CustomButton>
                                    )}

                                    <CustomButton
                                      label="Reschedule"
                                      className="btn--secondary"
                                      startIcon={<TodayOutlinedIcon></TodayOutlinedIcon>}
                                      onClick={() => {
                                        const appointment = {
                                          // appointmentData: null,
                                          id: upcomingAppointment?.id,
                                          orgId: upcomingAppointment?.orgId,
                                          appointmentTime:
                                            upcomingAppointment?.appointmentTime.slice(0, -3),
                                          patientId: upcomingAppointment?.patientId,
                                          doctorId: upcomingAppointment?.doctorId,
                                          appointmentDate: upcomingAppointment?.appointmentDate,
                                          notes: upcomingAppointment?.notes,
                                          type: upcomingAppointment?.type,
                                        };
                                        dispatch(setSelectedAppointmentData(appointment));
                                        setOpenAppointmentModal(true);
                                      }}
                                    ></CustomButton>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "fit-content",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "10px",
                                  }}
                                >
                                  <div>
                                    {showVisited && (
                                      <Typography className="doctorDetailText mt-25">
                                        Last visited {lastVisited !== 0 ? lastVisited : ""}{" "}
                                        {lastVisited <= 1
                                          ? lastVisited === 0
                                            ? "today"
                                            : "day ago"
                                          : "days ago"}
                                      </Typography>
                                    )}
                                    <Typography
                                      className="consultType"
                                      style={{ marginTop: "10px" }}
                                    >
                                      {upcomingAppointment?.type === CLINIC_VISIT
                                        ? "Clinic Visit"
                                        : "Teleconsultation"}
                                    </Typography>
                                  </div>
                                </div>
                              </Card>
                            )}
                            {upcomingAppointment && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginTop: "10px",
                                }}
                              >
                                <p
                                  className="view-all"
                                  onClick={() => {
                                    navigate("/home/patientAppointments");
                                  }}
                                >
                                  View All
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </Paper>
                    </Reveal>
                  </Grid>
                  <Grid item>
                    <Reveal>
                      <Paper
                        style={{
                          height: "100%",
                          padding: "20px",
                          textAlign: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography className="card-field-value">Health Feeds</Typography>
                          {/* <div>
                            <CustomButton
                              iconButton={<i className="ri-more-2-fill" />}
                              style={{ padding: 0, marginLeft: "5px" }}
                            ></CustomButton>
                          </div> */}
                        </div>
                        <div>
                          {contents?.slice(0, 2)?.map((content, index) => (
                            <Card key={index} style={{ marginBottom: "20px" }}>
                              <CardContent>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "left",
                                  }}
                                >
                                  <div
                                    style={{
                                      minWidth: "86px",
                                      minHeight: "86px",
                                      borderRadius: "8px",
                                      backgroundColor: "#F9F87133",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <PlayCircleIcon
                                      style={{ width: "20px", height: "20px" }}
                                    ></PlayCircleIcon>
                                  </div>
                                  <div>
                                    <Typography className="healthFeedTitle">
                                      {content?.title}
                                    </Typography>
                                    <Typography
                                      className="healthFeedText"
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 2,
                                      }}
                                    >
                                      {content?.description}
                                    </Typography>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "86px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <i className="ri-heart-line ri-xl" />
                                    <i className="ri-pushpin-line ri-xl" />
                                    <i className="ri-play-list-add-line ri-lg" />
                                  </div>
                                  <CustomButton
                                    label="Read Full Article"
                                    className="btn--primary"
                                    onClick={() => {
                                      navigate(`/home/health-feeds/content/${content?.uuid}`, {
                                        state: {
                                          contentId: content?.uuid,
                                        },
                                      });
                                    }}
                                  ></CustomButton>
                                </div>
                              </CardContent>
                            </Card>
                          ))}

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "10px",
                            }}
                          >
                            <p
                              className="view-all"
                              onClick={() => {
                                navigate("/home/health-feeds");
                              }}
                            >
                              View All
                            </p>
                          </div>
                        </div>
                      </Paper>
                    </Reveal>
                  </Grid>
                  {recentVisits.length !== 0 && (
                    <Grid>
                      <Reveal>
                        <Paper
                          style={{
                            minHeight: "275px",
                            width: "100%",
                            padding: "20px",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography className="card-field-value">Recent Visits</Typography>
                          </div>
                          <Grid container size={{ xs: 12 }} spacing={2}>
                            {recentVisits.length === 0 ? (
                              <Typography style={{ margin: "20px" }}>No appointments</Typography>
                            ) : (
                              <>
                                {recentVisits.slice(0, 3).map((item, index) => (
                                  <Grid
                                    size={{ xs: 12, md: 12, lg: 6 }}
                                    className="appointment-list"
                                  >
                                    <ul className="appointments">
                                      <RecentVisitItem appointment={item} key={index} />
                                    </ul>
                                  </Grid>
                                ))}
                                {recentVisits.length > 3 && (
                                  <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    className="appointment-list"
                                    sx={{ margin: "auto" }}
                                  >
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "15px",
                                        color: "#1976d2",
                                        textDecoration: "underline",
                                      }}
                                      onClick={toggleDrawer(!open)}
                                    >
                                      {`+${recentVisits.length - 3} more`}
                                    </span>
                                  </Grid>
                                )}
                              </>
                            )}
                          </Grid>
                        </Paper>
                      </Reveal>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Grid
                  sx={{ background: "#004c70", height: "50px", marginBottom: "20px" }}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "19px",
                      fontWeight: "500",
                      textAlign: "left",
                      paddingLeft: "15px",
                      color: "#ffffff",
                    }}
                  >
                    Recent Visits
                  </Typography>
                  <CustomButton
                    onClick={() => setOpen(false)}
                    iconButton={<CloseIcon></CloseIcon>}
                    style={{ color: "white", height: "2px", marginLeft: "auto" }}
                  ></CustomButton>
                </Grid>
                {recentVisits.map((item, index) => (
                  <Grid
                    size={{ xs: 12, md: 12, lg: 12 }}
                    sx={{ paddingX: "20px" }}
                    className="appointment-list"
                  >
                    <ul className="appointments">
                      <RecentVisitItem appointment={item} key={index} />
                    </ul>
                  </Grid>
                ))}
              </Drawer>
              <Grid item size={{ xs: 12, md: 6 }}>
                <Grid container spacing={2} direction="column">
                  {dailyMedicationValues.length > 0 && (
                    <Grid size={{ xs: 12, md: 6 }}>
                      <Reveal>
                        <Paper
                          style={{
                            width: "100%",
                            height: "fit-content",
                            minHeight: "300px",
                            borderRadius: "10px",
                            padding: "17px 24px",
                            marginBottom: "12px",
                            display: "flex",
                            flexDirection: "column",
                            // justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "10px",
                            }}
                          >
                            <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                              <Typography className="card-field-value">Daily Medicines</Typography>
                            </div>
                            <div>
                              <CustomButton
                                iconButton={<i className="ri-more-2-fill" />}
                                style={{ padding: 0, marginLeft: "10px" }}
                                // onClick={() => MedicationDropdown(true)}
                              />
                            </div>
                          </div>
                          <div>
                            {dailyMedicationValues.map((medicine, index) => {
                              const color = getRandomColor();
                              return (
                                <div
                                  onClick={() => {
                                    handleOpenImageModal(medicine);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginBottom: "5px",
                                      paddingBottom: "5px",
                                    }}
                                    key={medicine?.medicationId}
                                  >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <i
                                        style={{ marginRight: "10px", color: color }}
                                        className="ri-medicine-bottle-line ri-xl"
                                      />
                                      <p>{medicine.medicine}</p>
                                    </div>
                                    <div
                                      style={{ display: "flex", alignItems: "center", gap: "15px" }}
                                    >
                                      <div style={{ display: "flex", gap: "15px" }}>
                                        {medicine.frequency &&
                                          medicine?.frequency.split("-").map((freq, i) => (
                                            <div
                                              key={i}
                                              style={{
                                                height: "25px",
                                                width: "25px",
                                                borderRadius: "50%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                border: `1px solid ${color}`,
                                                backgroundColor: color,
                                              }}
                                            >
                                              {freq === "1" ? (
                                                <div
                                                  style={{
                                                    height: "10px",
                                                    width: "10px",
                                                    borderRadius: "50%",

                                                    backgroundColor: "white",
                                                    opacity: 0.4,
                                                  }}
                                                ></div>
                                              ) : freq >= 1 ? (
                                                <span
                                                  style={{
                                                    fontWeight: "600",
                                                    color: "white",
                                                  }}
                                                >
                                                  {freq}
                                                </span>
                                              ) : (
                                                <span style={{ color: "white" }}>-</span>
                                              )}
                                            </div>
                                          ))}
                                      </div>

                                      {notifyFlags[medicine.medicationId] === "ACTIVE" && (
                                        <i
                                          className="ri-notification-4-line ri-xl icon-primary-blue"
                                          onClick={() => {
                                            medicationRemindersUpdateFalse(medicine);
                                            // medicationRemindersOff(medicine);
                                            setNotifyFlags((prevNotifyFlags) => ({
                                              ...prevNotifyFlags,
                                              [medicine.medicationId]: "ARCHIVE",
                                            }));
                                          }}
                                          style={{ cursor: "pointer" }}
                                        ></i>
                                      )}
                                      {notifyFlags[medicine.medicationId] === "ARCHIVE" && (
                                        <i
                                          className="ri-notification-off-line ri-xl icon-primary-blue"
                                          onClick={() => {
                                            if (
                                              notificationReminderData.some(
                                                (medication) =>
                                                  medication.medicationId === medicine.medicationId
                                              )
                                            ) {
                                              medicationRemindersUpdateTrue(medicine);
                                            } else {
                                              medicationReminders(medicine);
                                            }
                                            // medicationReminders(medicine);
                                            setNotifyFlags((prevNotifyFlags) => ({
                                              ...prevNotifyFlags,
                                              [medicine.medicationId]: "ACTIVE",
                                            }));
                                          }}
                                          style={{ cursor: "pointer" }}
                                        ></i>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Paper>
                      </Reveal>
                    </Grid>
                  )}
                  <Grid container size={{ xs: 12, md: 12 }} direction="column">
                    <Grid>
                      <Reveal>
                        <Paper
                          style={{
                            height: "225px",
                            width: "100%",
                            marginBottom: "10px",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              height: "100%",
                              aspectRatio: "1 / 1",
                              backgroundColor: "#22ABE21A",
                              borderRadius: "10px 0px 250px 0px",
                            }}
                          ></div>
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              height: "83.3%",
                              aspectRatio: "1 / 1",
                              backgroundColor: "#22ABE240",
                              borderRadius: "10px 0px 250px 0px",
                            }}
                          ></div>
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              height: "66.67%",
                              aspectRatio: "1 / 1",
                              backgroundColor: "#22ABE280",
                              borderRadius: "10px 0px 250px 0px",
                            }}
                          ></div>
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              height: "50%",
                              aspectRatio: "1 / 1",
                              backgroundColor: "#22ABE2",
                              borderRadius: "10px 0px 250px 0px",
                            }}
                          ></div>
                          <div
                            style={{
                              width: "60px",
                              height: "60px",
                              borderRadius: "16px",
                              border: "2px solid #22ABE2",
                              gap: "10px",
                              position: "absolute",
                              top: "24px",
                              right: "24px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src="/images/hc_robot.svg"
                              alt="robot_image"
                              style={{ maxWidth: "30px", maxHeight: "30px" }}
                            ></img>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              bottom: "24px",
                              left: "24px",
                            }}
                          >
                            <Typography className="card-field-name">
                              Answer a few questions and get your <br></br> health score
                            </Typography>
                            <Typography className="getHealthScore">
                              Get your personal{" "}
                              <span className="getHealthScoreSpan">health score</span>
                            </Typography>
                          </div>
                        </Paper>
                      </Reveal>
                    </Grid>
                    <Grid>
                      <Reveal>
                        <Paper
                          style={{
                            height: "275px",
                            width: "100%",
                            padding: "20px",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography className="card-field-value">Family Members</Typography>
                            {/* <CustomButton
                              iconButton={<i className="ri-more-2-fill" />}
                              style={{ padding: 0 }}
                            ></CustomButton> */}
                          </div>
                          <div>
                            <CustomizedTable
                              rowsPerPageInTable={3}
                              columns={memberDetailFeilds}
                              tableData={memberDetailValues}
                            />
                          </div>
                        </Paper>
                      </Reveal>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <ModalUI
            visible={openModal}
            close={handleClose}
            title={"Link your Abha"}
            style={{
              // width:"300px",
              // height:"400px",
              overflowY: "auto",
            }}
            component={
              <PendingDetailsPopupComponent
                steps={steps}
                handleSkipForNow={handleSkipForNow}
                isAbhaNumberExist={isAbhaNumberExist}
                activeUser={currentActiveUser()}
              />
            }
          />

          <ModalUI
            visible={openAppointmentModal}
            close={() => {
              dispatch(setSelectedAppointmentData({}));
              setOpenAppointmentModal(false);
            }}
            title="Reschedule Appointment"
            style={{
              overflowY: "scroll",
              height: "33.375rem",
              width: "38.125rem",
            }}
            component={
              <BookAppointmentModal
                reschedule={true}
                handleClick={handleClick}
                data={upcomingAppointment}
                closeModal={() => {
                  setOpenAppointmentModal(false);
                }}
              />
            }
          />

          <ModalUI
            visible={modalOpen}
            close={handleCloseImageModal}
            title={title}
            style={{
              padding: "30px 60px",
            }}
            component={
              <div style={{ marginTop: "30px" }}>
                <SimpleImageSlider
                  autoPlay={true}
                  slideDuration={1}
                  navStyle={2}
                  width={"500px"}
                  height={"500px"}
                  images={medicineImages}
                  showNavs={true}
                  navMargin={1}
                  navSize={"12px"}
                />
              </div>
            }
          />
        </div>
      </div>
    </PatientDashboardSkeleton>
  );
};

const PatientDashboardSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 3 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
        </Grid>

        <Grid size={{ xs: 3 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
        </Grid>
        <Grid size={{ xs: 3 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
        </Grid>
        <Grid size={{ xs: 3 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
        </Grid>
        <Grid size={{ xs: 3 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
        </Grid>

        <Grid size={{ xs: 6 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={270}
          />
        </Grid>
        <Grid size={{ xs: 6 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={270}
          />
        </Grid>

        <Grid size={{ xs: 6 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={500}
          />
        </Grid>

        <Grid size={{ xs: 6 }}>
          <Skeleton
            sx={{ borderRadius: "4px", mb: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={200}
          />
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={300}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientDashboard;
