import { Avatar, Button, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { useTheme } from "@mui/material/styles";
import "assets/scss/customButtonStyle.scss";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";

/*
  ---> You can use this component three ways. lebel, icons, image and all other props are 
       common for both ways except "customBackGroundColor" and "textAndIconColor" props.
       Below ways just differ in the background color and color of the textAndIcon.

        1) you can give pre-defined classNames in the "assets/scss/customButtonStyle.scss" file
           to use standard pre-defined buttons. if you use this approach you have to import 
           "assets/scss/customButtonStyle.scss" file in your java-script file.
    
        2) you can give "customBackGroundColor" and "textAndIconColor" for custom colors of the buttons.

        3) you can create a style for the new custom className for the buttons which are frequently 
           used in the system and use the className for it in the component.

  ---> In addition to above you can use this component as a dropdwon too. you have to provide 
       dropDownMenuItems prop which has the list of menuItem content. each object of these list
       has label, badgeColor, image props. And there are other props related to dropDownMenu.
       Ypu can check it in this component definition.

       one Integration for the dropDown is as below,
            {
              const menuItems = [
                  {label: "Available", badgeColor: "#29bf91"},
                  {label: "Not available", badgeColor: "#345678"},
              ];

              <CustomButton
                            className={"btn--secondary-light"}
                            startIcon={<TimerOutlined />}
                            endIcon={<ArrowDropDownOutlinedIcon />}
                            dropDownMenuItems={menuItems}
                            defaultSelectedItem={menuItems[0]}
                            menuItemHoverColor='#29bf91'
                        />
            }
 */

const CustomButton = React.forwardRef(
  (
    {
      startIcon,
      endIcon,
      label,
      children,
      variant = "contained",
      MuiButtoncolor = "primary",
      onClick,
      style,
      borderRadius = "6px",
      textAndIconColor,
      disableElevation = true,
      disabled,
      className,
      customBackGroundColor,
      borderColor,
      width = "max-content",
      minWidth = "160px",
      height = "34px",
      displayButtonContent = "flex",
      justifyButton = "",
      fontWeight = "500",
      gap = "6px",
      justifyButtonContent = "space-between",
      alignButtonContentItems = "center",
      fontSize,
      Image,
      badgeColor,
      dropDownMenuItems,
      dropDownBorderRadius = "6px",
      menuItemHoverColor = "",
      defaultSelectedItem,
      onMenuItemClick,
      dropDownPadding = "6px",
      iconButton,
      showMenuItems,
      changeButtonLabelOnMenuItemClick,
      isTeleConsultation,
      handleRightGridClose,
      ...restProps
    },
    ref
  ) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState(defaultSelectedItem || null);
    const location = useLocation();

    const handleButtonClick = (event) => {
      onClick && onClick(event);
      if (dropDownMenuItems) {
        setAnchorEl(event.currentTarget);
      }
    };

    const handleClose = () => {
      setAnchorEl(null);
      if (handleRightGridClose) handleRightGridClose();
    };

    useEffect(() => {
      setAnchorEl(null);
    }, [location]);

    const handleMenuItemClick = (menuItem) => {
      if (changeButtonLabelOnMenuItemClick) {
        setSelectedMenuItem(menuItem);
      }
      setAnchorEl(null);
      onMenuItemClick && onMenuItemClick(menuItem);
    };

    const buttonStyle = Object.assign({
      border: `1px solid ${borderColor}`,
      borderRadius,
      displayButtonContent,
      justifyButtonContent,
      alignButtonContentItems,
      // paddingLeft: "12px",
      // paddingRight: "12px",
      width,
      height: height,
      textStyles: {
        color: textAndIconColor || "",
      },
      iconStyles: {
        fill: textAndIconColor || "",
      },
      ...(customBackGroundColor ? { backgroundColor: customBackGroundColor } : {}),
      ...(disabled && isTeleConsultation
        ? { backgroundColor: "rgba(0,0,0,0.6)", color: "#ffffff" }
        : {}),
      ...style,
    });

    const defaultTypographyProps = {
      fontFamily: "Poppins",
    };

    const hasLabel = label && label.trim() !== "";

    const buttonClass = !hasLabel ? "no-icon-margin" : "";

    const getStatusIcon = (badgeColor) => {
      const isHover = badgeColor === menuItemHoverColor;
      return (
        <span
          style={{
            backgroundColor: badgeColor,
            color: "white",
            padding: "4px 4px",
            width: "8px",
            height: "8px",
            textAlign: "center",
            border: isHover ? "1px solid white" : "",
            borderRadius: "10px",
            display: "inline-block",
          }}
        ></span>
      );
    };

    return (
      <>
        {iconButton ? (
          <IconButton
            ref={ref}
            onClick={handleButtonClick}
            className={`${className}`}
            disabled={disabled}
            style={{
              height: "40px",
              backgroundColor: customBackGroundColor ? customBackGroundColor : "",
              color: textAndIconColor,
              ...buttonStyle,
            }}
            aria-label="delete"
            {...restProps}
          >
            {iconButton}
            {children}
          </IconButton>
        ) : (
          <Button
            ref={ref}
            startIcon={
              startIcon &&
              !Image &&
              React.cloneElement(startIcon, { style: buttonStyle.iconStyles })
            }
            endIcon={endIcon && React.cloneElement(endIcon, { style: buttonStyle.iconStyles })}
            color={MuiButtoncolor}
            variant={variant}
            onClick={handleButtonClick}
            style={{ ...buttonStyle, gap: gap }}
            className={`${className} ${buttonClass}`}
            disabled={disabled}
            disableElevation={disableElevation}
            {...restProps}
          >
            {selectedMenuItem ? (
              <>
                {selectedMenuItem.image && (
                  <Avatar
                    src={selectedMenuItem.image}
                    sx={{
                      ...theme.typography.smallAvatar,
                      margin: "0px 0px 0px 0px !important",
                      cursor: "pointer",
                    }}
                  />
                )}
                {selectedMenuItem.badgeColor && getStatusIcon(selectedMenuItem.badgeColor)}
                {selectedMenuItem.label && (
                  <Typography
                    {...defaultTypographyProps}
                    sx={{
                      fontSize: fontSize ? fontSize : { md: "12px", lg: "13px", xl: "14px" },
                      fontWeight,
                      ...buttonStyle.textStyles,
                    }}
                  >
                    {selectedMenuItem.label}
                  </Typography>
                )}
              </>
            ) : (
              <>
                {Image && (
                  <Avatar
                    src={Image}
                    sx={{
                      ...theme.typography.smallAvatar,
                      margin: "0px 0px 0px 0px !important",
                      cursor: "pointer",
                    }}
                  />
                )}
                {badgeColor && getStatusIcon(badgeColor)}
                {hasLabel && (
                  <Typography
                    {...defaultTypographyProps}
                    sx={{
                      fontSize: fontSize ? fontSize : { md: "12px", lg: "13px", xl: "14px" },
                      fontWeight,
                      ...buttonStyle.textStyles,
                      m: "auto",
                    }}
                  >
                    {label}
                  </Typography>
                )}
                {children}
              </>
            )}
          </Button>
        )}
        {dropDownMenuItems && !showMenuItems && (
          <Grid>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  borderRadius: dropDownBorderRadius,
                  minWidth: minWidth,
                  padding: dropDownPadding,
                },
              }}
              MenuListProps={{ disablePadding: true }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              {dropDownMenuItems.map((item, index) => (
                <Grid key={index}>
                  {item.isSaveButton && <Divider />}
                  <MenuItem
                    key={index}
                    onClick={() => handleMenuItemClick(item)}
                    sx={{
                      "&:hover": {
                        backgroundColor: `${menuItemHoverColor} !important`,
                        color: menuItemHoverColor ? "#ffffff !important" : "",
                      },
                      borderRadius: "3px",
                      lineHeight: "21px",
                    }}
                    disabled={item.disabled ? item.disabled : false}
                    style={{
                      gap: gap,
                      fontStyle: item.isNoTemplateAvailable ? "italic" : "normal",
                      backgroundColor: item.isSaveButton ? "rgba(0, 255, 0, 0.1)" : "inherit",
                      boxShadow: item.isSaveButton ? "0px 2px 10px rgba(0, 0, 0, 0.1)" : "none",
                      borderRadius: item.isSaveButton ? "4px" : "inherit",
                      color: item.isSaveButton ? "#004c70" : "inherit",
                    }}
                  >
                    {item.icon}
                    {item.badgeColor && getStatusIcon(item.badgeColor)}

                    {item.image && (
                      <Avatar
                        src={item.image}
                        sx={{
                          ...theme.typography.smallAvatar,
                          margin: "0px 0px 0px 0px !important",
                          cursor: "pointer",
                        }}
                      />
                    )}
                    {item.label && (
                      <Typography {...defaultTypographyProps}>{item.label}</Typography>
                    )}
                  </MenuItem>
                  {index !== dropDownMenuItems.length - 1 && (
                    <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
                  )}
                </Grid>
              ))}
            </Menu>
          </Grid>
        )}
      </>
    );
  }
);

export default CustomButton;
