import { api as API } from "./AxiosInterceptor";

export const getAllTenants = () => API.get(`/api/tenants`);

export const createNewTenant = (data) => API.post(`/api/tenants`, data);

export const updateExistingTenant = (id, data) => API.put(`/api/tenants/${id}`, data);

export const getTenantByOrgId = (orgId) => API.get(`/api/tenants/org/${orgId}`);

export const getTenantById = (tenantId) => API.get(`/api/tenants/tenant/${tenantId}`);
